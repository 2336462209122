
<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">{{ heading }}</h4>
</div>
<div class="modal-body">
  {{content.description}}
</div>
<div class="modal-footer">
  <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="modalRef.hide()" mdbWavesEffect>{{ content.button }}</button>
</div>
