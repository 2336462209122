import { TitleCasePipe } from "@angular/common";


const titleCasePipe = new TitleCasePipe();

export class WorkorderFilters {

  public staticFilters;

  public dropdowns = ['guild', 'typecondition', 'typestatusonmobile'];

  public optionalFilters;
  constructor(private entities){
    this.optionalFilters = [
      {
        entity: entities.guild.entity,
        field: {
          name: 'id',
          type: 'select',
          label: titleCasePipe.transform(entities.guild.labels.form),
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: entities.guild.entity
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: 'employee',
        field: {
          name: 'name_complete',
          type: 'text',
          label: 'Encargado',
          width: 2
        }
      },
      {
        entity: this.entities.workorder.entity,
        field: {
          name: 'type_export_id',
          type: 'select',
          label: 'Exportado',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
            {
              value:  1,
              text: 'Pendiente'
            },
            {
              value:  2,
              text: 'SemiExportado'
            },
            {
              value:  3,
              text: 'Exportado'
            },
          ]
        }
      }
    ]
    this.staticFilters = [
      {
        entity: this.entities.workorder.entity,
        field: {
          name: 'number',
          type: 'text',
          label: 'Nº '+titleCasePipe.transform(this.entities.workorder.labels.form),
          width: 3
        }
      },
      {
        entity: this.entities.workorder.entity,
        field: {
          name: 'typecondition_id',
          type: 'select',
          label: 'Estado',
          width: 1,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: 'typecondition'
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
          ]
        }
      },
      {
        entity: this.entities.workorder.entity,
        field: {
          name: 'typestatusonmobile_id',
          type: 'select',
          label: 'Estado App',
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: 'typestatusonmobile'
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: this.entities.client.entity,
        field: {
          name: 'name',
          type: 'text',
          label: titleCasePipe.transform(this.entities.client.labels.form),
          width: 3
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'number_provider',
          type: 'text',
          label: 'Nº '+titleCasePipe.transform(this.entities.claim.labels.form),
          width: 2
        }
      },
      {
        entity: this.entities.workorder.entity,
        field: {
          name: 'created_at',
          type: 'date',
          label: 'Creado',
          width: 2,
          from: true
        }
      },
      {
        entity: 'current_appointment',
        field: {
          name: 'appointment_date_filter',
          type: 'date',
          label: 'Fecha Cita',
          width: 2,
          from: true
        }
      }
    ]

    }
}

