
<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <div style="margin: 10px;">
    <form [formGroup]="formCompany">
      <div class="row mt-2">
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
              <input mdbInput type="text" id="cif" formControlName="cif" class="form-control form-control-sm" placeholder="">
              <label for="cif" class="">CIF</label>
          </div>
        </div>
        <div class="col-lg">
            <div class="md-form md-outline" formGroupName="provider">
              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="companyname" formControlName="name" class="form-control form-control-sm" placeholder="">
              <label for="companyname" class="">Nombre</label>
              <mdb-error *ngIf="formCompany.controls.provider['controls'].name.invalid && (formCompany.controls.provider['controls'].name.dirty || formCompany.controls.provider['controls'].name.touched)">Campo obligatorio</mdb-error>
            </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="email" formControlName="email" class="form-control form-control-sm" placeholder="">
            <label for="email" class="">Email</label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="phone" formControlName="phone" class="form-control form-control-sm" placeholder="">
            <label for="phone" class="">Teléfono</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="mobile" formControlName="mobile" class="form-control form-control-sm" placeholder="">
            <label for="mobile" class="">Móvil</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="row">
            <div class="col-11">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" mdbValidate [validateSuccess]="false"  placeholder="Sin Asignar" label="Compañía principal" formControlName="provider_id">
                  <mdb-select-option *ngFor="let option of providers" [value]="option.id">{{ option.name }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formCompany.controls.provider_id.invalid && (formCompany.controls.provider_id.dirty || formCompany.controls.provider_id.touched)">Campo obligatorio</mdb-error>
              </div>
            </div>
            <div class="col-1 mt-2">
              <mdb-checkbox [default]="true" [checkboxPosition]="'left'" formControlName="is_subcompany"></mdb-checkbox>
            </div>
          </div>
        </div>     
      </div>
    </form>
  </div>
  
  <!--DIRECCIONES-->
  <div class="divCustomSection">
    <i class='fas fa-home-alt mr-2'></i> Dirección completa
  </div>
  <div style="margin: 10px;">
    <form [formGroup]="addressForm">
      <div class="row mt-3">
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="companyddress"formControlName="address" class="form-control form-control-sm" placeholder="">
            <label for="companyddress" class="">Dirección</label>
          </div>
        </div>
        <div class="col-lg" formGroupName="postalcode">
          <div class="row">
            <div class="col">
              <div class="md-form md-outline">
                <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                  <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                </a>
                <input mdbInput type="text" id="companypostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                <label for="companypostal_code" class="">Código postal</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="companypopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                <label for="companypopulation" class="">Población</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="companyprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                <label for="companyprovince" class="">Provincia</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!--
<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formCompany">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'<i class=\'fas fa-info-circle\'></i> Datos Nueva '+data.entity_label">
            <div class="row">
              <div class="col-lg-2">
                  <div class="md-form" formGroupName="provider">
                      <input mdbInput type="text" id="cif"
                          formControlName="cif" class="form-control form-control-sm" placeholder="">
                      <label for="cif" class="">CIF</label>
                  </div>
              </div>
              <div class="col-lg">
                  <div class="md-form" formGroupName="provider">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="companyname"
                          formControlName="name" class="form-control form-control-sm" placeholder="">
                      <label for="companyname" class="">Nombre</label>
                      <mdb-error
                          *ngIf="formCompany.controls.provider['controls'].name.invalid && (formCompany.controls.provider['controls'].name.dirty || formCompany.controls.provider['controls'].name.touched)">
                          Campo obligatorio</mdb-error>
                  </div>
              </div>
              <div class="col-lg-1">
                <div class="mt-3">
                  <mdb-checkbox [default]="true" [checkboxPosition]="'right'" formControlName="is_subcompany">Sub{{data.entity_label}}</mdb-checkbox>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="md-form">
                  <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Sin Asignar" label="" formControlName="provider_id">
                    <mdb-select-option *ngFor="let option of providers" [value]="option.id">{{ option.name }}</mdb-select-option>
                  </mdb-select-2>
                  <mdb-error
                          *ngIf="formCompany.controls.provider_id.invalid && (formCompany.controls.provider_id.dirty || formCompany.controls.provider_id.touched)">
                          Campo obligatorio</mdb-error>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md">
                <div class="md-form" formGroupName="provider">
                  <input mdbInput type="text" id="email"
                      formControlName="email" class="form-control form-control-sm" placeholder="">
                  <label for="email" class="">Email</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form" formGroupName="provider">
                  <input mdbInput type="text" id="phone"
                      formControlName="phone" class="form-control form-control-sm" placeholder="">
                  <label for="phone" class="">Teléfono</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form" formGroupName="provider">
                  <input mdbInput type="text" id="mobile"
                      formControlName="mobile" class="form-control form-control-sm" placeholder="">
                  <label for="mobile" class="">Móvil</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>

  </form>
    <div class="row mt-3">
      <div class="col-md custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="<i class='fas fa-home-alt'></i> Dirección">
            <form [formGroup]="addressForm">
              <div class="row mt-3">
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="companyddress"formControlName="address" class="form-control form-control-sm" placeholder="">
                    <label for="companyddress" class="">Dirección</label>
                  </div>
                </div>

              </div>
              <div class="row mt-2" formGroupName="postalcode">
                <!-- <div class="col-1">

                </div> -->
<!--               <div class="col-md-2">
                  <div class="md-form">
                    <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                      <mdb-icon fas icon="hand-point-up"></mdb-icon>
                    </a>
                    <input mdbInput type="text" id="companypostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="companypostal_code" class="">Código postal</label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="companypopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="companypopulation" class="">Población</label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="companyprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="companyprovince" class="">Provincia</label>
                  </div>
                </div>
              </div>
            </form>
          </mdb-tab>
          </mdb-tabset>
      </div>
      <!-- <div class="col-md custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="<i class='fas fa-broadcast-tower'></i> Protocolo">
            <form [formGroup]="formProtocol">
              <div class="row mt-2">
                <div class="col-md">
                    <div class="md-form">
                      <mdb-select-2 placeholder="Seleccionar" label="Protocolo" formControlName="typeprotocol_id">
                        <mdb-select-option *ngFor="let option of protocols" [value]="option.id">{{ option.name }}</mdb-select-option>
                      </mdb-select-2>
                  </div>
                </div>
                <div class="col-md">
                    <div class="md-form">
                      <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Seleccionar" label="Tipo de Acceso"  formControlName="typeprotocolaccess_id">
                        <mdb-select-option *ngFor="let option of access_type" [value]="option.id">{{ option.name }}</mdb-select-option>
                      </mdb-select-2>
                      <mdb-error
                            *ngIf="formProtocol.controls.typeprotocolaccess_id.invalid && (formProtocol.controls.typeprotocolaccess_id.dirty || formProtocol.controls.typeprotocolaccess_id.touched)">
                            Campo obligatorio</mdb-error>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form" formGroupName="access_data">
                    <input mdbInput type="text" id="code_provider" placeholder="" class="form-control form-control-sm" formControlName="code_provider">
                    <label for="code_provider">Código (opcional)</label>
                  </div>
                </div>
            </div>
              <div class="row mt-3" formGroupName="access_data">
                <ng-container *ngIf="typeprotocolaccess == 1 || typeprotocolaccess == 3 || typeprotocolaccess == 4">
                  <div class="col-md">
                    <div class="md-form">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="user" placeholder="" class="form-control form-control-sm" formControlName="user">
                      <label for="user">Usuario</label>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="md-form">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="password" id="password" placeholder="" class="form-control form-control-sm" formControlName="password">
                      <label for="password">Password</label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="typeprotocolaccess == 2 || typeprotocolaccess == 4">
                  <div class="col-md">
                    <div class="md-form">
                      <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Seleccionar" label="Email"  formControlName="mailaccount_id">
                        <mdb-select-option *ngFor="let option of mailaccounts" [value]="option.id">{{ option.mail }}</mdb-select-option>
                      </mdb-select-2>
                    </div>
                  </div>
                </ng-container>
              </div>
            </form>
          </mdb-tab>
        </mdb-tabset>
      </div> -->
<!--    </div>
</div>
-->