import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { ExpenseInterface } from 'src/app/shared/interfaces/data/expense-interface';
import { FirmAddressInterface } from 'src/app/shared/interfaces/data/firm-address-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { ReceiptInterface } from 'src/app/shared/interfaces/data/receipt-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { catchError } from 'rxjs/operators';
@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('expenseTabs', { static: true }) expenseTabs: TabsetComponent;

  public id: number;
  public dropdowndata
  public loading = false;
  public expense: ExpenseInterface;
  public receipts: Array<ReceiptInterface>;


  public firm_addresses: Array<FirmAddressInterface>;


  private formFunctions = new FormFunctions;

  private utils = new PGAUtils;


  private subscriptions: Array<Subscription> = [];
  private unlock_subscription: Subscription;

  private dateformats = new DateFormats;

  public formExpense: FormGroup;

  private lockdata: LockdataInterface;


  public claim_entity = this._entity.entities.claim;


  constructor(private _api: ApirequestService, private _lock: LockService, private _apifunctions: ApiFunctions, private _buttons: ActionButtonsService, private _notification: NotificationService, private _entity: EntityService) { }

  ngOnInit(): void {
    this.id = +this.data.id;
    this.formExpense = this.formFunctions.getForm(this.data.entity);
    this.getData();
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }

    this._api.unlockExit(this.lockdata)
  }

  private getData() {
    this.loading = true;

    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._apifunctions.getDropdowns(['taxe', 'typestate', 'employee', 'typepaymentmethod'])
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns(responses.find(response => response.response.hasOwnProperty('dropdown')).response.dropdown)

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setFirmAddresses(){
    this.loading = true;
    let newurl = new URL(this._api.getAPIEndpoint()+'firmsaddresses');
    let filter_firm = {
      firmaddress: {
        firm_id: this.expense.firm_id
      }
    }

    newurl.searchParams.append('filters', JSON.stringify(filter_firm));
    newurl.searchParams.append('paginate', JSON.stringify(0));

    this.subscriptions.push(
      this._api.get('firmsaddresses'+newurl.search).subscribe(
        response => {
          this.dropdowndata['firm_addresses'] = response.response.firmaddress;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns(dropdowndata: DropdownsInterface) {
    let data = dropdowndata;
    data.employee = data.employee.filter(employee => employee.enabled == true);
    this.dropdowndata = data;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    let expensedata: ExpenseInterface = apiresponse.expense
    this.expense = expensedata;
    this.receipts = expensedata.expensesreceipts;

    this.setFirmAddresses();

    this.tab.tabData.headerData = this.expense.number;

    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }


  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formExpense = undefined;
          setTimeout(() => {
            this.formExpense = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?: boolean) {

    if(block) {
      this.formFunctions.rellenarFormulario(this.formExpense, this.expense);
      this.formExpense.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formExpense, this.expense);
      if(this.expense.type_state_id == 1) {
        this.formExpense.controls.taxe['controls'].id.valueChanges.subscribe(
          val => {
            console.log(val)

            if(this.formExpense.controls.total_base.value >=0) {
              let total_iva = (+(this.formExpense.controls.total_base.value)*this.dropdowndata.taxe.find(t => t.id == val).value)/100;
              this.formExpense.controls.total_taxe.setValue(total_iva.toFixed(2));

              let total_linea = (+this.formExpense.controls.total_base.value) + (+this.formExpense.controls.total_taxe.value);
              this.formExpense.controls.total.setValue(total_linea.toFixed(2));
            } else {
              this.formExpense.controls.total_taxe.setValue(0);
              this.formExpense.controls.total.setValue(0);
            }
        });

        this.formExpense.controls.total_base.valueChanges.subscribe(
          val => {
            if(val >= 0) {

              let total_iva = (+(this.formExpense.controls.total_base.value)*this.dropdowndata.taxe.find(t => t.id == this.formExpense.controls.taxe['controls'].id.value).value)/100;
              this.formExpense.controls.total_taxe.setValue(total_iva.toFixed(2));

              let total_linea = (+this.formExpense.controls.total_base.value) + (+this.formExpense.controls.total_taxe.value);
              this.formExpense.controls.total.setValue(total_linea.toFixed(2));

            } else {
              this.formExpense.controls.total_taxe.setValue(0);
              this.formExpense.controls.total.setValue(0);
            }

          }
        )

        this.subscriptions.push(
          this.formExpense.valueChanges.subscribe(
            change => {
              this.tab.modifiedData = true;
            }
          )
        )

        this.formExpense.controls.taxe['controls'].id.markAsDirty();
        this.formExpense.controls.concept.markAsDirty();
        this.formExpense.controls.total_base.markAsDirty();
        this.formExpense.controls.total_taxe.markAsDirty();
        this.formExpense.controls.total.markAsDirty();
      } else {
        this.formExpense.disable()
      }
    }

  }


  changeSelect(tipo: string, valor) :void{
    this.formExpense.controls[tipo]['controls'].id.setValue(valor);
    this.formExpense.controls[tipo]['controls'].id.markAsDirty();
  }

  disableChanges(): boolean {
    let readonly = false;

    if(!this.lockdata?.unblock) {
      readonly = true;
    }

    return readonly;
  }

  onSubmit() {

    this._buttons.setLoading(true);

    this.formExpense.markAllAsTouched();

    if(this.formExpense.valid) {
      const params = {};

      //Datos Factura
      this.formFunctions.fillFormParams(this.formExpense, this.data.entity, params)

      console.log(params)


      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint+'/'+this.data.id, JSON.stringify(params)).subscribe(
          resp=>{
            this.tab.modifiedData = false;
            this.formExpense.markAsPristine();
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      )
    } else {
      this._buttons.setLoading(false);
    }


  }

}
