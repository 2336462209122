<div class="container-fluid pt-2">
 <!--  <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'"> -->

    <!-- <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos Empresa">
    </mdb-tab> -->
   <!--  <mdb-tab heading="<i class='fas fa-file-certificate'></i> Licencia"> -->
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col">
              <div class="card text-center mt-3">
                <h5 class="card-header grey white-text"><mdb-icon fas icon="file-certificate"></mdb-icon> Licencia</h5>
                <div class="card-body">
                  <!--Card content-->
                  <mdb-card-body class="text-center">

                    <div class="list-group list-panel">
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">
                          Tipo <span class="ml-auto">{{license_data.type_description}}<mdb-icon fas icon="file-certificate" class="ml-1"></mdb-icon></span>
                        </a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text">
                          Usuarios <span class="ml-auto">{{license_data.number_users}}<mdb-icon fas icon="user" class="ml-1"></mdb-icon></span>
                        </a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text" *ngIf="license_data.number_claims > 0">
                          {{claim_entity.labels.list| titlecase}} <span class="ml-auto">{{license_data.number_claims}}<mdb-icon fas icon="folders" class="ml-1"></mdb-icon></span>
                        </a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text" *ngIf="license_data.number_workorders > 0">
                          {{workorder_entity.labels.list | titlecase}} <span class="ml-auto">{{license_data.number_workorders}}<mdb-icon fas icon="file-alt" class="ml-1"></mdb-icon></span>
                        </a>

                    </div>
                </mdb-card-body>
                <!-- <mdb-card-footer>
                  <a (click)="openBuyingTab()">
                    Cambiar Licencia
                  </a>
                </mdb-card-footer> -->
                <!--/.Card content-->
                </div>
                <div class="card-footer">
                  <a (click)="openBuyingTab()">
                    Cambiar
                  </a>
                </div>
              </div>
            </div>
          </div>
           <div class="row">
            <div class="col">

              <div class="card text-center mt-3">
                <h5 class="card-header grey white-text"><mdb-icon fas icon="puzzle-piece"></mdb-icon> Módulos</h5>
                <div class="card-body">

                  <mdb-card-body class="text-center">

                    <mdb-card-text>
                      Contratados
                    </mdb-card-text>
                    <div class="list-group list-panel">
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text" *appModules="['companies']">
                          Compañías <span class="ml-auto"><mdb-icon fas icon="building" class="ml-1"></mdb-icon></span>
                        </a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text" *appModules="['billing']">
                          Facturación <span class="ml-auto"><mdb-icon fas icon="file-invoice-dollar" class="ml-1"></mdb-icon></span>
                        </a>
                        <a href="#" class="list-group-item d-flex justify-content-between dark-grey-text" *appModules="['mail']">
                          Correo electrónico <span class="ml-auto"><mdb-icon fas icon="at" class="ml-1"></mdb-icon></span>
                        </a>
                    </div>

                </mdb-card-body>
                </div>
                <div class="card-footer">
                  <a (click)="openBuyingTab()">
                    Cambiar
                  </a>
                </div>
              </div>
            </div>
           </div>


        </div>
        <div class="col-md">
          <app-license-counters [license_data]="license_data"></app-license-counters>
        </div>

      </div>

      <hr>

      <div class="row">
        <div class="col-md-4">
        </div>

      </div>









    <!-- </mdb-tab>
    <mdb-tab heading="<i class='fas fa-puzzle-piece'></i> Módulos">

    </mdb-tab>
  </mdb-tabset> -->
</div>
