import { Injectable } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { UnblockModalComponent } from '../components/dynamic-modals/unblock-modal/unblock-modal.component';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LockService {

  confirmUnblock = new Subject<LockdataInterface | null>();

  modalRefLock: MDBModalRef | null = null;
  constructor(private modalService: MDBModalService, private _user: UserService) { }

  fillLockdata(apilockdata): LockdataInterface {
    let lockdata: LockdataInterface = {
      id: apilockdata.id,
      register_id: apilockdata.register_id,
      table: apilockdata.table,
      username: apilockdata.user.name+' '+apilockdata.user.surname,
      unblock : true
    }

    if(apilockdata.user.email !== this._user.getUserData()?.email) {
      lockdata.unblock = false;
    }

    return lockdata;
  }

  showLockModal(lockdata: LockdataInterface){
    this.modalRefLock = this.modalService.show(UnblockModalComponent, {
      class: 'modal-lg',
      keyboard: false,
      ignoreBackdropClick: true,
      data: {
        text: 'El registro se encuentra bloqueado por el usuario '+lockdata.username+': ¿Desea desbloquearlo?',
        emit_unblock: false,
        lockdata: lockdata
      }
    });

    this.modalRefLock.content.unblockConfirm.subscribe(
      response => {
        this.confirmUnblock.next(response)
      }
    )
  }
}
