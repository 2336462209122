<app-loader *ngIf="loading"></app-loader>


<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
<div style="margin: 10px;">
    <form [formGroup]="formUser">
      <div class="row">
        <div class="col-lg">
          <div class="md-form md-outline">
              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="username"
                  formControlName="name" class="form-control form-control-sm" placeholder="">
              <label for="username" class="">Nombre</label>
              <mdb-error
                  *ngIf="!formUser.controls.name.valid && (formUser.controls.name.dirty || formUser.controls.name.touched)">
                  Campo obligatorio</mdb-error>
          </div>
        </div>
        <div class="col-lg">
            <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="usersurname"
                    formControlName="surname" class="form-control form-control-sm"  placeholder="">
                <label for="usersurname" class="">Apellidos</label>
                <mdb-error
                  *ngIf="!formUser.controls.surname.valid && (formUser.controls.surname.dirty || formUser.controls.surname.touched)">
                  Campo obligatorio</mdb-error>
            </div>
        </div>
        <div class="col-lg">
          <div class="md-form  md-outline">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email"
              class="form-control form-control-sm" placeholder="">
            <label for="email" class="">Email</label>
            <!-- <mdb-error *ngIf="formUser.controls.email.invalid && formUser.controls.email.errors.data_exists">El email introducido ya está en uso</mdb-error> -->
              <mdb-error *ngIf="formUser.controls.email.invalid && (formUser.controls.email.dirty || formUser.controls.email.touched)">
                <ng-container *ngFor="let error of formUser.controls.email.errors | keyvalue">
                  <ng-container [ngSwitch]="error.key">
                    <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                    <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                    <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>

                  </ng-container>
                </ng-container>

              </mdb-error>
          </div>
        </div>
        <div class="col-lg">
          <div class="row">
            <div class="col-9">
              <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" [type]="view_pass ? 'text' : 'password'" id="password" class="form-control custom-input-group" formControlName="password" placeholder="" aria-label="Contraseña">
                <label for="password">Contraseña</label>
              </div>
            </div>
            <div class="col-3">
              <div class="input-group-append">
                <button mdbBtn color="light" (click)="view_pass = !view_pass" size="sm"  type="button" id="button-addon2">
                  <mdb-icon fad [icon]="view_pass ? 'eye-slash' : 'eye'"></mdb-icon>
                </button>
              </div>
            </div>
          </div>
          <mdb-error *ngIf="formUser.controls.password.invalid && (formUser.controls.password.dirty || formUser.controls.password.touched)">Campo Obligatorio</mdb-error>
        </div>
      </div>
    </form>
  </div>

  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Empresas
  </div>
  <!--EMPRESAS-->
  <div style="margin: 10px;">
    <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
      <thead>
          <tr>
              <th scope="col" class="buttons-column">Acceso</th>
              <th scope="col">Empresa</th>
              <th scope="col" class="medium-column">Rol</th>
          </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let formFirm of formFirmRoles.controls; let i=index">
          <ng-container [formGroup]="formFirm">
            <tr mdbTableCol>
              <td class="buttons-column">
                <mdb-checkbox default="true" formControlName="is_enabled"></mdb-checkbox>
              </td>
              <td>
                <span>{{formFirm.controls.name.value}}</span>
              </td>
              <td>
                <select class="browser-default custom-select cell-select" formControlName="id_role">
                  <option *ngFor="let option of roles" [value]="option.value">{{ option.label }}</option>
                </select>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="divCustomSection">
    <i class='fas fa-envelope mr-2'></i> Cuentas de correo
  </div>
  <!--Cuentas de correo-->
  <div style="margin: 10px;">
    <div class="row">
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="tasks" size="lg" class="mr-1 text-primary"></mdb-icon>Cuentas de correo vinculadas al usuario
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Cuenta de correo</th>
                  <th scope="col" class="buttons-column">Pred.</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let account of linked_accounts; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="removeAccount(account)">
                        <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{account.mail}}</span>
                    </td>
                    <td  class="buttons-column">
                      <mdb-checkbox [default]="true" [checked]="account.default" (change)="onChangeDefault($event, account)"></mdb-checkbox>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="list-ul" size="lg" class="mr-1 text-primary"></mdb-icon>Cuentas de correo disponibles
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Cuenta de correo</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let account of available_accounts; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="addAccount(account)">
                        <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{account.mail}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </div>
</div>
<!--
<div class="container-fluid pt-2">

    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'<i class=\'fas fa-info-circle\'></i> Datos del nuevo '+data.entity_label">
            <form [formGroup]="formUser">
              <div class="row">
                <div class="col-lg">
                  <div class="md-form">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="username"
                          formControlName="name" class="form-control form-control-sm" placeholder="">
                      <label for="username" class="">Nombre</label>
                      <mdb-error
                          *ngIf="!formUser.controls.name.valid && (formUser.controls.name.dirty || formUser.controls.name.touched)">
                          Campo obligatorio</mdb-error>
                  </div>
                </div>
                <div class="col-lg">
                    <div class="md-form">
                        <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="usersurname"
                            formControlName="surname" class="form-control form-control-sm"  placeholder="">
                        <label for="usersurname" class="">Apellidos</label>
                        <mdb-error
                          *ngIf="!formUser.controls.surname.valid && (formUser.controls.surname.dirty || formUser.controls.surname.touched)">
                          Campo obligatorio</mdb-error>
                    </div>
                </div>
                <div class="col-lg">
                  <div class="md-form">
                    <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email"
                      class="form-control form-control-sm" placeholder="">
                    <label for="email" class="">Email</label>
                    <!-- <mdb-error *ngIf="formUser.controls.email.invalid && formUser.controls.email.errors.data_exists">El email introducido ya está en uso</mdb-error> -->
           <!--           <mdb-error *ngIf="formUser.controls.email.invalid && (formUser.controls.email.dirty || formUser.controls.email.touched)">
                        <ng-container *ngFor="let error of formUser.controls.email.errors | keyvalue">
                          <ng-container [ngSwitch]="error.key">
                            <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                            <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                            <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>

                          </ng-container>
                        </ng-container>

                      </mdb-error>
                  </div>
                </div>
                <div class="col-lg">
                  <div class="md-form input-group mb-3">
                    <input mdbInput mdbValidate [validateSuccess]="false" [type]="view_pass ? 'text' : 'password'" id="password" class="form-control custom-input-group" formControlName="password" placeholder="" aria-label="Contraseña">
                    <label for="password">Contraseña</label>
                    <div class="input-group-append">
                      <button mdbBtn color="light" (click)="view_pass = !view_pass" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2">
                        <mdb-icon fad [icon]="view_pass ? 'eye-slash' : 'eye'"></mdb-icon>
                      </button>
                    </div>
                    <mdb-error *ngIf="formUser.controls.password.invalid && (formUser.controls.password.dirty || formUser.controls.password.touched)">
                      Campo Obligatorio
                    </mdb-error>
                  </div>
                </div>
              </div>
            </form>
            <div class="row mt-3">
              <div class="col-md-5">
                <mdb-card class="z-depth-0">
                  <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                      <mdb-icon fad icon="home-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Acceso Empresas
                  </mdb-card-header>
                  <mdb-card-body>
                    <table mdbTable small="true">
                      <thead>
                          <tr>
                              <th scope="col">Acceso</th>
                              <th scope="col">Empresa</th>
                              <th scope="col">Rol</th>
                          </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let formFirm of formFirmRoles.controls; let i=index">
                          <ng-container [formGroup]="formFirm">
                            <tr mdbTableCol>
                              <td>
                                <mdb-checkbox default="true" formControlName="is_enabled"></mdb-checkbox>
                              </td>
                              <td>
                                {{formFirm.controls.name.value}}
                              </td>
                              <td>
                                <select class="browser-default custom-select cell-select" formControlName="id_role">
                                  <option *ngFor="let option of roles" [value]="option.value">{{ option.label }}</option>
                                </select>
                              </td>
                              <td>
                            </tr>
                          </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </mdb-card-body>
                </mdb-card>
              </div>

              <div class="col-md">
                <mdb-card class="z-depth-0">
                  <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                      <mdb-icon fad
                      icon="at"
                      size="md"
                      class="mr-1 text-primary"></mdb-icon> Cuentas de Correo
                  </mdb-card-header>
                  <mdb-card-body>
                    <div class="row">
                      <div class="col-6">
                        <mdb-card class="z-depth-0">
                          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                              Vinculadas
                          </mdb-card-header>
                          <mdb-card-body class="scroll-card-body">
                            <table mdbTable small="true">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Cuenta</th>
                                  <th scope="col">Predet.</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let account of linked_accounts; let i=index">
                                  <tr mdbTableCol>
                                    <td>
                                      <a (click)="removeAccount(account)">
                                        <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                                      </a>
                                    </td>
                                    <td>
                                      {{account.mail}}
                                    </td>
                                    <td>
                                      <mdb-checkbox [default]="true" [checked]="account.default" (change)="onChangeDefault($event, account)"></mdb-checkbox>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                      <div class="col-6">
                        <mdb-card class="z-depth-0">
                          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                              Disponibles
                          </mdb-card-header>
                          <mdb-card-body class="scroll-card-body">
                            <table mdbTable small="true">
                              <thead>
                                <!-- <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Cuenta</th>
                                </tr> -->
    <!--                          </thead>
                              <tbody>
                                <ng-container *ngFor="let account of available_accounts; let i=index">
                                  <tr mdbTableCol>
                                    <td>
                                      <a (click)="addAccount(account)">
                                        <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                                      </a>
                                    </td>
                                    <td>
                                      {{account.mail}}
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </mdb-card-body>
                        </mdb-card>
                      </div>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
</div>
-->