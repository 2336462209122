import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { FirmInterface } from 'src/app/shared/interfaces/data/firm-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { UserInterface } from 'src/app/shared/interfaces/data/user-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { FirmsService } from '../../services/firms.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { UserPermissionsService } from '../../services/user-permissions.service';
import { UserService } from '../../services/user.service';
import { Tab } from '../../tab-models/tab.model';
import { MDBModalRef, MDBModalService, TabsetComponent } from 'ng-uikit-pro-standard';
import { EntityService } from '../../services/entity.service';
import { UnblockModalComponent } from '../../components/dynamic-modals/unblock-modal/unblock-modal.component';
import { catchError, map } from 'rxjs/operators';
import { AddressesParamsInterface } from 'src/app/shared/interfaces/component-params/addresses-params-interface';
import { GoogleMapsModule, MapGeocoder } from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { element } from 'screenfull';
import { GmapsComponentComponent } from '../../components/gmaps-component/gmaps-component.component';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss']
})
export class FirmComponent implements OnInit, OnDestroy {
  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('firmTabs', { static: true }) firmTabs: TabsetComponent;
  @ViewChild('maps', {static: false}) maps: GmapsComponentComponent;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  public firm: FirmInterface;

  public loading: boolean = false;

  public formFirm: FormGroup;
  public formEntities: FormGroup;

  public id: number;
  private subscriptions: Array<Subscription> = [];

  public addresses: Array<any>;
  public users: Array<any>;
  public available_users: Array<UserInterface>

  public dropdowndata: DropdownsInterface;


  options = [
    { value: 3, label: 'Tramitador'},
    { value: 2, label: 'Gerente' },
  ];

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  public firm_addresses_params: AddressesParamsInterface = {
    addresses: [],
    parent_id: null,
    address_entity: 'firmaddress',
    parent_id_field: 'firm_id',
    entity_endpoint: 'firmsaddresses',
  }

  public user_role: number;
  private user_id: number;

  private is_modified: boolean = false;

  public whatsapptemplates: Array<any>;


  public readonly entities;
  public readonly default_entities;
  modalRefUnblock: MDBModalRef | null = null;

  ///formgroup de las opciones de empresa
  public formOptions: FormGroup;
  ///variable array con las opciones de empresa que devuelve el API
  public firmOptions: Array<any>;

  /**
   * constructor de la clase con los diferentes servicios a instanciar
   * @param _appconfig
   * @param _mapGeocoder
   * @param _api
   * @param _lock
   * @param _apifunctions
   * @param _buttons
   * @param _notification
   * @param _firms
   * @param _userpermissions
   * @param _user
   * @param fb
   * @param _entity
   * @param modalService
   */
  constructor(private _api: ApirequestService, private _lock: LockService, private _dropdowns: DropdownService,private _buttons: ActionButtonsService, private _notification: NotificationService, private _firms: FirmsService, private _userpermissions: UserPermissionsService, private _user: UserService, private fb: FormBuilder,private _entity: EntityService, private modalService: MDBModalService)
  {
    this.user_role = this._user.getUserData().role_id;
    this.user_id = this._user.getUserData().id;
    this.formFirm = this.formFunctions.getForm('firm');
    this.entities = this._entity.entities;
    this.default_entities = this._entity.default_entities.filter(
      entity => !entity.readonly
    );
    console.log(this.entities)
    this.setEntitiesForm();

    let pattern ="^[0-9]+";

    this.formOptions = this.fb.group({
      firm_general_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],
      firm_claims_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],
      firm_invoices_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],
      firm_invoicesproforma_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],
      firm_budgets_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],
      firm_workorders_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],
      firm_deliverynotes_history_date_filter: [12, [Validators.required, Validators.min(1), Validators.pattern(pattern)]],

    });
  }


  setEntitiesForm()
  {
    this.formEntities = this.fb.group({})
    this.default_entities.forEach(
      entity => {

        let form_entity = this.fb.group({
          list: [entity.list],
          form: [entity.form],
          route: [entity.route]
        })
        this.formEntities.addControl(entity.model, form_entity);
      }
    )
    //this.formEntities.disable();
    console.log(this.formEntities)
  }

  unsubscribeAll()
  {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnDestroy(): void
  {
    this.subscriptions.forEach(e => e.unsubscribe());

    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void
  {
    this.id = this.data.id;
    this.firm_addresses_params.parent_id = this.id;

    this.getData();
  }

  disableChanges(): boolean
  {
    let readonly = false;
    if(!this._userpermissions.checkPermission('modify_firms') || !this.lockdata?.unblock) {
      readonly = true;
    }

    return readonly;
  }

  private setTemplatesParams(): string
  {
    let newurl = new URL(this._api.getAPIEndpoint()+ 'whatsappstemplates')

    const order = {
      field:'uid',
      type:'ASC',
    }
    newurl.searchParams.append('order', JSON.stringify(order))
    newurl.searchParams.append('paginate', JSON.stringify(0))

    const filters: {[key: string]:any} = {
      'whatsapptemplate': {
        firm_id: this.id
      }
    }
    newurl.searchParams.append('filters', JSON.stringify(filters))

    return 'whatsappstemplates'+newurl.search;
   }

  private getData()
  {
    this.loading = true;
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.setTemplatesParams()),
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          console.log(responses)

          let templates_response = responses[0];
          this.whatsapptemplates = templates_response.response.whatsapptemplate;

          this.checkLock(responses.find(response => response.response.hasOwnProperty('firm')).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns()
  {
    this.dropdowndata = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    this.dropdowndata.tramit = this.dropdowndata?.tramit.filter(
      tramit => tramit.email !== this._user.getUserData().email
    );
    this.setUsers()
  }

  private checkLock(apiresponse: any): void
  {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.firm = apiresponse.firm;
    this.addresses = this.firm.firmaddress;
    this.firmOptions = this.firm.firmoption;

    this.users = this.firm.firmusers.map(
      data => {
        let form = new FormControl({value: data.role_id, disabled: this.disableChanges() || !this._userpermissions.checkPermission('modify_firm_users') });

        if(this.user_role == 3 && data.user.id == this.user_id)
        {
          if(form.enabled) {
            form.disable();
          }
        }

        form.valueChanges.subscribe(() => this.tab.modifiedData = true);

        return {
          user: data.user,
          role_id: data.role_id,
          roleform: form
        }
      }
    );

    this.firm.translationsmodels.forEach(
      model => {
        if(this.formEntities.controls[model.model]) {
          this.formEntities.controls[model.model]['controls'].list.setValue(model.list);
          this.formEntities.controls[model.model]['controls'].form.setValue(model.form);
        }
      }
    )

    this.firm_addresses_params.addresses = this.addresses;
    this.tab.tabData.headerData = this.firm.name;
    this.fillFirmOptions();

    if(this.addresses?.length > 0)
    {
      let defaultAddressModel = this.addresses.find(key => key.default == true);
      if(defaultAddressModel.address_complete != '')
      {
        this.LoadAddressesOnMap(this.addresses);
      }
    }

    if(!this.lockdata.unblock)
    {
      this.tab.tabLock = true;

      if(this.tab.tabActive)
      {
        this.openLockModal()
      }
    }
    else
    {
      this.setFormData()
    }
  }

  /**
   * funcion para pasar las direcciones al mapa y marcar la posicion predeterminada
   * @param addresses
   *
   */
  public LoadAddressesOnMap(addresses)
  {
    this.maps.MarkerDefaultPositionInMap(addresses);
  }

  public openLockModal()
  {
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata)
        {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formFirm = undefined;
          setTimeout(() => {
            this.formFirm = this.formFunctions.getForm('firm');
            this.setFormData();
          },5)
        }
        else
        {
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean)
  {
    if(block)
    {
      this.formFunctions.rellenarFormulario(this.formFirm, this.firm);
      this.formFirm.disable();
    }
    else
    {
      this.formFunctions.rellenarFormulario(this.formFirm, this.firm);

      if(this.disableChanges())
      {
        this.formFirm.disable();
      }
      else
      {
        this.subscriptions.push(
          this.formFirm.valueChanges.subscribe(
            change =>
            {
              this.tab.modifiedData = true;
            }
          )
        );

        this.subscriptions.push(
          this.formEntities.valueChanges.subscribe(
            change =>
            {
              this.tab.modifiedData = true;
            }
          )
        );

        this.subscriptions.push(
          this.formOptions.valueChanges.subscribe(
            change =>
            {
              this.tab.modifiedData = true;
            }
          )
        );
      }
    }
  }


  setUsers()
  {
    this.available_users = this.dropdowndata?.tramit.filter(
      user => {
        if(!this.users.find(mail_user => mail_user.user.id == user.id)) {
          return true
        }
      }
    );

  }

  removeUser(user: UserInterface)
  {
    this.users.splice(this.users.findIndex(u=> u.user.id == user.id), 1);
    this.setUsers()
    this.tab.modifiedData = true;
  }

  addUser(user: UserInterface)
  {
    let form = new FormControl({value: 3, disabled: this.disableChanges() || !this._userpermissions.checkPermission('modify_firm_users')});
    if(this.user_role == 3 && user.id == this.user_id) {
      if(form.enabled) {
        form.disable();
      }
    }
    this.users.push({user: user, role_id: 3 ,roleform: form});
    this.setUsers()
    this.tab.modifiedData = true;

  }

  private usersModified(): boolean
  {
    let modified = false;

    if(this.users.length !== this.firm.firmusers.length) {
      modified = true;
    } else {


      for (let index = 0; index < this.firm.firmusers.length; index++)
      {
        let user = this.firm.firmusers[index];
        if(!this.users.find(u => u.user.id == user.user.id))
        {
          modified = true;
          break;
        }
        else
        {
          let a = this.users.find(u => u.user.id === user.user.id);
          if(a?.roleform.dirty)
          {
            if(a.roleform.value != a.role_id)
            {
              modified = true;
            }
          }
        }
      }
    }
    return modified;
  }


  getTemplates()
  {
    this.loading = true;
    this.subscriptions.push(
      this._api.get(this.setTemplatesParams()).subscribe(
        response => {
          this.whatsapptemplates = response.response.whatsapptemplate;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  /**
   * Funcion para rellenar los valores de firmoptions en los input de la parte visual
   */
  private fillFirmOptions()
  {
    Object.keys(this.formOptions.controls).forEach(
      controlname =>
      {
        let option = this.firmOptions.find(item => item.option.name === controlname);
        if(option)
        {
          this.formOptions.controls[controlname].setValue(option.value)
        }
      }
    );
  }

  /**
   * Funcion para guardar los datos de empresa y sus pestañas si tienen cambios
   */
  public onSubmit()
  {
    this._buttons.setLoading(true);
    let apicalls = [];
    let requestUriFirm = this.GenerateFirmRequest();
    let requestUriTranslations = this.GenerateTranslationsRequest();
    let requestUriOptionsFirm = this.GenerateOptionsFirmRequest();

    if(requestUriFirm)
    {
      apicalls.push(requestUriFirm);
    }

    if(requestUriTranslations)
    {
      apicalls.push(requestUriTranslations);
    }

    if(requestUriOptionsFirm)
    {
      requestUriOptionsFirm.forEach(uriRequestOption =>
      {
        apicalls.push(uriRequestOption);
      });
    }

    forkJoin(
      apicalls
    )
    .pipe(
      catchError(error => {
        this._buttons.setLoading(false);
        return throwError(error)
      })
    )
    .subscribe(
      responses => {
        //SOLO PARA RESPUESTA INDIVIDUALES POR CADA PETICION
      },
      error => {
        this._buttons.setLoading(false);
      },
      () =>
      {
        this.tab.modifiedData = false;
        this.formFirm.markAsPristine();
        this.unsubscribeAll();
        this._buttons.reloadListTab(this.data.saveEndpoint);
        //this._buttons.setLoading(false);
        location.reload();
      }
    )
  }

  /**
   * Funcion para generar la url de peticion para update en empresa
   * @returns
   */
  private GenerateFirmRequest()
  {
    try
    {
      this.formFirm.markAllAsTouched();

      if(this.formFirm.valid)
      {
        this.formFirm.controls.name.markAsDirty();
        const params ={};
        this.formFunctions.fillFormParams(this.formFirm, this.data.entity, params);

        //users
        if(this.usersModified())
        {
          params['firmusers']=this.users.filter(u=>
          {
            if(this.user_role <3)
            {
              return true;
            }
            else
            {
              if(u.role_id == 3)
              {
                return true;
              }
            }
          }).map(u => {
            return {
              user_id: u.user.id,
              role_id: +u.roleform.getRawValue()
            }
          });
        }
        return this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params));
      }
    }
    catch
    {
      return null;
    }
  }

  /**
   *
   * @returns Funcion para generar un observable de la peticion post de traducciones
   */
  private GenerateTranslationsRequest()
  {
    try
    {
      this.formEntities.markAllAsTouched();

      if(this.formEntities.valid && this.formEntities.dirty)
      {
        const params:{translationmodel: Array<any>} =
        {
          translationmodel:[]
        }

        Object.keys(this.formEntities.controls).forEach(
          control => {
            if(this.formEntities.controls[control].dirty)
            {
              let formvalues = this.formEntities.controls[control].value;
              params.translationmodel.push({
                model: control,
                route: formvalues.route,
                list: formvalues.list,
                form: formvalues.form,
                firm_id: this.id
              })
            }
          }
        )
        return this._api.post('translationsmodels/storeupdatelist', JSON.stringify(params));
      }
    }
    catch
    {
      return null;
    }
  }

  /**
   * Funcion para guardar la pestaña opciones de empresa
   */
  private GenerateOptionsFirmRequest()
  {
    this.formOptions.markAllAsTouched();

    if(this.formOptions.valid && this.formOptions.dirty)
    {
      const params = {};
      let apicalls: Array<Observable<any>> = [];

      Object.keys(this.formOptions.controls).forEach(control =>
      {
        if(this.formOptions.controls[control].dirty)
        {
          let firmOption = this.firmOptions.find(item => item.option.name === control);

          params['firmoption']=
          {
            model: 'firmoption',
            value: this.formOptions.controls[control].value,
            option_id: firmOption.option_id,
            firm_id: this.id
          };

          apicalls.push(this._api.put('firmsoptions/'+firmOption.id, JSON.stringify(params)))
        }
      })

      return apicalls;
    }
    else
    {
      return null;
    }
  }
}
