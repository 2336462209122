import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ActionButtonsService } from 'src/app/main/services/action-buttons.service';
import { NotificationService } from 'src/app/main/services/notification.service';

@Component({
  selector: 'app-guild-form',
  templateUrl: './guild-form.component.html',
  styleUrls: ['./guild-form.component.scss']
})
export class GuildFormComponent implements OnInit {
  header: string;
  register:any;
  entity: string
  endpoint: string

  private subscriptions: Array<Subscription> = [];

  public formGuild: FormGroup;

  private id: number|null


  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _notification: NotificationService, private fb: FormBuilder, private _buttons: ActionButtonsService) {

  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(){
    this.formGuild = this.fb.group({
      name:['', Validators.required],
      enabled:[false],
    })

    if(this.register) {
      this.id = this.register.id;

      Object.keys(this.formGuild.controls).forEach(k => this.formGuild.controls[k].setValue(this.register[k]))

    }
  }

  submit(){
    if(this.formGuild.dirty) {
      this.formGuild.markAllAsTouched()
      if(this.formGuild.valid) {
        this._buttons.setLoading(true);

        const params = {
          [this.entity]: this.formGuild.value
        }

        let call:Observable<any>;

        if(this.register) {
          call = this._api.put(this.endpoint+'/'+this.register.id, JSON.stringify(params))
        } else {
          call = this._api.post(this.endpoint, JSON.stringify(params))
        }

        this.subscriptions.push(
          call.subscribe(
            response => {
              let msg = '';
              if(this.register) {
                msg = this._notification.getUpdateMsg();
              } else {
                msg = this._notification.getInsertMsg();

              }
              this._notification.notificacionOK(msg);
              this._buttons.reloadListTab(this.endpoint);
              this._buttons.setLoading(false);
              this.modalRef.hide();
            },
            error => {
              this._buttons.setLoading(false);
            }
          )
        )
      }
    } else {
      this.modalRef.hide();
    }
  }

  onClosed() {
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
    this.header = null;

  }

}
