import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdowns',
  templateUrl: './dropdowns.component.html',
  styleUrls: ['./dropdowns.component.scss']
})
export class DropdownsComponent implements OnInit {
  @Input() init_value;
  @Input() label;
  @Input() activefilter = false;
  @Input() dropdowndata;
  @Input() type;
  @Input() filtro;
  @Input() disabled;
  @Input() label_field: string = 'name';
  @Input() addFirstRegister;
  @Input() outline: boolean = false;
  @Input() appendToBody: boolean = false;

  @Input() dependent;
  @Input() depend_value;

  @Output() selectedValue = new EventEmitter<number>();
  @Output() change = new EventEmitter<number>();

  selectorOpciones: Array<any>;

  activadofiltro = true;

  subscriptions: Subscription;
  idserie;
  obj_resultado = [];


  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(cambios: SimpleChanges) {


    if(this.dropdowndata) {
      this.seleccionarCombo();
    } else
    {
      if(cambios.dropdowndata && !cambios.dropdowndata.firstChange) {
        this.seleccionarCombo();
      }
    }
  }

  seleccionarCombo(){
    //if(this.type !== 'taxe' && this.type !=='typecondition'){
    if(this.addFirstRegister){
      if(this.filtro) {
        this.selectorOpciones = [{value: -1, label: 'TODOS'}];
      } else {
        this.selectorOpciones = [{value: -1, label: 'Sin Asignar'}];
      }
    } else {
      this.selectorOpciones = [];
    }

    this.cargarDatos();

  }

  cargarDatos() {
    for (let index = 0; index < this.dropdowndata[this.type].length; index++) {
      this.selectorOpciones = [...this.selectorOpciones, {value: +this.dropdowndata[this.type][index].id, label: this.dropdowndata[this.type][index][this.label_field]}]
    }

  }

  getSelectedValue(valor) {
    this.selectedValue.emit(valor);

    /* if(this.filtro || this.type == 'tipoexp' || this.type == 'companias') {
      this.change.emit(valor);
    } */
  }

  ngOnDestroy(){
    if(this.subscriptions!=null) {
      this.subscriptions.unsubscribe();
    }

  }

}
