import { Injectable } from '@angular/core';
import { EntityPermissionsInterface } from 'src/app/shared/interfaces/entity-permissions/entity-permissions-interface';
import { EntityDataInterface } from 'src/app/shared/interfaces/entity-service/entity-data-interface';
import { Tab } from '../tab-models/tab.model';
import { TitleCasePipe } from '@angular/common';
import { ActionModel, AdministratorModel, AgentModel, BudgetModel, ClaimModel, ClientModel, CompanyModel, ComunicationProtocolModel, DeliveryNoteModel, EmailModel, EmployeeModel, ExpenseModel, ExpertModel, FirmModel, GuildModel, InvoiceModel, InvoiceproformaModel, LiquidationModel, MailAccountModel, RepairmanModel, ScaleModel, SerieModel, TaskModel, TaxeModel, UserModel, WorkorderModel } from 'src/app/shared/models/entities/index';
import { NewClaimComponent } from '../forms/new-claim/new-claim.component';
import { ClaimComponent } from '../forms/claim/claim.component';
import { WorkorderComponent } from '../forms/workorder/workorder.component';
import { NewWorkorderComponent } from '../forms/new-workorder/new-workorder.component';
import { InvoiceComponent } from '../forms/invoice/invoice.component';
import { InvoiceproformaComponent } from '../forms/invoiceproforma/invoiceproforma.component';
import { ClientComponent } from '../forms/client/client.component';
import { NewClientComponent } from '../forms/new-client/new-client.component';
import { NewInvoiceComponent } from '../forms/new-invoice/new-invoice.component';
import { NewInvoiceproformaComponent } from '../forms/new-invoiceproforma/new-invoiceproforma.component';
import { EmployeeComponent } from '../forms/employee/employee.component';
import { NewEmployeeComponent } from '../forms/new-employee/new-employee.component';
import { NewAgentComponent } from '../forms/new-agent/new-agent.component';
import { AgentComponent } from '../forms/agent/agent.component';
import { ExpertComponent } from '../forms/expert/expert.component';
import { NewExpertComponent } from '../forms/new-expert/new-expert.component';
import { BudgetComponent } from '../forms/budget/budget.component';
import { NewBudgetComponent } from '../forms/new-budget/new-budget.component';
import { NewDeliverynoteComponent } from '../forms/new-deliverynote/new-deliverynote.component';
import { DeliverynoteComponent } from '../forms/deliverynote/deliverynote.component';
import { ExpenseComponent } from '../forms/expense/expense.component';
import { NewExpenseComponent } from '../forms/new-expense/new-expense.component';
import { LiquidationComponent } from '../forms/liquidation/liquidation.component';
import { NewLiquidationComponent } from '../forms/new-liquidation/new-liquidation.component';
import { UserComponent } from '../forms/user/user.component';
import { NewUserComponent } from '../forms/new-user/new-user.component';
import { NewFirmComponent } from '../forms/new-firm/new-firm.component';
import { FirmComponent } from '../forms/firm/firm.component';
import { ScaleComponent } from '../forms/scale/scale.component';
import { NewScaleComponent } from '../forms/new-scale/new-scale.component';
import { NewMailAccountComponent } from '../forms/new-mail-account/new-mail-account.component';
import { MailAccountComponent } from '../forms/mail-account/mail-account.component';
import { CompanyComponent } from '../forms/company/company.component';
import { RepairmanComponent } from '../forms/repairman/repairman.component';
import { NewCompanyComponent } from '../forms/new-company/new-company.component';
import { NewRepairmanComponent } from '../forms/new-repairman/new-repairman.component';
import { NewAdministratorComponent } from '../forms/new-administrator/new-administrator.component';
import { AdministratorComponent } from '../forms/administrator/administrator.component';
import { DropdownService } from './dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private models:{[key:string]: any} = {};

  private default_models_data: {[key:string]: any} = [
    {
      model: 'firm',
      route: 'firms',
      form:'empresa',
      list:'empresas',
      icon: new FirmModel().model_icon,
    },
    {
      model: 'user',
      route: 'users',
      form:'usuario',
      list:'usuarios',
      icon: new UserModel().model_icon,
      readonly: false
    },
    {
      model: 'mailaccount',
      route: 'mailaccounts',
      form:'cuenta correo',
      list:'cuentas de correo',
      icon: new MailAccountModel().model_icon,
      readonly: false
    },
    {
      model: 'guild',
      route: 'guilds',
      form: 'gremio',
      list: 'gremios',
      icon: new GuildModel().model_icon,
    },
    {
      model: 'serie',
      route: 'series',
      form: 'serie',
      list: 'series',
      icon: new SerieModel().model_icon,
    },
    {
      model: 'taxe',
      route: 'taxes',
      form: 'impuesto',
      list: 'impuestos',
      icon: new TaxeModel().model_icon,
      readonly: true
    },
    {
      model: 'client',
      route: 'clients',
      form:'cliente',
      list:'clientes',
      icon: new ClientModel().model_icon,
    },
    {
      model: 'employee',
      route: 'employees',
      form:'empleado',
      list:'empleados',
      icon: new EmployeeModel().model_icon,
    },
    {
      model: 'company',
      route: 'providers',
      model_type: 1,
      form:'compañía',
      list:'compañías',
      icon: new CompanyModel().model_icon,
      readonly: true
    },
    {
      model: 'repairman',
      route: 'providers',
      model_type: 2,
      form:'reparador',
      list:'reparadores',
      icon: new RepairmanModel().model_icon,
      readonly: true
    },
    {
      model: 'administrator',
      route: 'providers',
      model_type: 3,
      form:'administrador fincas',
      list:'administradores fincas',
      icon: new AdministratorModel().model_icon,
      readonly: true
    },
    {
      model: 'agent',
      route: 'agents',
      form:'agente',
      list:'agentes',
      icon: new AgentModel().model_icon,
    },
    {
      model: 'expert',
      route: 'experts',
      form:'perito',
      list:'peritos',
      icon: new ExpertModel().model_icon,
    },
    {
      model: 'scale',
      route: 'scales',
      form:'baremo',
      list:'baremos',
      icon: new ScaleModel().model_icon,
    },
    {
      model: 'claim',
      route: 'claims',
      form:'expediente',
      list:'expedientes',
      icon: new ClaimModel().model_icon,
    },
    {
      model: 'workorder',
      route: 'workorders',
      form:'parte',
      list:'partes',
      icon: new WorkorderModel().model_icon,
    },
    {
      model: 'invoice',
      route: 'invoices',
      form:'factura',
      list:'facturas',
      icon: new InvoiceModel().model_icon,
    },
    {
      model: 'invoiceproforma',
      route: 'invoicesproforma',
      form:'f. proforma',
      list:'facturas proforma',
      icon: new InvoiceproformaModel().model_icon,
    },
    {
      model: 'budget',
      route: 'budgets',
      form:'presupuesto',
      list:'presupuestos',
      icon: new BudgetModel().model_icon,
    },
    {
      model: 'deliverynote',
      route: 'deliverynotes',
      form:'albarán',
      list:'albaranes',
      icon: new DeliveryNoteModel().model_icon,
    },
    {
      model: 'action',
      route: 'actions',
      form:'actuación',
      list:'actuaciones',
      icon: new ActionModel().model_icon,
    },
    {
      model: 'task',
      route: 'tasks',
      form:'tarea',
      list:'tareas',
      icon: new TaskModel().model_icon,
    },
    {
      model: 'expense',
      route: 'expenses',
      form:'gasto',
      list:'gastos',
      icon: new ExpenseModel().model_icon,
    },
    {
      model: 'liquidation',
      route: 'liquidations',
      form:'liquidación',
      list:'liquidaciones',
      icon: new LiquidationModel().model_icon,
    },
    {
      model: 'comunicationprotocol',
      route: 'comunicationsprotocols',
      form:'',
      list:'protocolos',
      icon: new ComunicationProtocolModel().model_icon,
      readonly: true
    },
    {
      model: 'email',
      route: 'emails',
      form:'',
      list:'Emails',
      icon: new EmailModel().model_icon,
      readonly: true
    },
  ];

  private alert_messages = {
    claims_license: 'Ha llegado al nº máximo de Expedientes',
    workorders_license: 'Ha llegado al nº máximo de Partes',

  }

  constructor(private titleCasePipe: TitleCasePipe, private _dropdown: DropdownService) {
  }

  public get entities(){
    return this.models;
  }

  public get default_entities(){
    return this.default_models_data;
  }

  public setEntitiesData(models_data: Array<any>): void {
    if(models_data?.length > 0) {
      models_data.forEach(
        model =>
          {
            const default_model = this.default_models_data.find(default_model => default_model.model === model.model);

            if(!default_model?.readonly) {
              this.models[model.model] = {
                labels: {
                  form: model.form,
                  list: model.list,
                },
                permissions: this.getEntityPermissions(model.route),
                entity: model.model,
                list_entity: model.route,
                saveEndpoint: model.route,
              }
            }
          }
      );
    }

    this.default_models_data.forEach(
      model => {
        if(!this.models[model.model]) {

          if(model.model_type) {
            switch (model.route) {
              case 'providers':
                  this.models[model.model] = {
                    labels: {
                      form: model.form,
                      list: model.list,
                    },
                    permissions: this.getEntityPermissions(model.route),
                    entity: 'provider',
                    entity_type: model.model_type,
                    list_entity: model.route,
                    saveEndpoint: model.route,
                  }
                break;

              default:
                break;
            }
          } else {
            this.models[model.model] = {
              labels: {
                form: model.form,
                list: model.list,
              },
              permissions: this.getEntityPermissions(model.route),
              entity: model.model,
              list_entity: model.route,
              saveEndpoint: model.route,
            }
          }

        }
      }
    )
    this.setEntityTabDatas();
  }

  setEntityTabDatas() {
    new ClaimModel().setModelData(this.models)
    new UserModel().setModelData(this.models);
    new FirmModel().setModelData(this.models);
    new WorkorderModel().setModelData(this.models);
    new InvoiceModel().setModelData(this.models);
    new InvoiceproformaModel().setModelData(this.models);
    new ClientModel().setModelData(this.models);
    new EmployeeModel().setModelData(this.models);
    new AgentModel().setModelData(this.models);
    new ExpertModel().setModelData(this.models);
    new GuildModel().setModelData(this.models);
    new TaxeModel().setModelData(this.models);
    new SerieModel().setModelData(this.models);
    new BudgetModel().setModelData(this.models);
    new DeliveryNoteModel().setModelData(this.models);
    new ActionModel().setModelData(this.models);
    new TaskModel().setModelData(this.models);
    new ExpenseModel().setModelData(this.models);
    new LiquidationModel().setModelData(this.models);
    new ComunicationProtocolModel().setModelData(this.models);
    new EmailModel().setModelData(this.models);
    new ScaleModel().setModelData(this.models);
    new MailAccountModel().setModelData(this.models);
    new CompanyModel().setModelData(this.models);
    new RepairmanModel().setModelData(this.models);
    new AdministratorModel().setModelData(this.models);

  }


  public getEntityData(entity: string): EntityDataInterface{
    return this.models[entity];
  }

  public getEntityTab(entity: string, register?:any, entity_type?: number): Tab {


    switch (entity) {
      case 'firms':
        return new FirmModel().getListTab(this.models.firm)
      case 'firm':
        return new FirmModel().getFormTab(FirmComponent, this.models.firm, register, 'name');
      case 'newfirm':
        return new FirmModel().getNewTab(NewFirmComponent, this.models.firm);
      case 'users':
        return new UserModel().getListTab(this.models.user)
      case 'user':
        return new UserModel().getFormTab(UserComponent, this.models.user, register, 'name_complete');
      case 'newuser':
        return new UserModel().getNewTab(NewUserComponent, this.models.user);
      case 'mailaccounts':
        this._dropdown.getDropdownsAPI(this.models.mailaccount.list_tabData.listParams.filters.dropdowns);
        return new MailAccountModel().getListTab(this.models.mailaccount)
      case 'mailaccount':
        //this._dropdown.getDropdownsAPI(this.models.mailaccount.form_tabData.edit_register_dropdowns);
        return new MailAccountModel().getFormTab(MailAccountComponent, this.models.mailaccount, register, 'mail');
      case 'newmailaccount':
        //this._dropdown.getDropdownsAPI(this.models.mailaccount.form_tabData.new_register_dropdowns);
        return new MailAccountModel().getNewTab(NewMailAccountComponent, this.models.mailaccount);
      case 'guilds':
        return new GuildModel().getListTab(this.models.guild)
      case 'series':
        return new SerieModel().getListTab(this.models.serie)
      case 'taxes':
        return new TaxeModel().getListTab(this.models.taxe)
      case 'clients':
        return new ClientModel().getListTab(this.models.client)
      case 'client':
        return new ClientModel().getFormTab(ClientComponent, this.models.client, register, 'name_complete');
      case 'newclient':
        return new ClientModel().getNewTab(NewClientComponent, this.models.client);
      case 'employees':
        return new EmployeeModel().getListTab(this.models.employee)
      case 'employee':
        return new EmployeeModel().getFormTab(EmployeeComponent, this.models.employee, register, 'name_complete');
      case 'newemployee':
        return new EmployeeModel().getNewTab(NewEmployeeComponent, this.models.employee);
      case 'agents':
        return new AgentModel().getListTab(this.models.agent)
      case 'agent':
        return new AgentModel().getFormTab(AgentComponent, this.models.agent, register, 'name');
      case 'newagent':
        return new AgentModel().getNewTab(NewAgentComponent, this.models.agent);
      case 'experts':
        return new ExpertModel().getListTab(this.models.expert)
      case 'expert':
        return new ExpertModel().getFormTab(ExpertComponent, this.models.expert, register, 'name_complete');
      case 'newexpert':
        return new ExpertModel().getNewTab(NewExpertComponent, this.models.expert);
      case 'scales':
        //this._dropdown.getDropdownsAPI(this.models.scale.list_tabData.listParams.filters.dropdowns);
        return new ScaleModel().getListTab(this.models.scale)
      case 'scale':
        return new ScaleModel().getFormTab(ScaleComponent, this.models.scale, register, 'code');
      case 'newscale':
        return new ScaleModel().getNewTab(NewScaleComponent, this.models.scale);
      case 'claims':
        return new ClaimModel().getListTab(this.models.claim);
      case 'claim':
        return new ClaimModel().getFormTab(ClaimComponent,this.models.claim, register, 'number');
      case 'newclaim':
        return new ClaimModel().getNewTab(NewClaimComponent, this.models.claim);
      case 'workorders':
        return new WorkorderModel().getListTab(this.models.workorder);
      case 'workorder':
         return new WorkorderModel().getFormTab(WorkorderComponent, this.models.workorder, register, 'number');
      case 'newworkorder':
         return new WorkorderModel().getNewTab(NewWorkorderComponent,this.models.workorder);
      case 'invoices':
        return new InvoiceModel().getListTab(this.models.invoice);
      case 'invoice':
         return new InvoiceModel().getFormTab(InvoiceComponent,this.models.invoice, register, 'number');
      case 'newinvoice':
         return new InvoiceModel().getNewTab(NewInvoiceComponent, this.models.invoice);
      case 'invoicesproforma':
        return new InvoiceproformaModel().getListTab(this.models.invoiceproforma);
      case 'invoiceproforma':
         return new InvoiceproformaModel().getFormTab(InvoiceproformaComponent,this.models.invoiceproforma, register, 'number');
      case 'newinvoiceproforma':
         return new InvoiceproformaModel().getNewTab(NewInvoiceproformaComponent, this.models.invoiceproforma);
      case 'budgets':
        return new BudgetModel().getListTab(this.models.budget);
      case 'budget':
         return new BudgetModel().getFormTab(BudgetComponent,this.models.budget, register, 'number');
      case 'newbudget':
         return new BudgetModel().getNewTab(NewBudgetComponent, this.models.budget);
      case 'deliverynotes':
        return new DeliveryNoteModel().getListTab(this.models.deliverynote);
      case 'deliverynote':
         return new DeliveryNoteModel().getFormTab(DeliverynoteComponent,this.models.deliverynote, register, 'number');
      case 'newdeliverynote':
         return new DeliveryNoteModel().getNewTab(NewDeliverynoteComponent, this.models.deliverynote);
      case 'expenses':
        return new ExpenseModel().getListTab(this.models.expense);
      case 'expense':
         return new ExpenseModel().getFormTab(ExpenseComponent,this.models.expense, register, 'number');
      case 'newexpense':
         return new ExpenseModel().getNewTab(NewExpenseComponent, this.models.expense);
      case 'liquidations':
        return new LiquidationModel().getListTab(this.models.liquidation);
      case 'liquidation':
         return new LiquidationModel().getFormTab(LiquidationComponent,this.models.liquidation, register, 'number');
      case 'newliquidation':
         return new LiquidationModel().getNewTab(NewLiquidationComponent, this.models.liquidation);
      case 'actions':
        return new ActionModel().getListTab(this.models.action);
      case 'tasks':
        return new TaskModel().getListTab(this.models.task);
      case 'emails':
        return new EmailModel().getListTab(this.models.email);
      case 'comunicationsprotocols':
        return new ComunicationProtocolModel().getListTab(this.models.comunicationprotocol);
      case 'providers':
        switch(entity_type) {
          case this.models.company.entity_type:
            return new CompanyModel().getListTab(this.models.company);
          case this.models.repairman.entity_type:
            return new RepairmanModel().getListTab(this.models.repairman);
          case this.models.administrator.entity_type:
            return new AdministratorModel().getListTab(this.models.administrator);
          default:
            throw new Error('404 Tab Not Found')
        }
      case 'provider':
        switch(entity_type) {
          case this.models.company.entity_type:
            return new CompanyModel().getFormTab(CompanyComponent, this.models.company, register, 'name');
          case this.models.repairman.entity_type:
            return new RepairmanModel().getFormTab(RepairmanComponent, this.models.repairman, register, 'name');
          case this.models.administrator.entity_type:
            return new AdministratorModel().getFormTab(AdministratorComponent, this.models.administrator, register, 'name');
          default:
            throw new Error('404 Tab Not Found');
          }
      case 'newprovider':
        switch(entity_type) {
          case this.models.company.entity_type:
            return new CompanyModel().getNewTab(NewCompanyComponent, this.models.company);
          case this.models.repairman.entity_type:
            return new RepairmanModel().getNewTab(NewRepairmanComponent, this.models.repairman);
          case this.models.administrator.entity_type:
            return new AdministratorModel().getNewTab(NewAdministratorComponent, this.models.administrator);
          default:
            throw new Error('404 Tab Not Found');
          }




      default:
        throw new Error('404 Tab Not Found')
        break;
    }
  }

  /**
   * Devuelve los permsisos de la entidad pasada por parametro
   * @param entity Entidad de la que recuperar los permisos
   * @returns permisos de la entidad
   */
  private getEntityPermissions(entity:string) : EntityPermissionsInterface{
    /**
    * Si los permisos de la entidad no son list_+entidad, crear una funcion personalizada con los permisos y un case que la devuelva
    */
    switch (entity) {
      default:
        return this.getDefaultEntityPermissions(entity);
    }
  }

  private getDefaultEntityPermissions(entity:string): EntityPermissionsInterface{
    return {
      list: 'list_'+entity,
      modify: 'modify_'+entity,
      delete: 'delete_'+entity,
    }
  }

  private getEntityModules(entity: string): Array<any>{
    switch (entity) {
      case 'agent':
      case 'agents':
      case 'company':
      case 'companies':
        return ['companies'];

      case 'comunicationsprotocols':
      case 'emails':
      case 'mailaccount':
      case 'mailaccounts':
        return ['mail', 'companies'];

      case 'scalesimporter':
        return ['scale_import'];

      case 'invoicing':
      case 'budget':
      case 'budgets':
      case 'deliverynote':
      case 'deliverynotes':
      case 'liquidation':
      case 'liquidations':
      case 'expense':
      case 'expenses':
        return ['billing'];


      default:
        return [];
    }
  }



}
