import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { DynamicListComponent } from "src/app/main/components/dynamic-list/dynamic-list.component";
import { Tab } from "src/app/main/tab-models/tab.model";
import { TaxeFormComponent } from "src/app/main/components/dynamic-modals/taxe-form/taxe-form.component";

const titleCasePipe = new TitleCasePipe();

export class TaxeModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'percentage',
    list: 'percentage'
  }

  setModelData(models:any) {

    models.taxe.icon = this.icon;

    models.taxe.list_tabData = {
      entity: models.taxe.entity,
      entity_label: models.taxe.labels.form,
      entity_permissions:models.taxe.permissions,
      saveEndpoint: models.taxe.saveEndpoint,
      modal_form: TaxeFormComponent,
      modal_class: 'modal-dialog modal-lg',
      listParams: {
        list_entity: models.taxe.list_entity,
        list_label: models.taxe.labels.list,
        limit: 10,
        headers: [
          'Nombre',
          'Activo',
          'Tipo',
          'Valor (%)',
          'Predet.',
          'Desde',
          'Hasta',
        ],
        fields: [
          { entity: models.taxe.entity, field: 'name' },
          { entity: models.taxe.entity, field: 'enabled', type: 'check' },
          { entity: 'typetaxe', field: 'name' },
          { entity: models.taxe.entity, field: 'value' },
          { entity: models.taxe.entity, field: 'default', type: 'check' },
          { entity: models.taxe.entity, field: 'use_start_date'},
          { entity: models.taxe.entity, field: 'use_end_date'},
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: null,
        disable_delete: true
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      DynamicListComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: false
      },
      tabData
      )
  }


}

