

export class MailAccountFilters {

  public staticFilters;
  public optionalFilters = [];
  public dropdowns = ['typemailaccount'];

  constructor(private entities){

    this.staticFilters = [
      {
        entity: this.entities.mailaccount.entity,
        field: {
          name: 'enabled',
          type: 'select',
          label: 'Estado',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todas'
            },
            {
              value:  0,
              text: 'Inactiva'
            },
            {
              value:  1,
              text: 'Activa'
            }
          ]
        }
      },
      {
        entity: this.entities.mailaccount.entity,
        field: {
          name: 'mail',
          type: 'text',
          label: 'Cuenta de correo',
          width: 2
        }
      },
      {
        entity: this.entities.mailaccount.entity,
        field: {
          name: 'typemailaccount_id',
          type: 'select',
          label: 'Proveedor',
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: 'typemailaccount'
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: this.entities.mailaccount.entity,
        field: {
          name: 'state',
          type: 'select',
          label: 'Situación',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todas'
            },
            {
              value:  0,
              text: 'Error en la configuración'
            },
            {
              value:  1,
              text: 'En funcionamiento'
            }
          ]
        }
      },
    ];
  }
}

