import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { DetailLinesComponent } from '../../components/detail-lines/detail-lines.component';
import { Tab } from '../../tab-models/tab.model';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { AppointmentInterface } from 'src/app/shared/interfaces/data/appointment-interface';
import { MDBModalRef, MDBModalService, SBItemComponent, TabsetComponent } from 'ng-uikit-pro-standard';
import { EmployeeInterface } from 'src/app/shared/interfaces/data/employee-interface';
import { UnblockModalComponent } from '../../components/dynamic-modals/unblock-modal/unblock-modal.component';
import { EmployeesAppointmentsWorkorderComponent } from '../../components/employees-appointments-workorder/employees-appointments-workorder.component';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { DocumentInterface } from 'src/app/shared/interfaces/data/document-interface';
import { WorkorderMessagesComponent } from '../../components/workorder-messages/workorder-messages.component';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { LockService } from '../../services/lock.service';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { WorkorderInterface } from 'src/app/shared/interfaces/data/workorder-interface';
import { FirmAddressInterface } from 'src/app/shared/interfaces/data/firm-address-interface';
import { EntityService } from '../../services/entity.service';
import { catchError } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-workorder',
  templateUrl: './workorder.component.html',
  styleUrls: ['./workorder.component.scss']
})
export class WorkorderComponent implements OnInit, OnDestroy {
  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('workorderTabs', { static: true }) workorderTabs: TabsetComponent;
  @ViewChild('detailComponent', {static: false}) detailComponent: DetailLinesComponent
  @ViewChild('appointmentComponent', {static: true}) appointmentComponent: EmployeesAppointmentsWorkorderComponent
  @ViewChild('messagesComponent', {static: true}) messagesComponent: WorkorderMessagesComponent

  @ViewChild('SBItemComponent', { static: false }) SBItemComponent: SBItemComponent;


  public claim_entity = this._entity.entities.claim;
  public client_entity = this._entity.entities.client;
  public employee_entity = this._entity.entities.employee;
  public guild_entity = this._entity.entities.guild;
  public user_entity = this._entity.entities.user;
  public serie_entity = this._entity.entities.serie;

  public id: number;
  public dropdowndata
  public loading = false;
  public workorder: WorkorderInterface;
  public tab_headers = {
    info: '<i class="fas fa-file"></i>',
    appointments: '<i class="fas fa-calendar"></i> Operarios y Cita',
    documents: '<i class="fas fa-paperclip"></i> Archivos'
  }

  public detail: Array<any>;

  private utils = new PGAUtils;

  private subscriptions: Array<Subscription> = [];

  public detail_headers: Array<any> = [
    {header: 'Exp.', class: 'buttons-column'},
    { header: 'Liq.', class: 'buttons-column'},
    {header: 'Código', class: 'code-column'},
    'Descripción',
    {header: this.titleCase.transform(this.employee_entity.labels.form), class:'medium-column'},
    { header: 'Cantidad', class: 'numbers-column'},
    {header: 'Coste', class: 'numbers-column'},
    {header: 'Precio', class: 'numbers-column'},
    {header:'%Descuento', class: 'percent-column'},
    {header:'%Impuesto', class: 'percent-column'}
  ];

  public detail_fields_order: Array<any> = [
    {
      control: 'type_export_line_id',
      type: 'checkbox',
      default:  0,
      disabled: false
    },
    {
      control: 'typeliquidation_id',
      type: 'checkbox',
      default:  1,
      disabled: false
    },
    {
      control: 'code',
      type: 'text',
      default: '',
      disabled: false,
      addKey: true,
    },
    {
      control: 'description',
      type: 'text',
      default: '',
      disabled: false,
      validate:true,
      validators:[Validators.required],
      error_msg: 'Campo obligatorio',
      addKey: true,
    },
    {
      control: 'employee_id',
      type: 'select',
      dropdown: 'employees',
      field_value: 'name_complete',
      clear: true,
      listen_changes: true,
      disabled: false,
      default: null,
    },
    {
      control: 'quantity',
      type: 'number',
      default: 1,
      disabled: false,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'cost',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'price',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'discount',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'taxe_id',
      type: 'select',
      dropdown: 'taxes',
      field_value: 'value',
      addKey: true,
      clear: false,
      listen_changes: true,
      disabled: true,
      default: 1,
    }
  ]


  private formFunctions = new FormFunctions;

  private dateformats = new DateFormats;

  public formWorkorder: FormGroup;

  public messages: Array<any>

  public appointments: Array<AppointmentInterface>;
  public current_appointment: AppointmentInterface
  public employees: Array<EmployeeInterface>;
  public documents: Array<DocumentInterface>;

  public typestatusonmobileUnblock: boolean = false;
  modalRefUnblock: MDBModalRef | null = null;


  public firm_addresses: Array<FirmAddressInterface>;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

// private _entity: EntityService
  constructor(private _api: ApirequestService, private _lock: LockService, private _apifunctions: ApiFunctions, private _buttons: ActionButtonsService, private _notification: NotificationService, private modalService: MDBModalService, private _entity: EntityService, private titleCase: TitleCasePipe, private _dropdowns: DropdownService) { }

  ngOnInit(): void {
    this.id = +this.data.id
    this.formWorkorder = this.formFunctions.getForm(this.data.entity);
    this.getData()

  }

  ngOnDestroy(): void {
    this.unsubscribeAll();

    this._api.unlockExit(this.lockdata)
  }

  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }

  }
/*
  getDropdowns(){
    this.subscriptions.push(
      this._apifunctions.getDropdowns(['taxe', 'guild', 'employee', 'typecondition', 'user', 'typestatusonmobile', 'typeexport']).subscribe(
        dropdowns => {
          let drop =  dropdowns.response.dropdown;
          drop.taxe = drop.taxe.filter(t => t.enabled == 1);
          this.dropdowndata = drop;
          if(this.workorder) {
            this.loading = false;
          }
          /* if(!this.dropdowndata.taxe.find(t => t.id === this.workorder.taxe.id)) {
            this.dropdowndata.taxe.unshift({id: this.workorder.taxe.id,name: this.workorder.taxe.name})
          } */
/*        },
        error => {
          this.loading = false;
        }
      )
    );
  }*/


  getData(){
    this.loading = true;
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          console.log(responses)
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns();
          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }


  private setFirmAddresses(){
    this.loading = true;
    let newurl = new URL(this._api.getAPIEndpoint()+'firmsaddresses');
    let filter_firm = {
      firmaddress: {
        firm_id: this.workorder.firm_id
      }
    }

    newurl.searchParams.append('filters', JSON.stringify(filter_firm));
    newurl.searchParams.append('paginate', JSON.stringify(0));

    this.subscriptions.push(
      this._api.get('firmsaddresses'+newurl.search).subscribe(
        response => {

          this.firm_addresses = response.response.firmaddress;
          this.dropdowndata['firm_addresses'] = response.response.firmaddress;
          /* if(this.dropdowndata) {
            this.dropdowndata['firm_addresses'] = response.response.firmaddress;
          } else {
            this.dropdowndata = {
              firm_addresses: response.response.firmaddress
            }
          } */
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    data.guild = data.guild.filter(guild => guild.enabled == true);
    data.taxe = data.taxe.filter(t => t.enabled == true);
    this.dropdowndata = data;

  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)

    this.workorder = apiresponse.workorder;
    this.appointments = this.workorder.appointments;
    this.current_appointment = this.workorder.appointments.find(app => app.finished === 0);
    if(!this.current_appointment && this.appointments.length>0){
      this.current_appointment = this.appointments[0];
    }
    this.employees = [...[
      {
        id: null,
        manager: false,
        name_complete: 'Sin asignar',
        address: null,
        email: null,
        name: null,
        surname: null,
        phone: null,
        mobile: null,
        postal_code: null,
        province: null,
        population: null,
        password: null,
        company_id: null,
        identification_document: null,
        web_access: null,
        enabled: true,
        observations: null
      }
    ], ...this.workorder.employees];

    this.documents = this.workorder.documents;

  
    if(this.workorder.claim?.id) {
      this.messagesComponent.claim_id = this.workorder.claim.id;
    }

    this.setFirmAddresses();

    this.tab.tabData.headerData = this.workorder.number;

    this.tab_headers.info += this.workorder.number;
    this.tab_headers.documents += this.utils.tabBadgeContent('badgeinfo', this.workorder.number_documents.toString());
    this.detail = this.workorder.lines;
    this.messages = this.workorder.messages;

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formWorkorder = undefined;
          setTimeout(() => {
            this.formWorkorder = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?: boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formWorkorder, this.workorder);
      this.setWorkorderAddress();
      this.setWorkorderProviderName();
      this.formWorkorder.disable();
    } else {

      this.formFunctions.rellenarFormulario(this.formWorkorder, this.workorder);

      this.setWorkorderAddress();
      this.setWorkorderProviderName();
      this.formWorkorder.controls.emission_date.setValue(this.dateformats.cambioFormato(this.workorder.emission_date));

      this.subscriptions.push(
        this.formWorkorder.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      )
    }

  }

 /* goToTab(index: number) {
    this.workorderTabs.setActiveTab(index);
  }*/

 /* goToAppointmentTab(){
    this.goToTab(3)
    this.SBItemComponent.applyToggle(true);
  }*/

  unblockModal(){
    this.modalRefUnblock = this.modalService.show(UnblockModalComponent, {
      class: 'modal-sm',
      data: {
        text: '¿Desea desbloquear el campo Estado App?'
      }
    });

    this.subscriptions.push(
      this.modalRefUnblock.content.unblockConfirm.subscribe(
          confirm => {
              if(confirm === true) {
                this.typestatusonmobileUnblock = true;
              } else {
                this.typestatusonmobileUnblock = false;
              }
          }
        )
   )
  }

  private setWorkorderAddress() {
    let address = '';

    if(this.workorder.claim) {
      if(this.workorder.claim?.policy) {
        address = this.workorder.claim.policy.address_complete;
      } else if(this.workorder.claim?.clientaddress) {
        address = this.workorder.claim.clientaddress.address_complete;
      }
    } else if(this.workorder.clientaddress_id) {
      address = this.workorder.clientaddress.address_complete;
    }

    this.formWorkorder.controls.workorder_address.setValue(address);
  }

  private setWorkorderProviderName() {
    let provider_name = 'Particular';
    if(this.workorder.provider) {
      provider_name = this.workorder.provider.name;
      if(this.workorder.subprovider) {
        provider_name += ' ('+this.workorder.subprovider.name+')';
      }
    }

    this.formWorkorder.controls.workorder_provider_name.setValue(provider_name);
  }

  disableChanges(): boolean {
    let readonly = false;
    if(!this.lockdata?.unblock) {
      readonly = true;
    } else {
      if(this.workorder && this.workorder.typecondition_id == 2) {
        //deshabilitar = true;
      }
    }

    return readonly;
  }

  onGetActiveTab(ev){
    if(ev?.activeTabIndex === 2) {
      this.messagesComponent.scroll()
    }
  }

  changeSelect(tipo: string, valor) :void{
    this.formWorkorder.controls[tipo]['controls'].id.setValue(valor);
    this.formWorkorder.controls[tipo]['controls'].id.markAsDirty();
  }

  reload(entity: string){
     if(entity === 'messages') {
      this.messagesComponent.loading = true;
     } else {
      this.loading = true;
     }
      this.subscriptions.push(
        this._api.get('workorders/'+this.id).subscribe(
          data=>{
            this[entity] = data.response.workorder[entity];
            this.messagesComponent.loading = false;
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        )
    );
  }


  public onSubmit(): void{
    this._buttons.setLoading(true);

    this.formWorkorder.markAllAsTouched();

    let line_form_error = false;
    this.detailComponent.detailsForms.controls.forEach(
      form => {
        form.markAllAsTouched();
      }
    )
    for (let index = 0; index < this.detailComponent.detailsForms.controls.length; index++) {
      if(this.detailComponent.detailsForms.controls[index].invalid) {
        line_form_error = true;
        break;
      }
    }

    let appointment_error = false;
    if(this.appointmentComponent.appointmentForm) {
      this.appointmentComponent.appointmentForm.markAllAsTouched()
      appointment_error = this.appointmentComponent.appointmentForm.invalid;
    }

    if(this.formWorkorder.valid && !line_form_error && !appointment_error) {
      const params = {};

      //Datos Parte
      this.formFunctions.fillFormParams(this.formWorkorder, this.data.entity, params)

      /* Object.keys(this.formWorkorder.controls).forEach(
        control =>{

        // if(this.formWorkorder.controls[control].enabled){
              if(this.formWorkorder.controls[control] instanceof FormGroup){
                if(this.formWorkorder.controls[control]['controls'].id.value !== -1) {

                  params[this.data.entity][control+'_id']=this.formWorkorder.controls[control]['controls'].id.value;                  }
              } else {
                  params[this.data.entity][control]=this.formWorkorder.controls[control].value;
              }
          }

        //}
      ) */



      //Datos Líneas
      params['workorderlines']= [];
      this.detailComponent.pushDetailsToMaster(params['workorderlines']);

      if(params['workorderlines'].length > 0) {
        params[this.data.entity]['total'] = this.detailComponent.total_lineas
        params[this.data.entity]['total_price'] = this.detailComponent.total_price
        params[this.data.entity]['total_taxe'] = this.detailComponent.total_taxe
        params[this.data.entity]['total_cost'] = this.detailComponent.total_cost
      }

      //Empleados
      params['employees'] = this.employees.filter(emp => emp.id).map(emp => {
        return {
          id: emp.id,
          manager: emp.manager
        }
      });

      let manager = this.employees.find(emp => emp.manager);
      if(manager) {
        params[this.data.entity]['employee_id'] = manager.id;
      }

      //Citas
      if(this.appointmentComponent.appointmentForm?.dirty) {
        let appointment_form = {...this.appointmentComponent.appointmentForm.getRawValue()};
        params['appointment'] = {
          id: appointment_form.id,
          head: appointment_form.head,
          body: appointment_form.body,
          finished: appointment_form.finished,
          starttime: this.dateformats.formatoSave(appointment_form.startdate)+' '+appointment_form.starttime+':00',
          endtime: this.dateformats.formatoSave(appointment_form.enddate)+' '+appointment_form.endtime+':00',
        }
      }

      console.log(params)
      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint+'/'+this.data.id, JSON.stringify(params)).subscribe(
          resp=>{
            this.tab.modifiedData = false;
            this.formWorkorder.markAsPristine();
            this.unsubscribeAll();
            this.appointmentComponent.unsubscribeAll();
            //this.appointmentComponent.appointmentForm = null;
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
            this.getData();
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      )
    } else {
      this._buttons.setLoading(false);
      /*if(!this.formWorkorder.valid || line_form_error) {
        this.goToTab(1);
      } else if(appointment_error) {
        this.goToTab(3)
      }*/
    }



  }



}
