import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  private modules: Array<string>;
  public modulesSub = new Subject<Array<string>>();

  constructor() { }

  setModules(modules: Array<string>){
    this.modules = modules;
    this.modulesSub.next(this.modules);
  }

  getModules(): Array<string> {
    return this.modules;
  }

  checkModule(module: string): boolean{
    if(this.modules){
      if(this.modules.find(mod => mod == module)){
        return true;
      }
  }
    return false;
  }

  checkModules(modules:Array<string>) {

    let hasModule = false;
    for (let index = 0; index < modules.length; index++) {
      if(this.modules.find(m2 => m2==modules[index])) {
        hasModule = true;
        break;
      }
    }

    return hasModule;
  }
}
