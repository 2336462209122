import { Injectable } from '@angular/core';
import { AdminPageModel, BuyingPageModel, ScalesImporterModel, UserProfileModel } from 'src/app/shared/models/various';
import { AdminPageComponent } from '../admin-page/admin-page.component';
import { Tab } from '../tab-models/tab.model';
import { BuyingPageComponent } from '../buying-page/buying-page.component';
import { ScalesImporterComponent } from '../scales-importer/scales-importer.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { DropdownService } from './dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class SinglePageService {

  private default_singlepages_data: {[key:string]: any} = {
    adminpage: {
      entity: 'adminpage',
      permissions : {
        list: 'list_modules',
        modify: 'modify_modules',
        delete: 'delete_modules',
      },
      icon: new AdminPageModel().page_icon,
      header: new AdminPageModel().page_header,
    },
    buyingpage: {
      entity: 'buyingpage',
      permissions : {
        list: 'list_modules',
        modify: 'modify_modules',
        delete: 'delete_modules',
      },
      icon: new BuyingPageModel().page_icon,
      header: new BuyingPageModel().page_header,
    },
    scalesimporter: {
      entity: 'scalesimporter',
      permissions : {
        list: 'list_scales',
        modify: 'modify_scales',
        delete: 'delete_scales',
      },
      modules : ['scale_import'],
      icon: new ScalesImporterModel().page_icon,
      header: new ScalesImporterModel().page_header,
      dropdowns: ['company_with_subcompanies', 'repairman', 'administrator', 'guild', 'taxe', 'province']
    },
    userprofile: {
      entity: 'userprofile',
      icon: new UserProfileModel().page_icon,
      header: new UserProfileModel().page_header,
    }
  };

  constructor(private _dropdown: DropdownService) { }

  get singlepages() {
    return this.default_singlepages_data;
  }

  getPageTab(entity: string): Tab {
    switch (entity) {
      case 'adminpage':
        return new AdminPageModel().getTab(AdminPageComponent, this.default_singlepages_data[entity]);
      case 'buyingpage':
        return new BuyingPageModel().getTab(BuyingPageComponent, this.default_singlepages_data[entity]);
      case 'scalesimporter':
        return new ScalesImporterModel().getTab(ScalesImporterComponent, this.default_singlepages_data[entity]);
      case 'userprofile':
        return new UserProfileModel().getTab(UserProfileComponent, this.default_singlepages_data[entity]);
      break;
      default:
        throw new Error('404 Tab Not Found');
        break;
    }

  }
}
