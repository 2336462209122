<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid">

    <ng-container *ngIf="data.listParams.filters">
      <app-dynamic-list-filters #dynamicFilters [entityFilters]="data.listParams.filters" (emitFilters)="applyFilters($event)"></app-dynamic-list-filters>
    </ng-container>

    <!--Table-->
    <div class="table-responsive" style="min-height:400px">
        <table mdbTable mdbTableScroll scrollY="true" maxHeight="100" #tableEl="mdbTable" class="z-depth-1">
            <!--Table head-->
            <thead>
                <tr class="blue darken-1 white-text">
                    <ng-container *ngFor="let header of data.listParams.headers; let i = index">
                        <th>
                            {{ header }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let registro of datos; let i = index">
                    <tr class="filas" (dblclick)="editRegister(registro)" (click)="selectRegister(registro, i)" (contextmenu)="onContextMenu($event,registro,i)" [class.selected]="i == selectedRow">
                        <ng-container *ngFor="let param of data.listParams.fields; let j = index">

                            <ng-container *ngIf="param.entity == data.entity; else nested">
                                <ng-container *ngIf="param.type == undefined || param.type == null ">
                                    <td>{{ registro[param.field] }}</td>
                                </ng-container>
                                <ng-container *ngIf="param.type == 'check' ">
                                    <ng-container *ngIf="registro[param.field] == 1 ">
                                        <td>
                                            <mdb-icon far icon="check-square"></mdb-icon>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="registro[param.field] != 1 ">
                                        <td>
                                            <mdb-icon far icon="square"></mdb-icon>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-template #nested>
                                <!-- Se recorre cada campo anidado del registro como clave->valor (clave: entidad anidada, valor: registro de la entidad)-->
                                <ng-container *ngFor="let campo of registro | keyvalue">
                                    <!-- Se compara la clave del parámetro con la del campo anidado -->
                                    <ng-container *ngIf="param.entity == campo.key">
                                        <td *ngIf="campo.value; else vacio">
                                            <!-- el rol del usuario es el unico campo especial: es un array de valores y dentro está el nombre del rol-->
                                            <ng-container *ngIf="
                                data.entity == 'user' && campo.key == 'roleuserfirm';
                                else normal
                              ">
                                                <ng-container *ngFor="let rolfirm of campo.value;let z=index">

                                                    <ng-container *ngIf="rolfirm.firm_id === activeFirm">

                                                        <ng-container *ngIf="param.field == 'is_enabled'; else rolename">
                                                            {{rolfirm[param.field]}}
                                                        </ng-container>

                                                        <ng-template #rolename>
                                                            {{rolfirm.role[param.field]}}
                                                        </ng-template>

                                                    </ng-container>

                                                </ng-container>


                                            </ng-container>

                                            <ng-template #normal>
                                                {{ campo.value[param.field] }}
                                            </ng-template>
                                        </td>
                                        <td *ngIf="!campo.value"></td>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>

    </div>

    <tfoot class="grey lighten-5 w-100">
        <tr>
            <td></td>
            <td [colSpan]="data.listParams.headers.length">
                <mat-paginator #paginator style="float: left" [length]="total" [pageIndex]="pag_actual" [pageSize]="data.listParams.limit" [pageSizeOptions]="[data.listParams.limit]" [showFirstLastButtons]="true" (page)="changePage($event)">
                </mat-paginator>
            </td>
        </tr>
    </tfoot>

    <!--Menú contextual en las filas de la tabla-->
    <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="editRegister(item)"><mdb-icon fas icon="eye" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Visualizar</button>

            <!-- <button mat-menu-item (click)="addTabAccesoWeb()" *ngIf="can_modify && listparams.web_access"><mdb-icon fas icon="laptop" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Acceso Web</button>

         <ng-container *ngIf="listparams.disable_user; else deletebutton2">
           <button mat-menu-item *ngIf="can_modify" (click)="confirmDisableUser()"><mdb-icon fas icon="user-slash" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Deshabilitar Usuario</button>
       </ng-container> -->

            <ng-container *appPermissions="data.entity_permissions?.delete">
                <button mat-menu-item *ngIf="item.id != 1"><mdb-icon fas icon="trash" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Eliminar</button>
            </ng-container>


        </ng-template>
    </mat-menu>

</div>
