<app-loader *ngIf="loading"></app-loader>

<form [formGroup]="formNewExpense">

  <div class="divCustomGlobal">
    <div class="divCustomSection">
      <i class='fas fa-book mr-2'></i> Información
    </div>
    <!--PESTAÑA INFORMACION-->
      <div class="m-3">
        <div class="row">

          <div class="col-4">
            <div class="row">
              <mdb-checkbox class="mt-2 ml-3" [default]="true" [disabled]="data.parentData" [(ngModel)]="hasClaim" [ngModelOptions]="{standalone: true}" (change)="changeClaim($event)"></mdb-checkbox>
              <div class="md-form md-outline">
                <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [disabled]="!hasClaim || data.parentData" id="seleccionarExpediente" mdbWavesEffect (click)="selectClaim()">
                  <mdb-icon fas icon="folder-plus" size="lg"></mdb-icon>
                </button>
              </div>
              <div class="col-lg">
                <div class="md-form md-outline">
                  <input type="text" name="claim_number" formControlName="claim_number" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                  <label for="claim_number" class="">Expediente</label>
                  <mdb-error *ngIf="formNewExpense.controls.claim_number.invalid && (formNewExpense.controls.claim_number.dirty || formNewExpense.controls.claim_number.touched)">Seleccionar {{claim_entity.labels.form | titlecase}}</mdb-error>
                </div>
              </div>           
            </div>
          </div>

          <div class="col-4">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" [allowClear]="true" placeholder="Elige un operario:" label="Operario" formControlName = "employee_id">
                <mdb-select-option *ngFor="let employee of dropdowns?.employee" [value]="employee.id">{{ employee.name_complete }}</mdb-select-option>
              </mdb-select-2>

            </div>
          </div>
        </div>
      </div>
  </div>

  <div class="divCustomSection">
    <i class='fas fa-money-bill mr-2'></i> Detalle
  </div>
  <div class="m-3">
    <div class="row">
      <div class="col-md-2">
        <div class="md-form md-outline">
          <input mdbInput type="text" id="code" class="form-control form-control-sm" formControlName="code" placeholder="">
          <label for="code">Código</label>
        </div>
      </div>
      <div class="col-md">
        <div class="md-form md-outline">
          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="concept" class="form-control form-control-sm" formControlName="concept" placeholder="">
          <label for="concept">Concepto</label>
          <mdb-error *ngIf="formNewExpense.controls.concept.invalid && (formNewExpense.controls.concept.dirty || formNewExpense.controls.concept.touched)">Campo Obligatorio</mdb-error>
        </div>
      </div>
      <div class="col-md-1">
        <div class="md-form md-outline">
          <input mdbInput  mdbValidate [validateSuccess]="false" type="number" step="1" class="form-control form-control-sm text-right" id="total_base" formControlName="total_base" placeholder="">
          <label for="total_base">Importe Base</label>
          <mdb-error *ngIf="formNewExpense.controls.total_base.invalid && (formNewExpense.controls.total_base.dirty || formNewExpense.controls.total_base.touched)">
            <span *ngIf="formNewExpense.controls.total_base.errors.required">Campo Obligatorio</span>
            <span *ngIf="formNewExpense.controls.total_base.errors.min">Valor incorrecto</span>
          </mdb-error>
        </div>
      </div>
      <div class="col-md-1">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" placeholder="" label="Impuesto" formControlName="taxe_id">
            <mdb-select-option *ngFor="let option of dropdowns?.taxe" [value]="option.id">{{ option.value }} %</mdb-select-option>
          </mdb-select-2>
        </div>
      </div>
      <div class="col-md-1">
        <div class="md-form md-outline">
          <input mdbInput type="text" id="total_taxe" class="form-control form-control-sm text-right" formControlName="total_taxe" placeholder="">
          <label for="total_taxe">Total IVA</label>
        </div>
      </div>
      <div class="col-md-1">
        <div class="md-form md-outline">
          <input mdbInput type="text" id="total" class="form-control form-control-sm text-right" formControlName="total" placeholder="">
          <label for="total">Total Neto</label>
        </div>
      </div>
    </div>
  </div>
</form>

<!--
<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formNewExpense">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(claim_entity.labels.form | titlecase)+', '+(employee_entity.labels.form | titlecase)">
            <div class="container">
            <div class="row">
              <div class="col-md-6 mt-2">
                <div class="row">
                  <div class="col-1 custom-cell mt-2">
                    <!-- <mdb-checkbox [default]="true" (change)="onChange($event)"></mdb-checkbox> -->
<!--                    <mdb-checkbox [default]="true" [disabled]="data.parentData" [(ngModel)]="hasClaim" [ngModelOptions]="{standalone: true}" (change)="changeClaim($event)"></mdb-checkbox>
                  </div>
                  <div class="col">
                    <span class="character-counter">{{claim_entity.labels.form | titlecase}}</span>
                    <div class="md-form input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn-group" mdbDropdown>
                          <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" [disabled]="!hasClaim || data.parentData"
                          id="seleccionarExpediente" mdbWavesEffect (click)="selectClaim()">
                          <mdb-icon fas icon="folder-plus" size="lg"></mdb-icon>
                        </button>
                        </div>
                      </div>
                      <input type="text" name="claim_number" formControlName="claim_number" mdbValidate [validateSuccess]="false"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <mdb-error *ngIf="formNewExpense.controls.claim_number.invalid && (formNewExpense.controls.claim_number.dirty || formNewExpense.controls.claim_number.touched)">
                      Campo Obligatorio
                    </mdb-error>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-4 mt-2">
                <div class="md-form md-outline">
                  <mdb-select-2 [outline]="false" [allowClear]="true" placeholder="Elige un operario:" label="Operario" formControlName = "employee_id">
                    <mdb-select-option *ngFor="let employee of dropdowns?.employee" [value]="employee.id">{{ employee.name_complete }}</mdb-select-option>
                  </mdb-select-2>

                </div>
              </div>
            </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
      <div class="col-12 custom-card-padding mt-3">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'Datos del '+(data.entity_label | titlecase)">
            <div class="row">
              <div class="col-md-2">
                <div class="md-form">
                  <input mdbInput type="text" id="code" class="form-control form-control-sm" formControlName="code" placeholder="">
                  <label for="code">Código</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="concept" class="form-control form-control-sm" formControlName="concept" placeholder="">
                  <label for="concept">Concepto</label>
                  <mdb-error *ngIf="formNewExpense.controls.concept.invalid && (formNewExpense.controls.concept.dirty || formNewExpense.controls.concept.touched)">
                    Campo Obligatorio
                  </mdb-error>
                </div>
              </div>
              <div class="col-md-1">
                <div class="md-form">
                  <input mdbInput
                    mdbValidate
                    [validateSuccess]="false"
                    type="number"
                    step="1"
                    class="form-control form-control-sm text-right"
                    id="total_base"
                    formControlName="total_base"
                    placeholder="">
                  <label for="total_base">Importe Base</label>
                  <mdb-error *ngIf="formNewExpense.controls.total_base.invalid && (formNewExpense.controls.total_base.dirty || formNewExpense.controls.total_base.touched)">
                      <span *ngIf="formNewExpense.controls.total_base.errors.required">Campo Obligatorio</span>
                      <span *ngIf="formNewExpense.controls.total_base.errors.min">Valor incorrecto</span>
                  </mdb-error>
                </div>
              </div>
              <div class="col-md-1">
                <div class="md-form">
                  <mdb-select-2 placeholder="" label="Impuesto" formControlName="taxe_id">
                    <mdb-select-option *ngFor="let option of dropdowns?.taxe " [value]="option.id">{{ option.value }} %</mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
              <div class="col-md-1">
                <div class="md-form">
                  <input mdbInput type="text" id="total_taxe" class="form-control form-control-sm text-right" formControlName="total_taxe" placeholder="">
                  <label for="total_taxe">Total IVA</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="md-form">
                  <input mdbInput type="text" id="total" class="form-control form-control-sm text-right" formControlName="total" placeholder="">
                  <label for="total">Total Neto</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </form>
</div>
-->