import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { from, Observable, Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { PGAUtils } from '../../shared/functions/pga-utils';
import { UserPermissionsService } from '../../main/services/user-permissions.service';
import { FirmsService } from '../../main/services/firms.service';
import { LockService } from '../../main/services/lock.service';
import { LockdataInterface } from '../../shared/interfaces/data/lockdata-interface';
import { concatMap } from 'rxjs/operators';
import { UserService } from '../../main/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ApirequestService {

	private readonly endpoint: string = 'https://api.pgaweb.reparadores.es/';

  private vhost = this.endpoint+'';
  private apiroute: string = 'api/';
  private loginroute: string = 'auth/login';
  private logoutroute: string = 'auth/logout';
  private storage_route: string = 'storage/';

  private _alias: string;

  private locksubscriptions: Array<Subscription> = [];

  private utils = new PGAUtils;
  private cancel_lock: boolean;

  constructor(private http: HttpClient, private router: Router, private _user: UserService,private _userPermissions: UserPermissionsService, private _firms: FirmsService, private _lock: LockService) {
    if(!this._alias){
      const hostname = window.location.hostname.split('.');
      this._alias = this.utils.SHA1(hostname[0])+'/';
    }
  }

  set alias(value: string){
    this._alias = value;
  }

  /**
   *
   * @returns ruta al endpoint del api para la empresa (con alias)
   */
  getAPIEndpoint(): string{
    return this.endpoint+this.apiroute+this._alias;
  }

  /**
   *
   * @returns ruta a la carpeta storage del api
   */
  getStorageRoute(): string{
     return this.vhost+this.storage_route;
  }

   /**
   * Envía petición POST como FormData
   * @param url ruta en el api
   * @param formData datos a mandar como formdata
   * @returns Observable para suscribirse
   */
  postfiles(url:string, formData: FormData): Observable<any> {
    return this.http.post(this.vhost + this.apiroute + this._alias + url ,formData);
  }

  /**
   * Envía petición POST como JSON
   * @param url ruta en el api
   * @param stringSolicitud parámetros en JSON
   * @returns Observable para suscribirse
   */
  post(url: string, stringSolicitud:string):Observable<any> {
    return this.http.post(this.vhost + this.apiroute + this._alias + url ,stringSolicitud);
  }

  /**
   * Envía petición PUT como JSON
   * @param url ruta en el api
   * @param stringSolicitud parámetros en JSON
   * @returns Observable para suscribirse
   */
  put(url: string, stringSolicitud:string):Observable<any> {
    return this.http.put(this.vhost + this.apiroute + this._alias + url ,stringSolicitud);
  }

  /**
   * Envía petición PATCH como JSON
   * @param url ruta en el api
   * @param stringSolicitud parámetros en JSON
   * @returns Observable para suscribirse
   */
  patch(url: string, stringSolicitud:string):Observable<any> {
    return this.http.patch(this.vhost + this.apiroute + this._alias + url ,stringSolicitud);
  }

  /**
   *
   * @param url ruta en el api con parámetros
   * @returns Observable para suscribirse
   */
  get(url: string):Observable<any> {
    return this.http.get(this.vhost + this.apiroute + this._alias + url);
  }

  /**
   *
   * @param url ruta en el api con parámetros
   * @returns Observable para suscribirse
   */
  delete(url): Observable<any> {
    return this.http.delete(this.vhost + this.apiroute + this._alias + url)
  }

  /**
   * Petición POST para el login del usuario
   * @param stringSolicitud Parámetros en JSON para el login
   * @returns Observable para suscribirse
   */
  userlogin(stringSolicitud:string):Observable<any> {
	this.cancel_lock = false;
    return this.http.post(this.vhost + this.apiroute + this.loginroute, stringSolicitud);
  }

  /**
   * Petición POST para el logout del usuario
   * @param stringSolicitud Parámetros en JSON para el logout
   * @returns Observable para suscribirse
   */
  userlogout(stringSolicitud:string):Observable<any> {
    return this.http.post(this.vhost + this.apiroute + this.logoutroute, stringSolicitud);
  }

  /**
   * Petición POST para refrescar el token de sesión
   * @returns Observable para suscribirse
   */
  refreshToken():Observable<any> {
    return this.http.post<any>(this.vhost + this.apiroute + this._alias + 'auth/refresh', {})
  }

  /**
   * Cierra la sesión del usuario
   * @param modalCloseSession opcional: Modal Cerrando sesión
   */
  logOut(modalCloseSession?: ModalDirective) {

    //si hay peticiones de lock en curso las corta
    this.cancelLockRequests();

    let domain = window.location.hostname.split('.');

    const logout_params = {
      alias: this.utils.SHA1(domain[0]),
      logoutdata: sessionStorage.getItem('pgabgid')
    };

    this.post('auth/logout', JSON.stringify(logout_params)).subscribe(
      response => {
        if(modalCloseSession) {
          modalCloseSession.hide();
        }
        sessionStorage.clear();
        //reseteo de servicios
        this._userPermissions.setPermissions(undefined);
        this._firms.setFirms(undefined);
        this._alias = null;
        this._user.reset();

        //cierra todas las pestañas
        //this._tabs.closeAllTabs();

        this.router.navigate(['/login']);
      },
      error => {
        if(modalCloseSession) {
          modalCloseSession.hide();
        }
        sessionStorage.clear();
        //reseteo de servicios
        this._userPermissions.setPermissions(undefined);
        this._user.reset();
        this._firms.setFirms(undefined)
      //  this._tabs.closeAllTabs();
        this._alias = null;
        this.router.navigate(['/login']);
      }
    );
  }

  /**
   * Ruta de bloqueos
   */
  private readonly lock_endpoint = 'locks';

  /**
   * Elimina el bloqueo de un registro y lo bloquea con los nuevos datos del usuario
   * @param lockdata datos del nuevo bloqueo
   * @returns Observable para suscribirse
   */
  lockRegister(lockdata: LockdataInterface): Observable<any> {
    const lock_params = {
      lock: {
        table: lockdata.table,
        register_id: lockdata.register_id
      }
    }

    /**
     * Se definen las peticiones al api en el orden que se ejecutarán
     */
    const lock_calls = [
      this.delete(this.lock_endpoint+'/'+lockdata.id),
      this.post(this.lock_endpoint, JSON.stringify(lock_params))
    ];

    /**
     * concatMap espera a que termine la primera petición (delete) antes de enviar la segunda (post)
     */
    return from(lock_calls)
            .pipe(
              concatMap(lock_call => lock_call)
            )
  }

  /**
   * Desbloquea un registro si estaba bloqueado
   * @param lockdata datos del bloqueo
   */
  unlockExit(lockdata: LockdataInterface){
    if(!this.cancel_lock) {
      if(lockdata?.unblock) {
        this.locksubscriptions.push(
        this.delete(this.lock_endpoint+'/'+lockdata.id).subscribe()
        )
      }
    }
  }

  /**
   * Cancela todas las peticiones de bloqueo/desbloqueo en curso
   */
  cancelLockRequests(): void {
	this.cancel_lock = true;
    this.locksubscriptions.forEach(s => s.unsubscribe())
  }

}
