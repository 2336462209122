<app-loader *ngIf="loading"></app-loader>
<!-- Nav tabs -->
<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs mi-clase" (getActiveTab)="onGetActiveTab($event)">
  <!--Panel 1-->
  <mdb-tab heading="Pendientes de procesar">
    <ng-container *ngIf="active_tab == 0">
      <app-table-comunications [params]="table_params" #t1 (page)="changePage($event)" (filters)="setFilters($event)" (process_protocol)="processProtocol($event)" (ignore)="ignoreComunication($event)"></app-table-comunications>
    </ng-container>
  </mdb-tab>
  <mdb-tab heading="Recibidas">
    <ng-container *ngIf="active_tab == 1">
      <app-table-comunications [params]="table_params" #t2 (page)="changePage($event)" (filters)="setFilters($event)" (unignore)="ignoreComunication($event, true)"></app-table-comunications>
    </ng-container>
  </mdb-tab>
  <mdb-tab heading="Enviadas">
    <ng-container *ngIf="active_tab == 2">
      <app-table-comunications [params]="table_params" #t3 (page)="changePage($event)" (filters)="setFilters($event)"></app-table-comunications>
    </ng-container>
  </mdb-tab>
  <mdb-tab heading="Acceso webs">
    <ng-container *ngIf="active_tab == 3">
      <div class="loading-screen-wrapper-comunications" *ngIf="downloading_comunications">
        <mdb-spinner spinnerType="small" spinnerColor="blue" class="spinner-comunications"></mdb-spinner>
      </div>
      <div class="row mt-2">
        <div class="col-md-2">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" placeholder="" label="Protocolo" [formControl]="access_type_protocol">
              <mdb-select-option *ngFor="let option of protocols_enum" [value]="option.value">{{ option.label }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
        <div class="col-md-2">
          <button type="button" mdbBtn color="primary" size="sm" mdbWavesEffect (click)="downloadComunications()">
            Comprobar Comunicaciones Web</button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <div class="md-form md-outline">
            <textarea type="text" id="comunications-log" class="md-textarea form-control" rows="5" placeholder="" mdbInput [(ngModel)]="comunications_log" disabled></textarea>
            <label for="comunications-log">Logs</label>
          </div>
        </div>
      </div>
    </ng-container>
  </mdb-tab>
</mdb-tabset>
