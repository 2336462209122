import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ModulesService } from '../services/modules.service';

@Directive({
  selector: '[appModules]'
})
export class ModulesDirective {

  private modules: Array<string>;
  private elseTemplate: TemplateRef<any>;

  @Input()
  set appModules(modules: Array<string>) {
    this.modules = modules;
    this.viewContainer.createEmbeddedView(this.templateRef)
    this.updateView();
  }

  @Input()
  set appModulesElse(template: TemplateRef<any>){
    this.elseTemplate = template
    this.updateView()
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _modules: ModulesService) { }



  updateView(){
    this.viewContainer.clear();
    if(this._modules.checkModules(this.modules)) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else if (this.elseTemplate){
      this.viewContainer.createEmbeddedView(this.elseTemplate)
    }
  }
}
