import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CollapseComponent, humanizeBytes,  MdbCheckboxChange,  MDBFileSelectDirective, MDBModalRef, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { AppConfigService } from 'src/app/main/services/app-config.service';
import { EntityService } from 'src/app/main/services/entity.service';
import { FileManagerService } from 'src/app/main/services/file-manager.service';
import { NotificationService } from 'src/app/main/services/notification.service';
import { TabService } from 'src/app/main/services/tab.service';
import { ClaimInterface } from 'src/app/shared/interfaces/data/claim-interface';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './whatsapp-chat.component.html',
  styleUrls: ['./whatsapp-chat.component.scss']
})
export class WhatsappChatComponent implements OnInit {
  claim: ClaimInterface;
  number: string = null;

  @ViewChild('scrollChat', { static: false }) scrollChat: any;
  @ViewChild('fileData', { static: false }) fileData: CollapseComponent;
  @ViewChild('fButton', { static: false }) fButton: MDBFileSelectDirective;

  message = '';

  public messages: Array<any> = [];
  private chat_data;

  public loading: boolean;


  private subscriptions: Array<Subscription> = [];

  formData = new FormData();
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  options: UploaderOptions

  filetypes = ['*'];

  private readonly whatsapp_filetypes = {
    audio: ['audio/aac', 'audio/mp4', 'audio/mpeg', 'audio/amr', 'audio/ogg'],
    image: ['image/jpeg', 'image/png'],
    video: ['video/mp4', 'video/3gp'],
    document:['text/plain', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
  }

  private selected_type: string;

  private local_blobs: Array<string>=[];

  public template_only: boolean = true;
  public show_template: boolean = false;

  selected_images: Array<any> =[];

  public has_solicitud_template: boolean;


  public claim_entity = this._entity.entities.claim;

  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _tabs: TabService,private _appconfig: AppConfigService,private sanitizer: DomSanitizer, private _notification: NotificationService, private _filemanager: FileManagerService, private _entity: EntityService) { }

  ngOnInit(): void {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    this.options = {
      concurrency: 1,
      maxUploads: 1,
      allowedContentTypes:['*']
    };


    this.loading = true;
    this.subscriptions.push(
      this._api.get('whatsappschats/'+this.number).subscribe(
        response => {
          this.has_solicitud_template = response.response.whatsappchat.firm.whatsapptemplates?.find(t => t.name == 'solicitud' && t.typewhatsapptemplatestatus_id == 2);
          console.log(this.has_solicitud_template)
          this.chat_data = response.response.whatsappchat;
          this.messages = response.response.whatsappchat.whatsappmessages.map(
            m=> {
              this.formatMessage(m);
              return m;
            }
          );

          this.checkLastReceivedMessage(response.response.whatsappchat.whatsappmessages);

            console.log(this.messages)
          this.loading = false;
          this.scroll();
        },
        error => {
          this.loading = false;
          this.modalRef.hide();
        }
      )
    );
  }

  private checkLastReceivedMessage(messages) {

    if(messages.length == 1) {
      //solo template
      this.template_only = true;
      let message_template = messages[0];
      let date_template = new Date(message_template.date_message);
      date_template.setHours(date_template.getHours() +2);
      let ahora = new Date();
      if(date_template < ahora) {
        this.show_template = true;
      } else {
        this.show_template = false;
      }

    } else {
      let all_received = messages.filter(m => m.received ==1);

      if(all_received.length > 0) {

        let last_received = all_received[all_received.length -1];

        let date_message = new Date(last_received.date_message);
        date_message.setHours(date_message.getHours() +24);
        let ahora = new Date();
        if(date_message < ahora) {
          this.template_only = true;
          this.show_template = true;
        } else {
          this.template_only = false;
          this.show_template = false;
        }



      } else {
        this.template_only = true;
      }
    }


  }

  openFirmTab(){
    this._tabs.addTab('firm', this.chat_data.firm);
    this.modalRef.hide();
  }

  private createURLObject(blob: Blob): SafeUrl {
    const object = URL.createObjectURL(blob);
    this.local_blobs.push(object);

    return this.sanitizer.bypassSecurityTrustUrl(object);
  }

  formatMessage(m): void {
    m.claim_document = m.documents?.find(d => d.claim_id === this.claim.id)
    switch(m.typewhatsappmessage_id) {
      //imagen
      case 2:
        if(m.path_relative) {
          m.filename = m.path_relative.split(/\\|\//).pop();
          m.thumbImg = "assets/images/imageError.png";
          let path = m.path_relative;
          if(m.path_relative_thumbnail){
            path = m.path_relative_thumbnail;
          }
          this.subscriptions.push(
            this._filemanager.getDocument(path, m.validate, m.name, m.mime).subscribe(
              resp => {
                  m.thumbImg =  this.createURLObject(resp.body);
                  console.log(m)
              },
              err => {
              })
          );
        }
        break;
      //audio
      case 3:
        if(m.path_relative && m.mime) {
          m.filename = m.path_relative.split(/\\|\//).pop();
          let codecs = m.mime.split(';').shift();
          if(codecs) {
            m.mime = codecs;
          }
          m.audio = null;
          this.subscriptions.push(
            this._filemanager.getDocument(m.path_relative, m.validate, m.name, m.mime).subscribe(
              resp => {
                m.audio = this.createURLObject(resp.body);

              },
              err => {
              })
          );
        }
        break;
      //documento
      case 4:
        if(m.path_relative) {
          m.filename = m.path_relative.split(/\\|\//).pop();
          m.file = null;
          this.subscriptions.push(
            this._filemanager.getDocument(m.path_relative, m.validate, m.name, m.mime).subscribe(
              resp => {
                m.file = this.createURLObject(resp.body);

              },
              err => {
              })
          );
        }
        break;
      //template
      case 5:
        m.message = m.message.replace(/\\n/g, "<br>");
        break;
      //video
      case 7:
        if(m.path_relative) {
          m.filename = m.path_relative.split(/\\|\//).pop();
          m.video = null;
          this.subscriptions.push(
            this._filemanager.getDocument(m.path_relative, m.validate, m.name, m.mime).subscribe(
              resp => {
                m.video = this.createURLObject(resp.body);
                console.log(m)
              },
              err => {
              })
          );
        }
        break;
      //sticker
      case 8:
        m.thumbImg = "assets/images/imageError.png";
        this.subscriptions.push(
          this._filemanager.getDocument(m.path_relative, m.validate, m.name,'image/webp').subscribe(
            resp => {
              m.thumbImg = this.createURLObject(resp.body);

            },
            err => {
            })
        );
        break;
      default:
        break;
    }

  }

  scroll(){
      setTimeout(() => {
        this.scrollChat.nativeElement.scrollTo(0, this.scrollChat.nativeElement.scrollHeight);
      }, 0)
  }

  reload(){
    this.loading = true;
    this.subscriptions.push(
      this._api.get('whatsappschats/'+this.number).subscribe(
        response => {
          this.chat_data = response.response.whatsappchat;
          this.messages = response.response.whatsappchat.whatsappmessages.map(
            m=> {
              this.formatMessage(m);
              return m;
            }
          );

          this.checkLastReceivedMessage(response.response.whatsappchat.whatsappmessages);

          this.loading = false;
          this.scroll();
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  sendMessage(){
    if(this.message != '') {
      if(!this.loading) {
        this.loading = true;
        const param = {
          type: 'text',
          to: this.number,
          message: this.message,
          whatsapp_chat_id: this.chat_data.id
        }
        this.subscriptions.push(
          this._api.post('whatsappsmessages', JSON.stringify(param)).subscribe(
            response => {
              this.message = '';
              this.reload();
            },
            error => {
              this.loading = false;
            }
          )
        )
      }
    }
  }

  sendFile() {
    console.log(this.files)
    if(this.files.length > 0) {
      if(!this.loading) {
        this.loading = true;


        const param = {
          type: this.selected_type,
          to: this.number,
          message: '',
          whatsapp_chat_id: this.chat_data.id
        }



        const event: UploadInput = {
         type: 'uploadAll',
         url: this._api.getAPIEndpoint()+'whatsappsmessages',
         method: 'POST',
         data: param,
         //headers: {'Authorization' : `Bearer ${localStorage.getItem('token')}`}
         withCredentials: true
         };

         console.log(event)

         this.uploadInput.emit(event);

      }
    }
  }

  sendTemplate(type: string){

    if(!this.loading) {
      let template_name = type+'_'+this.claim.firm.whatsapp_firm_alias;
      let template_message = this.claim.firm.whatsapptemplates.find(template => template.name === template_name);
      if(template_name && template_message) {
        this.loading = true;
        const param = {
          type: 'template',
          to: this.number,
          message: template_message,
          whatsapp_chat_id: this.chat_data.id,
          template_name: template_name
        }
        this.subscriptions.push(
          this._api.post('whatsappsmessages', JSON.stringify(param)).subscribe(
            response => {
              this.message = '';
              this.reload();
            },
            error => {
              this.loading = false;
            }
          )
        )
      }
    }
  }

  linkToClaim(data:any){
    this.loading = true;
    const params = {
      mime: data.mime,
      filename: data.filename,
      whatsapp_message_id: data.id,
      message: data.message,
      path_absolute: data.path_absolute,
      path_relative: data.path_relative,
      path_relative_thumbnail: data.path_relative_thumbnail,
      path_thumbnail: data.path_thumbnail,

      claim_id: this.claim.id
    }

    this.subscriptions.push(
      this._api.post('documents', JSON.stringify(params)).subscribe(
        response => {
          data.claim_document = params;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )

  }

  linkImage(ev: MdbCheckboxChange, data:any) {
      console.log(ev)
      console.log(data)
      if(ev.checked) {
        this.selected_images.push(data)
      } else {
        this.selected_images = this.selected_images.filter(s => s.id != data.id)
      }
     console.log(this.selected_images)
  }


  openFileSelect(type: string) {
    this.files = [];
    this.uploadInput.emit({ type: 'removeAll' });
    if(this.whatsapp_filetypes[type]) {
      this.filetypes = this.whatsapp_filetypes[type];
      this.selected_type = type;

     /*  this.options = {...{
        concurrency: 1,
        maxUploads: 1,
        allowedContentTypes: this.filetypes
      }
    } */
    //this.options.allowedContentTypes = [...['video/mp4']]

      setTimeout(() => {
        this.fButton.el.click()
      }, 1);
    } else {
      this.selected_type = null;
    }
  }



  showFiles() {
    let files = '';
    for (let i = 0; i < this.files.length; i ++) {
      files += this.files[i].name;
       if (!(this.files.length - 1 === i)) {
         files += ',';
      }
    }

    return files;
  }


 cancelUpload(): void {
  this.uploadInput.emit({ type: 'cancelAll' });
}

onUploadOutput(output: UploadOutput | any): void {
  console.log(output)
  if (output.type === 'rejected') {
    this.files = [];
  } else  if (output.type === 'allAddedToQueue') {
  } else if (output.type === 'addedToQueue') {
    this.files.push(output.file); // add file to array when added
  } else if (output.type === 'uploading') {
    // update current data in files array for uploading file
    const index = this.files.findIndex(file => file.id === output.file.id);
    this.files[index] = output.file;
  } else if (output.type === 'removed') {
    // remove file from array when removed
    this.files = this.files.filter((file: UploadFile) => file !== output.file);
  } else if (output.type === 'dragOver') {
    this.dragOver = true;
  } else if (output.type === 'dragOut') {
  } else if (output.type === 'drop') {
    this.dragOver = false;
  } else if (output.type === 'start') {
      this.loading = true;
  } else if (output.type === 'done') {

    this.loading = false;
    if(output.file.responseStatus === 200) {
      this.files = [];
      this.filetypes = ['*'];
      this.selected_type = null;
      this.reload()
    } else {
     // this.cancelUpload()
    // this.files=[]
    // this.uploadInput.emit({ type: 'removeAll' });
      this._notification.notificacionErrorManual('Error al enviar los archivos')
    }



  }

  this.showFiles();
}


deleteFile(file_id: string) {
  this.uploadInput.emit({ type: 'remove', id: file_id });
}

  onClosed(){
    this.chat_data = null;
    this.messages = null;
    this.message = null;
    this.filetypes = ['*'];
    this.selected_type = null;
    this.template_only = true;
    this.local_blobs.forEach(b => URL.revokeObjectURL(b));
    this.local_blobs = [];
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
