

export class UserFilters {



  public staticFilters;
  public optionalFilters;

  constructor(private entities){

    this.staticFilters = [
      {
        entity: this.entities.user.entity,
        field: {
          name: 'is_enabled',
          type: 'select',
          label: 'Estado',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
            {
              value:  0,
              text: 'Inactivo'
            },
            {
              value:  1,
              text: 'Activo'
            }
          ]
        }
      },
      {
        entity: this.entities.user.entity,
        field: {
          name: 'name',
          type: 'text',
          label: 'Nombre',
          width: 2
        }
      },
      {
        entity: this.entities.user.entity,
        field: {
          name: 'surname',
          type: 'text',
          label: 'Apellidos',
          width: 2
        }
      },
      {
        entity: this.entities.user.entity,
        field: {
          name: 'email',
          type: 'text',
          label: 'Email',
          width: 2
        }
      },
      
    ]

  }

}

