import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();

export class ClaimFilters {

  public staticFilters;
  public optionalFilters;
  public dropdowns = ['provider', 'guild', 'typecondition'];

  constructor(private entities){
    this.optionalFilters = [
      {
        entity: this.entities.company.entity,
        field: {
          name: 'id',
          type: 'select',
          label: 'Proveedor',
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: entities.company.entity
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: 'sub'+this.entities.company.entity,
        field: {
          name: 'name',
          type: 'text',
          label: 'Sub'+this.entities.company.labels.form,
          width: 2
        }
      },
      {
        entity: this.entities.guild.entity,
        field: {
          name: 'id',
          type: 'select',
          label: titleCasePipe.transform(this.entities.guild.labels.form),
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: entities.guild.entity
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: this.entities.user.entity,
        field: {
          name: 'name',
          type: 'text',
          label: titleCasePipe.transform(this.entities.user.labels.form),
          width: 2
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'created_at',
          type: 'date',
          label: 'Creado',
          width: 2,
          from: true
        }
      }
    ];

    this.staticFilters = [
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'number',
          type: 'text',
          label: 'Nº '+titleCasePipe.transform(this.entities.claim.labels.form),
          width: 2
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'number_provider',
          type: 'text',
          label: 'Nº Aviso',
          width: 2
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'typecondition_id',
          type: 'select',
          label: 'Estado',
          width: 1,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: 'typecondition'
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
          ]
        }
      },
      {
        entity: this.entities.client.entity,
        field: {
          name: 'name_complete',
          type: 'text',
          label: titleCasePipe.transform(this.entities.client.labels.form),
          width: 2
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'order_date',
          type: 'date',
          label: 'Fecha Encargo',
          width: 2,
          from: true
        }
      }

      /* {
        entity: this.entities.claim.entity,
        field: {
          name: 'from_created_at',
          type: 'date',
          label: 'Desde',
          width: 2
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'to_created_at',
          type: 'date',
          label: 'Hasta',
          width: 2
        }
      }, */
    ]

  }
}
