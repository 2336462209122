import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { humanizeBytes, MDBModalRef, UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { sendEmails } from 'src/app/shared/form-validators/custom-validators';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
  selected_account_id: string = null;
  accounts: Array<any>;
  reply_address: string = null;
  forward: boolean = null;
  forward_body: string = null;
  header_subject: string = '(sin asunto)';
  claim_id: number = null;

  private subscriptions: Array<Subscription> = []

  public formSendMail: FormGroup;

  public sending: boolean;

  // ADJUNTAR
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;
  humanizeBytes: Function;


  reload_sent = new Subject<boolean>();

  constructor(private _api: ApirequestService, public modalRef: MDBModalRef, private fb: FormBuilder) {
    this.humanizeBytes = humanizeBytes;
  }

  setForm(){
    this.formSendMail = this.fb.group({
      from:[this.selected_account_id],
      to:['',[Validators.required, sendEmails()]],
      subject:[null, Validators.required],
      body:''
    })

    if(this.forward) {
      this.formSendMail.controls.subject.setValue(this.header_subject)
      this.formSendMail.controls.body.setValue(this.forward_body)
    }

    this.formSendMail.controls.subject.valueChanges.subscribe(
      value => {
        if(value !== '') {
          this.header_subject = value;
        } else {
          this.header_subject = '(sin asunto)';
        }
      }
    )

    this.files = [];

    if(this.reply_address) {
      this.formSendMail.controls.to.setValue(this.reply_address)
    }

  }

  setCC(){
    if(this.formSendMail.controls.cc) {
      this.formSendMail.removeControl('cc')
    } else {
      this.formSendMail.addControl('cc', new FormControl('', [sendEmails()]))
    }
  }

  setCCO(){
    if(this.formSendMail.controls.cco) {
      this.formSendMail.removeControl('cco')
    } else {
      this.formSendMail.addControl('cco', new FormControl('', [sendEmails()]))
    }
  }

  onUploadOutput(output: UploadOutput | any): void {

    if (output.type === 'allAddedToQueue') {
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file.nativeFile); // add file to array when added
      console.log(this.files)
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.nativeFile.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.nativeFile.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }
  }

  removeAttach(index: number) {
    this.files.splice(index, 1);
  }

  searchKey(ev) {
    const key = ev.keyCode || ev.which;
    if (key == 13) {
      console.log('do')
    }
  }

  ngOnInit(): void {
    this.setForm();
  }


  sendMail(){
    this.formSendMail.markAllAsTouched();

    if(this.formSendMail.valid) {
      this.sending = true;
      let to = this.formSendMail.controls.to.value.split(';');
      //
      //let cco = this.formSendMail.controls.cco.value.split(';');

      let formData: any = new FormData();
      formData.append('subject', this.formSendMail.get('subject').value);
      formData.append('body', this.formSendMail.get('body').value);
      formData.append('to', to);

      if(this.formSendMail.controls.cc) {
        let cc = this.formSendMail.controls.cc.value.split(';');
        formData.append('cc', cc);
      }

      if(this.formSendMail.controls.cco) {
        let cco = this.formSendMail.controls.cco.value.split(';');
        formData.append('bcc', cco);
      }

      if(this.claim_id) {
        formData.append('claim_id', this.claim_id);
      }


      for (var i = 0; i < this.files.length; i++) {
        formData.append("file"+i.toString(), this.files[i]);
      }

      console.log(formData.value)
      this.subscriptions.push(
        this._api.postfiles('mailaccounts/' + this.formSendMail.controls.from.value + '/send', formData).subscribe(
          response => {

            this.modalRef.hide();

            if(this.claim_id) {
              this.reload_sent.next(true)
            } else {

              if(this.selected_account_id === this.formSendMail.controls.from.value) {
                this.reload_sent.next(true)
              }
            }

            this.sending = false;
          },
          error => {
            this.sending = false;
          }
        )
      )

    }
  }

  unsubscribeAll(){
    this.subscriptions.forEach(s => s.unsubscribe())
  }

}
