import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { BudgetFilters } from "../../data-classes";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class BudgetModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'file-invoice',
    list: 'file-invoice'
  }

  setModelData(models:any) {

    models.budget.icon = this.icon;

    models.budget.modules = ['billing'];

    models.budget.form_tabData = {
      entity: models.budget.entity,
      saveEndpoint: models.budget.saveEndpoint,
      entity_permissions: models.budget.permissions,
      entity_modules:models.budget.modules,
      new_register_dropdowns:['taxe', 'guild', 'serie', 'typeprovider', 'company_with_subcompanies', 'repairman', 'administrator'],
      edit_register_dropdowns: ['guild', 'taxe', 'typeexport', 'serie']
    }

    models.budget.list_tabData = {
      entity: models.budget.entity,
      entity_label: models.budget.labels.form,
      entity_permissions:models.budget.permissions,
      entity_modules:models.budget.modules,
      listParams: {
        list_entity: models.budget.list_entity,
        list_label: models.budget.labels.list,
        limit: 10,
        headers: [
          'Número',
          titleCasePipe.transform(models.serie.labels.form),
          titleCasePipe.transform(models.client.labels.form),
          titleCasePipe.transform(models.claim.labels.form),
          'Tramitador'
        ],
        fields: [
          {entity: models.budget.entity, field: 'number'},
          {entity: models.serie.entity, field: 'description'},
          {entity: models.client.entity, field: 'name_complete'},
          {entity: models.claim.entity, field: 'number'},
          {entity: models.user.entity, field: 'name'}
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new BudgetFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME[models.budget.entity],
        in_claim: {
          claim_id: null,
          limit:5,
          headers: [
            {label: 'Número', class: 'code-column'},
            {label: titleCasePipe.transform(models.serie.labels.form), class: 'numbers-column'},
            titleCasePipe.transform(models.client.labels.form),
            'Tramitador'
          ],
          fields: [
            {entity: models.budget.entity, field: 'number'},
            {entity: models.serie.entity, field: 'description'},
            {entity: models.client.entity, field: 'name_complete'},
            {entity: models.user.entity, field: 'name'}
          ],
          lines_limit: 10,
          lines_headers: [
            {header: titleCasePipe.transform(models.budget.labels.form), class: 'buttons-column'},
            {header: 'Exp.', class: 'icon-column'},
            {header: 'Código', class: 'code-column'},
            'Descripción',
            { header: 'Cantidad', class: 'numbers-column'},
            {header: 'Precio', class: 'numbers-column'},
            {header:'%Descuento', class: 'numbers-column'},
            {header:'%Impuesto', class: 'numbers-column'}
          ],
          lines_fields: [
            {
              field: 'budget_number',
              type: 'none',
            },
            {
              control: 'type_export_line_id',
              type: 'checkbox',
              default:  0,
              disabled: false,
              class: 'icon-column'
            },
            {
              control: 'code',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'description',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'quantity',
              type: 'number',
              default: 1,
              disabled: false
            },
            {
              control: 'price',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'discount',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'taxe_id',
              type: 'select',
              dropdown: 'taxes',
              field_value: 'taxe_value',
              select_value: 'id',
              select_label: 'value',
              addKey: true,
              clear: false,
              listen_changes: true,
              disabled: false,
              default: 1,
            }
          ],
          export_table_data: [
            {
              header: 'Número',
              entity: models.budget.entity,
              field_name: 'number',
              class: 'code-column'
            },
            {
              header: titleCasePipe.transform(models.client.labels.form),
              entity: models.client.entity,
              field_name: 'name_complete',
            },
            {
              header: 'Situación',
              entity: 'typeexport',
              field_name: 'name',
              class: 'code-column',
            },
            {
              header: 'total Base',
              entity: models.budget.entity,
              field_name: 'total_price',
              class: 'numbers-column',
            },
            {
              header: 'Total Imp.',
              entity: models.budget.entity,
              field_name: 'total_taxe',
              class: 'numbers-column',
            },
            {
              header: 'Total Neto',
              entity: models.budget.entity,
              field_name: 'total',
              class: 'numbers-column',
            },
          ],
          export_line_table_data: [
            {
              header: 'Código',
              field_name: 'code',
              class: 'code-column'
            },
            {
              header: 'Descripción',
              field_name: 'description'
            },
            {
              header: 'Precio',
              field_name: 'price',
              class: 'numbers-column'
            },
            {
              header: 'Cantidad',
              field_name: 'quantity',
              class: 'numbers-column'
            },
            {
              header: '%Desc',
              field_name: 'discount',
              class: 'numbers-column'
            },
            {
              header: '%Impto',
              field_name: 'taxe_value',
              class: 'numbers-column'
            },
            {
              header: 'Base',
              field_name: 'total_price',
              class: 'numbers-column'
            },
          ],
          export_destinations: [
            {
              entity: models.workorder.entity,
              label: titleCasePipe.transform(models.workorder.labels.form),
              list: models.workorder.list_entity
            },
            {
              entity: models.budget.entity,
              label: titleCasePipe.transform(models.budget.labels.form),
              list: models.budget.list_entity
            },
            {
              entity: models.deliverynote.entity,
              label: titleCasePipe.transform(models.deliverynote.labels.form),
              list: models.deliverynote.list_entity
            },
            {
              entity: models.invoice.entity,
              label: titleCasePipe.transform(models.invoice.labels.form),
              list: models.invoice.list_entity
            },
            {
              entity: models.invoiceproforma.entity,
              label: titleCasePipe.transform(models.invoiceproforma.labels.form),
              list: models.invoiceproforma.list_entity
            },
          ]
        }
    }


    }

  }

}
