<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <form [formGroup]="formNewWorkorder">
    <div style="margin: 10px;">
      <div class="row mt-2">
        <div class="col-lg">
          <div class="row">
            <mdb-checkbox class="mt-2 ml-3" [default]="true" [disabled]="data.parentData" [(ngModel)]="hasClaim" [ngModelOptions]="{standalone: true}" (change)="changeClaim($event)"></mdb-checkbox>
            <div class="md-form md-outline">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [disabled]="!hasClaim || data.parentData" id="seleccionarExpediente" mdbWavesEffect (click)="selectClaim()">
                <mdb-icon fas icon="folder-plus" size="lg"></mdb-icon>
              </button>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <input type="text" name="claim_number" formControlName="claim_number" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                <label for="claim_number" class="">Expediente</label>
                <mdb-error *ngIf="formNewWorkorder.controls.claim_number.invalid && (formNewWorkorder.controls.claim_number.dirty || formNewWorkorder.controls.claim_number.touched)">Seleccionar {{claim_entity.labels.form | titlecase}}</mdb-error>
              </div>
            </div>


          </div>
        </div>

        <div class="col-lg">
          <div class="row">
            <div class="md-form md-outline m-1">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" [disabled]="hasClaim" [mdbTooltip]="'Seleccionar '+(client_entity.labels.form | titlecase)" placement="top" id="seleccionarCliente" mdbWavesEffect (click)="selectClient()">
                <mdb-icon fas icon="user-check"></mdb-icon>
              </button>
            </div>

            <div class="md-form md-outline m-1">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" mdbWavesEffect [disabled]="hasClaim" [mdbTooltip]="'Crear '+(client_entity.labels.form | titlecase)" placement="top" (click)="newClient()">
                <mdb-icon fas icon="user-plus"></mdb-icon>
              </button>
            </div>

            <div class="col-lg">

              <div class="md-form md-outline">
                <input type="text" name="client_name" formControlName="client_name" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                <label for="client_name" class="">{{client_entity.labels.form | titlecase}}</label>
                <mdb-error *ngIf="formNewWorkorder.controls.client_name.invalid && (formNewWorkorder.controls.client_name.dirty || formNewWorkorder.controls.client_name.touched)">Seleccionar {{client_entity.labels.form | titlecase}}</mdb-error>
              </div>
            </div>

          </div>
        </div>

        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" placeholder="Elige un operario:" label="Operario" formControlName = "employee_id" [allowClear]="true">
              <mdb-select-option *ngFor="let employee of dropdowns?.employee" [value]="employee.id">{{ employee.name_complete }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>

        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" [placeholder]="'Elige un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName = "guild_id">
              <mdb-select-option *ngFor="let guild of dropdowns?.guild" [value]="guild.id">{{ guild.name }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+serie_entity.labels.form+':'" [label]="(serie_entity.labels.form |titlecase)" formControlName = "serie_id">
              <mdb-select-option *ngFor="let serie of dropdowns?.serie" [value]="serie.id">{{ serie.extend_description }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>

      </div>

    <!--LINEA 2proveedor-->
    <div class="row mt-3">

      <div class="col-lg">
        <div class="md-form md-outline">
          <ng-container *ngIf="hasClaim; else selectaddress">
            <div class="md-form md-outline" *ngIf="selected_claim_id">
              <input type="text" name="claim_address" formControlName="claim_address" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
              <label for="claim_address">Dirección</label>
            </div>
          </ng-container>
          <ng-template #selectaddress>
            <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
              <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
            </mdb-select-2>
            <mdb-error *ngIf="formNewWorkorder.controls.clientaddress_id.invalid && (formNewWorkorder.controls.clientaddress_id.dirty || formNewWorkorder.controls.clientaddress_id.touched)">Campo Obligatorio</mdb-error>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="hasClaim; else selectprovider">
        <div class="col-lg" *ngIf="selected_claim_id">
          <div class="md-form md-outline">
            <input type="text" name="provider_name" formControlName="provider_name"
            class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
            <label for="provider_name">Proveedor</label>
          </div>
        </div>
      </ng-container>
      <ng-template #selectprovider>
        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" placeholder="" label="Tipo Proveedor" formControlName="typeprovider">
              <mdb-select-option *ngFor="let type of provider_types" [value]="type.id">{{ type.name }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
        <ng-container [ngSwitch]="selected_typeprovider">
          <ng-container *ngSwitchCase="1">
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+company_entity.labels.form+':'" [label]="(company_entity.labels.form | titlecase)" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                  <mdb-select-option *ngFor="let company of companies" [value]="company.value">{{ company.label }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formNewWorkorder.controls.provider_id.invalid && (formNewWorkorder.controls.provider_id.dirty || formNewWorkorder.controls.provider_id.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" [placeholder]="'Elige una sub'+company_entity.labels.form+':'" [label]="'Sub'+company_entity.labels.form" formControlName="subprovider_id">
                  <mdb-select-option *ngFor="let company of subcompanies" [value]="company.value">{{ company.label }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                  <mdb-select-option *ngFor="let repairman of repairmen" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formNewWorkorder.controls.provider_id.invalid && (formNewWorkorder.controls.provider_id.dirty || formNewWorkorder.controls.provider_id.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                  <mdb-select-option *ngFor="let administrator of administrators" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formNewWorkorder.controls.provider_id.invalid && (formNewWorkorder.controls.provider_id.dirty || formNewWorkorder.controls.provider_id.touched)">Campo Obligatorio</mdb-error>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>


    </div>

    <!--LINEA 3 descripcion y pie-->
    <div class="row mt-3">
      <div class="col-lg">
        <div class="md-form md-outline">
          <textarea rows="3" type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
          <label for="description">Descripción</label>
        </div>
      </div>
      <div class="col-lg">
        <div class="md-form md-outline">
          <textarea rows="3"  type="text" id="footer" class="md-textarea form-control" placeholder="" mdbInput formControlName="footer"></textarea>
          <label for="footer">Pie</label>
        </div>
      </div>
    </div>
  </div>

  <!--SECCION CITA-->
  <div class="divCustomSection">
    <i class='fas fa-calendar-check mr-2'></i> Cita
  </div>
  <div style="margin: 10px;">
    <form [formGroup]="appointmentForm">
      <div class="row">
        <div class="col-1 mt-2">
          <mdb-checkbox [default]="true" formControlName="appointment_set" (change)="changeAppointment($event)"><span class="checkbox-label">Concertada</span></mdb-checkbox>
        </div>

        <div class="col">
          <div class="md-form md-outline" formGroupName="appointment">
            <mdb-date-picker mdbValidate [validateSuccess]="false" name="mydate2" [inline]="true" [options]="datepicker_options" formControlName="date" [label]="'Fecha Cita'"></mdb-date-picker>

            <mdb-error *ngIf="appointmentForm.controls.appointment['controls'].date.invalid &&
            (appointmentForm.controls.appointment['controls'].date.dirty || appointmentForm.controls.appointment['controls'].date.touched)">
              <span *ngIf="appointmentForm.controls.appointment['controls'].date.errors.date_error">Fecha incorrecta</span>
            </mdb-error>
          </div>
        </div>

        <div class="col">
          <div class="md-form md-outline" formGroupName="appointment">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="start" name="start" class="form-control mytp-time" formControlName="start" [mdbTimePicker]="timepickerinicio" readonly/>
            <label for="start">Hora Inicio</label>
            <mdb-error *ngIf="appointmentForm.controls.appointment['controls'].start.invalid && (appointmentForm.controls.appointment['controls'].start.dirty || appointmentForm.controls.appointment['controls'].start.touched)">
              <span *ngIf="appointmentForm.controls.appointment['controls'].start.errors.time_before">H. Inicio no permitida</span>
            </mdb-error>
            <mdb-timepicker-toggle [mdbTimePickerToggle]="timepickerinicio" *ngIf="appointmentForm.controls.appointment['controls'].start.enabled"></mdb-timepicker-toggle>
            <mdb-timepicker #timepickerinicio [twelveHour]="false" [min]="'08:00'" [max]="appointmentForm.controls.appointment['controls'].end.value" [clearButton]="''" [closeButton]="'Cerrar'" [okButton]="'Seleccionar'"></mdb-timepicker>
          </div>
        </div>

        <div class="col">
          <div class="md-form md-outline" formGroupName="appointment">
            <input mdbInput type="text" id="end" name="end" class="form-control mytp-time" formControlName="end" [mdbTimePicker]="timepickerfin" readonly/>
            <label for="end">Hora Fin</label>
            <mdb-timepicker-toggle [mdbTimePickerToggle]="timepickerfin" *ngIf="appointmentForm.controls.appointment['controls'].end.enabled"></mdb-timepicker-toggle>
            <mdb-timepicker #timepickerfin [twelveHour]="false" [min]="appointmentForm.controls.appointment['controls'].start.value" [max]="'20:00'" [clearButton]="''" [closeButton]="'Cerrar'" [okButton]="'Seleccionar'"></mdb-timepicker>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <div class="md-form md-outline" formGroupName="appointment">
            <textarea type="text" id="appointmenthead" class="md-textarea form-control" placeholder="" mdbInput formControlName="head"></textarea>
            <label for="appointmenthead">Cabecera</label>
          </div>
        </div>
        <div class="col-md">
          <div class="md-form md-outline" formGroupName="appointment">
            <textarea type="text" id="appointmentbody" class="md-textarea form-control" placeholder="" mdbInput formControlName="body"></textarea>
            <label for="appointmentbody">Descripción</label>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!--LINEAS-->
  <div class="divCustomSection">
    <i class='fas fa-money-bill mr-2'></i> Líneas
  </div>
  <div style="margin: 10px;">
    <div class="row mt-3 ">

      <div class="col">
        <app-detail-lines #detailComponent [master]="master_object" [taxes]="dropdowns?.taxe" [employees]="employees" [details]="[]" [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="false" (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
      </div>
    </div>
  </div>

</form>

<!--<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formNewWorkorder">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(claim_entity.labels.form | titlecase)+', '+(client_entity.labels.form | titlecase)+', Dirección, Proveedor'">
            <div class="row">
              <div class="col-md-2 mt-2">
                <div class="row">
                  <div class="col-1 custom-cell mt-2">
                    <mdb-checkbox [default]="true" [disabled]="data.parentData" [(ngModel)]="hasClaim" [ngModelOptions]="{standalone: true}" (change)="changeClaim($event)"></mdb-checkbox>
                  </div>
                  <div class="col">
                    <span class="character-counter">{{claim_entity.labels.form | titlecase}}</span>
                    <div class="md-form md-outline input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn-group" mdbDropdown>
                          <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" [disabled]="!hasClaim || data.parentData"
                          id="seleccionarExpediente" mdbWavesEffect (click)="selectClaim()">
                          <mdb-icon fas icon="folder-plus" size="lg"></mdb-icon>
                        </button>
                        </div>
                      </div>
                      <input type="text" name="claim_number" formControlName="claim_number" mdbValidate [validateSuccess]="false"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <mdb-error *ngIf="formNewWorkorder.controls.claim_number.invalid && (formNewWorkorder.controls.claim_number.dirty || formNewWorkorder.controls.claim_number.touched)">
                      Seleccionar {{claim_entity.labels.form | titlecase}}
                    </mdb-error>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md mt-2">

                <div class="row">
                  <div class="col">
                    <span class="character-counter">{{client_entity.labels.form | titlecase}}</span>
                    <div class="md-form md-outline input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn-group" mdbDropdown>
                          <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [disabled]="hasClaim" [mdbTooltip]="'Seleccionar '+(client_entity.labels.form | titlecase)" placement="top"
                          id="seleccionarCliente" mdbWavesEffect (click)="selectClient()">
                          <mdb-icon fas icon="user-check"></mdb-icon>
                        </button>
                        </div>
                      </div>
                      <input type="text" name="client_name" formControlName="client_name" mdbValidate [validateSuccess]="false"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <mdb-error *ngIf="formNewWorkorder.controls.client_name.invalid && (formNewWorkorder.controls.client_name.dirty || formNewWorkorder.controls.client_name.touched)">
                      Seleccionar {{client_entity.labels.form | titlecase}}
                    </mdb-error>
                    <div class="input-group-append" id="button-addon4">
                      <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" mdbWavesEffect [disabled]="hasClaim" [mdbTooltip]="'Crear '+(client_entity.labels.form | titlecase)" placement="top" (click)="newClient()">
                        <mdb-icon fas icon="user-plus"></mdb-icon>
                      </button>
                    </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md mt-2">
                <div class="md-form md-outline">
                  <ng-container *ngIf="hasClaim; else selectaddress">
                    <div class="md-form md-outline" *ngIf="selected_claim_id">
                      <input type="text" name="claim_address" formControlName="claim_address"
                      class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                      <label for="claim_address">Dirección</label>
                    </div>
                  </ng-container>
                  <ng-template #selectaddress>
                    <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                      <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                    </mdb-select-2>
                    <mdb-error *ngIf="formNewWorkorder.controls.clientaddress_id.invalid && (formNewWorkorder.controls.clientaddress_id.dirty || formNewWorkorder.controls.clientaddress_id.touched)">
                      Campo Obligatorio
                    </mdb-error>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <ng-container *ngIf="hasClaim; else selectprovider">
                <div class="col-md-3" *ngIf="selected_claim_id">
                  <div class="md-form md-outline">
                    <input type="text" name="provider_name" formControlName="provider_name"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <label for="provider_name">Proveedor</label>
                  </div>
                </div>
              </ng-container>
              <ng-template #selectprovider>
                <div class="col-md-3">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="" label="Tipo Proveedor" formControlName="typeprovider">
                      <mdb-select-option *ngFor="let type of dropdowns?.typeprovider" [value]="type.id">{{ type.name }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>


                  <ng-container [ngSwitch]="selected_typeprovider">
                    <ng-container *ngSwitchCase="1">
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+company_entity.labels.form+':'" [label]="(company_entity.labels.form | titlecase)" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                            <mdb-select-option *ngFor="let company of companies" [value]="company.value">{{ company.label }}</mdb-select-option>
                          </mdb-select-2>
                          <mdb-error *ngIf="formNewWorkorder.controls.provider_id.invalid && (formNewWorkorder.controls.provider_id.dirty || formNewWorkorder.controls.provider_id.touched)">
                            Campo Obligatorio
                          </mdb-error>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" [placeholder]="'Elige una sub'+company_entity.labels.form+':'" [label]="'Sub'+company_entity.labels.form" formControlName="subprovider_id">
                            <mdb-select-option *ngFor="let company of subcompanies" [value]="company.value">{{ company.label }}</mdb-select-option>
                          </mdb-select-2>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                            <mdb-select-option *ngFor="let repairman of dropdowns?.repairman" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
                          </mdb-select-2>
                          <mdb-error *ngIf="formNewWorkorder.controls.provider_id.invalid && (formNewWorkorder.controls.provider_id.dirty || formNewWorkorder.controls.provider_id.touched)">
                            Campo Obligatorio
                          </mdb-error>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                            <mdb-select-option *ngFor="let administrator of dropdowns?.administrator" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
                          </mdb-select-2>
                          <mdb-error *ngIf="formNewWorkorder.controls.provider_id.invalid && (formNewWorkorder.controls.provider_id.dirty || formNewWorkorder.controls.provider_id.touched)">
                            Campo Obligatorio
                          </mdb-error>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

              </ng-template>

            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>

      <div class="col-6 custom-card-padding mt-3">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(guild_entity.labels.form |titlecase) +', '+(serie_entity.labels.form |titlecase)+', Descripción (opcional)'">
            <div class="row">
              <div class="col-md">
                <mdb-select-2 [outline]="false" [placeholder]="'Elige un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName = "guild_id">
                  <mdb-select-option *ngFor="let guild of dropdowns?.guild" [value]="guild.id">{{ guild.name }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <div class="col-md">
                <div class="md-form">
                  <mdb-select-2 [outline]="false" [placeholder]="'Elige una '+serie_entity.labels.form+':'" [label]="(serie_entity.labels.form |titlecase)" formControlName = "serie_id">
                    <mdb-select-option *ngFor="let serie of dropdowns?.serie" [value]="serie.id">{{ serie.extend_description }}</mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md">
                <div class="md-form">
                  <textarea type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
                  <label for="description">Descripción</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
      <div class="col-6 custom-card-padding mt-3">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="Operarios y Cita (opcional)">
            <div class="row">
              <div class="col-5">
                <div class="md-form md-outline">
                  <mdb-select-2 [outline]="false" placeholder="Elige un operario:" label="Operario" formControlName = "employee_id" [allowClear]="true">
                    <mdb-select-option *ngFor="let employee of dropdowns?.employee" [value]="employee.id">{{ employee.name_complete }}</mdb-select-option>
                  </mdb-select-2>

                </div>
              </div>
            </div>
            <form [formGroup]="appointmentForm">
            <div class="row mt-4">
              <div class="col-1">
                  <mdb-checkbox [default]="true" formControlName="appointment_set" (change)="changeAppointment($event)"><span class="checkbox-label">Cita</span></mdb-checkbox>
              </div>
              <div class="col">
                <div class="md-form" formGroupName="appointment">
                  <mdb-date-picker mdbValidate [validateSuccess]="false" name="mydate2" [inline]="true" [options]="datepicker_options" formControlName="date" [label]="'Fecha Cita'"></mdb-date-picker>

                  <mdb-error *ngIf="appointmentForm.controls.appointment['controls'].date.invalid &&
                  (appointmentForm.controls.appointment['controls'].date.dirty || appointmentForm.controls.appointment['controls'].date.touched)">
                    <span *ngIf="appointmentForm.controls.appointment['controls'].date.errors.date_error">Fecha incorrecta</span>
                  </mdb-error>
                </div>
              </div>
              <div class="col">
                <div class="md-form" formGroupName="appointment">
                  <input
                      mdbInput
                      mdbValidate
                      [validateSuccess]="false"
                      type="text"
                      id="start"
                      name="start"
                      class="form-control mytp-time"
                      formControlName="start"
                      [mdbTimePicker]="timepickerinicio"
                      readonly
                    />
                    <label for="start">Hora Inicio</label>
                    <mdb-error *ngIf="appointmentForm.controls.appointment['controls'].start.invalid &&
                  (appointmentForm.controls.appointment['controls'].start.dirty || appointmentForm.controls.appointment['controls'].start.touched)">
                    <span *ngIf="appointmentForm.controls.appointment['controls'].start.errors.time_before">H. Inicio no permitida</span>
                  </mdb-error>
                    <mdb-timepicker-toggle
                      [mdbTimePickerToggle]="timepickerinicio"
                      *ngIf="appointmentForm.controls.appointment['controls'].start.enabled"
                    ></mdb-timepicker-toggle>
                    <mdb-timepicker #timepickerinicio
                    [twelveHour]="false"
                    [min]="'08:00'"
                    [max]="appointmentForm.controls.appointment['controls'].end.value"
                    [clearButton]="''"
                    [closeButton]="'Cerrar'"
                    [okButton]="'Seleccionar'"
                    ></mdb-timepicker>
                </div>
              </div>
              <div class="col">
                <div class="md-form" formGroupName="appointment">
                  <input
                      mdbInput
                      type="text"
                      id="end"
                      name="end"
                      class="form-control mytp-time"
                      formControlName="end"
                      [mdbTimePicker]="timepickerfin"
                      readonly
                    />
                    <label for="end">Hora Fin</label>
                    <mdb-timepicker-toggle
                      [mdbTimePickerToggle]="timepickerfin"
                      *ngIf="appointmentForm.controls.appointment['controls'].end.enabled"
                    ></mdb-timepicker-toggle>
                    <mdb-timepicker #timepickerfin
                    [twelveHour]="false"
                    [min]="appointmentForm.controls.appointment['controls'].start.value"
                    [max]="'20:00'"
                    [clearButton]="''"
                    [closeButton]="'Cerrar'"
                    [okButton]="'Seleccionar'"
                    ></mdb-timepicker>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md">
                <div class="md-form" formGroupName="appointment">
                  <textarea type="text" id="appointmenthead" class="md-textarea form-control" placeholder="" mdbInput formControlName="head"></textarea>
                  <label for="appointmenthead">Cabecera</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form" formGroupName="appointment">
                  <textarea type="text" id="appointmentbody" class="md-textarea form-control" placeholder="" mdbInput formControlName="body"></textarea>
                  <label for="appointmentbody">Descripción</label>
                </div>
              </div>
            </div>


          </form>
          </mdb-tab>
        </mdb-tabset>
      </div>

      <div class="col-12">
        <app-detail-lines #detailComponent [master]="master_object" [taxes]="dropdowns?.taxe" [details]="[]" [employees]="employees"
        [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="false" (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
      </div>
    </div>
  </form>
</div>
-->
