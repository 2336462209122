import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();

export class ActionFilters {



  public staticFilters;
  public optionalFilters =[];

  public dropdowns = ['typeaction', 'typecondition']

  constructor(private entities){

    this.staticFilters = [

      {
        entity: this.entities.action.entity,
        field: {
          name: 'close',
          type: 'select',
          label: 'Estado',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todas'
            },
            {
              value:  0,
              text: 'Abiertas'
            },
            {
              value:  1,
              text: 'Cerradas'
            }
          ]
        }
      },
      {
        entity: this.entities.action.entity,
        field: {
          name: 'typeaction_id',
          type: 'select',
          label: 'Tipo',
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: 'typeaction'
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
          ]
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'number',
          type: 'text',
          label: titleCasePipe.transform(this.entities.claim.labels.form),
          width: 2
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'typecondition_id',
          type: 'select',
          label: 'Estado '+titleCasePipe.transform(this.entities.claim.labels.form),
          width: 1,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: 'typecondition'
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
          ]
        }
      },
      {
        entity: this.entities.action.entity,
        field: {
          name: 'created_at',
          type: 'date',
          label: 'Creada',
          width: 2,
          from: true
        }
      }

    ]

  }

}

