import { Injectable } from '@angular/core';

import { IMyOptions } from 'ng-uikit-pro-standard';

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return '';
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

interface NgbDateStruct {
    /**
     * The year, for example 2016
     */
    year: number;
    /**
     * The month, for example 1=Jan ... 12=Dec
     */
    month: number;
    /**
     * The day of month, starting at 1
     */
    day: number;
}

@Injectable()
export class DateFormats {
    private hoy = new Date();
    private ayer = this.hoy.setDate(new Date().getDate() - 1)

    public opcionesMDBDatePicker: IMyOptions = {
        // Strings and translations
        dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab' },
        dayLabelsFull: { su: "Domingo", mo: "Lunes", tu: "Martes", we: "Miércoles", th: "Jueves", fr: "Viernes", sa: "Sábado" },
        monthLabels: { 1: 'Enero', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
        monthLabelsFull: { 1: "Enero", 2: "Febrero", 3: "Marzo", 4: "Abril", 5: "Mayo", 6: "Junio", 7: "Julio", 8: "Agosto", 9: "Septiembre", 10: "Octubre", 11: "Noviembre", 12: "Diciembre" },

        // Buttons
        todayBtnTxt: "Hoy",
        closeBtnTxt: "Cerrar",

        //Ocultar botón Limpiar
        showClearDateBtn: false,

        // Format
        dateFormat: 'dd/mm/yyyy',
       // dateFormat: 'yyyy-mm-dd',

        //Textos
        ariaLabelNextMonth: 'Mes Siguiente',
        ariaLabelNextYear: 'Año Siguiente',
        ariaLabelPrevMonth: 'Mes Anterior',
        ariaLabelPrevYear: 'Año Anterior',

        closeAfterSelect: true,

        editableDateField: false,
    }

    public opcionesMDBDatePicker_desdeHoy: IMyOptions = {
        // Strings and translations
        dayLabels: { su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab' },
        dayLabelsFull: { su: "Domingo", mo: "Lunes", tu: "Martes", we: "Miércoles", th: "Jueves", fr: "Viernes", sa: "Sábado" },
        monthLabels: { 1: 'Enero', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
        monthLabelsFull: { 1: "Enero", 2: "Febrero", 3: "Marzo", 4: "Abril", 5: "Mayo", 6: "Junio", 7: "Julio", 8: "Agosto", 9: "Septiembre", 10: "Octubre", 11: "Noviembre", 12: "Diciembre" },

        // Buttons
        todayBtnTxt: "Hoy",
        closeBtnTxt: "Cerrar",

        //Ocultar botón Limpiar
        showClearDateBtn: false,

        // Format
        dateFormat: 'dd/mm/yyyy',

        //Textos
        ariaLabelNextMonth: 'Mes Siguiente',
        ariaLabelNextYear: 'Año Siguiente',
        ariaLabelPrevMonth: 'Mes Anterior',
        ariaLabelPrevYear: 'Año Anterior',

        closeAfterSelect: true,
        disableUntil: { year: this.hoy.getFullYear(), month: this.hoy.getMonth() + 1, day: this.hoy.getDate() },
        editableDateField: false
    }

    /*  public myDatePickerOptions: IMyDpOptions = {
         // other options...
         dateFormat: 'dd/mm/yyyy',
         editableDateField: false,
         showClearDateBtn: false,
         openSelectorOnInputClick: true
     }; */

    parse(value: string) {
        if (value) {
            const dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            }
        }
        return null;
    }

    format(date): string {
        let stringDate: string = '';
        if (date) {
            stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
            stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
            stringDate += date.year;
        }
        return stringDate;
    }

    cambioFormato(fecha: string){
      let stringDate: string = '';
      let array_fecha = fecha.split('-');
      stringDate = array_fecha[2]+'/'+array_fecha[1]+'/'+array_fecha[0];
      return stringDate;
  }

    formatoSave(fecha: string): string {
        let stringDate: string = '';
        let array_fecha = fecha.split('/');
        stringDate = array_fecha[2] + '-' + array_fecha[1] + '-' + array_fecha[0];
        return stringDate;
    }

    tickToDate(tick) {
        const ticksToMicrotime = tick / 10000;
        const epochMicrotimeDiff = Math.abs(new Date(Date.UTC(0, 0, 1)).setFullYear(0));
        // new date is ticks, converted to microtime, minus difference from epoch microtime
        const tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);


        return tickDate;
    }

    getTickTime(tickDate) {
        let hora: any = tickDate.getUTCHours();
        if (hora < 10) {
            hora = '0' + hora;
        }

        let minutos: any = tickDate.getMinutes();
        if (tickDate.getSeconds() > 30) {
            minutos++;
        }
        if (minutos < 10) {
            minutos = '0' + minutos;
        }

        return hora + ':' + minutos;
    }

    dateToTick() {
        let yourDate = new Date();  // for example
        // the number of .net ticks at the unix epoch
        let epochTicks = 621355968000000000;

        // there are 10000 .net ticks per millisecond
        let ticksPerMillisecond = 10000;

        // calculate the total number of .net ticks for your date
        yourDate.setHours(yourDate.getHours() + 2);
        let yourTicks = epochTicks + (yourDate.getTime() * ticksPerMillisecond);

        return yourTicks;
    }

    //comparación de horas
    compareHours(horaInicio: string, horaFin: string): boolean {

        let inicio = horaInicio.split(':');
        let fin = horaFin.split(':');

        let sHour = inicio[0];
        let sMinute = inicio[1];

        let eHour = fin[0];
        let eMinute = fin[1];

        if (sHour < eHour) {
            return true;
        }
        else {
            if (sHour == eHour) {
                if (sMinute < eMinute || sMinute == eMinute) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
    }

    //comparación de fechas en formato 'YYYY-MM-DD'
    compareDates(fechaInicio: string, fechaFin: string, horaInicio?: string, horaFin?: string): boolean {

        let inicio = fechaInicio.split('-').reverse();
        let fin = fechaFin.split('-').reverse();

        let sDay = inicio[0];
        let sMonth = inicio[1];
        let sYear = inicio[2];

        let eDay = fin[0];
        let eMonth = fin[1];
        let eYear = fin[2];

        if (sYear < eYear) {
            return true;
        }
        else {
            if (sYear == eYear) {
                if (sMonth < eMonth) {
                    return true;
                }
                else {
                    if (sMonth == eMonth) {
                        if (sDay < eDay) {
                            return true;
                        }
                        else {
                            if (sDay == eDay) {
                                if (horaInicio == undefined && horaFin == undefined) {
                                    return true;
                                } else {
                                    return this.compareHours(horaInicio, horaFin);
                                }
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else {
                        return false;
                    }
                }
            }
            else {
                return false;
            }
        }
    }

    primerDiaSemana(d) {
        d = new Date(d);

        let day = d.getDay();
        let diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

        return new Date(d.setDate(diff)).toLocaleDateString();
    }

    ultimoDiaSemana(d) {
        d = new Date(d);

        let day = d.getDay();
        let diff = d.getDate() - day + 7;

        return new Date(d.setDate(diff)).toLocaleDateString();
    }

    fromDate(months:number) {
      let today = new Date();

      today.setMonth(today.getMonth() - months);
      return today.toLocaleDateString();
    }

    /**
     * Devuelve la fecha en formato 01/01/2023 del primer dia del mes a partir de un parametro date que le pasemos
     * @param date
     * @returns
     */
    firtsDayMonth(date: Date) {
        let y = date.getFullYear();
        let m = date.getMonth();

        let firstDay = new Date(y, m, 1);
        return firstDay.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
    }

    /**
     * Devuelve la fecha en formato 31/01/2023 del ultimo dia del mes a partir de un parametro date que le pasemos
     * @param date
     * @returns
     */
    lastDayMonth(date: Date) {
        let y = date.getFullYear();
        let m = date.getMonth();

        let lastDay = new Date(y, m + 1, 0);
        return lastDay.toLocaleDateString("es-ES", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
    }

    /**
     * Devuleve el primer día del mes anterior
     * @param month número de mes (Enero: 0)
     * @returns p.ej: 01/01/2023
     */
    primerDiaMesAnterior(month: number) {
        const date = new Date();
        let mes = month - 1;
        let year = date.getFullYear();
        let d: Date;
        if (mes == -1) {

            year--;
            d = new Date(year, 11);

        } else {
            d = new Date(year, mes);
        }

        let dia: any = d.getDate();
        if (dia < 10) {
            dia = '0' + dia;
        }

        let mes1: any = d.getMonth() + 1;
        if (mes1 < 10) {
            mes1 = '0' + mes1
        }

        return dia + '/' + mes1 + '/' + d.getFullYear();
    }

    ultimoDiaMesAnterior(month: number) {


        const date = new Date();
        let mes = month - 1;
        let year = date.getFullYear();
        let d: Date;
        if (mes == -1) {
            year--;
            d = new Date(year, 12, 0);

        } else {
            d = new Date(year, mes + 1, 0);
        }

        let dia: any = d.getDate();
        if (dia < 10) {
            dia = '0' + dia;
        }

        let mes1: any = d.getMonth() + 1;
        if (mes1 < 10) {
            mes1 = '0' + mes1
        }

        return dia + '/' + mes1 + '/' + d.getFullYear();

    }

    getCurrentTime() {
        let date = new Date();
        let hh: any = date.getHours();
        let mm: any = date.getMinutes();

        hh = hh < 10 ? '0' + hh : hh;
        mm = mm < 10 ? '0' + mm : mm;

        let curr_time = hh + ':' + mm;
        return curr_time;
    }

    getCurrentDate() {
        let date = new Date().toLocaleDateString();

        let split_date = date.split('/');
        let day = +split_date[0] < 10 ? '0' + split_date[0] : split_date[0];
        let month = +split_date[1] < 10 ? '0' + split_date[1] : split_date[1];

        let curr_date = day + '/' + month + '/' + split_date[2];

        return curr_date;
    }

    getLastWeekDate() {
        let d = new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString();
        let split_date = d.split('/');
        let day = +split_date[0] < 10 ? '0' + split_date[0] : split_date[0];
        let month = +split_date[1] < 10 ? '0' + split_date[1] : split_date[1];

        let curr_date = day + '/' + month + '/' + split_date[2];

        return curr_date;
    }

    getDateFromDateTime(datetime: string): string {
        let date = '';
        let split_datetime = datetime.split(' ');

        date = split_datetime[0];

        return date;
    }

    getTimeFromDateTime(datetime: string): string {
        let time = '';
        let split_datetime = datetime.split(' ');

        let time_split = split_datetime[1].split(':');

        time = time_split[0] + ':' + time_split[1];

        return time;
    }

    formatDateObject(date: Date): string {
        let stringDate: string = '';
        if (date) {
            let format_date: NgbDateStruct = {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
            }
            stringDate += isNumber(format_date.day) ? padNumber(format_date.day) + '/' : '';
            stringDate += isNumber(format_date.month) ? padNumber(format_date.month) + '/' : '';
            stringDate += format_date.year;
        }
        return stringDate;
    }

    formatTimeObject(date: Date): string {
        let stringTime: string = '';
        if (date) {
            stringTime += date.getHours();
            stringTime += ':'
            stringTime += (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        }

        return stringTime;
    }

}
