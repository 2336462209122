import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Observable, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ActionButtonsService } from 'src/app/main/services/action-buttons.service';
import { DropdownService } from 'src/app/main/services/dropdown.service';
import { NotificationService } from 'src/app/main/services/notification.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { IdNameInterface } from 'src/app/shared/interfaces/data/id-name-interface';

@Component({
  selector: 'app-taxe-form',
  templateUrl: './taxe-form.component.html',
  styleUrls: ['./taxe-form.component.scss']
})
export class TaxeFormComponent {

  header: string;
  register:any;
  entity: string
  endpoint: string

  private subscriptions: Array<Subscription> = [];

  public typetaxes: IdNameInterface[];
  public typetaxesmodels: IdNameInterface[];

  public formTaxe: FormGroup;

  private id: number|null;

  private dateformats = new DateFormats;
  public datepicker_options = this.dateformats.opcionesMDBDatePicker;


  private utils = new PGAUtils;

  public loading: boolean;


  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _dropdowns: DropdownService,private _notification: NotificationService, private fb: FormBuilder, private _buttons: ActionButtonsService) {

  }

  ngOnInit(): void {
    this.getDropdowns();
    this.setForm();
  }

  getDropdowns() {
    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(['typetaxe', 'typetaxemodel']).subscribe(
        response => {
          this.typetaxes = this.utils.objectDeepCopy(response.response.dropdown.typetaxe);
          this.typetaxesmodels = this.utils.objectDeepCopy(response.response.dropdown.typetaxemodel);
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  setForm(){
    this.formTaxe = this.fb.group({
      id:[{value: null, disabled: true}],
      name: ['', [Validators.required]],
      type_taxe_id: [null, [Validators.required]],
      type_taxe_model_id: [null, [Validators.required]],
      value: [0, [Validators.required, Validators.min(0)]],
      enabled: [true],
      default: [false],
      use_start_date:[this.dateformats.getCurrentDate(), Validators.required],
      use_end_date:[this.dateformats.getCurrentDate(), Validators.required]
    });

    if(this.register) {
      Object.keys(this.formTaxe.controls).forEach(
        control => {
          if(control.includes('date')) {
            this.formTaxe.controls[control].setValue(this.dateformats.cambioFormato(this.register[control]));
          } else {
            this.formTaxe.controls[control].setValue(this.register[control]);
          }
        }
      );

      this.formTaxe.controls.type_taxe_model_id.disable();
    }
  }

  submit(){
    this.formTaxe.markAllAsTouched();

    if(this.formTaxe.valid) {

      this._buttons.setLoading(true);
      let formValues = {...this.formTaxe.value};

      formValues.use_start_date = this.dateformats.formatoSave(formValues.use_start_date);
      formValues.use_end_date = this.dateformats.formatoSave(formValues.use_end_date);

      let params = {
        taxe: formValues
      };

      let api_call: Observable<any>;
      if(this.register) {
        api_call = this._api.put(this.endpoint+'/'+this.register.id, JSON.stringify(params));
      } else {
        api_call = this._api.post(this.endpoint, JSON.stringify(params));
      }


      this.subscriptions.push(
        api_call.subscribe(
          response => {
            let msg = '';
            if(this.register) {
              msg = this._notification.getUpdateMsg();
            } else {
              msg = this._notification.getInsertMsg();

            }
            this._notification.notificacionOK(msg);
            this._buttons.reloadListTab(this.endpoint);
            this._buttons.setLoading(false);
            this.modalRef.hide();
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      );

    }
  }

  onClosed() {
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
    this.header = null;

  }

}
