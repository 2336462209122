import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoicing',
  templateUrl: './invoicing.component.html',
  styleUrls: ['./invoicing.component.scss']
})
export class InvoicingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
