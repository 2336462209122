import { TitleCasePipe } from "@angular/common";
import { UserFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";
import { UsersComponent } from "src/app/main/lists/users/users.component";
import { Tab } from "src/app/main/tab-models/tab.model";
import { Type } from "@angular/core";

const titleCasePipe = new TitleCasePipe();

export class UserModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'user',
    list: 'users'
  }

  setModelData(models:any) {

    models.user.icon = this.icon;

    models.user.form_tabData = {
      entity: models.user.entity,
      saveEndpoint: models.user.saveEndpoint,
      entity_permissions: models.user.permissions,
      entity_label: models.user.labels.form,
      new_register_dropdowns:['user', 'mailaccount', 'firm'],
      edit_register_dropdowns:['user', 'mailaccount', 'firm'],
    }

    models.user.list_tabData = {
      entity: models.user.entity,
      entity_label: models.user.labels.form,
      entity_permissions:models.user.permissions,
      listParams: {
        list_entity: models.user.list_entity,
        list_label: models.user.labels.list,
        limit: 15,
        //cabecera y campos definidos en el componente personalizado (UsersComponent)
        headers: [],
        fields: [],
        web_access: false,
        disable_user: false,
        filter_firm: false,
        filters: new UserFilters(models)
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      UsersComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: true
      },
      tabData
      )
  }

  getFormTab(component: Type<any>, entity_data, register, header_field): Tab {
    let tabData = {...entity_data.form_tabData};

    tabData.id = register.id;


    /**
     * Si el registro tiene campo number, es el que se muestra en la cabecera de la pestaña
     * Si no tiene campo number, tiene que tener campo headerData, para volver a generar la pestaña en la recarga
     */
    let header = null;
    if(register.name) {
      header = register.name;
      if(register?.surname) {
        header += ' ' + register.surname
    }
    } else if(register.headerData){
      header = register.headerData
    } else {
      throw new Error('Incorrect Tab Params')
    }

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>"+header,
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
    )
  }
}
