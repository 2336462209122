import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Tab } from '../tab-models/tab.model';
import { LicenseService } from './license.service';
import { ModulesService } from './modules.service';
import { UserPermissionsService } from './user-permissions.service';
import { EntityService } from './entity.service';
import { AdministrationModel, ComunicationsModel, InvoicingModel, ManagementModel, ProcessingModel } from 'src/app/shared/models/sections';
import { SinglePageService } from './single-page.service';
import { AppNamePipe } from 'src/app/shared/pipes/app-name.pipe';
import { FirmsService } from './firms.service';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  public tabs: Tab[] = [];
  public tabSub = new BehaviorSubject<Tab[]>(this.tabs);

  constructor(private _userpermissions: UserPermissionsService, private _modules: ModulesService, private _license: LicenseService, private _entity: EntityService, private _singlepage: SinglePageService, private _appNamePipe: AppNamePipe, private _firms: FirmsService) {}

  /**
   * Añade una pestaña
   * @param entity identificador de la entidad de la que crear la pestaña
   * @param register registro seleccionado: SÓLO PARA PESTAÑAS DE FORMULARIO
   * @param type tipo de la entidad para entidades compartidas (PROVIDERS)
   * @param container_data datos del registro contenedor al abrir una pestaña de nuevo registro desde el contenedor (p,ej: datos del exp al abrir pestaña de nuevo parte desde el exp)
   * @param filters filtros predefindos que se le pasan a los listados para que se abra la pestaña ya filtrada
   */
  public addTab(entity: string, register?: any, type?: number, container_data?:any, filters?:any): void {

    let tabPermission: string;
    let tabModules: Array<string>;

    let license_limit = false;


    const models = this._entity.entities;
    const singlepages = this._singlepage.singlepages;


    /**
     * 1. Instancia de la variable Tab según los parámetros recibidos
     */
    let tab: Tab;
    switch (entity) {
      /**
       * Módulos
       */
      case 'adminpage':
        tab = this._singlepage.getPageTab(entity);
        tab.tabHeader+=this._appNamePipe.transform()
        tabPermission = tab.tabData.permissions.list;
        break;
      case 'buyingpage':
        tab = this._singlepage.getPageTab(entity);
        tabPermission = tab.tabData.permissions.list;
      break;
      /**
       * Perfil
       */
      case 'userprofile':
        tab = this._singlepage.getPageTab(entity);
        break;
      /**
       * Secciones
       */
      case 'administration':
        tab = new AdministrationModel().getTab();
        break;
      case 'management':
        tab = new ManagementModel().getTab();
        break;
      case 'processing':
        tab = new ProcessingModel().getTab();
        break;
      case 'invoicing':
        tab = new InvoicingModel().getTab();
        tabModules = tab.tabData.entity_modules;
        break;
      case 'communications':
        tab = new ComunicationsModel().getTab();
        break;
      /**
       * Importador de baremos
       */
       case 'scalesimporter':
        tab = this._singlepage.getPageTab(entity);
        tabModules = tab.tabData.modules;
        break;
      /**
       * Listados
       */

      case 'firms':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.firm.permissions.list;
        break;
      case 'taxes':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.taxe.permissions.list;
        break;
      case 'guilds':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.guild.permissions.list;
        break;
      case 'series':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.serie.permissions.list;
        break;
      case 'users':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.user.permissions.list;
      break;
      case 'mailaccounts':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.mailaccount.permissions.list;
        tabModules = models.mailaccount.modules;
      break;
      case 'providers':
          tab = this._entity.getEntityTab(entity, null, type);
          switch (type) {
            case models.company.entity_type:
                tabPermission = models.company.permissions.list;
                tabModules = models.company.modules;
              break;
            case models.repairman.entity_type:
                tabPermission = models.repairman.permissions.list;
                tabModules = models.repairman.modules;
              break;
            case models.administrator.entity_type:
                tabPermission = models.administrator.permissions.list;
                tabModules = models.administrator.modules;
              break;

            default:
              break;
          }

      break;
      case 'clients':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.client.permissions.list;
        break;
      case 'employees':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.employee.permissions.list;
        break;
      case 'agents':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.agent.permissions.list;
        break;
      case 'experts':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.expert.permissions.list;
        break;
      case 'workorders':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.workorder.permissions.list;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'claims':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.claim.permissions.list;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'invoices':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.invoice.permissions.list;
        tabModules = models.invoice.modules;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'invoicesproforma':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.invoiceproforma.permissions.list;
        tabModules = models.invoiceproforma.modules;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'budgets':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.budget.permissions.list;
        tabModules = models.budget.modules;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'deliverynotes':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.deliverynote.permissions.list;
        tabModules = models.deliverynote.modules;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'scales':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.scale.permissions.list;
        break;
      case 'expenses':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.expense.permissions.list;
        tabModules = models.expense.modules;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }

        break;
      case 'liquidations':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.liquidation.permissions.list;
        tabModules = models.liquidation.modules;

        if(filters) {
          tab.tabData.filtersValues = filters;
        }
        break;
      case 'emails':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.email.permissions.list;
        tabModules = models.email.modules;
        break;
      case 'comunicationsprotocols':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.comunicationprotocol.permissions.list;
        tabModules = models.comunicationprotocol.modules;
        break;
      case 'actions':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.action.permissions.list;
        tabModules = models.action.modules;
        break;
      case 'tasks':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.task.permissions.list;
        tabModules = models.task.modules;
        break;
      /**
       * Formularios Alta
       */
      case 'newfirm':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.firm.permissions.modify;
        break;
      case 'newprovider':
        tab = this._entity.getEntityTab(entity, null, type);
          switch (type) {
            case models.company.entity_type:
                tabPermission = models.company.permissions.list;
                tabModules = models.company.modules;
              break;
            case models.repairman.entity_type:
                tabPermission = models.repairman.permissions.list;
                tabModules = models.repairman.modules;
              break;
            case models.administrator.entity_type:
                tabPermission = models.administrator.permissions.list;
                tabModules = models.administrator.modules;
              break;

            default:
              break;
          }
        break;
      /* case 'newcompany':
        tab = new TabNewCompany().getTab();
        tabModules = tab.tabData.entity_modules;
        break; */
      case 'newemployee':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.employee.permissions.modify;
        break;
      case 'newagent':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.agent.permissions.modify;
        break;
      case 'newexpert':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.expert.permissions.modify;
        break;
      case 'newliquidation':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.liquidation.permissions.modify;
        break;
      case 'newclaim':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.claim.permissions.modify;
        license_limit = this._license.checkRemainingClaims();
        break;
      case 'newworkorder':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.workorder.permissions.modify;
        license_limit = this._license.checkRemainingWorkorders();
        if(container_data) {
          tab.tabData.parentData = container_data;
        }
        break;
      case 'newinvoice':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.invoice.permissions.modify;
        if(container_data) {
          tab.tabData.parentData = container_data;
        }
        break;
      case 'newinvoiceproforma':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.invoiceproforma.permissions.modify;
        if(container_data) {
          tab.tabData.parentData = container_data;
        }
        break;
      case 'newbudget':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.budget.permissions.modify;
        if(container_data) {
          tab.tabData.parentData = container_data;
        }
        break;
      case 'newdeliverynote':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.deliverynote.permissions.modify;
        if(container_data) {
          tab.tabData.parentData = container_data;
        }
        break;
      case 'newexpense':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.expense.permissions.modify;
        if(container_data) {
          tab.tabData.parentData = container_data;
        }
        break;
      case 'newmailaccount':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.mailaccount.permissions.modify;
        break;
      case 'newscale':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.scale.permissions.modify;
        break;
      case 'newclient':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.client.permissions.modify;
        break;
      case 'newuser':
        tab = this._entity.getEntityTab(entity);
        tabPermission = models.user.permissions.modify;
        break;
      /**
       * Formularios Edición
       */
      case 'firm':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.firm.permissions.modify;
        break;
      case 'user':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.user.permissions.list;
        break;
      /* case 'repairman':
        tab = new TabRepairman().getTab(register);
        break; */
      case 'provider':
        tab = this._entity.getEntityTab(entity, register, type);

        switch (type) {
          case models.company.entity_type:
            tabPermission = models.company.permissions.form;
            tabModules = models.company.entity_modules;
            break;
          case models.repairman.entity_type:
            tabPermission = models.repairman.permissions.form;
            tabModules = models.repairman.entity_modules;
            break;
          case models.administrator.entity_type:
            tabPermission = models.administrator.permissions.form;
            tabModules = models.administrator.entity_modules;
            break;

          default:
            break;
        }

        break;
      case 'client':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.client.permissions.list;
        break;
      case 'employee':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.employee.permissions.list;
        break;
      case 'agent':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.agent.permissions.list;
        break;
      case 'expert':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.expert.permissions.list;
        break;
      case 'claim':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.claim.permissions.list;
        break;
      case 'workorder':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.workorder.permissions.form;
        break;
      case 'scale':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.scale.permissions.form;
        break;
      case 'invoice':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.invoice.permissions.form;
        tabModules = models.invoice.entity_modules;
        break;
      case 'invoiceproforma':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.invoiceproforma.permissions.form;
        tabModules = models.invoiceproforma.entity_modules;
        break;
      case 'budget':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.budget.permissions.form;
        tabModules = models.budget.entity_modules;
        break;
      case 'deliverynote':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.deliverynote.permissions.form;
        tabModules = models.deliverynote.entity_modules;
        break;
      case 'expense':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.expense.permissions.form;
        tabModules = models.expense.entity_modules;
        break;
      case 'liquidation':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.liquidation.permissions.form;
        tabModules = models.liquidation.entity_modules;
        break;
      case 'mailaccount':
        tab = this._entity.getEntityTab(entity, register);
        tabPermission = models.mailaccount.permissions.form;
        tabModules = models.mailaccount.entity_modules;
        break;
      /**
       * Error
       */
      default:
        console.log(entity)
        throw new Error('404 Not Found')
        break;

    }

    //Compueba modulos antes de abrir pestaña
    if(!tabModules || this._modules.checkModules(tabModules)) {
      //Después de comprobar el módulo de la pestaña se comprueba si el usuario tiene permiso
      if(!tabPermission || this._userpermissions.checkPermission(tabPermission)) {

        if(!license_limit) {
          /**
           * Si se va a abrir una pestaña de un registro, primero se comprueba que no haya una abierta de ese registro
           */
          if (register) {
            let tabIndex = this.tabExists(entity, register.id)
            if (tabIndex > -1) {
              //si ya hay una pestaña abierta de ese registro se pone como activa
              tabIndex++
              this.changeActiveTab(tabIndex)
            } else {
              //si no, se abre una nueva pestaña
              this.createTab(tab)
            }
          } else {
            let tabIndex = this.tabEntityExists(entity, tab);
            if(tabIndex>-1) {
              tabIndex++
              this.changeActiveTab(tabIndex)
            } else {
              this.createTab(tab)
            }
          }
        }
      } else {
        throw new Error('403 Forbidden')
      }
    } else {
      throw new Error('403 Forbidden')
    }


  }

  /**
   * Busca en las pestañas la del registro pasado como parámetro
   * @param entity entidad
   * @param id id del registro
   * @returns índice de la pestaña abierta de ese registro
   */
  private tabExists(entity: string, id: any): number {
    return this.tabs.findIndex(tab => tab.tabData.entity === entity && tab.tabData.id === id)
  }

  private tabEntityExists(entity: string, tab: Tab): number {
    if(tab.tabData.newRegister) {
      if(tab.tabData.entity_type) {
        return this.tabs.findIndex(t => t.tabData.entity === tab.tabData.entity && t.tabData.entity_type === tab.tabData.entity_type && t.tabData.newRegister)
      }
      return this.tabs.findIndex(t => t.tabData.entity === tab.tabData.entity && t.tabData.newRegister)
    } else {
      return this.tabs.findIndex(t => t.tabData.entity === entity)
    }
  }

  private createTab(tab: Tab): void {
    /**
     *  Con la variable tab instanciada se añade al array de tabs y se marca como activa     *
     */

    let active = this.tabs.find(t => t.tabActive === true);
    if(active) {
      active.tabActive = false;
    }
    /* for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].tabActive === true) {
        this.tabs[i].tabActive = false;
      }
    } */

    if (this.tabs.length === 0) {
      tab.tabId = 1;
    } else {
      tab.tabId = this.tabs[this.tabs.length - 1].tabId + 1;
    }
    tab.tabActive = true;
    ///TODO: buscar listado de la entidad y abrir pestaña de registro al lado
    this.tabs.push(tab);
    this.tabSub.next(this.tabs);
  }

  /**
   * Obtiene el índice de la pestaña en el array de pestañas y llama a removeTab para eliminarla
   * @param tabId tabId de la pestaña: se usará para encontrar el índice en el array
   */
  public removeTabByTabId(tabId: number): void {
    let index = this.tabs.findIndex(tab => tab.tabId === tabId);
    this.removeTab(index)
  }

  /**
   * Elimina la pestaña del array
   * @param index Índice del Array de tabs a borrar
   */
  public removeTab(index: number) {
    //Elimina del array
    this.tabs.splice(index, 1);

    //Marcar activa de las pestañas restantes:
    if (this.tabs.length > 0) {

      //Si sólo queda una, se marca esa
      if (this.tabs.length === 1) {
        this.tabs[0].tabActive = true;

      } else {
        //Si hay más de una
        if (index < this.tabs.length) {
          //si la que se ha borrado no es la última, se marca como activa la que ocupa su lugar
          this.tabs[index].tabActive = true;
        } else {
          //si la que se ha borrado es la última se marca como activa la última del array
          this.tabs[index - 1].tabActive = true;
        }
      }

    }

    this.tabSub.next(this.tabs);
  }

  /**
   * Marca como activa en el array de tabs la pestaña seleccionada en el componente Mat-Tab-Group
   * @param index Índice de la pestaña seleccionada en el Mat-Tab-Group.
   */
  public changeActiveTab(index: number) {

    for (let i = 0; i < this.tabs.length; i++) {
      //index -1 porque hay una pestaña fija en en el Mat-Tab-Group, el índice en el array de tabs es el anterior
      if (i === (index - 1)) {
        this.tabs[i].tabActive = true;
      } else {
        this.tabs[i].tabActive = false;
      }
    }

    this.tabSub.next(this.tabs)

  }



  public closeAllTabs() {
    this.tabs = [];
    this.tabSub.next(this.tabs);
  }


  /**
   * Guarda em sessionStorage la información necesaria de las pestañas abiertas para poder regenerarlas posteriormente
   */
  public saveOpenTabs() {
    let tabsInfo = [];
    for (let i = 0; i < this.tabs.length; i++) {
      //no se guardan las pestañas de nuevo registro
      if(!this.tabs[i].tabData.noRecover) {
        let info = { entity: '', entity_type: null, entity_modules: null, id: null, header: null, active: false, filters_values:null, firm_id: null };


      if (this.tabs[i].tabData.listParams) {
        info.entity = this.tabs[i].tabData.listParams.list_entity;
      } else {
        info.entity = this.tabs[i].tabData.entity;
      }

      if(this.tabs[i].tabData.entity_type) {
        info.entity_type = this.tabs[i].tabData.entity_type
      }

      info.active = this.tabs[i].tabActive;
      if (this.tabs[i].tabData.id) {

        info.id = this.tabs[i].tabData.id
        info.header = this.tabs[i].tabData.headerData;

        if(this.tabs[i].tabData.firm_id) {
          info.firm_id = this.tabs[i].tabData.firm_id
        }
      }

      if (this.tabs[i].tabData.entity_modules) {
        info.entity_modules = this.tabs[i].tabData.entity_modules;
      }

      if (this.tabs[i].tabData.tab_filters) {
        info.filters_values = this.tabs[i].tabData.tab_filters;
      }

      tabsInfo.push(info)
      }
    }
    sessionStorage.setItem('openedTabs', btoa(JSON.stringify(tabsInfo)))
    //sessionStorage.setItem('openedTabs', JSON.stringify(tabsInfo))
  }

  /**
   * Lee de sessionStorage la informacion de las pestañas que hay que volver a generar
   */
  public recoverTabs() {

    if (sessionStorage.getItem('openedTabs')) {
      let tabs = JSON.parse(atob(sessionStorage.getItem('openedTabs')));
      //let tabs = JSON.parse(sessionStorage.getItem('openedTabs'));

      sessionStorage.removeItem('openedTabs')


      for (let i = 0; i < tabs.length; i++) {

        let render_tab = true;
        if(tabs[i].entity_modules) {
          if(!this._modules.checkModules(tabs[i].entity_modules)) {
            render_tab = false;
          }
        }

        if(render_tab) {
          if (tabs[i].id === null) {
            //Listado
            if(tabs[i].entity_type) {
              this.addTab(tabs[i].entity, null, tabs[i].entity_type, null, tabs[i].filters_values);
            } else {
              this.addTab(tabs[i].entity, null,null,null,tabs[i].filters_values);
            }
          } else {
            //Formulario
            if (tabs[i].header) {

              let recover = true;
              let reg:{[key:string]: any} = { id: tabs[i].id, headerData: tabs[i].header }

              //Si la pestaña de formulario tiene firm_id, se compara con la empresa activa para saber si se recupera la pestaña o no
              if(tabs[i].firm_id) {
                if(this._firms.getActiveFirm() != tabs[i].firm_id) {
                  recover = false;
                } else {
                  reg.firm_id = tabs[i].firm_id
                }
              }

             if(recover) {
              if(tabs[i].entity_type) {
                this.addTab(tabs[i].entity, reg, tabs[i].entity_type);
              } else {
                this.addTab(tabs[i].entity, reg);
              }

             }
            }
          }
        }

      }

      let previousActiveIndex = tabs.findIndex(t => t.active === true)
      this.changeActiveTab(++previousActiveIndex);
    }
  }

}
