<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
  <!--PESTAÑA INFORMACION-->
  <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">

    <ng-container *ngIf="formUser">
      <form [formGroup]="formUser">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-checkbox [default]="true" formControlName="is_enabled"><b style="color: gray;"> Usuario activo</b></mdb-checkbox>
          </mdb-card-header>

        </mdb-card>
        <div class="row mt-4">
          <!--NOMBRE-->
          <div class="col-lg">
            <div class="md-form md-outline">
              <input mdbInput type="text" id="username" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
              <label for="username" class="">Nombre</label>
              <mdb-error *ngIf="formUser.controls.name.invalid && (formUser.controls.name.dirty || formUser.controls.name.touched)">Campo obligatorio</mdb-error>
            </div>
          </div>
          <!--APELLIDOS-->
          <div class="col-lg">
            <div class="md-form md-outline">
              <input mdbInput type="text" id="usersurname" formControlName="surname" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                <label for="usersurname" class="">Apellidos</label>
                <mdb-error *ngIf="formUser.controls.surname.invalid && (formUser.controls.surname.dirty || formUser.controls.surname.touched)">Campo obligatorio</mdb-error>
            </div>
          </div>

          <!--EMAIL-->
          <div class="col-lg">
            <div class="row">
              <div class="col-9">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="email" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" placeholder="" formControlName="email">
                  <label for="email">Email</label>
                  <mdb-error *ngIf="formUser.controls.email.invalid && (formUser.controls.email.dirty || formUser.controls.email.touched)">
                    <ng-container *ngFor="let error of formUser.controls.email.errors | keyvalue">
                      <ng-container [ngSwitch]="error.key">
                        <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                        <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                        <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>
                      </ng-container>
                    </ng-container>
                  </mdb-error>
                </div>
              </div>

              <div class="col-3">
                <!--ICONO CANDADO-->
                <ng-container *appPermissions="'modify_users'">
                  <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.email.disabled">
                    <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock" mdbWavesEffect (click)="unblockModal('email')">
                      <mdb-icon fas icon="lock"></mdb-icon>
                    </button>
                  </div>
                  <!--ICONO PARA BLOQUEAR-->
                  <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.email.enabled">
                    <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock-email" mdbWavesEffect (click)="relockField('email')">
                      <mdb-icon fas icon="unlock"></mdb-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>


          <!--PASSWORD-->
          <div class="col-lg">
            <div class="row">
              <div class="col-9">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="password" mdbValidate [validateSuccess]="false" class="form-control form-control-sm block-unblock" placeholder="" formControlName="password">
                  <label for="password">Nueva Contraseña</label>
                  <mdb-error *ngIf="formUser.controls.password.invalid && (formUser.controls.password.dirty || formUser.controls.password.touched)">
                    <ng-container *ngFor="let error of formUser.controls.password.errors | keyvalue">
                      <ng-container [ngSwitch]="error.key">
                        <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                      </ng-container>
                    </ng-container>
                  </mdb-error>
                </div>
              </div>

              <div class="col-3">
                <!--ICONO CANDADO-->
                <ng-container *appPermissions="'modify_users'">
                  <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.password.disabled">
                    <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock-newpassword" mdbWavesEffect (click)="unblockModal('password')">
                      <mdb-icon fas icon="lock"></mdb-icon>
                    </button>
                  </div>
                  <!--ICONO PARA BLOQUEAR-->
                  <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.password.enabled">
                    <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock" mdbWavesEffect (click)="relockField('password')">
                      <mdb-icon fas icon="undo"></mdb-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </mdb-tab>

  <!--PESTAÑA CUENTAS DE CORREO -->
  <mdb-tab heading="<i class='fas fa-envelope mr-2'></i> Cuentas de correo">
    <div class="row">
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="tasks" size="lg" class="mr-1 text-primary"></mdb-icon>Cuentas de correo vinculadas al usuario
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Cuenta de correo</th>
                  <th scope="col" class="buttons-column">Pred.</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let account of linked_accounts; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <ng-container *appPermissions="'modify_users'">
                        <a (click)="removeAccount(account)" *ngIf="!disableChanges()">
                          <mdb-icon fad icon="times-circle" class="red-text" size="lg"></mdb-icon>
                        </a>
                      </ng-container>
                    </td>
                    <td>
                      <span>{{account.mail}}</span>
                    </td>
                    <td class="buttons-column">
                      <ng-container *appPermissions="'modify_users'">
                        <mdb-checkbox [default]="true" [checked]="account.default" (change)="onChangeDefault($event, account)"></mdb-checkbox>
                      </ng-container>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="list-ul" size="lg" class="mr-1 text-primary"></mdb-icon>Cuentas de correo disponibles
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Cuenta de correo</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let account of available_accounts; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <ng-container *appPermissions="'modify_users'">
                        <a (click)="addAccount(account)" *ngIf="!disableChanges()">
                          <mdb-icon fad icon="plus-circle" class="blue-text" size="lg"></mdb-icon>
                        </a>
                      </ng-container>
                    </td>
                    <td><span>{{account.mail}}</span></td>
                  </tr>
                </ng-container>
              </tbody>

            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </mdb-tab>

  <!--EMPRESAS-->

  <mdb-tab heading="<i class='fas fa-building mr-2'></i> Empresas">
    <div class="row">
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="tasks" size="lg" class="mr-1 text-primary"></mdb-icon>Empresas vinculadas al usuario
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col" class="buttons-column">Pred.</th>
                  <th scope="col">Empresa</th>
                  <th scope="col" class="medium-column">Rol</th>
                  <th scope="col">Permisos adicionales</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let firm of user_firms; let i=index">
                    <tr mdbTableCol class="firm-row" [class.selected]="firm.firm_id == selected_firm?.firm_id">
                      <td class="buttons-column">
                        <a (click)="removeFirm(firm)" *ngIf="!disableRoleChanges()">
                          <mdb-icon fad icon="times-circle" size="lg" class="red-text"></mdb-icon>
                        </a>
                      </td>
                      <td class="buttons-column">
                        <mdb-checkbox [default]="true" [formControl]="firm.default_form"></mdb-checkbox>
                      </td>
                      <td>
                        <span>{{firm.firm_name}}</span>
                      </td>
                      <td>
                        <select class="form-control form-control-sm" [formControl]="firm.role_form">
                          <option *ngFor="let option of roles" [value]="option.value">{{ option.label }}</option>
                        </select>
                      </td>

                      <td>
                        <ng-container *ngIf="firm.role_form.value == 3; else role2">
                          <span>
                            <a (click)="loadAdditionalPermissions(firm)">
                              <mdb-icon fad icon="magic" size="lg" class="green-text"></mdb-icon>
                              <label class="ml-2">Revisar</label>
                            </a>
                          </span>
                        </ng-container>
                        <ng-template #role2>
                          <span>
                            <mdb-icon fad icon="badge-check" size="lg" class="green-text"></mdb-icon>
                            <label class="ml-2">Todos</label>
                          </span>
                        </ng-template>
                      </td>
                    </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="list-ul" size="lg" class="mr-1 text-primary"></mdb-icon>Empresas disponibles
          </mdb-card-header>
          <mdb-card-body>
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Empresa</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let firm of available_firms; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="addFirm(firm)" *ngIf="!disableRoleChanges()">
                        <mdb-icon fad icon="plus-circle" class="blue-text" size="lg"></mdb-icon>
                      </a>
                    </td>
                    <td><span>{{firm.name}}</span></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
    <div class="row" *ngIf="selected_firm">
      <div class="col-md">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">


            <mdb-icon fad icon="badge-check" size="lg" class="mr-1 text-primary"></mdb-icon>Permisos Adicionales para {{selected_firm.firm_name}}
            <div class="float-right">
              <a (click)="savePermissions()">
                <mdb-icon fad icon="save" size="lg" class="text-primary"></mdb-icon>
              </a>


            <a (click)="selected_firm = null" class="ml-3">
              <mdb-icon fad icon="times-circle" size="lg"></mdb-icon>
            </a>
            </div>
          </mdb-card-header>
          <mdb-card-body>
            <div class="row">
              <ng-container [formGroup]="formPermissions">
                <ng-container *ngFor="let permission of app_permissions">
                  <div class="col-md-12">
                    <mdb-checkbox [default]="true" formControlName="{{permission.permission}}">
                      {{permission.label}}
                    </mdb-checkbox>
                  </div>
                </ng-container>

              </ng-container>
            </div>
          </mdb-card-body>
          <!-- <mdb-card-footer>
            <a (click)="savePermissions()">
              <mdb-icon fad icon="save" size="md" class="text-primary"></mdb-icon>
            </a>
            <a (click)="selected_firm = null">
              <mdb-icon fad icon="times" size="md" class="text-secondary"></mdb-icon>
            </a>
          </mdb-card-footer> -->
        </mdb-card>
      </div>
    </div>
  </mdb-tab>

</mdb-tabset>

<!--

  <div class="pt-2">

  <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

    <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
      <ng-container *ngIf="formUser">
        <form [formGroup]="formUser">
          <div class="row">
            <div class="col-lg">
              <div class="md-form">
                  <input mdbInput type="text" id="username" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                  <label for="username" class="">Nombre</label>
                  <mdb-error *ngIf="formUser.controls.name.invalid && (formUser.controls.name.dirty || formUser.controls.name.touched)">
                      Campo obligatorio</mdb-error>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form">
                  <input mdbInput type="text" id="usersurname" formControlName="surname" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                  <label for="usersurname" class="">Apellidos</label>
                  <mdb-error *ngIf="formUser.controls.surname.invalid && (formUser.controls.surname.dirty || formUser.controls.surname.touched)">
                      Campo obligatorio</mdb-error>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form input-group mb-3">
                <input mdbInput type="text" id="email" mdbValidate [validateSuccess]="false" class="form-control form-control-sm block-unblock" placeholder="" formControlName="email">
                <label for="email">Email</label>
                <ng-container *appPermissions="'modify_users'">
                  <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.email.disabled">
                    <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock" mdbWavesEffect (click)="unblockModal('email')">
                      <mdb-icon fas icon="lock"></mdb-icon>
                    </button>
                  </div>

                  <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.email.enabled">
                    <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock-email" mdbWavesEffect (click)="relockField('email')">
                      <mdb-icon fas icon="undo"></mdb-icon>
                    </button>
                  </div>
                </ng-container>
                <mdb-error *ngIf="formUser.controls.email.invalid && (formUser.controls.email.dirty || formUser.controls.email.touched)">
                  <ng-container *ngFor="let error of formUser.controls.email.errors | keyvalue">
                    <ng-container [ngSwitch]="error.key">
                      <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                      <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                      <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>

                    </ng-container>
                  </ng-container>

                </mdb-error>
              </div>
            </div>
            <div class="col-lg" *appPermissions="'modify_users'">
              <div class="md-form input-group mb-3">
                <input mdbInput type="text" id="password" mdbValidate [validateSuccess]="false" class="form-control form-control-sm block-unblock" placeholder="" formControlName="password">
                <label for="password">Nueva Contraseña</label>
                <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.password.disabled">
                  <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock-newpassword" mdbWavesEffect (click)="unblockModal('password')">
                    <mdb-icon fas icon="lock"></mdb-icon>
                  </button>
                </div>
                <div class="input-group-append" *ngIf="!disableChanges() && formUser?.controls.password.enabled">
                  <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 block-unblock" type="button" id="button-addon-lock" mdbWavesEffect (click)="relockField('password')">
                    <mdb-icon fas icon="undo"></mdb-icon>
                  </button>
                </div>
                <mdb-error *ngIf="formUser.controls.password.invalid && (formUser.controls.password.dirty || formUser.controls.password.touched)">
                  <ng-container *ngFor="let error of formUser.controls.password.errors | keyvalue">
                    <ng-container [ngSwitch]="error.key">
                      <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>

                    </ng-container>
                  </ng-container>

                </mdb-error>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
      <div class="row mt-3">
        <div class="col-md">
          <mdb-card class="z-depth-0">
            <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                <mdb-icon fad
                icon="at"
                size="md"
                class="mr-1 text-primary"></mdb-icon> Cuentas de Correo
            </mdb-card-header>
            <mdb-card-body>
              <div class="row">
                <div class="col-6">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        Vinculadas
                    </mdb-card-header>
                    <mdb-card-body class="scroll-card-body">
                      <table mdbTable small="true">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Cuenta</th>
                            <th scope="col">Predet.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let account of linked_accounts; let i=index">
                            <tr mdbTableCol>
                              <td>
                                <ng-container *appPermissions="'modify_users'">
                                  <a (click)="removeAccount(account)">
                                    <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                                  </a>
                                </ng-container>
                              </td>
                              <td>
                                {{account.mail}}
                              </td>
                              <td>
                                <ng-container *appPermissions="'modify_users'">
                                  <mdb-checkbox [default]="true" [checked]="account.default" (change)="onChangeDefault($event, account)"></mdb-checkbox>
                                </ng-container>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </mdb-card-body>
                  </mdb-card>
                </div>
                <div class="col-6">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        Disponibles
                    </mdb-card-header>
                    <mdb-card-body class="scroll-card-body">
                      <table mdbTable small="true">
                        <thead>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let account of available_accounts; let i=index">
                            <tr mdbTableCol>
                              <td>
                                <ng-container *appPermissions="'modify_users'">
                                  <a (click)="addAccount(account)">
                                    <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                                  </a>
                                </ng-container>
                              </td>
                              <td>
                                {{account.mail}}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </mdb-tab>
    <mdb-tab heading="<i class='fas fa-building'></i> Empresas" *appPermissions="'list_firm_users'">

        <div class="row">
          <div class="col-md">

            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                  <mdb-icon fad icon="building" size="lg" class="mr-1 text-primary"></mdb-icon>Empresas del {{data.entity_label}}
              </mdb-card-header>
              <mdb-card-body>
                <table mdbTable small="true">
                  <thead>
                      <tr>
                          <th scope="col"></th>
                          <th scope="col" class="text-center">Pred.</th>
                          <th scope="col">Empresa</th>
                          <th scope="col">Rol</th>
                          <th scope="col" class="text-center">P. Adicionales</th>
                      </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let firm of user_firms; let i=index">
                        <tr mdbTableCol class="firm-row" [class.selected]="firm.firm_id == selected_firm?.firm_id">
                          <td>
                            <a (click)="removeFirm(firm)" *ngIf="!disableRoleChanges()">
                              <mdb-icon fad icon="times-circle" size="sm" class="red-text"></mdb-icon>
                            </a>
                          </td>
                          <td class="text-center">
                            <mdb-checkbox [default]="true" [formControl]="firm.default_form"></mdb-checkbox>
                          </td>
                          <td (click)="loadAdditionalPermissions(firm)">
                            {{firm.firm_name}}
                          </td>
                          <td>
                            <select class="browser-default custom-select cell-select" [formControl]="firm.role_form">
                              <option *ngFor="let option of roles" [value]="option.value">{{ option.label }}</option>
                            </select>
                          </td>
                          <td class="text-center" (click)="loadAdditionalPermissions(firm)">
                            <ng-container *ngIf="hasAditionalPermissions(firm.firm_id) && firm.role_form.value == 3">
                              <mdb-icon fad icon="badge-check" size="sm" class="text-info"></mdb-icon>
                            </ng-container>
                          </td>
                        </tr>
                    </ng-container>
                  </tbody>
                </table>
              </mdb-card-body>
            </mdb-card>

          </div>
          <div class="col-md-4">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                  <mdb-icon fad icon="building" size="lg" class="mr-1 text-primary"></mdb-icon>Empresas disponibles
              </mdb-card-header>
              <mdb-card-body>

                <table mdbTable small="true">
                  <tbody>
                    <ng-container *ngFor="let firm of available_firms; let i=index">
                      <tr mdbTableCol>
                        <td>
                          <a (click)="addFirm(firm)" *ngIf="!disableRoleChanges()">
                            <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                          </a>
                        </td>
                        <td>
                          {{firm.name}}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
        <div class="row" *ngIf="selected_firm">
          <div class="col-md">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                <div class="float-left">
                  <a (click)="savePermissions()">
                    <mdb-icon fad icon="save" size="lg" class="text-primary"></mdb-icon>
                  </a>


                <a (click)="selected_firm = null" class="ml-3">
                  <mdb-icon fad icon="times-circle" size="lg"></mdb-icon>
                </a>
                </div>

                {{selected_firm.firm_name}}: <mdb-icon fad icon="badge-check" size="lg" class="mr-1 text-primary"></mdb-icon>Permisos Adicionales

              </mdb-card-header>
              <mdb-card-body>
                <div class="row">
                  <ng-container [formGroup]="formPermissions">
                    <ng-container *ngFor="let permission of app_permissions">
                      <div class="col-md-12">
                        <mdb-checkbox [default]="true" formControlName="{{permission.permission}}">
                          {{permission.label}}
                        </mdb-checkbox>
                      </div>
                    </ng-container>

                  </ng-container>
                </div>
              </mdb-card-body>
              <!-- <mdb-card-footer>
                <a (click)="savePermissions()">
                  <mdb-icon fad icon="save" size="md" class="text-primary"></mdb-icon>
                </a>
                <a (click)="selected_firm = null">
                  <mdb-icon fad icon="times" size="md" class="text-secondary"></mdb-icon>
                </a>
              </mdb-card-footer> -->
 <!--           </mdb-card>
          </div>
        </div>

    </mdb-tab>
  </mdb-tabset>
</div>

-->
