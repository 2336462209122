import { TitleCasePipe, ViewportScroller } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MDBFileSelectDirective, MDBModalRef, MDBModalService, MdbStepComponent, MdbStepperComponent, TabDirective, TabsetComponent, UploaderOptions, UploadFile, UploadOutput } from 'ng-uikit-pro-standard';
import { humanizeBytes } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { NotificationService } from '../services/notification.service';
import { FirmsService } from '../services/firms.service';
import { ActionButtonsService } from '../services/action-buttons.service';
import { ScalesImporterHelpComponent } from '../components/dynamic-modals/scales-importer-help/scales-importer-help.component';
import { FirmInterface } from 'src/app/shared/interfaces/data/firm-interface';
import { ProviderInterface } from 'src/app/shared/interfaces/data/provider-interface';
import { ProvinceInterface } from 'src/app/shared/interfaces/data/province-interface';
import { TaxeInterface } from 'src/app/shared/interfaces/data/taxe-interface';
import { GuildInterface } from 'src/app/shared/interfaces/data/guild-interface';
import { ScalesTableComponent } from './components/scales-table/scales-table.component';
import { Tab } from '../tab-models/tab.model';
import { EntityService } from '../services/entity.service';
import { DropdownService } from '../services/dropdown.service';

@Component({
  selector: 'app-scales-importer',
  templateUrl: './scales-importer.component.html',
  styleUrls: ['./scales-importer.component.scss']
})
export class ScalesImporterComponent implements OnInit {
  @Input() tab: Tab;
  @Input() data;


  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent;
  @ViewChild('stepper', { static: false }) stepper: MdbStepperComponent;
  @ViewChild('scalesTable', { static: false }) scalesTable: ScalesTableComponent;


  private utils = new PGAUtils;

  private subscriptions: Array<Subscription> = [];

  private formchange_subscriptions: Array<Subscription> = [];


  public loading = false;

  public fileForm: FormGroup;
  public firmForm: FormGroup;
  public companiesForm: FormGroup;
  public repairmanForm: FormGroup;
  public administratorForm: FormGroup;

  public dataForm: FormGroup;
  public providerTypeForm: FormGroup;

  public files: UploadFile[];
  public dragOver: boolean;
  options: UploaderOptions;

  public firms: Array<FirmInterface>
  public selected_firm: string

  public companies: Array<ProviderInterface>;
  public all_subcompanies: Array<ProviderInterface>;
  public subcompanies: Array<ProviderInterface>;
  public selected_company: string;
  public selected_subcompany: string;
  public selected_administrator: string;
  public selected_repairman: string;
  public selected_typeProvider: string;
  public repairmans: Array<ProviderInterface>;
  public administrators: Array<ProviderInterface>;
  public typeproviders: any[];

  public provinces: Array<ProvinceInterface>;
  public taxes: Array<TaxeInterface>;
  public guilds: Array<GuildInterface>;

  public confirmed_data: boolean;
  public scalesFormsData: any;

  public importerTabs: any[] = [];

  public guild_entity = this._entity.entities.guild;
  public scale_entity = this._entity.entities.scale;

  modalRef: MDBModalRef | null = null;

  private firtsRun;
  private providerName;
  private subProviderName;

  /**
   * Contructor de la clase
   * @param modalService
   * @param fb
   * @param _apifunctions
   * @param _firms
   * @param _entity
   * @param titleCase
   */
  constructor(private modalService: MDBModalService, private fb: FormBuilder, private _apifunctions: ApiFunctions, private _firms: FirmsService, private _entity:EntityService, private titleCase: TitleCasePipe, private _dropdowns: DropdownService)
  {
    this.options =
    {
      concurrency: 1,
      allowedContentTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.oasis.opendocument.spreadsheet']
    };

    this.files = [];
    this.firms = this._firms.getFirms().filter(firm => firm.id > 0);
    this.typeproviders = ['Compañía', 'Reparador', 'Administrador de fincas'];
    this.firtsRun = true;
  }

  /**
   * Ejecutamos 2 funciones despues de la ejecucion del constructor
   */
  ngOnInit(): void
  {
    this.setStepperForms()
    this.getDropdowns()
  }

  /**
   * Function para iniciar los steppers  y dejar a la escucha los cambios en cada uno de ellos
   */
  setStepperForms(): void
  {
    this.fileForm = this.fb.group(
    {
      file:[null, Validators.required]
    })

    this.firmForm = this.fb.group(
      {
      firm_id: [null, Validators.required]
    })

    this.providerTypeForm = this.fb.group(
    {
      typeprovider: [null, Validators.required]
    })

    this.companiesForm = this.fb.group(
    {
      company_id:[null, Validators.required],
      subcompany_id:[null]
    });

    this.repairmanForm = this.fb.group(
    {
      company_id:[null, Validators.required],
    });

    this.administratorForm = this.fb.group(
    {
      company_id:[null, Validators.required],
    });


    this.dataForm = this.fb.group(
    {
      taxe_id:[null, Validators.required],
      guild_id:[null, Validators.required],
      province_id:[null, Validators.required],
    });

    this.formchange_subscriptions.push(
      this.firmForm.controls.firm_id.valueChanges.subscribe(
        value =>
        {
          if(this.firmForm.controls.firm_id.enabled)
          {
            this.selected_firm = this.firms.find(f => f.id === value).name;
            this.stepper.next();
          }
        }
      )
    )

    this.formchange_subscriptions.push(
      this.providerTypeForm.controls.typeprovider.valueChanges.subscribe(
        value =>
        {
          if(this.providerTypeForm.controls.typeprovider.enabled)
          {
            if(!this.firtsRun) // La primera vez no queremos que haga esto
            {
              this.companiesForm.controls.company_id.reset();
              this.companiesForm.controls.subcompany_id.reset();
              this.administratorForm.controls.company_id.reset();
              this.repairmanForm.controls.company_id.reset();
              this.selected_company = null;
              this.selected_subcompany = null;
              this.selected_repairman = null;
              this.selected_administrator = null;
            }
            else
            {
              this.firtsRun = false;
            }
            this.selected_typeProvider = this.typeproviders.find(f => f === value).toString();
            this.stepper.next();
          }
        }
      )
    )


    this.formchange_subscriptions.push(
      this.companiesForm.controls.company_id.valueChanges.subscribe(
        value => {
          if(this.companiesForm.controls.company_id.enabled && this.companiesForm.controls.company_id.value != null)
          {
            this.selected_company = this.companies.find(c => c.id === value)?.name
            this.companiesForm.controls.subcompany_id.reset();
            this.subcompanies = this.companies.find(c => c.id === value)?.subcompanies;
            this.providerName = this.selected_company;
            if(this.subcompanies === undefined || this.subcompanies.length == 0)
            {
              this.stepper.next();
            }
          }
        }
      )
    );

    this.formchange_subscriptions.push(
      this.companiesForm.controls.subcompany_id.valueChanges.subscribe(
        value =>
        {
          if(this.companiesForm.controls.subcompany_id.enabled && this.companiesForm.controls.subcompany_id.value != null)
          {
            this.selected_subcompany = this.subcompanies?.find(sc => sc.id === value)?.name
            this.subProviderName = this.selected_subcompany;
            this.stepper.next();
          }
          else
          {
            this.selected_subcompany = null;
            this.subProviderName = null;
          }
        }
      )
    );

    this.formchange_subscriptions.push(
      this.repairmanForm.controls.company_id.valueChanges.subscribe(
        value =>
        {
          if(this.repairmanForm.controls.company_id.enabled && this.repairmanForm.controls.company_id.value != null)
          {
            this.selected_repairman = this.repairmans.find(c => c.id === value)?.name
            this.providerName = this.selected_repairman;
            this.stepper.next();
          }
        }
      )
    );

    this.formchange_subscriptions.push(
      this.administratorForm.controls.company_id.valueChanges.subscribe(
        value =>
        {
          if(this.administratorForm.controls.company_id.enabled && this.administratorForm.controls.company_id.value != null)
          {
            this.selected_administrator = this.administrators.find(c => c.id === value)?.name
            this.providerName = this.selected_administrator;
            this.stepper.next();
          }
        }
      )
    );
  }

  /**
   * Funcion para comprobar que es un archivo válido y continuar
   */
  public checkFile()
  {
    this.fileForm.markAllAsTouched();
    if(this.fileForm.valid)
    {
      this.stepper.next();
     // this.toggleDisabledState()
    }
  }


  goToTable() {

  }

  onGetActiveTab(ev){
    //console.log(ev)
  }

  /**
   * Funcion para deshabilitar todos los pasos
   */
  public confirmData()
  {
    //DESHABILITAR PASOS
    this.stepper.steps.forEach(
      step =>
      {
        step.editable = false;

        if(!step.isDone)
        {
          step.isDone = true;
        }
      }
    )

    this.stepper.next()
   // this.fileForm.disable();
    this.firmForm.disable();
    this.providerTypeForm.disable();
    this.companiesForm.disable();
    this.repairmanForm.disable();
    this.administratorForm.disable();
    this.dataForm.disable();

    let providerForm;

    switch(this.selected_typeProvider)
    {
      case "Compañía":
        providerForm = this.companiesForm.getRawValue();
        break;

      case "Reparador":
        providerForm = this.repairmanForm.getRawValue();
        break;

      case "Administrador de fincas":
        providerForm = this.administratorForm.getRawValue();
        break;
    }

    this.scalesFormsData =
    {
      firm: {...this.firmForm.getRawValue(),firm_name:this.selected_firm},
      company: {...providerForm,company_name: this.providerName, subcompany_name: this.subProviderName},
      data: this.dataForm.getRawValue()
    }
    this.confirmed_data = true;

    if(this.staticTabs.tabs.length === 1)
    {
      this.importerTabs.push({title: "<i class='fas fa-table'></i> Tabla de "+this.titleCase.transform(this.scale_entity.labels.list), component: 'table'});
    }

    setTimeout(() => {this.staticTabs.setActiveTab(2)},0)
  }

  public tabModified(ev: boolean)
  {
    this.tab.modifiedData = ev;
  }

  /**
   * Funcion para resetear todo
   * @param all
   */
  public resetData(all?:boolean)
  {
    this.tab.modifiedData = false;
    this.staticTabs.setActiveTab(1);
    this.importerTabs = [];
    this.scalesFormsData = null;
    this.stepper.steps.forEach(
      step =>
      {
        step.editable = true;
      }
    )

    this.fileForm.enable();
    this.firmForm.enable();
    this.providerTypeForm.enable();
    this.companiesForm.enable();
    this.repairmanForm.enable();
    this.administratorForm.enable();
    this.dataForm.enable();
    this.confirmed_data = false;

    if(all)
    {
      this.formchange_subscriptions.forEach(fs => fs.unsubscribe())
      this.files = [];
      this.selected_company=this.selected_firm=this.selected_subcompany=null;
      this.setStepperForms()
      this.stepper.resetAll()
    }
    else
    {
      this.stepper.setNewActiveStep(0);
    }
  }

  loadImporter(){

  }

  openHelpModal()
  {
    this.modalRef = this.modalService.show(ScalesImporterHelpComponent, {
      class: 'modal-dialog modal-fluid',
      scroll: true,
      data: {
      }
    })
  }

  onUploadOutput(output: UploadOutput | any): void {
    if (output.type === 'rejected')
    {
      this.fileForm.reset();
      this.files = [];
    }
    else if (output.type === 'addedToQueue')
    {
      this.files = [];
      this.files.push(output.file); // add file to array when added
      this.checkFile()
    }
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }

  /**
   *  Se obtienen los desplegables para los formularios
  */
  public getDropdowns()
  {

    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.dropdowns).subscribe(
        response => {
          let dropdowndata = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.dropdowns));

          if(dropdowndata) {
            this.companies =  dropdowndata.company_with_subcompanies;
            this.repairmans =  dropdowndata.repairman;
            this.administrators =  dropdowndata.administrator;
            this.provinces = [...[{id:-1, name:'Sin provincia'}],...dropdowndata.province];
            this.guilds = dropdowndata.guild?.filter(g => g.enabled);
            this.taxes = dropdowndata.taxe?.filter(t=> t.enabled);

            this.loading = false;
          }
        },
        error => {
          this.loading = false;
        }
      )
    );


  }



  onSubmit()
  {
    this.scalesTable.submit();
  }

}
