import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { EmployeeFilters } from "../../data-classes";

const titleCasePipe = new TitleCasePipe();

export class EmployeeModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'user-hard-hat',
    list: 'hard-hat'
  }

  setModelData(models:any) {
    models.employee.icon = this.icon;

    models.employee.form_tabData = {
      entity: models.employee.entity,
      saveEndpoint: models.employee.saveEndpoint,
      entity_permissions: models.employee.permissions,
      new_register_dropdowns: ['guild'],
      edit_register_dropdowns: ['guild', 'user']
    }

    models.employee.list_tabData = {
      entity: models.employee.entity,
      entity_permissions: models.employee.permissions,
      entity_label: models.employee.labels.form,
      listParams: {
          list_entity: models.employee.list_entity,
          list_label: models.employee.labels.list,
          limit: 10,
          headers: [
              'Activo',
              'Acceso Web',
              'NIF',
              'Nombre',
              'Dirección',
              'Teléfono',
              'Móvil',
              'Email',
          ],
          fields: [
              { entity: models.employee.entity, field: 'enabled', type: 'check'},
              { entity: models.employee.entity, field: 'web_access', type: 'check'},
              { entity: models.employee.entity, field: 'identification_document' },
              { entity: models.employee.entity, field: 'name_complete'},
              { entity: models.employee.entity, field: 'address_complete' },
              { entity: models.employee.entity, field: 'phone' },
              { entity: models.employee.entity, field: 'mobile' },
              { entity: models.employee.entity, field: 'email' },
          ],
          web_access: true,
          disable_user: false,
          filters: new EmployeeFilters(models)
      }
    }
  }


}
