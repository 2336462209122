<div class="modal-body">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
  </button>
  <section>
      <h3 class="titulo">Antes de empezar:</h3>
      <p>
          PGA Baremos permite importar baremos a PGA de una forma fácil, para ello es necesario que el
          baremo
          esté en un formato excel válido. Los formatos aceptados son XLSX, XLS y ODS; pero también es
          necesario un formato interno determinado.
          <br>
          <br>
          Al comienzo de cada hoja es necesaria una cabecera ordenada de esta forma:
      </p>
      <img src="/assets/baremos/ayuda/ayuda_formato_interno.png" alt="Formato de la cabecera"
          class="border">
      <br>
      <ul class="list-unstyled">
          <li>Columna A1: código</li>
          <li>Columna B1: descripción</li>
          <li>Columna C1: importe</li>
          <li>Columna D1: coste (este campo no es obligatorio, pero si nos lo facilita la compañía lo
              podemos
              agregar también)</li>
      </ul>
      <p>
          Normalmente se recomienda en caso de tener que importar baremos de varios gremios, agregarlos en
          diferentes hojas (a la hoja se le pone el nombre del gremio), para posteriormente poder marcar
          un
          gremio determinado para cada hoja (véase a continuación en la imagen):
      </p>
      <img src="/assets/baremos/ayuda/ayuda_formato_interno_hojas.png" alt="Formato de las hojas"
          class="border">
      <br><br>
      <p>
          También se puede descargar un baremo ejemplo pulsando los botones de la cabecera "Ejemplo ODS",
          "Ejemplo XLS" o "Ejemplo XLSX" (según el formato que necesitemos).
      </p>
      <img src="/assets/baremos/ayuda/ayuda_ejemplos_osd_xls.png" alt="Muestra de ejemplos de descarga"
          class="border">
      <hr>
      <br><br>
  </section>

  <section>
      <h3 class="titulo">Funcionamiento del importador (paso a paso)</h3>
      <p>Para comenzar, seleccionaremos el baremo pulsando sobre el botón "Seleccionar archivo", que
          abrirá un explorador para localizar el baremo. Tras seleccionarlo, pulsamos en "Siguiente".
      </p>
      <img src="/assets/baremos/ayuda/ayuda_paso1.png" alt="Paso uno" class="border">
      <br><br>
      <p>En el segundo paso nos pide la empresa a la que importar los baremos. Pulsando en "Selecciona
          empresa" nos muestra la lista de empresas dadas de alta en el programa PGA.</p>
      <img src="/assets/baremos/ayuda/ayuda_paso2.png" alt="Paso dos" class="border">
      <br><br>
      <p>En el tercer paso seleccionamos la compañía a importar los baremos. Al igual que antes, al pulsar
          en
          "Selecciona una compañia" nos mostrará la lista de compañías dadas de alta en PGA. Tras
          seleccionar la compañía aparecerá la opción de seleccionar las subcompañías asociadas a la
          compañía seleccionada.</p>
      <img src="/assets/baremos/ayuda/ayuda_paso3.png" alt="Paso tres" class="border">
      <br><br>
      <p>En el cuarto paso nos pide los últimos datos para importar el baremo como el gremio, el
          IVA y la provincia. Estos son obligatorios, tras
          seleccionarlos pulsamos el boton "Enviar" y pasaremos al siguiente paso.
      </p>
      <img src="/assets/baremos/ayuda/ayuda_paso4.png" alt="Paso cuatro" class="border">
      <br>
      <p>
          Notas:
      </p>
      <ul>
          <li>
              En los siguientes pasos se puede modificar el gremio por hojas del excel.
          </li>
          <li>
              En provincia se puede seleccionar "Sin provincia" para que los baremos se muestren en todas
              las provincias.
          </li>
      </ul>
      <br>
      <p>
          Tras pulsar en "Enviar" en el paso anterior, se generará una tabla con los datos seleccionados
          en
          los pasos anteriores y los del excel que nos permiten comprobar que los datos son correctos. La
          tabla muestra todas las hojas del excel y nos permite en la primera fila de cada hoja, en las
          casillas de gremio, impuesto y provincia, modificar lo seleccionado para esa hoja. En caso de
          que una o
          varias hojas del excel no tengan un formato válido se mostrará el mensaje de aviso más adelante
          en el registro de sucesos (post-importación) "La Hoja X no tiene un formato válido" y no se
          importarán (solo se importarán los registros que aparecen en la tabla). Para continuar pulsamos
          en el botón "Importar".
      </p>
      <img src="/assets/baremos/ayuda/ayuda_tabla.png" alt="Tabla" class="border">
      <br><br>
      <p>
          Ahora se mostrará una barra de progreso con el progreso de importación. Cuando se
          procesen todos los registros se muestra un mensaje de aviso con información sobre la
          importación.
      </p>
      <img src="/assets/baremos/ayuda/ayuda_importar.png" alt="Tabla" class="border">

  </section>

</div>
<div class="modal-footer">
  <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
      (click)="modalRef.hide()" mdbWavesEffect>Cerrar</button>
</div>
