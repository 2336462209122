import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateFormats } from '../functions/date-formats';


export function ValidateStepsCheckWithSelect(check: AbstractControl,select: AbstractControl,) {
  if (check || select.value < 0) {
    return { invalid: true };
  }
  return null;
}



export function usernameValidator(users_mails: Array<string>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return this.checkIfUsernameExists3(control.value).pipe(
      map(res => {
        // if res is true, username exists, return true
        return res ? { usernameExists: true } : null;
        // NB: Return null if there is no error
      })
    );
  };
}

export function checkIfUsernameExists(username: string, users_mails: Array<string>): Observable<boolean> {
  return of(users_mails.includes(username));
}


export function matchValues(
  matchTo: string // name of the control to match to
): (AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    return !!control.parent &&
      !!control.parent.value &&
      control.value === control.parent.controls[matchTo].value
      ? null
      : { isMatching: false };
  };
}


/**
 *
 * @param myDefaultValue: any, valor por defecto del control (no disparará la validación)
 * @param compareControl: string, nombre del control relacionado con la validación
 * @param compareValue:any, valor del control que devolverá validación ok
 * @returns null si el valor del control coincide  error si no
 */

export function conditionalCompareValidator(
  myDefaultValue: any,
  compareControl: string,
  compareValue: any
): (AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {

    return !!control.parent &&
      !!control.parent.value &&
      /* (
        control.parent.controls[compareControl].value === null ||
        control.parent.controls[compareControl].value === compareValue
      ) */
      (
        (control.value !== myDefaultValue && control.value !== null) ||
        ((control.value == null || control.value == myDefaultValue) && (control.parent.controls[compareControl].value == null || control.parent.controls[compareControl].value == compareValue))


      )
      ? null
      : { conditionalCompareValidator: true };
  };
}



/**
 *
 * @param matchTo array de string del que se comparará el valor del control
 * @returns devolverá error si el valor del control está en el array pasado por parámetro
 */
export function matchValueToArray(
  matchTo: string[]
): (AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value !== matchTo.find(it => it == control.value)
      ? null
      : { data_exists: true };
  };
}


export function dateBeforeToday(

): (AbstractControl) => ValidationErrors | null {
  let date_functions = new DateFormats;
  return (control: AbstractControl): ValidationErrors | null => {
    return  date_functions.formatoSave(control.value) >= date_functions.formatoSave(date_functions.getCurrentDate())
      ? null
      : { date_error: true };
  };
}


export function timeBeforeNow(
  date_field: string // name of the control to match to
): (AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    let date_functions = new DateFormats;

    return !!control.parent &&
      !!control.parent.value &&
      (
      //NO mostrará error si

      //la fecha es la de hoy y la hora es maypr que la actual
      (date_functions.formatoSave(control.parent.controls[date_field].value) === date_functions.formatoSave(date_functions.getCurrentDate()) &&
      control.value > date_functions.getCurrentTime())
      ||
      //o si la fecha no es la de hoy
      date_functions.formatoSave(control.parent.controls[date_field].value) !== date_functions.formatoSave(date_functions.getCurrentDate())

      )
      ? null
      : { time_before: true };
  };
}



export function sendEmails(): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const mail_regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      let emails = control.value.split(';');

      let check = true;
      for (let index = 0; index < emails.length; index++) {
        const element = emails[index];
        if(element !== '') {
          if(!mail_regex.test(element.trim())) {
            check = false;
            break;
          }
        }
      }
      return check ? null : {email_error : true};
    };
  }



  export function selectRequired(): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value > -1 ? null : {select_required: true}
    }
  }
