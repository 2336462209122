import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { ENUM_HISTORY_DATE_NAME } from 'src/app/shared/enumerates/enum-history-date-name';

@Injectable({
  providedIn: 'root'
})
export class FirmsService {
 /**
   * Array con los permisos del usuario
   */
  private firms: Array<any>;

  ///Variable para saber las opciones de una empresa activa.
  private activeFirmOptions: any;

  constructor() { }

  /**
   * Asigna el array con los permisos obtenidos del API a la propiedad de la clase
   * HACER LO DE LAS OPCIONES
   * @param p Array de permisos
   */
  setFirms(p: Array<any>): void {

    this.firms = p;
    if(this.firms) {
      let firm_active = this.firms.find(firm => firm.active == 1);

      if(firm_active.firmoption.length == 0)
      {
        this.activeFirmOptions =
        {
          [ENUM_HISTORY_DATE_NAME.claim]: 12,
          [ENUM_HISTORY_DATE_NAME.workorder]: 12,
          [ENUM_HISTORY_DATE_NAME.invoice]: 12,
          [ENUM_HISTORY_DATE_NAME.invoiceproforma]: 12,
          [ENUM_HISTORY_DATE_NAME.budget]: 12,
          [ENUM_HISTORY_DATE_NAME.deliverynote]: 12,
          [ENUM_HISTORY_DATE_NAME.general]: 12
        }
      }
      else
      {
        this.activeFirmOptions = firm_active.firmoption;

      }
    }

    /*if(p !== undefined) {

     /* if(this.firms.length > 1) {
        let todas = 1;
        for (let index = 0; index < this.firms.length; index++) {
          if(this.firms[index].active ==0) {
            todas = 0;
            break;
          }

        }

         /*  this.firms.unshift({id: -1, name: 'TODAS', active: todas}) */
      //}

      // }
  }

  setActiveFirm(idFirm: number): void {
    this.firms.forEach(
      empresa => {

        //solo desactivamos las otras firms si no se ha elegido TODAS
        if(idFirm ===-1){
          empresa.active = 1;
        } else {
          if(empresa.id == idFirm) {
            empresa.active = 1;
          } else {
            empresa.active = 0;
          }
        }

    });
  }

  getActiveFirm(): number {
    let activa = 0;

    for (let index = 0; index < this.firms.length; index++) {
      if(this.firms[index].active ==1) {
        activa = this.firms[index].id;
        break;
      }

    }
    return activa;
  }
  /**
   * Devuelve el array de permisos
   */
  getFirms(): Array<any>{
   return  this.firms;
  }

  /**
   * Devuelve el array de opciones de una empresa activa
   */
  getActiveFirmOptions(): Array<any>{
    return this.activeFirmOptions;
  }

}

