<app-loader *ngIf="loading"></app-loader>

<mdb-tabset #staticTabs [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
  <!--PESTAÑA INFORMACION-->
  <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
    <div class="container-fluid" id="inicio">
      <div class="row small text-muted m-2">
        <div class="col-1">
          <a (click)="openHelpModal()">
            <mdb-icon fad icon="info-circle" class="blue-text mr-1" size="lg"></mdb-icon>
            Ayuda
          </a>
        </div>
        <div class="col-1">
          <a (click)="resetData(true)">
            <mdb-icon fad icon="undo" class="primary-text mr-1" size="lg"></mdb-icon>
            Reiniciar
          </a>
        </div>
      </div>
    </div>
    <mdb-stepper #stepper [linear]="true" [vertical]="true" class="mystepper">
      <mdb-step name="Archivo: {{files[0]?.name}}" label="" [stepForm]="fileForm">
        <form [formGroup]="fileForm">
          <div class="file-field md-form">
            <div mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect *ngIf="fileForm.enabled">
              <span>Seleccionar Archivo</span>
              <input type="file" mdbFileSelect [options]="options" formControlName="file" (uploadOutput)="onUploadOutput($event)" accept=".xls, .xlsx, .ods">
            </div>
          </div>
        </form>
      </mdb-step>

      <mdb-step name="Empresa: {{selected_firm}}" [stepForm]="firmForm">
        <form [formGroup]="firmForm">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="md-form">
              <mdb-select-2  mdbValidate [validateSuccess]="false" placeholder="Selecciona empresa" label="" formControlName="firm_id">
                <mdb-select-option *ngFor="let firm of firms" [value]="firm.id">{{ firm.name}}</mdb-select-option>
              </mdb-select-2>
              <mdb-error *ngIf="firmForm.controls.firm_id.invalid && (firmForm.controls.firm_id.dirty || firmForm.controls.firm_id.touched)">Seleccione una empresa para continuar</mdb-error>
            </div>
            </div>
          </div>
        </form>
      </mdb-step>

      <mdb-step name="Tipo Proveedor: {{selected_typeProvider}}" [stepForm]="providerTypeForm">
        <form [formGroup]="providerTypeForm">
          <div class="row mb-3">
            <div class="col-md-6">
              <div class="md-form">
              <mdb-select-2  mdbValidate [validateSuccess]="false" placeholder="Selecciona un tipo de proveedor" label="" formControlName="typeprovider">
                <mdb-select-option *ngFor="let typeprovider of typeproviders" [value]="typeprovider">{{typeprovider}}</mdb-select-option>
              </mdb-select-2>
              <mdb-error *ngIf="providerTypeForm.controls.typeprovider.invalid && (providerTypeForm.controls.typeprovider.dirty || providerTypeForm.controls.typeprovider.touched)">Seleccione un tipo de proveedor para continuar</mdb-error>
            </div>
            </div>
          </div>
        </form>
      </mdb-step>

      <ng-container [ngSwitch]="selected_typeProvider">
        <!--COMPAÑIAS-->
        <ng-container *ngSwitchCase="'Compañía'">
          <mdb-step name="Compañía: {{selected_company}} / {{selected_subcompany}}" [stepForm]="companiesForm">
            <form [formGroup]="companiesForm">
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="md-form">
                    <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Compañía" label="" formControlName="company_id">
                      <mdb-select-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}</mdb-select-option>
                    </mdb-select-2>
                    <mdb-error *ngIf="companiesForm.controls.company_id.invalid && (companiesForm.controls.company_id.dirty || companiesForm.controls.company_id.touched)">Seleccione una compañía para continuar</mdb-error>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form">
                    <mdb-select-2 placeholder="Subcompañía" label="" formControlName="subcompany_id">
                      <mdb-select-option *ngFor="let subcompany of subcompanies" [value]="subcompany.id">{{ subcompany.name }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>
              </div>
            </form>
          </mdb-step>
        </ng-container>

        <!--REPARADORES-->
        <ng-container *ngSwitchCase="'Reparador'">
          <mdb-step name="Reparador: {{selected_repairman}}" [stepForm]="repairmanForm">
            <form [formGroup]="repairmanForm">
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="md-form">
                    <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Reparador" label="" formControlName="company_id">
                      <mdb-select-option *ngFor="let repairman of repairmans" [value]="repairman.id">{{ repairman.name }}
                      </mdb-select-option>
                    </mdb-select-2>
                    <mdb-error
                      *ngIf="repairmanForm.controls.company_id.invalid && (repairmanForm.controls.company_id.dirty || repairmanForm.controls.company_id.touched)">
                      Seleccione un reparador para continuar
                    </mdb-error>
                  </div>
                </div>
              </div>
            </form>
          </mdb-step>
        </ng-container>

        <!--Administradores de fincas-->
        <ng-container *ngSwitchCase="'Administrador de fincas'">
          <mdb-step name="Administrador de fincas: {{selected_administrator}}" [stepForm]="administratorForm">
            <form [formGroup]="administratorForm">
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="md-form">
                    <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Administrador de fincas" label="" formControlName="company_id">
                      <mdb-select-option *ngFor="let administrator of administrators" [value]="administrator.id">{{administrator.name}}</mdb-select-option>
                    </mdb-select-2>
                    <mdb-error *ngIf="administratorForm.controls.company_id.invalid && (administratorForm.controls.company_id.dirty || administratorForm.controls.company_id.touched)">Seleccione un administrador de fincas para continuar</mdb-error>
                  </div>
                </div>
              </div>
            </form>
          </mdb-step>
        </ng-container>
      </ng-container>

      <mdb-step [name]="'Datos de los '+scale_entity.labels.list" [stepForm]="dataForm" [editable]="true">
        <form [formGroup]="dataForm">
          <div class="row mb-3">
            <div class="col-md-3">
              <div class="md-form">
                <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Seleccionar" label="Impuesto" formControlName="taxe_id">
                  <mdb-select-option *ngFor="let taxe of taxes" [value]="taxe.id">{{ taxe.value }}%
                  </mdb-select-option>
              </mdb-select-2>
              <mdb-error
                  *ngIf="dataForm.controls.taxe_id.invalid && (dataForm.controls.taxe_id.dirty || dataForm.controls.taxe_id.touched)">
                  Seleccione un impuesto para continuar
                </mdb-error>
              </div>
            </div>
            <div class="col-md">
              <div class="md-form">
                <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Seleccionar" [label]="(guild_entity.labels.form |titlecase)" formControlName="guild_id">
                  <mdb-select-option *ngFor="let guild of guilds" [value]="guild.id">{{ guild.name }}
                  </mdb-select-option>
              </mdb-select-2>
              <mdb-error
                  *ngIf="dataForm.controls.guild_id.invalid && (dataForm.controls.guild_id.dirty || dataForm.controls.guild_id.touched)">
                  Seleccione un {{guild_entity.labels.form}} para continuar
                </mdb-error>
              </div>
            </div>
            <div class="col-md">
              <div class="md-form">
                <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Seleccionar" label="Provincia" formControlName="province_id">
                  <mdb-select-option *ngFor="let province of provinces" [value]="province.id">{{ province.name }}
                  </mdb-select-option>
              </mdb-select-2>
              <mdb-error
                  *ngIf="dataForm.controls.province_id.invalid && (dataForm.controls.province_id.dirty || dataForm.controls.province_id.touched)">
                  Seleccione una provincia para continuar
                </mdb-error>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col">
              <button type="button" mdbBtn size="sm" color="primary" mdbWavesEffect [disabled]="dataForm.pristine || dataForm.invalid" (click)="confirmData()" *ngIf="!confirmed_data">
                Confirmar
              </button>

              <button type="button" mdbBtn size="sm" color="primary" mdbWavesEffect [disabled]="false" (click)="resetData()" *ngIf="confirmed_data">
                Modificar
              </button>
            </div>
          </div>
        </form>
      </mdb-step>
    </mdb-stepper>
  </mdb-tab>
  <ng-container *ngIf="confirmed_data">
    <mdb-tab *ngFor="let tab of importerTabs" heading="{{ tab.title }}">
      <ng-container [ngSwitch]="tab.component">
        <ng-container *ngSwitchCase="'table'">
          <app-scales-table #scalesTable [file]="files[0].nativeFile" [formsData]="scalesFormsData" [guilds]="guilds" [provinces]="provinces" [taxes]="taxes" (reset)="resetData(true)" (tab_modified)="tabModified($event)" (openHelpModal)="openHelpModal()"></app-scales-table>
        </ng-container>

        <ng-container *ngSwitchDefault>
          resultado?
        </ng-container>

      </ng-container>
    </mdb-tab>
  </ng-container>
</mdb-tabset>



<!--
<div class="container-fluid" id="inicio">
  <div class="row small text-muted m-2">
    <div class="col-1">
      <a (click)="openHelpModal()">
        <mdb-icon fad icon="info-circle" class="blue-text mr-1" size="lg"></mdb-icon>
        Ayuda
      </a>
    </div>
    <div class="col-1">
      <a (click)="resetData(true)">
        <mdb-icon fad icon="undo" class="primary-text mr-1" size="lg"></mdb-icon>
        Reiniciar
      </a>
    </div>
  </div>

  <mdb-tabset #staticTabs [buttonClass]="'md-tabs pills-primary white mb-2 custom-tab-pga'" [contentClass]="'card'" (getActiveTab)="onGetActiveTab($event)">

    <mdb-tab [heading]="'<i class=\'fas fa-info-circle\'></i> Selección '+scale_entity.labels.list">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">

            <div class="row">
              <div class="col">
                    <a (click)="openHelpModal()">
                      <mdb-icon fad icon="info-circle" class="blue-text mr-1" size="lg"></mdb-icon>
                      Ayuda
                    </a>
                </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <a (click)="resetData(true)">
                  <mdb-icon fad icon="undo" class="primary-text ml-1 mr-1" size="lg"></mdb-icon>
                  Reiniciar
                </a>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col">
                <div class="row">
                  <div class="col">
                    Archivos de ejemplo:
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <a mdbBtn rounded="true" size="sm" color="dark-green" mdbWavesEffect
                      href="/assets/baremos/ejemplos/BaremosEjemplo.xlsx" download>
                      .xlsx
                      <mdb-icon fas icon="file-excel"></mdb-icon>
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <a mdbBtn rounded="true" size="sm" color="dark-green" mdbWavesEffect
                        href="/assets/baremos/ejemplos/BaremosEjemplo.xls" download>
                        .xls
                        <mdb-icon fas icon="file-excel"></mdb-icon>
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <a mdbBtn rounded="true" size="sm" color="dark-green" mdbWavesEffect
                        href="/assets/baremos/ejemplos/BaremosEjemplo.ods" download>
                        .ods
                        <mdb-icon fas icon="file-excel"></mdb-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-6 offset-md-1">
            <mdb-stepper #stepper [linear]="true" [vertical]="true" class="mystepper">

              <mdb-step name="Archivo: {{files[0]?.name}}" label="" [stepForm]="fileForm">
                <form [formGroup]="fileForm">
                  <div class="file-field md-form">
                    <div mdbBtn color="primary" size="sm" class="waves-light" mdbWavesEffect *ngIf="fileForm.enabled">
                      <span>Seleccionar Archivo</span>
                      <input type="file" mdbFileSelect [options]="options" formControlName="file" (uploadOutput)="onUploadOutput($event)" accept=".xls, .xlsx, .ods">
                    </div>
                  </div>
                </form>

              </mdb-step>

              <mdb-step name="Empresa: {{selected_firm}}" [stepForm]="firmForm">
                <form [formGroup]="firmForm">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="md-form">
                      <mdb-select-2  mdbValidate [validateSuccess]="false" placeholder="Selecciona empresa" label="" formControlName="firm_id">
                        <mdb-select-option *ngFor="let firm of firms" [value]="firm.id">{{ firm.name
                            }}
                        </mdb-select-option>
                    </mdb-select-2>
                    <mdb-error
                        *ngIf="firmForm.controls.firm_id.invalid && (firmForm.controls.firm_id.dirty || firmForm.controls.firm_id.touched)">
                        Seleccione una empresa para continuar
                      </mdb-error>
                    </div>
                    </div>
                  </div>
                </form>
              </mdb-step>
              <mdb-step name="Compañía: {{selected_company}} | {{selected_subcompany}}" [stepForm]="companiesForm">
                <form [formGroup]="companiesForm">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="md-form">
                        <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Compañía" label="" formControlName="company_id">
                          <mdb-select-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}
                          </mdb-select-option>
                      </mdb-select-2>
                        <mdb-error
                          *ngIf="companiesForm.controls.company_id.invalid && (companiesForm.controls.company_id.dirty || companiesForm.controls.company_id.touched)">
                          Seleccione una compañía para continuar
                        </mdb-error>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="md-form">
                        <mdb-select-2 placeholder="Subcompañía" label="" formControlName="subcompany_id">
                          <mdb-select-option *ngFor="let subcompany of subcompanies" [value]="subcompany.id">{{ subcompany.name }}
                          </mdb-select-option>
                      </mdb-select-2>
                    </div>
                    </div>
                  </div>
                </form>
              </mdb-step>
              <mdb-step [name]="'Datos de los '+scale_entity.labels.list" [stepForm]="dataForm" [editable]="true">
                <form [formGroup]="dataForm">
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <div class="md-form">
                        <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Seleccionar" label="Impuesto" formControlName="taxe_id">
                          <mdb-select-option *ngFor="let taxe of taxes" [value]="taxe.id">{{ taxe.value }} %
                          </mdb-select-option>
                      </mdb-select-2>
                      <mdb-error
                          *ngIf="dataForm.controls.taxe_id.invalid && (dataForm.controls.taxe_id.dirty || dataForm.controls.taxe_id.touched)">
                          Seleccione un impuesto para continuar
                        </mdb-error>
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="md-form">
                        <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Seleccionar" [label]="(guild_entity.labels.form |titlecase)" formControlName="guild_id">
                          <mdb-select-option *ngFor="let guild of guilds" [value]="guild.id">{{ guild.name }}
                          </mdb-select-option>
                      </mdb-select-2>
                      <mdb-error
                          *ngIf="dataForm.controls.guild_id.invalid && (dataForm.controls.guild_id.dirty || dataForm.controls.guild_id.touched)">
                          Seleccione un {{guild_entity.labels.form}} para continuar
                        </mdb-error>
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="md-form">
                        <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Seleccionar" label="Provincia" formControlName="province_id">
                          <mdb-select-option *ngFor="let province of provinces" [value]="province.id">{{ province.name }}
                          </mdb-select-option>
                      </mdb-select-2>
                      <mdb-error
                          *ngIf="dataForm.controls.province_id.invalid && (dataForm.controls.province_id.dirty || dataForm.controls.province_id.touched)">
                          Seleccione una provincia para continuar
                        </mdb-error>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col">
                      <button type="button" mdbBtn size="sm" color="primary" mdbWavesEffect [disabled]="dataForm.pristine || dataForm.invalid" (click)="confirmData()" *ngIf="!confirmed_data">
                        Confirmar
                      </button>

                      <button type="button" mdbBtn size="sm" color="primary" mdbWavesEffect [disabled]="false" (click)="resetData()" *ngIf="confirmed_data">
                        Modificar
                      </button>
                    </div>
                  </div>
                </form>
              </mdb-step>
            </mdb-stepper>
          </div>
        </div>



      </div>

        </mdb-tab>
        <ng-container *ngIf="confirmed_data">
          <mdb-tab *ngFor="let tab of importerTabs" heading="{{ tab.title }}">
            <ng-container [ngSwitch]="tab.component">
              <ng-container *ngSwitchCase="'table'">
                <app-scales-table #scalesTable [file]="files[0].nativeFile" [formsData]="scalesFormsData"
                [guilds]="guilds" [provinces]="provinces" [taxes]="taxes"
                (reset)="resetData(true)" (tab_modified)="tabModified($event)" (openHelpModal)="openHelpModal()"></app-scales-table>
              </ng-container>

              <ng-container *ngSwitchDefault>
                resultado?
              </ng-container>

            </ng-container>
          </mdb-tab>
        </ng-container>
        <!-- <mdb-tab heading="<i class='fas fa-table'></i> Tabla de Baremos" [disabled]="true" #tabTable>
          <ng-container *ngIf="!tabTable.disabled">
            <app-scales-table></app-scales-table>
          </ng-container>
        </mdb-tab>
        <mdb-tab heading="Resultado" [disabled]="true">
        </mdb-tab> -->
  <!--    </mdb-tabset>
    </div>
  -->
