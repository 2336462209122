import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LicenseDataInterface } from 'src/app/shared/interfaces/license/license-data-interface';
import { AppConfigService } from './app-config.service';
import { TitleCasePipe } from '@angular/common';
import { EntityService } from './entity.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private license_data: LicenseDataInterface;
  public licenseDataSub = new Subject<LicenseDataInterface>();

  private entities;

  constructor(private _appconfig: AppConfigService, private _entity: EntityService, private titleCasePipe: TitleCasePipe) {
    this.entities= this._entity.entities;
  }

  setLicenseData(data: LicenseDataInterface) {

    let description = '';
    switch (data.license_id) {
      case 1:
        description+='Usuarios';
        break;
      case 2:
        description+=this.titleCasePipe.transform(this.entities.claim.labels.list);
        break;
      case 3:
        description+=this.titleCasePipe.transform(this.entities.workorder.labels.list);
        break;
      case 4:
        description+='Usuarios';
        if(data.number_claims>0){
          description+=', '+this.titleCasePipe.transform(this.entities.claim.labels.list);
        }
        if(data.number_workorders>0){
          description+=', '+this.titleCasePipe.transform(this.entities.workorder.labels.list);
        }
    }

    data.type_description = description;

    this.license_data = data;
    this.licenseDataSub.next(this.license_data);
    this.checkLicenseData();
  }

  checkRemainingClaims(): boolean {
    let limit = false;

    if(this.license_data.number_claims > 0) {
      if(this.license_data.remainingClaims === 0) {
        limit = true;
      }
    }


    return limit;
  }

  checkRemainingWorkorders(): boolean {
    //PONER A FALSE PARA QUE COMPRUEBE LA LICENCIA CORRECTAMENTE
    let limit = false;

    if(this.license_data.number_workorders > 0) {
      if(this.license_data.remainingWorkorders === 0) {
        limit = true;
      }
    }


    return limit;
  }

  checkLicenseData() {
    switch (this.license_data.license_id) {
      //licencia por expediente
      case 2:
        if(this.checkRemainingClaims()) {
          this._appconfig.addAppAlert('claims_license')
        } else {
          this._appconfig.deleteAppAlert('claims_license');
        }
        break;
      //licencia por partes
      case 3:
          if(this.checkRemainingWorkorders()) {
            this._appconfig.addAppAlert('workorders_license')
          } else {
            this._appconfig.deleteAppAlert('workorders_license');
          }
      break;
      //licencia hibrida
      case 4:
          //comprueba expedientes
          if(this.checkRemainingClaims()) {
              this._appconfig.addAppAlert('claims_license')
          } else {
            this._appconfig.deleteAppAlert('claims_license');
          }

          //comprueba partes
          if(this.checkRemainingWorkorders()){
              this._appconfig.addAppAlert('workorders_license');
          } else {
            this._appconfig.deleteAppAlert('workorders_license');
          }


        break;
    }
  }

  getLicenseData(): LicenseDataInterface {
    return this.license_data;
  }



}
