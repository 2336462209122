<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset">
    <!--PESTAÑA INFORMACION-->
    <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
        <ng-container *ngIf="formDeliverynote">
          <form [formGroup]="formDeliverynote">
            <div class="row mt-3">
              <div class="col-lg-2">
                <div class="md-form md-outline" mdbtooltip="emission_date">
                  <mdb-date-picker [inline]="true" name="emission_date" [options]="dateFormatter.opcionesMDBDatePicker" id="emission_date" [label]="'Fecha Emisión'" [placeholder]="" formControlName="emission_date" required></mdb-date-picker>
                </div>
              </div>

              <div class="col-lg-1">
                <ng-container *ngIf="dropdowndata; else seriefield">
                  <div class="md-form">
                      <app-dropdowns [label]="(serie_entity.labels.form | titlecase)" [type]="serie_entity.entity" [dropdowndata]="dropdowndata"
                        [disabled]="true"
                        [init_value]="formDeliverynote.controls.serie.get('id').value"
                        [activefilter]="true"
                        [addFirstRegister]="true"
                        [label_field]="'description'"
                        [outline] = "true"
                        [appendToBody]="true"
                        (selectedValue)="changeSelect(serie_entity.entity, $event)"
                        >
                      </app-dropdowns>
                  </div>
                </ng-container>
                <ng-template #seriefield>
                  <div class="md-form md-outline" formGroupName="serie">
                    <input mdbInput type="text" id="seriename" class="form-control form-control-sm" placeholder="" formControlName="description">
                    <label for="seriename">{{serie_entity.labels.form | titlecase}}</label>
                  </div>
                </ng-template>
              </div>
              <div class="col-lg-2">
                <div class="md-form md-outline" >
                  <input mdbInput type="text"  id="deliverynotenumber" class="form-control form-control-sm" formControlName="number" placeholder="" readonly="true">
                  <label for="deliverynotenumber">Número</label>
                </div>
              </div>

              <div class="col-lg-1" formGroupName="typeexport">
                <ng-container *ngIf="dropdowndata; else typeexportfield">
                  <div class="md-form">
                    <app-dropdowns [label]="'Exportación'" [type]="'typeexport'" [dropdowndata]="dropdowndata"
                    [disabled]="true"
                    [init_value]="formDeliverynote.controls.typeexport.get('id').value"
                    [activefilter]="false"
                    [outline] = "true"
                    [appendToBody]="true"
                    (selectedValue)="changeSelect('typeexport', $event)">
                  </app-dropdowns>
                </div>
                </ng-container>
                <ng-template #typeexportfield>
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="typeexport" class="form-control form-control-sm" placeholder="" formControlName="name">
                    <label for="typeexport">Exportación</label>
                  </div>
                </ng-template>
              </div>

              <div class="col-lg-3">
                <div class="md-form md-outline" formGroupName="client">
                  <input mdbInput type="text" id="clientname" class="form-control form-control-sm" formControlName="name_complete" placeholder="" readonly="true">
                  <label for="clientname">{{client_entity.labels.form | titlecase}}</label>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="deliverynote_address" class="form-control form-control-sm" formControlName="deliverynote_address" placeholder="" readonly="true">
                  <label for="deliverynote_address">Dirección</label>
                </div>
              </div>

              

              
              

            </div>
            <div class="row mt-3">
              <div class="col-lg-3">
                <div class="md-form md-outline">
                  <textarea rows="4" type="text" id="description" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="description"></textarea>
                  <label for="description">Descripción</label>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="md-form md-outline">
                  <textarea rows="4" type="text" id="footer" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="footer"></textarea>
                  <label for="footer">Pie</label>
                </div>
              </div>

              <div class="col-6 pr-0 pl-0">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="provider_name" class="form-control form-control-sm" formControlName="deliverynote_provider_name" placeholder="" readonly="true">
                      <label for="provider_name">Proveedor</label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <ng-container *ngIf="dropdowndata; else guildfield">
                      <div class="md-form">
                          <app-dropdowns [label]="(guild_entity.labels.form | titlecase)" [type]="guild_entity.entity" [dropdowndata]="dropdowndata"
                            [disabled]="disableChanges()"
                            [init_value]="formDeliverynote.controls.guild.get('id').value"
                            [activefilter]="true"
                            [addFirstRegister]="true"
                            [outline] = "true"
                            [appendToBody]="true"
                            (selectedValue)="changeSelect(guild_entity.entity, $event)"
                            >
                          </app-dropdowns>
                      </div>
                    </ng-container>
                    <ng-template #guildfield>
                      <div class="md-form md-outline" formGroupName="guild">
                        <input mdbInput type="text" id="guildname" class="form-control form-control-sm" placeholder="" formControlName="name">
                        <label for="guildname">{{guild_entity.labels.form | titlecase}}</label>
                      </div>
                    </ng-template>
                  </div>
    

                  
                  <div class="col-lg-2" >
                    <div class="md-form md-outline" formGroupName="claim">
                      <input mdbInput type="text" id="claimnumber" class="form-control form-control-sm" placeholder="" formControlName="number">
                        <label for="claimnumber">Nº {{claim_entity.labels.form | titlecase}}</label>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6" formGroupName="firmaddress">
                    <ng-container *ngIf="dropdowndata?.firm_addresses; else firmaddress">
                      <div class="md-form">
                        <app-dropdowns [label]="'Dirección Empresa'" [type]="'firm_addresses'" [dropdowndata]="dropdowndata"
                        [disabled]="disableChanges()"
                        [label_field]="'address_complete'"
                        [init_value]="formDeliverynote.controls.firmaddress.get('id').value"
                        [activefilter]="false"
                        [outline] = "true"
                        [appendToBody]="true"
                        (selectedValue)="changeSelect('firmaddress', $event)">
                      </app-dropdowns>
                    </div>
                    </ng-container>
                    <ng-template #firmaddress>
                      <div class="md-form md-outline">
                        <input mdbInput type="text" id="firmaddress_complete" class="form-control form-control-sm" placeholder="" formControlName="address_complete">
                        <label for="firmaddress_complete">Dirección Empresa</label>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-lg-4" formGroupName="claim">
                    <div class="md-form md-outline" formGroupName="user">
                      <input mdbInput type="text" id="claimusername" class="form-control form-control-sm" placeholder="" formControlName="name">
                      <label for="claimusername">Tramitador {{claim_entity.labels.form | titlecase}}</label>
                    </div>
                  </div>
                  
                </div>
              </div>

              
            </div>
            <div class="row mt-3 ">
              <div class="col">
                <app-detail-lines #detailComponent [master]="deliverynote" [maxHeight]="'23rem'" [taxes]="dropdowndata?.taxe" [details]="detail"
                [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="disableChanges()" (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
              </div>
            </div>
          </form>
      </ng-container>
    </mdb-tab>
</mdb-tabset>


<!--<app-loader *ngIf="loading"></app-loader>
<div class="pt-2">
  <mdb-tabset #deliverynoteTabs [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

    <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
      <ng-container *ngIf="formDeliverynote">
      <form [formGroup]="formDeliverynote">
          <div class="row">
            <div class="col-lg-2">
              <div class="md-form">
                <input mdbInput type="text" id="deliverynotenumber" class="form-control form-control-sm" formControlName="number" placeholder="" readonly="true">
                <label for="deliverynotenumber">Número</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="md-form" formGroupName="client">
                <input mdbInput type="text" id="clientname" class="form-control form-control-sm" formControlName="name_complete" placeholder="" readonly="true">
                <label for="clientname">{{client_entity.labels.form | titlecase}}</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="md-form">
                <input mdbInput type="text" id="deliverynote_address" class="form-control form-control-sm" formControlName="deliverynote_address" placeholder="" readonly="true">
                <label for="deliverynote_address">Dirección</label>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form">
                <input mdbInput type="text" id="provider_name" class="form-control form-control-sm" formControlName="deliverynote_provider_name" placeholder="" readonly="true">
                <label for="provider_name">Proveedor</label>
              </div>
            </div>
            <!-- <div class="col-lg">
              <div class="md-form" formGroupName="provider">
                <input mdbInput type="text" id="provider_name" class="form-control form-control-sm" formControlName="name" placeholder="" readonly="true">
                <label for="provider_name">Proveedor</label>
              </div>
            </div> -->
<!--          </div>
          <div class="row mt-3">
            <div class="col-lg">
              <div class="md-form" formGroupName="claim">
                <input mdbInput type="text" id="claimnumber" class="form-control form-control-sm" placeholder="" formControlName="number">
                  <label for="claimnumber">Nº {{claim_entity.labels.form | titlecase}}</label>
              </div>
            </div>
            <div class="col-lg-1" formGroupName="claim">
              <div class="md-form" formGroupName="user">
                <input mdbInput type="text" id="claimusername" class="form-control form-control-sm" placeholder="" formControlName="name">
                  <label for="claimusername">Tramitador {{claim_entity.labels.form | titlecase}}</label>
              </div>
            </div>
            <div class="col-lg">
              <ng-container *ngIf="dropdowndata; else guildfield">
                <div class="md-form">
                    <app-dropdowns  [label]="(guild_entity.labels.form | titlecase)" [type]="guild_entity.entity" [dropdowndata]="dropdowndata"
                      [disabled]="disableChanges()"
                      [init_value]="formDeliverynote.controls.guild.get('id').value"
                      [activefilter]="true"
                      [addFirstRegister]="true"
                      (selectedValue)="changeSelect(guild_entity.entity, $event)"
                      >
                    </app-dropdowns>
                </div>
              </ng-container>
              <ng-template #guildfield>
                <div class="md-form" formGroupName="guild">
                  <input mdbInput type="text" id="guildname" class="form-control form-control-sm" placeholder="" formControlName="name">
                  <label for="guildname">{{guild_entity.labels.form | titlecase}}</label>
                </div>
              </ng-template>
            </div>
            <div class="col-lg-2" formGroupName="typeexport">
              <ng-container *ngIf="dropdowndata; else typeexportfield">
                <div class="md-form">
                  <app-dropdowns [label]="'Exportación'" [type]="'typeexport'" [dropdowndata]="dropdowndata"
                  [disabled]="true"
                  [init_value]="formDeliverynote.controls.typeexport.get('id').value"
                  [activefilter]="false"
                  (selectedValue)="changeSelect('typeexport', $event)"
                  >
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #typeexportfield>
                <div class="md-form">
                  <input mdbInput type="text" id="typeexport" class="form-control form-control-sm" placeholder="" formControlName="name">
                  <label for="typeexport">Exportación</label>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg" formGroupName="firmaddress">
              <ng-container *ngIf="dropdowndata?.firm_addresses; else firmaddress">
                <div class="md-form">
                  <app-dropdowns [label]="'Dirección Emisor'" [type]="'firm_addresses'" [dropdowndata]="dropdowndata"
                  [disabled]="disableChanges()"
                  [label_field]="'address_complete'"
                  [init_value]="formDeliverynote.controls.firmaddress.get('id').value"
                  [activefilter]="false"
                  (selectedValue)="changeSelect('firmaddress', $event)"
                  >
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #firmaddress>
                <div class="md-form">
                  <input mdbInput type="text" id="firmaddress_complete" class="form-control form-control-sm" placeholder="" formControlName="address_complete">
                  <label for="firmaddress_complete">Dirección Emisor</label>
                </div>
              </ng-template>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <textarea type="text" id="description" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="description"></textarea>
                <label for="description">Descripción</label>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <textarea type="text" id="footer" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="footer"></textarea>
                <label for="footer">Pie</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
              <div class="col-lg">
                <app-detail-lines #detailComponent [master]="deliverynote" [employees]="deliverynote?.employees" [taxes]="dropdowndata?.taxe" [details]="detail"
                [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="disableChanges()"
                (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
              </div>
          </div>
      </form>
      </ng-container>
    </mdb-tab>
    </mdb-tabset>
</div>
-->
