import { TitleCasePipe } from "@angular/common";
import { Type } from "@angular/core";
import { DynamicListComponent } from "src/app/main/components/dynamic-list/dynamic-list.component";
import { Tab } from "src/app/main/tab-models/tab.model";

const titleCasePipe = new TitleCasePipe();

export class EntityBaseModel {
  protected readonly fa_conf = {
    form: 'far',
    list: 'far',
  }
  protected readonly icon ={
    form: 'cog',
    list: 'cog'
  }

  constructor() {
  }

  get model_icon() {
    return this.icon;
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      DynamicListComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: true
      },
      tabData
      )
  }

  getFormTab(component: Type<any>, entity_data, register, header_field): Tab {
    let tabData = {...entity_data.form_tabData};

    tabData.id = register.id;

    if(register.firm_id) {
      tabData.firm_id = register.firm_id;
    }


    /**
     * Si el registro tiene campo number, es el que se muestra en la cabecera de la pestaña
     * Si no tiene campo number, tiene que tener campo headerData, para volver a generar la pestaña en la recarga
     */
    let header = null;
    if(register[header_field]) {
      header = register[header_field];
    } else if(register.headerData){
      header = register.headerData
    } else {
      throw new Error('Incorrect Tab Params')
    }

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>"+header,
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
    )
  }

  getNewTab(component: Type<any>, entity_data): Tab {
    let tabData = {...entity_data.form_tabData};
    tabData.newRegister = true;
    tabData.noRecover = true;

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>Nuevo "+titleCasePipe.transform(entity_data.labels.form),
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
      )
  }
}
