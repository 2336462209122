import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { ScalesFilters } from "../../data-classes";


const titleCasePipe = new TitleCasePipe();

export class ScaleModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'coins',
    list: 'coins'
  }

  setModelData(models:any) {

    models.scale.icon = this.icon;

    models.scale.form_tabData = {
      entity: models.scale.entity,
      saveEndpoint: models.scale.saveEndpoint,
      entity_permissions: models.scale.permissions,
      entity_label: models.scale.labels.form,
      new_register_dropdowns: ['company_with_subcompanies', 'guild', 'taxe', 'province'],
      edit_register_dropdowns: ['company_with_subcompanies', 'guild', 'taxe', 'province']
    }

    models.scale.list_tabData = {
      entity: models.scale.entity,
      entity_label: models.scale.labels.form,
      entity_permissions:models.scale.permissions,
      listParams: {
        list_entity: models.scale.list_entity,
        list_label: models.scale.labels.list,
          limit: 15,
          headers: [
            'Código',
            'Descripción',
            titleCasePipe.transform(models.firm.labels.form),
            'Compañía',
            'Subcompañía',
            titleCasePipe.transform(models.guild.labels.form),
            'Importe',
            'Coste'
        ],
          fields: [
            { entity: models.scale.entity, field: 'code' },
            { entity: models.scale.entity, field: 'description' },
            { entity: models.firm.entity, field: 'name' },
            { entity: 'provider', field: 'name' },
            { entity: 'subprovider', field: 'name' },
            { entity: models.guild.entity, field: 'name' },
            { entity: models.scale.entity, field: 'price' },
            { entity: models.scale.entity, field: 'cost' }
          ],
          web_access: false,
            disable_user: false,
            filter_firm: true,
            filters: new ScalesFilters(models),
      }
    }
  }
}
