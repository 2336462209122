import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { MailAccountInterface } from 'src/app/shared/interfaces/data/mail-account-interface';
import { UserInterface } from 'src/app/shared/interfaces/data/user-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { catchError } from 'rxjs/operators';
import { EntityService } from '../../services/entity.service';
import { TabService } from '../../services/tab.service';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-mail-account',
  templateUrl: './mail-account.component.html',
  styleUrls: ['./mail-account.component.scss']
})
export class MailAccountComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  public mailaccount: MailAccountInterface;

  public loading: boolean = false;

  public formMailAccount: FormGroup;

  public users: Array<UserInterface>
  public available_users: Array<UserInterface>

  public dropdowndata: DropdownsInterface;


  public id: number;
  private subscriptions: Array<Subscription> = [];

  private imap_ports = [
    {
      type: 'ssl',
      label:'SSL/TLS',
      ports: [
        993
      ]
    },
    {
      type: 'starttls',
      label: 'STARTTLS',
      ports: [
        143
      ]
    },
    {
      type: 'plain',
      label: 'Ninguna',
      ports: [
        143
      ]
    },
  ]

  private smtp_ports = [
    {
      type: 'ssl',
      ports: [
        465
      ]
    },
    {
      type: 'starttls',
      ports: [
        587
      ]
    },
    {
      type: 'plain',
      ports: [
        25
      ]
    },
  ]

  public filtered_imap_ports: Array<any>;
  public filtered_smtp_ports: Array<any>;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  /**
   * Datos de la entidad usuario
   */
  public user_entity = this._entity.entities.user;

  constructor(private _tabs: TabService, private _api: ApirequestService, private _apifunctions: ApiFunctions, private _notification: NotificationService, private _buttons: ActionButtonsService, private _lock: LockService, private _entity: EntityService, private _dropdowns: DropdownService) {

  }

  ngOnDestroy(): void {
    this.unsubscribeAll()

    this._api.unlockExit(this.lockdata)
  }

  unsubscribeAll(){
    this.subscriptions.forEach(e => e.unsubscribe());

  }

  disableChanges(): boolean {
    let readonly = false;
    if(!this.lockdata?.unblock) {
      readonly = true;
    }

    return readonly;
  }

  ngOnInit(): void {
    this.formMailAccount = this.formFunctions.getForm(this.data.entity);
    this.id = +this.data.id;
    this.getData();
  }

  private getData() {
    this.loading = true;

    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    )


  }

  private setDropdowns() {
    this.dropdowndata = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    if(this.users) {
      this.setUsers()
    }
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.mailaccount = apiresponse.mailaccount;

    if(this.mailaccount.typemailaccount_id === 3) {
      this.setMethodValues()
    }

    this.users = apiresponse.mailaccount.users
    .map(user => {
      if(user.default_mailaccount === 0) {
        user.default_mailaccount = false;
      } else if(user.default_mailaccount === 1){
        user.default_mailaccount = true;
      }
      return user;
    });

    if(this.dropdowndata) {
      this.setUsers();
    }

    this.tab.tabData.headerData = this.mailaccount.mail;

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formMailAccount = undefined;
          setTimeout(() => {
            this.formMailAccount = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },0)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setMethodValues(){

    let method_value = 'plain';
    if(this.mailaccount.ssl) {
      method_value = 'ssl';
    } else if(this.mailaccount.starttls) {
      method_value = 'starttls'
    }

    this.filtered_imap_ports = this.imap_ports.find(ports => ports.type === method_value)?.ports;
    this.filtered_smtp_ports = this.smtp_ports.find(ports => ports.type === method_value)?.ports;

    if(!this.formMailAccount.controls.method) {
      this.formMailAccount.addControl('method', new FormControl(method_value));
    } else {
      this.formMailAccount.controls.method.setValue(method_value)
    }
  }

  private setFormData(block?: boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formMailAccount, this.mailaccount);
      if(this.mailaccount.typemailaccount_id === 3) {
        this.setMethodValues();
      }
      this.formMailAccount.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formMailAccount, this.mailaccount);

      if(this.mailaccount.typemailaccount_id === 3) {
        this.formMailAccount.controls.password.setValue(atob(this.mailaccount.password))
        this.formMailAccount.controls.password.addValidators(Validators.required)
        this.formMailAccount.controls.imap_port.addValidators(Validators.required)
        this.formMailAccount.controls.imap_server.addValidators(Validators.required)
        this.formMailAccount.controls.smtp_port.addValidators(Validators.required)
        this.formMailAccount.controls.smtp_server.addValidators(Validators.required)


        this.setMethodValues();


        this.formMailAccount.controls.enabled.markAsDirty();
        this.formMailAccount.controls.password.markAsDirty();
        this.formMailAccount.controls.imap_port.markAsDirty();
        this.formMailAccount.controls.imap_server.markAsDirty();
        this.formMailAccount.controls.smtp_port.markAsDirty();
        this.formMailAccount.controls.smtp_server.markAsDirty();



        this.formMailAccount.controls.method.valueChanges.subscribe(
          value => {
            this.changePorts(value);
          }
        )

        this.formMailAccount.updateValueAndValidity();
      }

      this.subscriptions.push(
        this.formMailAccount.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );

    }



  }

  setUsers(){
    this.available_users = this.dropdowndata?.tramit?.filter(
      user => {
        if(!this.users.find(mail_user => mail_user.id == user.id)) {
          return true
        }
      }
    ).map(
      user => {
        user.default_mailaccount = false;
        return user;
      })

  }

  removeUser(user: UserInterface) {
    this.users.splice(this.users.findIndex(u=> u.id == user.id), 1);
    this.setUsers()
    this.tab.modifiedData = true;
  }

  addUser(user: UserInterface) {
    this.users.push(user);
    this.setUsers()
    this.tab.modifiedData = true;
  }

  onChangeDefault(event: MdbCheckboxChange, user: UserInterface) {
    user.default_mailaccount = event.checked;
    this.tab.modifiedData = true;
  }

  changePorts(type:string){
    this.filtered_imap_ports = this.imap_ports.find(ports => ports.type === type)?.ports;
    this.filtered_smtp_ports = this.smtp_ports.find(ports => ports.type === type)?.ports;

    this.formMailAccount.controls.imap_port.setValue(this.filtered_imap_ports[0]);
    this.formMailAccount.controls.imap_port.markAsDirty();
    this.formMailAccount.controls.smtp_port.setValue(this.filtered_smtp_ports[0]);
    this.formMailAccount.controls.smtp_port.markAsDirty();

  }

  onSubmit(){
    this.formMailAccount.markAllAsTouched()

    if(this.formMailAccount.valid) {
      this._buttons.setLoading(true);

      const params = {};
      this.formFunctions.fillFormParams(this.formMailAccount, this.data.entity, params);
      params['mail_account_users'] = this.users.map(it => {
        return {
          user_id: it.id,
          default: it.default_mailaccount
        }
      });

      switch (this.mailaccount.typemailaccount_id) {
        case 3:
          this.updateDedicatedDomainAccount(params)
          break;

        default:
          this.subscriptions.push(
            this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
              resp=>{
                this.tab.modifiedData = false;
                this.formMailAccount.markAsPristine()
                this._buttons.reloadListTab(this.data.saveEndpoint);
                this._buttons.setLoading(false);
                this._notification.notificacionOK(this._notification.getUpdateMsg())

              },
              error => {
                this._buttons.setLoading(false);
              }
            )
          );
          break;
      }


    }


  }

  public openMailProvider() {
    let url = '';
    let model = ''
    switch (this.mailaccount.typemailaccount_id) {
      case 1:
        url = 'mailaccounts/gmail/api/url';
        model = 'gmailAPi'
        break;
      case 2:
        url = 'mailaccounts/outlook/api/url';
        model = 'outlookAPi'
        break;
      default:
        throw new Error('Incorrect Type')
        break;

    }

    this._buttons.setLoading(true)
    this.subscriptions.push(
      this._api.get(url).subscribe(
        resp => {
          let url = resp['response'][model]['url'];

          this._tabs.removeTabByTabId(this.tab.tabId);
          sessionStorage.setItem('mail_operation', 'edit');
          sessionStorage.setItem('mail_id', this.mailaccount.id.toString());
          window.location.href = url;

        },
        error => {
          this._buttons.setLoading(false)
        }
      )
    );

  }

  private updateDedicatedDomainAccount(params){
    params[this.data.entity].mail = this.formMailAccount.controls.mail.value;
    if(params[this.data.entity].password) {
      params[this.data.entity].password = btoa(params[this.data.entity].password);
    }


    switch (this.formMailAccount.controls.method.value) {
      case 'ssl':
        params[this.data.entity].ssl = true;
        params[this.data.entity].starttls = false;
        break;
      case 'starttls':
        params[this.data.entity].ssl = false;
        params[this.data.entity].starttls = true;
        break;
    }


    this.subscriptions.push(
      this._api.post(this.data.saveEndpoint+'/dedicated/login', JSON.stringify(params)).subscribe(
        response => {
          this.mailaccount = response.response.mailaccount;
          if(!this.mailaccount.state) {
            this._notification.notificacionOK(this._notification.getUpdateMsg())
            this._notification.notificacionErrorManual('Error de conexión: '+this.mailaccount.state_description)
          } else {
            this._buttons.reloadListTab(this.data.saveEndpoint);
            this._notification.notificacionOK(this._notification.getUpdateMsg())
          }


          this.tab.modifiedData = false;
          this._buttons.setLoading(false);
        },
        error => {
          this._buttons.setLoading(false);
        }
      )
    );

  }

}
