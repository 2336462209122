<app-loader *ngIf="loading"></app-loader>
<div class="container-fluid mt-3">
  <form [formGroup]="formProfile" *ngIf="!error">
    <div class="row">
      <div class="col">
        <div class="md-form form-sm">
          <input mdbInput type="text" id="name" formControlName="name" class="form-control form-control-sm">
          <label for="name" class="">Nombre</label>
        </div>
      </div>
      <div class="col">
        <div class="md-form form-sm">
          <input mdbInput type="text" id="surname" formControlName="surname" class="form-control form-control-sm">
          <label for="surname" class="">Apellidos</label>
        </div>
      </div>
      <div class="col">
        <div class="md-form form-sm">
          <input mdbInput type="text" id="email" formControlName="email" class="form-control form-control-sm">
          <label for="email" class="">Email</label>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="formProfile.controls.options">
          <div class="col-md" formGroupName="options">
            <div class="md-form">
            <mdb-select placeholder="" [options]="themes" id="theme_webpanel" formControlName="theme_webpanel" (selected)="changeTheme($event)" ></mdb-select>
            <label for="theme_webpanel">Tema</label>
          </div>
        </div>
        <div class="col-md" formGroupName="options">
          <div class="md-form">
            <mdb-select placeholder="" [options]="pages" id="init_tab" formControlName="init_tab"></mdb-select>
            <label for="init_tab">Pestaña por defecto</label>
          </div>
        </div>
        <div class="col-md" formGroupName="options">
          <div class="md-form">
            <mdb-select placeholder="" [options]="views" id="type_view" formControlName="type_view"></mdb-select>
            <label for="type_view">Aspecto</label>
          </div>
        </div>
      </ng-container>
      <div class="col-md" *ngIf="user?.roleuserfirm.length > 1">
        <div class="md-form">
          <mdb-select placeholder="" [options]="firms" id="defaultFirm" formControlName="defaultFirm"></mdb-select>
          <label for="defaultFirm">Empresa por defecto</label>
        </div>
      </div>
      <div class="col-md" *ngIf="user?.mailaccounts.length > 0">
        <div class="md-form">
          <mdb-select placeholder="Seleccionar" [options]="accounts" id="defaultMail" formControlName="defaultMail"></mdb-select>
          <label for="defaultMail">Cuenta Predeterminada</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="md-form form-sm input-group">
          <input mdbInput type="text" id="password" formControlName="password" #password class="form-control form-control-sm">
          <label for="password" class="">Nueva Contraseña</label>

            <div class="input-group-append">
            <button mdbBtn color="default" size="md" class="m-0 px-3 py-2" mdbTooltip="{{lock_text}}" placement="top" type="button" id="button-addon2" (click)="unlockPass()"
              mdbWavesEffect><i [ngClass]="(formProfile.controls.password.enabled) ? 'fas fa-unlock': 'fas fa-lock'"></i></button>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="md-form form-sm" *ngIf="formProfile.controls.password.enabled">
          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="confirm_password" formControlName="confirm_password" class="form-control form-control-sm">
          <label for="confirm_password">Confirmar Contraseña</label>
          <mdb-error *ngIf="formProfile.controls.confirm_password.invalid && (formProfile.controls.confirm_password.dirty || formProfile.controls.confirm_password.touched)">Las contraseñas no coinciden</mdb-error>
        </div>
      </div>
    </div>
  </form>

  <div class="alert alert-info fade show" *ngIf="error">
    <strong>No se ha logrado cargar el perfil por un error. </strong>
  </div>
</div>



 <div mdbModal #temaDemoModal="mdbModal" class="modal fade bottom" tabindex="-1" role="dialog" aria-labelledby="temaDemoModal" (closed)="declineDemo()"
  aria-hidden="true" [config]="{ignoreBackdropClick: false,backdrop: false, keyboard:false}">
  <div class="modal-dialog modal-frame modal-bottom" role="document">
    <div class="modal-content">
      <div class="modal-body row">
        <div class="col">
          <span>
            ¿Aplicar el tema seleccionado?
          </span>
        </div>
        <div class="col text-right">
          <button type="button" [disabled]="loading" mdbBtn color="secondary" class="waves-light" aria-label="Close" (click)="declineDemo()" mdbWavesEffect>Reestablecer ({{demoTime}})</button>
          <button type="button" [disabled]="loading" mdbBtn color="primary" class="relative waves-light" (click)="saveTheme()" mdbWavesEffect>Guardar Tema</button>
        </div>
      </div>
    </div>
  </div>
</div>
