<div class="modal-header">
  <h4 class="modal-title w-100" id="myModalLabel">{{header}}</h4>
</div>
<div class="modal-body">
  <div class="text-center">
    <mdb-icon fas icon="exclamation-triangle" size="3x" class="mb-3 orange-text"></mdb-icon>
    <p>{{text}}</p>
    <ng-container *ngFor="let data of register_data">
      <p>{{data}}</p>
    </ng-container>

  </div>
</div>
<div class="modal-footer text-center">
  <ng-container *ngIf="!deleting; else wait">
    <button mdbBtn type="button" size="sm" color="default" mdbWavesEffect (click)="delete()">
      <mdb-icon fas icon="check"></mdb-icon>
    </button>
    <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="modalRef.hide()">
      <mdb-icon fas icon="undo"></mdb-icon>
    </button>
  </ng-container>
  <ng-template #wait>
    <mdb-progress-bar
      class="progress primary-color-dark"
      mode="indeterminate"
    ></mdb-progress-bar>
  </ng-template>
</div>
