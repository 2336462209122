import { AdminPageComponent } from "src/app/main/admin-page/admin-page.component"
import { Tab } from "src/app/main/tab-models/tab.model"
import { SinglePageBaseModel } from "./single-page-base.model"


export class AdminPageModel extends SinglePageBaseModel {

  protected icon = 'info-circle';


}
