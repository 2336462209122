<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid">


    <div class="row">

        <!-- Sidebar -->
        <div class="col-12 col-md-6 col-xl-2 accounts-left-panel">
            <mdb-card class="accountspanel">
                <mdb-card-body>

                    <!-- Accordion para gestionar recibidos y enviados-->
                    <mdb-accordion [multiple]="true">
                        <ng-container *ngFor="let account of accounts">
                            <mdb-accordion-item [customClass]="'item-account'"[collapsed]="selected_account?.account.id !== account.id">
                                <mdb-accordion-item-head  [customClass]="'accounts-accordion'" [ngClass]="{'selected-account': account.id === selected_account?.account.id}">{{ account.mail }}
                                </mdb-accordion-item-head>
                                <mdb-accordion-item-body [customClass]="'accounts-body'">
                                    <ul class="list-group" id="items">
                                        <li class="list-group-item d-flex justify-content-between align-items-center custom-item"
                                            [ngClass]="{'selected': account.id === selected_account?.account.id && selected_account?.folder === 'received'}"
                                            (click)="setActive(account, 1)">

                                            <a><mdb-icon fad icon="inbox" size="sm" class="mr-1 text-primary"></mdb-icon>Recibidos</a>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between align-items-center custom-item" [ngClass]="{'selected':  account.id === selected_account?.account.id && selected_account?.folder === 'sent'}"
                                            (click)="setActive(account, 2)">

                                            <a><mdb-icon fad icon="paper-plane" size="sm" class="mr-1 text-primary"></mdb-icon>Enviados</a>
                                        </li>
                                    </ul>
                                </mdb-accordion-item-body>
                            </mdb-accordion-item>
                        </ng-container>
                    </mdb-accordion>

                </mdb-card-body>
            </mdb-card>


        </div>
        <!-- Sidebar -->


        <div class="col accounts-left-panel" *ngIf="accounts?.length>0">
          <div class="row mt-1">
            <div class="col-4">
              <a class="border border-light rounded mx-1 p-1 small" (click)="receive()">
                <mdb-icon fad icon="inbox-in" class="mr-1"></mdb-icon>Recibir
              </a>
              <a class="border border-light rounded mx-1 p-1 small" (click)="openMailModal()">
                <mdb-icon fad icon="pencil" class="mr-1"></mdb-icon>Redactar
              </a>
              <a class="border border-light rounded mx-1 p-1 small" (click)="filters.toggle()">
                <mdb-icon fad icon="sliders-v" class="mr-1"></mdb-icon>Filtro Rápido
              </a>
              <a class="border border-light rounded mx-1 p-1 small" (click)="clearFilters()" *ngIf="formFilters?.dirty">
                <mdb-icon fad icon="undo" class="mr-1"></mdb-icon>Limpiar Filtros
              </a>
              <ng-container *ngIf="selected_email">
                <ng-container *ngIf="selected_email.claim; else linkclaimheader">
                  <a class="border border-light rounded mx-1 p-1 small" (click)="unlinkClaim(selected_email.id)" *ngIf="!selected_email.typeprotocol_id">
                    <mdb-icon fad icon="unlink" class="mr-1"></mdb-icon>Desvincular
                  </a>
                </ng-container>
                <ng-template #linkclaimheader>
                  <a class="border border-light rounded mx-1 p-1 small" (click)="linkClaim(selected_email.id)">
                    <mdb-icon fad icon="link" class="mr-1"></mdb-icon>Vincular
                  </a>
                </ng-template>
              </ng-container>
            </div>
            <div class="col-3"  *ngIf="processed_result?.Error > 0">
              <div #alert class="alert alert-dismissible alert-warning fade show custom-alert" role="alert">
                {{processed_result | json }}
              </div>
            </div>
          </div>
          <div class="mt-2" mdbCollapse #filters="bs-collapse">
            <form [formGroup]="formFilters">
              <div class="row">
                <div class="col">
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="claim" formControlName="claim" class="form-control form-control-sm" placeholder="" (keypress)="searchKey($event)" >
                    <label for="claim">{{claim_entity.labels.form | titlecase}}</label>
                  </div>
                </div>
                <div class="col">
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="from" formControlName="from" class="form-control form-control-sm" placeholder="" (keypress)="searchKey($event)" >
                    <label for="from">Remitente</label>
                  </div>
                </div>
                <div class="col">
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="to" formControlName="to" class="form-control form-control-sm" placeholder="" (keypress)="searchKey($event)" >
                    <label for="to">Destinatario</label>
                  </div>
                </div>
                <div class="col">
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="subject" formControlName="subject" class="form-control form-control-sm" placeholder="" (keypress)="searchKey($event)" >
                    <label for="subject">Asunto</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="" formControlName="linked" label="Mostrar" (valueChange)="filterChanged($event)">
                      <mdb-select-option *ngFor="let option of has_claim_options" [value]="option.value">{{ option.label }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>
                <div class="col-2">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="" formControlName="has_attacheds" label="Adjuntos" (valueChange)="filterChanged($event)">
                      <mdb-select-option *ngFor="let option of read_options" [value]="option.value">{{ option.label }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>
                <div class="col-2">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="" formControlName="check" label="Procesado" (valueChange)="filterChanged($event)">
                      <mdb-select-option *ngFor="let option of process_options" [value]="option.value">{{ option.label }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>
                <div class="col-2">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="" formControlName="read" label="Leído" (valueChange)="filterChanged($event)">
                      <mdb-select-option *ngFor="let option of read_options" [value]="option.value">{{ option.label }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>
                <div class="col-2">
                  <div class="row">
                    <div class="col-1 date-checkbox">
                      <mdb-checkbox [default]="true" formControlName="checkbox"></mdb-checkbox>
                    </div>
                    <div class="col" formGroupName="date">
                      <mdb-date-picker
                      [inline]="true"
                      name="from"
                      formControlName="from"
                      [placeholder]="''"
                      [options]="datepicker_options"
                      [label]="'Desde'"
                      [outlineInput]="true"
                      (dateChanged)="filterChanged($event)"
                      >
                    </mdb-date-picker>
                    </div>
                  </div>
                </div>
                <div class="col-2" formGroupName="date">
                  <mdb-date-picker
                  [inline]="true"
                  name="to"
                  formControlName="to"
                  [placeholder]="''"
                  [options]="datepicker_options"
                  [label]="'Hasta'"
                  [outlineInput]="true"
                  (dateChanged)="filterChanged($event)"
                  >
                  </mdb-date-picker>
                </div>
                <!-- <div class="col-1">
                  <a class="blue-text">
                    <mdb-icon fas icon="search" size="md" class="m-2"></mdb-icon>
                  </a>

                  <a class="red-text"><mdb-icon fas icon="undo" size ="md" class="m-2"></mdb-icon>
                  </a>
                </div> -->
              </div>
            </form>
          </div>
          <div class="messages-panel-contract">
            <table mdbTable mdbTableScroll scrollY="true" maxHeight="17rem" stickyHeader= "true" hover="true" small="false">
              <thead>
                  <tr>
                      <th scope="col">
                        <ng-container *ngIf="selected_account.folder == 'received'; else sent">
                          De
                        </ng-container>
                        <ng-template #sent>
                          Enviado a
                        </ng-template>
                      </th>
                      <th scope="col">Asunto</th>
                      <th scope="col">{{claim_entity.labels.form | titlecase}}</th>
                      <!-- <ng-container *ngIf="selected_account.folder == 'received'"> -->
                        <th scope="col" class="text-center">Prot.</th>
                      <!-- </ng-container> -->
                      <th scope="col">Fecha</th>
                      <th scope="col"><mdb-icon fas icon="paperclip"></mdb-icon></th>
                  </tr>
              </thead>
              <tbody>
                  <ng-container *ngFor="let email of emails; let i=index">
                    <tr mdbTableCol class="message-row" (contextmenu)="onContextMenu($event,email)" (click)="selectEmail(email)" [ngClass]="{'selected': email.id === selected_email?.id}">
                      <td [ngStyle]="{'font-weight': !email.read ? 'bold' : 'normal'}" >
                        <ng-container *ngIf="selected_account.folder == 'received'; else sent">
                          {{email.from}}
                        </ng-container>
                        <ng-template #sent>
                          {{email.to}}
                        </ng-template>
                      </td>
                      <td [ngStyle]="{'font-weight': !email.read ? 'bold' : 'normal'}" >{{email.subject}}</td>
                      <td [ngStyle]="{'font-weight': !email.read ? 'bold' : 'normal'}" >{{email.claim?.number}}</td>
                      <!-- <ng-container *ngIf="selected_account.folder == 'received'"> -->
                        <td class="text-center">
                          <ng-container *ngIf="email.typeprotocol_id">
                            <img src="{{getProtocolLogo(email.typeprotocol_id)}}" alt="" class="protocol-logo">
                          </ng-container>
                        </td>
                      <!-- </ng-container> -->
                      <td [ngStyle]="{'font-weight': !email.read ? 'bold' : 'normal'}" >{{email.date | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                      <td>
                        <mdb-icon fas icon="paperclip" *ngIf="email.has_attacheds == 1"></mdb-icon>
                      </td>
                    </tr>
                  </ng-container>
              </tbody>
          </table>


          <!-- <tfoot class="grey lighten-5 w-100">
            <tr>
                <td></td>
                <td [colSpan]="6">
                </td>
            </tr>
        </tfoot> -->
          </div>
          <mat-paginator #paginator class="w-100 d-flex mail-paginator" [length]="total" [pageIndex]="current_page" [pageSize]="per_page" [pageSizeOptions]="[per_page]" [showFirstLastButtons]="true"  (page)="changePage($event)">
          </mat-paginator>

          <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
          </div>
          <mat-menu #contextMenu="matMenu">
              <ng-template matMenuContent let-item="item">
                <button mat-menu-item (click)="openMailModal(true)">
                  <mdb-icon fad icon="reply" class="mr-1"></mdb-icon>Responder
                </button>
                <button mat-menu-item (click)="openMailModal(null, true)">
                  <mdb-icon fad icon="share" class="mr-1"></mdb-icon>Reenviar
                </button>
                <ng-container *ngIf="item.claim; else linkclaim">
                  <button mat-menu-item (click)="unlinkClaim(item.id)" *ngIf="!item.typeprotocol_id">
                    <mdb-icon fad icon="unlink" class="mr-1"></mdb-icon>Desvincular
                  </button>
                  <button mat-menu-item (click)="openClaim(item)">
                    <mdb-icon fad [icon]="claim_entity.icon.form" class="mr-1"></mdb-icon>Ver {{claim_entity.labels.form | titlecase}}
                  </button>
                </ng-container>
                <ng-template #linkclaim>
                  <button mat-menu-item (click)="linkClaim(item.id)">
                    <mdb-icon fad icon="link" class="mr-1"></mdb-icon>Vincular
                  </button>
                </ng-template>

                <!-- <ng-container *ngIf="true">
                  <button mat-menu-item (click)="ignoreComunication(item)">
                  <mdb-icon fas icon="exclamation-triangle" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Ignorar
                </button>
                </ng-container> -->



                <!-- <ng-template #unignore>
                  <button mat-menu-item *ngIf="item.ignored" (click)="unIgnoreComunication(item)">
                    <mdb-icon fas icon="cogs" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Pendiente
                  </button>
                </ng-template> -->

              </ng-template>
          </mat-menu>

          <div class="row mt-3 mb-1">
            <ng-container *ngIf="selected_email">
              <div class="col">
                <mdb-card>
                  <mdb-card-header class="email-header">
                    <div class="row">
                      <div class="col-9">
                        <div class="row">
                          <div class="col">
                           <span class="text-muted">De:</span> {{selected_email.from}}
                          </div>
                          <div class="col">
                            <span class="text-muted">A:</span> {{selected_email.to}}
                          </div>
                          <div class="col-3">
                            <span class="text-muted">Fecha:</span> {{selected_email.date | date: 'dd/MM/yyyy HH:mm:ss'}}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <span class="text-muted">Asunto:</span> {{selected_email.subject}}
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <a class="border border-light rounded mx-1 p-1 small" (click)="openMailModal(true)">
                          <mdb-icon fad icon="reply" class="mr-1"></mdb-icon>Responder
                        </a>
                        <a class="border border-light rounded mx-1 p-1 small" (click)="openMailModal(null, true)">
                          <mdb-icon fad icon="share" class="mr-1"></mdb-icon>Reenviar
                        </a>
                        <ng-container *ngIf="selected_email.claim; else linkclaim">
                          <a class="border border-light rounded mx-1 p-1 small" (click)="unlinkClaim(selected_email.id)" *ngIf="!selected_email.typeprotocol_id">
                            <mdb-icon fad icon="unlink" class="mr-1"></mdb-icon>Desvincular
                          </a>
                        </ng-container>
                        <ng-template #linkclaim>
                          <a class="border border-light rounded mx-1 p-1 small" (click)="linkClaim(selected_email.id)">
                            <mdb-icon fad icon="link" class="mr-1"></mdb-icon>Vincular
                          </a>
                        </ng-template>
                        <!-- <br>
                        <a class="border border-light rounded mx-1 p-1 small">
                          <mdb-icon fad icon="eye" class="mr-1"></mdb-icon>Ver
                        </a> -->
                      </div>
                    </div>
                  </mdb-card-header>
                  <mdb-card-body class="email-body">
                    <!-- <mdb-card-title>
                      <h5>Special title treatment</h5>
                    </mdb-card-title> -->
                    <mdb-card-text [innerHTML]="selected_email.bodySanitized"></mdb-card-text>
                  </mdb-card-body>
                  <mdb-card-footer *ngIf="selected_email.has_attacheds">
                    <div class="row">
                      <ng-container *ngIf="selected_email.emailattached.length > 0; else processAttach">
                        <div class="col-1">
                          <a class="small" (click)="test.toggle()" mdbWavesEffect>
                            <mdb-icon fad icon="paperclip mr-1"></mdb-icon>{{selected_email.number_attacheds}} Adjunto(s)
                          </a>
                        </div>
                        <div class="col-1 offset-10">
                          <a class="border border-light mx-1 p-1 small" (click)="downloadAttach(selected_email)">
                            <mdb-icon fad icon="inbox-in mr-1"></mdb-icon>Guardar
                          </a>
                        </div>
                      </ng-container>
                      <ng-template #processAttach>
                        <div class="col">
                          <a class="border border-light mx-1 p-1 small" (click)="processAttachs(selected_email)">
                            <mdb-icon fad icon="inbox-in mr-1"></mdb-icon>Procesar {{selected_email.number_attacheds}} Adjunto(s)
                          </a>
                        </div>
                      </ng-template>
                    </div>
                    <div class="row" mdbCollapse #test="bs-collapse">
                      <ng-container *ngIf="selected_email.emailattached.length > 0">

                          <ng-container *ngFor="let attach of selected_email.emailattached">
                            <div class="col-2">
                              <a class="small" (click)="downloadAttach(selected_email, attach)">
                                <mdb-icon fad icon="file mr-1"></mdb-icon>{{ attach.name }}
                              </a>
                            </div>
                          </ng-container>

                      </ng-container>

                    </div>

                  </mdb-card-footer>
                </mdb-card>
              </div>
            </ng-container>
          </div>

        <!-- </div> -->
        </div>

    </div>


</div>

