import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { Tab } from '../../tab-models/tab.model';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { FormGroup } from '@angular/forms';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { ScaleInterface } from 'src/app/shared/interfaces/data/scale-interface';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { LockService } from '../../services/lock.service';
import { ProviderInterface } from 'src/app/shared/interfaces/data/provider-interface';
import { catchError, map, startWith } from 'rxjs/operators';
import { EntityService } from '../../services/entity.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-scale',
  templateUrl: './scale.component.html',
  styleUrls: ['./scale.component.scss']
})
export class ScaleComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  private scale: ScaleInterface;

  public loading: boolean = false;

  public formScale: FormGroup;

  private id: number;
  private subscriptions: Array<Subscription> = [];

  public dropdowndata: DropdownsInterface;


  public companies: Array<ProviderInterface>=[];
  public subcompanies: Array<ProviderInterface>;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  public guild_entity = this._entity.entities.guild;

  constructor(private _api: ApirequestService, private _lock: LockService, private _dropdowns: DropdownService, private _buttons: ActionButtonsService, private _notification: NotificationService, private _entity: EntityService)
  {
    this.subcompanies = [];
  }

  ngOnDestroy(): void {

    this.subscriptions.forEach(e => e.unsubscribe());

    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void {
    this.formScale = this.formFunctions.getForm(this.data.entity);
    this.id = this.data.id;
    this.getData();
  }

  private getData() {
    this.loading = true;
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  setDropdowns(): void {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    /* data.guild = data.guild.filter(guild => guild.enabled == true);
    data.taxe = data.taxe.filter(taxe => taxe.enabled == true); */

    this.dropdowndata = data;

    this.companies =  data.company_with_subcompanies;

    /* this.filteredCompanyOptions = this.formScale.controls.company_name.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.filterCompanies(value))
    ); */

    this.subscriptions.push(
      this.formScale.controls.provider_id.valueChanges.subscribe(
        value => {
          this.formScale.controls.subprovider_id.reset();

          if(this.formScale.controls.provider_id.enabled)
          {
            if(this.companies.find(c => c.id === value)?.subcompanies)
            {
                this.subcompanies = this.companies.find(c => c.id === value).subcompanies;
            }
            else
            {
              this.subcompanies = [];
            }
          }
        }
      )
    );

    if(this.companies.find(c => c.id === this.scale.provider_id)?.subcompanies)
    {
      if(this.companies.find(c => c.id === this.scale.provider_id).subcompanies?.length > 0)
      {
        this.subcompanies = this.companies.find(c => c.id === this.scale.provider_id).subcompanies;
      }
      else
      {
        this.subcompanies = [];
      }
    }
    else
    {
      this.subcompanies = [];
    }


   // setTimeout(()=>{ this.loadSubcompanies(this.formScale.controls.company_id.value)}, 0)
  }

  checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.scale = apiresponse.scale;

    this.tab.tabData.headerData = this.scale.code;

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formScale = undefined;
          setTimeout(() => {
            this.formScale = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formScale, this.scale);
      /* if (this.scale.provider.provider_id !== null) {
        //es subcompañía
        this.formScale.controls.company_id.setValue(this.scale.provider.provider_id);
        this.formScale.controls.subcompany_id.setValue(this.scale.provider_id);

      } else {
        this.formScale.controls.company_id.setValue(this.scale.provider_id)
      } */

      this.formScale.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formScale, this.scale);
      /* if (this.scale.provider.provider_id !== null) {
        //es subcompañía
        this.formScale.controls.company_id.setValue(this.scale.provider.provider_id);
        this.formScale.controls.subcompany_id.setValue(this.scale.provider_id);

      } else {
        this.formScale.controls.company_id.setValue(this.scale.provider_id)
      } */


      this.subscriptions.push(
        this.formScale.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }

  /* filterCompanies(value: string): any[] {
    this.subcompanies = [];
    console.log(value)
    if(this.formScale.controls.subcompany_id.dirty) {
      this.formScale.controls.subcompany_id.reset();
    }
    const filterValue = value?.toLowerCase();
    return (value=== '') ? this.dropdowndata.company : this.dropdowndata.company.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  companySelected(company_id:number){
    this.formScale.controls.subcompany_id.reset();

    this.loadSubcompanies(company_id)
  }


  loadSubcompanies(company_id: number) {
    this.subcompanies = this.dropdowndata.subcompany.filter(sc => sc.provider_id == company_id);
  } */

  onSubmit(){
    this.formScale.markAllAsTouched();
    if(this.formScale.valid) {
      this._buttons.setLoading(true);

      let form_values = {...this.formScale.value};
      console.log(form_values)
      let params = {
        scale: {
          provider_id: form_values.provider_id,
          subprovider_id: form_values.subprovider_id,
          province_id: form_values.province_id,
          guild_id: form_values.guild_id,
          code: form_values.code,
          internal_code: form_values.internal_code,
          description: form_values.description,
          price: form_values.price,
          taxe_id: form_values.taxe_id,
          cost: form_values.cost,
        }
      };

      console.log(params)
      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          response => {
            this.tab.modifiedData = false;
            this.formScale.markAsPristine();
            this._buttons.reloadListTab(this.data.saveEndpoint);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(response.response[this.data.entity])
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }


}
