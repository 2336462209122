import { TitleCasePipe } from "@angular/common";


const titleCasePipe = new TitleCasePipe();

export class DeliverynoteFilters {

  public staticFilters;
  public optionalFilters;

  constructor(private entities){


    this.staticFilters = [
      {
        entity: this.entities.deliverynote.entity,
        field: {
          name: 'number',
          type: 'text',
          label: 'Nº '+titleCasePipe.transform(this.entities.deliverynote.labels.form),
          width: 3
        }
      },
      {
        entity: this.entities.client.entity,
        field: {
          name: 'name',
          type: 'text',
          label: titleCasePipe.transform(this.entities.client.labels.form),
          width: 3
        }
      },
      {
        entity: this.entities.claim.entity,
        field: {
          name: 'number',
          type: 'text',
          label: titleCasePipe.transform(this.entities.claim.labels.form),
          width: 3
        }
      },
      {
        entity: this.entities.deliverynote.entity,
        field: {
          name: 'created_at',
          type: 'date',
          label: 'Creado',
          width: 2,
          from: true
        }
      },
    ]


  }
}

