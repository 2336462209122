import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-scales-importer-help',
  templateUrl: './scales-importer-help.component.html',
  styleUrls: ['./scales-importer-help.component.scss']
})
export class ScalesImporterHelpComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
  }

}
