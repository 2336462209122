import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { UserInterface } from 'src/app/shared/interfaces/data/user-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-new-firm',
  templateUrl: './new-firm.component.html',
  styleUrls: ['./new-firm.component.scss']
})
export class NewFirmComponent implements OnInit, OnDestroy {
  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  public loading: boolean = false;
  public formFirm: FormGroup;
  public addressForm: FormGroup;

  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;


  public dropdowndata: DropdownsInterface;

  public users: Array<any> = [];
  public available_users: Array<UserInterface>

  options = [
    { value: 3, label: 'Tramitador'},
    { value: 2, label: 'Gerente' },
  ];

  modalRef: MDBModalRef | null = null;
  modalRefAddress: MDBModalRef | null = null;

  /**
   * Datos de la entidad usuario
   */
  public user_entity = this._entity.entities.user;

  constructor(private modalService: MDBModalService, private _dropdowns: DropdownService,private _tabs: TabService,private _api: ApirequestService, private _buttons: ActionButtonsService, private _notification: NotificationService, private fb: FormBuilder, private _entity: EntityService) {
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.createForm();
    this.getDropdowns();
  }

  getDropdowns(){
    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          let data = this.utils.objectDeepCopy(response.response.dropdown);

          this.dropdowndata = data;
          this.setUsers()

          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  createForm() {
    this.formFirm = this.fb.group({
      identification_document: [null],
      name: [null, [Validators.required]],
      email: [null,[Validators.email]],
      phone: [null],
      mobile: [null],
    });

    this.addressForm = this.fb.group({
      address:[''],
      postalcode: this.fb.group({
        province_id:[null],
        province:[{value:null, disabled: false}],
        population_id:[null],
        population:[{value:null, disabled: false}],
        description:[{value:null, disabled: false}],
        id:[null]
      })
    });

    this.subscriptions.push(
      this.formFirm.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    );

    this.subscriptions.push(
      this.addressForm.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    );
  };

  selectLocation(){
    this.modalRefAddress = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccionar Población',
              list_headers: [
                'CP',
                'Población',
                'Provincia',
              ],
              list_fields: [
                { entity: 'postalcode', field: 'description'},
                { entity: 'postalcode', field: 'population' },
                { entity: 'postalcode', field: 'province' },
              ],
              list_entity: 'postalcode',
              list_endpoint: 'postalcodes',
            }
          })

      this.subscriptions.push(
        this.modalRefAddress.content.selectedRegister.subscribe(register => {
          if(register) {
            this.formFunctions.rellenarFormulario(this.addressForm.controls.postalcode, register);
            this.addressForm.markAsDirty();
          }
        })
      );

      this.modalService.close.subscribe(() => this.modalRefAddress.content.onClosed())
  }

  setUsers(){
    this.available_users = this.dropdowndata?.tramit.filter(
      user => {
        if(!this.users.find(added_user => added_user.user.id == user.id)) {
          return true
        }
      }
    )

    console.log(this.users)

  }

  removeUser(user: UserInterface) {
    this.users.splice(this.users.findIndex(u=> u.user.id == user.id), 1);
    this.setUsers()
    //this.tab.modifiedData = true;
  }

  addUser(user: UserInterface) {
    let form = new FormControl(3);

    this.users.push({user: user,  role: form});
    this.setUsers()
    this.tab.modifiedData = true;
  }


  onSubmit(){
    this.formFirm.markAllAsTouched();


    if(this.formFirm.valid){
      this._buttons.setLoading(true);

      const params:{[key:string]:any} = {};
      this.formFunctions.fillFormParams(this.formFirm, 'firm', params);

      if(this.addressForm.dirty) {
        let addressValues = this.addressForm.value;

        params.firmaddress = {
          address: addressValues.address,
          postalcode_id: addressValues.postalcode.id,
          province_id: addressValues.postalcode.province_id,
          population_id: addressValues.postalcode.population_id,
        }
      }

        //console.log(this.users)

        if(this.users.length>0){
          params.users = this.users.map(u =>
            {
              return {
                id: u.user.id,
                role_id: +u.role.value
              }
            }
          );
        }

        console.log(params)

        this.subscriptions.push(
          this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
            response => {
              let firm = response.response.firm;

              this._buttons.reloadListTab(this.data.saveEndpoint)
              this._buttons.setLoading(false);
              this._notification.notificacionOK(this._notification.getInsertMsg())

              //Abre el modal de apertura de registro
              this.modalRef = this.modalService.show(OpenRegisterComponent, {
                  keyboard: false,
                  ignoreBackdropClick: true,
                  class: 'modal-dialog',
                  data: {
                    heading: 'Empresa Creada',
                    text: 'Empresa creada correctamente, ¿desea acceder a su ficha?',
                    register: firm,
                    form_entity: 'firm',
                  }
                })

                this._tabs.removeTabByTabId(this.tab.tabId)
            },
            error => {
              this._buttons.setLoading(false);
            }
          )
        )

      }


  }

}
