import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();

export class ScalesFilters {

  public staticFilters;
  public optionalFilters =[];
  public dropdowns = ['provider', 'guild'];

  constructor(private entities){

    this.staticFilters = [
      {
        entity: this.entities.scale.entity,
        field: {
          name: 'code',
          type: 'text',
          label: 'Código',
          width: 2
        }
      },
      {
        entity: this.entities.scale.entity,
        field: {
          name: 'description',
          type: 'text',
          label: 'Descripción',
          width: 2
        }
      },
      {
        entity: this.entities.guild.entity,
        field: {
          name: 'id',
          type: 'select',
          label: titleCasePipe.transform(this.entities.guild.labels.form),
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: entities.guild.entity
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: this.entities.company.entity,
        field: {
          name: 'id',
          type: 'select',
          label: 'Proveedor',
          width: 2,
          dynamic: true,
          dropdown_data: {
            value: 'id',
            text: 'name',
            entity: entities.company.entity
          },
          options: [
            {
              value:  -1,
              text: 'Todos'
            }
          ]
        }
      },
      {
        entity: 'province',
        field: {
          name: 'name',
          type: 'text',
          label: 'Provincia',
          width: 2
        }
      },
    ]
  }

}

