import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalService, TabsetComponent } from 'ng-uikit-pro-standard';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { FirmAddressInterface } from 'src/app/shared/interfaces/data/firm-address-interface';
import { LinesInterface } from 'src/app/shared/interfaces/data/lines-interface';
import { LiquidationInterface } from 'src/app/shared/interfaces/data/liquidation-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { ReceiptInterface } from 'src/app/shared/interfaces/data/receipt-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { catchError } from 'rxjs/operators';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-liquidation',
  templateUrl: './liquidation.component.html',
  styleUrls: ['./liquidation.component.scss']
})
export class LiquidationComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('liquidationTabs', { static: true }) liquidationTabs: TabsetComponent;

  public id: number;
  public dropdowndata
  public loading = false;
  public liquidation: LiquidationInterface;
  public receipts: Array<ReceiptInterface>;
  public FiltersActive = false;
  private utils = new PGAUtils;

  /**
   * lineas de la liquidación
   */
  public details: Array<any>;

  /**
   * lineas liquidables del empleado
   */
  public employee_lines: Array<LinesInterface>;

  /**
   * lineas liquidables no insertadas en la liquidación
   */
  public available_lines: Array<LinesInterface>;

  /**
   * lineas liquidables no insertadas mostradas (filtradas)
   */
  public filter_lines: Array<LinesInterface>;

  /**
   * lineas eliminadas
   */
  public delete_lines: Array<any>;

  //filtros lineas
  public filterWorkorder: string = '';
  public filterClaim: string = '';
  public filterCode: string = '';

  //formularios de las lineas de la liquidación
  public detailsForms: FormArray = new FormArray([]);

  private formFunctions = new FormFunctions;
  public dateFormatter = new DateFormats;


  public firm_addresses: Array<FirmAddressInterface>;

  public formLiquidation: FormGroup;

  private subscriptions: Array<Subscription> = [];

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  public activeTab: number | null;

  public claim_entity = this._entity.entities.claim;
  public workorder_entity = this._entity.entities.workorder;
  public employee_entity = this._entity.entities.employee;
  public serie_entity = this._entity.entities.serie;

  
  constructor(private modalService: MDBModalService, private _lock: LockService, private _api: ApirequestService, private _apifunctions: ApiFunctions, private _buttons: ActionButtonsService, private _notification: NotificationService, private fb: FormBuilder, private _entity: EntityService, private _dropdowns: DropdownService) { }

  ngOnInit(): void {
    this.id = +this.data.id;
    this.formLiquidation = this.formFunctions.getForm(this.data.entity);
    this.getData();

  }
  onGetActiveTab(ev){
    this.activeTab =  ev?.activeTabIndex;
  }
  private getData() {
    this.loading = true;
    this.detailsForms.clear();
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns();
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  ///FUncion para activar o no los filtros
  public activeFilters()
  {
    if(this.FiltersActive)
    {
      this.FiltersActive = false;
    }
    else
    {
      this.FiltersActive = true;
    }
  }

  goToTab(index: number) {
    this.liquidationTabs.setActiveTab(index);
  }

  private setFirmAddresses(){
    this.loading = true;
    let newurl = new URL(this._api.getAPIEndpoint()+'firmsaddresses');
    let filter_firm = {
      firmaddress: {
        firm_id: this.liquidation.firm_id
      }
    }

    newurl.searchParams.append('filters', JSON.stringify(filter_firm));
    newurl.searchParams.append('paginate', JSON.stringify(0));

    this.subscriptions.push(
      this._api.get('firmsaddresses'+newurl.search).subscribe(
        response => {
          this.dropdowndata['firm_addresses'] = response.response.firmaddress;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    this.dropdowndata = data;
  }

  /**
   * devuelve el valor del impuesto del que se pasa el id por parámetro
   * @param id id del impuesto
   */
  getTaxeValueFromId(id: number): number {
    return +this.dropdowndata.taxe.find(t => t.id == id).value;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.liquidation = apiresponse.liquidation;
    this.details = apiresponse.liquidation.lines;
    this.receipts = apiresponse.liquidation.liquidationreceipts;

    this.setFirmAddresses();

    this.tab.tabData.headerData = this.liquidation.number;


    if(!this.lockdata.unblock) {
      this.loading = false;
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }


  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formLiquidation = undefined;
          setTimeout(() => {
            this.formLiquidation = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?: boolean) {
    this.detailsForms.clear();
    if(block){
      this.formFunctions.rellenarFormulario(this.formLiquidation, this.liquidation);
      this.details.forEach(
        detail => {
          this.fillLineForm(detail);
        }
      )
      this.formLiquidation.disable();
    } else {

      this.formFunctions.rellenarFormulario(this.formLiquidation, this.liquidation);

      this.details.forEach(
        detail => {
          this.fillLineForm(detail);
        }
      )

      if(!this.disableChanges()) {
        this.loadEmployeeLines()
      } else {
        this.loading = false;
      }

      this.formLiquidation.controls.emission_date.setValue(this.dateFormatter.cambioFormato(this.liquidation.emission_date));

      this.subscriptions.push(
        this.formLiquidation.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      )


    this.subscriptions.push(
      this.detailsForms.valueChanges.subscribe(
        change => {
          this.tab.modifiedData = true;
        }
      )
    )
    }

   // console.log(this.formLiquidation.controls)
  }

  private loadEmployeeLines(){
    this.loading = true;

    //reseteo de filtros y arrays de lineas
    this.filterClaim = '';
    this.filterCode = '';
    this.filterWorkorder = '';
    this.employee_lines = [];
    this.filter_lines = [];
    this.available_lines = [];
    this.delete_lines = [];

    let newUrl = new URL(this._api.getAPIEndpoint() + 'workorderslines');

    let linesFilters = {
      workorderlines: {
        employee_id: this.formLiquidation.controls.employee['controls'].id.value,
        //línea liquidable
        typeliquidation_id: 2
      },
      workorder: {
        //parte terminado
        typecondition_id: 2
      }
    };
    newUrl.searchParams.append('filters', JSON.stringify(linesFilters));

    newUrl.searchParams.append('paginate', JSON.stringify(0));

    this.subscriptions.push(
      this._api.get('workorderslines' + newUrl.search).subscribe(
        response => {
          this.employee_lines = response.response.workorderlines;
          this.setEmployeeLines();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  setEmployeeLines(filter?:boolean) {
    //de las lineas liquidables del empleado filtra las que no se han añadido al formulario
    this.available_lines = this.employee_lines.filter(
      line => {
        if(!this.details.find((l) => l.workorderline.id == line.id)){
          return true
        }
      }
    )

    if(filter) {
      //si se han aplicado filtros, se aplican a las lineas disponibles
      this.filterLines()
    } else {
      //si no se han aplicado filtros las lineas disponibles son las que se muestran
      this.filter_lines = [...this.available_lines];
    }

  }

  filterLines(){
    this.filter_lines = this.available_lines.filter(line=> {
      let found = false;

      //filtro parte
      let workorder = false;
      if(this.filterWorkorder !== ''){
        if(line.workorder_number.toUpperCase().includes(this.filterWorkorder.toUpperCase())){
          workorder=true;
        }
      } else {
        workorder = true;
      }

      //filtro expediente
      let claim = false;
      if(this.filterClaim !== ''){
        if(line.claim_number?.toString().toUpperCase().includes(this.filterClaim.toUpperCase())){
          claim=true;
        }
      } else {
        claim = true;
      }

      //filtro codigo
      let code = false;
      if(this.filterCode !== ''){
        if(line.code?.toUpperCase().includes(this.filterCode.toUpperCase())){
          code=true;
        }
      } else {
        code = true;
      }

      if(workorder && claim && code) {
        found = true;
      }

      return found
    }

    );
  }

  clearFilters(){
    this.filterClaim = '';
    this.filterWorkorder = '';
    this.filterCode = '';
    this.filter_lines = [...this.available_lines]
  }

  /**
   * Inserta la linea en el array de lineas de la liquidación y genera su formulario de linea
   * @param line linea a añadir
   */
  addLine(line: LinesInterface){
    let idline = null;

    //si la linea tiene liquidationline_id, es una linea existente de la liquidación que se vuelve a insertar
    if(line.liquidationline_id){
      idline = line.liquidationline_id;

      //se elimina del array de lineas eliminadas
      if(this.delete_lines.findIndex(line => line.id == idline) >= 0){
        this.delete_lines.splice(this.delete_lines.findIndex(line => line.id == idline),1);
      }

      //se elimina del array de lineas liquidables del operario
      if(this.employee_lines.findIndex(line => line.liquidationline_id == idline)>=0) {
        this.employee_lines.splice(this.employee_lines.findIndex(line => line.liquidationline_id == idline), 1)
      }
    }


    let insertline = {
      code: line.code,
      cost: line.cost,
      description: line.description,
      discount: line.discount,
      id: idline,
      quantity: line.quantity,
      taxe_id: line.taxe_id,
      total: line.total,
      total_cost: line.total_cost,
      total_taxe: line.total_taxe,
      workorderline: {...line}
    };

    //se añade al array de lineas de la liquidación y se genera el formulario de linea
    this.details.push(insertline)
    this.fillLineForm(insertline);

    this.calculateTotal();

    //actualiza las lineas disponibles y filtradas
    this.setEmployeeLines(true);
  }

  /**
   * Elimina la linea del array de lineas de liquidación y la añade a las lineas liquidables de empleado
   * @param index índice (posición) de la línea en el array de lineas de la liquidación
   */
  public removeLine(index: number) {
    let workorderline = {...this.details[index]};

    //eliminación del array de lineas liquidables y del formulario de linea
    this.details.splice(index, 1)
    this.detailsForms.removeAt(index);

    //si la línea tiene id es de la liquidación, se añade a las liquidables del empleado y al array de lineas eliminadas
    if(workorderline.id) {
      this.employee_lines.push(workorderline.workorderline)
      if(this.delete_lines.findIndex(line => line.id == workorderline.id) < 0){
        this.delete_lines.push(workorderline)
      }
    }
    this.calculateTotal();
    this.setEmployeeLines(true)
  }

  /**
   * Rellena el formulario de linea insertada y escucha los cambios para calcular totales
   * @param line datos de la linea
   */
  private fillLineForm(line){

    const lineform = this.fb.group({
      id:[{value: line.id, disabled: true}],
      liquidation_id:[{value: this.id, disabled: true}],
      workorder_line_id:[{value: line.workorderline.id, disabled: true}],
      code:[{value: line.code, disabled: this.disableChanges()}],
      description:[{value:line.description, disabled: this.disableChanges()}],
      quantity:[{value:line.quantity, disabled: this.disableChanges()}, [Validators.required, Validators.min(1)]],
      cost:[{value:line.cost, disabled: this.disableChanges()}, [Validators.required, Validators.min(0)]],
      taxe_id:[{value:line.taxe_id, disabled: this.disableChanges()}, [Validators.required, Validators.min(0)]],
      total_cost:[{value: line.total_cost, disabled: true}],
      total_taxe:[{value: line.total_taxe, disabled: true}],
      total:[{value: line.total, disabled: true}],
    });

    lineform.controls.quantity.valueChanges.subscribe(
      value => {
        if(value >=0) {
          this.calculateLine(lineform);

        }
      }
    )
    lineform.controls.cost.valueChanges.subscribe(
      val => {
        if(val>=0) {
          this.calculateLine(lineform);
        }
      }
    )
    lineform.controls.taxe_id.valueChanges.subscribe(
      val => {
        if(val){
          this.calculateLine(lineform);
        }
      }
    )
    this.detailsForms.push(lineform)
  }

  /**
   * Calcula los totales de la linea y los añade al formulario de linea
   * @param form formulario de linea
   */
  calculateLine(form: FormGroup){
    let total_cost = ((+form.controls.quantity.value)*(+form.controls.cost.value)).toFixed(2);

    form.controls.total_cost.setValue(total_cost);

    let total_iva = (+(total_cost)*this.getTaxeValueFromId(form.controls.taxe_id.value))/100;
    form.controls.total_taxe.setValue(total_iva.toFixed(2));

    let total_linea = (+total_cost) + (+form.controls.total_taxe.value);
    form.controls.total.setValue(total_linea.toFixed(2));

    this.calculateTotal();
  }

  /**
   * Calcula los totales de la liquidacion a partir de los de las lineas
   */
  calculateTotal(){
    let total = 0;
    let total_taxe = 0;
    let total_cost = 0;
    for (let j = 0; j < this.detailsForms.controls.length; j++) {
      let form: FormGroup = this.detailsForms.controls[j] as FormGroup;
      total += +form.controls.total.value;
      total_taxe += +form.controls.total_taxe.value;
      total_cost += +form.controls.total_cost.value;
    }

    this.formLiquidation.controls.total_cost.setValue(+total_cost.toFixed(2));
    this.formLiquidation.controls.total_taxe.setValue(+total_taxe.toFixed(2));
    this.formLiquidation.controls.total.setValue(+total.toFixed(2));

    this.formLiquidation.controls.total_cost.markAsDirty();
    this.formLiquidation.controls.total_taxe.markAsDirty();
    this.formLiquidation.controls.total.markAsDirty();

  // console.log(this.formLiquidation.controls)
  }

  changeSelect(tipo: string, valor) :void{
    this.formLiquidation.controls[tipo]['controls'].id.setValue(valor);
    this.formLiquidation.controls[tipo]['controls'].id.markAsDirty();
  }

  disableChanges(): boolean {
    let readonly = false;
    if(!this.lockdata?.unblock) {
      readonly = true;
    } else {
      if(this.liquidation && this.liquidation.type_state_id > 1) {
        readonly = true;
      }
    }

    return readonly;
  }

  onSubmit(){
    this.formLiquidation.markAllAsTouched()
    this.detailsForms.markAllAsTouched()

    if(this.formLiquidation.valid && this.detailsForms.valid) {
      this._buttons.setLoading(true);
      this.calculateTotal()
      const params = {};
      this.formFunctions.fillFormParams(this.formLiquidation, this.data.entity, params);

      params['liquidationlines'] = this.detailsForms.getRawValue();

      this.delete_lines.forEach(
        delete_line => {
          params['liquidationlines'].push({id: delete_line.id, workorder_line_id: delete_line.workorderline.id, delete: true})
        }
      )

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint+'/'+this.data.id, JSON.stringify(params)).subscribe(
          resp=>{
            this.tab.modifiedData = false;
            this.formLiquidation.markAsPristine();
            this.unsubscribeAll()
            this.getData();
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      )
    }
  }

  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }

  }

  ngOnDestroy(): void {
    this.unsubscribeAll()

    this._api.unlockExit(this.lockdata)
  }

}
