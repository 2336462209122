import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { EntitiesAlerts } from 'src/app/shared/entities/alerts';
import { EntitiesData } from 'src/app/shared/entities/entities-data';
import { ModulesService } from './modules.service';
import { UserPermissionsService } from './user-permissions.service';
import { EntityService } from './entity.service';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private init_page_options = [
    { value: 'inicio', label: 'Inicio', group: false},
    { value: 'userprofile', label: 'Perfil' },
  ]

  private readonly whatsapp_templates = [
    {
      type: 'welcome',
      template: 'inicio'
    },
    {
      type: 'solicitud',
      template: 'hello_world'
    }
  ];

  private readonly START_TEMPLATE = {
    name: '',
    typewhatsapptemplatecategory_id: 2,
    message: '',
    button_message: 'Conforme cliente'
  }

  private app_alerts: Array<any> =[];
  public appAlerts = new BehaviorSubject<Array<any>>(this.app_alerts);

  apiGoogleLoaded: Observable<boolean>;

  apiGoogle: boolean;
  apiGoogleSubject = new Subject<boolean>();

  constructor(private http: HttpClient, private titlecasePipe: TitleCasePipe, private _userpermissions: UserPermissionsService, private _modules: ModulesService, private _entity: EntityService) 
  { 
    
  }

  /**
   * Funcion para subscribirse al servicio de Google Mapas
   */
  setGoogleMapsApi(): void 
  {

    this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC6TSA1F-svobLuCFCu2GD2ebFe-RkPHGY', 'callback')
    .subscribe(
      response => {
        this.apiGoogle = true;
        this.apiGoogleSubject.next(true);
      },
      error => {
        this.apiGoogle = false;
        this.apiGoogleSubject.next(false);
      }
    )

    /* this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyC6TSA1F-svobLuCFCu2GD2ebFe-RkPHGY', 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        ); */
  }

  /**
   * Funcion para devolver un booleano con el estado del api de Google
   * @returns 
   */
  getGoogleMapsApi(): boolean 
  {
    return this.apiGoogle;
  }

  addAppAlert(value: string) {
    if(!this.app_alerts.find(item => item.type === value)) {
      let alert = {
        type: value,
        text: new EntitiesAlerts().getAlertMessage(value)
      }

      this.app_alerts.push(alert);
      this.appAlerts.next(this.app_alerts)
    }
  }

  deleteAppAlert(value: string) {
    this.app_alerts = this.app_alerts.filter(item => item.type !== value);
    this.appAlerts.next(this.app_alerts)

  }

  getAppAlerts(): Array<any> {
    return this.app_alerts;
  }

  get WhatsAppTemplates(): Array<any> {
    return this.whatsapp_templates;
  }

  checkInitTabEntity(entity: string): boolean {
    let check = false;
    let init_tab_modules = new EntitiesData(entity).modules;
    if(init_tab_modules.length === 0 || this._modules.checkModules(init_tab_modules)) {
      check = true;
    }

    return check;
  }

  getInitPageOptions(){
    const entities = this._entity.entities;
    //TODO: COMPROBAR MODULOS
    let user_init_page_options = [...this.init_page_options];

    user_init_page_options.push({ value:'', label: 'Gestión', group: true})
    let clients_params = entities.client;
    if(this._userpermissions.checkPermission(clients_params.permissions.list)) {
      user_init_page_options.push({ value: clients_params.list_entity, label: this.titlecasePipe.transform(clients_params.labels.list) });
    }

    if(this._modules.checkModule('companies')) {
      let companies_params = entities.company;
      if(this._userpermissions.checkPermission(companies_params.permissions.list)) {
        user_init_page_options.push({ value: companies_params.list_entity, label: this.titlecasePipe.transform(companies_params.labels.list) });
      }

      let agents_params = entities.agent;
      if(this._userpermissions.checkPermission(agents_params.permissions.list)) {
        user_init_page_options.push({ value: agents_params.list_entity, label: this.titlecasePipe.transform(agents_params.labels.list) });
      }
    }



    user_init_page_options.push({ value:'', label: 'Tramitación', group: true})
    let claims_params = entities.claim;
    if(this._userpermissions.checkPermission(claims_params.permissions.list)) {
      user_init_page_options.push({ value: claims_params.list_entity, label: this.titlecasePipe.transform(claims_params.labels.list) });
    }


    let workorders_params = entities.workorder;
    if(this._userpermissions.checkPermission(workorders_params.permissions.list)) {
      user_init_page_options.push({ value: workorders_params.list_entity, label: this.titlecasePipe.transform(workorders_params.labels.list) });
    }

    if(this._modules.checkModule('billing')) {
      user_init_page_options.push({ value:'', label: 'Facturación', group: true})
      let invoices_params = entities.invoice;
      if(this._userpermissions.checkPermission(invoices_params.permissions.list)) {
        user_init_page_options.push({ value: invoices_params.list_entity, label: this.titlecasePipe.transform(invoices_params.labels.list) });
      }


      let budgets_params = entities.budget;
      if(this._userpermissions.checkPermission(budgets_params.permissions.list)) {
        user_init_page_options.push({ value: budgets_params.list_entity, label: this.titlecasePipe.transform(budgets_params.labels.list) });
      }

      let deliverynotes_params = entities.budget;
      if(this._userpermissions.checkPermission(deliverynotes_params.permissions.list)) {
        user_init_page_options.push({ value: deliverynotes_params.list_entity, label: this.titlecasePipe.transform(deliverynotes_params.labels.list) });
      }

    }




    //return user_init_page_options.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
    return user_init_page_options;
  }



  getProtocolLogo(id_protocol: number): string {
    let path = 'assets/images/protocols/';

    switch (id_protocol) {
      case 1:
        path += 'logo_asitur.svg'
        break;
      case 2:
        path += 'caser_helvetia.png'
        break;
      case 3:
        path += 'homeserve.png'
        break;
      case 4:
        path += 'santa_lucia.svg'
        break;
      case 5:
        path += 'axa.png'
        break;

      default:
        path = null;
        break;
    }
    return path;
  }

  getProtocolsEnum(): Array<any> {
    return [
      {
        value: 'all',
        label: '--TODOS--'
      },
      {
        value: 'asitur',
        label: 'Asitur'
      },
      {
        value: 'caser',
        label: 'Caser'
      },
      {
        value: 'homeserve',
        label: 'HomeServe'
      },
      {
        value: 'santalucia',
        label: 'Santa Lucía'
      },
      {
        value: 'axa',
        label: 'Axa'
      },
      {
        value: 'funciona',
        label: 'Funciona'
      },
    ]
  }

  getWhatsappStartTemplate(firm_name: string, firm_alias: string): any {
    this.START_TEMPLATE.message = 'Se ha asignado a la empresa '+firm_name+' para resolver su siniestro, en adelante puede contactar mediante este canal. Gracias.';
    this.START_TEMPLATE.name ='welcome_'+firm_alias;
    return this.START_TEMPLATE;
  }
}
