<div class="modal-header justify-content-center">
  <h4 class="modal-title" id="myModalLabel">{{header | titlecase}}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="formGuild">
    <div class="row">
      <div class="col-3">
        <mdb-checkbox formControlName="enabled" [default]="true">Activo</mdb-checkbox>
      </div>
      <div class="col">
        <div class="md-form">
          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="guildname" formControlName="name" placeholder="" class="form-control form-control-sm">
          <label for="guildname">Nombre</label>
          <mdb-error *ngIf="formGuild.controls.name.invalid && (formGuild.controls.name.dirty || formGuild.controls.name.touched)">
            Campo obligatorio
          </mdb-error>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer text-center">
  <button mdbBtn type="button" size="sm" color="default" mdbWavesEffect (click)="submit()">
    <mdb-icon fas icon="check"></mdb-icon>
  </button>
  <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="modalRef.hide()">
    <mdb-icon fas icon="undo"></mdb-icon>
  </button>
</div>
