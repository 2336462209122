import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LinesInterface } from 'src/app/shared/interfaces/data/lines-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-new-liquidation',
  templateUrl: './new-liquidation.component.html',
  styleUrls: ['./new-liquidation.component.scss']
})
export class NewLiquidationComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  public loading: boolean = true; //spinner

  public dropdowns: DropdownsInterface;
  private subscriptions: Array<Subscription> = [];
  private formFunctions = new FormFunctions;
  public FiltersActive = false;

  public formNewLiquidation: FormGroup;
  lineForm: FormGroup;

  public employee_lines: Array<LinesInterface>;
  public filter_lines: Array<LinesInterface>;
  public available_lines: Array<LinesInterface>;
  public inserted_lines: Array<any> = [];
  private utils = new PGAUtils;

  public edit_line: LinesInterface;

  public filterWorkorder: string = '';
  public filterClaim: string = '';
  public filterCode: string = '';

  modalRefEmployee: MDBModalRef | null = null;
  modalRefOpenRegister: MDBModalRef | null = null;

  public claim_entity = this._entity.entities.claim;
  public workorder_entity = this._entity.entities.workorder;
  public employee_entity = this._entity.entities.employee;
  public serie_entity = this._entity.entities.serie;

  constructor(private modalService: MDBModalService, private _buttons: ActionButtonsService, private fb: FormBuilder, public api: ApiFunctions, private _api: ApirequestService,
    private _notification: NotificationService, private _tabs: TabService, private _entity: EntityService, private _dropdowns: DropdownService) {

     }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.createForm();
    this.getDropdowns();
  }

  createForm() {
    this.formNewLiquidation = this.fb.group({
      employee_id:[null, [Validators.required]],
      employee_name: ['', [Validators.required]],
      description: ['', []],
      footer: ['', []],
      serie_id: [1],
      total_cost: [0],
      total_price: [0],
      total_taxe: [0],
      total: [0],
    });
  }

  ///FUncion para activar o no los filtros
  public activeFilters()
  {
    if(this.FiltersActive)
    {
      this.FiltersActive = false;
    }
    else
    {
      this.FiltersActive = true;
    }
  }

  // Evento que se ejecuta al abrir el primer modal
  getDropdowns() {
    this.formNewLiquidation.controls.employee_id.enable();

    this.subscriptions.push(
      //this.api.getDropdowns(['employee', 'taxe', 'serie']).subscribe(data => {
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        data => {

        this.dropdowns = this.utils.objectDeepCopy(data.response.dropdown);
        this.dropdowns.taxe = this.dropdowns.taxe.filter(e => e.enabled == true);

        this.loading = false;

        this.subscriptions.push(
          this.formNewLiquidation.valueChanges.subscribe(
            change => {
              this.tab.modifiedData = true;
            }
          )
        );
      })
    );
  }

  selectEmployee(){
    this.modalRefEmployee = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.employee_entity.labels.form,
              list_headers: [
                'Activo',
                'Acceso Web',
                'NIF',
                'Nombre',
                'Dirección',
                'Teléfono',
                'Móvil',
                'Email',
              ],
              list_fields: [
                { entity: this.employee_entity.entity, field: 'enabled', type: 'check'},
                { entity: this.employee_entity.entity, field: 'web_access', type: 'check'},
                { entity: this.employee_entity.entity, field: 'identification_document' },
                { entity: this.employee_entity.entity, field: 'name_complete'},
                { entity: this.employee_entity.entity, field: 'line_complete' },
                { entity: this.employee_entity.entity, field: 'phone' },
                { entity: this.employee_entity.entity, field: 'mobile' },
                { entity: this.employee_entity.entity, field: 'email' },
              ],
              list_entity: this.employee_entity.entity,
              list_endpoint: this.employee_entity.list_entity,
            }
          })

    //recoge los datos del cliente seleccionado
    this.modalRefEmployee.content.selectedRegister.subscribe(register => {

      this.formNewLiquidation.controls.employee_id.setValue(register.id)
      this.formNewLiquidation.controls.employee_name.setValue(register.name_complete);

      this.formNewLiquidation.controls.employee_id.markAsDirty();
      this.formNewLiquidation.controls.serie_id.markAsDirty();

      this.loadEmployeeLines();
    })

    //libera memoria de seleccion de empleado
    this.modalService.closed.subscribe(() => this.modalRefEmployee.content.onClosed())
  }

  private loadEmployeeLines(){
    this.loading = true;
    this.filterClaim = '';
    this.filterCode = '';
    this.filterWorkorder = '';
    this.employee_lines = [];
    this.filter_lines = [];
    this.available_lines = [];
    this.inserted_lines = [];
    this.edit_line = null;

    let newUrl = new URL(this._api.getAPIEndpoint() + 'workorderslines');

    let linesFilters = {
      workorderlines: {
        employee_id: this.formNewLiquidation.controls.employee_id.value,
        //línea liquidable
        typeliquidation_id: 2
      },
      workorder: {
        //parte terminado
        typecondition_id: 2
      }
    };
    newUrl.searchParams.append('filters', JSON.stringify(linesFilters));

    newUrl.searchParams.append('paginate', JSON.stringify(0));

    this.subscriptions.push(
      this._api.get('workorderslines' + newUrl.search).subscribe(
        response => {
          this.employee_lines = response.response.workorderlines;
          this.setEmployeeLines()
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  filterLines(){
    this.filter_lines = this.available_lines.filter(line=> {
      let found = false;

      let workorder = false;
      if(this.filterWorkorder !== ''){
        if(line.workorder_number.toUpperCase().includes(this.filterWorkorder.toUpperCase())){
          workorder=true;
        }
      } else {
        workorder = true;
      }

      let claim = false;
      if(this.filterClaim !== ''){
        if(line.claim_number?.toString().toUpperCase().includes(this.filterClaim.toUpperCase())){
          claim=true;
        }
      } else {
        claim = true;
      }

      let code = false;
      if(this.filterCode !== ''){
        if(line.code?.toUpperCase().includes(this.filterCode.toUpperCase())){
          code=true;
        }
      } else {
        code = true;
      }

      if(workorder && claim && code) {
        found = true;
      }

      return found
    }

    );
  }

  clearFilters(){
    this.filterClaim = '';
    this.filterWorkorder = '';
    this.filterCode = '';
    this.filter_lines = [...this.available_lines]
  }

  setEmployeeLines(filter?:boolean) {
    this.available_lines = this.employee_lines.filter(
      line => {
        if(!this.inserted_lines.find((l) => l.workorder_line_id == line.id)){
          return true
        }
      }
    )

    if(filter) {
      this.filterLines()
    } else {
      this.filter_lines = [...this.available_lines];
    }

    this.calculateTotalDetails()

  }
  public addLine(line: LinesInterface){

    this.inserted_lines.push({
      workorder_line_id: line.id,
      workorder_number: line.workorder_number,
      claim_number: line.claim_number,
      code: line.code,
      description: line.description,
      cost: line.cost,
      quantity: line.quantity,
      taxe_id: line.taxe_id,
      total: line.total_cost,
      total_taxe: line.total_taxe,
      total_cost: line.total_cost
    });
    this.setEmployeeLines(true)

  }
  public removeLine(line: any) {
    this.inserted_lines.splice(this.inserted_lines.findIndex((l) => l.workorder_line_id == line.workorder_line_id), 1);
    this.setEmployeeLines(true)


  }

 editLine(line){
      this.edit_line = line;
      this.lineForm = this.fb.group({
        workorder_line_id:[{value: line.workorder_line_id, disabled: true}],
        code:[line.code],
        description:[line.description],
        quantity:[line.quantity, [Validators.required, Validators.min(1)]],
        cost:[line.cost, [Validators.required, Validators.min(0)]],
        taxe_id:[line.taxe_id, [Validators.required, Validators.min(0)]],
        total_cost:[{value: line.total_cost, disabled: true}],
        total_taxe:[{value: line.total_taxe, disabled: true}],
        total:[{value: line.total, disabled: true}],
      })

      this.lineForm.controls.quantity.valueChanges.subscribe(
        value => {

          if(value >=0) {
            this.calculateLine();

          }
        }
      )

      this.lineForm.controls.cost.valueChanges.subscribe(
        val => {
          if(val>=0) {
            this.calculateLine();
          }
        }
      )


      this.lineForm.controls.taxe_id.valueChanges.subscribe(
        val => {
          if(val){
            this.calculateLine();
          }
        }
      )

  }

  calculateLine(){
    let total_cost = ((+this.lineForm.controls.quantity.value)*(+this.lineForm.controls.cost.value)).toFixed(2);

    this.lineForm.controls.total_cost.setValue(total_cost);

    let total_iva = (+(total_cost)*this.getTaxeValueFromId(this.lineForm.controls.taxe_id.value))/100;
    this.lineForm.controls.total_taxe.setValue(total_iva.toFixed(2));

    let total_linea = (+total_cost) + (+this.lineForm.controls.total_taxe.value);
    this.lineForm.controls.total.setValue(total_linea.toFixed(2));

  }

  clearEdit() {
      this.edit_line = null;
      this.lineForm.reset();
  }

  saveLine(line){
    this.lineForm.markAllAsTouched()
    if(this.lineForm.valid) {
      this.calculateLine();
      let formvalues = {...this.lineForm.getRawValue()}
      Object.keys(formvalues).forEach(
        field => {
          line[field] = formvalues[field]
        }
      )

      this.edit_line = null

      this.calculateTotalDetails()

    }
   //
  }

  //devuelve el valor del impuesto del que se pasa el id por parámetro
  getTaxeValueFromId(id: number): number {
    return +this.dropdowns.taxe.find(t => t.id == id).value;
  }

  //calcula los totales del parte a partir de los de las lineas
  private calculateTotalDetails(): void{
    let total = 0;
    let total_taxe = 0;
    let total_cost = 0;
    for (let j = 0; j < this.inserted_lines.length; j++) {

      total += +this.inserted_lines[j].total;
      total_taxe += +this.inserted_lines[j].total_taxe;
      total_cost += +this.inserted_lines[j].total_cost;

    }


    this.formNewLiquidation.controls.total_cost.setValue(+total_cost.toFixed(2));
    this.formNewLiquidation.controls.total_taxe.setValue(+total_taxe.toFixed(2));
    this.formNewLiquidation.controls.total.setValue(+total.toFixed(2));

    this.formNewLiquidation.controls.total_cost.markAsDirty();
    this.formNewLiquidation.controls.total_taxe.markAsDirty();
    this.formNewLiquidation.controls.total.markAsDirty();

  }

  // Botón de guardar
  onSubmit() {
    // marcar para verificar si los campos son válidos
    this.formNewLiquidation.markAllAsTouched();

    //en ese caso
    if (this.formNewLiquidation.valid) {
      this._buttons.setLoading(true);
      const params = {};
      this.formFunctions.fillFormParams(this.formNewLiquidation, this.data.entity, params);

      params[this.data.entity]['taxe_id'] = 1
      params['liquidationlines'] = this.inserted_lines.map(line => {
        let l = {...line};
        delete l.workorder_number;
        delete l.claim_number;
        return l
      })

      console.log(params)
      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {
            let liquidation = response.response.liquidation;

            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg());

            //Abre el modal de apertura de registro
            this.modalRefOpenRegister = this.modalService.show(OpenRegisterComponent, {
                    keyboard: false,
                    ignoreBackdropClick: true,
                    class: 'modal-dialog',
                    data: {
                      heading: 'Registro Creado',
                      text: 'Registro creado correctamente, ¿desea acceder al registro?',
                      register: liquidation,
                      form_entity: this.data.entity,
                    }
                  })

                  this._tabs.removeTabByTabId(this.tab.tabId)
          },
          error => {

          }
        )
      );
    }

  }
}
