import { AdministrationComponent } from "src/app/main/sections/administration/administration.component"
import { Tab } from "src/app/main/tab-models/tab.model"

export class AdministrationModel {

  tabData = {
    entity: 'administration'
  }

  getTab(): Tab {
    return new Tab(
        AdministrationComponent,
        "<i class='fas fa-briefcase mr-1'></i>Panel Administración",
        {
            buttonNew: false,
            buttonDelete: false
        },
        this.tabData
    )
}
}
