import { TitleCasePipe } from "@angular/common";
import { InvoiceproformaFilters } from "../../data-classes";
import { DynamicListComponent } from "src/app/main/components/dynamic-list/dynamic-list.component";
import { Tab } from "src/app/main/tab-models/tab.model";
import { EntityBaseModel } from "./entity-base.model";
import { Type } from "@angular/core";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class InvoiceproformaModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'file-invoice-dollar',
    list: 'file-invoice-dollar'
  }


  setModelData(models:any) {

    models.invoiceproforma.icon = this.icon;

    models.invoice.modules = ['billing'];

    models.invoiceproforma.form_tabData = {
      entity: models.invoiceproforma.entity,
      saveEndpoint: models.invoiceproforma.saveEndpoint,
      entity_permissions: models.invoiceproforma.permissions,
      entity_modules:models.invoiceproforma.modules,
      new_register_dropdowns:['taxe', 'guild', 'serie', 'typeprovider', 'company_with_subcompanies', 'repairman', 'administrator'],
      edit_register_dropdowns: ['guild', 'taxe', 'typeexport', 'serie']
    }

    models.invoiceproforma.list_tabData = {
      entity: models.invoiceproforma.entity,
      entity_label: models.invoiceproforma.labels.form,
      entity_permissions:models.invoiceproforma.permissions,
      entity_modules:models.invoiceproforma.modules,
      listParams: {
        list_entity: models.invoiceproforma.list_entity,
        list_label: models.invoiceproforma.labels.list,
        limit: 10,
        headers: [
          'Número',
          titleCasePipe.transform(models.serie.labels.form),
          titleCasePipe.transform(models.client.labels.form),,
          titleCasePipe.transform(models.claim.labels.form),
          'Tramitador'
        ],
        fields: [
          {entity: models.invoiceproforma.entity, field: 'number'},
          {entity: models.serie.entity, field: 'description'},
          {entity: models.client.entity, field: 'name_complete'},
          {entity: models.claim.entity, field: 'number'},
          {entity: models.user.entity, field: 'name'}
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new InvoiceproformaFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME[models.invoiceproforma.entity],
        in_claim: {
          claim_id: null,
          limit:5,
          headers: [
            {label: 'Número', class: 'code-column'},
            {label: titleCasePipe.transform(models.serie.labels.form), class: 'numbers-column'},
            titleCasePipe.transform(models.client.labels.form),
            'Tramitador',
          ],
          fields: [
            {entity:  models.invoiceproforma.entity, field: 'number'},
            {entity: models.serie.entity, field: 'description'},
            {entity: models.client.entity, field: 'name'},
            {entity: models.user.entity, field: 'name'},
          ],
          lines_limit: 10,
          lines_headers: [
            {header: titleCasePipe.transform(models.invoiceproforma.labels.form), class: 'code-column'},
            {header: 'Exp.', class: 'icon-column'},
            {header: 'Código', class: 'code-column'},
            'Descripción',
            { header: 'Cantidad', class: 'numbers-column'},
            {header: 'Precio', class: 'numbers-column'},
            {header:'%Descuento', class: 'numbers-column'},
            {header:'%Impuesto', class: 'numbers-column'}
          ],
          lines_fields: [
            {
              field: 'invoiceproforma_number',
              type: 'none',
            },
            {
              control: 'type_export_line_id',
              type: 'checkbox',
              default:  0,
              disabled: false,
              class: 'icon-column'
            },
            {
              control: 'code',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'description',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'quantity',
              type: 'number',
              default: 1,
              disabled: false
            },
            {
              control: 'price',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'discount',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'taxe_id',
              type: 'select',
              dropdown: 'taxes',
              field_value: 'taxe_value',
              select_value: 'id',
              select_label: 'value',
              addKey: true,
              clear: false,
              listen_changes: true,
              disabled: false,
              default: 1,
            }
          ],
          export_table_data: [
            {
              header: 'Número',
              entity: models.invoiceproforma.entity,
              field_name: 'number',
              class: 'code-column'
            },
            {
              header: titleCasePipe.transform(models.client.labels.form),
              entity: models.client.entity,
              field_name: 'name_complete',
            },
            {
              header: 'Situación',
              entity: 'typeexport',
              field_name: 'name',
              class: 'code-column',
            },
            {
              header: 'total Base',
              entity: models.invoiceproforma.entity,
              field_name: 'total_price',
              class: 'numbers-column',
            },
            {
              header: 'Total Imp.',
              entity: models.invoiceproforma.entity,
              field_name: 'total_taxe',
              class: 'numbers-column',
            },
            {
              header: 'Total Neto',
              entity: models.invoiceproforma.entity,
              field_name: 'total',
              class: 'numbers-column',
            },
          ],
          export_line_table_data: [
            {
              header: 'Código',
              field_name: 'code',
              class: 'code-column'
            },
            {
              header: 'Descripción',
              field_name: 'description'
            },
            {
              header: 'Precio',
              field_name: 'price',
              class: 'numbers-column'
            },
            {
              header: 'Cantidad',
              field_name: 'quantity',
              class: 'numbers-column'
            },
            {
              header: '%Desc',
              field_name: 'discount',
              class: 'numbers-column'
            },
            {
              header: '%Impto',
              field_name: 'taxe_value',
              class: 'numbers-column'
            },
            {
              header: 'Base',
              field_name: 'total_price',
              class: 'numbers-column'
            },
          ],
          export_destinations: [
            {
              entity: models.invoice.entity,
              label: titleCasePipe.transform(models.invoice.labels.form),
              list: models.invoice.list_entity
            },
          ]
        }
    }


    }
  }

  getListTab(invoiceproforma_data): Tab {
    let tabData = {...invoiceproforma_data.list_tabData};
    return new Tab(
      DynamicListComponent,
      "<i class='"+this.fa_conf.list+' fa-'+invoiceproforma_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(invoiceproforma_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: true
      },
      tabData
      )
  }


  getNewTab(component: Type<any>, entity_data): Tab {
    let tabData = {...entity_data.form_tabData};
    tabData.newRegister = true;
    tabData.noRecover = true;

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>Nueva "+titleCasePipe.transform(entity_data.labels.form),
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
      )
  }
}

