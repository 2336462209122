import { GuildFormComponent } from "src/app/main/components/dynamic-modals/guild-form/guild-form.component";
import { EntityBaseModel } from "./entity-base.model";
import { Tab } from "src/app/main/tab-models/tab.model";
import { DynamicListComponent } from "src/app/main/components/dynamic-list/dynamic-list.component";
import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();

export class GuildModel extends EntityBaseModel {

  protected readonly icon = {
    form: 'tools',
    list: 'tools'
  }

  setModelData(models:any) {

    models.guild.icon = this.icon;

    models.guild.list_tabData = {
      entity: models.guild.entity,
      entity_label: models.guild.labels.form,
      entity_permissions:models.guild.permissions,
      saveEndpoint: models.guild.saveEndpoint,
      modal_form: GuildFormComponent,
      modal_class: 'modal-dialog',
      listParams: {
        list_entity: models.guild.list_entity,
        list_label: models.guild.labels.list,
        limit: 10,
        headers: [
          'Activo',
          'Nombre'
        ],
        fields: [
          { entity: models.guild.entity, field: 'enabled', type: 'check' },
          { entity: models.guild.entity, field: 'name' }
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: null,
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      DynamicListComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: false
      },
      tabData
      )
  }


}
