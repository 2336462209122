<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset">
  <!--PESTAÑA INFORMACION-->
  <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
      <ng-container *ngIf="formLiquidation">
        <form [formGroup]="formLiquidation">
          <div class="row mt-3">
            <div class="col-lg-2">
              <div class="md-form md-outline" mdbtooltip="emission_date">
                <mdb-date-picker [inline]="true" name="emission_date" [options]="dateFormatter.opcionesMDBDatePicker" id="emission_date" [label]="'Fecha Emisión'" [placeholder]="" formControlName="emission_date" required></mdb-date-picker>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="liquidationnumber" class="form-control form-control-sm" formControlName="number" placeholder="" readonly="true">
                <label for="liquidationnumber">Número</label>
              </div>
            </div>
            <div class="col-lg-2" formGroupName="employee">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="employee" class="form-control form-control-sm" placeholder="" formControlName="name_complete">
                <label for="employee">{{employee_entity.labels.form | titlecase}}</label>
              </div>
            </div>
            <div class="col-lg-2" formGroupName="typestate">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="typestatename" class="form-control form-control-sm" placeholder="" formControlName="name">
                <label for="typestatename">Estado</label>
              </div>
            </div>

            <div class="col-lg-1">
              <ng-container *ngIf="dropdowndata; else seriefield">
                <div class="md-form">
                    <app-dropdowns [label]="(serie_entity.labels.form | titlecase)" [type]="serie_entity.entity" [dropdowndata]="dropdowndata"
                      [disabled]="true"
                      [init_value]="formLiquidation.controls.serie.get('id').value"
                      [activefilter]="true"
                      [addFirstRegister]="true"
                      [label_field]="'description'"
                      [outline] = "true"
                      [appendToBody]="true"
                      (selectedValue)="changeSelect(serie_entity.entity, $event)"
                      >
                    </app-dropdowns>
                </div>
              </ng-container>
              <ng-template #seriefield>
                <div class="md-form md-outline" formGroupName="serie">
                  <input mdbInput type="text" id="seriename" class="form-control form-control-sm" placeholder="" formControlName="description">
                  <label for="seriename">{{serie_entity.labels.form | titlecase}}</label>
                </div>
              </ng-template>
            </div>

            <div class="col-lg" formGroupName="firmaddress">
              <ng-container *ngIf="dropdowndata?.firm_addresses; else firmaddress">
                <div class="md-form">
                  <app-dropdowns [label]="'Dirección Emisor'" [type]="'firm_addresses'" [dropdowndata]="dropdowndata"
                  [disabled]="disableChanges()"
                  [outline]="true"
                  [label_field]="'address_complete'"
                  [init_value]="formLiquidation.controls.firmaddress.get('id').value"
                  [activefilter]="false"
                  (selectedValue)="changeSelect('firmaddress', $event)"
                  >
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #firmaddress>
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="firmaddress_complete" class="form-control form-control-sm" placeholder="" formControlName="address_complete">
                  <label for="firmaddress_complete">Dirección Emisor</label>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg">
              <div class="md-form md-outline">
                <textarea rows="4" type="text" id="description" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="description"></textarea>
                <label for="description">Descripción</label>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <textarea rows="4" type="text" id="footer" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="footer"></textarea>
                <label for="footer">Pie</label>
              </div>
            </div>
          </div>
        </form>
        <div class="row mt-3">
          <div class="col-md">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                  <mdb-icon fad icon="list" size="lg" class="mr-1 text-primary"></mdb-icon>Líneas {{data.entity_label | titlecase}}
              </mdb-card-header>
              <mdb-card-body class="scroll-card-body">
                <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
                  <thead>
                      <tr>
                          <th scope="col" class="buttons-column"></th>
                          <th scope="col">{{workorder_entity.labels.form | titlecase}}</th>
                          <th scope="col">Código</th>
                          <th scope="col">Descripción</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">Importe</th>
                          <th scope="col-1">% IVA</th>
                          <th scope="col">Importe</th>
                      </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let detailForm of detailsForms.controls; let i=index">
                      <ng-container [formGroup]="detailForm">
                        <tr mdbTableCol>
                          <td class="buttons-column">
                            <a (click)="removeLine(i)" *ngIf="!disableChanges()">
                              <mdb-icon fad icon="trash" class="red-text mr-1"></mdb-icon>
                            </a>
                          </td>
                          <td>
                            <span>{{details[i].workorderline.workorder_number}}</span>
                          </td>
                          <td>
                            <input mdbInput type="text" id="linecode{{i}}" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" formControlName="code">
                          </td>
                          <td>
  
                            <input mdbInput type="text" id="linedescription{{i}}" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" formControlName="description">
  
  
                          </td>
                          <td>
  
                            <input mdbInput type="number" id="linequantity{{i}}" mdbValidate [validateSuccess]="false" class="form-control form-control-sm text-right" formControlName="quantity">
  
                          </td>
                          <td>
  
                            <input mdbInput type="number" id="linecost{{i}}" mdbValidate [validateSuccess]="false" class="form-control form-control-sm text-right" formControlName="cost">
  
                          </td>
                          <td>
  
                            <select class="form-control form-control-sm" id="linetaxe{{i}}" formControlName="taxe_id"  mdbValidate [validateSuccess]="false" >
                              <option *ngFor="let option of dropdowndata?.taxe" class="form-control form-control-sm" [value]="option.id">{{ option.value }}%</option>
                            </select>
  
                          </td>
                          <td>
                            <input mdbInput type="number" id="linetotal{{i}}" class="form-control form-control-sm text-right" formControlName="total">
                            
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    
                  </tbody>
                </table>
              </mdb-card-body>
              <!-- <mdb-card-footer class="d-flex justify-content-end">
  
              </mdb-card-footer> -->
            </mdb-card>
          </div>

          <div class="col-md">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                  <mdb-icon fad icon="list-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Líneas Liquidables <!--del {{employee_entity.labels.form | titlecase}}--> (Partes de trabajo)
                  <a class="ml-2"><mdb-icon fad icon="search" size="lg" class="mr-1 text-primary" (click)="activeFilters()"></mdb-icon></a>
                </mdb-card-header>
              <mdb-card-body class="scroll-card-body">
                <div class="container-fluid" *ngIf="available_lines?.length>0">
                  <ng-container *ngIf="FiltersActive;">

                    <div class="row">
                      <div class="col">
                        <div class="md-form md-outline">
                          <input mdbInput type="text" id="filterWorkorder" [(ngModel)]="filterWorkorder" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                          <label for="filterWorkorder">{{workorder_entity.labels.form | titlecase}}</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="md-form md-outline">
                          <input mdbInput type="text" id="filterClaim" [(ngModel)]="filterClaim" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                          <label for="filterClaim">{{claim_entity.labels.form | titlecase}}</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="md-form md-outline">
                          <input mdbInput type="text" id="filterCode" [(ngModel)]="filterCode" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                          <label for="filterCode">Código</label>
                        </div>
                      </div>
                      <div class="col-1">
                        <a class="blue-text" (click)="clearFilters()" *ngIf="filterCode != '' || filterClaim != '' || filterWorkorder != ''">
                          <mdb-icon fas icon="times" size="lg" class="my-2"></mdb-icon></a>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
                  <thead>
                      <tr>
                          <th scope="col" class="buttons-column"></th>
                          <th scope="col">{{workorder_entity.labels.form | titlecase}}</th>
                          <th scope="col">{{claim_entity.labels.form | titlecase}}</th>
                          <th scope="col">Código</th>
                          <th scope="col">Descripción</th>
                          <th scope="col">Cantidad</th>
                          <th scope="col">Importe</th>
                          <th scope="col">% IVA</th>
                          <th scope="col">Total</th>
                      </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let line of filter_lines; let i=index">
                      <tr mdbTableCol>
                        <td class="buttons-column">
                          <a (click)="addLine(line)" *ngIf="!disableChanges()">
                            <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                          </a>
                        </td>
                        <td><span>{{line.workorder_number}}</span></td>
                        <td><span>{{line.claim_number}}</span></td>
                        <td><span>{{line.code}}</span></td>
                        <td><span>{{line.description}}</span></td>
                        <td><span>{{line.quantity  | number:'1.2-2':'es'}}</span></td>
                        <td><span>{{line.cost  | number:'1.2-2':'es'}} €</span></td>
                        <td><span>{{getTaxeValueFromId(line.taxe_id)| number:'1.0-0':'es'}}%</span></td>
                        <td><span>{{line.total_cost  | number:'1.2-2':'es'}} €</span></td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </ng-container>
  </mdb-tab>
  <!--PESTAÑA RECIBOS-->
  <mdb-tab heading="<i class='fas fa-receipt'></i> Recibos">
    <div class="row mt-3">
      <app-receipts [receipts]="receipts"
      [entity]="'liquidationreceipt'"
      [entity_endpoint]="'liquidationsreceipts'"
      [parent_entity]="'liquidation'"
      [readonly]="disableChanges()"
      [concept_suffix]="'Pago '+(data.entity_label | titlecase)+': '" [register_number]="liquidation?.number" [register_id]="liquidation?.id" [typepaymentmethods]="dropdowndata?.typepaymentmethod" (loading)="loading = $event"></app-receipts>
    </div>
  </mdb-tab>
</mdb-tabset>



