import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { DetailLinesComponent } from '../../components/detail-lines/detail-lines.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { DeliveryNoteInterface } from 'src/app/shared/interfaces/data/delivery-note-interface';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { LockService } from '../../services/lock.service';
import { FirmAddressInterface } from 'src/app/shared/interfaces/data/firm-address-interface';
import { EntityService } from '../../services/entity.service';
import { catchError } from 'rxjs/operators';
import { DropdownService } from '../../services/dropdown.service';


@Component({
  selector: 'app-deliverynote',
  templateUrl: './deliverynote.component.html',
  styleUrls: ['./deliverynote.component.scss']
})
export class DeliverynoteComponent implements OnInit {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('deliverynoteTabs', { static: true }) deliverynoteTabs: TabsetComponent;
  @ViewChild('detailComponent', {static: false}) detailComponent: DetailLinesComponent



  public id: number;
  public dropdowndata
  public loading = false;
  public deliverynote: DeliveryNoteInterface;
  private formFunctions = new FormFunctions;

  public detail: Array<any>;

  private utils = new PGAUtils;


  public firm_addresses: Array<FirmAddressInterface>;
  private subscriptions: Array<Subscription> = [];

  public dateFormatter = new DateFormats;

  public formDeliverynote: FormGroup;

  public detail_headers: Array<any> = [
    {header: 'Exp.', class: 'buttons-column'},
    {header: 'Código', class: 'code-column'},
    'Descripción',
    { header: 'Cantidad', class: 'numbers-column'},
    {header: 'Precio', class: 'numbers-column'},
    {header:'%Descuento', class: 'percent-column'},
    {header:'%Impuesto', class: 'percent-column'}
  ]
  public detail_fields_order: Array<any> = [
    {
      control: 'type_export_line_id',
      type: 'checkbox',
      default:  0,
      disabled: false
    },
    {
      control: 'code',
      type: 'text',
      default: '',
      disabled: false,
      addKey: true,
    },
    {
      control: 'description',
      type: 'text',
      default: '',
      disabled: false,
      validate:true,
      validators:[Validators.required],
      error_msg: 'Campo obligatorio',
      addKey: true,
    },
    {
      control: 'quantity',
      type: 'number',
      default: 1,
      disabled: false,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio'
    },
    {
      control: 'price',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio'
    },
    {
      control: 'discount',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio'
    },
    {
      control: 'taxe_id',
      type: 'select',
      dropdown: 'taxes',
      field_value: 'value',
      addKey: true,
      clear: false,
      listen_changes: true,
      disabled: false,
      default: 1,
    }
  ]


  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;


  public claim_entity = this._entity.entities.claim;
  public client_entity = this._entity.entities.client;
  public guild_entity = this._entity.entities.guild;
  public serie_entity = this._entity.entities.serie;

  constructor(private _api: ApirequestService, private _lock: LockService, private _apifunctions: ApiFunctions, private _buttons: ActionButtonsService, private _notification: NotificationService, private _entity: EntityService, private _dropdowns: DropdownService) { }

  ngOnInit(): void {
    this.id = +this.data.id;
    this.formDeliverynote = this.formFunctions.getForm(this.data.entity);
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll()

    this._api.unlockExit(this.lockdata)
  }

  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }

  }

  private getData() {
    this.loading = true;
    this.detailComponent?.detailsForms.clear();
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setFirmAddresses(){
    this.loading = true;
    let newurl = new URL(this._api.getAPIEndpoint()+'firmsaddresses');
    let filter_firm = {
      firmaddress: {
        firm_id: this.deliverynote.firm_id
      }
    }

    newurl.searchParams.append('filters', JSON.stringify(filter_firm));
    newurl.searchParams.append('paginate', JSON.stringify(0));

    this.subscriptions.push(
      this._api.get('firmsaddresses'+newurl.search).subscribe(
        response => {
          this.dropdowndata['firm_addresses'] = response.response.firmaddress;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    data.guild = data.guild.filter(guild => guild.enabled == true);

    this.dropdowndata = data;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.deliverynote = apiresponse.deliverynote;
    this.detail = apiresponse.deliverynote.lines;

    this.setFirmAddresses();

    this.tab.tabData.headerData = this.deliverynote.number;


    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formDeliverynote = undefined;
          setTimeout(() => {
            this.formDeliverynote = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?: boolean) {

    if(block){
      this.formFunctions.rellenarFormulario(this.formDeliverynote, this.deliverynote);
      this.setDeliverynoteAddress();
      this.setDeliverynoteProviderName();
      this.formDeliverynote.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formDeliverynote, this.deliverynote);
      this.setDeliverynoteAddress();
      this.setDeliverynoteProviderName();
      this.formDeliverynote.controls.emission_date.setValue(this.dateFormatter.cambioFormato(this.deliverynote.emission_date));

      this.subscriptions.push(
        this.formDeliverynote.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      )
    }

  }

    private setDeliverynoteAddress() {
    let address = '';
    if(this.deliverynote.claim) {
      if(this.deliverynote.claim?.policy) {
        address = this.deliverynote.claim.policy.address_complete;
      } else if(this.deliverynote.claim?.clientaddress) {
        address = this.deliverynote.claim.clientaddress.address_complete;
      }
    } else if(this.deliverynote.clientaddress_id) {
      address = this.deliverynote.clientaddress.address_complete;
    }

    this.formDeliverynote.controls.deliverynote_address.setValue(address);
  }


  setDeliverynoteProviderName(){
    let provider_name = 'Particular';
    if(this.deliverynote.provider) {
      provider_name = this.deliverynote.provider.name;
      if(this.deliverynote.subprovider) {
        provider_name += ' ('+this.deliverynote.subprovider.name+')';
      }
    }

    this.formDeliverynote.controls.deliverynote_provider_name.setValue(provider_name);
  }

  disableChanges(): boolean {
    let readonly = false;

    if(!this.lockdata?.unblock) {
      readonly = true;
    } else {
      if(this.deliverynote && this.deliverynote.typecondition_id == 2) {
        //deshabilitar = true;
      }
    }

    return readonly;
  }

  changeSelect(tipo: string, valor) :void{
    this.formDeliverynote.controls[tipo]['controls'].id.setValue(valor);
    this.formDeliverynote.controls[tipo]['controls'].id.markAsDirty();
  }

  onSubmit() {

    this._buttons.setLoading(true);

    this.formDeliverynote.markAllAsTouched();

    let line_form_error = false;
    this.detailComponent.detailsForms.controls.forEach(
      form => {
        form.markAllAsTouched();
      }
    )
    for (let index = 0; index < this.detailComponent.detailsForms.controls.length; index++) {
      if(this.detailComponent.detailsForms.controls[index].invalid) {
        line_form_error = true;
        break;
      }
    }


    if(this.formDeliverynote.valid && !line_form_error) {
      const params = {};

      //Datos Presupuesto
      this.formFunctions.fillFormParams(this.formDeliverynote, this.data.entity, params)

      //Datos Líneas
      params['deliverynotelines']= [];
      this.detailComponent.pushDetailsToMaster(params['deliverynotelines']);

      if(params['deliverynotelines'].length > 0) {
        params[this.data.entity]['total'] = this.detailComponent.total_lineas
        params[this.data.entity]['total_price'] = this.detailComponent.total_price
        params[this.data.entity]['total_taxe'] = this.detailComponent.total_taxe
        params[this.data.entity]['total_cost'] = this.detailComponent.total_cost
      }

      console.log(params)

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint+'/'+this.data.id, JSON.stringify(params)).subscribe(
          resp=>{
            this.tab.modifiedData = false;
            this.formDeliverynote.markAsPristine();
            this.unsubscribeAll()
            this.getData()
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      )
    } else {
      this._buttons.setLoading(false);
    }


  }
}
