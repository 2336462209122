import { Type } from "@angular/core";
import { FirmFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";
import { Tab } from "src/app/main/tab-models/tab.model";
import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();


export class FirmModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'building',
    list: 'building'
  }

  setModelData(models:any) {

    models.firm.icon = this.icon;

    models.firm.form_tabData = {
      entity: models.firm.entity,
      saveEndpoint: models.firm.saveEndpoint,
      entity_permissions: models.firm.permissions,
      entity_label: models.firm.labels.form,
      new_register_dropdowns: ['tramit'],
      edit_register_dropdowns: ['tramit', 'typewhatsapptemplatecategory', 'typewhatsapptemplatestatus']
    }

    models.firm.list_tabData = {
      entity: models.firm.entity,
      entity_label: models.firm.labels.form,
      entity_permissions:models.firm.permissions,
      listParams: {
        list_entity: models.firm.list_entity,
        list_label: models.firm.labels.list,
          limit: 10,
          headers: [
            'Estado',
            'Nombre',
            'CIF',
            'E-mail',
            'Teléfono',
            'Móvil',
            'Web'
          ],
          fields: [
            {entity: models.firm.entity, field: 'enabled', type: 'check' },
            {entity: models.firm.entity, field: 'name'},
            {entity: models.firm.entity, field: 'identification_document'},
            {entity: models.firm.entity, field: 'email'},
            {entity: models.firm.entity, field: 'phone'},
            {entity: models.firm.entity, field: 'mobile'},
            {entity: models.firm.entity, field: 'url_web'},

          ],
          web_access: false,
          disable_user: false,
          filter_firm: false,
          filters: new FirmFilters(models)
      }
    }
  }

  getNewTab(component: Type<any>, entity_data): Tab {
    let tabData = {...entity_data.form_tabData};
    tabData.newRegister = true;
    tabData.noRecover = true;

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>Nueva "+titleCasePipe.transform(entity_data.labels.form),
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
      )
  }


  getFormTab(component: Type<any>, entity_data, register, header_field): Tab {
    let tabData = {...entity_data.form_tabData};

    tabData.id = register.id;
    tabData.map = true;

    if(register.firm_id) {
      tabData.firm_id = register.firm_id;
    }


    /**
     * Si el registro tiene campo number, es el que se muestra en la cabecera de la pestaña
     * Si no tiene campo number, tiene que tener campo headerData, para volver a generar la pestaña en la recarga
     */
    let header = null;
    if(register[header_field]) {
      header = register[header_field];
    } else if(register.headerData){
      header = register.headerData
    } else {
      throw new Error('Incorrect Tab Params')
    }

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>"+header,
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
    )
  }
}
