<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h5 class="modal-title w-100 d-flex justify-content-center" id="myModalLabel">{{number}}</h5>
</div>
<div class="modal-body p-0">

  <!-- <div class="container-fluid"> -->
    <div class="row">
      <div class="col">
        <mdb-card class="chat-room small-chat wide"
            id="myForm">
    <div class="my-custom-scrollbar grey lighten-3"
         style="height: 28rem; overflow-y:scroll;" #scrollChat>
      <mdb-card-body class="p-3">

          <app-loader *ngIf="loading"></app-loader>

        <div class="mb-1"
             style="clear: both"></div>
        <div class="chat-message">
          <ng-container *ngFor="let message of messages; let i = index">

            <mdb-card class="rounded w-75 z-depth-0 mb-2" [ngClass]="message.sent == 1 ? 'sent' : 'received'">
                      <mdb-card-body class="p-2">
                        <mdb-card-text class="black-text">

                          <ng-container [ngSwitch]="message.typewhatsappmessage_id">
                              <ng-container *ngSwitchCase="2">
                                <ng-container *ngIf="message.thumbImg">
                                  <a [download]="message.filename" [href]="message.thumbImg">
                                    <img class="img-thumbnail custom-img-height"
                                    [src]="message.thumbImg"
                                    alt=""
                                    >
                                  </a>

                                  <ng-container *ngIf="message.received">
                                    <ng-container *ngIf="!message.claim_document; else linked">
                                      <div class="small mt-2">
                                        <a (click)="linkToClaim(message)">
                                          <mdb-icon fad icon="link" size="lg" class="blue-text"></mdb-icon>
                                          Vincular a {{claim_entity.labels.form | titlecase}}
                                        </a>
                                      </div>
                                    </ng-container>
                                    <ng-template #linked>
                                      <div class="small font-weight-bold">
                                          <mdb-icon fad [icon]="claim_entity.icon.form" size="lg" class="blue-text"></mdb-icon>
                                          Vinculado a {{claim_entity.labels.form | titlecase}}
                                      </div>
                                    </ng-template>
                                  </ng-container>


                                </ng-container>

                              </ng-container>
                              <ng-container *ngSwitchCase="3">
                                <ng-container *ngIf="message.audio; else brokenaudio">
                                  <audio controls [title]="message.filename" [download]="message.filename">
                                    <source [src]="message.audio" [type]="message.mime">
                                    Your browser does not support the audio element.
                                  </audio>
                                </ng-container>
                                <ng-template #brokenaudio>
                                  <audio controls>
                                    <source [src]="message.audio">
                                    Your browser does not support the audio element.
                                  </audio>
                                </ng-template>
                                <ng-container *ngIf="message.received">
                                  <ng-container *ngIf="!message.claim_document; else linked">
                                    <div class="small mt-2">
                                      <a (click)="linkToClaim(message)">
                                        <mdb-icon fad icon="link" size="lg" class="blue-text"></mdb-icon>
                                        Vincular a {{claim_entity.labels.form | titlecase}}
                                      </a>
                                    </div>
                                  </ng-container>
                                  <ng-template #linked>
                                    <div class="small font-weight-bold">
                                        <mdb-icon fad [icon]="claim_entity.icon.form" size="lg" class="blue-text"></mdb-icon>
                                        Vinculado a {{claim_entity.labels.form | titlecase}}
                                    </div>
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngSwitchCase="4">
                                <div class="">
                                  <a [download]="message.filename" [href]="message.file">
                                    <mdb-icon fad icon="file-download" size="3x" class="blue-text"></mdb-icon>
                                  </a>
                                  <div class="small text-muted">
                                    {{ message.message }}
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngSwitchCase="5">
                                <span [innerHTML]="message.message">
                                </span>
                              </ng-container>
                              <ng-container *ngSwitchCase="7">
                                <ng-container *ngIf="message.video; else brokenvideo">
                                  <video width="320" height="240" controls>
                                    <source [src]="message.video" [type]="message.mime">
                                      Your browser does not support the video tag.
                                  </video>
                                </ng-container>
                                <ng-template #brokenvideo>
                                  <video width="320" height="240" controls>
                                    <source [src]="message.video" type="video/mp4">
                                      Your browser does not support the video tag.
                                  </video>
                                </ng-template>
                                <ng-container *ngIf="message.received">
                                  <ng-container *ngIf="!message.claim_document; else linked">
                                    <div class="small mt-2">
                                      <a (click)="linkToClaim(message)">
                                        <mdb-icon fad icon="link" size="lg" class="blue-text"></mdb-icon>
                                        Vincular a {{claim_entity.labels.form | titlecase}}
                                      </a>
                                    </div>
                                  </ng-container>
                                  <ng-template #linked>
                                    <div class="small font-weight-bold">
                                        <mdb-icon fad [icon]="claim_entity.icon.form" size="lg" class="blue-text"></mdb-icon>
                                        Vinculado a {{claim_entity.labels.form | titlecase}}
                                    </div>
                                  </ng-template>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngSwitchCase="8">
                                <ng-container *ngIf="message.thumbImg">
                                  <a [download]="message.filename" [href]="message.thumbImg">
                                    <img class="img-thumbnail custom-img-height"
                                    [src]="message.thumbImg"
                                    alt=""
                                    >
                                  </a>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngSwitchDefault>
                                {{ message.message }}
                              </ng-container>


                          </ng-container>


                          <div class="messagedate mt-1">
                            {{ message.date_message | date: 'dd/MM/yyyy' }} - {{ message.date_message | date: 'HH:mm'  }}


                            <ng-container *ngIf="message.sent == 1">
                              <!-- <div class="float-right"> -->
                                <mdb-icon fas icon="check" *ngIf="message.typewhatsappstatus_id == 1" class="grey-text"></mdb-icon>
                                <mdb-icon fas icon="check-double" *ngIf="message.typewhatsappstatus_id == 2" class="grey-text"></mdb-icon>
                                <mdb-icon fas icon="check-double" *ngIf="message.typewhatsappstatus_id == 3" class="blue-text"></mdb-icon>
                              <!-- </div> -->
                            </ng-container>
                          </div>
                        </mdb-card-text>
                      </mdb-card-body>
          </mdb-card>

        </ng-container>
        </div>
      </mdb-card-body>
    </div>
    <div class="card-footer">
        <ng-container *ngIf="template_only; else alloptions">

          <ng-container  *ngIf="show_template; else infomessage">

            <div class="row">
              <span class="small">Para seguir la conversación debe volver a enviar su plantilla</span>
            </div>
            <a (click)="sendTemplate('welcome')">
              <mdb-icon fad icon="file-alt" class="mr-1" size="" mdbTooltip="Enviar plantilla" placement="top"></mdb-icon>
              <span class="small">Enviar plantilla</span>
            </a>
            <!-- <div class="row">
              <span class="small">Para seguir la conversación debe enviar la plantilla de solicitud</span>
            </div>
            <ng-container *ngIf="has_solicitud_template; else no_solicitud_template">
              <a (click)="sendTemplate('solicitud')">
                <mdb-icon fad icon="file-alt" class="mr-1" size="" mdbTooltip="Enviar plantilla de Solicitud" placement="top"></mdb-icon>
                <span class="small">Enviar plantilla de Solicitud</span>
              </a>
            </ng-container>
            <ng-template #no_solicitud_template>
              <a (click)="openFirmTab()">
                <span class="small">Crear Plantilla Solicitud</span>
              </a>
            </ng-template> -->

          </ng-container>
          <ng-template #infomessage>
            <span class="small" *ngIf="!loading">
              No podrá enviar mensajes hasta que reciba respuesta a la plantilla
            </span>
          </ng-template>

          <a (click)="reload()">
            <mdb-icon fad icon="sync" class="float-right" mdbTooltip="Recargar Mensajes" placement="top"></mdb-icon>
          </a>
        </ng-container>
        <ng-template #alloptions>
          <ng-container *ngIf="files?.length == 0; else filessection">
          <div class="md-form input-group mb-3">
            <textarea type="text" id="message" class="md-textarea md-textarea-auto form-control"  [(ngModel)]="message"
            placeholder="Escriba aquí su mensaje..."
            aria-label="Escriba aquí su mensaje..."
            rows="1"
            aria-describedby="button-addon2" mdbInput></textarea>

              <div class="input-group-append">
                <button mdbBtn
                        color="blue"
                        size="sm"
                        class="m-0 px-3 py-2"
                        type="button"
                        id="button-addon2"
                        mdbWavesEffect
                        [disabled]="message == ''"
                        (click)="sendMessage()"
                        >
                        Enviar

                </button>
              </div>
            </div>
          </ng-container>

            <ng-template #filessection>

                <div class="row">
                  <ng-container *ngFor="let file of files">
                    <div class="chip" (click)="deleteFile(file.id)">
                      <mdb-icon fas icon="paperclip" class="mr-1"></mdb-icon>{{file.name}}
                    </div>
                  </ng-container>
                  <div class="mt-1" *ngIf="files?.length == 1">
                    <!-- <a (click)="sendFile()">
                      <mdb-icon fad icon="cloud-upload" class="ml-2 blue-text" size="lg" mdbTooltip="Enviar" placement="top"></mdb-icon>
                    </a> -->
                    <button mdbBtn
                            color="blue"
                            size="sm"
                            class="m-0 px-3 py-2"
                            type="button"
                            id="button-addon2"
                            mdbWavesEffect
                            (click)="sendFile()"
                            >
                            Enviar

                    </button>
                  </div>
                </div>
            </ng-template>
          <div class="mt-3">
            <a (click)="openFileSelect('image')">
              <mdb-icon fad icon="camera" class="mr-2" mdbTooltip="Enviar fotos" placement="top"></mdb-icon>
            </a>
            <a (click)="openFileSelect('video')">
              <mdb-icon fad icon="video-plus" class="mr-2" mdbTooltip="Enviar video" placement="top"></mdb-icon>
            </a>
            <a (click)="openFileSelect('audio')">
              <mdb-icon fad icon="headphones" class="mr-2" mdbTooltip="Enviar audio" placement="top"></mdb-icon>
            </a>
            <a (click)="openFileSelect('document')">
              <mdb-icon fad icon="file" class="mr-2" mdbTooltip="Enviar documento" placement="top"></mdb-icon>
            </a>
            <a (click)="reload()">
              <mdb-icon fad icon="sync" class="float-right" mdbTooltip="Recargar Mensajes" placement="top"></mdb-icon>
            </a>
          </div>
          <div class="mt-3">
            <div class="file-field d-flex justify-content-center">
              <input type="file" mdbFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" #fButton
                [accept]="filetypes" readonly>
            </div><!--
            <div class="row">
              <ng-container *ngFor="let file of files">
                <div class="chip" (click)="deleteFile(file.id)">
                  {{file.name}}
                </div>
              </ng-container>
              <div class="mt-1" *ngIf="files?.length == 1">
                <a (click)="sendFile()">
                  <mdb-icon fad icon="cloud-upload" class="ml-2 blue-text" size="lg" mdbTooltip="Enviar" placement="top"></mdb-icon>
                </a>
              </div>
            </div> -->
          </div>
        </ng-template>

    </div>
  </mdb-card>
  </div>
  </div>
  <!-- </div> -->

</div>
