import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { ProviderInterface } from 'src/app/shared/interfaces/data/provider-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { DropdownService } from '../../services/dropdown.service';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss']
})
export class NewCompanyComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data;

  public loading: boolean = false;
  public formCompany: FormGroup;
  //public formProtocol: FormGroup;
  public addressForm: FormGroup;

  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;


  public dropdowns: DropdownsInterface;
  public providers: Array<ProviderInterface>;
  public protocols: Array<any> = [{id: -1, name: 'Sin asignar'}];
  public access_type: Array<any>;
  public mailaccounts: Array<any>;

  modalRef: MDBModalRef | null = null;
  modalRefAddress: MDBModalRef | null = null;
  constructor(private modalService: MDBModalService, private _tabs: TabService, private _api: ApirequestService, private fb: FormBuilder, private _dropdowns: DropdownService, private _buttons: ActionButtonsService, private _notification: NotificationService){

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.createForm();
    this.getDropdowns()
  }

  createForm() {
    this.formCompany = this.fb.group({
      provider: this.fb.group({
        cif: [null],
        name: [null, [Validators.required]],
        email: [null],
        phone: [null],
        mobile: [null],
      }),
      is_subcompany:[null],
      provider_id: [{value: null, disabled: true}],
    });



    this.subscriptions.push(
      this.formCompany.controls.is_subcompany.valueChanges.subscribe(
        value => {
          if(value) {
            this.formCompany.controls.provider_id.enable();
            this.formCompany.controls.provider_id.setValidators(Validators.required);
          } else {
            this.formCompany.controls.provider_id.clearValidators();
            this.formCompany.controls.provider_id.disable();
          }

          this.formCompany.controls.provider_id.reset(null)
          this.formCompany.controls.provider_id.updateValueAndValidity()
        }
      )
    )

    /* this.formProtocol = this.fb.group({
      typeprotocol_id:[-1],
      typeprotocolaccess_id:[{value: null, disabled: true}, [Validators.required]],
      access_data: this.fb.group({
        mailaccount_id:[{value: null, disabled: true}, [Validators.required]],
        user:[{value: null, disabled: true}, [Validators.required]],
        password:[{value: null, disabled: true}, [Validators.required]],
        //token:[{value: null, disabled: true}],
        code_provider:[{value: null, disabled: true}],
      })
    }) */

 /*    this.subscriptions.push(
      this.formProtocol.controls.typeprotocol_id.valueChanges.subscribe(
        value => {
          if(value > -1) {
            this.formProtocol.controls.typeprotocolaccess_id.enable();
          } else {
            this.formProtocol.controls.typeprotocolaccess_id.setValue(null);
            this.formProtocol.controls.typeprotocolaccess_id.disable();
          }
        }
      )
    );
    this.subscriptions.push(
      this.formProtocol.controls.typeprotocolaccess_id.valueChanges.subscribe(
        value => {
          let access_data_form: FormGroup = this.formProtocol.controls.access_data as FormGroup;
          access_data_form.reset();
          access_data_form.markAsPristine();
          access_data_form.disable();

          switch (value) {
            case 1:
              access_data_form.controls.user.setValidators(Validators.required)
              access_data_form.controls.password.setValidators(Validators.required)
              access_data_form.controls.user.enable();
              access_data_form.controls.password.enable();
              access_data_form.controls.code_provider.enable();
              break;
            case 2:
              access_data_form.controls.mailaccount_id.setValidators(Validators.required)
              access_data_form.controls.mailaccount_id.enable();
              access_data_form.controls.code_provider.enable();
              break;
            case 3:
              access_data_form.controls.user.setValidators(Validators.required)
              access_data_form.controls.password.setValidators(Validators.required)
              access_data_form.controls.user.enable();
              access_data_form.controls.password.enable();
              access_data_form.controls.code_provider.enable();
              break;
            case 4:
              access_data_form.controls.user.clearValidators()
              access_data_form.controls.password.clearValidators()
              access_data_form.controls.mailaccount_id.clearValidators();

              access_data_form.controls.user.enable();
              access_data_form.controls.password.enable();
              access_data_form.controls.mailaccount_id.enable();
              access_data_form.controls.code_provider.enable();
              break;

            default:

              break;
          }
        }
      )
    ); */

    /* this.subscriptions.push(
      this.formProtocol.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    ); */


    this.subscriptions.push(
      this.formCompany.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    );


    this.addressForm = this.fb.group({
      address:[''],
      postalcode: this.fb.group({
        province_id:[null],
        province:[{value:null, disabled: false}],
        population_id:[null],
        population:[{value:null, disabled: false}],
        description:[{value:null, disabled: false}],
        id:[null]
      })
    });

    this.subscriptions.push(
      this.addressForm.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    );
  };

  /* get typeprotocolaccess(): number {
    return this.formProtocol.controls.typeprotocolaccess_id.value;
  } */

  getDropdowns() {
    this.loading = true;

    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          this.dropdowns = this.utils.objectDeepCopy(response.response.dropdown);

          this.providers = this.dropdowns.company;
          this.protocols = [...this.protocols,...this.dropdowns.protocol];
          this.access_type = this.dropdowns.protocolaccess;
          this.mailaccounts = this.dropdowns.mailaccount;
          console.log(response.response)
          this.loading = false;
        },
        error => {
          this.formCompany.disable();
          //this.formProtocol.disable();
          this.addressForm.disable();
          this.loading = false;
        }
      )
    );
  }

  selectLocation(){
    this.modalRefAddress = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccionar Población',
              list_headers: [
                'CP',
                'Población',
                'Provincia',
              ],
              list_fields: [
                { entity: 'postalcode', field: 'description'},
                { entity: 'postalcode', field: 'population' },
                { entity: 'postalcode', field: 'province' },
              ],
              list_entity: 'postalcode',
              list_endpoint: 'postalcodes',
            }
          })

      this.subscriptions.push(
        this.modalRefAddress.content.selectedRegister.subscribe(register => {
          if(register) {
            this.formFunctions.rellenarFormulario(this.addressForm.controls.postalcode, register);
            this.addressForm.markAsDirty();
          }
        })
      );

      this.modalService.close.subscribe(() => this.modalRefAddress.content.onClosed())
  }

  onSubmit(){
    this.formCompany.markAllAsTouched()
    //this.formProtocol.markAllAsTouched()

    if(this.formCompany.valid){
      this._buttons.setLoading(true);
      const params: {provider?: any | null, providersubprovider?: any|null,provideraddress?: any | null, protocolaccess?: any | null} = {};
      this.formFunctions.fillFormParams((this.formCompany.controls.provider as FormGroup), this.data.entity, params);
      params.provider.typeprovider_id = this.data.entity_type;

      if(this.formCompany.controls.is_subcompany.value) {
        params.providersubprovider = {
          provider_id: this.formCompany.controls.provider_id.value
        };
      }

      if(this.addressForm.dirty) {
        let addressValues = this.addressForm.value;

        params.provideraddress = {
          address: addressValues.address,
          postalcode_id: addressValues.postalcode.id,
          province_id: addressValues.postalcode.province_id,
          population_id: addressValues.postalcode.population_id,
        }
      }

      /* if(this.formProtocol.controls.access_data.dirty) {
        this.formFunctions.fillFormParams((this.formProtocol.controls.access_data as FormGroup), 'protocolaccess', params);

        params.protocolaccess.typeprotocol_id = this.formProtocol.controls.typeprotocol_id.value;
        params.protocolaccess.typeprotocolaccess_id = this.formProtocol.controls.typeprotocolaccess_id.value;
      } */

      console.log(params)
      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {
            let provider = response.response.provider
            this._buttons.reloadListTab(this.data.saveEndpoint, this.data.entity_type);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
            this.modalRef = this.modalService.show(OpenRegisterComponent, {
                    keyboard: false,
                    ignoreBackdropClick: true,
                    class: 'modal-dialog',
                    data: {
                      heading: 'Registro Creado',
                      text: 'Registro creado correctamente, ¿desea acceder al registro?',
                      register: provider,
                      form_entity: this.data.entity,
                      form_entity_type: this.data.entity_type,
                    }
                  })

            this._tabs.removeTabByTabId(this.tab.tabId);
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      );

    }
  }

}
