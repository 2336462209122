import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { matchValueToArray } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { ClientInterface } from 'src/app/shared/interfaces/data/client-interface';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { FirmsService } from '../../services/firms.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { UserPermissionsService } from '../../services/user-permissions.service';
import { Tab } from '../../tab-models/tab.model';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config.service';
import { MapGeocoder } from '@angular/google-maps';
import { GmapsComponentComponent } from '../../components/gmaps-component/gmaps-component.component';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;
  @ViewChild('maps', {static: false}) maps: GmapsComponentComponent;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  private client: ClientInterface;

  public loading: boolean = false;

  public formClient: FormGroup;
  public changedWebAccesValue: boolean;
  private firms: Array<number>;

  public id: number;
  private subscriptions: Array<Subscription> = [];

  public heading = "<i class='fas fa-info-circle'></i> Datos generales";

  public addresses: Array<any>;
  public contacts: Array<any>;
  public policies: Array<any>;
  public documents: Array<any>;


  public dropdowndata: DropdownsInterface;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  /**
   * Contructor de la clase
   * @param _appconfig
   * @param _mapGeocoder
   * @param _api
   * @param _lock
   * @param _apifunctions
   * @param _buttons
   * @param _notification
   * @param _firms
   * @param _userpermissions
   */
  constructor(private _dropdowns: DropdownService, private _api: ApirequestService, private _lock: LockService, private _apifunctions: ApiFunctions,private _buttons: ActionButtonsService, private _notification: NotificationService, private _firms: FirmsService, private _userpermissions: UserPermissionsService)
  {
    this.formClient = this.formFunctions.getForm('client');
  }

  /**
   * funcion para pasar las direcciones al mapa y marcar la posicion predeterminada
   * @param addresses
   *
   */
  public LoadAddressesOnMap(addresses)
  {
    this.maps.MarkerDefaultPositionInMap(addresses);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());

    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void {
    //Cogemos todas las firms y filtramos por las que tienen id > 0 y luego mapeamos para obtener el id únicamente
    this.firms = this._firms.getFirms().filter(emp => emp.id > 0).map(emp => emp.id);


    this.id = this.data.id;
    this.getData();
  }

  private getData()
  {
    this.loading = true;
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty('client')).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let dropdowns = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    dropdowns.user = dropdowns.user.filter(u => u.email !== this.client.email);
    this.dropdowndata = dropdowns;

  }


  private checkLock(apiresponse: any): void
  {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.client = apiresponse.client;
    this.addresses = this.client.clientaddress;
    this.contacts = this.client.contacts;
    this.policies = this.client.policies;
    this.documents = this.client.documents;

    this.tab.tabData.headerData = this.client.name;

    if(this.addresses?.length > 0)
    {
      let defaultAddressModel = this.addresses.find(key => key.default == true);
      if(defaultAddressModel.address_complete != '')
      {
        this.LoadAddressesOnMap(this.addresses);
      }
    }

    if (this.client?.surname) {
      this.tab.tabData.headerData += ' ' + this.client.surname;
    }

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal()
  {
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formClient = undefined;
          setTimeout(() => {
            this.formClient = this.formFunctions.getForm('client');
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean)
  {
    if(block){
      this.formFunctions.rellenarFormulario(this.formClient, this.client);
      this.formClient.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formClient, this.client);

      if(this.formClient.controls.web_access.value == true){
        this.formClient.controls.email.disable();
      }

      this.subscriptions.push(
        this.formClient.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }


  changeEnabled(evt: MdbCheckboxChange) {
    if (evt.checked) {
      this.formClient.controls.web_access.enable();
    } else {
      this.formClient.controls.web_access.setValue(0);

      this.formClient.controls.surname.clearValidators()
      this.formClient.controls.email.removeValidators([Validators.required, matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))]);
      this.formClient.controls.surname.updateValueAndValidity();
      this.formClient.controls.email.updateValueAndValidity();

    }

  }

  changeWebAccess(evt: MdbCheckboxChange) {
    // si enabled es false no dejar que web_access se pueda poner a true
    if (this.formClient.controls.enabled.value == 0) {
      this.formClient.controls.web_access.setValue(0);
    }

    if (this.formClient.controls.web_access.value == 1) {
      this.formClient.controls.surname.addValidators(Validators.required);
      this.formClient.controls.email.addValidators([Validators.required, Validators.email, matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))]);
    } else {
      this.formClient.controls.surname.clearValidators();
      this.formClient.controls.email.clearValidators();
    }

    this.formClient.controls.surname.updateValueAndValidity();
    this.formClient.controls.email.updateValueAndValidity();
  }



  public onSubmit(): void {

    this.formClient.markAllAsTouched();

    if (this.formClient.valid) {
      this._buttons.setLoading(true);
      const params = {};

      if (this.client.web_access != this.formClient.controls.web_access.value) {
        params['access_pgaweb'] = {};
        params['access_pgaweb']['web_access'] = this.formClient.controls.web_access.value;
        params['access_pgaweb']['name'] = this.formClient.controls.name.value;
        params['access_pgaweb']['surname'] = this.formClient.controls.surname.value;

        if (this.client.web_access == true) {
          params['access_pgaweb']['email'] = this.client.email;
        } else {
          params['access_pgaweb']['email'] = this.formClient.controls.email.value;
        }

        params['access_pgaweb']['id_firms'] = this.firms;
      }

      params[this.data.entity] = {};

      //Datos cliente
      Object.keys(this.formClient.controls).forEach(
        control => {
          params[this.data.entity][control] = this.formClient.controls[control].value;
        }
      );
      console.log(JSON.stringify(params));

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          resp => {
            this.tab.modifiedData = false;
            this._buttons.reloadListTab(this.data.saveEndpoint);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }
}
