import { ComunicationsProtocolsComponent } from "src/app/main/comunications-protocols/comunications-protocols.component";
import { EntityBaseModel } from "./entity-base.model";
import { Tab } from "src/app/main/tab-models/tab.model";
import { TitleCasePipe } from "@angular/common";

const titleCasePipe = new TitleCasePipe();


export class ComunicationProtocolModel extends EntityBaseModel {
  protected readonly icon = {
    form: '',
    list: 'broadcast-tower'
  }

  setModelData(models:any) {

    models.comunicationprotocol.icon = this.icon;

    models.comunicationprotocol.modules =  ['mail', 'companies'];


    models.comunicationprotocol.list_tabData = {
      entity: models.comunicationprotocol.entity,
      entity_label: models.comunicationprotocol.labels.form,
      entity_permissions:models.comunicationprotocol.permissions,
      entity_modules:models.comunicationprotocol.modules,
      saveEndpoint: models.comunicationprotocol.saveEndpoint,
      listParams: {
        list_entity: models.comunicationprotocol.list_entity,
        list_label: models.comunicationprotocol.labels.list,
        limit: 10
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      ComunicationsProtocolsComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: false,
        buttonDelete: false,
        buttonSearch: false
      },
      tabData
      )
  }
}
