import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { SerieFormComponent } from "src/app/main/components/dynamic-modals/serie-form/serie-form.component";
import { SeriesComponent } from "src/app/main/lists/series/series.component";
import { Tab } from "src/app/main/tab-models/tab.model";

const titleCasePipe = new TitleCasePipe();

export class SerieModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'sort-numeric-down',
    list: 'sort-numeric-down'
  }

  setModelData(models:any) {

    models.serie.icon = this.icon;

    models.serie.list_tabData = {
      entity: models.serie.entity,
      entity_label: models.serie.labels.form,
      entity_permissions:models.serie.permissions,
      saveEndpoint: models.serie.saveEndpoint,
      modal_form: SerieFormComponent,
      modal_class: 'modal-dialog',
      listParams: {
        list_entity: models.serie.list_entity,
        list_label: models.serie.labels.list,
        limit: 10,
        headers: [
          'Predeterminada',
          'Descripción',
          'Desc. Extendida'
        ],
        fields: [
          { entity: models.serie.entity, field: 'default', type: 'check' },
          { entity: models.serie.entity, field: 'description' },
          { entity: models.serie.entity, field: 'extend_description' },
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: null,
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      SeriesComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: false
      },
      tabData
      )
  }
}
