import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
	public userAgent: string;
  private validate: string;

  constructor(private http: HttpClient, private _api: ApirequestService) {
	this.userAgent = window.navigator.userAgent;
  }

  setValidate(value:string) {
    this.validate = value;
  }

  getValidate(): string{
    return this.validate;
  }

  getDocument(url: string, validate: string, filename:string, mime?: string):Observable<any> {
	  let amime = '*/*';
		if(mime) {
		  amime = mime;
		}
    let headers = new HttpHeaders({
      'Content-Type': mime,
      'Authorization' : `Basic ${validate}`,
      'Accept': amime
    })

   return this.http.get(this._api.getStorageRoute()+url, { headers:headers, observe: 'response', responseType: 'blob'})
  }

  getXMLDocument(url: string, validate: string, filename:string):Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'text/xml',
      'Authorization' : `Basic ${validate}`
    })

   return this.http.get(this._api.getStorageRoute()+url, { headers:headers, observe: 'response', responseType: 'text'})
  }

  downloadDocument(url: string, validate: string, filename: string, mime: string):void {
    this.getDocument(url, validate, filename, mime).subscribe(
      d=>{
        const enlace = document.createElement('a');
        const objectUrl = URL.createObjectURL(d.body)
        enlace.setAttribute('href', objectUrl);
        enlace.setAttribute('target', '_blank');
        enlace.setAttribute('download', filename);


        enlace.style.display = 'none';
        document.body.appendChild(enlace);

        enlace.click();
        URL.revokeObjectURL(objectUrl);
      },
      e => {
        console.log(e)
      }
     );
  }

 getThumbnails(documents: Array<any>, messages?: boolean){
    documents?.forEach(
      register => {
        let document = register;

        if(messages) {
          if(register.document) {
            document = register.document;
          } else {
            document = null;
          }
        }
        if(document) {
          if(document.mime.includes('image')) {
            this.getDocument(document.path_relative_thumbnail, document.validate, document.name,document.mime).subscribe(
              resp => {
                let reader = new FileReader();
                  reader.readAsDataURL(resp.body);
                  reader.onloadend = function () {
                      let base64data = reader.result;
                      document.thumbImg = base64data
                  }

              },
              err => {
                document.thumbImg = "assets/images/imageError.png";
              });
          }
        }

      }
    )

  }

  imgToBase64(body, callback?) {
    return new Promise(function(resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(body);
      reader.onloadend = function () {
          let base64data = reader.result;
          resolve(base64data)
      }

      reader.onerror = reject;
    });
  }

  getImageScale(img): Promise<number>{
    return new Promise ((resolved, rejected) => {
      var image = new Image();
      image.onload = (e) => {
          let scale = image.width / image.height
          resolved(scale)
      };
      image.src = img;
    });
  }

}
