<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <div style="margin: 10px;">
    <form [formGroup]="formAdministrator">
      <div class="row mt-2">
        <div class="col-lg-2">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="cif" formControlName="cif" class="form-control form-control-sm" placeholder="">
            <label for="cif" class="">CIF</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="administratorname" formControlName="name" class="form-control form-control-sm" placeholder="">
            <label for="administratorname" class="">Nombre</label>
            <mdb-error *ngIf="formAdministrator.controls.provider['controls'].name.invalid && (formAdministrator.controls.provider['controls'].name.dirty || formAdministrator.controls.provider['controls'].name.touched)">Campo obligatorio</mdb-error>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="email" formControlName="email" class="form-control form-control-sm" placeholder="">
            <label for="email" class="">Email</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="phone"formControlName="phone" class="form-control form-control-sm" placeholder="">
            <label for="phone" class="">Teléfono</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline" formGroupName="provider">
            <input mdbInput type="text" id="mobile" formControlName="mobile" class="form-control form-control-sm" placeholder="">
            <label for="mobile" class="">Móvil</label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!--DIRECCIONES-->
  <div class="divCustomSection">
    <i class='fas fa-home-alt mr-2'></i> Dirección completa
  </div>
  <div style="margin: 10px;">
    <form [formGroup]="addressForm">
      <div class="row mt-3">
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="administratorddress"formControlName="address" class="form-control form-control-sm" placeholder="">
            <label for="administratorddress" class="">Dirección</label>
          </div>
        </div>

        <div class="col-lg" formGroupName="postalcode">
          <div class="row">
            <div class="col">
              <div class="md-form md-outline">
                <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                  <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                </a>
                <input mdbInput type="text" id="administratorpostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                <label for="administratorpostal_code" class="">Código postal</label>
              </div>
            </div>
            <div class="col-md">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="administratorpopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                <label for="administratorpopulation" class="">Población</label>
              </div>
            </div>
            <div class="col-md">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="administratorprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                <label for="administratorprovince" class="">Provincia</label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>

<!--
<div class="container-fluid pt-2">
  <form [formGroup]="formAdministrator">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'<i class=\'fas fa-info-circle\'></i> Datos Nuevo '+data.entity_label">
            <div class="row">
              <div class="col-lg-2">
                  <div class="md-form" formGroupName="provider">
                      <input mdbInput type="text" id="cif"
                          formControlName="cif" class="form-control form-control-sm" placeholder="">
                      <label for="cif" class="">CIF</label>
                  </div>
              </div>
              <div class="col-lg">
                  <div class="md-form" formGroupName="provider">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="administratorname"
                          formControlName="name" class="form-control form-control-sm" placeholder="">
                      <label for="administratorname" class="">Nombre</label>
                      <mdb-error
                          *ngIf="formAdministrator.controls.provider['controls'].name.invalid && (formAdministrator.controls.provider['controls'].name.dirty || formAdministrator.controls.provider['controls'].name.touched)">
                          Campo obligatorio</mdb-error>
                  </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md">
                <div class="md-form" formGroupName="provider">
                  <input mdbInput type="text" id="email"
                      formControlName="email" class="form-control form-control-sm" placeholder="">
                  <label for="email" class="">Email</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form" formGroupName="provider">
                  <input mdbInput type="text" id="phone"
                      formControlName="phone" class="form-control form-control-sm" placeholder="">
                  <label for="phone" class="">Teléfono</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form" formGroupName="provider">
                  <input mdbInput type="text" id="mobile"
                      formControlName="mobile" class="form-control form-control-sm" placeholder="">
                  <label for="mobile" class="">Móvil</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>

  </form>
    <div class="row mt-3">
      <div class="col-md custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="<i class='fas fa-home-alt'></i> Dirección">
            <form [formGroup]="addressForm">
              <div class="row mt-3">
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="administratorddress"formControlName="address" class="form-control form-control-sm" placeholder="">
                    <label for="administratorddress" class="">Dirección</label>
                  </div>
                </div>

              </div>
              <div class="row mt-2" formGroupName="postalcode">
                <!-- <div class="col-1">

                </div> -->
 <!--               <div class="col-md-2">
                  <div class="md-form">
                    <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                      <mdb-icon fas icon="hand-point-up"></mdb-icon>
                    </a>
                    <input mdbInput type="text" id="administratorpostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="administratorpostal_code" class="">Código postal</label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="administratorpopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="administratorpopulation" class="">Población</label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="administratorprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="administratorprovince" class="">Provincia</label>
                  </div>
                </div>
              </div>
            </form>
          </mdb-tab>
          </mdb-tabset>
      </div>
    </div>
</div>
-->