<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" *ngIf="emit_unblock">
    <span aria-hidden="true">×</span>
  </button>
  <h5 class="modal-title w-100 d-flex justify-content-center" id="myModalLabel"><mdb-icon fas icon="unlock" class="mt-1 mr-1"></mdb-icon>Desbloquear</h5>
</div>
<div class="modal-body">
  <ng-container *ngIf="!unblocking; else unblockingtext">

    <ng-container *ngIf="!error_unblock; else showErrorBlock">
      <p class="text-center">
        {{text}}
      </p>
    </ng-container>
    <ng-template #showErrorBlock>
      <p class="text-center">
        Error al desbloquear el registro, pruebe a volver a abrirlo
      </p>
    </ng-template>
  </ng-container>
  <ng-template #unblockingtext>
    <p class="text-center">
      Desbloqueando registro
    </p>
  </ng-template>

</div>
<div class="modal-footer text-center">
  <ng-container *ngIf="!unblocking; else wait">
    <ng-container *ngIf="!error_unblock; else closeTabButton">
      <button mdbBtn type="button" size="sm" color="default" mdbWavesEffect (click)="unblock()">
        Sí, Desbloquear
      </button>
      <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="block()">
        No
      </button>
    </ng-container>
    <ng-template #closeTabButton>
      <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="closeTab()">
        Cerrar Pestaña
      </button>
    </ng-template>
  </ng-container>
  <ng-template #wait>
    <mdb-progress-bar
    class="progress primary-color-dark"
    mode="indeterminate"
  ></mdb-progress-bar>
  </ng-template>
</div>
