
<app-loader *ngIf="loading"></app-loader>
<div class="modal-header justify-content-center">
  <h4 class="modal-title" id="myModalLabel">{{header | titlecase}}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="formTaxe">
    <div class="row">
      <div class="col-2">
        <mdb-checkbox formControlName="enabled" [default]="true">Activo</mdb-checkbox>
      </div>
      <div class="col-3">
        <mdb-checkbox formControlName="default" [default]="true">Predeterminado</mdb-checkbox>
      </div>
      <div class="col">
        <div class="md-form md-outline">
          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="taxename" formControlName="name" placeholder="" class="form-control form-control-sm">
          <label for="taxename">Nombre</label>
          <mdb-error *ngIf="formTaxe.controls.name.invalid && (formTaxe.controls.name.dirty || formTaxe.controls.name.touched)">
            Campo obligatorio
          </mdb-error>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" mdbValidate [validateSuccess]="false" placeholder="--Seleccionar--" label="Impuesto" formControlName="type_taxe_id">
            <mdb-select-option *ngFor="let typetaxe of typetaxes" [value]="typetaxe.id">{{typetaxe.name}}
            </mdb-select-option>
            </mdb-select-2>
            <mdb-error
                *ngIf="formTaxe.controls.type_taxe_id.invalid &&  (formTaxe.controls.type_taxe_id.dirty || formTaxe.controls.type_taxe_id.touched)">
              Campo obligatorio
            </mdb-error>
          </div>
      </div>
      <div class="col">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" mdbValidate [validateSuccess]="false" placeholder="--Seleccionar--" label="Tipo" formControlName="type_taxe_model_id">
            <mdb-select-option *ngFor="let typetaxemodel of typetaxesmodels" [value]="typetaxemodel.id">{{typetaxemodel.name}}
            </mdb-select-option>
            </mdb-select-2>
            <mdb-error
                *ngIf="formTaxe.controls.type_taxe_model_id.invalid &&  (formTaxe.controls.type_taxe_model_id.dirty || formTaxe.controls.type_taxe_model_id.touched)">
              Campo obligatorio
            </mdb-error>
        </div>
      </div>
      <div class="col-3">
        <div class="md-form md-outline input-group mb-4">
          <input mdbInput mdbValidate [validateSuccess]="false" type="number" id="taxevalue" formControlName="value" placeholder="" class="form-control form-control-sm text-right">
          <label for="taxevalue">Cantidad</label>
          <mdb-error *ngIf="formTaxe.controls.value.invalid && (formTaxe.controls.value.dirty || formTaxe.controls.value.touched)">
            Campo obligatorio
          </mdb-error>
          <div class="input-group-append">
            <span class="input-group-text md-addon" id="material-addon2">%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">


    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="md-form">
          <mdb-date-picker mdbValidate [validateSuccess]="false" name="taxestartdate" [inline]="true" [outlineInput]="true" [options]="datepicker_options" formControlName="use_start_date" [label]="'Desde'"></mdb-date-picker>
          <mdb-error *ngIf="formTaxe.controls.use_start_date.invalid && (formTaxe.controls.use_start_date.dirty || formTaxe.controls.use_start_date.touched)">
            Campo obligatorio
          </mdb-error>

        </div>
      </div>
      <div class="col-md-6">
        <div class="md-form">
          <mdb-date-picker mdbValidate [validateSuccess]="false" name="taxeenddate" [inline]="true" [outlineInput]="true" [options]="datepicker_options" formControlName="use_end_date" [label]="'Hasta'"></mdb-date-picker>
          <mdb-error *ngIf="formTaxe.controls.use_end_date.invalid && (formTaxe.controls.use_end_date.dirty || formTaxe.controls.use_end_date.touched)">
            Campo obligatorio
          </mdb-error>
        </div>

      </div>
    </div>
  </form>
</div>
<div class="modal-footer text-center">
  <button mdbBtn type="button" size="sm" color="default" mdbWavesEffect (click)="submit()">
    <mdb-icon fas icon="check"></mdb-icon>
  </button>
  <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="modalRef.hide()">
    <mdb-icon fas icon="undo"></mdb-icon>
  </button>
</div>
