<app-loader *ngIf="loading"></app-loader>
<div class="container-fluid ">
  <div class="row">
    <div class="col-12 claim-header">
      <mdb-card>
        <mdb-card-header class="grey lighten-3 small">
          <div class="row">
            <div class="col-2">
              <span class="text-muted">{{data.entity_label | titlecase}}: </span> {{claim?.number}} <br>
              <ng-container *ngIf="claim?.provider">
                <span class="text-muted">Aviso: </span> {{claim?.number_provider}}
              </ng-container>
            </div>
            <div class="col-3">
              <span class="text-muted">Proveedor: </span>
              <ng-container *ngIf="claim?.provider; else particular">
                {{claim.provider.name}} <span *ngIf="claim.subprovider">({{claim.subprovider.name}})</span> <br>
                <span class="text-muted">{{client_entity.labels.form | titlecase}}: </span>{{claim?.client.name_complete}}
              </ng-container>
              <ng-template #particular>
                Particular
              </ng-template>
            </div>
           
            <div class="col-2">
              <span class="text-muted">Estado: </span>
              {{claim?.typecondition?.name}} <br>
              <span class="text-muted">Fecha: </span> {{claim?.order_date | date: 'dd/MM/yyyy'}}
            </div>
            <div class="col">
              <a [mdbTooltip]="data.entity_label | titlecase" placement="bottom" class="header-icons mx-1" (click)="selectSection('info')">
                <mdb-icon fad icon="info-circle" size="2x" [ngClass]="{'blue-text': selected_section.section === 'info'}"></mdb-icon>
                <span class="small text-muted d-block">{{data.entity_label | titlecase}}</span>
              </a>
              <ng-template #showless>
                <a [mdbTooltip]="workorder_entity.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(workorder_entity.list_entity)">
                  <mdb-icon fad [icon]="workorder_entity.icon.list" size="2x" [ngClass]="{'blue-text': selected_section.section === workorder_entity.list_entity}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.workorders_count > 0">{{claim.workorders_count}}</span>
                  <span class="small text-muted d-block">{{workorder_entity.labels.list | titlecase}}</span>
                </a>
                <a [mdbTooltip]="invoice.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(invoice.list_entity)">
                  <mdb-icon fad [icon]="invoice.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === invoice.list_entity}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.invoices_count > 0">{{claim.invoices_count}}</span>
                  <span class="small text-muted d-block">{{invoice.labels.list | titlecase}}</span>
                </a>
                <a [mdbTooltip]="budget_entity.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(budget_entity.list_entity)">
                  <mdb-icon fad [icon]="budget_entity.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === budget_entity.list_entity}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.budgets_count>0">{{claim.budgets_count}}</span>
                  <span class="small text-muted d-block">{{ (budget_entity.labels.list.length > 6 ) ? (budget_entity.labels.list | slice:0:6 | titlecase)+'.':(budget_entity.labels.list | titlecase) }}</span>
                </a>
                <a [mdbTooltip]="deliverynote_entity.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(deliverynote_entity.list_entity)">
                  <mdb-icon fad [icon]="deliverynote_entity.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === deliverynote_entity.list_entity}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.deliverynotes_count>0">{{claim.deliverynotes_count}}</span>
                  <span class="small text-muted d-block">{{deliverynote_entity.labels.list | titlecase}}</span>
                </a>
                <a [mdbTooltip]="invoiceproforma.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(invoiceproforma.list_entity)">
                  <mdb-icon fad [icon]="invoiceproforma.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === invoiceproforma.list_entity}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.invoicesproforma_count > 0">{{claim.invoicesproforma_count}}</span>
                  <span class="small text-muted d-block">{{invoiceproforma.labels.form | titlecase}}</span>
                </a>
                <a [mdbTooltip]="action_entity.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(action_entity.list_entity)">
                  <mdb-icon fad [icon]="action_entity.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === action_entity.list_entity}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.actions_count > 0">{{claim.actions_count}}</span>
                  <span class="small text-muted d-block">{{action_entity.labels.list | titlecase}}</span>
                </a>
                <a mdbTooltip="Documentos" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection('documents')">
                  <mdb-icon fad icon="file-pdf" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'documents'}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.documents_count > 0">{{claim.documents_count}}</span>
                  <span class="small text-muted d-block">Documentos</span>
                </a>
                <a mdbTooltip="Multimedia" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection('multimedia')">
                  <mdb-icon fad icon="photo-video" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'multimedia'}"></mdb-icon>
                  <span class="counter success-color" *ngIf="claim?.multimedia_count > 0">{{claim.multimedia_count}}</span>
                  <span class="small text-muted d-block">Multimedia</span>
                </a>
                <a mdbTooltip="Emails" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection('claimemails')">
                  <mdb-icon fad icon="at" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'claimemails'}"></mdb-icon>
                  <span class="small text-muted d-block">Emails</span>
                </a>
                <a mdbTooltip="Comunicaciones Protocolo" placement="bottom" class="header-icons mx-1 animated slideInRight" *ngIf="claim?.typeprotocol_id" (click)="selectSection('claimprotocol')">
                  <mdb-icon fad icon="broadcast-tower" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'claimprotocol'}"></mdb-icon>
                  <span class="small text-muted d-block">Prot.</span>
                </a>
                <ng-container *ngIf="currentWindowWidth > 1520">
                  <a  [mdbTooltip]="task_entity.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(task_entity.list_entity)">
                    <mdb-icon fad [icon]="task_entity.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === task_entity.list_entity}"></mdb-icon>
                    <span class="counter success-color" *ngIf="claim?.tasks_count > 0">{{claim.tasks_count}}</span>
                    <span class="small text-muted d-block">{{task_entity.labels.list | titlecase}}</span>
                  </a>
                  <a mdbTooltip="Terceros" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection('thirds')">
                    <mdb-icon fad icon="user-injured" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'thirds'}"></mdb-icon>
                    <span class="small text-muted d-block">Terceros</span>
                  </a>
                </ng-container>
                <a (click)="showmore = true" class="header-icons mx-1 animated slideInRight" *ngIf="!showmore" mdbTooltip="Ver Más" placement="bottom">
                  <mdb-icon fad icon="chevron-right" size="lg"></mdb-icon>
                  <span class="small text-muted d-block">más</span>
                </a>
              </ng-template>


              <ng-container *ngIf="showmore; else showless">
                <a (click)="showmore = false" class="header-icons mx-1 animated slideInLeft" mdbTooltip="Volver" placement="bottom">
                  <mdb-icon fad icon="chevron-left" size="lg"></mdb-icon>
                  <span class="small text-muted d-block">volver</span>
                </a>
                <ng-container *ngIf="currentWindowWidth < 1520">
                  <a  [mdbTooltip]="task_entity.labels.list | titlecase" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection(task_entity.list_entity)">
                    <mdb-icon fad [icon]="task_entity.icon.list" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === task_entity.list_entity}"></mdb-icon>
                    <span class="counter success-color" *ngIf="claim?.tasks_count > 0">{{claim.tasks_count}}</span>
                    <span class="small text-muted d-block">{{task_entity.labels.list | titlecase}}</span>
                  </a>
                  <a mdbTooltip="Terceros" placement="bottom" class="header-icons mx-1 animated slideInRight" (click)="selectSection('thirds')">
                    <mdb-icon fad icon="user-injured" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'thirds'}"></mdb-icon>
                    <span class="small text-muted d-block">Terceros</span>
                  </a>
                </ng-container>
                <a mdbTooltip="Valoraciones Periciales" placement="bottom" class="header-icons mx-1 animated slideInLeft">
                  <mdb-icon fad icon="clipboard-user" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'assessments'}"></mdb-icon>
                  <span class="small text-muted d-block">Val. Periciales</span>
                </a>
              </ng-container>

            </div>
            <!-- <div class="col-12" mdbCollapse #test="bs-collapse">
              <a mdbTooltip="Comunicaciones Protocolo" placement="bottom" class="header-icons mx-1" *ngIf="claim?.typeprotocol_id">
                <mdb-icon fad icon="broadcast-tower" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'protocol'}"></mdb-icon>
                <span class="small text-muted d-block">Prot.</span>
              </a>
              <a mdbTooltip="Comunicaciones Protocolo" placement="bottom" class="header-icons mx-1" *ngIf="claim?.typeprotocol_id">
                <mdb-icon fad icon="broadcast-tower" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'protocol'}"></mdb-icon>
                <span class="small text-muted d-block">Prot.</span>
              </a>
              <a mdbTooltip="Comunicaciones Protocolo" placement="bottom" class="header-icons mx-1" *ngIf="claim?.typeprotocol_id">
                <mdb-icon fad icon="broadcast-tower" size="2x" class="" [ngClass]="{'blue-text': selected_section.section === 'protocol'}"></mdb-icon>
                <span class="small text-muted d-block">Prot.</span>
              </a>
            </div> -->
          </div>
        </mdb-card-header>

      </mdb-card>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 bc-icons-2">
      <!--<mdb-breadcrumb customClass="claim-breadcrumbs border-bottom">
        <mdb-breadcrumb-item class="active">
          <mdb-icon fas icon="{{selected_section.icon}}" class="mr-1"></mdb-icon>
          {{selected_section.label}}
        </mdb-breadcrumb-item>
        <!-- <mdb-breadcrumb-item>
          <mdb-icon fas icon="caret-right" class="mr-1"></mdb-icon> {{selected_section.label}}
        </mdb-breadcrumb-item> -->
     <!-- </mdb-breadcrumb>-->
      </div>
    <ng-container *ngIf="selected_section.section === 'info'; else claimtabs">
      <div class="col">
        <ng-container *ngIf="formClaim">
          <form [formGroup]="formClaim">
            <div class="row">
              <div class="col-lg">
                <div class="row">
                  <div class="col">
                    <div class="md-form md-outline">
                        <mdb-date-picker [inline]="true" name="order_date" [options]="dateFormatter.opcionesMDBDatePicker" id="order_date" [label]="'Fecha Encargo'" [placeholder]="'Fecha Encargo'" formControlName="order_date" required></mdb-date-picker>
                    </div>
                  </div>
                  <div class="col">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="number_provider" class="form-control" formControlName="number_provider" placeholder="">
                      <label for="number_provider">Nº de Aviso</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="number" class="form-control" formControlName="number" placeholder="">
                      <label for="number">Nº de {{data.entity_label | titlecase}}</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="number_assistance" class="form-control" formControlName="number_assistance" placeholder="">
                      <label for="number_assistance">Nº de Asistencia</label>
                    </div>
                  </div>

                  <div class="col-lg" formGroupName="typecondition">
                    <ng-container *ngIf="dropdowndata?.typecondition; else nodropdowncondition">
                      <div class="md-form">
                        <app-dropdowns [label]="'Estado'" [outline]="true" [type]="'typecondition'" [dropdowndata]="dropdowndata"
                        [disabled]="formClaim.disabled"
                        [init_value]="formClaim.controls.typecondition.get('id').value"
                        [activefilter]="false"
                        (selectedValue)="changeSelect('typecondition', $event)"
                        >
                      </app-dropdowns>
                    </div>
                    </ng-container>
                    <ng-template #nodropdowncondition>
                      <div class="md-form md-outline">
                        <input mdbInput type="text" id="typeconditionname" class="form-control form-control-sm" formControlName="name" placeholder="">
                        <label for="typeconditionname">Estado</label>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-lg">
                    <div formGroupName="user">
                      <ng-container *ngIf="dropdowndata?.user; else nodropdown">
                        <div class="md-form">
                          <app-dropdowns [label]="'Tramitador'" [outline]="true" [type]="user_entity.entity" [dropdowndata]="dropdowndata" [disabled]="formClaim.disabled" [init_value]="formClaim.controls.user.get('id').value" (selectedValue)="changeSelect(user_entity.entity, $event)"></app-dropdowns>
                        </div>
                      </ng-container>
                      <ng-template #nodropdown>
                        <div class="md-form md-outline">
                          <input mdbInput type="text" id="username" class="form-control" formControlName="name" placeholder="">
                          <label for="username">Tramitador</label>
                        </div>
                      </ng-template>
                    </div>
                  </div> 
                </div>

                <div class="row mt-3">
                  <div class="col-md-2">
                    <ng-container *ngIf="dropdowndata?.agent; else nodropdown2">
                      <div class="md-form" formGroupName="agent">
                        <app-dropdowns [label]="agent_entity.labels.form | titlecase" [outline]="true" [type]="agent_entity.entity" [dropdowndata]="dropdowndata" [disabled]="formClaim.disabled" [init_value]="formClaim.controls.agent.get('id').value" [addFirstRegister]="true" (selectedValue)="changeSelect(agent_entity.entity, $event)"></app-dropdowns>
                      </div>
                    </ng-container>
                    <ng-template #nodropdown2>
                      <div class="md-form md-outline"  formGroupName="agent">
                        <input mdbInput type="text" id="agentname" class="form-control" formControlName="name" placeholder="">
                        <label for="agentname">{{agent_entity.labels.form | titlecase}}</label>
                      </div>
                    </ng-template>
                  </div>

                  <div class="col-md-2">
                    <div formGroupName="guild">
                      <ng-container *ngIf="dropdowndata?.guild; else nodropdown1">
                        <div class="md-form">
                          <app-dropdowns [label]="(guild_entity.labels.form | titlecase)" [outline]="true" [type]="guild_entity.entity" [dropdowndata]="dropdowndata" [disabled]="formClaim.disabled" [init_value]="formClaim.controls.guild.get('id').value" [addFirstRegister]="true" (selectedValue)="changeSelect(guild_entity.entity, $event)"></app-dropdowns>
                        </div>
                      </ng-container>
                      <ng-template #nodropdown1>
                        <div class="md-form md-outline">

                          <input mdbInput type="text" id="guildname" class="form-control" formControlName="name" placeholder="">
                          <label for="guildname">{{guild_entity.labels.form | titlecase}}</label>
                        </div>
                      </ng-template>
                    </div>
                  </div>

                  <ng-container *ngIf="claim?.policy; else particularclaim">
                    <div class="col-md-2">
                      <div class="md-form md-outline" formGroupName="provider">
                        <input mdbInput type="text" id="companyname" class="form-control" formControlName="name" placeholder="">
                        <label for="companyname">{{company_entity.labels.form | titlecase}}</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="md-form md-outline" formGroupName="subprovider">
                        <input mdbInput type="text" id="companyname" class="form-control" formControlName="name" placeholder="">
                        <label for="companyname">Sub{{company_entity.labels.form }}</label>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="md-form md-outline input-group" formGroupName="policy">
                        <input mdbInput type="text" id="policynumber" class="form-control input-group-padding" formControlName="number" placeholder="">
                        <label for="policynumber">Póliza</label>
                        <div class="input-group-append" *ngIf="!disableChanges() && !claim?.typeprotocol_id">
                          <button mdbBtn color="default" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2" (click)="unblockModal(true)"
                            mdbWavesEffect><mdb-icon fas icon="sync"></mdb-icon></button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #particularclaim>
                    <div class="col-md-2">
                        <div class="md-form  md-outline input-group" formGroupName="provider">
                          <input mdbInput type="text" id="providername" class="form-control input-group-padding" formControlName="name" placeholder="">
                          <label for="providername">Proveedor</label>

                          <div class="input-group-append" *ngIf="!disableChanges() && !claim?.typeprotocol_id">
                            <button mdbBtn color="grey" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2" (click)="unblockModal(true)"
                              mdbWavesEffect><mdb-icon fas icon="sync"></mdb-icon></button>
                          </div>
                        </div>
                    </div>
                  </ng-template>
                </div>

                <div class="row mt-3">
                  <div class="col-md">
                    <div class="md-form md-outline" formGroupName="client">
                      <input mdbInput type="text" id="clientname" class="form-control" formControlName="name" placeholder="">
                      <label for="clientname">{{client_entity.labels.form | titlecase}}</label>
                    </div>
                  </div>

                  <ng-container *ngIf="claim?.policy; else clientaddress">
                    <div class="col-md">
                      <div class="md-form md-outline" formGroupName="policy">
                        <input mdbInput type="text" id="address_complete" class="form-control" formControlName="address_complete" placeholder="">
                        <label for="address_complete">Dirección</label>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #clientaddress>
                    <div class="col-md">
                      <div class="md-form md-outline" formGroupName="clientaddress">
                          <input mdbInput type="text" id="address_complete" class="form-control unblock-input" formControlName="address_complete" placeholder="">
                          <label for="address_complete">Dirección</label>
                      </div>
                    </div>
                  </ng-template>
                  <div class="col-md">
                    <div class="md-form md-outline" formGroupName="client">
                      <input mdbInput type="text" id="clientmail" class="form-control" formControlName="email" placeholder="">
                      <label for="clientmail">Email</label>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="md-form md-outline" formGroupName="client">
                      <input mdbInput type="text" id="clientphone" class="form-control" formControlName="phone" placeholder="">
                      <label for="clientphone">Teléfono</label>
                    </div>
                  </div>
                  
                  <div class="col-md">
                    <div class="md-form md-outline input-group" formGroupName="client">
                      <input mdbInput type="text" id="clientmobile" class="form-control input-group-padding" formControlName="mobile" placeholder="">
                      <label for="clientmobile">Móvil</label>
                      <ng-container *appPermissions="'list_whatsappschats'">
                        <ng-container *ngIf="welcome_template_approved">
                          <div class="input-group-append" *ngIf="!disableChanges() && formClaim.controls.client['controls'].mobile.value != null && formClaim.controls.client['controls'].mobile.value != ''">
                            <button mdbBtn color="success" size="sm" class="m-0 px-3 py-2" type="button" id="button-addon2" (click)="openChat()"
                            mdbWavesEffect><mdb-icon fab icon="whatsapp" size="2x"></mdb-icon></button>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="md-form md-outline">
                  <!-- <mdb-icon fas icon="eye" class="prefix"></mdb-icon> -->
                  <textarea type="text" id="description" rows="10" class="md-textarea form-control" mdbInput formControlName="description" placeholder=""></textarea>
                  <label for="description">Descripción</label>
                  <div class="d-flex justify-content-end">

                      <a class="blue-text" (click)="showFullDescription()">
                        <mdb-icon fas icon="eye" size="lg"></mdb-icon></a>
                  </div>
                </div>
              </div>
            </div>
        </form>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #claimtabs>
      <ng-container *ngIf="!selected_section.params;else masterdetails">
        <ng-container [ngSwitch]="selected_section.section">
          <ng-container *ngSwitchCase="'claimprotocol'">
            <div class="col">
              <app-claim-protocol-comunications [readonly]="disableChanges()" [claimData]="claim"></app-claim-protocol-comunications>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'claimemails'">
            <div class="col">
              <app-claim-emails [readonly]="disableChanges()" [claimData]="claim"></app-claim-emails>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="action_entity.list_entity">
            <div class="col">
              <app-claim-actions [readonly]="disableChanges()" [claimData]="claim" (reloadCounters)="reloadCounters()"></app-claim-actions>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="task_entity.list_entity">
            <div class="col">
              <app-claim-tasks [readonly]="disableChanges()" [claimData]="claim" (reloadCounters)="reloadCounters()"></app-claim-tasks>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'thirds'">
            <div class="col">
              <app-claim-thirds [readonly]="disableChanges()" [claimData]="claim"></app-claim-thirds>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'documents'">
            <div class="col">
             <app-claim-documents [readonly]="disableChanges()" [claimData]="claim"></app-claim-documents>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'multimedia'">
            <div class="col">
              <app-claim-multimedia [readonly]="disableChanges()" [claimData]="claim"></app-claim-multimedia>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #masterdetails>
        <div class="col">
          <app-dynamic-in-claim-list #inclaimlist [readonly]="disableChanges()" [data]="selected_section.params" [claimData]="claim" [taxes]="dropdowndata?.taxe" (reload_counters)="reloadCounters()"></app-dynamic-in-claim-list>
        </div>
      </ng-template>
    </ng-template>
  </div>

  <div class="row custom-fixed-bottom">
    <app-loader *ngIf="loading"></app-loader>
    <div class="col-12">
      <mdb-accordion [multiple]="false">
        <mdb-accordion-item [collapsed]="true" [customClass]="'border-1'">
          <mdb-accordion-item-head [customClass]="'grey lighten-3 small claim-history-accordion'">
            <mdb-icon fad icon="history" size="lg" class="mr-1 text-primary"></mdb-icon>Historial del {{data.entity_label | titlecase}}
          </mdb-accordion-item-head>
          <mdb-accordion-item-body>
            <div class="table-history">
              <table mdbTable mdbTableScroll scrollY="true" maxHeight="17rem" stickyHeader= "true" hover="true" small="false">
                <thead>
                    <tr>
                      <th scope="col">
                        Fecha
                      </th>
                      <th scope="col">
                        icono
                      </th>
                      <th scope="col">
                        Descripción
                      </th>
                      <th scope="col">

                      </th>
                    </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of claim?.history; let i=index">
                    <tr mdbTableCol>
                      <td>
                        {{item.updated_at | date: 'dd/MM/yyyy HH:mm:ss'}}
                      </td>
                      <td>
                        <ng-container *ngIf="app_entities[item.enumModel]?.icon?.form; else default_history_icon">
                          <!-- <mdb-icon fad icon="file"></mdb-icon> -->
                          <mdb-icon fad icon="{{app_entities[item.enumModel].icon.form}}" class="mr-1"></mdb-icon>
                        </ng-container>
                        <ng-template #default_history_icon>
                          <mdb-icon fad icon="file"></mdb-icon>
                        </ng-template>
                      </td>
                      <td>
                        {{ item.description }}
                      </td>
                      <td>
                        <a class="" (click)="openItem(item)">
                          <mdb-icon fad icon="eye" class=""></mdb-icon>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </mdb-accordion-item-body>
        </mdb-accordion-item>
      </mdb-accordion>
    </div>

  </div>
</div>
