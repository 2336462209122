import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { matchValues } from "../form-validators/custom-validators";
import { DateFormats } from "./date-formats";


export class FormFunctions {
  private builder: FormBuilder;

  getForm(entity: string): FormGroup {
    switch (entity) {
      case 'profile':
        return this.getProfileForm();
      case 'mailaccount':
        return this.getMailAccountForm();
      case 'client':
        return this.getClientForm();
      case 'policy':
        return this.getPolicyForm();
      case 'provider':
        return this.getProviderForm();
      case 'user':
        return this.getUserForm();
      case 'workorder':
        return this.getWorkOrderForm();
      case 'claim':
        return this.getClaimForm();
      case 'invoice':
        return this.getInvoiceForm();
      case 'deliverynote':
        return this.getDeliveryForm();
      case 'invoiceproforma':
        return this.getInvoiceproformaForm();
      case 'budget':
        return this.getBudgetForm();
      case 'agent':
        return this.getAgentForm();
      case 'employee':
        return this.getEmployeeForm();
      case 'expense':
        return this.getExpenseForm();
      case 'liquidation':
        return this.getLiquidationForm();
      case 'expert':
        return this.getExpertForm();
      case 'scale':
        return this.getScaleForm();
      case 'firm':
        return this.getFirmForm();

      default:
        return new FormGroup({});
    }
  }


  fillFormParams(form: FormGroup, entity: string, params:any){
    let fields_changed =  Object.entries(form.controls).filter(value => value[1].dirty);

    const dateFormatter = new DateFormats;

    params[entity] = {};
    fields_changed.forEach(
      field => {
        const name = field[0];
        const type = field[1];

        if(type instanceof FormGroup) {
          let value = type['controls'].id.value;
          if(value === -1) {
            value = null;
          }
          params[entity][name+'_id'] = value
        } else {
          let value = type.value
          if(name.includes('date')) {
            value = dateFormatter.formatoSave(value);
          } else if(name.includes('_id')) {
            if(value === -1) {
              value = null;
            }
          }

          params[entity][name] = value;
        }
      }
    )
  }


  rellenarFormulario(form, dataobject, path?) {

      Object.keys(form.controls).forEach(
        control =>{
          if(form.controls[control] instanceof FormGroup){

            if(path) {
              if(path[control]) {
                this.rellenarFormulario(form.controls[control], dataobject, path[control])
              }
            } else {
              //si el índice del formgroup no está vacío en el objeto de datos se entra al formgroup (no tiene sentido entrar en el formgroup si los hijos del objeto de datos no existen)
              if(dataobject[control] !== null) {
                this.rellenarFormulario(form.controls[control], dataobject, dataobject[control])
              }
            }

          } else {
            if(path) {
              form.controls[control].setValue(path[control]);
            } else {
              if(path !== null) {
                form.controls[control].setValue(dataobject[control])
              }

            }
          }
        }
      )

  }

  lineFormChange(form: FormGroup, taxe_value: number, cost?:boolean) {
    if(cost) {
      form.controls.total_cost.setValue(((+form.controls.quantity.value)*(+form.controls.cost.value)).toFixed(2))
    }

    let price = (form.controls.quantity.value)*(+form.controls.price.value)

    let total_discount = (price * form.controls.discount.value)/100;
    let total_price = price - total_discount;

    form.controls.total_price.setValue(+total_price.toFixed(2));

    let total_iva = (+(total_price)*taxe_value)/100;
    form.controls.total_taxe.setValue(total_iva.toFixed(2));

    let total_linea = (+total_price) + (+form.controls.total_taxe.value);
    form.controls.total.setValue(total_linea.toFixed(2));
  }

  private getFirmForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      email: new FormControl({ value: '', disabled:false},Validators.email),
      phone: new FormControl({ value: '', disabled:false}),
      mobile: new FormControl({ value: '', disabled:false}),
      identification_document: new FormControl({ value: '', disabled:false}),
      name:new FormControl({ value: '', disabled: false}, Validators.required),
      enabled:new FormControl({ value: 0, disabled:false}),
      url_web: new FormControl({ value: '', disabled:false}),
      observations:new FormControl({ value: '', disabled: false}),    
      whatsapp_mobile:new FormControl({ value: '', disabled: false}),    
      whatsapp_telephone_number_identifier:new FormControl({ value: '', disabled: false}),    
      whatsapp_business_account_identifier:new FormControl({ value: '', disabled: false}),    
      whatsapp_token:new FormControl({ value: '', disabled: false}),    

    })
  }

  private getMailAccountForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: null, disabled:true}),
      enabled:new FormControl({ value: null, disabled:false}),
      mail:new FormControl({ value: null, disabled:true}),
      data_error_connection:new FormControl({ value: null, disabled:true}),
      imap_port:new FormControl({ value: null, disabled:false}),
      imap_server:new FormControl({ value: null, disabled:false}),
      smtp_port:new FormControl({ value: null, disabled:false}),
      smtp_server:new FormControl({ value: null, disabled:false}),
      password:new FormControl({ value: null, disabled:false}),
      state:new FormControl({ value: null, disabled:true}),
    })
  }


  private getProfileForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      name: new FormControl({ value: '', disabled:false}),
      surname: new FormControl({ value: '', disabled:false}),
      image_profile: new FormControl({ value: '', disabled:false}),
      defaultFirm: new FormControl({ value: null, disabled:false}),
      defaultMail: new FormControl({ value: null, disabled:false}),
      password: new FormControl({ value: '', disabled:true}),
      confirm_password: new FormControl({ value: '', disabled:true}, {validators: matchValues('password')}),
      email: new FormControl({ value: '', disabled:true}),
    },
    )
  }

  private getUserForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      name: new FormControl({ value: '', disabled:false},[Validators.required]),
      surname: new FormControl({ value: '', disabled:false}, [Validators.required]),
      email: new FormControl({ value: '', disabled:true}),
      password: new FormControl({ value: '', disabled:true}),
      is_enabled:new FormControl({ value: 0, disabled:false}),
    },
    )
  }

  private getPolicyForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:false}),
      address: new FormControl({ value: '', disabled:false}),
      enabled: new FormControl({ value: 1, disabled:false}),
      number: new FormControl({ value: '', disabled:false}, Validators.required),
      population: new FormControl({ value: '', disabled:false}),
      postal_code: new FormControl({ value: '', disabled:false}),
      provider_id: new FormControl({ value: null, disabled:false}),
      subprovider_id: new FormControl({ value: -1, disabled:false}),
      province: new FormControl({ value: '', disabled:false}),
    },
    )
  }

  private getProviderForm(): FormGroup {
    return new FormGroup({
      id:new FormControl({ value: null, disabled:false}, Validators.required),
      name:new FormControl({ value: '', disabled:false}, Validators.required),
      cif:new FormControl({ value: '', disabled:false}),
      email:new FormControl({ value: '', disabled:false}),
      phone:new FormControl({ value: '', disabled:false}),
      mobile:new FormControl({ value: '', disabled:false}),
      enabled:new FormControl({ value: false, disabled:false}),
      observations:new FormControl({ value: '', disabled:false}),

    })
  }

  private getCompanyForm(): FormGroup {
    return new FormGroup({
      id:new FormControl({ value: null, disabled:false}, Validators.required),
      company_id:new FormControl({ value: null, disabled:false}),
      name:new FormControl({ value: '', disabled:false}, Validators.required),
      cif:new FormControl({ value: '', disabled:false}),
      email:new FormControl({ value: '', disabled:false}),
      phone:new FormControl({ value: '', disabled:false}),
      mobile:new FormControl({ value: '', disabled:false}),
      enabled:new FormControl({ value: false, disabled:false}),
      observations:new FormControl({ value: '', disabled: false}),
      protocol_id: new FormControl({ value: null, disabled:false})
    })
  }


  private getClientForm(): FormGroup{

    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      enabled:new FormControl({ value: 0, disabled:false}),
      web_access:new FormControl({ value: 0, disabled:false}),
      email: new FormControl({ value: '', disabled:false}),
      phone: new FormControl({ value: '', disabled:false}),
      mobile: new FormControl({ value: '', disabled:false}),
      identification_document: new FormControl({ value: '', disabled:false}),
      name:new FormControl({ value: '', disabled: false}, Validators.required),
      surname:new FormControl({ value: '', disabled: false}),
      observations:new FormControl({ value: '', disabled: false}),

    });
  }

  private getAgentForm(): FormGroup{

    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      enabled:new FormControl({ value: 0, disabled:false}),
      web_access:new FormControl({ value: 0, disabled:false}),
      email: new FormControl({ value: '', disabled:false}),
      phone: new FormControl({ value: '', disabled:false}),
      mobile: new FormControl({ value: '', disabled:false}),
      identification_document: new FormControl({ value: '', disabled:false}),
      name:new FormControl({ value: '', disabled: false}, Validators.required),
      surname:new FormControl({ value: '', disabled: false}),
      address:new FormControl({value: '', disabled:false}),
      postal_code:new FormControl({value: '', disabled:false}),
      population:new FormControl({value: '', disabled:false}),
      province:new FormControl({value: '', disabled:false}),
      observations:new FormControl({value: '', disabled:false}),
    });
  }

  private getExpertForm(): FormGroup{

    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      enabled:new FormControl({ value: 0, disabled:false}),
      email: new FormControl({ value: '', disabled:false}),
      phone: new FormControl({ value: '', disabled:false}),
      mobile: new FormControl({ value: '', disabled:false}),
      identification_document: new FormControl({ value: '', disabled:false}),
      name:new FormControl({ value: '', disabled: false}, Validators.required),
      surname:new FormControl({ value: '', disabled: false}),
      address:new FormControl({value: '', disabled:false}),
      postal_code:new FormControl({value: '', disabled:false}),
      population:new FormControl({value: '', disabled:false}),
      province:new FormControl({value: '', disabled:false}),
      observations:new FormControl({value: '', disabled:false}),
    });
  }

  private getScaleForm(): FormGroup{

    return new FormGroup({
      id: new FormControl({ value: -1, disabled: true }),
      provider_id: new FormControl({ value: null, disabled: false }, Validators.required),
      subprovider_id: new FormControl({ value: null, disabled: false }),
      guild_id: new FormControl({ value: null, disabled: false }, Validators.required),
      province_id: new FormControl({ value: null, disabled: false }, Validators.required),
      code: new FormControl({ value: null, disabled: false }, Validators.required),
      internal_code: new FormControl({ value: null, disabled: false }),
      description: new FormControl({ value: null, disabled: false }, Validators.required),
      price: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(0)]),
      taxe_id: new FormControl({ value: null, disabled: false }),
      cost: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(0)])
    });
  }

  private getEmployeeForm(): FormGroup{

    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      enabled:new FormControl({ value: 0, disabled:false}),
      web_access:new FormControl({ value: 0, disabled:false}),
      email: new FormControl({ value: '', disabled:false}),
      phone: new FormControl({ value: '', disabled:false}),
      mobile: new FormControl({ value: '', disabled:false}),
      identification_document: new FormControl({ value: '', disabled:false}),
      name:new FormControl({ value: '', disabled: false}, Validators.required),
      surname:new FormControl({ value: '', disabled: false}),
      address:new FormControl({value: '', disabled:false}),
      postal_code:new FormControl({value: '', disabled:false}),
      population:new FormControl({value: '', disabled:false}),
      province:new FormControl({value: '', disabled:false}),
      observations:new FormControl({ value: '', disabled: false})    
    });
  }

  private getInvoiceproformaForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      type_export_id :new FormControl({value: '', disabled:true}),
      description:new FormControl({ value: '', disabled: false}),
      emission_date: new FormControl(''),
      footer:new FormControl({ value: '', disabled: false}),
      number:new FormControl({value: '', disabled:true}),
      clientaddress_id :new FormControl({value: '', disabled:true}),
      clientaddress: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        address_complete:new FormControl({ value: '', disabled:true}),
      }),
      serie: new FormGroup({
        id:new FormControl(-1),
        description:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
      invoiceproforma_address: new FormControl({value: '', disabled:true}),
      invoiceproforma_provider_name: new FormControl({value: '', disabled:true}),

      client: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        name_complete:new FormControl({ value: '', disabled:true}),
      }),
      guild: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
      
       claim: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        number:new FormControl({ value: '---', disabled:true}),
        clientaddress: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true}),
        }),
        user: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          name:new FormControl({ value: '---', disabled:true})
        }),
        policy: new FormGroup({
          id:new FormControl({ value: '', disabled:true}),
          number:new FormControl({ value: '', disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true})
        }),
      }),
      typeexport: new FormGroup({
        id: new FormControl({ value: -1, disabled:true}),
        name: new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),

    })
  }

  private getDeliveryForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      type_export_id :new FormControl({value: '', disabled:true}),
      description:new FormControl({ value: '', disabled: false}),
      emission_date: new FormControl(''),
      footer:new FormControl({ value: '', disabled: false}),
      number:new FormControl({value: '', disabled:true}),
      clientaddress_id :new FormControl({value: '', disabled:true}),
      clientaddress: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        address_complete:new FormControl({ value: '', disabled:true}),
      }),
      deliverynote_address: new FormControl({value: '', disabled:true}),
      deliverynote_provider_name: new FormControl({value: '', disabled:true}),

      client: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        name_complete:new FormControl({ value: '', disabled:true}),
      }),
      guild: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
       claim: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        number:new FormControl({ value: '---', disabled:true}),
        clientaddress: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true}),
        }),
        user: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          name:new FormControl({ value: '---', disabled:true})
        }),
        policy: new FormGroup({
          id:new FormControl({ value: '', disabled:true}),
          number:new FormControl({ value: '', disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true})
        }),
      }),
      typeexport: new FormGroup({
        id: new FormControl({ value: -1, disabled:true}),
        name: new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),
      serie: new FormGroup({
        id:new FormControl(-1),
        description:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
    })

  }

  private getExpenseForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      number:new FormControl({value: '', disabled:true}),
      code:new FormControl({value: '', disabled:false}),
      emission_date: new FormControl(''),
      concept:new FormControl({value: '', disabled:false}),
      total:new FormControl({value: '', disabled:true}),
      total_base:new FormControl({value: null, disabled:false}),
      total_taxe:new FormControl({value: '', disabled:true}),
      claim: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        number:new FormControl({ value: '---', disabled:true}),
      }),
      typestate: new FormGroup({
        id: new FormControl(-1),
        name: new FormControl({ value: '', disabled:true})
      }),
      employee: new FormGroup({
        id: new FormControl(-1),
        name: new FormControl({ value: '', disabled:true})
      }),
      taxe: new FormGroup({
        id:new FormControl({ value: -1, disabled:false}),
        name:new FormControl({ value: '', disabled:true}),
        value:new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),
    })

  }

  private getBudgetForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      type_export_id :new FormControl({value: '', disabled:true}),
      description:new FormControl({ value: '', disabled: false}),
      emission_date: new FormControl(''),
      footer:new FormControl({ value: '', disabled: false}),
      number:new FormControl({value: '', disabled:true}),
      clientaddress_id :new FormControl({value: '', disabled:true}),
      clientaddress: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        address_complete:new FormControl({ value: '', disabled:true}),
      }),
      budget_address: new FormControl({value: '', disabled:true}),
      budget_provider_name: new FormControl({value: '', disabled:true}),

      client: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        name_complete:new FormControl({ value: '', disabled:true}),
      }),
      guild: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
      serie: new FormGroup({
        id:new FormControl(-1),
        description:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
       claim: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        number:new FormControl({ value: '---', disabled:true}),
        clientaddress: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true}),
        }),
        user: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          name:new FormControl({ value: '---', disabled:true})
        }),
        policy: new FormGroup({
          id:new FormControl({ value: '', disabled:true}),
          number:new FormControl({ value: '', disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true})
        }),
      }),
      typeexport: new FormGroup({
        id: new FormControl({ value: -1, disabled:true}),
        name: new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),

    })

  }

  private getInvoiceForm(): FormGroup {
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      type_export_id :new FormControl({value: '', disabled:true}),
      description:new FormControl({ value: '', disabled: false}),
      emission_date: new FormControl(''),
      footer:new FormControl({ value: '', disabled: false}),
      number:new FormControl({value: '', disabled:true}),
      clientaddress_id :new FormControl({value: '', disabled:true}),
      clientaddress: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        address_complete:new FormControl({ value: '', disabled:true}),
      }),
      invoice_address: new FormControl({value: '', disabled:true}),
      invoice_provider_name: new FormControl({value: '', disabled:true}),

      client: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        name_complete:new FormControl({ value: '', disabled:true}),
      }),
      guild: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
       claim: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        number:new FormControl({ value: '---', disabled:true}),
        clientaddress: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true}),
        }),
        user: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          name:new FormControl({ value: '---', disabled:true})
        }),
        policy: new FormGroup({
          id:new FormControl({ value: '', disabled:true}),
          number:new FormControl({ value: '', disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true})
        }),
      }),
      serie: new FormGroup({
        id:new FormControl({ value: -1, disabled:true}),
        description:new FormControl({ value: '', disabled:true})
      }),
      /*typeexport: new FormGroup({
        id: new FormControl({ value: -1, disabled:true}),
        name: new FormControl({ value: '', disabled:true})
      }),*/
      typestate: new FormGroup({
        id: new FormControl({ value: -1, disabled:true}),
        name: new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),

    })
  }

  private getClaimForm(): FormGroup {
    return new FormGroup({
      id: new FormControl({ value: -1, disabled:true}),
      description: new FormControl(''),
      number: new FormControl({value: '', disabled:true}),
      number_assistance: new FormControl({value: '', disabled:false}),
      number_provider: new FormControl({value: '', disabled:true}),
      order_date: new FormControl(''),
      user: new FormGroup({
        id: new FormControl(-1),
        name: new FormControl({value: '', disabled:true}),
      }),
      client: new FormGroup({
        id: new FormControl({ value: null, disabled:true}),
        name: new FormControl({ value: '', disabled:true}),
        phone: new FormControl({ value: '', disabled:true}),
        mobile: new FormControl({ value: '', disabled:true}),
        email: new FormControl({ value: '', disabled:true}),
      }),
      clientaddress: new FormGroup({
        id: new FormControl({value: null, disabled: false}),
        address_complete: new FormControl({ value: '', disabled:true}),
      }),
      agent: new FormGroup({
        id: new FormControl( {value: -1, disabled:false}),
        name: new FormControl( {value: '', disabled:true}),
      }),
      guild: new FormGroup({
        id:new FormControl(-1),
        name: new FormControl( {value: 'Sin Asignar', disabled:true})
      }),
      provider: new FormGroup({
        id: new FormControl( {value: null, disabled:true}),
        name: new FormControl( {value: 'Particular', disabled:true}),
      }),
      subprovider: new FormGroup({
        id: new FormControl( {value: null, disabled:true}),
        name: new FormControl( {value: '--Sin asignar--', disabled:true}),
      }),
      policy: new FormGroup({
        id:new FormControl({ value: null, disabled:false}),
        number:new FormControl({ value: '', disabled:true}),
        address_complete: new FormControl({ value: '', disabled:true}),
      }),
      typecondition: new FormGroup({
        id: new FormControl(-1),
        name: new FormControl({ value: '', disabled:true})
      })

    })

  }

  private getWorkOrderForm(): FormGroup {
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      description:new FormControl({ value: '', disabled: false}),
      emission_date: new FormControl(''),
      footer:new FormControl({ value: '', disabled: false}),
      work_description:new FormControl({ value: '---', disabled: true}),
      number:new FormControl({value: '', disabled:true}),
      type_export_id :new FormControl({value: '', disabled:true}),
      clientaddress_id :new FormControl({value: '', disabled:true}),
      clientaddress: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        address_complete:new FormControl({ value: '', disabled:true}),
      }),
      workorder_address: new FormControl({value: '', disabled:true}),
      workorder_provider_name: new FormControl({value: '', disabled:true}),
      provider: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        name:new FormControl({ value: 'Particular', disabled:true})
      }),
      user: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({value: '', disabled:true}),
      }),
      typestatusonmobile: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({value: '---', disabled:true}),
      }),
      client: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        name_complete:new FormControl({ value: '', disabled:true}),
      }),
      guild: new FormGroup({
        id:new FormControl(-1),
        name:new FormControl({ value: 'Sin Asignar', disabled:true})
      }),
      claim: new FormGroup({
        id:new FormControl({ value: null, disabled:true}),
        number:new FormControl({ value: '---', disabled:true}),
        clientaddress: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true}),
        }),
        user: new FormGroup({
          id:new FormControl({ value: null, disabled:true}),
          name:new FormControl({ value: '---', disabled:true})
        }),
        policy: new FormGroup({
          id:new FormControl({ value: '', disabled:true}),
          number:new FormControl({ value: '', disabled:true}),
          address_complete:new FormControl({ value: '', disabled:true})
        }),
      }),
      serie: new FormGroup({
        id:new FormControl(-1),
        description:new FormControl({ value: '', disabled:true})
      }),
      typecondition: new FormGroup({
        id: new FormControl(-1),
        name: new FormControl({ value: '', disabled:true})
      }),
      typeexport: new FormGroup({
        id: new FormControl({ value: -1, disabled:true}),
        name: new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),

    })
  }


  private getLiquidationForm(): FormGroup{
    return new FormGroup({
      id:new FormControl({ value: -1, disabled:true}),
      number:new FormControl({value: '', disabled:false}),
      description:new FormControl({ value: '', disabled: false}),
      emission_date: new FormControl(''),
      footer:new FormControl({ value: '', disabled: false}),
      total:new FormControl({value: '', disabled:true}),
      total_cost:new FormControl({value: null, disabled:false}),
      total_taxe:new FormControl({value: '', disabled:true}),
      employee: new FormGroup({
        id: new FormControl({ value: null, disabled:true}),
        name_complete: new FormControl({ value: '', disabled:true})
      }),
      typestate: new FormGroup({
        id: new FormControl(-1),
        name: new FormControl({ value: '', disabled:true})
      }),
      firmaddress:new FormGroup({
        id: new FormControl({ value: -1, disabled:false}),
        address_complete: new FormControl({ value: '', disabled:true})
      }),
      serie: new FormGroup({
        id:new FormControl(-1),
        description:new FormControl({ value: '', disabled:true})
      }),
    });
  }


  /* *
   *
   * @param form Formulario a Rellenar
   * @param dataobject Objeto que tiene todos los datos para rellenar. OBLIGATORIO en la primera llamada a la función
   * @param internaldata subobjeto de dataobject con los datos del nivel correspondiente. SOLO SE PASA EN LAS LLAMADAS RECURSIVAS
   */
rellenarFormulario4(form: FormGroup, dataobject?, internaldata?) {

  Object.keys(form.controls).forEach(
    control =>{

      if(form.controls[control] instanceof FormGroup){
        //el control es un formgroup, llama recursivamente a la funcion para rellenar sus controles

        const form_group = form.controls[control] as FormGroup;



        if(internaldata) {
  /** 3 **/
          //si el campo es formgroup y se ha pasado internaldata hay que volver a llamar la funcion y pasar el formgroup actual y el nivel del objeto de datos
          //formgroup de nivel interno (anidado) en el formulario formulario
          if(internaldata[control]) {
            this.rellenarFormulario4(form_group, null, internaldata[control])
          }
        } else {
  /** 2 **/
          //si el campo es formgroup y no se ha pasado internal data, es que es la primera llamada recursiva (formgroup en el primel nivel del formulario)
          this.rellenarFormulario4(form_group, null, dataobject[control])
        }

      } else {
        if(internaldata) {
  /** 4 **/
          //controles normales dentro del formgroup interno. se rellena con el parámetro internaldata y el nombre del control
          form.controls[control].setValue(internaldata[control]);
        } else {
  /** 1 **/
          //controles normales del formulario principal. no se ha pasado internaldata (es undefined). se rellena con el dato del objeto principal
          if(internaldata !== null) {
            form.controls[control].setValue(dataobject[control])
          }

        }
      }
    }
  )

}


}
