<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset">
    <!--PESTAÑA INFORMACION-->
    <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
        <ng-container *ngIf="formExpense">
          <form [formGroup]="formExpense">
            <div class="row mt-3">
              <div class="col-lg-2">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="expensenumber" class="form-control form-control-sm" formControlName="number" placeholder="" readonly="true">
                  <label for="expensenumber">Número</label>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="md-form md-outline" formGroupName="claim">
                  <input mdbInput type="text" id="claimnumber" class="form-control form-control-sm" placeholder="" formControlName="number">
                    <label for="claimnumber">Nº {{claim_entity.labels.form | titlecase}}</label>
                </div>
              </div>
              <div class="col-lg" formGroupName="firmaddress">
                <ng-container *ngIf="dropdowndata?.firm_addresses; else firmaddress">
                  <div class="md-form">
                    <app-dropdowns [label]="'Dirección Emisor'" [outline]="true" [type]="'firm_addresses'" [dropdowndata]="dropdowndata"
                    [disabled]="disableChanges()"
                    [label_field]="'address_complete'"
                    [init_value]="formExpense.controls.firmaddress.get('id').value"
                    [activefilter]="false"
                    (selectedValue)="changeSelect('firmaddress', $event)"
                    >
                  </app-dropdowns>
                </div>
                </ng-container>
                <ng-template #firmaddress>
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="firmaddress_complete" class="form-control form-control-sm" placeholder="" formControlName="address_complete">
                    <label for="firmaddress_complete">Dirección Emisor</label>
                  </div>
                </ng-template>
              </div>
              <div class="col-lg-1" formGroupName="typestate">
                <ng-container *ngIf="dropdowndata; else typestate">
                  <div class="md-form">
                    <app-dropdowns [label]="'Estado'" [outline]="true" [type]="'typestate'" [dropdowndata]="dropdowndata"
                    [disabled]="true"
                    [init_value]="formExpense.controls.typestate.get('id').value"
                    [activefilter]="false"
                    (selectedValue)="changeSelect('typestate', $event)"
                    >
                  </app-dropdowns>
                </div>
                </ng-container>
                <ng-template #typestate>
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="typestatename" class="form-control form-control-sm" placeholder="" formControlName="name">
                    <label for="typestatename">Estado</label>
                  </div>
                </ng-template>
              </div>
            </div> 
            <div class="row mt-3">
              <div class="col-md-2">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="code" class="form-control form-control-sm" formControlName="code" placeholder="">
                  <label for="code">Código</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form md-outline">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="concept" class="form-control form-control-sm" formControlName="concept" placeholder="">
                  <label for="concept">Concepto</label>
                  <mdb-error *ngIf="formExpense.controls.concept.invalid && (formExpense.controls.concept.dirty || formExpense.controls.concept.touched)">
                    Campo Obligatorio
                  </mdb-error>
                </div>
              </div>
              <div class="col-md-1">
                <div class="md-form md-outline">
                  <input mdbInput
                    mdbValidate
                    [validateSuccess]="false"
                    type="number"
                    step="1"
                    class="form-control form-control-sm text-right"
                    id="total_base"
                    formControlName="total_base"
                    placeholder="">
                  <label for="total_base">Importe Base</label>
                  <mdb-error *ngIf="formExpense.controls.total_base.invalid && (formExpense.controls.total_base.dirty || formExpense.controls.total_base.touched)">
                      <span *ngIf="formExpense.controls.total_base.errors.required">Campo Obligatorio</span>
                      <span *ngIf="formExpense.controls.total_base.errors.min">Valor incorrecto</span>
                  </mdb-error>
                </div>
              </div>
              <div class="col-lg-1" formGroupName="taxe">
                <ng-container *ngIf="dropdowndata; else taxefield">
                  <div class="md-form">
                    <app-dropdowns [label]="'Impuesto'" [type]="'taxe'" [outline]="true" [dropdowndata]="dropdowndata"
                    [disabled]="formExpense.controls.taxe.get('id').disabled"
                    [init_value]="formExpense.controls.taxe.get('id').value"
                    [label_field]="'value'"
                    [activefilter]="false"
                    (selectedValue)="changeSelect('taxe', $event)"
                    >
                  </app-dropdowns>
                </div>
                </ng-container>
                <ng-template #taxefield>
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="taxe" class="form-control form-control-sm" placeholder="" formControlName="value">
                    <label for="taxe">Impuesto</label>
                  </div>
                </ng-template>
              </div>
              <div class="col-md-1">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="total_taxe" class="form-control form-control-sm text-right" formControlName="total_taxe" placeholder="">
                  <label for="total_taxe">Total IVA</label>
                </div>
              </div>
              <div class="col-md-1">
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="total" class="form-control form-control-sm text-right" formControlName="total" placeholder="">
                  <label for="total">Total Neto</label>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
    </mdb-tab>
    
  <!--PESTAÑA RECIBOS-->
  <mdb-tab heading="<i class='fas fa-receipt'></i> Recibos">
    <div class="row mt-3">
      <app-receipts [receipts]="receipts"
      [entity]="'expensereceipt'"
      [entity_endpoint]="'expensesreceipts'"
      [parent_entity]="'expense'"
      [readonly]="disableChanges()" 
      [concept_suffix]="'Pago '+(data.entity_label | titlecase)+': '" [register_number]="expense?.number" [register_id]="expense?.id" [typepaymentmethods]="dropdowndata?.typepaymentmethod" (loading)="loading = $event"></app-receipts>
    </div>
  </mdb-tab>
</mdb-tabset>

<!--<app-loader *ngIf="loading"></app-loader>
<div class="pt-2">
  <mdb-tabset #expenseTabs [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

    <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
      <ng-container *ngIf="formExpense">
      <form [formGroup]="formExpense">
          <div class="row">
            <div class="col-lg-2">
              <div class="md-form">
                <input mdbInput type="text" id="expensenumber" class="form-control form-control-sm" formControlName="number" placeholder="" readonly="true">
                <label for="expensenumber">Número</label>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="md-form" formGroupName="claim">
                <input mdbInput type="text" id="claimnumber" class="form-control form-control-sm" placeholder="" formControlName="number">
                  <label for="claimnumber">Nº {{claim_entity.labels.form | titlecase}}</label>
              </div>
            </div>
            <div class="col-lg" formGroupName="firmaddress">
              <ng-container *ngIf="dropdowndata?.firm_addresses; else firmaddress">
                <div class="md-form">
                  <app-dropdowns [label]="'Dirección Emisor'" [type]="'firm_addresses'" [dropdowndata]="dropdowndata"
                  [disabled]="disableChanges()"
                  [label_field]="'address_complete'"
                  [init_value]="formExpense.controls.firmaddress.get('id').value"
                  [activefilter]="false"
                  (selectedValue)="changeSelect('firmaddress', $event)"
                  >
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #firmaddress>
                <div class="md-form">
                  <input mdbInput type="text" id="firmaddress_complete" class="form-control form-control-sm" placeholder="" formControlName="address_complete">
                  <label for="firmaddress_complete">Dirección Emisor</label>
                </div>
              </ng-template>
            </div>
            <div class="col-lg-1" formGroupName="typestate">
              <ng-container *ngIf="dropdowndata; else typestate">
                <div class="md-form">
                  <app-dropdowns [label]="'Situación'" [type]="'typestate'" [dropdowndata]="dropdowndata"
                  [disabled]="true"
                  [init_value]="formExpense.controls.typestate.get('id').value"
                  [activefilter]="false"
                  (selectedValue)="changeSelect('typestate', $event)"
                  >
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #typestate>
                <div class="md-form">
                  <input mdbInput type="text" id="typestatename" class="form-control form-control-sm" placeholder="" formControlName="name">
                  <label for="typestatename">Estado</label>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-2">
              <div class="md-form">
                <input mdbInput type="text" id="code" class="form-control form-control-sm" formControlName="code" placeholder="">
                <label for="code">Código</label>
              </div>
            </div>
            <div class="col-md">
              <div class="md-form">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="concept" class="form-control form-control-sm" formControlName="concept" placeholder="">
                <label for="concept">Concepto</label>
                <mdb-error *ngIf="formExpense.controls.concept.invalid && (formExpense.controls.concept.dirty || formExpense.controls.concept.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>
            <div class="col-md-1">
              <div class="md-form">
                <input mdbInput
                  mdbValidate
                  [validateSuccess]="false"
                  type="number"
                  step="1"
                  class="form-control form-control-sm text-right"
                  id="total_base"
                  formControlName="total_base"
                  placeholder="">
                <label for="total_base">Importe Base</label>
                <mdb-error *ngIf="formExpense.controls.total_base.invalid && (formExpense.controls.total_base.dirty || formExpense.controls.total_base.touched)">
                    <span *ngIf="formExpense.controls.total_base.errors.required">Campo Obligatorio</span>
                    <span *ngIf="formExpense.controls.total_base.errors.min">Valor incorrecto</span>
                </mdb-error>
              </div>
            </div>
            <div class="col-lg-1" formGroupName="taxe">
              <ng-container *ngIf="dropdowndata; else taxefield">
                <div class="md-form">
                  <app-dropdowns [label]="'% IVA'" [type]="'taxe'" [dropdowndata]="dropdowndata"
                  [disabled]="formExpense.controls.taxe.get('id').disabled"
                  [init_value]="formExpense.controls.taxe.get('id').value"
                  [label_field]="'value'"
                  [activefilter]="false"
                  (selectedValue)="changeSelect('taxe', $event)"
                  >
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #taxefield>
                <div class="md-form">
                  <input mdbInput type="text" id="taxe" class="form-control form-control-sm" placeholder="" formControlName="value">
                  <label for="taxe">Impuesto</label>
                </div>
              </ng-template>
            </div>
            <div class="col-md-1">
              <div class="md-form">
                <input mdbInput type="text" id="total_taxe" class="form-control form-control-sm text-right" formControlName="total_taxe" placeholder="">
                <label for="total_taxe">Total IVA</label>
              </div>
            </div>
            <div class="col-md-1">
              <div class="md-form">
                <input mdbInput type="text" id="total" class="form-control form-control-sm text-right" formControlName="total" placeholder="">
                <label for="total">Total Neto</label>
              </div>
            </div>
          </div>
    </form>
    </ng-container>

    </mdb-tab>
    <mdb-tab heading="<i class='fas fa-receipt'></i> Recibos">
      <app-receipts [receipts]="receipts"
      [entity]="'expensereceipt'"
      [entity_endpoint]="'expensesreceipts'"
      [parent_entity]="data.entity"
      [readonly]="disableChanges()"
      [concept_suffix]="'Cobro '+(data.entity_label | titlecase)+': '" [register_number]="expense?.number" [register_id]="expense?.id" [typepaymentmethods]="dropdowndata?.typepaymentmethod" (loading)="loading = $event"></app-receipts>
    </mdb-tab>
    </mdb-tabset>
</div>

-->