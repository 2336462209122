import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  /**
   * Array con los permisos del usuario
   */
   private permissions: Array<string>;

   /**
    * Flag para la pantalla de Cargando Aplicación mientras se hace la petición de permisos al API
    */
   private checking_permissions = new Subject<boolean>();

   constructor() { }

   /**
    * Asigna el array con los permisos obtenidos del API a la propiedad de la clase
    * @param p Array de permisos
    */
   setPermissions(p: Array<string>): void {
     this.permissions = p;
   }

   /**
    * Devuelve el array de permisos
    */
   getPermissions(): Array<string>{
    return  this.permissions;
   }


   /**
    *
    * @param valor :se asigna a la propiedad de clase para que se active/desactive la pantalla de Cargando Aplicación
    */
   setCheckPermissions(valor: boolean){
     this.checking_permissions.next(valor);
   }

   /**
    * Devuelve el valor true/false como Observable del flag para activar la pantalla de Cargando Aplicación
    */
   getCheckPermissions(): Observable<boolean>{
     return this.checking_permissions.asObservable();
   }


   /**
    * Comprueba si en el array de permisos está permiso p
    * @param p permiso a comprobar ('list_users', 'modify_workorder', etc)
    */
   checkPermission(p: string): boolean{
     if(this.permissions){
      if(this.permissions.find(permission => permission == p)) {
        return true;
      }
    /*  for (let index = 0; index < this.permissions.length; index++) {
       if(this.permissions[index]==p){
         return true;
       }
     } */
   }
     return false;
   }






 }
