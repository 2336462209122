import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { ProviderInterface } from 'src/app/shared/interfaces/data/provider-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { AddressesParamsInterface } from 'src/app/shared/interfaces/component-params/addresses-params-interface';
import { catchError } from 'rxjs/operators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { AppConfigService } from '../../services/app-config.service';
import { LockService } from '../../services/lock.service';
import { UserPermissionsService } from '../../services/user-permissions.service';

@Component({
  selector: 'app-repairman',
  templateUrl: './repairman.component.html',
  styleUrls: ['./repairman.component.scss']
})
export class RepairmanComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data;

  private formFunctions = new FormFunctions;
  public loading: boolean = false;
  public formProvider: FormGroup;

  public provider: ProviderInterface;

  public id: number;
  private subscriptions: Array<Subscription> = [];

  public heading = "<i class='fas fa-info-circle'></i> Datos generales";

  public addresses: Array<any>;

  public provider_addresses_params: AddressesParamsInterface = {
    addresses: [],
    parent_id: null,
    address_entity: 'provideraddress',
    parent_id_field: 'provider_id',
    entity_endpoint: 'providersaddresses',
  }


  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  constructor(private _api: ApirequestService, private _lock: LockService, private _buttons: ActionButtonsService, private _notification: NotificationService, private _apifunctions:ApiFunctions, private _appconfig: AppConfigService, private fb:FormBuilder, private _userpermissions: UserPermissionsService) {

  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void {
    console.log(this.data)
    this.formProvider = this.formFunctions.getForm(this.data.entity);
    this.id = this.data.id;
    this.provider_addresses_params.parent_id = this.id;
    this.getData();
  }

  private getData() {
    this.loading = true;
    this.subscriptions.push(
      this._api.get(this.data.saveEndpoint + '/' + this.id).subscribe(
        response => {
          this.checkLock(response.response);
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }


  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.provider = apiresponse.provider;

    this.addresses = this.provider.provideraddress;
    this.provider_addresses_params.addresses = this.addresses;
    console.log(this.addresses)

    this.tab.tabData.headerData = this.provider.name;

    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formProvider = undefined;
          setTimeout(() => {
            this.formProvider = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formProvider, this.provider);
      this.formProvider.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formProvider, this.provider);

     this.subscriptions.push(
        this.formProvider.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }

  disableChanges(): boolean {
    let readonly = false;
    if(!this._userpermissions.checkPermission('modify_providers') || !this.lockdata?.unblock) {
      readonly = true;
    }

    return readonly;
  }


  public onSubmit(): void {

    this.formProvider.markAllAsTouched();

    if (this.formProvider.valid) {
      this._buttons.setLoading(true);

      const params = {};
      this.formFunctions.fillFormParams(this.formProvider, this.data.entity, params);

      console.log(params)

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          resp => {
            this.tab.modifiedData = false;
            this._buttons.reloadListTab(this.data.saveEndpoint, this.data.entity_type);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }


}
