import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';


import { ErrorHandler, Injectable } from '@angular/core';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApirequestService } from './request/apirequest.service';

import { UserPermissionsService } from '../main/services/user-permissions.service';
import { NotificationService } from '../main/services/notification.service';


@Injectable({
  providedIn: 'root'
})

export class Interceptor implements HttpInterceptor {


  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private _api: ApirequestService, private _userPermissions: UserPermissionsService, private _notification: NotificationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      /**
       * withCredentials: true -> envía al servidor la Cookie con el token
       */

      //DESARROLLO: sessionStorage
    /*   let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${sessionStorage.getItem('token')}`
      })

      if(req.url.includes('/send')){
        headers = new HttpHeaders({
          'Authorization' : `Bearer ${sessionStorage.getItem('token')}`
        })
      }

      if(!req.url.includes('storage/')) {
          req = req.clone({headers:
            headers
          });
      } */

      if(!req.url.includes('googleapis')) {
            //PRODUCCION: COOKIES
            let headers = new HttpHeaders({
              'Content-Type': 'application/json',
              });

            //para el envío de email (ruta '/send'), la cabecera content-type no se envía
            if(req.url.includes('/send')){
              headers = new HttpHeaders({});
            }

            //para todas las rutas que no sean de documentos (storage), se envían cabeceras y credenciales
            if(!req.url.includes('storage/')) {
              req = req.clone({headers: headers, withCredentials: true});
              }
      }



      return next.handle(req).pipe(
        map(
          resp => {
              if (resp instanceof HttpResponse) {
              //respuesta del api correcta

              //si la petición es logout se limpian las notificaciones
              if(req.url.includes('logout')) {
                 this._notification.clearAlerts();
               }

                return resp;
              }
            }
        ),
        catchError((err: HttpErrorResponse) => {

           console.log(err)
           return this.errorHandler(err, req, next);

        })
      );

    }


    /**
     * Manejador de Errores
     * @param err: Objeto con el objeto de error Http que devuelve el api
     * @param req: petición http que ha producido el error
     * @param next: manejador de peticiones http del interceptor
     */
    errorHandler(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if(req.url.includes('appconfig')) {
        //si el error se produce en la comprobación de permisos, roles, etc del usuario (recarga de página)
        switch (err.status) {
          case 401:
            //intenta refrescar el token
            return this.refrescarToken(req, next);
            break;
          default:
            //cierra aplicación con flag para mostrar modal de sesión caducada
            this.closeApp(true);
            break;
          }
        }
        else {

          if(req.url.includes('auth') || req.url.includes('userlogin')) {
          /**
            * Errores de autenticación
            */
            switch (err.status) {
              case 0:
              case 401:
              case 422:
              case 500:
                //cierra aplicación directamente sin modal de error
                  this.closeApp();
                break;
              default:
                //cierra aplicación con flag para mostrar modal de sesión caducada
                this.closeApp(true);
                break;
            }
          } else {

          /**
            * Errores de la aplicación
            */
          switch (err.status) {
            case 400:
              //sólo se muestra notificación si el error no es al bloquear registro

              //si el error esde autenticación muestra modal de fun de sesion
              //si no sólo muestra alerta en la aplicación
              if(!req.url.includes('locks')) {
                if(err.error.response.auth) {
                  this.closeApp(true);
                } else {
                  this._notification.notificacionError(err);
                }
              }
            break;
            case 401:
              //error 401 sólo se devuelve al caducar el token -> se intenta refrescar
              return this.refrescarToken(req, next);
            break;
            case 403:
              if(!req.url.includes('locks')) {
                  //error de permisos del usuario
                  this.closeApp(true, true);
              }
            break;
            case 0:
              //error de servidor, muestr fin de sesión
              this.closeApp(true);
            break;
            case 404:
            case 422:
            case 500:
              this._notification.notificacionError(err);
            break;
            default:
              this.closeApp(true);
            break;
          }
              }
        }

      return throwError( err );
    }



    /**
     * Cierra la aplicación
     * @param showmodal flag para mostrar el modal de error
     * @param unauthorized flag para que el modal de error muestre error de privilegios (si no se pasa el modal de error cerrará sesión)
     */
    private closeApp(showmodal?: boolean, unauthorized?:boolean){


      this._userPermissions.setCheckPermissions(false);

      this._notification.clearAlerts();
      if(showmodal) {
        this._notification.modalDinamico(unauthorized);

      } else {
        //sessionStorage.clear();
        this.router.navigateByUrl('/login');
      }



    }


    /**
     * Cuando al enviar una petición el token ha caducado,
     * se intenta renovar el token y se repite la petición
     */
    private refrescarToken(request: HttpRequest<any>, next: HttpHandler) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this._api.refreshToken().pipe(
          switchMap((resp: any) => {
            this.isRefreshing = false;
            sessionStorage.setItem('pgabgid', resp.response.auth.token)
            this.refreshTokenSubject.next(resp.response.auth.token);
            return next.handle(request);
          }),
          catchError((err: HttpErrorResponse) => {

            return this.errorHandler(err, request, next);
          }
          )
        );

      } else {

        //si está en marcha la petición de refresco del token cuando se manda otra petición, ésta espera a tener el nuevo token
        return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(jwt => {
              return next.handle(request);
            })
          );
      }
    }













    //COMENTAR PARA SUBIR A PRODUCCION
    /* private refrescarToken(request: HttpRequest<any>, next: HttpHandler) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this._api.refreshToken().pipe(
          switchMap((resp: any) => {
            this.isRefreshing = false;
            sessionStorage.setItem('token', resp.response.auth.token)
            sessionStorage.setItem('pgabgid', resp.response.auth.token)
            this.refreshTokenSubject.next(resp.response.auth.token);
            return next.handle(this.addNewToken(request));
          }));

      } else {

        //si está en marcha la petición de refresco del token cuando se manda otra petición, ésta espera a tener el nuevo token
        return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
            return next.handle(this.addNewToken(request));
          }));
      }
    }
    private addNewToken(request) {
      // Get access token from Local Storage
      const accessToken = sessionStorage.getItem('token');
      // If access token is null this means that user is not logged in
      // And we return the original request
      if (!accessToken) {
          return request;
      }
      // We clone the request, because the original request is immutable
      return request.clone({
          setHeaders: {
              'Content-Type' : 'application/json',
              Authorization: `Bearer ${ accessToken }`
          }
      });
    } */

}
