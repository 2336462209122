import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { Tab } from '../../tab-models/tab.model';
import { FirmsService } from '../../services/firms.service';
import { TabService } from '../../services/tab.service';
import { NotificationService } from '../../services/notification.service';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmDeleteComponent } from '../dynamic-modals/confirm-delete/confirm-delete.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { DynamicListFiltersComponent } from '../dynamic-list-filters/dynamic-list-filters.component';
import { LicenseService } from '../../services/license.service';
import { UserService } from '../../services/user.service';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { ENUM_HISTORY_DATE_FILTER } from 'src/app/shared/enumerates/enum-history-date-filter';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss']
})
export class DynamicListComponent implements OnInit, OnDestroy {
  @Input() tab: Tab
  @Input() data: any
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;
  @ViewChild('dynamicFilters', { static: false }) dynamicFilters: DynamicListFiltersComponent;

  public contextMenuPosition = { x: '0px', y: '0px' };


  private orderfield = '';
  private ordertype = '';
  protected subscriptions: Array<Subscription> = [];

  public datos: Array<any>;

  public total = 0;
  public pag_actual = 0;
 // public pageSize = 10;
 // public pageSizeOptions = [5, 10, 25, 100];

  public loading = false;

  public selectedRow = -1;
  protected register = null;


  protected filters: any

  protected date_formats = new DateFormats;

  modalRef: MDBModalRef | null = null;
  modalRefMaintenance: MDBModalRef | null = null;
  constructor(protected _dropdown: DropdownService, protected modalService: MDBModalService, protected _api: ApirequestService, protected _tabs: TabService, private _firms: FirmsService, private _license: LicenseService, protected _notificacion: NotificationService, private _buttons: ActionButtonsService, protected _user: UserService) {

  }


  ngOnInit(): void {
    if(this.data.listParams.filters?.dropdowns) {
      this._dropdown.getDropdownsAPI(this.data.listParams.filters.dropdowns);
    }

    this.filters = {[this.data.entity] :{}};





    /* if(this.data.tab_filters) {
      this.data.filtersValues = {...this.data.tab_filters};

    } */



    if(this.data.filtersValues) {
      if(!this.data.tab_filters) {
        this.data.tab_filters = {...this.data.filtersValues};
      }
      this.addFilters();
    } else {

      //si el listado tiene filtro historico y ningun otro filtro, se pone el filtro historico
      if(this.data.listParams.history_date_filter) {
          if(this._firms.getActiveFirmOptions()?.[this.data.listParams.history_date_filter]) {

              //filtro historico
              let history_filter = {
                [ENUM_HISTORY_DATE_FILTER[this.data.listParams.history_date_filter]]: {
                  from: this.date_formats.formatoSave(this.date_formats.fromDate(this._firms.getActiveFirmOptions()[this.data.listParams.history_date_filter])),
                  to: this.date_formats.formatoSave(this.date_formats.getCurrentDate())
                }
              };

              //filtro en la propia entidad(tabla)
              this.data.filtersValues = {
                [this.data.entity]: history_filter
              }

              this.addFilters();
          }

      }
    }

    this.loadData();

  }

  ngOnDestroy(): void {
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
  }

  addFirmFilter() {
    if(this._firms.getActiveFirm() != -1) {
        this.filters[this.data.entity]['firm_id'] = this._firms.getActiveFirm();
    }
  }

  addTypeProviderFilter() {
    this.filters[this.data.entity]['typeprovider_id'] = this.data.entity_type
    /* if (this.filters) {

      if (!this.filters[this.data.entity]) {
        this.filters[this.data.entity] = {}
      }
      this.filters[this.data.entity]['typeprovider_id'] = this.data.listParams.entity_type

    } else {

      this.filters = {}
      this.filters[this.data.entity] = {}
      this.filters[this.data.entity]['typeprovider_id'] = this.data.listParams.entity_type
    } */
  }

  addFilters(): void {
    Object.keys(this.data.filtersValues).forEach(
      entity => {
        if(entity === this.data.entity) {
          this.filters[this.data.entity] = {...this.filters[this.data.entity],...this.data.filtersValues[entity]};

        } else {
          this.filters[entity] = this.data.filtersValues[entity];
        }
      }
    )

  }

  loadData(page?:string): void{
    this.selectedRow = -1;
    this.register = null;
    this.loading = true;
    this.datos = [];
    if(this.data.listParams.filter_firm) {
      this.addFirmFilter();
    }

    if (this.data.entity_type != null) {
      this.addTypeProviderFilter();
    }





    //Construcción de la url y sus parámetros
    let newurl = new URL(this._api.getAPIEndpoint()+this.data.listParams.list_entity)

    //Limit
    newurl.searchParams.set('limit', this.data.listParams.limit)

    //Pagina
    if(page){
      newurl.searchParams.append('page', page)
    }

    //Orden
   // if(this.orderfield !== '' && this.ordertype !== '') {
      /*let order = {};
      order['field']='number';
      order['type']='asc';
      newurl.searchParams.append('order', JSON.stringify(order))*/
    //}

    //Filtros
    if(this.filters) {
      newurl.searchParams.append('filters', JSON.stringify(this.filters))
    }




    this.subscriptions.push(
      this._api.get(this.data.listParams.list_entity+newurl.search).subscribe(
      data=> {
        this.datos=data.response[this.data.entity].data;

        this._license.setLicenseData(data.response.accountant.license);

       // this._accountants.setAccountants(data.response.accountant);

        this.total = data.response[this.data.entity].total;
        //if(this.data.filtersValues){
          this.tab.tabData.header_counter = this.total;
        //}
        this.pag_actual = data.response[this.data.entity].current_page -1;
        this.loading = false;

      },
      error=>{
        console.log(error)
        this.loading = false;
      }
    )
    );
  }

  changePage(pag){
    let p = pag.pageIndex + 1;
    this.loadData(p.toString())
  }

  applyFilters(ev) {
    if(this.data.filtersValues) {
      //this.data.filtersValues = null;
    }
    if(ev === null){
      this.data.filtersValues = null;
      this.filters = {[this.data.entity] :{}};
    } else {
      this.filters = ev;
      if(!this.filters[this.data.entity]){
        this.filters[this.data.entity] = {};
      }

       this.data.filtersValues = {...this.filters};
    }

    this.data.tab_filters = {...this.filters};


    this.loadData()
  }

  selectRegister(registro,position) {

      if (this.selectedRow == position) {
        this.selectedRow = -1;
        this.register = null;
        this.tab.deleteButton = false;

      } else {
        this.register = registro;
        this.selectedRow = position;
        if(!this.tab.tabData?.listParams?.disable_delete) {
          this.tab.deleteButton = true;
        }
      }

  }

  onContextMenu(event: MouseEvent, registro,pos) {
    event.preventDefault();
      this.selectedRow = pos;
      this.register = registro;

      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': registro };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
  }

  editRegister(register): void{
    if(this.data.modal_form) {

      this.modalRefMaintenance = this.modalService.show(this.data.modal_form, {
              keyboard: false,
              ignoreBackdropClick: true,
              class: this.data.modal_class,
              data: {
                header: this.data.entity_label,
                register: register,
                entity: this.data.entity,
                endpoint: this.data.saveEndpoint
              }
            })

        this.subscriptions.push(
          this.modalService.closed.subscribe(() => this.modalRefMaintenance.content.onClosed())
        );

    } else {
      if(this.data.entity_type) {
        this._tabs.addTab(this.data.entity, register, this.data.entity_type);
      } else {
        this._tabs.addTab(this.data.entity, register);
      }
    }

  }

  confirmDelete(){
    /**
    * Abre el modal y le pasa datos y configuración
     */
    this.modalRef = this.modalService.show(ConfirmDeleteComponent, {
        keyboard: false,
        ignoreBackdropClick: true,
        class: 'modal-dialog',
        data: {
          header: 'Eliminar Registro',
          id: this.register.id,
          register_data: [this.register.number],
          entity: this.data.entity,
          delete_endpoint: this.data.listParams.list_entity
        }
      })

  /**
    * Recoge los datos emtidos desde el modal
    */
   this.subscriptions.push(
      this.modalRef.content.confirmDelete.subscribe(
          confirm => {
            this.selectedRow = -1;
            this.register = null;
            this.tab.deleteButton = false;
            this._buttons.reloadListTab(this.data.listParams.list_entity);
          }
        )
   )

    /**
     * Escucha el cierre del modal para limpiar variables y suscripciones
     */
    this.subscriptions.push(
      this.modalService.closed.subscribe(() => this.modalRef.content.onClosed())
    );
  }

}
