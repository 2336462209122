import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { TabService } from 'src/app/main/services/tab.service';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';

@Component({
  selector: 'app-unblock-modal',
  templateUrl: './unblock-modal.component.html',
  styleUrls: ['./unblock-modal.component.scss']
})
export class UnblockModalComponent implements OnInit {
  text: string = null;
  emit_unblock: boolean = true;
  lockdata: LockdataInterface;

  private subscriptions: Array<Subscription> = [];
  public unblocking: boolean = false;

  public error_unblock: boolean;

  private unblockConfirm = new Subject<boolean | LockdataInterface | null>();
  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _tabs: TabService) { }

  ngOnInit(): void {
  }

  block(){
    if(!this.emit_unblock) {
      this.unblockConfirm.next(null)
    }

    this.modalRef.hide();
  }

  unblock(){
    if(this.emit_unblock) {
      this.unblockConfirm.next(true);
      this.modalRef.hide();
    } else {
      if(!this.unblocking) {
        this.unblocking = true;

        this.subscriptions.push(
          this._api.lockRegister(this.lockdata).subscribe(
            response => {
              console.log(response)
              if(response.response.lock?.id) {
                this.unblockConfirm.next(response.response.lock)
              }
            },
            error =>{
              console.log(error)
              this.error_unblock = true;
              this.unblocking = false;
            },
            () => {
              console.log('finished')
              this.unblocking = false;
              this.error_unblock = false;
              this.modalRef.hide();
            }
          )
        )

      }
    }
  }

  closeTab(){
    let index = this._tabs.tabs.findIndex(tab => tab.tabActive);
    this.modalRef.hide();
    this.onClosed();
    this._tabs.removeTab(index);
  }


  onClosed() {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe())
    }
    this.error_unblock = false;
  }


}
