import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { Type } from "@angular/core";
import { Tab } from "src/app/main/tab-models/tab.model";
import { LiquidationFilters } from "../../data-classes";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class LiquidationModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'receipt',
    list: 'receipt'
  }

  setModelData(models:any) {
    models.liquidation.icon = this.icon;

    models.liquidation.form_tabData = {
      entity: models.liquidation.entity,
      entity_permissions: models.liquidation.permissions,
      saveEndpoint: models.liquidation.saveEndpoint,
      entity_label: models.liquidation.labels.form,
      entity_modules:models.liquidation.modules,
      new_register_dropdowns:['employee', 'taxe', 'serie'],
      edit_register_dropdowns: ['serie', 'taxe', 'typestate', 'typepaymentmethod']
    }

    models.liquidation.list_tabData = {
      entity: models.liquidation.entity,
      entity_permissions: models.liquidation.permissions,
      entity_modules:models.liquidation.modules,
      listParams: {
        list_entity: models.liquidation.list_entity,
        list_label: models.liquidation.labels.list,
        limit: 10,
        headers: [
          'Número',
          titleCasePipe.transform(models.serie.labels.form),
          titleCasePipe.transform(models.employee.labels.form),
          'Situación',
          'Total'
        ],
        fields: [
          {entity: models.liquidation.entity, field: 'number'},
          {entity: models.serie.entity, field: 'description'},
          {entity: models.employee.entity, field: 'name'},
          {entity: 'typestate', field: 'name'},
          {entity: models.liquidation.entity, field: 'total'}
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new LiquidationFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME.general,
        in_claim: {
          claim_id: null,
          limit: 5,
          headers: [
            'Número',
            titleCasePipe.transform(models.serie.labels.form),
            titleCasePipe.transform(models.employee.labels.form),
            'Situación',
            'Total'
          ],
          fields: [
            {entity: models.liquidation.entity, field: 'number'},
            {entity: models.serie.entity, field: 'description'},
            {entity: models.employee.entity, field: 'name'},
            {entity: 'typestate', field: 'name'},
            {entity: models.liquidation.entity, field: 'total'}
          ],
        }
      }
    }
  }


  getNewTab(component: Type<any>, entity_data): Tab {
    let tabData = {...entity_data.form_tabData};
    tabData.newRegister = true;
    tabData.noRecover = true;

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>Nueva "+titleCasePipe.transform(entity_data.labels.form),
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
      )
  }

}

