import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserPermissionsService } from '../services/user-permissions.service';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionsDirective implements OnInit {
 /*  @Input() permission: string;
 */

 private permission: string

  @Input()
  set appPermissions(permission: string) {
    this.permission = permission;
    this.viewContainer.createEmbeddedView(this.templateRef)
    this.updateView();
  }
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _userpermissions: UserPermissionsService) { }

  ngOnInit(): void {
   // throw new Error('Method not implemented.');
  }

  updateView(){
    this.viewContainer.clear();
    if(this._userpermissions.checkPermission(this.permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    }
  }


}
