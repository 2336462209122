import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MDBModalRef, MDBModalService, MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { selectRequired } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { DetailLinesComponent } from '../../components/detail-lines/detail-lines.component';
import { DynamicListSelectionComponent } from '../../components/dynamic-list-selection/dynamic-list-selection.component';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { TitleCasePipe } from '@angular/common';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-new-budget',
  templateUrl: './new-budget.component.html',
  styleUrls: ['./new-budget.component.scss']
})
export class NewBudgetComponent implements OnInit {
  @Input() tab: Tab
  @Input() data: any

  @ViewChild('listSelection', { static: true }) listSelection!: DynamicListSelectionComponent;
  @ViewChild('detailComponent', { static: true }) detailComponent!: DetailLinesComponent;

  private subscriptions: Array<Subscription> = [];

  public loading = false;

  formNewBudget: FormGroup;


  private dateformats = new DateFormats;
  public datepicker_options = this.dateformats.opcionesMDBDatePicker;

  private utils = new PGAUtils;

  private formFunctions = new FormFunctions;


  public hasClaim: boolean;

  public dropdowns: DropdownsInterface;

  public provider_types: Array<any> = [{id: -1, name: 'Particular'}];
  public repairmen: Array<any> = [];
  public administrators: Array<any> = [];
  public companies: Array<any> = [{ value: -1, label: 'Sin asignar', subcompanies:[] }]
  public subcompanies: Array<any> = [{ value: -1, label: 'Sin asignar' }]


  addresses = [];

  public detail_headers: Array<any> = [
    {header: 'Exp.', class: 'buttons-column'},
    {header: 'Código', class: 'code-column'},
    'Descripción',
    { header: 'Cantidad', class: 'numbers-column'},
    {header: 'Precio', class: 'numbers-column'},
    {header:'%Descuento', class: 'percent-column'},
    {header:'%Impuesto', class: 'percent-column'}
  ]
  public detail_fields_order: Array<any> = [
    {
      control: 'type_export_line_id',
      type: 'checkbox',
      default:  0,
      disabled: false
    },
    {
      control: 'code',
      type: 'text',
      default: '',
      disabled: false,
      addKey: true,
    },
    {
      control: 'description',
      type: 'text',
      default: '',
      disabled: false,
      validate:true,
      validators:[Validators.required],
      error_msg: 'Campo obligatorio',
      addKey: true,
    },
    {
      control: 'quantity',
      type: 'number',
      default: 1,
      disabled: false,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio'
    },
    {
      control: 'price',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio'
    },
    {
      control: 'discount',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio'
    },
    {
      control: 'taxe_id',
      type: 'select',
      dropdown: 'taxes',
      field_value: 'value',
      addKey: true,
      clear: false,
      listen_changes: true,
      disabled: true,
      default: 1,
    }
  ]

  public master_object = {
    typecondition_id: 1,
    type_export_id: 1,

  }

  modalRefClaim: MDBModalRef | null = null;
  modalRefClient: MDBModalRef | null = null;
  modalRefOpenRegister: MDBModalRef | null = null;

  public claim_entity = this._entity.entities.claim;
  public user_entity = this._entity.entities.user;
  public client_entity = this._entity.entities.client;
  public guild_entity = this._entity.entities.guild;
  public serie_entity = this._entity.entities.serie;
  public company_entity = this._entity.entities.company;

  constructor(private modalService: MDBModalService, private _buttons: ActionButtonsService, private _apifunctions: ApiFunctions, private fb: FormBuilder,
     private _api: ApirequestService, private _notification: NotificationService, private _tabs: TabService, private _entity: EntityService, private _titleCasePipe: TitleCasePipe, private _dropdowns: DropdownService) {

  }

  ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  setBudgetForm(){
    this.formNewBudget = this.fb.group({
      claim_id: [null, []],
      claim_number: [{value:'', disabled: false}, []],
      claim_address: [{value:'', disabled: true}, []],
      client_id: [null, [Validators.required]],
      client_name: ['', [Validators.required]],
      clientaddress_id: [{value: null, disabled: true}, []],
      provider_id: [{value: -1, disabled: true}, [selectRequired()]],
      subprovider_id: [-1, []],
      provider_name: [{value:'Particular', disabled: true}, []],
      typeprovider:[{value: -1, disabled: false}],
      guild_id: [-1, []],
      serie_id: [1, []],
      description: ['', []],
      footer: ['', []],

      total_cost: [{value: 0.00, disabled: true}],
      total_price: [{value: 0.00, disabled: true}],
      total_taxe: [{value: 0.00, disabled: true}],
      total: [{value: 0.00, disabled: true}],
    })


    this.subscriptions.push(
      this.formNewBudget.controls['provider_id'].valueChanges.subscribe((id) => {

        this.formNewBudget.controls.subprovider_id.setValue(-1)

        if(this.selected_typeprovider == 1) {

          if(id > 0 ){
             this.subcompanies = [...[{value:-1,label:'Sin asignar'}],...this.companies.find(c => c.value === id)?.subcompanies.map(subcompanies => { return { value: subcompanies.id, label: subcompanies.name } })];
          }
        }
      })
    );


    this.subscriptions.push(
      this.formNewBudget.controls.typeprovider.valueChanges.subscribe(
        type => {

          switch (type) {
            case -1:
              this.formNewBudget.controls.provider_id.setValue(-1);
              this.formNewBudget.controls.provider_id.disable();
              this.formNewBudget.controls.subprovider_id.disable();

              break;
            case 1:
              if(this.formNewBudget.controls.provider_id.disabled) {
                this.formNewBudget.controls.provider_id.enable();

              }

              this.formNewBudget.controls.subprovider_id.enable();
              this.formNewBudget.controls.provider_id.setValue(-1);
              break;
            case 2:
            case 3:
              if(this.formNewBudget.controls.provider_id.disabled) {
                this.formNewBudget.controls.provider_id.enable();
              }
              this.formNewBudget.controls.subprovider_id.disable();
              this.formNewBudget.controls.provider_id.setValue(-1);
            break;
          }
        }
      )
    );
  }

  ngOnInit() {

    this.loadDropdowns()
    this.setBudgetForm()

    //Si es presupuesto de expediente se cargan los datos necesarios del padre
    if(this.data.parentData) {
      this.hasClaim = true;
      this.formNewBudget.controls.claim_id.setValue(this.data.parentData.id);
      this.formNewBudget.controls.claim_id.markAsDirty();
      this.formNewBudget.controls.claim_number.setValue(this.data.parentData.number);

      this.formNewBudget.controls.client_id.setValue(this.data.parentData.client.id);
      this.formNewBudget.controls.client_id.markAsDirty();
      this.formNewBudget.controls.client_name.setValue(this.data.parentData.client.name_complete);


      this.addresses = this.data.parentData.client.clientaddress;

      if(this.data.parentData.policy) {
        this.formNewBudget.controls.claim_address.setValue(this.data.parentData.policy.address_complete);
      } else {
        this.formNewBudget.controls.claim_address.setValue(this.addresses.find(address => address.id === this.data.parentData.clientaddress_id).address_complete);
      }

      if(this.data.parentData.provider){
        this.formNewBudget.controls.provider_id.setValue(this.data.parentData.provider_id);
        this.formNewBudget.controls.provider_id.markAsDirty();

        let provider_name = this.data.parentData.provider.name;
        if(this.data.parentData.subprovider) {
          provider_name += ' ('+this.data.parentData.subprovider.name+')';
          this.formNewBudget.controls.subprovider_id.setValue(this.data.parentData.subprovider_id);
          this.formNewBudget.controls.subprovider_id.markAsDirty();
        } else {
          this.formNewBudget.controls.subprovider_id.setValue(null);
          this.formNewBudget.controls.subprovider_id.markAsPristine();
        }


        this.formNewBudget.controls.provider_name.setValue(provider_name);
      }


    }


    this.formNewBudget.valueChanges.subscribe(
      change => {
        this.tab.modifiedData = true;
      }
    )


  }

  //carga los datos de los desplegables
  loadDropdowns() {

    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          this.dropdowns = this.utils.objectDeepCopy(response.response.dropdown);
          this.dropdowns.taxe = this.dropdowns.taxe.filter(e => e.enabled == true);
          this.provider_types = [...this.provider_types, ...this.dropdowns.typeprovider];
          this.repairmen = this.dropdowns.repairman;
          this.administrators = this.dropdowns.administrator;
          this.companies = [...this.companies, ...this.dropdowns.company_with_subcompanies?.map(comp => { return { value: comp.id, label: comp.name, subcompanies: comp.subcompanies } })];

          this.loading = false;
        },
        error => {

          this.formNewBudget.disable()
          this.loading = false;
        }
      )
    )
  }

  //check de expediente
  changeClaim(ev: MdbCheckboxChange){
    this.formNewBudget.controls.claim_id.markAsPristine();
    this.formNewBudget.controls.provider_id.markAsPristine();
    this.formNewBudget.controls.subprovider_id.markAsPristine();

    if(!this.hasClaim) {
      //si se desmarca el check de expediente se resetan los campos de expediente y proveedor
      this.formNewBudget.controls.claim_id.setValue(null);
      this.formNewBudget.controls.typeprovider.setValue(-1);
      this.formNewBudget.controls.claim_number.setValue('');
      this.formNewBudget.controls.claim_address.setValue(null);
      this.formNewBudget.controls.provider_name.setValue('Particular');
      //y se habilita la selección de cliente
      this.formNewBudget.controls.clientaddress_id.enable();

      //se elimina la validación de expediente
      this.formNewBudget.controls.claim_number.removeValidators([Validators.required])

      //obligatorio seleccionar dirección del cliente
      this.formNewBudget.controls.clientaddress_id.addValidators([Validators.required])

      this.formNewBudget.controls.client_name.enable()

    } else {
      //si se marca el expediente se deshabilita la selección de cliente
      this.formNewBudget.controls.client_name.disable();
      this.formNewBudget.controls.clientaddress_id.disable();
      this.formNewBudget.controls.provider_id.disable();

      //obligatorio seleccionar un expediente
      this.formNewBudget.controls.claim_number.addValidators([Validators.required])

      //se elimina la validación de expediente
      this.formNewBudget.controls.clientaddress_id.removeValidators([Validators.required])
    }


    this.formNewBudget.controls.claim_number.updateValueAndValidity()
    this.formNewBudget.controls.clientaddress_id.updateValueAndValidity()
  }


  //abre la selección de expediente
  selectClaim(){

    this.modalRefClaim = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.claim_entity.labels.form,
              list_headers: [
                'Nº Aviso',
                'Número',
                'Fecha encargo',
                'Proveedor',
                this._titleCasePipe.transform(this.client_entity.labels.form),
                'Dirección',
                'Tramitador',
              ],
              list_fields: [
                {entity: this.claim_entity.entity, field: 'number'},
                {entity: this.claim_entity.entity, field: 'number_provider'},
                {entity: this.claim_entity.entity, field: 'order_date'},
                {entity: this.company_entity.entity, field: 'name', custom: true, show_with: {entity: 'sub'+this.company_entity.entity, field:'name'}},
                {entity: this.client_entity.entity, field: 'name_complete'},
                {entity: 'clientaddress', field: 'address_complete'},
                {entity: this.user_entity.entity, field: 'name'}
              ],
              list_entity: this.claim_entity.entity,
              list_endpoint: this.claim_entity.list_entity,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                }
              ]
            }
        })

    //recoge los datos del expediente seleccionado
    this.modalRefClaim.content.selectedRegister.subscribe(register => {
      this.addresses = [];

      //se quitan validaciones de direccion de cliente
      this.formNewBudget.controls.clientaddress_id.removeValidators([Validators.required]);
      this.formNewBudget.controls.clientaddress_id.setValue(null);
      this.formNewBudget.controls.clientaddress_id.markAsPristine();
      this.formNewBudget.controls.clientaddress_id.updateValueAndValidity();

      //datos expediente
      this.formNewBudget.controls.claim_id.setValue(register.id);
      this.formNewBudget.controls.claim_id.markAsDirty();
      this.formNewBudget.controls.claim_number.setValue(register.number);

      //datos cliente
      this.formNewBudget.controls.client_id.setValue(register.client.id);
      this.formNewBudget.controls.client_id.markAsDirty();
      this.formNewBudget.controls.client_name.setValue(register.client.name_complete);

      //si el expediente es de compañía se muestra la dirección de la compañía, si no se muestra la del cliente
      this.addresses = register.client.clientaddress;
      if(register.policy) {
        this.formNewBudget.controls.claim_address.setValue(register.policy.address_complete);
      } else {
        this.formNewBudget.controls.claim_address.setValue(this.addresses.find(address => address.id === register.clientaddress_id)?.address_complete);
      }

      //si el expediente es de compañía se cargan los datos de la compañía
      if(register.provider){
        this.formNewBudget.controls.provider_id.setValue(register.provider_id);
        this.formNewBudget.controls.provider_id.markAsDirty();

        let provider_name = register.provider.name;
        if(register.subprovider) {
          provider_name += ' ('+register.subprovider.name+')';

          this.formNewBudget.controls.subprovider_id.setValue(register.subprovider_id);
          this.formNewBudget.controls.subprovider_id.markAsDirty();
        } else {
          this.formNewBudget.controls.subprovider_id.setValue(null);
          this.formNewBudget.controls.subprovider_id.markAsPristine();
        }

        this.formNewBudget.controls.provider_name.setValue(provider_name);
      }
    })

    //libera memoria del modal de selección de expediente
    this.modalService.closed.subscribe(() => this.modalRefClaim.content.onClosed())

  }

  get selected_typeprovider(): number {
    return this.formNewBudget?.controls.typeprovider.value
  }

  get selected_claim_id(): number {
    return this.formNewBudget?.controls.claim_id.value
  }

  newClient(){
    this._tabs.addTab('newclient')
  }
  //abre la selección de cliente
  selectClient(){

    this.modalRefClient = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.client_entity.labels.form,
              list_headers: [
                'Activo',
                'Acceso Web',
                'NIF',
                'Nombre y apellidos',
                'Dirección completa',
                'Teléfono',
                'Móvil',
                'Email',
              ],
              list_fields: [
                { entity: this.client_entity.entity, field: 'enabled', type: 'check' },
                { entity: this.client_entity.entity, field: 'web_access', type: 'check' },
                { entity: this.client_entity.entity, field: 'identification_document' },
                { entity: this.client_entity.entity, field: 'name_complete' },
                { entity: 'defaultaddress', field: 'address_complete' },
                { entity: this.client_entity.entity, field: 'phone' },
                { entity: this.client_entity.entity, field: 'mobile' },
                { entity: this.client_entity.entity, field: 'email' },
              ],
              list_entity: this.client_entity.entity,
              list_endpoint: this.client_entity.saveEndpoint,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                }
              ]
            }
          })

      //recoge los datos del cliente seleccionado
      this.modalRefClient.content.selectedRegister.subscribe(register => {
        this.addresses = [];
        this.formNewBudget.controls.client_id.setValue(register.id);
        this.formNewBudget.controls.client_id.markAsDirty();
        this.formNewBudget.controls.client_name.setValue(register.name_complete);
        this.formNewBudget.controls.clientaddress_id.enable();

        let default_address = register.clientaddress.find(address => address.default === 1);
        this.formNewBudget.controls.clientaddress_id.enable();
        this.formNewBudget.controls.clientaddress_id.setValue(default_address?.id);
        this.formNewBudget.controls.clientaddress_id.markAsDirty();

        this.formNewBudget.controls.clientaddress_id.addValidators([Validators.required]);
        this.formNewBudget.controls.clientaddress_id.updateValueAndValidity();
        this.addresses = register.clientaddress;
      })

      //libera memoria del modal de selección de cliente
    this.modalService.closed.subscribe(() => this.modalRefClient.content.onClosed())
  }


  public onSubmit(): void{

    this.formNewBudget.markAllAsTouched();

    let line_form_error = false;
    this.detailComponent.detailsForms.controls.forEach(
      form => {
        form.markAllAsTouched();
      }
    )
    for (let index = 0; index < this.detailComponent.detailsForms.controls.length; index++) {
      if(this.detailComponent.detailsForms.controls[index].invalid) {
        line_form_error = true;
        break;
      }
    }

    if(this.formNewBudget.valid && !line_form_error) {
      this._buttons.setLoading(true);

      let params ={};

      //datos presupuesto
      this.formFunctions.fillFormParams(this.formNewBudget, this.data.entity, params);


      //lineas
      params['budgetlines'] = [];
      this.detailComponent.pushDetailsToMaster(params['budgetlines']);
      if(params['budgetlines'].length > 0) {
        params[this.data.entity]['total'] = this.detailComponent.total_lineas
        params[this.data.entity]['total_price'] = this.detailComponent.total_price
        params[this.data.entity]['total_taxe'] = this.detailComponent.total_taxe
      }



      console.log(params)


      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {

            let budget = response.response.budget;


            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
             this.modalRefOpenRegister = this.modalService.show(OpenRegisterComponent, {
                keyboard: false,
                ignoreBackdropClick: true,
                class: 'modal-dialog',
                data: {
                heading: 'Registro Creado',
                text: 'Registro creado correctamente, ¿desea acceder al registro?',
                  register: budget,
                  form_entity: this.data.entity,
                }
              })

              this._tabs.removeTabByTabId(this.tab.tabId)
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      )
    }



  }

  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
  }

}
