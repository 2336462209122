<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <div style="margin: 10px;">
    <form [formGroup]="clientForm">
      <div class="row mt-2">
        <div class="col-lg">
          <div class="md-form md-outline">
              <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
              <label for="identification_document" class="">CIF</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientname" formControlName="name" class="form-control form-control-sm" placeholder="">
            <label for="clientname" class="">Nombre</label>
            <mdb-error *ngIf="!clientForm.controls.name.valid && (clientForm.controls.name.dirty || clientForm.controls.name.touched)">Campo obligatorio</mdb-error>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientsurname" formControlName="surname" class="form-control form-control-sm"  placeholder="">
            <label for="clientsurname" class="">Apellidos</label>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="clientphone" formControlName="phone" class="form-control form-control-sm"  placeholder="">
            <label for="clientphone" class="">Teléfono</label>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="clientmobile" formControlName="mobile" class="form-control form-control-sm"  placeholder="">
            <label for="clientmobile" class="">Móvil</label>
          </div>
        </div>
      </div>
    </form>
  </div>

<!--DIRECCIONES-->
  <div class="divCustomSection">
    <i class='fas fa-home-alt mr-2'></i> Dirección completa
  </div>
  <div style="margin: 10px;">
    <form [formGroup]="addressForm">
      <div class="row mt-3">
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="firmddress"formControlName="address" class="form-control form-control-sm" placeholder="">
            <label for="firmddress" class="">Dirección</label>
          </div>
        </div>
        <div class="col-lg" formGroupName="postalcode">
          <div class="row">
            <div class="col">
              <div class="md-form md-outline">
                <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                  <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                </a>
                <input mdbInput type="text" id="clientpostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                <label for="clientpostal_code" class="">Código postal</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="clientpopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                <label for="clientpopulation" class="">Población</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="clientprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                <label for="clientprovince" class="">Provincia</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  
  <!--POLIZAS-->
  <ng-container *appModules="['companies']">
    <div class="divCustomSection">
      <i class='fas fa-file-signature mr-2'></i> Póliza (Opcional)
    </div>
      <div style="margin: 10px;">
        <form [formGroup]="policyForm">
          <div class="row mt-3">
            <div class="col-4">
              <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="policynumber" formControlName="number" class="form-control form-control-sm" placeholder="">
                <label for="policynumber" class="">Número de Póliza</label>
                <mdb-error *ngIf="policyForm.controls.number.invalid && (policyForm.controls.number.dirty || policyForm.controls.number.touched)">Debe introducir un número de Póliza</mdb-error>
              </div>
            </div>


            <div class="col-4">
              <div class="md-form">
                <mdb-select [outline]="true" [options]="companies" mdbValidate [validateSuccess]="false" formControlName="provider_id" placeholder="" [label]="(company_entity.labels.form | titlecase)" ></mdb-select>
                <mdb-error *ngIf="policyForm.controls.provider_id.invalid && (policyForm.controls.provider_id.dirty || policyForm.controls.provider_id.touched)">Debe seleccionar una {{company_entity.labels.form | titlecase}}"</mdb-error>
              </div>
            </div>
            <div class="col-4">
              <div class="md-form">
                <mdb-select [outline]="true" [options]="subcompanies" mdbValidate [validateSuccess]="false" placeholder=""  [label]="'Sub'+company_entity.labels.form" formControlName="subprovider_id"></mdb-select>
              </div>
            </div>

          </div>
          <div class="row mt-3">
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="policyaddress" formControlName="address" class="form-control form-control-sm" placeholder="">
                <label for="policyaddress" class="">Dirección</label>
              </div>
            </div>
            <div class="col-2">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="policypostal_code"formControlName="postal_code" class="form-control form-control-sm" placeholder="">
                <label for="policypostal_code" class="">Código postal</label>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="policypopulation"  formControlName="population" class="form-control form-control-sm" placeholder="">
                <label for="policyprovince" class="">Población</label>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="policypopulation"  formControlName="province" class="form-control form-control-sm" placeholder="">
                <label for="policyprovince" class="">Provincia</label>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <button type="button" mdbBtn color="blue" rounded="true" size="sm" (click)="fillPolicyAddress()">
                <mdb-icon fas icon="copy"></mdb-icon> Copiar Dirección del {{data.entity_label | titlecase}}
              </button>
            </div>
          </div>
        </form>
      </div>

  </ng-container>
</div>


<!--
<div class="container-fluid pt-2">
  <div class="row">
    <div class="col-12 custom-card-padding">
      <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

          <mdb-tab [heading]="heading">
              <form [formGroup]="clientForm">
                  <div class="row">
                      <div class="col-lg-2">
                          <div class="md-form">
                              <input mdbInput type="text" id="identification_document"
                                  formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                              <label for="identification_document" class="">NIF</label>
                          </div>
                      </div>
                      <div class="col-lg">
                          <div class="md-form">
                              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientname"
                                  formControlName="name" class="form-control form-control-sm" placeholder="">
                              <label for="clientname" class="">Nombre</label>
                              <mdb-error
                                  *ngIf="!clientForm.controls.name.valid && (clientForm.controls.name.dirty || clientForm.controls.name.touched)">
                                  Campo obligatorio</mdb-error>
                          </div>
                      </div>
                      <div class="col-lg">
                          <div class="md-form">
                              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientsurname"
                                  formControlName="surname" class="form-control form-control-sm"  placeholder="">
                              <label for="clientsurname" class="">Apellidos</label>
                          </div>
                      </div>
                      <div class="col-lg-2">
                          <div class="md-form">
                              <input mdbInput type="text" id="clientphone"
                                  formControlName="phone" class="form-control form-control-sm"  placeholder="">
                              <label for="clientphone" class="">Teléfono</label>
                          </div>
                      </div>
                      <div class="col-lg-2">
                          <div class="md-form">
                              <input mdbInput type="text" id="clientmobile"
                                  formControlName="mobile" class="form-control form-control-sm"  placeholder="">
                              <label for="clientmobile" class="">Móvil</label>
                          </div>
                      </div>
                  </div>
              </form>



          </mdb-tab>
      </mdb-tabset>

    </div>
  </div>
    <div class="row mt-3">
      <div class="col-md custom-card-padding">

        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'<i class=\'fas fa-home-alt\'></i> Dirección del '+data.entity_label+' (opcional)'">
            <form [formGroup]="addressForm">
              <div class="row mt-3">
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="clientddress"formControlName="address" class="form-control form-control-sm" placeholder="">
                    <label for="clientddress" class="">Dirección</label>
                  </div>
                </div>

              </div>
              <div class="row mt-3" formGroupName="postalcode">
                <!-- <div class="col-1">

                </div> -->
 <!--               <div class="col-md-2">
                  <div class="md-form">
                    <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                      <mdb-icon fas icon="hand-point-up"></mdb-icon>
                    </a>
                    <input mdbInput type="text" id="clientpostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="clientpostal_code" class="">Código postal</label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="clientpopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="clientpopulation" class="">Población</label>
                  </div>
                </div>
                <div class="col-md">
                  <div class="md-form">
                    <input mdbInput type="text" id="clientprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                    <label for="clientprovince" class="">Provincia</label>
                  </div>
                </div>
              </div>
            </form>
          </mdb-tab>
          </mdb-tabset>
      </div>
      <ng-container *appModules="['companies']">
        <div class="col-md custom-card-padding">
          <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

            <mdb-tab heading="<i class='fas fa-file-signature'></i> Póliza (opcional)">
              <form [formGroup]="policyForm">
                <div class="row mt-3">
                    <div class="col-4">
                      <div class="md-form">
                        <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="policynumber" formControlName="number" class="form-control form-control-sm" placeholder="">
                        <label for="policynumber" class="">Número de Póliza</label>
                        <mdb-error *ngIf="policyForm.controls.number.invalid && (policyForm.controls.number.dirty || policyForm.controls.number.touched)">Debe introducir un número de Póliza</mdb-error>
                      </div>
                    </div>


                    <div class="col-4">
                      <div class="md-form">
                        <mdb-select [options]="companies" mdbValidate [validateSuccess]="false" formControlName="provider_id" placeholder="" [label]="(company_entity.labels.form | titlecase)" ></mdb-select>
                        <mdb-error *ngIf="policyForm.controls.provider_id.invalid && (policyForm.controls.provider_id.dirty || policyForm.controls.provider_id.touched)">Debe seleccionar una {{company_entity.labels.form | titlecase}}"</mdb-error>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="md-form">
                        <mdb-select [options]="subcompanies" mdbValidate [validateSuccess]="false" placeholder=""  [label]="'Sub'+company_entity.labels.form" formControlName="subprovider_id"></mdb-select>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-3">
                    <div class="col-md">
                      <div class="md-form">
                        <input mdbInput type="text" id="policyaddress" formControlName="address" class="form-control form-control-sm" placeholder="">
                        <label for="policyaddress" class="">Dirección</label>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="md-form">
                        <input mdbInput type="text" id="policypostal_code"formControlName="postal_code" class="form-control form-control-sm" placeholder="">
                        <label for="policypostal_code" class="">Código postal</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="md-form">
                        <input mdbInput type="text" id="policypopulation"  formControlName="population" class="form-control form-control-sm" placeholder="">
                        <label for="policyprovince" class="">Población</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="md-form">
                        <input mdbInput type="text" id="policypopulation"  formControlName="province" class="form-control form-control-sm" placeholder="">
                        <label for="policyprovince" class="">Provincia</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <button type="button" mdbBtn color="blue" rounded="true" size="sm" (click)="fillPolicyAddress()">
                        <mdb-icon fas icon="copy"></mdb-icon> Copiar Dirección del {{data.entity_label | titlecase}}
                      </button>
                    </div>
                  </div>
            </form>
            </mdb-tab>
          </mdb-tabset>

        </div>
      </ng-container>

    </div>
</div>
-->