import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { DynamicListSelectionComponent } from '../../components/dynamic-list-selection/dynamic-list-selection.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CollapseComponent, humanizeBytes, MdbCheckboxChange, MDBModalRef, MDBModalService, ModalDirective, UploadFile, UploadInput, UploadOutput } from 'ng-uikit-pro-standard';
import {  MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '../../services/notification.service';
import { MailAccountInterface } from 'src/app/shared/interfaces/data/mail-account-interface';
import { Tab } from '../../tab-models/tab.model';
import { EmailInterface } from 'src/app/shared/interfaces/data/email-interface';
import { FileManagerService } from '../../services/file-manager.service';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { SendEmailComponent } from '../../components/dynamic-modals/send-email/send-email.component';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { TabService } from '../../services/tab.service';
import { EntityService } from '../../services/entity.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {
  @Input() tab: Tab;
  @Input() data: any;


  @ViewChild('filters', { static: true }) filters: CollapseComponent;
  @ViewChild(MatMenuTrigger, { static: false }) contextMenu: MatMenuTrigger;

  public contextMenuPosition = { x: '0px', y: '0px' };

  private subscriptions: Array<Subscription> = [];

  private utils = new PGAUtils;

  /* Array de cuentas del usuario */
  public accounts: Array<any> = [];

  /* Controla el spinner mientras se reciben los datos de las cuentas del usuario */
  public loading = true;


  /* Cuenta del usuario seleccionada */
  public selected_account:{
    account: MailAccountInterface,
    folder: string
  } = {
    account: null,
    folder: 'received'
  };

  public selected_email: EmailInterface;


  public emails: Array<EmailInterface>;

  public total = 0;
  public current_page = 0;
  public per_page = '20';


  private dateFunctions = new DateFormats;

  public formFilters: FormGroup;

  public datepicker_options = this.dateFunctions.opcionesMDBDatePicker;
  public read_options = [
    {value:-1, label:'TODOS'},
    {value:1, label:'SÍ'},
    {value:0, label:'NO'},
  ]

  public process_options = [
    {value:-1, label:'TODOS'},
    {value:0, label:'PROCESADOS'},
    {value:1, label:'PENDIENTES'},
  ]

  public has_claim_options = [
    {value:-1, label:'TODOS'},
    {value:0, label:'SIN VINCULAR'},
    {value:1, label:'VINCULADOS'},
  ];


  modalRefMail: MDBModalRef | null = null;
  modalRefClaim: MDBModalRef | null = null;


  public claim_entity = this._entity.entities.claim;
  public user_entity = this._entity.entities.user;
  public client_entity = this._entity.entities.client;
  public company_entity = this._entity.entities.company;

  constructor(private _api: ApirequestService, private modalService: MDBModalService, private _tabs: TabService,private fb: FormBuilder, private sanitizer: DomSanitizer, private _notification: NotificationService, private _filemanager: FileManagerService, private _entity: EntityService, private _titleCasePipe: TitleCasePipe) {
    this.setFormFilters();
  }

  setFormFilters(){
    this.formFilters = this.fb.group({
      claim:[''],
      from:[''],
      to:[''],
      subject:[''],
      linked:[-1],
      has_attacheds:[-1],
      check:[-1],
      read:[-1],
      checkbox: [false],
      date: this.fb.group({
        from:[{ value: this.dateFunctions.getLastWeekDate(), disabled: true}],
        to:[{ value: this.dateFunctions.getCurrentDate(), disabled: true}],
      })
    });

    this.formFilters.controls.checkbox.valueChanges.subscribe(
      value => {
        if(value) {
          this.formFilters.controls.date.enable();
          this.getEmails()
        } else {
          this.formFilters.controls.date.disable();
          this.getEmails()
        }
      }
    )


  }



  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }

  getData(): void {
    this.loading = true;
    const param = {};
    const filter = {};
    filter['enabled'] = 1;
    param['mailaccount'] = filter;

    this.subscriptions.push(
      this._api.get('mailaccounts/user/accounts').subscribe(
        accounts => {
          this.accounts = accounts.response.mailaccount;

          if(this.accounts.length > 0) {
            /* Si el usuario no tiene cuenta predefinida se selecciona la primera */
            if (!this.accounts.find(it => it.default == 1)) {

              this.selected_account.account = this.accounts[0];
            } else {
              this.selected_account.account = this.accounts.find(it => it.default == 1)
            }

            this.getEmails();
          } else {
            this.loading = false;
          }


          //this.loading = false;
        },
        error => {
          this.loading = false;
        }
      ));
  }

  /**
   *
   * @param account cuenta de correo para marcar como activa
   * @param folderIndex carpeta de la cuenta
   */
  setActive(account: MailAccountInterface, folderIndex:number){
    this.setFormFilters()
    this.emails = [];
    this.selected_email = null;

    if(account.id !== this.selected_account.account.id) {
      this.selected_account.account = account;
    }
    switch (folderIndex) {
      case 1:
        this.selected_account.folder = 'received';
        break;
      case 2:
        this.selected_account.folder = 'sent';
        break;

      default:
        this.selected_account.folder = 'received';
        break;
    }

    this.getEmails();
  }


  /****************
   * CARGAR CORREOS
   ***************/

  getEmails(page?:string){
    this.loading = true;
    this.selected_email = null;

    //Construcción de la url y sus parámetros
    let newurl = new URL(this._api.getAPIEndpoint()+this.data.saveEndpoint)

    //Limit
    newurl.searchParams.set('limit', this.per_page)

    const order = {
      field:'date',
      type:'DESC',
    }
    newurl.searchParams.append('order', JSON.stringify(order))

    //Pagina
    if(page){
      newurl.searchParams.append('page', page)
    }

    //newurl.searchParams.append('paginate', JSON.stringify(0))

    const filters: {[key: string]:any} = {
      email: {
        mailaccount_id: this.selected_account.account.id
      }
    }

    filters.email[this.selected_account.folder] = 1;


    this.applyFilters(filters);

    newurl.searchParams.append('filters', JSON.stringify(filters))


    this.subscriptions.push(
      this._api.get(this.data.saveEndpoint+newurl.search).subscribe(
        response => {
          /* this.emails = response.response.email;
          this.total = this.emails.length; */

          this.emails = response.response.email.data;
          this.total = response.response.email.total;
          this.current_page = response.response.email.current_page -1;

          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  changePage(pag){
    this.selected_email = null;
    this.emails = [];
    let p = pag.pageIndex + 1;
    this.getEmails(p.toString())
  }
  getProtocolLogo(id_protocol: number): string {
    let path = 'assets/images/protocols/';

    switch (id_protocol) {
      case 1:
        path += 'logo_asitur.png'
        break;
      case 2:
        path += 'caser_helvetia.png'
        break;
      case 3:
        path += 'homeserve.png'
        break;
      case 4:
        path += 'santa_lucia.svg'
        break;
      case 5:
        path += 'axa.png'
        break;

      default:
        path = null;
        break;
    }
    return path;
  }


  /********
   * FILTROS
   **********/

   searchKey(ev) {
    const key = ev.keyCode || ev.which;
    if (key == 13) {
      this.getEmails()
    }
  }

  filterChanged(ev){
    setTimeout(() => {
      this.getEmails()
    }, 0);

  }

  private applyFilters(filters){
    let form_values = {...this.formFilters.value};

    Object.keys(form_values).forEach(
      field => {
        if(field !== 'checkbox'){
          if(field === 'date') {
            filters.email[field] = {
              from: this.dateFunctions.formatoSave(form_values[field].from),
              to: this.dateFunctions.formatoSave(form_values[field].to)
            }
          } else {
            if(form_values[field] !== -1 && form_values[field] !== '') {

              switch (field) {
                case this.claim_entity.entity:
                  filters.claim = {
                    number: form_values[field]
                  }
                  break;

                default:
                  filters.email[field] = form_values[field];
                  break;
              }

            }
          }
        }
      }
    )


  }

  clearFilters(){
    this.setFormFilters();
    this.getEmails()
  }


  /***********
   * RECIBIR
   ***********/

  receive(){
    this.loading = true;
    this.selected_email = null;

    this.subscriptions.push(
      this._api.get('mailaccounts/' + this.selected_account.account.id + '/download').subscribe(
        response => {
          this.getEmails();
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  /********
   * LEER
   ********/

  selectEmail(email: EmailInterface) {

    if(this.selected_email?.id === email.id) {
      this.selected_email = null;
    } else {

      this.selected_email = email;
      this.selected_email.bodySanitized =  this.sanitizer.bypassSecurityTrustHtml(this.selected_email.body?.replace(/  /g, '<br>'));

      if(!this.selected_email.read) {
        this.markRead(this.selected_email);
      }
    }
  }

  markRead(email: EmailInterface){
    this.loading = true;
    const params = {
      email:{
        read: 1
      }
    };

    this._api.put(this.data.saveEndpoint+'/' + email.id, JSON.stringify(params)).subscribe(
      resp => {
        email.read = true;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    )
  }

  /***********
   * ADJUNTOS
   ************/

  processAttachs(email: EmailInterface){
    this.loading = true
    this.subscriptions.push(
      this._api.get(this.data.saveEndpoint+'/' + email.id + '/download').subscribe(
        response => {
          email.emailattached = response.response.emailattached;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  downloadAttach(email: EmailInterface, attach?: any) {
    console.log(email)
    console.log(attach)
    if(attach) {
      this._filemanager.downloadDocument(attach.path_relative, attach.validate, attach.name, attach.mime);
    } else {
      email.emailattached.forEach(
        file => {
          this._filemanager.downloadDocument(file.path_relative, file.validate, file.name, file.mime);
        }
      )
    }

  }

  /*********
   * ENVIAR
   *********/

  openMailModal(reply?:boolean, forward?:boolean){

    let mail_data:{[key:string]:any} = {
      selected_account_id: this.selected_account.account.id,
      accounts: this.accounts
    };

    if(reply) {
      if(this.selected_account.folder === 'received') {
        mail_data.reply_address = this.utils.cleanReplyAddress(this.selected_email.from)
      } else if(this.selected_account.folder === 'sent') {
        mail_data.reply_address = this.utils.cleanReplyAddress(this.selected_email.to)
      }

      mail_data.forward = true;
      mail_data.header_subject = 'Re: ' + this.selected_email.subject;
      mail_data.forward_body = this.utils.setReplyMessageBody(this.selected_email)
    }

    if(forward) {
      console.log(this.selected_email)
      mail_data.forward = true;
      mail_data.header_subject = 'Fwd: ' + this.selected_email.subject;
      mail_data.forward_body = this.utils.setForwardMessageBody(this.selected_email)


    }

    this.modalRefMail = this.modalService.show(SendEmailComponent, {
      class: 'modal-fluid',
      data: mail_data
    })

    this.subscriptions.push(
      this.modalRefMail.content.reload_sent.subscribe(
        reload => {
          if(reload && this.selected_account.folder === 'sent') {
            this.getEmails()
          }
        }
      )
    )

    this.subscriptions.push(
      this.modalService.closed.subscribe(() => this.modalRefMail.content.unsubscribeAll())
    );
  }

  /*********************
   * VINCULAR EXPEDIENTE
   *********************/

  linkClaim(email_id: number){

    this.modalRefClaim = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.claim_entity.labels.form,
              list_headers: [
                'Nº Aviso',
                'Número',
                'Fecha encargo',
                'Proveedor',
                this._titleCasePipe.transform(this.client_entity.labels.form),
                'Dirección',
                'Tramitador',
              ],
              list_fields: [
                {entity: this.claim_entity.entity, field: 'number'},
                {entity: this.claim_entity.entity, field: 'number_provider'},
                {entity: this.claim_entity.entity, field: 'order_date'},
                {entity: this.company_entity.entity, field: 'name'},
                {entity: this.client_entity.entity, field: 'name_complete'},
                {entity: 'policy', field: 'address_complete'},
                {entity: this.user_entity.entity, field: 'name'}
              ],
              list_entity: this.claim_entity.entity,
              list_endpoint: this.claim_entity.list_entity,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                }
              ]
            }
        })


    this.subscriptions.push(
      this.modalRefClaim.content.selectedRegister.subscribe(register => {
        this.loading = true;
        const params = {
          email:{
            claim_id: register.id
          }
        }
        console.log(email_id)
        this.subscriptions.push(
          this._api.put(this.data.saveEndpoint+'/' + email_id, JSON.stringify(params)).subscribe(
            response => {
              this._notification.notificacionOK('Email vinculado correctamente');
              this.getEmails();
            },
            error => {
              this.loading = false;
            }
          )
        )
      })
    );

  }

  unlinkClaim(email_id: number) {
    this.loading = true;
    const params = {
      email:{
        claim_id: null
      }
    };

    this.subscriptions.push(
      this._api.put(this.data.saveEndpoint+'/' + email_id, JSON.stringify(params)).subscribe(
        response => {
          this._notification.notificacionOK('Email desvinculado correctamente');
          this.getEmails()
        },
        error => {
          this.loading = false;
        }
      )
    )
  }


  onContextMenu(event: MouseEvent, email) {
    event.preventDefault();

    this.selected_email = email;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': email };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  openClaim(email){
    console.log(email)
    if(email.claim) {
      this._tabs.addTab(this.claim_entity.entity, email.claim);
    }
  }
}
