import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { forkJoin, from, of, Subscription, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { matchValueToArray } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { EmployeeInterface } from 'src/app/shared/interfaces/data/employee-interface';
import { GuildInterface } from 'src/app/shared/interfaces/data/guild-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { EmployeeGuildsComponent } from '../../components/employee-guilds/employee-guilds.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { FirmsService } from '../../services/firms.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('guildsComponent', {static: false}) guildsComponent: EmployeeGuildsComponent

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;

  private employee: EmployeeInterface;

  public loading: boolean = false;

  public formEmployee: FormGroup;
  public changedWebAccesValue: boolean;
  private firms: Array<number>;

  public employee_guilds: Array<GuildInterface>;
  public dropdowndata: DropdownsInterface;

  public documents: Array<any>;

  public id: number;
  private subscriptions: Array<Subscription> = [];

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  constructor(private _api: ApirequestService, private _lock: LockService, private _dropdowns: DropdownService,private _buttons: ActionButtonsService, private _notification: NotificationService, private _firms: FirmsService)
  {
    this.formEmployee = this.formFunctions.getForm('employee');
  }

  ngOnDestroy(): void {
    this.unsubscribeAll()

    this._api.unlockExit(this.lockdata)
  }

  unsubscribeAll(){
    this.subscriptions.forEach(e => e.unsubscribe());

  }

  ngOnInit(): void {
    //Cogemos todas las firms y filtramos por las que tienen id > 0 y luego mapeamos para obtener el id únicamente
    this.firms = this._firms.getFirms().filter(emp => emp.id> 0).map(emp => emp.id);

    this.id = this.data.id;
    this.getData();
  }

  private getData() {
    this.loading = true;

    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty('employee')).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    data.guild = data.guild.filter(guild => guild.enabled == true);
    data.user = data.user.filter(u => u.email !== this.employee.email);
    this.dropdowndata = data;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.employee = apiresponse.employee;
    this.employee_guilds = apiresponse.employee.employeeguild;
    this.documents = apiresponse.employee.documents;

    this.tab.tabData.headerData = this.employee.name_complete;


    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formEmployee = undefined;
          setTimeout(() => {
            this.formEmployee = this.formFunctions.getForm('employee');
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?: boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formEmployee, this.employee);
      this.formEmployee.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formEmployee, this.employee);

      if(this.formEmployee.controls.web_access.value == true){
        this.formEmployee.controls.email.disable();
      }

      this.subscriptions.push(
        this.formEmployee.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }


  }



  changeEnabled(evt: MdbCheckboxChange) {
    if (evt.checked) {
      this.formEmployee.controls.web_access.enable();
    } else {
      this.formEmployee.controls.web_access.setValue(0);

      this.formEmployee.controls.surname.clearValidators()
      this.formEmployee.controls.email.removeValidators([Validators.required, matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))]);
      this.formEmployee.controls.surname.updateValueAndValidity();
      this.formEmployee.controls.email.updateValueAndValidity();

    }

  }

  changeWebAccess(evt: MdbCheckboxChange) {
    // si enabled es false no dejar que web_access se pueda poner a true
    if(this.formEmployee.controls.enabled.value == 0) {
      this.formEmployee.controls.web_access.setValue(0);
    }

    if (this.formEmployee.controls.web_access.value == 1) {
      this.formEmployee.controls.surname.addValidators(Validators.required);
      this.formEmployee.controls.email.addValidators([Validators.required, Validators.email, matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))]);
    } else {
      this.formEmployee.controls.surname.clearValidators();
      this.formEmployee.controls.email.clearValidators();
    }

    this.formEmployee.controls.surname.updateValueAndValidity();
    this.formEmployee.controls.email.updateValueAndValidity();
  }

  public onSubmit(): void {

    this.formEmployee.markAllAsTouched();

    if (this.formEmployee.valid) {
      this._buttons.setLoading(true);
      const params = {};

      this.formFunctions.fillFormParams(this.formEmployee, this.data.entity, params)
      params[this.data.entity]['name'] = this.formEmployee.controls.name.value;

      if(this.employee.web_access != this.formEmployee.controls.web_access.value) {
        params['access_pgaweb'] = {};
        params['access_pgaweb']['web_access'] = this.formEmployee.controls.web_access.value;
        params['access_pgaweb']['name'] = this.formEmployee.controls.name.value;
        params['access_pgaweb']['surname'] = this.formEmployee.controls.surname.value;
        if (this.employee.web_access == true) {
          params['access_pgaweb']['email'] = this.employee.email;
        } else {
          params['access_pgaweb']['email'] = this.formEmployee.controls.email.value;
        }
        params['access_pgaweb']['id_firms'] = this.firms;
      }

      if(this.guildsComponent.id_default) {
        params[this.data.entity]['guild_default'] = +this.guildsComponent.id_default;
      }
      params[this.data.entity]['guilds'] = this.employee_guilds.map(guild => {return guild.id});

      console.log(params);

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          resp => {
            this.tab.modifiedData = false;
            this.formEmployee.markAsPristine();
            this._buttons.reloadListTab(this.data.saveEndpoint);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }

}
