import { Type } from "@angular/core";
import { Tab } from "src/app/main/tab-models/tab.model";
import { AgentFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";



export class AgentModel extends EntityBaseModel {
  protected readonly fa_conf = {
    form: 'fab',
    list: 'fab',
  }
  protected readonly icon = {
    form: 'black-tie',
    list: 'black-tie'
  }

  setModelData(models:any) {

    models.agent.icon = this.icon;

    models.agent.form_tabData = {
      entity: models.agent.entity,
      saveEndpoint: models.agent.saveEndpoint,
      entity_permissions: models.agent.permissions,
      entity_label: models.agent.labels.form,
      new_register_dropdowns: [],
      edit_register_dropdowns: ['user']
    }

    models.agent.list_tabData = {
      entity: models.agent.entity,
      entity_label: models.agent.labels.form,
      entity_permissions:models.agent.permissions,
      listParams: {
        list_entity: models.agent.list_entity,
        list_label: models.agent.labels.list,
          limit: 15,
          headers: [
            'Activo',
            'Acceso Web',
            'NIF',
            'Nombre',
            'Apellidos',
            'Dirección',
            'Teléfono',
            'Móvil',
            'Email',
          ],
          fields: [
            { entity: models.agent.entity, field: 'enabled', type: 'check'},
            { entity: models.agent.entity, field: 'web_access', type: 'check'},
            { entity: models.agent.entity, field: 'identification_document' },
            { entity: models.agent.entity, field: 'name'},
            { entity: models.agent.entity, field: 'surname'},
            { entity: models.agent.entity, field: 'address_complete' },
            { entity: models.agent.entity, field: 'phone' },
            { entity: models.agent.entity, field: 'mobile' },
            { entity: models.agent.entity, field: 'email' },
          ],
          web_access: true,
          disable_user: false,
          filters: new AgentFilters(models)
      }
    }

  }

  getFormTab(component: Type<any>, entity_data, register, header_field): Tab {
    let tabData = {...entity_data.form_tabData};

    tabData.id = register.id;


    /**
     * Si el registro tiene campo number, es el que se muestra en la cabecera de la pestaña
     * Si no tiene campo number, tiene que tener campo headerData, para volver a generar la pestaña en la recarga
     */
    let header = null;
    if (register.name) {
      header = register.name;
      if(register?.surname) {
          header += ' ' + register.surname
      }
  } else if(register.headerData){
      header = register.headerData
    } else {
      throw new Error('Incorrect Tab Params')
    }

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>"+header,
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
    )
  }
}
