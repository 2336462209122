import { ComunicationsProtocolsComponent } from "src/app/main/comunications-protocols/comunications-protocols.component";
import { EntityBaseModel } from "./entity-base.model";
import { Tab } from "src/app/main/tab-models/tab.model";
import { TitleCasePipe } from "@angular/common";
import { EmailsComponent } from "src/app/main/lists/emails/emails.component";

const titleCasePipe = new TitleCasePipe();


export class EmailModel extends EntityBaseModel {
  protected readonly icon = {
    form: '',
    list: 'at'
  }

  setModelData(models:any) {

    models.email.icon = this.icon;

    models.email.modules =  ['mail'];


    models.email.list_tabData = {
      entity: models.email.entity,
      entity_label: models.email.labels.list,
      entity_permissions:models.email.permissions,
      entity_modules:models.email.modules,
      saveEndpoint: models.email.saveEndpoint,
      listParams: {
        list_entity: models.email.list_entity
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      EmailsComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: false,
        buttonDelete: false,
        buttonSearch: false
      },
      tabData
      )
  }
}
