import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Tab } from '../tab-models/tab.model';
import { TabService } from './tab.service';

@Injectable({
  providedIn: 'root'
})
export class ActionButtonsService {

  activeTab: Tab;
  tabs: Tab[];

  private loading: boolean = false;
  public loadingSub = new BehaviorSubject<boolean>(this.loading);

  constructor(private _tabs: TabService) {
    /**
     * Se obtiene la pestaña activa para poder cambiarle las propiedad edit después de guardar datos
     **/
    this._tabs.tabSub.subscribe(tabs =>{
      this.tabs = tabs;
      this.activeTab = tabs.find(tab => tab.tabActive);
    });
  }

  toggleFilters(tab: Tab) {
    tab.tabComponentRef.instance.dynamicFilters.filters.toggle()
  }

  setLoading(value: boolean): void {
    this.loading = value;
    this.loadingSub.next(this.loading);
  }

  /**
   * Ejecuta el método onSubmit de la pestaña pasada por parámetro
   * @param saveTab Pestaña de la que ejecutar el método onSubmit
   */
  saveRegister(saveTab: Tab){
    saveTab.tabComponentRef.instance.onSubmit();
  }

  deleteRegister(tab: Tab){
    tab.tabComponentRef.instance.confirmDelete();
  }

  /**
   * Llama al método loadData del componente de la pestaña listado de la entidad
   * @param entity entidad del listado
   * @param type tipo de entidad, para no recargar todas las pestañas de entidades compartidas
   */
  reloadListTab(entity: string, type?: number) {
    let listTabs = this.tabs.filter((tab: Tab) => tab.tabData.listParams?.list_entity === entity);

    if(type) {
     listTabs = listTabs.filter((tab:Tab) => tab.tabData.entity_type === type)
    }

    for (let index = 0; index < listTabs.length; index++) {
      listTabs[index].tabComponentRef.instance.loadData();

    }
  }

  reloadClaimListTab(claim_id: any, section_reload: string) {
    let tab = this.tabs.find(t => t.tabData.entity === 'claim' && t.tabData.id === claim_id);
    if(tab) {
        tab.tabComponentRef.instance.reloadSection(section_reload);
    }
  }

}
