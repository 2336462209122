import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { ProviderInterface } from 'src/app/shared/interfaces/data/provider-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { AppConfigService } from '../../services/app-config.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { UserPermissionsService } from '../../services/user-permissions.service';
import { Tab } from '../../tab-models/tab.model';
import { catchError } from 'rxjs/operators';
import { AddressesParamsInterface } from 'src/app/shared/interfaces/component-params/addresses-params-interface';
import { GmapsComponentComponent } from '../../components/gmaps-component/gmaps-component.component';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data;
  @ViewChild('maps', {static: false}) maps: GmapsComponentComponent;


  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  public loading: boolean = false;
  public formProvider: FormGroup;
  public formProtocol: FormGroup;

  public provider: ProviderInterface;

  public id: number;
  private subscriptions: Array<Subscription> = [];

  public heading = "<i class='fas fa-info-circle'></i> Datos generales";

  public addresses: Array<any>;

  public provider_addresses_params: AddressesParamsInterface = {
    addresses: [],
    parent_id: null,
    address_entity: 'provideraddress',
    parent_id_field: 'provider_id',
    entity_endpoint: 'providersaddresses',
  }

  public all_providers: Array<ProviderInterface>;
  public providers: Array<ProviderInterface>;
  public available_providers: Array<ProviderInterface>;

  public protocols: Array<any> = [{id: -1, name: 'Sin asignar'}];
  public access_type: Array<any>;
  public mailaccounts: Array<any>;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  public protocol_logo_path: string;

  constructor(private _api: ApirequestService, private _lock: LockService, private _buttons: ActionButtonsService, private _notification: NotificationService, private _dropdowns: DropdownService, private _appconfig: AppConfigService, private fb:FormBuilder, private _userpermissions: UserPermissionsService) {

    this.setFormProtocol();
  }

  /**
   * funcion para pasar las direcciones al mapa y marcar la posicion predeterminada
   * @param addresses
   *
   */
  public LoadAddressesOnMap(addresses)
  {
    this.maps.MarkerDefaultPositionInMap(addresses);
  }

  setFormProtocol(){
    this.formProtocol = this.fb.group({
    //  status_error_message:[{value: null, disabled: true}],
      enabled:[false],
      typeprotocol_id:[{value: -1, disabled: true}],
      typeprotocolaccess_id:[{value: null, disabled: true}, [Validators.required]],
      access_data: this.fb.group({
        mailaccount_id:[{value: null, disabled: true}, [Validators.required]],
        user:[{value: null, disabled: true}, [Validators.required]],
        password:[{value: null, disabled: true}, [Validators.required]],
        //token:[{value: null, disabled: true}],
        code_provider:[{value: null, disabled: true}],
      })
    });

    this.subscriptions.push(
      this.formProtocol.controls.enabled.valueChanges.subscribe(
        value => {
          //console.log('ENABLED: '+value)
          if(value) {
            this.formProtocol.controls.typeprotocol_id.enable();
          } else {
            this.formProtocol.controls.access_data.disable();
            //this.formProtocol.controls.typeprotocol_id.setValue(-1);
            this.formProtocol.controls.typeprotocol_id.disable();
            this.formProtocol.controls.typeprotocolaccess_id.disable();
          }
        }
      )
    );

    this.subscriptions.push(
      this.formProtocol.controls.typeprotocol_id.valueChanges.subscribe(
        value => {
          //console.log('COMP: '+value)
          if(value > -1) {
            this.formProtocol.controls.typeprotocolaccess_id.enable();
            this.protocol_logo_path = this._appconfig.getProtocolLogo(value);
          } else {
            this.protocol_logo_path = null;
            this.formProtocol.controls.typeprotocolaccess_id.setValue(null);
            this.formProtocol.controls.typeprotocolaccess_id.disable();
            if(!this.formProtocol.controls.enabled.value) {
              this.formProtocol.controls.access_data.reset();
            }
          }
        }
      )
    );
    this.subscriptions.push(
      this.formProtocol.controls.typeprotocolaccess_id.valueChanges.subscribe(
        value => {
          let access_data_form: FormGroup = this.formProtocol.controls.access_data as FormGroup;

          //console.log('TIPO: '+value)
          if(this.formProtocol.controls.enabled.value) {
            switch (value) {
              case 1:
                access_data_form.controls.mailaccount_id.setValue(null);
                //access_data_form.controls.mailaccount_id.markAsPristine();
                access_data_form.controls.mailaccount_id.disable();

                access_data_form.controls.user.setValidators(Validators.required)
                access_data_form.controls.password.setValidators(Validators.required)
                access_data_form.controls.user.enable();
                access_data_form.controls.password.enable();
                access_data_form.controls.code_provider.enable();
                break;
              case 2:
                access_data_form.controls.user.setValue(null);
                //access_data_form.controls.user.markAsPristine();
                access_data_form.controls.user.disable();
                access_data_form.controls.password.setValue(null);
                //access_data_form.controls.password.markAsPristine();
                access_data_form.controls.password.disable();

                access_data_form.controls.mailaccount_id.setValidators(Validators.required)
                access_data_form.controls.mailaccount_id.enable();
                access_data_form.controls.code_provider.enable();
                break;
              case 3:
                access_data_form.controls.mailaccount_id.setValue(null);
               // access_data_form.controls.mailaccount_id.markAsPristine();
                access_data_form.controls.mailaccount_id.disable();

                access_data_form.controls.user.setValidators(Validators.required)
                access_data_form.controls.password.setValidators(Validators.required)
                access_data_form.controls.user.enable();
                access_data_form.controls.password.enable();
                access_data_form.controls.code_provider.enable();
                break;
              case 4:
                access_data_form.controls.user.clearValidators()
                access_data_form.controls.password.clearValidators()
                access_data_form.controls.mailaccount_id.clearValidators();

                access_data_form.controls.user.enable();
                access_data_form.controls.password.enable();
                access_data_form.controls.mailaccount_id.enable();
                access_data_form.controls.code_provider.enable();
                break;

              default:
               // console.log(value)
                break;
            }
          }

        }
      )
    );
  }

  get typeprotocolaccess(): number {
    return this.formProtocol.controls.typeprotocolaccess_id.value;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void {
    console.log(this.data)
    this.formProvider = this.formFunctions.getForm(this.data.entity);
    this.id = this.data.id;
    this.provider_addresses_params.parent_id = this.id;
    this.getData();
  }

  private getData() {
    this.loading = true;
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));

   this.protocols = data.protocol;

   this.all_providers = data.company;
   this.setProviders();
   this.protocols = [...this.protocols,...data.protocol];
   this.access_type = data.protocolaccess;
   this.mailaccounts = data.mailaccount;
  }

  setProviders(){
    this.available_providers = this.all_providers.filter(
      p => {
        if(p.id != this.provider?.id && !this.providers.find(p1 => p1.id == p.id)){
          return true;
        }
      }
    )

  }

  removeProvider(provider: ProviderInterface) {
    this.providers.splice(this.providers.findIndex(u=> u.id == provider.id), 1);
    this.setProviders()
    this.tab.modifiedData = true;
  }

  addProvider(provider: ProviderInterface) {
    this.providers.push(provider);
    this.setProviders();
    this.tab.modifiedData = true;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.provider = apiresponse.provider;
    this.providers = this.provider.is_subcompanyof;

    this.addresses = this.provider.provideraddress;
    this.provider_addresses_params.addresses = this.addresses;
    console.log(this.addresses)

    this.tab.tabData.headerData = this.provider.name;

    if(this.addresses?.length > 0)
    {
      let defaultAddressModel = this.addresses.find(key => key.default == true);
      if(defaultAddressModel.address_complete != '')
      {
        this.LoadAddressesOnMap(this.addresses);
      }
    }

    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formProvider = undefined;
          setTimeout(() => {
            this.formProvider = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formProvider, this.provider);
      this.formProvider.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formProvider, this.provider);

      if(this.provider.protocolaccess) {
        this.formFunctions.rellenarFormulario(this.formProtocol.controls.access_data, this.provider.protocolaccess);
        //this.formProtocol.controls.status_error_message.setValue(this.provider.protocolaccess.status_error_message)
        this.formProtocol.controls.enabled.setValue(this.provider.protocolaccess.enabled)
        this.formProtocol.controls.typeprotocol_id.setValue(this.provider.protocolaccess.typeprotocol_id)
        this.formProtocol.controls.typeprotocolaccess_id.setValue(this.provider.protocolaccess.typeprotocolaccess_id)
        console.log(this.formProtocol)
      }

     this.subscriptions.push(
        this.formProvider.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }

  disableChanges(): boolean {
    let readonly = false;
    if(!this._userpermissions.checkPermission('modify_providers') || !this.lockdata?.unblock) {
      readonly = true;
    }

    return readonly;
  }

  saveProtocolData(){
    this.formProtocol.markAllAsTouched();
    if(this.formProtocol.valid) {
      let proceed = false;
      let post = false;
      if(!this.provider.protocolaccess || !this.provider.protocolaccess.enabled) {
        if(this.formProtocol.controls.enabled.value) {
          post = true;
          proceed = true;
        }
      } else {
        proceed = true;
      }

      if(proceed) {
        this.loading = true;
        const params:{protocolaccess?: any | null} = {}

        let data_form = this.formProtocol.controls.access_data as FormGroup;

        Object.keys(data_form.controls).forEach(c => data_form.controls[c].markAsDirty());

        this.formFunctions.fillFormParams((data_form), 'protocolaccess', params);

        params.protocolaccess.enabled = this.formProtocol.controls.enabled.value;
        params.protocolaccess.typeprotocol_id = this.formProtocol.controls.typeprotocol_id.value;
        params.protocolaccess.typeprotocolaccess_id = this.formProtocol.controls.typeprotocolaccess_id.value;
        params.protocolaccess.provider_id = this.id;

        console.log(this.formProtocol.controls.access_data)

        console.log(params)
        let apicall: Observable<any>;
        if(post) {
          apicall = this._api.post('protocolsaccess', JSON.stringify(params))
        } else {
          apicall = this._api.put('protocolsaccess/' + this.provider.protocolaccess.id, JSON.stringify(params))
        }

        this.subscriptions.push(
          apicall.subscribe(
            response => {
              if(post) {
                this.provider.protocolaccess = response.response.protocolaccess
              }
              this.loading = false;
            },
            error => {
              this.loading = false;
            }
          )
        )
      }


    }

    /* this.formProtocol.markAllAsTouched();
    if(this.formProtocol.valid) {
      const params:{protocolaccess?: any | null} = {}
      this.formFunctions.fillFormParams((this.formProtocol.controls.access_data as FormGroup), 'protocolaccess', params);

        params.protocolaccess.enabled = this.formProtocol.controls.enabled.value;
        params.protocolaccess.typeprotocol_id = this.formProtocol.controls.typeprotocol_id.value;
        params.protocolaccess.typeprotocolaccess_id = this.formProtocol.controls.typeprotocolaccess_id.value;
        params.protocolaccess.provider_id = this.id;



        console.log(params)

        console.log(this.provider.protocolaccess)
    } */
  }

  public onSubmit(): void {

    this.formProvider.markAllAsTouched();

    if (this.formProvider.valid) {
      this._buttons.setLoading(true);

      const params = {};
      this.formFunctions.fillFormParams(this.formProvider, this.data.entity, params);

      params['providersubprovider'] = this.providers.map(p => {return p.id})

      console.log(params)

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          resp => {
            this.tab.modifiedData = false;
            this._buttons.reloadListTab(this.data.saveEndpoint, this.data.entity_type);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
      /* this._buttons.setLoading(true);

      const params = {};
      params['provider'] = {};

      //Datos perito
      Object.keys(this.formProvider.controls).forEach(
        control => {
          params['provider'][control] = this.formProvider.controls[control].value;
        }
      );

      if(!params['provider']['is_subprovider']) {
        params['provider']['provider_id'] = null;
      }
      if(!params['provider']['has_protocol']) {
        params['provider']['protocol_id'] = null;
      }
      delete params['is_subprovider'];
      delete params['has_protocol'];

      console.log(params);

      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          resp => {
            this.tab.modifiedData = false;
            this._buttons.reloadListTab(this.data.saveEndpoint, 1);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response['provider'])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      ); */
    }
  }


}
