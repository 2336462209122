import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { matchValueToArray } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { AgentInterface } from 'src/app/shared/interfaces/data/agent-interface';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { FirmsService } from '../../services/firms.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';
import { catchError } from 'rxjs/operators';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  private agent: AgentInterface;

  public loading: boolean = false;

  public formAgent: FormGroup;
  public changedWebAccesValue: boolean;
  private firms: Array<number>;

  private id: number;
  private subscriptions: Array<Subscription> = [];

  public heading = "<i class='fas fa-info-circle'></i> Datos generales";


  public dropdowndata: DropdownsInterface;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  constructor(private _api: ApirequestService, private _lock: LockService, private _dropdowns: DropdownService,private _buttons: ActionButtonsService, private _notification: NotificationService, private _firms: FirmsService) {
    this.formAgent = this.formFunctions.getForm('agent');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());

    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void {
    //Cogemos todas las firms y filtramos por las que tienen id > 0 y luego mapeamos para obtener el id únicamente
    this.firms = this._firms.getFirms().filter(emp => emp.id> 0).map(emp => emp.id);


    this.id = this.data.id;
    this.getData();
  }

  private getData() {
    this.loading = true;
    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty('agent')).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {
    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    data.user = data.user.filter(u => u.email !== this.agent.email);
    this.dropdowndata = data;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.agent = apiresponse.agent;

    this.tab.tabData.headerData = this.agent.name;

    if(this.agent?.surname) {
      this.tab.tabData.headerData += ' ' + this.agent.surname;
    }


    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formAgent = undefined;
          setTimeout(() => {
            this.formAgent = this.formFunctions.getForm('agent');
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formAgent, this.agent);
      this.formAgent.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formAgent, this.agent);

      if(this.formAgent.controls.web_access.value == true){
        this.formAgent.controls.email.disable();
      }

      this.subscriptions.push(
        this.formAgent.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }

  changeEnabled(evt: MdbCheckboxChange) {
    if (evt.checked) {
      this.formAgent.controls.web_access.enable();
    } else {
      this.formAgent.controls.web_access.setValue(0);

      this.formAgent.controls.surname.clearValidators()
      this.formAgent.controls.email.removeValidators([Validators.required, matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))]);
      this.formAgent.controls.surname.updateValueAndValidity();
      this.formAgent.controls.email.updateValueAndValidity();

    }

  }

  changeWebAccess(evt: MdbCheckboxChange) {
    // si enabled es false no dejar que web_access se pueda poner a true
    if(this.formAgent.controls.enabled.value == 0) {
      this.formAgent.controls.web_access.setValue(0);
    }

    if (this.formAgent.controls.web_access.value == 1) {
      this.formAgent.controls.surname.addValidators(Validators.required);
      this.formAgent.controls.email.addValidators([Validators.required, Validators.email, matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))]);
    } else {
      this.formAgent.controls.surname.clearValidators();
      this.formAgent.controls.email.clearValidators();
    }

    this.formAgent.controls.surname.updateValueAndValidity();
    this.formAgent.controls.email.updateValueAndValidity();
  }

  public onSubmit(): void {

    this.formAgent.markAllAsTouched();

    if (this.formAgent.valid) {
      this._buttons.setLoading(true);
      const params = {};

      this.formFunctions.fillFormParams(this.formAgent, this.data.entity, params)
      params[this.data.entity]['name'] = this.formAgent.controls.name.value;

      if(this.agent.web_access != this.formAgent.controls.web_access.value) {
        params['access_pgaweb'] = {};
        params['access_pgaweb']['web_access'] = this.formAgent.controls.web_access.value;
        params['access_pgaweb']['name'] = this.formAgent.controls.name.value;
        params['access_pgaweb']['surname'] = this.formAgent.controls.surname.value;

        if (this.agent.web_access == true) {
          params['access_pgaweb']['email'] = this.agent.email;
        } else {
          params['access_pgaweb']['email'] = this.formAgent.controls.email.value;
        }

        params['access_pgaweb']['id_firms'] = this.firms;
      }


      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          resp => {
            this.tab.modifiedData = false;
            this.formAgent.markAsPristine();
            this._buttons.reloadListTab(this.data.saveEndpoint);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(resp.response[this.data.entity])
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }
}
