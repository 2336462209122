import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { UserInterface } from 'src/app/shared/interfaces/data/user-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../services/action-buttons.service';
import { AppConfigService } from '../services/app-config.service';
import { FirmsService } from '../services/firms.service';
import { NotificationService } from '../services/notification.service';
import { PanelOptionsService } from '../services/panel-options.service';
import { UserPermissionsService } from '../services/user-permissions.service';
import { Tab } from '../tab-models/tab.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;
  @ViewChild('password', { static: false }) password: ElementRef;
  @ViewChild('temaDemoModal', { static: true }) temaDemoModal: ModalDirective;

  private subscriptions: Array<Subscription> = [];

  private user: UserInterface;

  /**Almacenará los temas facilitados por el API */

  public formProfile: FormGroup;
  public loading = false;

  public error: boolean = false;

  private id = -1;

  public can_modify = false;

  public demoTime: number = 15;

  private counterBack;

  /** opciones del MDB SELECT Tema */
  public themes = [
    { value: 'PGA-Skin', label: 'PGA Style' },
    { value: 'white-skin', label: 'Light Mode' },
    { value: 'black-skin', label: 'Dark Mode' },
    { value: 'cyan-skin', label: 'Cyan Skin' },
    { value: 'mdb-skin', label: 'Material Design' },
    { value: 'deep-purple-skin', label: 'Deep Purple Skin' },
    { value: 'pink-skin', label: 'Pink Skin' },
    { value: 'indigo-skin', label: 'Indigo Skin' },
    { value: 'light-blue-skin', label: 'Ligth Blue Skin' },
    { value: 'grey-skin', label: 'Grey Skin' }
  ];

  public pages;

  public views = [
    { value: 'tramit-view', label: 'Vista Tramitador' },
    { value: 'employee-view', label: 'Vista Operario' },
  ];

  public firms = [];
  public accounts = [];

  public lock_text = 'Desbloquear contraseña';

  private formfunctions = new FormFunctions;

  constructor(private _api: ApirequestService, private _permissions: UserPermissionsService,
    private _notification: NotificationService, private formBuilder: FormBuilder,
    private _options: PanelOptionsService, private _buttons: ActionButtonsService,private _firms: FirmsService, private _appconfig: AppConfigService) {

    this.pages = this._appconfig.getInitPageOptions()
    this.formProfile = this.formfunctions.getForm('profile')
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscriptions.push(
      this._api.get('users/profile').subscribe(
        response => {
          this.id = response['response']['user']['id'];
          this.user = response.response.user;

          this.formfunctions.rellenarFormulario(this.formProfile, this.user);

          //console.log(this.formPerfil)
          console.log(this.user.options)

          this.formProfile.addControl('options', this.formBuilder.group({
            theme_webpanel: this._options.getDefaultTheme(),
            init_tab: 'inicio',
            type_view: this._options.getDefaultView()
          }));

          if (this.user.options.length > 0) {
            for (let index = 0; index < this.user.options?.length; index++) {
              (this.formProfile.controls.options as FormGroup).controls[this.user.options[index].name].setValue(this.user.options[index].option.value);

              if(this.user.options[index].name === 'init_tab') {
                if(!this.user.options[index].option.value) {
                  (this.formProfile.controls.options as FormGroup).controls[this.user.options[index].name].setValue('inicio')
                } else {
                  if(!this._appconfig.checkInitTabEntity(this.user.options[index].option.value)) {
                    (this.formProfile.controls.options as FormGroup).controls[this.user.options[index].name].setValue('inicio')
                  }
                }
              }
            }
          }

          if (this.user.roleuserfirm.length > 1) {
            this.firms = this.user.roleuserfirm.map(it => {
              return {
                value: it.firm_id,
                label: this._firms.getFirms().find(firm => firm.id === it.firm_id).name
              }
            });

            let activa = this.user.roleuserfirm.find(it => it.default == true);
            this.formProfile.controls.defaultFirm.setValue(activa.firm_id)
          }

          if (this.user.mailaccounts.length > 0) {
            this.accounts = this.user.mailaccounts.map(it => {
              return {
                value: it.id,
                label: it.mail
              }
            });

            let defaultaccount = this.user.mailaccounts.find(it => it.default_account == 1);

            this.formProfile.controls.defaultMail.setValue(defaultaccount.id)
          }


          console.log(this.formProfile)

          this.subscriptions.push(
            this.formProfile.valueChanges.subscribe(
              change => {
                this.tab.modifiedData = true;
              }
            )
          );

          this.loading = false;
        },
        error => {
          console.log(error)
          this.error = true;
          this.loading = false;
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  checkPermission(p: string): boolean {
    return this._permissions.checkPermission(p)
  }

  unlockPass() {
    if (this.formProfile.controls.password.enabled) {
      this.formProfile.controls.password.setValue('');
      this.formProfile.controls.confirm_password.setValue('');
      this.formProfile.controls.confirm_password.disable();
      this.formProfile.controls.password.disable();
      this.lock_text = 'Desbloquear contraseña';
    } else {
      this.formProfile.controls.password.enable();
      this.password.nativeElement.focus();
      this.formProfile.controls.confirm_password.enable();
      this.formProfile.controls.password.addValidators(Validators.required);
      this.formProfile.controls.confirm_password.addValidators(Validators.required);
      this.lock_text = 'Bloquear contraseña';

      this.formProfile.controls.password.updateValueAndValidity()
    }
  }

  onSubmit(): void {
    this.formProfile.markAsDirty();

    if (this.formProfile.valid) {
      if (!this.loading) {
        this._buttons.setLoading(true);
        const params = {};
        params['user'] = {};
        params['user']['is_enabled'] = true;
        params['user']['profileUpdate'] = true;

        let user_form = this.formProfile.controls;


        Object.keys(user_form).forEach(
          control => {
            if (user_form[control] instanceof FormControl) {
              if (user_form[control].dirty && user_form[control].enabled) {
                params['user'][control] = user_form[control].value
              }
            }
          }

        )

        let options_form = this.formProfile.controls.options as FormGroup;

        if (options_form.dirty) {
          params['user']['options'] = [];
          Object.keys(options_form.controls).forEach(
            control => {
              if (options_form.controls[control].dirty) {
                const param_option = {}
                param_option['name'] = control;
                param_option['value'] = options_form.controls[control].value;
                params['user']['options'].push(param_option)
              }
            }
          )
        }



        console.log(JSON.stringify(params))
        this.subscriptions.push(
          this._api.put('users/profile', JSON.stringify(params)).subscribe(
            resp => {
              this.tab.modifiedData = false;
              this._buttons.setLoading(false);
              this._notification.notificacionOK(resp.response.user)
            },
            error => {
              this._buttons.setLoading(false);
            }
          )
        );
      }

    }

  }

  changeTheme(evt) {
    console.log(evt)
    clearInterval(this.counterBack);
    // reducir la barra de carga
    this.counterBack = setInterval(() => { this.countTime() }, 1000);

    // cambiar el tema temporalmente
    if (evt.value != '') {
      this._options.setDemoTheme(evt.value)
    } else {
      this._options.setDemoTheme(this._options.getDefaultTheme())
    }
    this.temaDemoModal.show();
  }

  countTime() {
    this.demoTime--;
    if (this.demoTime == 0 || this.demoTime < 0) {
      clearInterval(this.counterBack);
      this.declineDemo()
    }
  }

  declineDemo() {
    if (!this.loading) {

      if (this._options.getSavedTheme()) {
        this.formProfile.controls.options['controls'].theme_webpanel.setValue(this._options.getSavedTheme())
      } else {
        let tema_inicial = this.user.options.find(it => it.name == 'theme_webpanel');
        if (tema_inicial) {
          this.formProfile.controls.options['controls'].theme_webpanel.setValue(tema_inicial.option.value);
        } else {
          this.formProfile.controls.options['controls'].theme_webpanel.setValue(this._options.getDefaultTheme())
        }
      }

      clearInterval(this.counterBack);
      this._options.declineDemoTheme();
      this.demoTime = 15;
      this.temaDemoModal.hide();
    }
  }

  saveTheme() {
    clearInterval(this.counterBack);
    if (!this.loading) {
      this.loading = true;
      const params = {};
      params['user'] = {};
      params['user']['is_enabled'] = true;
      params['user']['profileUpdate'] = true;

      params['user']['options'] = [{ name: 'theme_webpanel', value: this.formProfile.controls.options['controls'].theme_webpanel.value }];

      console.log(JSON.stringify(params))
      this.subscriptions.push(
        this._api.put('users/profile', JSON.stringify(params)).subscribe(
          response => {
            this._notification.notificacionOK(response.response.user)
            this._options.setCurrentTheme(this.formProfile.controls.options['controls'].theme_webpanel.value);
            sessionStorage.setItem('theme_webpanel', this.formProfile.controls.options['controls'].theme_webpanel.value)
            this.temaDemoModal.hide();
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        )
      );
    }
  }

}
