import { EntityPermissionsInterface } from "../interfaces/entity-permissions/entity-permissions-interface";

export class EntitiesData {

  public permissions: EntityPermissionsInterface;
  public modules: Array<string>;

  constructor(entity: string) {
    this.permissions = this.getEntityPermissions(entity);
    this.modules = this.getEntityModules(entity);
  }

  private getEntityData(entity: string){
    return {
      permissions: this.getEntityPermissions(entity),
      modules: this.getEntityModules(entity),
      labels: this.getEntityLabels(entity),
      icon: this.getEntityIcon(entity)
    }
  }

    /**
   * Devuelve los permsisos de la entidad pasada por parametro
   * @param entity Entidad de la que recuperar los permisos
   * @returns permisos de la entidad
   */
  private getEntityPermissions(entity:string) : EntityPermissionsInterface{
    /**
    * Si los permisos de la entidad no son list_+entidad, crear una funcion personalizada con los permisos y un case que la devuelva
    */
    switch (entity) {
      default:
        return this.getDefaultEntityPermissions(entity);
    }
  }

  private getDefaultEntityPermissions(entity:string): EntityPermissionsInterface{
    return {
      list: 'list_'+entity,
      modify: 'modify_'+entity,
      delete: 'delete_'+entity,
    }
  }

  private getEntityModules(entity: string): Array<any>{
    switch (entity) {
      case 'agent':
      case 'agents':
      case 'company':
      case 'companies':
        return ['companies'];

      case 'comunicationsprotocols':
      case 'emails':
      case 'mailaccount':
      case 'mailaccounts':
        return ['mail', 'companies'];

      case 'scalesimporter':
        return ['scale_import'];

      case 'invoicing':
      case 'invoices':
      case 'invoice':
      case 'invoices':
      case 'invoiceproforma':
      case 'invoicesproforma':
      case 'budget':
      case 'budgets':
      case 'deliverynote':
      case 'deliverynotes':
      case 'liquidation':
      case 'liquidations':
      case 'expense':
      case 'expenses':
        return ['billing'];


      default:
        return [];
    }
  }

  private getEntityLabels(entity: string): any{
    let labels = {
      form: '',
      list: '',
    }
    switch (entity) {
      case 'email':
        labels.form = 'Email';
        labels.list = 'Emails';
        break;
      case 'claim':
        labels.form = 'Expediente';
        labels.list = 'Expedientes';
        break;
    }

    return labels;
  }


  private getEntityIcon(entity: string): any{
    let labels = {
      form: '',
      list: '',
    }
    switch (entity) {
      case 'email':
        labels.form = 'at';
        labels.list = 'at';
        break;
      case 'claim':
        labels.form = 'folder-open';
        labels.list = 'folders';
        break;
    }
  }


}
