import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { matchValueToArray } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { MailAccountInterface } from 'src/app/shared/interfaces/data/mail-account-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { FirmsService } from '../../services/firms.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit, OnDestroy {
  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  public loading: boolean = false;
  public formUser: FormGroup;
  public formFirmRoles: FormArray = new FormArray([]);

  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;

  public firms: Array<any>;


  public dropdowndata: DropdownsInterface
  public view_pass: boolean;
  public roles = [
    {value: 2, label: 'Gerente'},
    {value: 3, label: 'Tramitador'}
  ]

  public available_accounts: Array<any> = [];
  public linked_accounts: Array<any> = [];
  modalRef: MDBModalRef | null = null;

  constructor(private modalService: MDBModalService, private  _firms: FirmsService, private _apifunctions: ApiFunctions,private _tabs: TabService, private _api: ApirequestService, private _buttons: ActionButtonsService, private _notification: NotificationService, private fb: FormBuilder, private _dropdowns: DropdownService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
   // this.firms = this._firms.getFirms().filter(firm => firm.id == this._firms.getActiveFirm());
    console.log(this.firms)

    this.createForm();
    this.getDropdowns();

  }

  createForm() {
    this.formUser = this.fb.group({
      name: [null, [Validators.required]],
      surname: ['',[Validators.required]],
      email: [null,[Validators.required,Validators.email]],
      password: [null,[Validators.required]],

    });


  }

  private setRoleFirms(){

    this.firms.forEach(
      firm => {
        let form_rolefirm = this.fb.group({
          id_firm:[{value: firm.id, disabled:true}],
          name:[{value: firm.name, disabled:true}],
          is_enabled:[false],
          id_role:[{value: 3, disabled:true}],
        });

        this.subscriptions.push(
          form_rolefirm.controls.is_enabled.valueChanges.subscribe(
            value => {
              if(value) {
                form_rolefirm.controls.id_role.enable();
              } else {
                form_rolefirm.controls.id_role.disable();
              }
            }
          )
        );
        this.formFirmRoles.push(form_rolefirm)
      }
    )

    this.subscriptions.push(
      this.formFirmRoles.valueChanges.subscribe(
        change => {
          this.tab.modifiedData = true;
        }
      )
    );
  }


  getDropdowns(){
    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          this.dropdowndata = this.utils.objectDeepCopy(response.response.dropdown);
          this.firms = [...this.dropdowndata.firm];
          this.setRoleFirms()
          this.setAccounts()

          this.formUser.controls.email.addValidators(matchValueToArray(this.dropdowndata.user.map(user => {return user.email})));
          this.formUser.controls.email.updateValueAndValidity();

          this.subscriptions.push(
            this.formUser.valueChanges.subscribe(
              change => {
                this.tab.modifiedData = true;
              }
            )
          );

          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  setAccounts(){
    this.available_accounts = this.dropdowndata?.mailaccount.filter(
      account => {
        if(!this.linked_accounts.find(a => a.id == account.id)) {
          return true
        }
      }
    ).map(
      account => {
        account.default = false;
        return account;
      })
  }

  addAccount(account: MailAccountInterface) {
    this.linked_accounts.push(account)
    this.setAccounts();
    this.tab.modifiedData = true;
  }
  removeAccount(account: MailAccountInterface){
    this.linked_accounts.splice(this.linked_accounts.findIndex(u=> u.id == account.id), 1);
    this.setAccounts();
  }

  onChangeDefault(event: MdbCheckboxChange, account: MailAccountInterface) {
    account.default = event.checked;
  }

  onSubmit(){
    this.formUser.markAllAsTouched();

    if(this.formUser.valid) {
      this._buttons.setLoading(true)
      let form_user_values = {...this.formUser.value}

      const params:{[key:string]: any} = {
        user: form_user_values
      }

      if(this.formFirmRoles.dirty) {
        params.roleuserfirm = [];

        this.formFirmRoles.controls.forEach(
          form => {
            if(form.dirty) {
              let firmrole_value = {...form.getRawValue()};
              if(firmrole_value.is_enabled) {
                params.roleuserfirm.push({
                  id_firm: firmrole_value.id_firm,
                  is_enabled: firmrole_value.is_enabled,
                  id_role: firmrole_value.id_role,
                })
              }
            }
          }
        )
      }

      if(this.linked_accounts.length > 0) {
        params.mail_accounts = this.linked_accounts.map(acc => { return {id: acc.id, default: acc.default}})
      }

      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {
            let user = response.response.user
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
            this.modalRef = this.modalService.show(OpenRegisterComponent, {
                    keyboard: false,
                    ignoreBackdropClick: true,
                    class: 'modal-dialog',
                    data: {
                      heading: 'Registro Creado',
                      text: 'Registro creado correctamente, ¿desea acceder al registro?',
                      register: user,
                      form_entity: this.data.entity,
                    }
                  })

            this._tabs.removeTabByTabId(this.tab.tabId);
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }

}
