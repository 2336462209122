import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';

@Component({
  selector: 'app-new-agent',
  templateUrl: './new-agent.component.html',
  styleUrls: ['./new-agent.component.scss']
})
export class NewAgentComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  public loading: boolean = false;
  public formAgent: FormGroup;

  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;

  modalRef: MDBModalRef | null = null;
  constructor(private modalService: MDBModalService,private _tabs: TabService, private _api: ApirequestService, private _buttons: ActionButtonsService, private _notification: NotificationService, private fb: FormBuilder) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formAgent = this.fb.group({
      identification_document: [null],
      name: [null, [Validators.required]],
      surname: [null],
      address: [null],
      email: [null],
      phone: [null],
      mobile: [null],
      postal_code: [null],
      province: [null],
      population: [null],
    });

    this.subscriptions.push(
      this.formAgent.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    );
  };

  public onSubmit(): void {
    this.formAgent.markAllAsTouched();

    if (this.formAgent.valid) {
      this._buttons.setLoading(true);
      const params = {};
      this.formFunctions.fillFormParams(this.formAgent, this.data.entity, params);

      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {
            let agent = response.response.agent
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
            this.modalRef = this.modalService.show(OpenRegisterComponent, {
                    keyboard: false,
                    ignoreBackdropClick: true,
                    class: 'modal-dialog',
                    data: {
                      heading: 'Registro Creado',
                      text: 'Registro creado correctamente, ¿desea acceder al registro?',
                      register: agent,
                      form_entity: this.data.entity,
                    }
                  })

            this._tabs.removeTabByTabId(this.tab.tabId);
          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }

}
