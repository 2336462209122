
<app-loader *ngIf="loading"></app-loader>

<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
  <!--PESTAÑA INFORMACION-->
  <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">

    <ng-container *ngIf="formFirm">
      <form [formGroup]="formFirm">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <!--<mdb-icon fad icon="building" size="lg" class="mr-1 text-primary">
            </mdb-icon>Datos Generales-->
          <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Empresa activa</b></mdb-checkbox>

        </mdb-card-header>

        </mdb-card>
        <div class="row mt-2">
          <div class="col-lg">
          </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg">
                <div class="md-form md-outline">
                    <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                    <label for="identification_document" class="">CIF</label>
                </div>
            </div>
            <div class="col-lg">
                <div class="md-form md-outline">
                    <input mdbInput type="text" id="firmname" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                    <label for="firmname" class="">Nombre</label>
                    <mdb-error *ngIf="formFirm.controls.name.invalid&& (formFirm.controls.name.dirty || formFirm.controls.name.touched)">
                        Campo obligatorio</mdb-error>
                </div>
            </div>

            <div class="col-lg">
                <div class="md-form md-outline">
                    <input mdbInput type="text" id="firmphone" formControlName="phone" class="form-control form-control-sm"  placeholder="">
                    <label for="firmphone" class="">Teléfono</label>
                </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg">
                <div class="md-form md-outline">
                    <input mdbInput type="text" id="firmmobile" formControlName="mobile" class="form-control form-control-sm"  placeholder="">
                    <label for="firmmobile" class="">Móvil</label>
                </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email"
                  class="form-control form-control-sm" placeholder="">
                <label for="email" class="">Email</label>
                <mdb-error
                  *ngIf="formFirm.controls.email.invalid && (formFirm.controls.email.dirty || formFirm.controls.email.touched)">
                  Introduce un email válido</mdb-error>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="firmurlweb" formControlName="url_web" class="form-control form-control-sm"  placeholder="">
                <label for="firmurlweb" class="">Web</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="md-form md-outline">
                <textarea type="text" id="observations" rows="15" formControlName="observations" placeholder="" class="md-textarea form-control" mdbInput></textarea>
                <label for="observations">Observaciones</label>
              </div>
            </div>
            <div class="col-md-6">
              <app-gmaps-component #maps></app-gmaps-component>
            </div>
          </div>
      </form>
    </ng-container>
  </mdb-tab>

  <!--PESTAÑA DIRECCIONES-->
  <mdb-tab heading="<i class='fas fa-map-marked-alt mr-2'></i> Direcciones">
    <app-addresses [params]="firm_addresses_params" [readonly]="disableChanges()" (loading)="loading = $event" (reload_map)="LoadAddressesOnMap($event)"></app-addresses>
    <!--<div  class="mt-4" style="height: 500px;" *ngIf="apiGoogleMap">
      <app-gmaps-component [mapOptions]="mapOptions" [googleMapMarkerPositions]="googleMapMarkerPositions" [googleMapMarkerOptions]="googleMapMarkerOptions">
      </app-gmaps-component>
    </div>-->
  </mdb-tab>


  <!--PESTAÑA USUARIOS-->
  <mdb-tab heading="<i class='fas fa-user mr-2'></i> Usuarios" *appPermissions="entities.user.permissions.list">
    <div class="row mt-2">
      <div class="col">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad [icon]="entities.user.icon.list" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios vinculados a la empresa
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Nombre completo</th>
                  <th scope="col">Email</th>
                  <th scope="col" class="medium-column">Rol</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of users; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <ng-container *appPermissions="entities.user.permissions.modify">
                        <a (click)="removeUser(user.user)" *ngIf="!disableChanges() && (user.role_id != 2 || user_role < 3)">
                          <mdb-icon fad icon="times-circle" class="red-text mr-1" size="lg"></mdb-icon>
                        </a>
                      </ng-container>
                    </td>
                    <td>
                      <span>{{user.user.name}} {{user.user.surname}}</span>
                    </td>
                    <td>
                      <span>{{user.user.email}}</span>
                    </td>
                    <td>
                      <ng-container *ngIf="(user.role_id == 2 && user_role > 2); else show_role_form">
                        <span>Gerente</span>
                      </ng-container>
                      <ng-template #show_role_form>
                        <select class="form-control form-control-sm" [formControl]="user.roleform">
                          <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
                        </select>
                      </ng-template>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios disponibles
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Nombre completo</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of available_users; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <ng-container *appPermissions="entities.user.permissions.modify">
                        <a (click)="addUser(user)" *ngIf="!disableChanges()">
                          <mdb-icon fad icon="plus-circle mr-1" size="lg" class="blue-text"></mdb-icon>
                        </a>
                      </ng-container>
                    </td>
                    <td>
                      <span>{{user.name}} {{user.surname}}</span>
                    </td>
                    <td>
                      <span>{{user.email}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </mdb-tab>

  <!--PESTAÑA TRADUCCIONES-->
  <mdb-tab *ngIf="true"  heading="<i class='fad fa-language mr-2'></i> Nomenclatura">
    <div class="row mt-2">
      <div class="col-8">
      <form [formGroup]="formEntities">
        <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
          <thead>
            <tr>
              <th scope="col">Entidad</th>
              <th scope="col">Formulario</th>
              <th scope="col">Listado</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let entity of default_entities; let i=index">
              <tr mdbTableCol formGroupName="{{entity.model}}">
                <td>
                    <span><b>{{entity.list | titlecase}}</b></span>
                </td>
                <td>
                    <input mdbInput type="text" id="form1" class="form-control" formControlName="form">
                </td>
                <td>
                    <input mdbInput type="text" id="form2" class="form-control" formControlName="list">
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </form>
      </div>
    </div>
  </mdb-tab>

  <!--PESTAÑA OPCIONES-->
  <mdb-tab *ngIf="true" heading="<i class='far fa-list mr-2'></i> Opciones">
    <ng-container>
      <mdb-card class="z-depth-0">
        <mdb-card-header class="blue-grey-text white border-bottom border-primary">
          <mdb-icon fad icon="ballot" size="lg" class="mr-1 text-primary">
          </mdb-icon>Período de visualización en históricos
        </mdb-card-header>
      </mdb-card>
      <div class="container-fluid mt-2">
        <form [formGroup]="formOptions">
          <div class="row">
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" inputmode="numeric" step="1" min="1" class="form-control" formControlName="firm_general_history_date_filter" id="firm_general_history_date_filter" mdbInput>
                <label for="firm_general_history_date_filter">General</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" inputmode="numeric" step="1" min="1" class="form-control" formControlName="firm_claims_history_date_filter" id="firm_claims_history_date_filter" mdbInput>
                <label for="firm_claims_history_date_filter">Expedientes</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" inputmode="numeric" step="1" min="1" class="form-control" formControlName="firm_invoices_history_date_filter" id="firm_invoices_history_date_filter" mdbInput>
                <label for="firm_invoices_history_date_filter">Facturas</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" inputmode="numeric" step="1" min="1" class="form-control" formControlName="firm_invoicesproforma_history_date_filter" id="firm_invoicesproforma_history_date_filter" mdbInput>
                <label for="firm_invoicesproforma_history_date_filter">Facturas proforma</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" inputmode="numeric" step="1" min="1" class="form-control" formControlName="firm_budgets_history_date_filter" id="firm_budgets_history_date_filter" mdbInput>
                <label for="firm_budgets_history_date_filter">Presupuestos</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" inputmode="numeric" step="1" min="1" class="form-control" formControlName="firm_workorders_history_date_filter" id="firm_workorders_history_date_filter" mdbInput>
                <label for="firm_workorders_history_date_filter">Partes de trabajo</label>
              </div>
            </div>
            <div class="col">
              <div class="md-form md-outline">
                <input type="number" step="1" min="1" class="form-control" formControlName="firm_deliverynotes_history_date_filter" id="firm_deliverynotes_history_date_filter" mdbInput>
                <label for="firm_deliverynotes_history_date_filter">Albaranes</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ng-container>
  </mdb-tab>

  <!--PESTAÑA WHATSAPP-->
  <mdb-tab *ngIf="true" heading="<i class='fab fa-whatsapp mr-2'></i> Whatsapp">
    <mdb-card class="z-depth-0">
      <mdb-card-header class="blue-grey-text white border-bottom border-primary">
        <mdb-icon fad icon="ballot" size="lg" class="mr-1 text-primary">
        </mdb-icon>Datos de configuración
      </mdb-card-header>
    </mdb-card>
   <!-- <div class="row mt-2">-->
      <form [formGroup]="formFirm">
        <table mdbTable class="mt-2 inline-buttons-table">
          <thead>
            <tr class="text-center">
              <th scope="col" class="code-column">Número teléfono</th>
              <th scope="col" class="medium-column">Identificador del número de teléfono</th>
              <th scope="col" class="large-column">Identificador de la cuenta de WhatsApp Business</th>
              <th scope="col">Identificador de acceso temporal/permanente</th>
            </tr>
          </thead>
          <tbody>
            <tr mdbTableCol>
              <td>
                <input mdbInput type="text" id="form1" class="form-control" formControlName="whatsapp_mobile">
              </td>
              <td>
                <input mdbInput type="text" id="form1" class="form-control" formControlName="whatsapp_telephone_number_identifier">
              </td>
              <td>
                <input mdbInput type="text" id="form1" class="form-control" formControlName="whatsapp_business_account_identifier">
              </td>
              <td>
                <input mdbInput type="text" id="form1" class="form-control" formControlName="whatsapp_token">

                <!--
                  <textarea type="text" id="form1" class="md-textarea form-control" mdbInput formControlName="whatsapp_token"></textarea>
                 -->
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    <!--</div>-->
    <app-firm-whatsapp [dropdowns]="dropdowndata" [firmData]="firm" [whatsapptemplates]="whatsapptemplates" [readonly]="disableChanges()"></app-firm-whatsapp>
  </mdb-tab>
</mdb-tabset>

