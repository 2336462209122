import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { conditionalCompareValidator } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;

    //Formularios
    public clientForm: FormGroup;
    public addressForm: FormGroup;
    public policyForm: FormGroup;

  public heading = "<i class='fas fa-info-circle'></i> Datos Personales";

  //Valores de los desplegables
  public companies: Array<any> = [{ value: -1, label: 'Sin asignar', subcompanies:[] }]
  public subcompanies: Array<any> = [{ value: -1, label: 'Sin asignar' }]

  //Variables
  public loading: boolean = false;
  public loadingDropdowns: boolean = false;

  //Comprobación de errores
  public subscriptions: Array<Subscription> = [];


  public company_entity = this._entity.entities.company;

  modalRef: MDBModalRef | null = null;
  modalRefAddress: MDBModalRef | null = null;
  constructor(private modalService: MDBModalService, private _dropdowns: DropdownService, private fb: FormBuilder, private _api: ApirequestService, private _notification: NotificationService, private _apifunctions: ApiFunctions, private _buttons: ActionButtonsService, private _tabs: TabService, private _entity: EntityService) { }

  ngOnInit(): void {
    this.createForm();
    this.getDropdowns();


  }

  tabChanges() {
    this.subscriptions.push(
      this.clientForm.valueChanges.subscribe(
        change => {
          this.tab.modifiedData = true;
        }
      )
    )

    this.subscriptions.push(
      this.addressForm.valueChanges.subscribe(
        change => {
          this.tab.modifiedData = true;
        }
      )
    )

    this.subscriptions.push(
      this.policyForm.valueChanges.subscribe(
        change => {
          if(!this.tab.modifiedData) {
            this.tab.modifiedData = true;
          }
        }
      )
    )

  }

  createForm() {

    this.clientForm = this.fb.group({
      surname: null,
      name: [null, Validators.required],
      identification_document: null,
      phone: null,
      mobile: null,
      email: [null, Validators.email],
    })
    //Para la Póliza
    this.policyForm = this.fb.group({
      number: [null],
      address: null,
      population: null,
      province: null,
      postal_code: null,
      provider_id:[-1],
      subprovider_id: [{value: -1, disabled: true}]
    })

    this.subscriptions.push(
      this.policyForm.controls['provider_id'].valueChanges.subscribe((id) => {

        this.policyForm.controls.subprovider_id.setValue(-1)
        if(id > 0 ){

           this.subcompanies = [...[{value:-1,label:'Sin asignar'}],...this.companies.find(c => c.value === id).subcompanies.map(subcompanies => { return { value: subcompanies.id, label: subcompanies.name } })];

         //si se ha elegido compañía y el nº de poliza no tiene validador, se le añade
          if(!this.policyForm.controls.number.validator){
            this.policyForm.controls.number.addValidators(conditionalCompareValidator('','provider_id', -1));
            this.policyForm.controls.number.updateValueAndValidity();
          }

          this.tab.modifiedData = true;

          this.policyForm.controls.subprovider_id.enable()
        }else{
          this.policyForm.controls.subprovider_id.disable();

          //si se quita la compañía, se le quita el validador al nº de póliza
          if(this.policyForm.controls.number.validator){
            this.policyForm.controls.number.clearValidators();
            this.policyForm.controls.number.updateValueAndValidity();
          }

          this.subcompanies = [{value:-1,label:'Sin asignar'}]
        }


      })
    );

    this.subscriptions.push(
      this.policyForm.controls['number'].valueChanges.subscribe((number) => {

        if(number === ''){
          //si se borra el nº de póliza se le quita el validador a la compañía
          if(this.policyForm.controls.provider_id.value === -1 && this.policyForm.controls.provider_id.validator) {
            this.policyForm.controls.provider_id.clearValidators()
            this.policyForm.controls.provider_id.updateValueAndValidity()
          }

        } else {
          //si se escribe el nº de póliza se añade el validador a la compañía
          if(!this.policyForm.controls.provider_id.validator){
            this.policyForm.controls.provider_id.addValidators(conditionalCompareValidator(-1,'number', ''));
            this.policyForm.controls.provider_id.updateValueAndValidity();
          }
        }
        console.log(this.policyForm.controls.provider_id)

      })
    );


    this.addressForm = this.fb.group({
      address:[''],
      postalcode: this.fb.group({
        province_id:[null],
        province:[{value:null, disabled: false}],
        population_id:[null],
        population:[{value:null, disabled: false}],
        description:[{value:null, disabled: false}],
        id:[null]
      })
    });


    this.tabChanges();
  }

  getDropdowns() {

    this.loading = true;

    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          let dropdown = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.new_register_dropdowns));
          this.companies = [...this.companies, ...dropdown.company_with_subcompanies.map(comp => { return { value: comp.id, label: comp.name, subcompanies: comp.subcompanies } })];

          this.loading = false;
        }, error => {
          this.loading = false;
        }
      )
    );
  }

  selectLocation(){
    this.modalRefAddress = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccionar Población',
              list_headers: [
                'CP',
                'Población',
                'Provincia',
              ],
              list_fields: [
                { entity: 'postalcode', field: 'description'},
                { entity: 'postalcode', field: 'population' },
                { entity: 'postalcode', field: 'province' },
              ],
              list_entity: 'postalcode',
              list_endpoint: 'postalcodes',
            }
          })

      this.subscriptions.push(
        this.modalRefAddress.content.selectedRegister.subscribe(register => {
          if(register) {
            this.formFunctions.rellenarFormulario(this.addressForm.controls.postalcode, register);
            this.addressForm.markAsDirty();
          }
        })
      );

      this.modalService.close.subscribe(() => this.modalRefAddress.content.onClosed())
  }

  fillPolicyAddress(){
      this.policyForm.patchValue({
        population: this.addressForm.controls.postalcode['controls'].population.value,
        province: this.addressForm.controls.postalcode['controls'].province.value,
        postal_code: this.addressForm.controls.postalcode['controls'].description.value,
        address: this.addressForm.controls.address.value,
      });
      this.policyForm.markAsDirty();
  }

  onSubmit(){

    this.clientForm.markAllAsTouched();
    this.policyForm.markAllAsTouched();
    if(this.clientForm.valid && this.policyForm.valid){
      this._buttons.setLoading(true);


      const params:{[key:string]:any} = {};
      this.formFunctions.fillFormParams(this.clientForm, 'client', params);

      if(this.addressForm.dirty) {
        let addressValues = this.addressForm.value;

        params.clientaddress = {
          address: addressValues.address,
          postalcode_id: addressValues.postalcode.id,
          province_id: addressValues.postalcode.province_id,
          population_id: addressValues.postalcode.population_id,
        }
      }

      if(this.policyForm.dirty) {
        let policyForm = this.policyForm.value;
        params.policy = {
          number: policyForm.number,
          address: policyForm.address,
          population: policyForm.population,
          province: policyForm.province,
          postal_code: policyForm.postal_code,
          provider_id: policyForm.provider_id,
          subprovider_id: (policyForm.subprovider_id > -1) ? policyForm.subprovider_id : null,
        }
      }



      console.log(params)
      this.subscriptions.push(
        this._api.post('clients',JSON.stringify(params)).subscribe(
          response => {
              let client = response.response.client;

              this._buttons.reloadListTab(this.data.saveEndpoint)
              this._buttons.setLoading(false);
              this._notification.notificacionOK(this._notification.getInsertMsg())

              //Abre el modal de apertura de registro
              this.modalRef = this.modalService.show(OpenRegisterComponent, {
                  keyboard: false,
                  ignoreBackdropClick: true,
                  class: 'modal-dialog',
                  data: {
                    heading: 'Registro Creado',
                    text: 'Registro creado correctamente, ¿desea acceder al registro?',
                    register: client,
                    form_entity: this.data.entity,
                  }
                })

                this._tabs.removeTabByTabId(this.tab.tabId)
        },
        error => {
          this._buttons.setLoading(false);
       })
      );
    }
  }
}

