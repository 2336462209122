<div class="container-fluid fondo">
  <div class="container load">

    <mdb-progress-bar class="progress primary-color-dark" mode="indeterminate" ></mdb-progress-bar>
    <picture>
    <img src="assets/images/logo_pga_software_sm.png"  class="rounded mx-auto d-block mt-5">
  </picture>

  </div>

</div>
