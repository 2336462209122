<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset">
    <!--PESTAÑA INFORMACION-->
    <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
      <ng-container *ngIf="formWorkorder">
        <form [formGroup]="formWorkorder">
          <div class="row mt-3">
            <div class="col-lg-2">
              <div class="md-form md-outline" mdbtooltip="emission_date">
                <mdb-date-picker [inline]="true" name="emission_date" [options]="dateformats.opcionesMDBDatePicker" id="emission_date" [label]="'Fecha Emisión'" [placeholder]="" formControlName="emission_date" required></mdb-date-picker>
              </div>
            </div>

            <div class="col-lg-1">
              <ng-container *ngIf="dropdowndata; else seriefield">
                <div class="md-form">
                    <app-dropdowns [label]="(serie_entity.labels.form | titlecase)" [type]="serie_entity.entity" [dropdowndata]="dropdowndata"
                      [disabled]="true"
                      [init_value]="formWorkorder.controls.serie.get('id').value"
                      [activefilter]="true"
                      [addFirstRegister]="true"
                      [label_field]="'description'"
                      [outline] = "true"
                      [appendToBody]="true"
                      (selectedValue)="changeSelect(serie_entity.entity, $event)"
                      >
                    </app-dropdowns>
                </div>
              </ng-container>
              <ng-template #seriefield>
                <div class="md-form md-outline" formGroupName="serie">
                  <input mdbInput type="text" id="seriename" class="form-control form-control-sm" placeholder="" formControlName="description">
                  <label for="seriename">{{serie_entity.labels.form | titlecase}}</label>
                </div>
              </ng-template>
            </div>
            <div class="col-lg-2">
              <div class="md-form md-outline" >
                <input mdbInput type="text"  id="workordernumber" class="form-control form-control-sm" formControlName="number" placeholder="" readonly="true">
                <label for="workordernumber">Número</label>
              </div>
            </div>

            <div class="col-lg-1" formGroupName="typeexport">
              <ng-container *ngIf="dropdowndata; else typeexportfield">
                <div class="md-form">
                  <app-dropdowns [label]="'Exportación'" [type]="'typeexport'" [dropdowndata]="dropdowndata"
                  [disabled]="true"
                  [init_value]="formWorkorder.controls.typeexport.get('id').value"
                  [activefilter]="false"
                  [outline] = "true"
                  [appendToBody]="true"
                  (selectedValue)="changeSelect('typeexport', $event)">
                </app-dropdowns>
              </div>
              </ng-container>
              <ng-template #typeexportfield>
                <div class="md-form md-outline">
                  <input mdbInput type="text" id="typeexport" class="form-control form-control-sm" placeholder="" formControlName="name">
                  <label for="typeexport">Exportación</label>
                </div>
              </ng-template>
            </div>

            <div class="col-lg-3">
              <div class="md-form md-outline" formGroupName="client">
                <input mdbInput type="text" id="clientname" class="form-control form-control-sm" formControlName="name_complete" placeholder="" readonly="true">
                <label for="clientname">{{client_entity.labels.form | titlecase}}</label>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="md-form md-outline">
                <input mdbInput type="text" id="workorder_address" class="form-control form-control-sm" formControlName="workorder_address" placeholder="" readonly="true">
                <label for="workorder_address">Dirección</label>
              </div>
            </div>






          </div>
          <div class="row mt-2">
            <div class="col-lg-2">
              <div class="md-form md-outline">
                <textarea rows="7" type="text" id="description" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="description"></textarea>
                <label for="description">Descripción</label>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="md-form md-outline">
                <textarea rows="7" type="text" id="footer" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="footer"></textarea>
                <label for="footer">Pie</label>
              </div>
            </div>

            <div class="col-lg-2">
              <div class="md-form md-outline">
                <textarea rows="7" type="text" id="workdescription" class="md-textarea form-control form-control-sm" mdbInput placeholder="" formControlName="work_description"></textarea>
                <label for="workdescription">Trabajos</label>
              </div>
            </div>

            <div class="col-6 pr-0 pl-0">
              <div class="row">
                <div class="col-lg-6">
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="provider_name" class="form-control form-control-sm" formControlName="workorder_provider_name" placeholder="" readonly="true">
                    <label for="provider_name">Proveedor</label>
                  </div>
                </div>

                <div class="col-lg-4" formGroupName="claim">
                  <div class="md-form md-outline" formGroupName="user">
                    <input mdbInput type="text" id="claimusername" class="form-control form-control-sm" placeholder="" formControlName="name">
                    <label for="claimusername">Tramitador {{claim_entity.labels.form | titlecase}}</label>
                  </div>
                </div>

                <div class="col-lg-2" >
                  <div class="md-form md-outline" formGroupName="claim">
                    <input mdbInput type="text" id="claimnumber" class="form-control form-control-sm" placeholder="" formControlName="number">
                      <label for="claimnumber">Nº {{claim_entity.labels.form | titlecase}}</label>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-6" formGroupName="firmaddress">
                  <ng-container *ngIf="dropdowndata?.firm_addresses; else firmaddress">
                    <div class="md-form">
                      <app-dropdowns [label]="'Dirección Empresa'" [type]="'firm_addresses'" [dropdowndata]="dropdowndata"
                      [disabled]="disableChanges()"
                      [label_field]="'address_complete'"
                      [init_value]="formWorkorder.controls.firmaddress.get('id').value"
                      [activefilter]="false"
                      [outline] = "true"
                      [appendToBody]="true"
                      (selectedValue)="changeSelect('firmaddress', $event)">
                    </app-dropdowns>
                  </div>
                  </ng-container>
                  <ng-template #firmaddress>
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="firmaddress_complete" class="form-control form-control-sm" placeholder="" formControlName="address_complete">
                      <label for="firmaddress_complete">Dirección Empresa</label>
                    </div>
                  </ng-template>
                </div>

                <div class="col-lg-4" formGroupName="typecondition">
                  <ng-container *ngIf="dropdowndata; else conditionfield">
                    <div class="md-form">
                      <app-dropdowns [label]="'Estado'" [type]="'typecondition'" [dropdowndata]="dropdowndata"
                      [disabled]="disableChanges()"
                      [init_value]="formWorkorder.controls.typecondition.get('id').value"
                      [activefilter]="false"
                      [outline] = "true"
                      (selectedValue)="changeSelect('typecondition', $event)"
                      >
                    </app-dropdowns>
                  </div>
                  </ng-container>
                  <ng-template #conditionfield>
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="typeconditionname" class="form-control form-control-sm" placeholder="" formControlName="name">
                      <label for="typeconditionname">Estado</label>
                    </div>
                  </ng-template>
                </div>

                <div class="col-lg">
                  <ng-container *ngIf="dropdowndata; else userfield">
                    <div class="md-form">
                      <app-dropdowns [label]="'Tramit. '+(data.entity_label | titlecase)" [type]="user_entity.entity" [dropdowndata]="dropdowndata"
                        [disabled]="disableChanges()"
                        [init_value]="formWorkorder.controls.user.get('id').value"
                        [activefilter]="true"
                        (selectedValue)="changeSelect(user_entity.entity, $event)"
                        [outline]="true">
                      </app-dropdowns>
                    </div>
                  </ng-container>
                  <ng-template #userfield>
                    <div class="md-form md-outline" formGroupName="user">
                      <input mdbInput type="text" id="username" class="form-control form-control-sm" placeholder="" formControlName="name">
                      <label for="username">Tramit. {{data.entity_label | titlecase}}</label>
                    </div>
                  </ng-template>
                </div>

              </div>
              <div class="row mt-2">
                <div class="col-lg-6" formGroupName="typestatusonmobile">
                  <ng-container *ngIf="typestatusonmobileUnblock; else statusonmobilefield">
                    <div class="md-form">
                      <app-dropdowns [label]="'Estado App'" [type]="'typestatusonmobile'" [dropdowndata]="dropdowndata"
                      [disabled]="disableChanges()"
                      [init_value]="formWorkorder.controls.typestatusonmobile.get('id').value"
                      [activefilter]="false"
                      [outline] = "true"
                      (selectedValue)="changeSelect('typestatusonmobile', $event)"
                      >
                    </app-dropdowns>
                  </div>
                  </ng-container>
                  <ng-template #statusonmobilefield>
                    <div class="md-form md-outline input-group mb-2">
                      <input mdbInput type="text" id="typestatusonmobile" class="form-control form-control-sm typestatusonmobile" placeholder="" formControlName="name">
                      <label for="typestatusonmobile">Estado App</label>
                      <div class="input-group-append" *ngIf="!disableChanges()">
                        <button mdbBtn color="grey" size="sm" class="m-0 px-3 py-2 typestatusonmobile" type="button" id="button-addon-lock" mdbWavesEffect (click)="unblockModal()">
                          <mdb-icon fas icon="lock"></mdb-icon>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </div>

                
                <div class="col-lg-4">
                  <ng-container *ngIf="dropdowndata; else guildfield">
                    <div class="md-form">
                        <app-dropdowns [label]="(guild_entity.labels.form | titlecase)" [type]="guild_entity.entity" [dropdowndata]="dropdowndata"
                          [disabled]="disableChanges()"
                          [init_value]="formWorkorder.controls.guild.get('id').value"
                          [activefilter]="true"
                          [addFirstRegister]="true"
                          [outline] = "true"
                          [appendToBody]="true"
                          (selectedValue)="changeSelect(guild_entity.entity, $event)"
                          >
                        </app-dropdowns>
                    </div>
                  </ng-container>
                  <ng-template #guildfield>
                    <div class="md-form md-outline" formGroupName="guild">
                      <input mdbInput type="text" id="guildname" class="form-control form-control-sm" placeholder="" formControlName="name">
                      <label for="guildname">{{guild_entity.labels.form | titlecase}}</label>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>


          </div>
          <div class="row mt-3 ">
            <div class="col">
              <app-detail-lines #detailComponent [master]="workorder" [maxHeight]="'23rem'" [taxes]="dropdowndata?.taxe" [details]="detail" [employees]="employees"
              [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="disableChanges()" (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
            </div>
          </div>
        </form>
    </ng-container>

    <div class="row custom-fixed-bottom" *ngIf="current_appointment">
      <div class="col-12">
        <mdb-accordion [multiple]="false">
          <mdb-accordion-item [collapsed]="true" [customClass]="'border-1'" #SBItemComponent>
            <mdb-accordion-item-head [customClass]="'grey lighten-3 small appontments-accordion'">
              <mdb-icon fad icon="calendar" size="lg" class="mr-1 text-primary"></mdb-icon>Citas del {{data.entity_label}}
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
              <div class="table-history">
                <table mdbTable mdbTableScroll scrollY="true" maxHeight="17rem" stickyHeader= "true" hover="true" small="false">
                  <thead>
                      <tr>
                        <th scope="col">Inicio</th>
                        <th scope="col">Fin</th>
                        <th scope="col">Cabecera</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Estado</th>
                        <th scope="col"></th>
                      </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let appointment of workorder?.appointments; let i=index">
                      <tr mdbTableCol>
                        <td>{{appointment.starttime | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>{{appointment.endtime | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>{{appointment.head}}</td>
                        <td>{{appointment.body}}</td>
                        <td>
                          <ng-container *ngIf="appointment.finished == 0; else finished">
                            Abierta
                          </ng-container>
                          <ng-template #finished>
                           Finalizada
                          </ng-template>
                        </td>
                        <td>
                          <a (click)="goToAppointmentTab()"  mdbTooltip="Editar" placement="top" *ngIf="appointment.finished == 0">
                            <mdb-icon fad icon="pencil" class="blue-text mr-1"></mdb-icon>
                          </a>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
        </mdb-accordion>
      </div>
     </div>
  </mdb-tab>

  <!--SECCION documentos-->
  <mdb-tab heading="<i class='fas fa-image mr-2'></i> Documentos">
    <app-workorder-documents [workorder_id]="id" [readonly]="disableChanges()" [claim_id]="workorder?.claim_id" [workorder_documents]="documents" (reloadDocuments)="reload('documents')"></app-workorder-documents>
  </mdb-tab>

  <!--SECCION OPERARIOS / CITAS-->

  <mdb-tab heading="<i class='fas fa-user-hard-hat'></i> <i class='fas fa-calendar-check'></i> Operarios y Citas">
    <app-employees-appointments-workorder #appointmentComponent [readonly]="disableChanges()" [dropdowndata]="dropdowndata" [all_employees]="dropdowndata?.employee" [workorder_employees]="employees" [workorder_appointments]="appointments" (detailsChanged)="tab.modifiedData = true"></app-employees-appointments-workorder>
  </mdb-tab>

  <!--SECCION MENSAJES-->
  <mdb-tab heading="<i class='fas fa-comments'></i> Mensajes">
    <div class="mt-3">
      <app-workorder-messages #messagesComponent [readonly]="disableChanges()" [workorder_id]="id" [messages]="messages" (reloadMessages)="reload('messages')"></app-workorder-messages>
    </div>
  </mdb-tab>

</mdb-tabset>

