import { TitleCasePipe } from "@angular/common";
import { ClaimFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class ClaimModel extends EntityBaseModel  {

  private readonly license_alert: 'Ha llegado al nº máximo de Expedientes';
  protected readonly icon = {
    form: 'folder-open',
      list: 'folders'
  }

  setModelData(models:any) {
    models.claim.icon = this.icon;

    models.claim.form_tabData = {
      entity: models.claim.entity,
      entity_permissions: models.claim.permissions,
      saveEndpoint: models.claim.saveEndpoint,
      entity_label: models.claim.labels.form,
      license_alert: this.license_alert,
      new_register_dropdowns:['guild', 'typeprovider', 'repairman', 'administrator'],
      edit_register_dropdowns: ['user','agent','taxe','guild','employee','typecondition','typeprovider', 'repairman','administrator']
    }

    models.claim.list_tabData = {
      entity: models.claim.entity,
      entity_permissions: models.claim.permissions,
      license_alert: this.license_alert,
      listParams: {
        list_entity: models.claim.list_entity,
        list_label: models.claim.labels.list,
        limit: 10,
        headers: [
          'Número',
          'Nº Aviso',
          'Fecha encargo',
          'Proveedor',
          titleCasePipe.transform(models.client.labels.form),
          'Dirección',
          'Tramitador',
        ],
        fields: [
          {entity: models.claim.entity, field: 'number'},
          {entity: models.claim.entity, field: 'number_provider'},
          {entity: models.claim.entity, field: 'order_date'},
          {entity: 'provider', field: 'name', custom: true, show_with: {entity: 'subprovider', field:'name'}},
          {entity: models.client.entity, field: 'name_complete'},
          {entity: 'clientaddress', field: 'address_complete'},
          {entity: models.user.entity, field: 'name'}
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new ClaimFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME[models.claim.entity]
      }
    }
  }



}
