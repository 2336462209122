

export class PostalCodesFilters {



  public staticFilters;
  public optionalFilters;

  constructor(){

    this.staticFilters = [
      {
        entity: 'postalcode',
        field: {
          name: 'description',
          type: 'text',
          label: 'CP',
          width: 3
        }
      },
      {
        entity: 'postalcode',
        field: {
          name: 'population',
          type: 'text',
          label: 'Población',
          width: 3
        }
      },
      {
        entity: 'postalcode',
        field: {
          name: 'province',
          type: 'text',
          label: 'Provincia',
          width: 3
        }
      },
    ]

  }


}

