<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <form [formGroup]="formNewLiquidation">
    <div style="margin: 10px;">
      <div class="row mt-3">
        <div class="col-4">
          <div class="row">
            <div class="col-lg">
              <div class="md-form md-outline">
                <input type="text" name="employee_name" formControlName="employee_name" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                <label for="employee_name" class="">{{employee_entity.labels.form | titlecase}}</label>
                <mdb-error *ngIf="formNewLiquidation.controls.employee_name.invalid && (formNewLiquidation.controls.employee_name.dirty || formNewLiquidation.controls.employee_name.touched)">Campo Obligatorio</mdb-error>            
              </div>
            </div>
            <div class="md-form md-outline">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [mdbTooltip]="'Seleccionar '+(employee_entity.labels.form | titlecase)" placement="top" id="seleccionarEmpleado" mdbWavesEffect (click)="selectEmployee()">            
                <mdb-icon fas [icon]="employee_entity.icon.form" size="lg"></mdb-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+serie_entity.labels.form+':'" [label]="(serie_entity.labels.form |titlecase)" formControlName = "serie_id">
              <mdb-select-option *ngFor="let serie of dropdowns?.serie" [value]="serie.id">{{ serie.extend_description }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md">
          <div class="md-form md-outline">
            <textarea rows="3" type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
            <label for="description">Descripción</label>
          </div>
        </div>
        <div class="col-md ">
          <div class="md-form md-outline">
            <textarea rows="3" type="text" id="footer" class="md-textarea form-control" placeholder="" mdbInput formControlName="footer"></textarea>
            <label for="footer">Pie</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


  <!--LINEAS A LIQUIDAR-->

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Líneas
  </div>
  <div style="margin: 10px;">
    <div class="row mt-3">

      <div class="col-md">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad icon="list" size="lg" class="mr-1 text-primary"></mdb-icon>Líneas {{data.entity_label | titlecase}}
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                  <tr>
                      <th scope="col" class="buttons-column"></th>
                      <th scope="col">{{workorder_entity.labels.form | titlecase}}</th>
                      <th scope="col">{{claim_entity.labels.form | titlecase}}</th>
                      <th scope="col">Código</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Cantidad</th>
                      <th scope="col">Precio</th>
                      <th scope="col">% IVA</th>
                      <th scope="col">Importe</th>
                  </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let line of inserted_lines; let i=index">
                  <tr mdbTableCol [class.selected]="edit_line?.workorder_line_id === line.workorder_line_id">
                    <td class="buttons-column">
                      <span>
                        <ng-container *ngIf="!edit_line">
                          <a (click)="removeLine(line)">
                            <mdb-icon fad icon="trash" class="red-text mr-1"></mdb-icon>
                          </a>
  
                          <a (click)="editLine(line)">
                            <mdb-icon fad icon="pencil" class="text-default ml-1"></mdb-icon>
                          </a>
                        </ng-container>
                      </span>
                    </td>
                    <ng-container *ngIf="edit_line?.workorder_line_id === line.workorder_line_id; else linedata">
                      <td class="align-middle">{{edit_line.workorder_number}}</td>
                      <td class="align-middle">{{edit_line.claim_number}}</td>
                      <ng-container [formGroup]="lineForm">
                        <td>
                          <div class="md-form md-outline">
                            <input mdbInput type="text" class="form-control form-control-sm" id="linecode" formControlName="code" placeholder="">
                          </div>
                        </td>
                        <td>
                          <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" class="form-control form-control-sm" id="linedescription" formControlName="description" placeholder="">
                            <mdb-error *ngIf="lineForm.controls.description.invalid && (lineForm.controls.description.dirty || lineForm.controls.description.touched)">Campo Obligatorio</mdb-error>
                          </div>
                        </td>
                        <td>
                          <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="number" step="1" class="form-control form-control-sm text-right" id="linequantity" formControlName="quantity" placeholder="">
                            <mdb-error class="line-field-number-error" *ngIf="lineForm.controls.quantity.invalid && (lineForm.controls.quantity.dirty || lineForm.controls.quantity.touched)">
                              <span *ngIf="lineForm.controls.quantity.errors.required">Campo Obligatorio</span>
                              <span *ngIf="lineForm.controls.quantity.errors.min">Valor incorrecto</span>
                            </mdb-error>
                          </div>
                        </td>
                        <td>
                          <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="number" step="0.5" class="form-control form-control-sm text-right" id="linecost" formControlName="cost" placeholder="">
                            <mdb-error class="line-field-number-error" *ngIf="lineForm.controls.cost.invalid && (lineForm.controls.cost.dirty || lineForm.controls.cost.touched)">
                              <span *ngIf="lineForm.controls.cost.errors.required">Campo Obligatorio</span>
                              <span *ngIf="lineForm.controls.cost.errors.min">Valor incorrecto</span>
                            </mdb-error>
                          </div>
                        </td>
                        <td>
                          <select class="m-0 browser-default custom-select selected" formControlName="taxe_id">
                            <option *ngFor="let taxe of dropdowns?.taxe" [value]="taxe.id">{{taxe.value}} %</option>
                          </select>
                        </td>
                        <td>
                          <div class="md-form md-outline">
                            <input mdbInput type="text" class="form-control form-control-sm text-center total-input" id="linetotal" formControlName="total" placeholder="">
                          </div>
                        </td>
                      </ng-container>
                    </ng-container>
                    <ng-template #linedata>
                      <td><span>{{line.workorder_number}}</span></td>
                      <td><span>{{line.claim_number}}</span></td>
                      <td><span>{{line.code}}</span></td>
                      <td><span>{{line.description}}</span></td>
                      <td><span>{{line.quantity  | number:'1.2-2':'es'}}</span></td>
                      <td><span>{{line.cost  | number:'1.2-2':'es'}} €</span></td>
                      <td><span>{{getTaxeValueFromId(line.taxe_id) | number:'1.0-0':'es'}}%</span></td>
                      <td><span>{{line.total  | number:'1.2-2':'es'}} €</span></td>
                    </ng-template>
                  </tr>
                  <ng-container *ngIf="edit_line?.workorder_line_id === line.workorder_line_id">
                    <tr class="selected">
                        <td [colSpan]="12" class="table-cell-save">
                            <a (click)="saveLine(line)">
                                <mdb-icon fad icon="save" class="text-default mr-1"></mdb-icon>
                            </a>
                            <a (click)="clearEdit()">
                                <mdb-icon fad icon="undo-alt" class="red-text ml-1"></mdb-icon>
                            </a>


                        </td>
                    </tr>
                </ng-container>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad icon="list-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Líneas Liquidables del {{employee_entity.labels.form | titlecase}}
              <a class="ml-2"><mdb-icon fad icon="search" size="lg" class="mr-1 text-primary" (click)="activeFilters()"></mdb-icon></a>
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <div class="container-fluid" *ngIf="available_lines?.length>0">
              <ng-container *ngIf="FiltersActive;">
                <div class="row">
                  <div class="col">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="filterWorkorder" [(ngModel)]="filterWorkorder" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                      <label for="filterWorkorder">{{workorder_entity.labels.form | titlecase}}</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="filterClaim" [(ngModel)]="filterClaim" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                      <label for="filterClaim">{{claim_entity.labels.form | titlecase}}</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="filterCode" [(ngModel)]="filterCode" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                      <label for="filterCode">Código</label>
                    </div>
                  </div>
                  <div class="col-1">
                    <a class="blue-text" (click)="clearFilters()" *ngIf="filterCode != '' || filterClaim != '' || filterWorkorder != ''">
                      <mdb-icon fas icon="times" size="lg" class="my-2"></mdb-icon></a>
                  </div>
                </div>
              </ng-container>
            </div>

            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                  <tr>
                      <th scope="col" class="buttons-column"></th>
                      <th scope="col">{{workorder_entity.labels.form | titlecase}}</th>
                      <th scope="col">{{claim_entity.labels.form | titlecase}}</th>
                      <th scope="col">Código</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Cantidad</th>
                      <th scope="col">Precio</th>
                      <th scope="col">% IVA</th>
                      <th scope="col">Importe</th>
                  </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let line of filter_lines; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="addLine(line)">
                        <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                      </a>
                    </td>
                    <td><span>{{line.workorder_number}}</span></td>
                    <td><span>{{line.claim_number}}</span></td>
                    <td><span>{{line.code}}</span></td>
                    <td><span>{{line.description}}</span></td>
                    <td><span>{{line.quantity  | number:'1.2-2':'es'}}</span></td>
                    <td><span>{{line.cost  | number:'1.2-2':'es'}} €</span></td>
                    <!--<td>{{line.taxe.value | number:'1.0-0':'es'}}%</td>-->
                    <td><span>{{getTaxeValueFromId(line.taxe_id)| number:'1.0-0':'es'}}%</span></td>
                    <td><span>{{line.total_cost  | number:'1.2-2':'es'}} €</span></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </div>
</div>



<!--
  <app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formNewLiquidation">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(employee_entity.labels.form | titlecase)+' y Líneas'">
            <div class="row">
              <div class="col-md-4 mt-2">
                <span class="character-counter">{{employee_entity.labels.form | titlecase}}</span>
                <div class="md-form input-group mb-3">
                  <div class="input-group-prepend">
                    <div class="btn-group" mdbDropdown>
                      <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [mdbTooltip]="'Seleccionar '+(employee_entity.labels.form | titlecase)" placement="top"
                      id="seleccionarCliente" mdbWavesEffect (click)="selectEmployee()">
                      <mdb-icon fas [icon]="employee_entity.icon.form"></mdb-icon>
                    </button>
                    </div>
                  </div>
                  <input type="text" name="employee_name" formControlName="employee_name" mdbValidate [validateSuccess]="false"
                class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                <mdb-error *ngIf="formNewLiquidation.controls.employee_name.invalid && (formNewLiquidation.controls.employee_name.dirty || formNewLiquidation.controls.employee_name.touched)">
                  Campo Obligatorio
                </mdb-error>
                </div>
              </div>
              <div class="col-md">
                <mdb-card class="z-depth-0">
                  <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                      <mdb-icon fad icon="list-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Líneas Liquidables del {{employee_entity.labels.form | titlecase}}
                  </mdb-card-header>
                  <mdb-card-body class="scroll-card-body">
                    <div class="container-fluid" *ngIf="available_lines?.length>0">
                      <div class="row">
                        <div class="col">
                          <div class="md-form md-outline">
                            <input mdbInput type="text" id="filterWorkorder" [(ngModel)]="filterWorkorder" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                            <label for="filterWorkorder">{{workorder_entity.labels.form | titlecase}}</label>
                          </div>
                        </div>
                        <div class="col">
                          <div class="md-form md-outline">
                            <input mdbInput type="text" id="filterClaim" [(ngModel)]="filterClaim" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                            <label for="filterClaim">{{claim_entity.labels.form | titlecase}}</label>
                          </div>
                        </div>
                        <div class="col">
                          <div class="md-form md-outline">
                            <input mdbInput type="text" id="filterCode" [(ngModel)]="filterCode" [ngModelOptions]="{standalone: true}" placeholder="" class="form-control" (keyup)="filterLines()">
                            <label for="filterCode">Código</label>
                          </div>
                        </div>
                        <div class="col-1">
                          <a class="blue-text" (click)="clearFilters()" *ngIf="filterCode != '' || filterClaim != '' || filterWorkorder != ''">
                            <mdb-icon fas icon="times" size="lg" class="my-2"></mdb-icon></a>
                        </div>
                      </div>
                    </div>

                    <table mdbTable small="true">
                      <thead>
                          <tr>
                              <th scope="col"></th>
                              <th scope="col">{{workorder_entity.labels.form | titlecase}}</th>
                              <th scope="col">{{claim_entity.labels.form | titlecase}}</th>
                              <th scope="col">Código</th>
                              <th scope="col">Descripción</th>
                              <th scope="col">Cantidad</th>
                              <th scope="col">Precio</th>
                              <th scope="col">% IVA</th>
                              <th scope="col">Importe</th>
                          </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let line of filter_lines; let i=index">
                          <tr mdbTableCol>
                            <td>
                              <a (click)="addLine(line)">
                                <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                              </a>
                            </td>
                            <td>{{line.workorder_number}}</td>
                            <td>{{line.claim_number}}</td>
                            <td>{{line.code}}</td>
                            <td>{{line.description}}</td>
                            <td>{{line.quantity  | number:'1.2-2':'es'}}</td>
                            <td>{{line.cost  | number:'1.2-2':'es'}} €</td>
                            <td>{{line.taxe.value | number:'1.0-0':'es'}}%</td>
                            <td>{{line.total_cost  | number:'1.2-2':'es'}} €</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>

                  </mdb-card-body>
                </mdb-card>
              </div>

            </div>

            <div class="row mt-3">
              <div class="col-md">
                <mdb-card class="z-depth-0">
                  <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                      <mdb-icon fad icon="list" size="lg" class="mr-1 text-primary"></mdb-icon>Líneas {{data.entity_label | titlecase}}
                  </mdb-card-header>
                  <mdb-card-body class="scroll-card-body">
                    <table mdbTable small="true">
                      <thead>
                          <tr>
                              <th scope="col"></th>
                              <th scope="col" class="text-center">{{workorder_entity.labels.form | titlecase}}</th>
                              <th scope="col" class="text-center">{{claim_entity.labels.form | titlecase}}</th>
                              <th scope="col" class="text-center">Código</th>
                              <th scope="col" class="text-center">Descripción</th>
                              <th scope="col" class="text-center">Cantidad</th>
                              <th scope="col" class="text-center">Precio</th>
                              <th scope="col" class="text-center">% IVA</th>
                              <th scope="col" class="text-center">Importe</th>
                          </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let line of inserted_lines; let i=index">
                          <tr mdbTableCol [class.selected]="edit_line?.workorder_line_id === line.workorder_line_id">
                            <td>
                              <ng-container *ngIf="!edit_line">
                                <a (click)="removeLine(line)">
                                  <mdb-icon fad icon="trash" class="red-text mr-1"></mdb-icon>
                                </a>

                                <a (click)="editLine(line)">
                                  <mdb-icon fad icon="pencil" class="text-default ml-1"></mdb-icon>
                                </a>
                              </ng-container>
                            </td>
                            <ng-container *ngIf="edit_line?.workorder_line_id === line.workorder_line_id; else linedata">
                              <td class="align-middle">{{edit_line.workorder_number}}</td>
                              <td class="align-middle">{{edit_line.claim_number}}</td>
                              <ng-container [formGroup]="lineForm">
                                <td>
                                  <div class="md-form md-outline">
                                    <input mdbInput
                                           type="text"
                                           class="form-control form-control-sm"
                                           id="linecode"
                                           formControlName="code"
                                           placeholder="">
                                  </div>
                                </td>
                                <td>
                                  <div class="md-form md-outline">
                                    <input mdbInput mdbValidate [validateSuccess]="false"
                                           type="text"
                                           class="form-control form-control-sm"
                                           id="linedescription"
                                           formControlName="description"
                                           placeholder="">
                                    <mdb-error *ngIf="lineForm.controls.description.invalid && (lineForm.controls.description.dirty || lineForm.controls.description.touched)">
                                      Campo Obligatorio
                                    </mdb-error>
                                  </div>
                                </td>
                                <td>
                                  <div class="md-form md-outline">
                                    <input mdbInput
                                           mdbValidate
                                           [validateSuccess]="false"
                                           type="number"
                                           step="1"
                                           class="form-control form-control-sm text-right"
                                           id="linequantity"
                                           formControlName="quantity"
                                           placeholder="">
                                        <mdb-error class="line-field-number-error" *ngIf="lineForm.controls.quantity.invalid && (lineForm.controls.quantity.dirty || lineForm.controls.quantity.touched)">
                                          <span *ngIf="lineForm.controls.quantity.errors.required">Campo Obligatorio</span>
                                          <span *ngIf="lineForm.controls.quantity.errors.min">Valor incorrecto</span>
                                      </mdb-error>
                                  </div>
                                </td>
                                <td>
                                  <div class="md-form md-outline">
                                    <input mdbInput
                                           mdbValidate
                                           [validateSuccess]="false"
                                           type="number"
                                           step="0.5"
                                           class="form-control form-control-sm text-right"
                                           id="linecost"
                                           formControlName="cost"
                                           placeholder="">
                                      <mdb-error class="line-field-number-error" *ngIf="lineForm.controls.cost.invalid && (lineForm.controls.cost.dirty || lineForm.controls.cost.touched)">
                                          <span *ngIf="lineForm.controls.cost.errors.required">Campo Obligatorio</span>
                                          <span *ngIf="lineForm.controls.cost.errors.min">Valor incorrecto</span>
                                      </mdb-error>
                                  </div>
                                </td>
                                <td>
                                  <select class="m-0 browser-default custom-select selected" formControlName="taxe_id">
                                    <option *ngFor="let taxe of dropdowns?.taxe" [value]="taxe.id">{{taxe.value}} %</option>
                                  </select>
                                </td>
                                <td>
                                  <div class="md-form md-outline">
                                    <input mdbInput
                                           type="text"
                                           class="form-control form-control-sm text-center total-input"
                                           id="linetotal"
                                           formControlName="total"
                                           placeholder="">
                                  </div>
                                </td>
                              </ng-container>
                            </ng-container>
                            <ng-template #linedata>
                              <td class="text-center">{{line.workorder_number}}</td>
                              <td class="text-center">{{line.claim_number}}</td>
                              <td class="text-center">{{line.code}}</td>
                              <td class="text-center">{{line.description}}</td>
                              <td class="text-center">{{line.quantity  | number:'1.2-2':'es'}}</td>
                              <td class="text-center">{{line.cost  | number:'1.2-2':'es'}} €</td>
                              <td class="text-center">{{getTaxeValueFromId(line.taxe_id) | number:'1.0-0':'es'}}%</td>
                              <td class="text-center">{{line.total  | number:'1.2-2':'es'}} €</td>
                            </ng-template>
                          </tr>
                          <ng-container *ngIf="edit_line?.workorder_line_id === line.workorder_line_id">
                            <tr class="selected">
                                <td [colSpan]="12" class="table-cell-save">
                                    <a (click)="saveLine(line)">
                                        <mdb-icon fad icon="save" class="text-default mr-1"></mdb-icon>
                                    </a>
                                    <a (click)="clearEdit()">
                                        <mdb-icon fad icon="undo-alt" class="red-text ml-1"></mdb-icon>
                                    </a>


                                </td>
                            </tr>
                        </ng-container>
                        </ng-container>
                      </tbody>
                    </table>
                  </mdb-card-body>
                </mdb-card>
              </div>

            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="Descripción y Pie">
            <div class="row">
              <div class="col-md mt-2">
                <div class="md-form">
                  <textarea type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
                  <label for="description">Descripción</label>
                </div>
              </div>
              <div class="col-md mt-2">
                <div class="md-form">
                  <textarea type="text" id="footer" class="md-textarea form-control" placeholder="" mdbInput formControlName="footer"></textarea>
                  <label for="footer">Pie</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </form>
</div>
-->