import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-new-claim',
  templateUrl: './new-claim.component.html',
  styleUrls: ['./new-claim.component.scss']
})
export class NewClaimComponent implements OnInit, OnDestroy {
  @Input() tab: Tab
  @Input() data

  private subscriptions: Array<Subscription> = [];

  public loading = false;

  formNewClaim: FormGroup;

  private formFunctions = new FormFunctions;

  private utils = new PGAUtils;
  private dateformats = new DateFormats;
  public datepicker_options = this.dateformats.opcionesMDBDatePicker;

  addresses = [];
  policies = [];
  repairmen = [];
  administrators = [];


  public dropdowns: DropdownsInterface;
  public guilds: Array<any> = [{id: -1, name: 'Sin Asignar'}];
  public provider_types: Array<any> = [{id: -1, name: 'Particular'}];

  modalRefClient: MDBModalRef | null = null;
  modalRefOpenRegister: MDBModalRef | null = null;

  public client_entity = this._entity.entities.client;
  public guild_entity = this._entity.entities.guild;

  constructor(private fb: FormBuilder, private modalService: MDBModalService, private _tabs: TabService, private _api: ApirequestService, private _apifunctions: ApiFunctions, private _buttons: ActionButtonsService,  private _notification: NotificationService, private _entity: EntityService, private _dropdowns: DropdownService) { }
  ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  private unsubscribeAll(){
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.loadDropdowns()


    this.formNewClaim = this.fb.group({
      client_id:[null, [Validators.required]],
      client_name:[{value: '', disabled: false}, [Validators.required]],
      clientaddress_id:[{value: null, disabled: true}, []],
      typeprovider:[{value: -1, disabled: true}],
      provider_id:[null, []],
      subprovider_id:[null, []],
      policy_id:[{value: null, disabled: true}],
      number_provider:[null],
      number_assistance:[null],
      description:[''],
      guild_id:[-1],
      order_date:[this.dateformats.getCurrentDate()]
    })
    this.formNewClaim.controls.order_date.markAsDirty();


    this.subscriptions.push(
      this.formNewClaim.valueChanges.subscribe(
        change => {
          this.tab.modifiedData = true;
        }
      )
    )

    this.subscriptions.push(
      this.formNewClaim.controls.policy_id.valueChanges.subscribe(
        id =>{
          if(id) {
            let policy_data = this.policies.find(p => p.id == id);
            this.formNewClaim.controls.provider_id.setValue(policy_data.provider_id);
            this.formNewClaim.controls.subprovider_id.setValue(policy_data.subprovider_id);
            this.formNewClaim.controls.provider_id.markAsDirty();
            this.formNewClaim.controls.subprovider_id.markAsDirty();
          }
        }
      )
    )

    this.subscriptions.push(
      this.formNewClaim.controls.typeprovider.valueChanges.subscribe(
        type => {

          switch (type) {
            case 1:
              //compañías: habilitar polizas y hacerla obligatoria. deshabilitar address_id y quitar el validador
              this.formNewClaim.controls.policy_id.enable();
              this.formNewClaim.controls.policy_id.setValidators(Validators.required);
              this.formNewClaim.controls.policy_id.updateValueAndValidity();
              this.formNewClaim.controls.provider_id.reset();
              this.formNewClaim.controls.subprovider_id.reset();

              if(this.policies.length>0) {
              // this.formNewClaim.controls.policy_id.setValue(this.policies[0].id);
              }

              this.formNewClaim.controls.clientaddress_id.reset();
              this.formNewClaim.controls.clientaddress_id.clearValidators();
              this.formNewClaim.controls.clientaddress_id.updateValueAndValidity();
              this.formNewClaim.controls.clientaddress_id.disable();
              break;
            case 2:
            case 3:
              this.resetProviderData();
              console.log(this.formNewClaim.controls.provider_id)
              this.formNewClaim.controls.provider_id.setValidators(Validators.required);
              this.formNewClaim.controls.provider_id.updateValueAndValidity();
              break;
            default:
              this.resetProviderData();
              this.formNewClaim.controls.provider_id.clearValidators();
              this.formNewClaim.controls.provider_id.updateValueAndValidity();
              break;
          }
        }
      )
    )
  }

  private resetProviderData(){
    if(this.formNewClaim.controls.clientaddress_id.disabled) {
      this.formNewClaim.controls.clientaddress_id.enable();
      this.formNewClaim.controls.clientaddress_id.setValidators(Validators.required);
      this.formNewClaim.controls.clientaddress_id.updateValueAndValidity();
    }
    this.formNewClaim.controls.provider_id.reset();
    this.formNewClaim.controls.subprovider_id.reset();


    this.formNewClaim.controls.policy_id.reset();
    this.formNewClaim.controls.policy_id.clearValidators();
    this.formNewClaim.controls.policy_id.updateValueAndValidity();
    this.formNewClaim.controls.policy_id.disable();

  }

  get selected_typeprovider(): number {
    return this.formNewClaim.controls.typeprovider.value
  }


  //carga los datos de los desplegables
  loadDropdowns() {

    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          this.dropdowns = this.utils.objectDeepCopy(response.response.dropdown);

          this.dropdowns.typeprovider = [...this.provider_types, ...this.dropdowns?.typeprovider];
          this.dropdowns.guild = [...this.guilds, ...this.dropdowns?.guild];

          this.loading = false;
        },
        error=> {
          this.formNewClaim.disable()
          this.loading = false;
        }
      )
    );

  }

  newClient(){
    this._tabs.addTab('newclient')
  }
  //abre la selección de cliente
  selectClient(){

    this.modalRefClient = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.client_entity.labels.form,
              list_headers: [
                'Activo',
                'Acceso Web',
                'NIF',
                'Nombre y apellidos',
                'Dirección completa',
                'Teléfono',
                'Móvil',
                'Email',
              ],
              list_fields: [
                { entity: this.client_entity.entity, field: 'enabled', type: 'check' },
                { entity: this.client_entity.entity, field: 'web_access', type: 'check' },
                { entity: this.client_entity.entity, field: 'identification_document' },
                { entity: this.client_entity.entity, field: 'name_complete' },
                { entity: 'defaultaddress', field: 'address_complete' },
                { entity: this.client_entity.entity, field: 'phone' },
                { entity: this.client_entity.entity, field: 'mobile' },
                { entity: this.client_entity.entity, field: 'email' },
              ],
              list_entity: this.client_entity.entity,
              list_endpoint: this.client_entity.saveEndpoint,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                },
                {
                  name: 'policies',
                  value: 'true'
                },
              ]
            }
          })

      this.subscriptions.push(
        //recoge los datos del cliente seleccionado
        this.modalRefClient.content.selectedRegister.subscribe(register => {
          this.addresses = [];
          this.policies = [];

          this.formNewClaim.controls.client_id.setValue(register.id);
          this.formNewClaim.controls.client_id.markAsDirty();
          this.formNewClaim.controls.client_name.setValue(register.name_complete);

          let default_address = register.clientaddress.find(address => address.default === 1);
          this.formNewClaim.controls.clientaddress_id.setValue(default_address?.id);
          this.formNewClaim.controls.clientaddress_id.enable();
          this.formNewClaim.controls.clientaddress_id.markAsDirty();

          this.formNewClaim.controls.clientaddress_id.addValidators([Validators.required]);
          this.formNewClaim.controls.clientaddress_id.updateValueAndValidity();
          this.addresses = register.clientaddress;
          this.policies = register.policies;

          if(this.formNewClaim.controls.typeprovider.disabled) {
            this.formNewClaim.controls.typeprovider.enable();
          }

        })
      );

      this.subscriptions.push(
        this.modalService.closed.subscribe(() => this.modalRefClient.content.onClosed())
      );
    }

  onSubmit(){
    this.formNewClaim.markAllAsTouched();

    if(this.formNewClaim.valid) {
      this._buttons.setLoading(true);

      const params:any = {};
      if(this.formNewClaim.controls.typeprovider.dirty){
         this.formNewClaim.controls.typeprovider.markAsPristine();
      }
      this.formFunctions.fillFormParams(this.formNewClaim, this.data.entity, params);

      console.log(params)
      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {

            let claim = response.response.claim;

            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
             this.modalRefOpenRegister = this.modalService.show(OpenRegisterComponent, {
                keyboard: false,
                ignoreBackdropClick: true,
                class: 'modal-dialog',
                data: {
                  heading: 'Registro Creado',
                  text: 'Registro creado correctamente, ¿desea acceder al registro?',
                  register: claim,
                  form_entity: this.data.entity,
                }
              })

              this._tabs.removeTabByTabId(this.tab.tabId)

          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      )
    }
  }
}
