import { Component, HostListener } from '@angular/core';
import { UserPermissionsService } from './main/services/user-permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  public title = 'PGA Gest';

  /**
   * Flag que controla cuando se activa/desactiva la pantalla de cargando aplicación
   */
  public loading_permissions = false;




  constructor(private _userPermissions: UserPermissionsService){
      this.checkPermissions();


  }


  /**
   * Se comprueba si hay que activar/desactivar la pantalla de cargando aplicación
   */
  checkPermissions(): void {
    this._userPermissions.getCheckPermissions().subscribe(
      response =>{
        this.loading_permissions = response;
      },
      err => {
        this._userPermissions.setCheckPermissions(false);
      }
    )
  }
}
