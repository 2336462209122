import { Type } from "@angular/core";
import { Tab } from "src/app/main/tab-models/tab.model";

export class SinglePageBaseModel {
  protected fa_conf = 'far';

  protected icon = 'cog';

  protected header = '';

  get page_icon() {
    return this.icon;
  }

  get page_header() {
    return this.header;
  }



  getTab(component: Type<any>, page_data): Tab {
    let tabData = {...page_data};
    return new Tab(
      component,
      "<i class='"+this.fa_conf+' fa-'+page_data.icon+" fa-lg mr-1'></i>"+tabData.header,
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
      )
  }

}
