
export class ClientFilters {


  public staticFilters;
  public optionalFilters;

  constructor(private entities){
    this.optionalFilters = [
      {
        entity: this.entities.client.entity,
        field: {
          name: 'email',
          type: 'text',
          label: 'Email',
          width: 2
        }
      },
      {
        entity: 'clientaddress',
        field: {
          name: 'province',
          type: 'text',
          label: 'Provincia',
          width: 2
        }
      },
      {
        entity: 'clientaddress',
        field: {
          name: 'population',
          type: 'text',
          label: 'Población',
          width: 2
        }
      },
    ];

    this.staticFilters = [
      {
        entity: this.entities.client.entity,
        field: {
          name: 'enabled',
          type: 'select',
          label: 'Estado',
          width: 1,
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
            {
              value:  0,
              text: 'Inactivo'
            },
            {
              value:  1,
              text: 'Activo'
            }
          ]
        }
      },
      {
        entity: this.entities.client.entity,
        field: {
          name: 'web_access',
          type: 'select',
          label: 'Acceso Web',
          width: 1,
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
            {
              value:  0,
              text: 'No'
            },
            {
              value:  1,
              text: 'Sí'
            }
          ]
        }
      },
      {
        entity: this.entities.client.entity,
        field: {
          name: 'name_complete',
          type: 'text',
          label: 'Nombre',
          width: 2
        }
      },
      {
        entity: 'clientaddress',
        field: {
          name: 'address',
          type: 'text',
          label: 'Dirección',
          width: 2
        }
      },
      {
        entity: 'clientaddress',
        field: {
          name: 'postal_code',
          type: 'text',
          label: 'C. Postal',
          width: 2
        }
      },
      
      {
        entity: this.entities.client.entity,
        field: {
          name: 'identification_document',
          type: 'text',
          label: 'DNI/NIF',
          width: 2
        }
      },
    ]

  }


  }
