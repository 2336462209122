import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { UserPermissionsService } from 'src/app/main/services/user-permissions.service';
import { PGAUtils } from '../../functions/pga-utils';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {

  private utils = new PGAUtils;

  constructor(private router: Router, private _api: ApirequestService, private _userPermissions: UserPermissionsService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (sessionStorage.getItem('isLoggedin')) {
        this.router.navigate(['/login']);
        return false;
      }

      this._userPermissions.setCheckPermissions(true);
      let session = route.paramMap.get('id');
      const parametros = {};
      parametros['alias'] = this.utils.SHA1('akrolis');

      return this._api.post('check-reset/'+session, JSON.stringify(parametros)).pipe(
        map(
          response => {
            this._userPermissions.setCheckPermissions(false);
              return true
          }
        ),
        catchError(err => {
          this.router.navigate(['/login']);
          this._userPermissions.setCheckPermissions(false);
          return throwError( err );
        })
      );
  }



  /* canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  } */

}
