import { TitleCasePipe } from "@angular/common";
import { InvoiceFilters } from "../../data-classes";
import { Tab } from "src/app/main/tab-models/tab.model";
import { EntityBaseModel } from "./entity-base.model";
import { Type } from "@angular/core";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class InvoiceModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'file-invoice-dollar',
    list: 'file-invoice-dollar'
  }


  setModelData(models:any) {

    models.invoice.icon = this.icon;

    models.invoice.modules = ['billing'];

    models.invoice.form_tabData = {
      entity: models.invoice.entity,
      saveEndpoint: models.invoice.saveEndpoint,
      entity_permissions: models.invoice.permissions,
      entity_modules:models.invoice.modules,
      new_register_dropdowns:['taxe', 'guild', 'serie', 'typeprovider', 'company_with_subcompanies', 'repairman', 'administrator'],
      edit_register_dropdowns: ['guild', 'taxe', 'typestate', 'serie', 'typepaymentmethod']
    }

    models.invoice.list_tabData = {
      entity: models.invoice.entity,
      entity_label: models.invoice.labels.form,
      entity_permissions:models.invoice.permissions,
      entity_modules:models.invoice.modules,
      listParams: {
        list_entity: models.invoice.list_entity,
        list_label: models.invoice.labels.list,
        limit: 10,
        headers: [
          'Número',
          titleCasePipe.transform(models.serie.labels.form),
          titleCasePipe.transform(models.client.labels.form),
          titleCasePipe.transform(models.claim.labels.form),
          'Tramitador'
        ],
        fields: [
          {entity: models.invoice.entity, field: 'number'},
          {entity: models.serie.entity, field: 'description'},
          {entity: models.client.entity, field: 'name_complete'},
          {entity: models.claim.entity, field: 'number'},
          {entity: models.user.entity, field: 'name'}
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new InvoiceFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME[models.invoice.entity],
        in_claim: {
          claim_id: null,
          limit:5,
          headers: [
            {label: 'Número', class: 'code-column'},
            {label: titleCasePipe.transform(models.serie.labels.form), class: 'numbers-column'},
            titleCasePipe.transform(models.client.labels.form),
            'Tramitador'
          ],
          fields: [
            {entity: models.invoice.entity, field: 'number'},
            {entity: models.serie.entity, field: 'description'},
            {entity: models.client.entity, field: 'name_complete'},
            {entity: models.user.entity, field: 'name'}
          ],
          lines_limit: 10,
          lines_headers: [
            {header: titleCasePipe.transform(models.invoice.labels.form), class: 'numbers-column'},
            {header: 'Código', class: 'code-column'},
            'Descripción',
            { header: 'Cantidad', class: 'numbers-column'},
            {header: 'Precio', class: 'numbers-column'},
            {header:'%Descuento', class: 'numbers-column'},
            {header:'%Impuesto', class: 'numbers-column'}
          ],
          lines_fields: [
            {
              field: 'invoice_number',
              type: 'none',
            },
            {
              control: 'code',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'description',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'quantity',
              type: 'number',
              default: 1,
              disabled: false
            },
            {
              control: 'price',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'discount',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'taxe_id',
              type: 'select',
              dropdown: 'taxes',
              field_value: 'taxe_value',
              select_value: 'id',
              select_label: 'value',
              addKey: true,
              clear: false,
              listen_changes: true,
              disabled: false,
              default: 1,
            }
          ]
        }
    }


    }
  }


  getNewTab(component: Type<any>, entity_data): Tab {
    let tabData = {...entity_data.form_tabData};
    tabData.newRegister = true;
    tabData.noRecover = true;

    return new Tab(
      component,
      "<i class='"+this.fa_conf.form+' fa-'+entity_data.icon.form+" fa-lg mr-1'></i>Nueva "+titleCasePipe.transform(entity_data.labels.form),
      {
        buttonNew: false,
        buttonDelete: false
      },
      tabData
      )
  }
}
