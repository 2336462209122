<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
    <!--PESTAÑA INFORMACION-->
    <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
        <ng-container *ngIf="formProvider">
            <form [formGroup]="formProvider">
                <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        <div class="row">
                            <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Administrador de fincas activo</b></mdb-checkbox>
                        </div>
                    </mdb-card-header>
                </mdb-card>
                <div class="row mt-2">
                  <div class="col-lg">
                      <div class="md-form md-outline">
                        <input mdbInput type="text" id="cif" formControlName="cif" placeholder="" class="form-control form-control-sm">
                        <label for="cif" class="">CIF</label>
                      </div>
                  </div>
                  <div class="col-lg">
                    <div class="md-form md-outline">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="providername" placeholder="" formControlName="name" class="form-control form-control-sm">
                      <label for="providername" class="">Nombre</label>
                      <mdb-error *ngIf="formProvider.controls.name.invalid && (formProvider.controls.name.dirty || formProvider.controls.name.touched)">Campo obligatorio</mdb-error>
                    </div>
                  </div>
                  <div class="col-lg">
                    <div class="md-form md-outline">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="provideremail" placeholder="" formControlName="email" class="form-control form-control-sm">
                      <label for="provideremail" class="">Email</label>
                      <mdb-error *ngIf="formProvider.controls.email.invalid && (formProvider.controls.email.dirty || formProvider.controls.email.touched)">Introduce un email válido</mdb-error>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="providerphone" formControlName="phone" placeholder="" class="form-control form-control-sm">
                      <label for="providerphone" class="">Teléfono</label>
                    </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form md-outline">
                        <input mdbInput type="text" id="providermobile" formControlName="mobile" placeholder="" class="form-control form-control-sm">
                        <label for="providermobile" class="">Móvil</label>
                      </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-6">
                      <div class="md-form md-outline">
                          <textarea type="text" id="observations" rows="15" formControlName="observations" placeholder="" class="md-textarea form-control" mdbInput></textarea>
                          <label for="observations">Observaciones</label>
                      </div>
                  </div>
                  <div class="col-md-6">
                    <app-gmaps-component #maps></app-gmaps-component>
                  </div>
              </div>
            </form>
        </ng-container>
    </mdb-tab>
  
    <!--PESTAÑA DIRECCIONES-->
    <mdb-tab heading="<i class='fas fa-map-marked-alt mr-2'></i> Direcciones">
      <app-addresses [params]="provider_addresses_params" [readonly]="disableChanges()" (loading)="loading = $event"></app-addresses>
    </mdb-tab>
  </mdb-tabset>


  <!--

<div class="pt-2">

  <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

    <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
        <ng-container *ngIf="formProvider">

              <form [formGroup]="formProvider">
                <div class="row">
                    <div class="col-lg-1">
                        <mdb-checkbox formControlName="enabled" [default]="true" >Activo</mdb-checkbox>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="cif" formControlName="cif" placeholder=""
                                class="form-control form-control-sm">
                            <label for="cif" class="">CIF</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="providername" placeholder=""
                                formControlName="name" class="form-control form-control-sm">
                            <label for="providername" class="">Nombre</label>
                            <mdb-error
                                *ngIf="formProvider.controls.name.invalid && (formProvider.controls.name.dirty || formProvider.controls.name.touched)">
                                Campo obligatorio
                            </mdb-error>
                        </div>
                    </div>

                    <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="providerphone" formControlName="phone" placeholder=""
                              class="form-control form-control-sm">
                          <label for="providerphone" class="">Teléfono</label>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="providermobile" formControlName="mobile" placeholder=""
                              class="form-control form-control-sm">
                          <label for="providermobile" class="">Móvil</label>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="provideremail" placeholder=""
                              formControlName="email" class="form-control form-control-sm">
                          <label for="provideremail" class="">Email</label>
                          <mdb-error
                              *ngIf="formProvider.controls.email.invalid && (formProvider.controls.email.dirty || formProvider.controls.email.touched)">
                              Introduce un email válido</mdb-error>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md">
                    <app-addresses [params]="provider_addresses_params" [readonly]="disableChanges()" (loading)="loading = $event"></app-addresses>
                    </div>
                </div>

              </form>
          </ng-container>
            </mdb-tab>
        </mdb-tabset>
</div>
-->