import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { ExpenseFilters } from "../../data-classes";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class ExpenseModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'euro-sign',
    list: 'euro-sign'
  }

  setModelData(models:any) {
    models.expense.icon = this.icon;

    models.expense.form_tabData = {
      entity: models.expense.entity,
      entity_permissions: models.expense.permissions,
      saveEndpoint: models.expense.saveEndpoint,
      entity_label: models.expense.labels.form,
      entity_modules:models.expense.modules,
    }

    models.expense.list_tabData = {
      entity: models.expense.entity,
      entity_permissions: models.expense.permissions,
      entity_modules:models.expense.modules,
      listParams: {
        list_entity: models.expense.list_entity,
        list_label: models.expense.labels.list,
        limit: 10,
        headers: [
          'Número',
          'Código',
          titleCasePipe.transform(models.claim.labels.form),
          'Concepto',
          'Bruto',
          'Importe',
          'Situación',
        ],
        fields: [
          {entity: models.expense.entity, field: 'number'},
          {entity: models.expense.entity, field: 'code'},
          {entity: models.claim.entity, field: 'number'},
          {entity: models.expense.entity, field: 'concept'},
          {entity: models.expense.entity, field: 'total_base'},
          {entity: models.expense.entity, field: 'total'},
          {entity: 'typestate', field: 'name'},
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new ExpenseFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME.general,
        in_claim: {
          claim_id: null,
          limit: 5,
          headers: [
            'Número',
            'Código',
            'Concepto',
            'Bruto',
            'Importe',
            'Situación',
          ],
          fields: [
            {entity: models.expense.entity, field: 'number'},
            {entity: models.expense.entity, field: 'code'},
            {entity: models.expense.entity, field: 'concept'},
            {entity: models.expense.entity, field: 'total_base'},
            {entity: models.expense.entity, field: 'total'},
            {entity: 'typestate', field: 'name'},
          ],
        }
      }
    }
  }

}
