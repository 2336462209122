import { TitleCasePipe } from "@angular/common";
import { TaskComponent } from "src/app/main/components/dynamic-modals/task/task.component";
import { TasksComponent } from "src/app/main/lists/tasks/tasks.component";
import { Tab } from "src/app/main/tab-models/tab.model";
import { TaskFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";

const titleCasePipe = new TitleCasePipe();

export class TaskModel extends EntityBaseModel {

  protected readonly icon = {
    form: 'tasks',
    list: 'tasks'
  }

  setModelData(models:any) {

    models.task.icon = this.icon;

    models.task.form_tabData = {
      new_register_dropdowns: ['typecondition', 'tramit'],
      edit_register_dropdowns: ['typecondition', 'tramit']
    }

    models.task.list_tabData = {
      entity: models.task.entity,
      entity_label: models.task.labels.form,
      entity_permissions:models.task.permissions,
      saveEndpoint: models.task.saveEndpoint,
      modal_form: TaskComponent,
      modal_class: 'modal-lg',
      listParams: {
        list_entity: models.task.list_entity,
        list_label: models.task.labels.list,
        limit: 10,
        headers: [
          'Fecha',
          'Emisor',
          'Receptor',
          'Descripción',
          titleCasePipe.transform(models.claim.labels.form),
          'Aviso',
          'Cerrada'
        ],
        fields: [
          { entity: models.task.entity, field: 'date_time' },
          { entity: 'userorigin', field: 'name',  custom: true, show_with: {entity: 'userorigin', field:'surname'}},
          { entity: 'userdestiny', field: 'name',  custom: true, show_with: {entity: 'userdestiny', field:'surname'}},
          { entity: models.task.entity, field: 'description' },
          { entity: models.claim.entity, field: 'number' },
          { entity: models.claim.entity, field: 'number_provider' },
          { entity: models.task.entity, field: 'typecondition_id', type: 'check' }
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new TaskFilters(models),
      }
    }
  }


  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      TasksComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: true
      },
      tabData
      )
  }

}
