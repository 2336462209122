import { Pipe, PipeTransform } from '@angular/core';
import { ModulesService } from 'src/app/main/services/modules.service';

@Pipe({
  name: 'appName'
})
export class AppNamePipe implements PipeTransform {

  constructor(private _modules: ModulesService){}

  transform(): string {
    let title = 'PGA Gest';
    if(!this._modules.checkModule('companies')) {
      title = 'AK Gest';
    }
    return title;
  }

}
