import { Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { FirmsService } from 'src/app/main/services/firms.service';
import { ClaimFilters, ClientFilters, EmployeeFilters, PostalCodesFilters } from 'src/app/shared/data-classes';
import { DynamicListFiltersComponent } from '../../dynamic-list-filters/dynamic-list-filters.component';
import { EntityService } from 'src/app/main/services/entity.service';

@Component({
  selector: 'app-list-selection',
  templateUrl: './list-selection.component.html',
  styleUrls: ['./list-selection.component.scss']
})
export class ListSelectionComponent implements OnInit {
  @ViewChild('dynamicFilters', { static: false }) dynamicFilters: DynamicListFiltersComponent;

  selectedRegister = new Subject<any>();


  modal_header: string;
  list_headers: Array<string>;
  list_fields: Array<any>
  list_entity: string;
  list_endpoint: string;
  list_extra_params: Array<any>;

  public data: Array<any>;
  public loading: boolean;

  public entity_filters: any;
  private filter_firm: boolean;
  private filters: any

  public total = 0;
  public pag_actual = 0;

  public limit = '10'

  private subscriptions: Array<Subscription> = [];
  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _firms: FirmsService, private _entity: EntityService) { }

  ngOnInit(): void {
    this.filters = {[this.list_entity] :{}};
    switch (this.list_endpoint) {
      case 'claims':
        this.entity_filters = new ClaimFilters(this._entity.entities);
        this.filter_firm = true;
        break;
      case 'clients':
        this.entity_filters = new ClientFilters(this._entity.entities);
        break;
      case 'employees':
        this.entity_filters = new EmployeeFilters(this._entity.entities);
        break;
      case 'postalcodes':
        this.entity_filters = new PostalCodesFilters();
        break;
    }

    /* this.subscriptions.push(
      this._api.get(this.list_endpoint).subscribe(
        data => {
          console.log(data)
          this.data = data.response[this.list_entity].data;
        }
      )
    ) */

    this.loadData();

  }

  addFirmFilter() {
    if(this._firms.getActiveFirm() != -1) {
        this.filters[this.list_entity]['firm_id'] = this._firms.getActiveFirm();
    }
  }

  loadData(page?:string): void{
    this.loading = true;
    this.data = [];
    if(this.filter_firm) {
      this.addFirmFilter();
    }



    //Construcción de la url y sus parámetros
    let newurl = new URL(this._api.getAPIEndpoint()+this.list_endpoint)

    //Limit
    newurl.searchParams.set('limit', this.limit)

    //Pagina
    if(page){
      newurl.searchParams.append('page', page)
    }

    if(this.list_extra_params) {
      this.list_extra_params.forEach(element => {
        newurl.searchParams.append(element.name, element.value);
      });
    }


    //Orden
   // if(this.orderfield !== '' && this.ordertype !== '') {
      /*let order = {};
      order['field']='number';
      order['type']='asc';
      newurl.searchParams.append('order', JSON.stringify(order))*/
    //}
    /* let order = {
      field: 'id',
      type: 'desc'
    }
    newurl.searchParams.append('order', JSON.stringify(order)) */
    //Filtros
    if(this.filters) {
      newurl.searchParams.append('filters', JSON.stringify(this.filters))
    }



    this.subscriptions.push(
      this._api.get(this.list_endpoint+newurl.search).subscribe(
      data=> {
        this.data=data.response[this.list_entity].data;


       // this._accountants.setAccountants(data.response.accountant);

        this.total = data.response[this.list_entity].total;
        this.pag_actual = data.response[this.list_entity].current_page -1;
        this.loading = false;

      },
      error=>{
        console.log(error)
        this.loading = false;
      }
    )
    );
  }

  changePage(pag){
    let p = pag.pageIndex + 1;
    this.loadData(p.toString())
  }

  toggleFilters() {
    this.dynamicFilters.filters.toggle()
  }

  applyFilters(ev) {
    if(ev === null){
      this.filters = {[this.list_entity] :{}};
    } else {
      this.filters = ev;
      if(!this.filters[this.list_entity]){
        this.filters[this.list_entity] = {};
      }
    }

    this.loadData()
  }

  selectRegister(reg:any){
    console.log(reg)
    this.selectedRegister.next(reg);
    this.modalRef.hide();
  }

  onClosed() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

}
