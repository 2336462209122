import { Component, OnInit } from '@angular/core';
import { DynamicListComponent } from '../../components/dynamic-list/dynamic-list.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent extends DynamicListComponent implements OnInit {

  editRegister(register): void{
    if(this.data.modal_form) {

      this.modalRefMaintenance = this.modalService.show(this.data.modal_form, {
             // keyboard: false,
             // ignoreBackdropClick: true,
              class: this.data.modal_class,
              data: {
                header: 'Datos de '+this.data.entity_label,
                register: register,
                entity: this.data.entity,
                endpoint: this.data.saveEndpoint
              }
            });

        this.subscriptions.push(
          this.modalRefMaintenance.content.reload.subscribe(
            reload => {
              if(reload) {
                this.loadData()
              }
            }
          )
        )

        this.subscriptions.push(
          this.modalService.closed.subscribe(() => this.modalRefMaintenance.content.unsubscribeAll())
        );

    } else {
      if(this.data.listParams?.form_entity) {
        this._tabs.addTab(this.data.listParams.form_entity, register);
      } else {
        this._tabs.addTab(this.data.entity, register);
      }
    }

  }
}
