import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { DynamicListSelectionComponent } from '../../components/dynamic-list-selection/dynamic-list-selection.component';
import { Tab } from '../../tab-models/tab.model';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { dateBeforeToday, selectRequired, timeBeforeNow } from 'src/app/shared/form-validators/custom-validators';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DetailLinesComponent } from '../../components/detail-lines/detail-lines.component';
import { EmployeeInterface } from 'src/app/shared/interfaces/data/employee-interface';
import { EntityService } from '../../services/entity.service';
import { TitleCasePipe } from '@angular/common';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-new-workorder',
  templateUrl: './new-workorder.component.html',
  styleUrls: ['./new-workorder.component.scss']
})
export class NewWorkorderComponent implements OnInit, OnDestroy {
  @Input() tab: Tab
  @Input() data: any

  @ViewChild('listSelection', { static: true }) listSelection!: DynamicListSelectionComponent;
  @ViewChild('detailComponent', { static: true }) detailComponent!: DetailLinesComponent;

  private subscriptions: Array<Subscription> = [];

  public claim_entity = this._entity.entities.claim;
  public user_entity = this._entity.entities.user;
  public client_entity = this._entity.entities.client;
  public employee_entity = this._entity.entities.employee;
  public guild_entity = this._entity.entities.guild;
  public serie_entity = this._entity.entities.serie;
  public company_entity = this._entity.entities.company;


  public loading = false;

  formNewWorkorder: FormGroup;

  appointmentForm: FormGroup;


  private dateformats = new DateFormats;
  public datepicker_options = this.dateformats.opcionesMDBDatePicker_desdeHoy;

  private utils = new PGAUtils;


  private formFunctions = new FormFunctions;


  public hasClaim: boolean;

  public dropdowns: DropdownsInterface;


  public guilds: Array<any> = [{id: -1, name: 'Sin Asignar'}];
  public provider_types: Array<any> = [{id: -1, name: 'Particular'}];
  public repairmen: Array<any> = [];
  public administrators: Array<any> = [];
  public companies: Array<any> = [{ value: -1, label: 'Sin asignar', subcompanies:[] }]
  public subcompanies: Array<any> = [{ value: -1, label: 'Sin asignar' }]

  addresses = [];

  public detail_headers: Array<any> = [
    {header: 'Exp.', class: 'buttons-column'},
    { header: 'Liq.', class: 'buttons-column'},
    {header: 'Código', class: 'code-column'},
    'Descripción',
    {header: this._titleCasePipe.transform(this.employee_entity.labels.form), class:'medium-column'},
    { header: 'Cantidad', class: 'numbers-column'},
    {header: 'Coste', class: 'numbers-column'},
    {header: 'Precio', class: 'numbers-column'},
    {header:'%Descuento', class: 'percent-column'},
    {header:'%Impuesto', class: 'percent-column'}
  ];

  public detail_fields_order: Array<any> = [
    {
      control: 'type_export_line_id',
      type: 'checkbox',
      default:  0,
      disabled: false
    },
    {
      control: 'typeliquidation_id',
      type: 'checkbox',
      default:  1,
      disabled: false
    },
    {
      control: 'code',
      type: 'text',
      default: '',
      disabled: false,
      addKey: true,
    },
    {
      control: 'description',
      type: 'text',
      default: '',
      disabled: false,
      validate:true,
      validators:[Validators.required],
      error_msg: 'Campo obligatorio',
      addKey: true,
    },
    {
      control: 'employee_id',
      type: 'select',
      dropdown: 'employees',
      field_value: 'name_complete',
      clear: true,
      listen_changes: true,
      disabled: false,
      default: null,
    },
    {
      control: 'quantity',
      type: 'number',
      default: 1,
      disabled: false,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'cost',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'price',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'discount',
      type: 'number',
      disabled: false,
      default: 0,
      validate: true,
      validators: [Validators.required],
      error_msg: 'Campo obligatorio',
    },
    {
      control: 'taxe_id',
      type: 'select',
      dropdown: 'taxes',
      field_value: 'value',
      addKey: true,
      clear: false,
      listen_changes: true,
      disabled: true,
      default: 1,
    }
  ]

  public master_object = {
    typecondition_id: 1,
    type_export_id: 1,

  }

  private readonly unasign_employee: EmployeeInterface =  {
    id: null,
    manager: false,
    name_complete: 'Sin asignar',
    address: null,
    email: null,
    name: null,
    surname: null,
    phone: null,
    mobile: null,
    postal_code: null,
    province: null,
    population: null,
    password: null,
    company_id: null,
    identification_document: null,
    web_access: null,
    enabled: true,
    observations: null
  };

  employees: Array<EmployeeInterface>=[
    this.unasign_employee
  ];

  modalRefClaim: MDBModalRef | null = null;
  modalRefClient: MDBModalRef | null = null;
  modalRefOpenRegister: MDBModalRef | null = null;



  constructor(private modalService: MDBModalService, private _buttons: ActionButtonsService, private _apifunctions: ApiFunctions, private fb: FormBuilder,
     private _api: ApirequestService, private _notification: NotificationService, private _tabs: TabService, private _entity: EntityService, private _titleCasePipe: TitleCasePipe, private _dropdowns: DropdownService) {

  }
  ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  setWorkorderForm(){
    this.formNewWorkorder = this.fb.group({
      claim_id: [null, []],
      claim_number: [{value:'', disabled: false}, []],
      claim_address: [{value:'', disabled: true}, []],
      client_id: [null, [Validators.required]],
      client_name: ['', [Validators.required]],
      clientaddress_id: [{value: null, disabled: true}, []],
      provider_id: [{value: -1, disabled: true}, [selectRequired()]],
      subprovider_id: [-1, []],
      provider_name: [{value:'Particular', disabled: true}, []],
      typeprovider:[{value: -1, disabled: false}],
      guild_id: [-1, []],
      employee_id: [null, []],
      serie_id: [1, []],
      description: ['', []],
      footer: ['', []],

      total_cost: [{value: 0.00, disabled: true}],
      total_price: [{value: 0.00, disabled: true}],
      total_taxe: [{value: 0.00, disabled: true}],
      total: [{value: 0.00, disabled: true}],
    })


    this.subscriptions.push(
      this.formNewWorkorder.controls['provider_id'].valueChanges.subscribe((id) => {

        this.formNewWorkorder.controls.subprovider_id.setValue(-1)

        if(this.selected_typeprovider == 1) {

          if(id > 0 ){
             this.subcompanies = [...[{value:-1,label:'Sin asignar'}],...this.companies.find(c => c.value === id)?.subcompanies.map(subcompanies => { return { value: subcompanies.id, label: subcompanies.name } })];
          }
        }
      })
    );

    this.subscriptions.push(
      this.formNewWorkorder.controls.employee_id.valueChanges.subscribe(
        id => {
          if(id>-1) {
          this.employees = [...[
            this.unasign_employee
          ], ...this.getSelectedEmployee(id)]
          } else{
            this.employees = [
              this.unasign_employee
            ];
          }
         /*  this.employees.unshift({ id: -1, enabled: true, name_complete: 'Sin asignar' }) */
        }
      )
    )

    this.subscriptions.push(
      this.formNewWorkorder.controls.typeprovider.valueChanges.subscribe(
        type => {

          switch (type) {
            case -1:
              this.formNewWorkorder.controls.provider_id.setValue(-1);
              this.formNewWorkorder.controls.provider_id.disable();
              this.formNewWorkorder.controls.subprovider_id.disable();

              break;
            case 1:
              if(this.formNewWorkorder.controls.provider_id.disabled) {
                this.formNewWorkorder.controls.provider_id.enable();

              }

              this.formNewWorkorder.controls.subprovider_id.enable();
              this.formNewWorkorder.controls.provider_id.setValue(-1);
              break;
            case 2:
            case 3:
              if(this.formNewWorkorder.controls.provider_id.disabled) {
                this.formNewWorkorder.controls.provider_id.enable();
              }
              this.formNewWorkorder.controls.subprovider_id.disable();
              this.formNewWorkorder.controls.provider_id.setValue(-1);
            break;
          }
        }
      )
    );
  }

  setAppointmentForm(){
    this.appointmentForm = this.fb.group({
      appointment_set: [false],
      appointment: this.fb.group({
        head: [{value:'Cita concertada', disabled: true}],
        body: [{value:'', disabled: true}],
        date: [{value: this.dateformats.getCurrentDate(), disabled:true}, [dateBeforeToday()]],
        start: [{value: '08:00', disabled:true},[timeBeforeNow('date')]],
        end: [{value: '20:00', disabled: true}],
      })

    });

    //escucha cambios en la fecha de cita para mostrar error si la hora es anterior a la actual
    this.appointmentForm.controls.appointment['controls'].date.valueChanges.subscribe(
      value => {
        this.appointmentForm.controls.appointment['controls'].start.updateValueAndValidity()
      }
    )
  }


  ngOnInit() {

    this.loadDropdowns()

   this.setWorkorderForm();
   this.setAppointmentForm();



    if(this.data.parentData) {
      this.hasClaim = true;
      this.formNewWorkorder.controls.claim_id.setValue(this.data.parentData.id);
      this.formNewWorkorder.controls.claim_id.markAsDirty();
      this.formNewWorkorder.controls.claim_number.setValue(this.data.parentData.number);

      this.formNewWorkorder.controls.client_id.setValue(this.data.parentData.client.id);
      this.formNewWorkorder.controls.client_id.markAsDirty();
      this.formNewWorkorder.controls.client_name.setValue(this.data.parentData.client.name_complete);


      this.addresses = this.data.parentData.client.clientaddress;

      if(this.data.parentData.policy) {
        this.formNewWorkorder.controls.claim_address.setValue(this.data.parentData.policy.address_complete);
      } else {
        this.formNewWorkorder.controls.claim_address.setValue(this.addresses.find(address => address.id === this.data.parentData.clientaddress_id).address_complete);
      }

      if(this.data.parentData.provider){
        this.formNewWorkorder.controls.provider_id.setValue(this.data.parentData.provider_id);
        this.formNewWorkorder.controls.provider_id.markAsDirty();

        let provider_name = this.data.parentData.provider.name;
        if(this.data.parentData.subprovider) {
          provider_name += ' ('+this.data.parentData.subprovider.name+')';
          this.formNewWorkorder.controls.subprovider_id.setValue(this.data.parentData.subprovider_id);
          this.formNewWorkorder.controls.subprovider_id.markAsDirty();
        } else {
          this.formNewWorkorder.controls.subprovider_id.setValue(null);
          this.formNewWorkorder.controls.subprovider_id.markAsPristine();
        }


        this.formNewWorkorder.controls.provider_name.setValue(provider_name);
      }
    }

    this.formNewWorkorder.valueChanges.subscribe(
      change => {
        this.tab.modifiedData = true;
      }
    )


  }

  //carga los datos de los desplegables
  loadDropdowns() {

    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          this.dropdowns = this.utils.objectDeepCopy(response.response.dropdown);
          this.dropdowns.typeprovider = [...this.provider_types, ...this.dropdowns?.typeprovider];
          this.dropdowns.guild = [...this.guilds, ...this.dropdowns?.guild];
          this.dropdowns.taxe = this.dropdowns.taxe?.filter(e => e.enabled == true);
          this.companies = [...this.companies, ...this.dropdowns.company_with_subcompanies?.map(comp => { return { value: comp.id, label: comp.name, subcompanies: comp.subcompanies } })];


          this.loading = false;
        },
        error=> {
          this.formNewWorkorder.disable()
          this.loading = false;
        }
      )
    );
  }

  getSelectedEmployee(id): Array<EmployeeInterface>{
    return this.dropdowns?.employee.filter( e => e.id === id)
  }

  //check de expediente
  changeClaim(ev: MdbCheckboxChange){
    this.formNewWorkorder.controls.claim_id.markAsPristine();
    this.formNewWorkorder.controls.provider_id.markAsPristine();
    this.formNewWorkorder.controls.subprovider_id.markAsPristine();

    if(!this.hasClaim) {
      //si se desmarca el check de expediente se resetan los campos de expediente y proveedor
      this.formNewWorkorder.controls.claim_id.setValue(null);
      this.formNewWorkorder.controls.typeprovider.setValue(-1);
      this.formNewWorkorder.controls.claim_number.setValue('');
      this.formNewWorkorder.controls.claim_address.setValue(null);
      this.formNewWorkorder.controls.provider_name.setValue('Particular');
      //y se habilita la selección de cliente
      this.formNewWorkorder.controls.clientaddress_id.enable();

      //se elimina la validación de expediente
      this.formNewWorkorder.controls.claim_number.removeValidators([Validators.required])

      //obligatorio seleccionar dirección del cliente
      this.formNewWorkorder.controls.clientaddress_id.addValidators([Validators.required])

      this.formNewWorkorder.controls.client_name.enable()

    } else {
      //si se marca el expediente se deshabilita la selección de cliente
      this.formNewWorkorder.controls.client_name.disable();
      this.formNewWorkorder.controls.clientaddress_id.disable();
      this.formNewWorkorder.controls.provider_id.disable();

      //obligatorio seleccionar un expediente
      this.formNewWorkorder.controls.claim_number.addValidators([Validators.required])

      //se elimina la validación de expediente
      this.formNewWorkorder.controls.clientaddress_id.removeValidators([Validators.required])
    }


    this.formNewWorkorder.controls.claim_number.updateValueAndValidity()
    this.formNewWorkorder.controls.clientaddress_id.updateValueAndValidity()
  }

  //check de cita
  changeAppointment(ev: MdbCheckboxChange){
    if (ev.checked == true) {
      this.appointmentForm.controls.appointment.enable()
    } else {
      this.appointmentForm.controls.appointment.disable()
    }

    this.appointmentForm.controls.appointment.markAllAsTouched();
  }


  //abre la selección de expediente
  selectClaim(){

    this.modalRefClaim = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.claim_entity.labels.form,
              list_headers: [
                'Nº Aviso',
                'Número',
                'Fecha encargo',
                'Proveedor',
                this._titleCasePipe.transform(this.client_entity.labels.form),
                'Dirección',
                'Tramitador',
              ],
              list_fields: [
                {entity: this.claim_entity.entity, field: 'number'},
                {entity: this.claim_entity.entity, field: 'number_provider'},
                {entity: this.claim_entity.entity, field: 'order_date'},
                {entity: this.company_entity.entity, field: 'name', custom: true, show_with: {entity: 'sub'+this.company_entity.entity, field:'name'}},
                {entity: this.client_entity.entity, field: 'name_complete'},
                {entity: 'clientaddress', field: 'address_complete'},
                {entity: this.user_entity.entity, field: 'name'}
              ],
              list_entity: this.claim_entity.entity,
              list_endpoint: this.claim_entity.list_entity,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                }
              ]
            }
        })

    //recoge los datos del expediente seleccionado
    this.modalRefClaim.content.selectedRegister.subscribe(register => {

      this.addresses = [];

      //se quitan validaciones de direccion de cliente
      this.formNewWorkorder.controls.clientaddress_id.removeValidators([Validators.required]);
      this.formNewWorkorder.controls.clientaddress_id.setValue(null);
      this.formNewWorkorder.controls.clientaddress_id.markAsPristine();
      this.formNewWorkorder.controls.clientaddress_id.updateValueAndValidity();

      //datos expediente
      this.formNewWorkorder.controls.claim_id.setValue(register.id);
      this.formNewWorkorder.controls.claim_id.markAsDirty();
      this.formNewWorkorder.controls.claim_number.setValue(register.number);

      //datos cliente
      this.formNewWorkorder.controls.client_id.setValue(register.client.id);
      this.formNewWorkorder.controls.client_id.markAsDirty();
      this.formNewWorkorder.controls.client_name.setValue(register.client.name_complete);

      //si el expediente es de compañía se muestra la dirección de la compañía, si no se muestra la del cliente
      this.addresses = register.client.clientaddress;
      if(register.policy) {
        this.formNewWorkorder.controls.claim_address.setValue(register.policy.address_complete);
      } else {
        this.formNewWorkorder.controls.claim_address.setValue(register.clientaddress.address_complete);
      }

      //si el expediente es de compañía se cargan los datos de la compañía
      if(register.provider){
        this.formNewWorkorder.controls.provider_id.setValue(register.provider_id);
        this.formNewWorkorder.controls.provider_id.markAsDirty();

        let provider_name = register.provider.name;
        if(register.subprovider) {
          provider_name += ' ('+register.subprovider.name+')';

          this.formNewWorkorder.controls.subprovider_id.setValue(register.subprovider_id);
          this.formNewWorkorder.controls.subprovider_id.markAsDirty();
        } else {
          this.formNewWorkorder.controls.subprovider_id.setValue(null);
          this.formNewWorkorder.controls.subprovider_id.markAsPristine();
        }

        this.formNewWorkorder.controls.provider_name.setValue(provider_name);
      }
    })

    //libera memoria del modal de selección de expediente
    this.modalService.closed.subscribe(() => this.modalRefClaim.content.onClosed())

  }

  get selected_typeprovider(): number {
    return this.formNewWorkorder?.controls.typeprovider.value
  }

  get selected_claim_id(): number {
    return this.formNewWorkorder?.controls.claim_id.value
  }

  newClient(){
    this._tabs.addTab('newclient')
  }
  //abre la selección de cliente
  selectClient(){

    this.modalRefClient = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
              modal_header: 'Seleccione un '+this.client_entity.labels.form,
              list_headers: [
                'Activo',
                'Acceso Web',
                'NIF',
                'Nombre y apellidos',
                'Dirección completa',
                'Teléfono',
                'Móvil',
                'Email',
              ],
              list_fields: [
                { entity: this.client_entity.entity, field: 'enabled', type: 'check' },
                { entity: this.client_entity.entity, field: 'web_access', type: 'check' },
                { entity: this.client_entity.entity, field: 'identification_document' },
                { entity: this.client_entity.entity, field: 'name_complete' },
                { entity: 'defaultaddress', field: 'address_complete' },
                { entity: this.client_entity.entity, field: 'phone' },
                { entity: this.client_entity.entity, field: 'mobile' },
                { entity: this.client_entity.entity, field: 'email' },
              ],
              list_entity: this.client_entity.entity,
              list_endpoint: this.client_entity.saveEndpoint,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                }
              ]
            }
          })

      //recoge los datos del cliente seleccionado
      this.modalRefClient.content.selectedRegister.subscribe(register => {
        console.log(register)
        this.addresses = [];
        this.formNewWorkorder.controls.client_id.setValue(register.id);
        this.formNewWorkorder.controls.client_id.markAsDirty();
        this.formNewWorkorder.controls.client_name.setValue(register.name_complete);
        this.formNewWorkorder.controls.clientaddress_id.enable();

        let default_address = register.clientaddress.find(address => address.default === 1);
        this.formNewWorkorder.controls.clientaddress_id.enable();
        this.formNewWorkorder.controls.clientaddress_id.setValue(default_address?.id);
        this.formNewWorkorder.controls.clientaddress_id.markAsDirty();

        this.formNewWorkorder.controls.clientaddress_id.addValidators([Validators.required]);
        this.formNewWorkorder.controls.clientaddress_id.updateValueAndValidity();
        this.addresses = register.clientaddress;
      })

      //libera memoria del modal de selección de cliente
    this.modalService.closed.subscribe(() => this.modalRefClient.content.onClosed())
  }


  public onSubmit(): void{
    this.formNewWorkorder.markAllAsTouched();
    this.appointmentForm.markAllAsTouched();

    let line_form_error = false;
    this.detailComponent.detailsForms.controls.forEach(
      form => {
        form.markAllAsTouched();
      }
    );

    for (let index = 0; index < this.detailComponent.detailsForms.controls.length; index++) {
      if(this.detailComponent.detailsForms.controls[index].invalid) {
        line_form_error = true;
        break;
      }
    }

    if(this.formNewWorkorder.valid && this.appointmentForm.valid && !line_form_error) {
      this._buttons.setLoading(true);

      let params ={};

      //datos parte
      this.formFunctions.fillFormParams(this.formNewWorkorder, this.data.entity, params);

      //cita
      let formappointmentvalues = {...this.appointmentForm.getRawValue()};
      if(formappointmentvalues.appointment_set) {
        let appointment_data = {...formappointmentvalues['appointment']};
        let appointment = {
          head: appointment_data.head,
          body: appointment_data.body,
          starttime: this.dateformats.formatoSave(appointment_data.date)+' '+appointment_data.start+':00',
          endtime: this.dateformats.formatoSave(appointment_data.date)+' '+appointment_data.end+':00'
        }
        params['appointment'] = appointment;
      }

      //lineas
      params['workorderlines'] = [];
      this.detailComponent.pushDetailsToMaster(params['workorderlines']);
      if(params['workorderlines'].length > 0) {
        params[this.data.entity]['total'] = this.detailComponent.total_lineas
        params[this.data.entity]['total_price'] = this.detailComponent.total_price
        params[this.data.entity]['total_cost'] = this.detailComponent.total_cost
        params[this.data.entity]['total_taxe'] = this.detailComponent.total_taxe
      }


      console.log(params)


      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {

            let workorder = response.response.workorder;


          if(this.data.parentData){
            this._buttons.reloadClaimListTab(this.data.parentData.id, this.data.saveEndpoint)
            } else {
              this._buttons.reloadListTab(this.data.saveEndpoint)
            }

            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
             this.modalRefOpenRegister = this.modalService.show(OpenRegisterComponent, {
                keyboard: false,
                ignoreBackdropClick: true,
                class: 'modal-dialog',
                data: {
                  heading: 'Registro Creado',
                  text: 'Registro creado correctamente, ¿desea acceder al registro?',
                  register: workorder,
                  form_entity: this.data.entity,
                }
              })

              this._tabs.removeTabByTabId(this.tab.tabId)
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      )
    }



  }

  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
  }

}
