

export class EmployeeFilters {



  public staticFilters;
  public optionalFilters;

  constructor(private entities){

    this.staticFilters = [
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'enabled',
          type: 'select',
          label: 'Estado',
          width: 1,
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
            {
              value:  0,
              text: 'Inactivo'
            },
            {
              value:  1,
              text: 'Activo'
            }
          ]
        }
      },
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'web_access',
          type: 'select',
          label: 'Acceso Web',
          width: 1,
          options: [
            {
              value:  -1,
              text: 'Todos'
            },
            {
              value:  0,
              text: 'No'
            },
            {
              value:  1,
              text: 'Sí'
            }
          ]
        }
      },
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'name_complete',
          type: 'text',
          label: 'Nombre',
          width: 2
        }
      },
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'address',
          type: 'text',
          label: 'Dirección',
          width: 2
        }
      },
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'email',
          type: 'text',
          label: 'Email',
          width: 2
        }
      },
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'phone',
          type: 'text',
          label: 'Teléfono',
          width: 2
        }
      },
      {
        entity: this.entities.employee.list_entity,
        field: {
          name: 'mobile',
          type: 'text',
          label: 'Móvil',
          width: 2
        }
      },
      
    ]

  }


}

