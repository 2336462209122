<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h5 class="modal-title w-100 d-flex justify-content-center" id="myModalLabel">Actualizar Proveedor</h5>
</div>
<div class="modal-body">
  <form [formGroup]="formTypeProvider">
    <div class="row">
      <div class="col-md-6">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true"  placeholder="" label="Tipo Proveedor" placeholder="" formControlName="typeprovider">
            <mdb-select-option *ngFor="let type of provider_types" [value]="type.id">{{ type.name }}</mdb-select-option>
          </mdb-select-2>
        </div>

      </div>
    </div>
    <div class="row mt-3">
      <ng-container [ngSwitch]="selected_typeprovider">
        <ng-container *ngSwitchCase="-1">
          <div class="col-md">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
              </mdb-select-2>
              <!-- <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                Dirección Obligatoria
              </mdb-error> -->
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <div class="col-md">
            <div class="md-form md-outline">

              <mdb-select-2 [outline]="true" placeholder="Elige una póliza:" label="Póliza" formControlName="policy_id" mdbValidate [validateSuccess]="false">
                <mdb-select-option *ngFor="let policy of policies" [value]="policy.id">
                  {{ policy.number }} - {{policy.provider.name}}
                <ng-container *ngIf="policy.subprovider; else policyprovider">
                  ({{policy.subprovider.name}})
                </ng-container>
                - <span class="small">{{policy.address_complete}}</span>
                </mdb-select-option>
              </mdb-select-2>
              <!-- <mdb-error *ngIf="formNewClaim.controls.policy_id.invalid && (formNewClaim.controls.policy_id.dirty || formNewClaim.controls.policy_id.touched)">
                Póliza Obligatoria
              </mdb-error> -->
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2">

          <div class="col-md-6">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                <mdb-select-option *ngFor="let repairman of repairmen" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
              </mdb-select-2>
              <!-- <mdb-error *ngIf="formNewClaim.controls.provider_id.invalid && (formNewClaim.controls.provider_id.dirty || formNewClaim.controls.provider_id.touched)">
                Reparador Obligatorio
              </mdb-error> -->
            </div>
          </div>
          <div class="col-md-12 mt-1">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
              </mdb-select-2>
              <!-- <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                Dirección Obligatoria
              </mdb-error> -->
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <div class="col-md-6">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                <mdb-select-option *ngFor="let administrator of administrators" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
              </mdb-select-2>
              <!-- <mdb-error *ngIf="formNewClaim.controls.provider_id.invalid && (formNewClaim.controls.provider_id.dirty || formNewClaim.controls.provider_id.touched)">
                Administrador Obligatorio
              </mdb-error> -->
            </div>
          </div>
          <div class="col-md-12 mt-1">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
              </mdb-select-2>
              <!-- <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                Dirección Obligatoria
              </mdb-error> -->
            </div>
          </div>
        </ng-container>
    </ng-container>
    </div>

  </form>
</div>
<div class="modal-footer">
<button type="button" mdbBtn color="primary" mdbWavesEffect (click)="confirm()">Confirmar</button>
</div>
