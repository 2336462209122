<app-loader *ngIf="loading"></app-loader>



<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
  <!--PESTAÑA INFORMACION-->
  <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
    <ng-container *ngIf="formMailAccount">
      <form [formGroup]="formMailAccount">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <div class="row">
              <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Cuenta de correo activa</b></mdb-checkbox>
              <ng-container *ngIf="mailaccount?.state == 0;">
                <mdb-icon fad icon="exclamation-triangle" size="sm" mdbTooltip="{{ mailaccount.state_description }}" placement="right" class="amber-text ml-3 mt-1"></mdb-icon>
              </ng-container>
            </div>
          </mdb-card-header>
        </mdb-card>
        <div class="row mt-4">
          <!--cuenta-->
          <div class="col-6">
            <div class="md-form md-outline">
              <input mdbInput type="text" id="mail" formControlName="mail" class="form-control form-control-sm" placeholder="">
              <label for="mail" class="">Cuenta de correo</label>
            </div>
          </div>

          <!--PARA CUENTAS DE GOOGLE Y OUTLOOK SOLO-->
          <ng-container *ngIf="mailaccount?.state == 0;">
            <div class="col-3" *ngIf="mailaccount?.typemailaccount_id == 1" >
              <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="openMailProvider()">
                <mdb-icon fab icon="google" size="md" class="mr-1"></mdb-icon> Reactivar en Google
              </button>
            </div>

            <div class="col-3" *ngIf="mailaccount?.typemailaccount_id == 2">
              <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="openMailProvider()">
                <mdb-icon fab icon="windows" size="md" class="mr-1"></mdb-icon> Reactivar con Outlook
              </button>
            </div>
          </ng-container>

          <!--seguridad-->

          <div class="col-2" *ngIf="mailaccount?.typemailaccount_id === 3">
            <div class="md-form">
              <mdb-select-2 placeholder="Seleccionar" label="Seguridad" formControlName="method">
                <mdb-select-option *ngFor="let option of imap_ports" [value]="option.type">{{ option.label }}</mdb-select-option>
              </mdb-select-2>
            </div>
          </div>  

          <!--password-->
          <div class="col-2" *ngIf="mailaccount?.typemailaccount_id === 3">
            <div class="row">
              <div class="col-10">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" [type]="view_pass ? 'text' : 'password'" id="password" class="form-control custom-input-group" formControlName="password" placeholder="" aria-label="Contraseña">
                  <label for="password">Contraseña</label>
                </div>
              </div>
              <div class="col-2">
                <div class="input-group-append mt-2">
                  <button mdbBtn color="light" (click)="view_pass = !view_pass" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2" [disabled]="disableChanges()">
                    <mdb-icon fad [icon]="view_pass ? 'eye-slash' : 'eye'"></mdb-icon>
                  </button>
                </div>
                <mdb-error *ngIf="formMailAccount.controls.password.invalid && (formMailAccount.controls.password.dirty || formMailAccount.controls.password.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>
          </div>
                  
        </div>
        
        <!--servidor imap-->

        <div class="row mt-2" *ngIf="mailaccount?.typemailaccount_id === 3">

          <div class="col-6">
            <div class="md-form md-outline">                  
              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="imap_server" class="form-control form-control-sm" formControlName="imap_server" placeholder="">
              <label for="imap_server">Servidor IMAP</label>
              <mdb-error *ngIf="formMailAccount.controls.imap_server.invalid && (formMailAccount.controls.imap_server.dirty || formMailAccount.controls.imap_server.touched)">
                Campo Obligatorio
              </mdb-error>
            </div>
          </div>
          <!--puerto imap-->
          <div class="col-2">
            <div class="md-form">
              <mdb-select-2 placeholder="Seleccionar" label="Puerto IMAP" formControlName="imap_port">
                <mdb-select-option *ngFor="let port of filtered_imap_ports" [value]="port">{{ port }}</mdb-select-option>
              </mdb-select-2>
            </div>
          </div>  
        </div>
        
        <div class="row mt-2" *ngIf="mailaccount?.typemailaccount_id === 3">
          <!--servidor smtp-->

          <div class="col-6">
            <div class="md-form md-outline">                  
              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="smtp_server" class="form-control form-control-sm" formControlName="smtp_server" placeholder="">
              <label for="smtp_server">Servidor SMTP</label>
              <mdb-error *ngIf="formMailAccount.controls.smtp_server.invalid && (formMailAccount.controls.smtp_server.dirty || formMailAccount.controls.smtp_server.touched)">
                Campo Obligatorio
              </mdb-error>
            </div>
          </div>
          <!--puerto smtp-->
          <div class="col-2">
            <div class="md-form">
              <mdb-select-2 placeholder="Seleccionar" label="Puerto SMTP" formControlName="smtp_port">
                <mdb-select-option *ngFor="let port of filtered_smtp_ports" [value]="port">{{ port }}</mdb-select-option>
              </mdb-select-2>
            </div>
          </div>  
        </div>
      </form>
    </ng-container>
  </mdb-tab>

  <!--pestaña USUARIOS-->
  <mdb-tab heading="<i class='fas fa-user mr-2'></i> Usuarios">    
    <div class="row mt-2">
      <div class="col">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad [icon]="user_entity.icon.list" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios vinculados a la cuenta de correo
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" bordered="true">
              <thead>
                <tr class="miclase-colorFirtRowTable">
                  <th scope="col"></th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Email</th>
                  <th scope="col">Pred.</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of users; let i=index">
                  <tr mdbTableCol>
                    <td>
                      <a (click)="removeUser(user)" *ngIf="!disableChanges()">
                        <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      {{user.name}} {{user.surname}}
                    </td>
                    <td>
                      {{user.email}}
                    </td>
                    <td>
                      <mdb-checkbox [default]="true" [disabled]="disableChanges()" [checked]="user.default_mailaccount" (change)="onChangeDefault($event, user)"></mdb-checkbox>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios disponibes
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" bordered="true">
              <thead>
                <tr class="miclase-colorFirtRowTable">
                  <th scope="col"></th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of available_users; let i=index">
                  <tr mdbTableCol>
                    <td>
                      <a (click)="addUser(user)" *ngIf="!disableChanges()">
                        <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      {{user.name}} {{user.surname}}
                    </td>
                    <td>
                      {{user.email}}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>


    </div>
  </mdb-tab>

</mdb-tabset>


<!--
<div class="pt-2">
  <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">


    <ng-container *ngIf="formMailAccount">
      <ng-container [formGroup]="formMailAccount">
        <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
              <div class="row">

                <div class="col-lg-1">
                    <mdb-checkbox [default]="true" formControlName="enabled">Activa
                    </mdb-checkbox>

                </div>
                <div class="col-lg-3">
                    <div class="md-form">
                        <input mdbInput type="text" id="mail" formControlName="mail" class="form-control form-control-sm" placeholder="">
                        <label for="mail" class="">Cuenta</label>
                    </div>
                </div>
              </div>


              <div class="row mt-3">

                <div class="col-md-5" *ngIf="mailaccount?.typemailaccount_id === 3">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                        <mdb-icon fad
                        icon="at"
                        size="md"
                        class="mr-1"></mdb-icon> Datos Cuenta

                        <ng-container *ngIf="mailaccount?.state == 0">
                          <mdb-icon fad
                          icon="exclamation-triangle"
                          size="lg"
                          mdbTooltip="Error de conexión: {{ mailaccount.state_description }}" placement="right"
                          class="amber-text ml-2"></mdb-icon>
                        </ng-container>
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row justify-content-center">
                        <div class="col-md-4">
                          <div class="md-form input-group mb-3">
                            <input mdbInput mdbValidate [validateSuccess]="false" [type]="view_pass ? 'text' : 'password'" id="password" class="form-control custom-input-group" formControlName="password" placeholder="" aria-label="Contraseña">
                            <label for="password">Contraseña</label>
                            <div class="input-group-append">
                              <button mdbBtn color="light" (click)="view_pass = !view_pass" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2" [disabled]="disableChanges()">
                                <mdb-icon fad [icon]="view_pass ? 'eye-slash' : 'eye'"></mdb-icon>
                              </button>
                            </div>
                            <mdb-error *ngIf="formMailAccount.controls.password.invalid && (formMailAccount.controls.password.dirty || formMailAccount.controls.password.touched)">
                              Campo Obligatorio
                            </mdb-error>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="md-form">
                            <mdb-select-2 placeholder="Seleccionar" label="Seguridad" formControlName="method">
                              <mdb-select-option *ngFor="let option of imap_ports" [value]="option.type">{{ option.label }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-center mt-3">
                        <div class="col-md-6">
                          <mdb-card class="z-depth-0">
                            <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                                <mdb-icon fad
                                icon="inbox-in"
                                size="sm"
                                class="mr-1"></mdb-icon>Ajustes de Entrada
                            </mdb-card-header>
                            <mdb-card-body>
                              <div class="row">
                                <div class="col-md">
                                  <div class="md-form">
                                    <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="imap_server" class="form-control form-control-sm" formControlName="imap_server" placeholder="">
                                    <label for="imap_server">Servidor de entrada</label>
                                    <mdb-error *ngIf="formMailAccount.controls.imap_server.invalid && (formMailAccount.controls.imap_server.dirty || formMailAccount.controls.imap_server.touched)">
                                      Campo Obligatorio
                                    </mdb-error>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="md-form">
                                    <mdb-select-2 placeholder="Seleccionar" label="Puerto" formControlName="imap_port">
                                      <mdb-select-option *ngFor="let port of filtered_imap_ports" [value]="port">{{ port }}</mdb-select-option>
                                    </mdb-select-2>
                                  </div>
                                </div>
                              </div>
                            </mdb-card-body>
                          </mdb-card>
                        </div>
                        <div class="col-md-6">
                          <mdb-card class="z-depth-0">
                            <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                                <mdb-icon fad
                                icon="inbox-out"
                                size="sm"
                                class="mr-1"></mdb-icon>Ajustes de Salida
                            </mdb-card-header>
                            <mdb-card-body>
                              <div class="row">
                                <div class="col-md">
                                  <div class="md-form">
                                    <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="smtp_server" class="form-control form-control-sm" formControlName="smtp_server" placeholder="">
                                    <label for="smtp_server">Servidor de salida</label>
                                    <mdb-error *ngIf="formMailAccount.controls.smtp_server.invalid && (formMailAccount.controls.smtp_server.dirty || formMailAccount.controls.smtp_server.touched)">
                                      Campo Obligatorio
                                    </mdb-error>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="md-form">
                                    <mdb-select-2 placeholder="Seleccionar" label="Puerto" formControlName="smtp_port">
                                      <mdb-select-option *ngFor="let port of filtered_smtp_ports" [value]="port">{{ port }}</mdb-select-option>
                                    </mdb-select-2>
                                  </div>
                                </div>
                              </div>
                            </mdb-card-body>
                          </mdb-card>
                        </div>
                      </div>
                    </mdb-card-body>
                  </mdb-card>
                </div>
                <div class="col-md">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                        <mdb-icon fad
                        [icon]="user_entity.icon.list"
                        size="md"
                        class="mr-1"></mdb-icon> {{user_entity.labels.list | titlecase}}
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row">
                        <div class="col-6">
                          <mdb-card class="z-depth-0">
                            <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                                <mdb-icon fad [icon]="user_entity.icon.list" size="lg" class="mr-1 text-primary"></mdb-icon>Vinculados
                            </mdb-card-header>
                            <mdb-card-body class="scroll-card-body">
                              <table mdbTable small="true">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Predet.</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let user of users; let i=index">
                                    <tr mdbTableCol>
                                      <td>
                                        <a (click)="removeUser(user)" *ngIf="!disableChanges()">
                                          <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                                        </a>
                                      </td>
                                      <td>
                                        {{user.name}} {{user.surname}}
                                      </td>
                                      <td>
                                        {{user.email}}
                                      </td>
                                      <td>
                                        <mdb-checkbox [default]="true" [disabled]="disableChanges()" [checked]="user.default_mailaccount" (change)="onChangeDefault($event, user)"></mdb-checkbox>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                              </table>
                            </mdb-card-body>
                          </mdb-card>
                        </div>
                        <div class="col-6">
                          <mdb-card class="z-depth-0">
                            <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                                <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Disponibles
                            </mdb-card-header>
                            <mdb-card-body class="scroll-card-body">
                              <table mdbTable small="true">
                                <thead>
                                  <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Nombre</th>
                                    <th scope="col">Email</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <ng-container *ngFor="let user of available_users; let i=index">
                                    <tr mdbTableCol>
                                      <td>
                                        <a (click)="addUser(user)" *ngIf="!disableChanges()">
                                          <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                                        </a>
                                      </td>
                                      <td>
                                        {{user.name}} {{user.surname}}
                                      </td>
                                      <td>
                                        {{user.email}}
                                      </td>
                                    </tr>
                                  </ng-container>
                                </tbody>
                              </table>
                            </mdb-card-body>
                          </mdb-card>
                        </div>


                      </div>
                    </mdb-card-body>
                  </mdb-card>
                  </div>
              </div>

        </mdb-tab>


      </ng-container>
    </ng-container>
  </mdb-tabset>
</div>
-->