<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <div style="margin: 10px;">
    <form [formGroup]="formExpert">
      <div class="row mt-2">
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
            <label for="identification_document" class="">NIF</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="expertname" formControlName="name" class="form-control form-control-sm" placeholder="">
            <label for="expertname" class="">Nombre</label>
            <mdb-error *ngIf="!formExpert.controls.name.valid && (formExpert.controls.name.dirty || formExpert.controls.name.touched)">Campo obligatorio</mdb-error>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="expertsurname" formControlName="surname" class="form-control form-control-sm"  placeholder="">
            <label for="expertsurname" class="">Apellidos</label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email" class="form-control form-control-sm" placeholder="">
            <label for="email" class="">Email</label>
            <mdb-error *ngIf="formExpert.controls.email.invalid && (formExpert.controls.email.dirty || formExpert.controls.email.touched)">Introduce un email válido</mdb-error>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="expertphone" formControlName="phone" class="form-control form-control-sm"  placeholder="">
            <label for="expertphone" class="">Teléfono</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="expertmobile" formControlName="mobile" class="form-control form-control-sm"  placeholder="">
            <label for="expertmobile" class="">Móvil</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="expertaddress" formControlName="address" class="form-control form-control-sm" placeholder="">
            <label for="expertaddress" class="">Dirección</label>
          </div>
        </div>
        <div class="col-4">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="expertprovince" formControlName="province" class="form-control form-control-sm" placeholder="">
            <label for="expertprovince" class="">Provincia</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="expertpopulation" formControlName="population" class="form-control form-control-sm" placeholder="">
            <label for="expertpopulation" class="">Población</label>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <input mdbInput type="text" id="expertpostalcode" formControlName="postal_code" class="form-control form-control-sm" placeholder="">
            <label for="expertpostalcode" class="">Código Postal</label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!--
<div class="container-fluid pt-2">
  <form [formGroup]="formExpert">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'<i class=\'fas fa-info-circle\'></i> Datos del nuevo '+data.entity_label">
            <div class="row">
              <div class="col-lg-2">
                  <div class="md-form">
                      <input mdbInput type="text" id="identification_document"
                          formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                      <label for="identification_document" class="">NIF</label>
                  </div>
              </div>
              <div class="col-lg">
                  <div class="md-form">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientname"
                          formControlName="name" class="form-control form-control-sm" placeholder="">
                      <label for="clientname" class="">Nombre</label>
                      <mdb-error
                          *ngIf="!formExpert.controls.name.valid && (formExpert.controls.name.dirty || formExpert.controls.name.touched)">
                          Campo obligatorio</mdb-error>
                  </div>
              </div>
              <div class="col-lg">
                  <div class="md-form">
                      <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientsurname"
                          formControlName="surname" class="form-control form-control-sm"  placeholder="">
                      <label for="clientsurname" class="">Apellidos</label>
                  </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email"
                    class="form-control form-control-sm" placeholder="">
                  <label for="email" class="">Email</label>
                  <mdb-error
                    *ngIf="formExpert.controls.email.invalid && (formExpert.controls.email.dirty || formExpert.controls.email.touched)">
                    Introduce un email válido</mdb-error>
                </div>
              </div>
              <div class="col-lg">
                  <div class="md-form">
                      <input mdbInput type="text" id="clientphone"
                          formControlName="phone" class="form-control form-control-sm"  placeholder="">
                      <label for="clientphone" class="">Teléfono</label>
                  </div>
              </div>
              <div class="col-lg">
                  <div class="md-form">
                      <input mdbInput type="text" id="clientmobile"
                          formControlName="mobile" class="form-control form-control-sm"  placeholder="">
                      <label for="clientmobile" class="">Móvil</label>
                  </div>
              </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <mdb-card class="z-depth-0">
                <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                    <mdb-icon fad icon="home-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Dirección
                </mdb-card-header>
                <mdb-card-body>
                  <div class="row">
                    <div class="col-lg">
                      <div class="md-form">
                        <input mdbInput type="text" id="address" formControlName="address"
                          class="form-control form-control-sm" placeholder="">
                        <label for="address" class="">Dirección</label>
                      </div>
                    </div>

                    <div class="col-lg">
                      <div class="md-form">
                        <input mdbInput type="text" id="population" formControlName="population"
                          class="form-control form-control-sm" placeholder="">
                        <label for="population" class="">Población</label>
                      </div>
                    </div>
                    <div class="col-lg">
                      <div class="md-form">
                        <input mdbInput type="text" id="province" formControlName="province"
                          class="form-control form-control-sm" placeholder="">
                        <label for="province" class="">Provincia</label>
                      </div>
                    </div>
                    <div class="col-lg-2 col-xl-1">
                      <div class="md-form">
                        <input mdbInput type="text" id="postalcode" formControlName="postal_code"
                          class="form-control form-control-sm" placeholder="">
                        <label for="postalcode" class="">Código Postal</label>
                      </div>
                    </div>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </form>
</div>
-->