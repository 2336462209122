import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { LicenseDataInterface } from 'src/app/shared/interfaces/license/license-data-interface';
import { ActionButtonsService } from '../services/action-buttons.service';
import { LicenseService } from '../services/license.service';
import { NotificationService } from '../services/notification.service';


const WORKORDER_PRICE = 0.15;
@Component({
  selector: 'app-buying-page',
  templateUrl: './buying-page.component.html',
  styleUrls: ['./buying-page.component.scss']
})
export class BuyingPageComponent implements OnInit, OnDestroy {
  /**
   * Componente pestañas
   */
  @ViewChild('buyingTabs', { static: false }) buyingTabs: TabsetComponent;

  //Precios de expedientes y partes: se cogerán del api eventualmente
  public readonly CLAIM_PRICE = 0.25;
  public readonly WORKORDER_PRICE = 0.15;

  /**
   * Módulos contratados del cliente
   */
  private modules:Array<any>=[];

  /**
   * Endpoint
   */
  private entity_endpoint = 'firmsclients';

  /**
   * Array de suscripciones: se irán añadiendo a medida que se hagan peticiones al api y se liberarán al salir
   */
  private subscriptions: Array<Subscription> = [];

  /**
   * Array de cambio de licencia en la cesta
   */
  public license_cart: Array<any>=[];
  /**
   * Array de cambio de módulos en la cesta
   */
  public modules_cart: Array<any>=[];

  /**
   * Precio total de la cesta
   */
  public total_cart: number = 0;

  /**
   * Flag para mostrar/ocultar el Loader
   */
  public loading: boolean = true;

  /**
   * Flag para mostrar el botón Añadir más en expedientes
   */
  public show_claims_add: boolean = false;
  /**
   * Cantidad de expedientes añadidos
   */
  public number_claims: number = 0;

  /**
   * Flag para mostrar el botón Añadir más en partes
   */
  public show_workorders_add: boolean = false;
  /**
   * Cantidad de partes añadidos
   */
  public number_workorders: number = 0;

  /**
   * Datos de la licencia
   */
  public license_data: LicenseDataInterface;

  constructor(private _license: LicenseService, private _api: ApirequestService, private _notification: NotificationService, private _buttons: ActionButtonsService) {

    //obtiene la información de la licencia del servicio y queda escuchando cambios
    this.license_data = this._license.getLicenseData();
    this._license.licenseDataSub.subscribe(
      data => {
        console.log(data)
        this.license_data = data;
      }
    )
  }

  ngOnDestroy(): void {
    //Libera las suscripciones al api
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscriptions.push(
      this._api.get(this.entity_endpoint+'/modules').subscribe(
        response => {
          this.modules = response.response.module
          console.log(this.modules)
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );

  }

  /**
   * Añade a la cesta número de expedientes seleccionado
   */
  addClaims(): void{
    this.license_cart = this.license_cart.filter(l => l.name !== 'number_claims')
    this.license_cart.push(
      {
        name:'number_claims',
        quantity:this.number_claims,
        description:'Expedientes ('+this.number_claims.toString()+'x'+this.CLAIM_PRICE.toString()+')',
        price: +(this.number_claims*this.CLAIM_PRICE).toFixed(2)
      }
    )
    this.calculateTotal()
    this.show_claims_add = false;
    this.number_claims = 0;

  }

  /**
   * Añade a la cesta número de partes seleccionado
   */
  addWorkorders(): void{
    this.license_cart = this.license_cart.filter(l => l.name !== 'number_workorders')
    this.license_cart.push(
      {
        name:'number_workorders',
        quantity:this.number_workorders,
        description:'Partes ('+this.number_workorders.toString()+'x'+this.WORKORDER_PRICE.toString()+')',
        price: +(this.number_workorders*this.WORKORDER_PRICE).toFixed(2)
      }
    )
    this.calculateTotal()
    this.show_workorders_add = false;
    this.number_workorders = 0;

  }


  /**
   * Elimina del carrito la ampliación de partes/expedientes
   * @param name servicio a eliminar del carrito
   */
  removeLicense(name: string): void{
    this.license_cart = this.license_cart.filter(m => m.name !== name);
    this.calculateTotal();
  }

  /**
   * Elimina del carrito un módulo
   * @param modulo módulo a eliminar del carrito
   */
  modulePrice(module: string): number {
    let price = 0;
    let selected_module = this.modules.find(m => m.name === module);
    if(selected_module) {
      price = selected_module.price
    }

    return price;
  }

  /**
   * Según si el cliente ya tiene el módulo pasado por parámetro se muestra el botón añadir módulo o no
   * @param module módulo a comprobar si lo tiene el cliente
   */
  showAddButton(module: string): boolean {
    let show = true;
    if(this.modules_cart.find(m => m.name === module)) {
      show =false;
    }

    return show;
  }

   /**
   * Según si el cliente ya tiene el módulo pasado por parámetro se muestra el botón quitar módulo o no
   * @param module módulo a comprobar si lo tiene el cliente
   */
  showRemoveButton(module: string): boolean {
    let show = true;
    if(this.modules_cart.find(m => m.delete == true && m.name === module)) {
      show =false;
    }

    return show;
  }

  /**
   * Añade el módulo al carrito
   * @param module módulo a añadir del carrito
   * @param remove se pasa sólo cuando el cliente elimina un modulo que ya tenia
   */
  addModule(module: string, remove?:boolean): void {

    let selected_module = this.modules.find(m => m.name === module);

    if(remove) {
      selected_module.delete = true;
    }
    this.modules_cart.push(selected_module)
    this.calculateTotal()
  }

  /**
   * Elimina el módulo al carrito
   * @param module módulo a eliminar del carrito
   */
  removeModule(module: string): void {
    this.modules_cart = this.modules_cart.filter(m => m.name !== module);
    this.calculateTotal()
  }

  /**
   * Calcula el total del carrito
   */
  private calculateTotal(): void {
    this.total_cart = 0;

    this.license_cart.forEach(element => {
        this.total_cart += +element.price;
    })

    this.modules_cart.forEach(element => {
      if(!element.delete) {
        this.total_cart += +element.price;
      }
    });

    this.total_cart = +this.total_cart.toFixed(2)
  }

  /**
   * Envío al api
   */
  onSubmit(): void{
    this._buttons.setLoading(true);

    let param ={};
    if(this.license_cart.length>0) {
        param['license'] = {}
        this.license_cart.forEach(
          item => {
            param['license'][item.name] = item.quantity
          }
        )

        /* param['license'] = this.license_cart.map(
          item => {
            let data:{name:string, quantity:number} = {'name': item.name, quantity: item.quantity};
            return data;
          }
        ) */

    }

    if(this.modules_cart.length>0) {

        param['modules'] = this.modules_cart.map(
          product => {
            let module:{name:string, delete?:boolean} = {'name': product.name};
            if(product.delete) {
              module.delete = product.delete
            }

            return module;
          }
        )

    }

    this.subscriptions.push(
      this._api.post(this.entity_endpoint+'/license',JSON.stringify(param)).subscribe(
        response => {

          this._notification.notificacionOK(response.response.firmclient);

          location.reload();
        },
        error => {
          this._buttons.setLoading(false);
        }
      )
    )
  }




}
