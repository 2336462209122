import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApirequestService } from '../../../api/request/apirequest.service';
import { map } from 'rxjs/operators';
import { UserPermissionsService } from 'src/app/main/services/user-permissions.service';
import { FirmsService } from 'src/app/main/services/firms.service';
import { UserService } from 'src/app/main/services/user.service';
import { TabService } from 'src/app/main/services/tab.service';
import { LicenseService } from 'src/app/main/services/license.service';
import { ModulesService } from 'src/app/main/services/modules.service';
import { EntityService } from 'src/app/main/services/entity.service';
import { DropdownService } from 'src/app/main/services/dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private _api: ApirequestService,private _userPermissions: UserPermissionsService, private _license: LicenseService, private _modules: ModulesService,private _firms: FirmsService
    , private _user: UserService, private _tabs: TabService, private _entity: EntityService, private _dropdown: DropdownService ) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


      if (sessionStorage.getItem('isLoggedin')) {

        if(sessionStorage.getItem('activeFirm')) {

          if(this._userPermissions.getPermissions() === undefined){

            return this.permisos().pipe(
              map(
                response => {
                    this._userPermissions.setPermissions(response.response.appconfig.permissions);
                    this._firms.setFirms(response.response.appconfig.firms);
                    this._entity.setEntitiesData(response.response.appconfig.firms.find(active => active.active)?.translationsmodels)

                    this._user.setUserData({
                      id: response.response.appconfig.id,
                      role_id: response.response.appconfig.role_id,
                      email: response.response.appconfig.email,
                      name: response.response.appconfig.name+' '+response.response.appconfig.surname
                    });



                    this._license.setLicenseData(response.response.appconfig.license_data)
                    this._modules.setModules(response.response.appconfig.modules)

                    if(sessionStorage.getItem('dropdowns')) {
                      this._dropdown.recoverDropdowns()

                      sessionStorage.removeItem('dropdowns')
                    }

                    if(sessionStorage.getItem('openedTabs')) {
                      this._tabs.recoverTabs()

                      sessionStorage.removeItem('openedTabs')
                    }

                    this._userPermissions.setCheckPermissions(false);
                    let activeFirm = this._firms.getActiveFirm().toString();
                    sessionStorage.setItem('activeFirm', activeFirm);
                    return true
                }
              )
            );


          } else {
            return true;
          }

        } else {
          sessionStorage.clear();
          this._user.reset();
          this._dropdown.resetDropdowns();
          this.router.navigate(['/login']);
          return false;
        }
    }

    sessionStorage.clear();
    this._user.reset();
    this._dropdown.resetDropdowns();
    this.router.navigate(['/login']);
    return false;
  }



  private permisos(): Observable<any>{
    this._userPermissions.setCheckPermissions(true);

    return this._api.get('users/appconfig')

  }

}
