import { DatePipe } from "@angular/common";
import { EmailInterface } from "../interfaces/data/email-interface";

export class PGAUtils {

  private illegalRe = /[\/\?<>\\:\*\|":]/g;
  private controlRe = /[\x00-\x1f\x80-\x9f]/g;
  private reservedRe = /^\.+$/g;
  private windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/ig;

  private noAlfa = /[ |¿?/¡!\\]/g;

  private sanitize(input: string): string {
    let sanitized = input
      .replace(this.illegalRe, '_')
      .replace(this.controlRe, '_')
      .replace(this.reservedRe, '_')
      .replace(this.windowsReservedRe, '_')
      .replace(this.noAlfa, '_');

    return sanitized;
  }

  /**
   * Devuelve una copia el Array original sin el elemento con id pasado como parámetro
   * @param array_origin array del que eliminar
   * @param element elemento a eliminar
   * @returns devuelve una copia del array original sin el elemento
   *
   * Ej: mi_array = this.utils.deleteFromArrayById(mi_array, elemento)
   */
  deleteFromArrayById(array_origin: Array<any>, element:any): Array<any> {
    return array_origin.filter(it => it.id !== element.id);
  }

  /**
   * Devuelve una copia el Array original sin el elemento con el campo pasado como parámetro
   * @param array_origin array del que eliminar
   * @param element elemento a eliminar
   * @param field campo único que se comparará para filtrar el array
   * @returns devuelve una copia del array original sin el elemento
   *
   * Ej: mi_array = this.utils.deleteFromArrayById(mi_array, elemento, 'id')
   */
  deleteFromArrayByField(array_origin: Array<any>, element:any, field:string): Array<any> {
    return array_origin.filter(it => it[field] !== element[field]);
  }

  /**
   * Genera el código HTML para concatenar a la cabecera de una pestaña mdb-tab
   * @param colorclass clase de css que aplicará el color de fondo al badge (clases definidas en styles.scss). P.ej: badgeinfo
   * @param value contenido de la badge
   * @returns Cadena con el código HTML de la badge. P.ej: '\<span class="tab-badge badgeinfo">3\</span>'
   */
  tabBadgeContent(colorclass: string, value: string): string {
    let content = ' <span class="tab-badge ';
    content += colorclass + '">';
    content += value;
    content += '</span>';
    return content;
  }


  limpiarNombreArchivo(nombre: string): string {
    let nombre_limpio = this.sanitize(nombre);

    nombre_limpio = nombre_limpio.toLowerCase();

    //acentos
    nombre_limpio = nombre_limpio.replace(/á/g, "a");
    nombre_limpio = nombre_limpio.replace(/é/g, "e");
    nombre_limpio = nombre_limpio.replace(/í/g, "i");
    nombre_limpio = nombre_limpio.replace(/ó/g, "o");
    nombre_limpio = nombre_limpio.replace(/ú/g, "u");
    nombre_limpio = nombre_limpio.replace(/à/g, "a");
    nombre_limpio = nombre_limpio.replace(/è/g, "e");
    nombre_limpio = nombre_limpio.replace(/ì/g, "i");
    nombre_limpio = nombre_limpio.replace(/ò/g, "o");
    nombre_limpio = nombre_limpio.replace(/ù/g, "u");

    //diéresis
    nombre_limpio = nombre_limpio.replace(/ä/g, "a");
    nombre_limpio = nombre_limpio.replace(/ë/g, "e");
    nombre_limpio = nombre_limpio.replace(/ï/g, "i");
    nombre_limpio = nombre_limpio.replace(/ö/g, "o");
    nombre_limpio = nombre_limpio.replace(/ü/g, "u");

    //grados
    nombre_limpio = nombre_limpio.replace(/º/g, "o");
    nombre_limpio = nombre_limpio.replace(/ª/g, "a");

    //consonantes
    nombre_limpio = nombre_limpio.replace(/ñ/g, "n");
    nombre_limpio = nombre_limpio.replace(/ç/g, "c");

    return nombre_limpio;
  }

  cleanReplyAddress(address: string):string {
    let addresses = address.split(',');

    addresses = addresses.map(
      a => {
        let formatted = a.split('<').pop().split('>')[0];
        return formatted;
      }
    )

    return addresses.join(';')
  }

  setReplyMessageBody(email: EmailInterface):string {
    const datePipe = new DatePipe('es');

    let reply_header = '\r\n\r\nEl '+datePipe.transform(email.date,'dd/MM/yyyy')+' a las '+datePipe.transform(email.date,'HH:mm')+', '+email.from+' escribió:\r\n';

    return (reply_header+'\t'+email.body).replace(/  /g, '\r\n\t');
  }

  setForwardMessageBody(email: EmailInterface): string {
    const datePipe = new DatePipe('es');
    let forward_header = '    -------- Mensaje reenviado --------  ';

    forward_header+='Asunto: '+email.subject+'  ';
    forward_header+='Fecha: '+datePipe.transform(email.date,'dd/MM/yyyy HH:mm:ss')+'  ';
    forward_header+='De: '+email.from+'  ';
    forward_header+='Para: '+email.to+'  ';
    if(email.cc) {
      forward_header+='CC: '+email.cc+'  ';
    }
    forward_header+='  ';
    return (forward_header+email.body).replace(/  /g, '\r\n');
  }


  public SHA1(msg) {
    function rotate_left(n, s) {
      var t4 = (n << s) | (n >>> (32 - s));
      return t4;
    };
    function lsb_hex(val) {
      var str = '';
      var i;
      var vh;
      var vl;
      for (i = 0; i <= 6; i += 2) {
        vh = (val >>> (i * 4 + 4)) & 0x0f;
        vl = (val >>> (i * 4)) & 0x0f;
        str += vh.toString(16) + vl.toString(16);
      }
      return str;
    };
    function cvt_hex(val) {
      var str = '';
      var i;
      var v;
      for (i = 7; i >= 0; i--) {
        v = (val >>> (i * 4)) & 0x0f;
        str += v.toString(16);
      }
      return str;
    };
    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, '\n');
      var utftext = '';
      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        }
        else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        }
        else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }
      }
      return utftext;
    };
    var blockstart;
    var i, j;
    var W = new Array(80);
    var H0 = 0x67452301;
    var H1 = 0xEFCDAB89;
    var H2 = 0x98BADCFE;
    var H3 = 0x10325476;
    var H4 = 0xC3D2E1F0;
    var A, B, C, D, E;
    var temp;
    msg = Utf8Encode(msg);
    var msg_len = msg.length;
    var word_array = new Array();
    for (i = 0; i < msg_len - 3; i += 4) {
      j = msg.charCodeAt(i) << 24 | msg.charCodeAt(i + 1) << 16 |
        msg.charCodeAt(i + 2) << 8 | msg.charCodeAt(i + 3);
      word_array.push(j);
    }
    switch (msg_len % 4) {
      case 0:
        i = 0x080000000;
        break;
      case 1:
        i = msg.charCodeAt(msg_len - 1) << 24 | 0x0800000;
        break;
      case 2:
        i = msg.charCodeAt(msg_len - 2) << 24 | msg.charCodeAt(msg_len - 1) << 16 | 0x08000;
        break;
      case 3:
        i = msg.charCodeAt(msg_len - 3) << 24 | msg.charCodeAt(msg_len - 2) << 16 | msg.charCodeAt(msg_len - 1) << 8 | 0x80;
        break;
    }
    word_array.push(i);
    while ((word_array.length % 16) != 14) word_array.push(0);
    word_array.push(msg_len >>> 29);
    word_array.push((msg_len << 3) & 0x0ffffffff);
    for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
      for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
      for (i = 16; i <= 79; i++) W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
      A = H0;
      B = H1;
      C = H2;
      D = H3;
      E = H4;
      for (i = 0; i <= 19; i++) {
        temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 20; i <= 39; i++) {
        temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 40; i <= 59; i++) {
        temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      for (i = 60; i <= 79; i++) {
        temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
        E = D;
        D = C;
        C = rotate_left(B, 30);
        B = A;
        A = temp;
      }
      H0 = (H0 + A) & 0x0ffffffff;
      H1 = (H1 + B) & 0x0ffffffff;
      H2 = (H2 + C) & 0x0ffffffff;
      H3 = (H3 + D) & 0x0ffffffff;
      H4 = (H4 + E) & 0x0ffffffff;
    }
    var temp: any = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

    return temp.toLowerCase();
  }

  //EXPORTAR EXCEL

  /*
   static exportTableToExcel(tableId: string, name?: string) {
     let { sheetName, fileName } = getFileName(name);
     let targetTableElm = document.getElementById(tableId);
     let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{
       sheet: sheetName
     });
     XLSX.writeFile(wb, `${fileName}.xlsx`);
   }
   */

  specialCharacters(text: string) {
    if (text != undefined) {
      text = text.replace(/</g, '&lt;');
      text = text.replace(/"/g, '&quot;');
      text = text.replace(/&/g, '&amp;');
      text = text.replace(/\\/g, '&frasl;')
      text = text.replace(/>/g, '&gt;');
      text = text.replace(/‚/g, '&sbquo;');
      text = text.replace(/„/g, '&bdquo;');
      text = text.replace(/†/g, '&dagger;');
      text = text.replace(/‡/g, '&Dagger;');
      text = text.replace(/‰/g, '&permil;');
      text = text.replace(/‹/g, '&lsaquo;');
      text = text.replace(/‘/g, '&lsquo;');
      text = text.replace(/’/g, '&rsquo;');
      text = text.replace(/“/g, '&ldquo;');
      text = text.replace(/”/g, '&rdquo;');
      text = text.replace(/™/g, '&trade;');
      text = text.replace(/›/g, '&rsaquo;');
      text = text.replace(/ /g, '&nbsp;');
      text = text.replace(/¡/g, '&iexcl;');
      text = text.replace(/¢/g, '&cent;');
      text = text.replace(/£/g, '&pound;');
      text = text.replace(/¤/g, '&curren;');
      text = text.replace(/¥/g, '&yen;');
      text = text.replace(/¦/g, '&brvbar;');
      text = text.replace(/§/g, '&sect;');
      text = text.replace(/¨/g, '&uml;');
      text = text.replace(/©/g, '&copy;');
      text = text.replace(/ª/g, '&ordf;');
      text = text.replace(/«/g, '&laquo;');
      text = text.replace(/¬/g, '&not;');
      text = text.replace(/­	/g, '&shy;');
      text = text.replace(/®/g, '&reg;');
      text = text.replace(/¯/g, '&macr;');
      text = text.replace(/°/g, '&deg;');
      text = text.replace(/±/g, '&plusmn;');
      text = text.replace(/²/g, '&sup2;');
      text = text.replace(/³/g, '&sup3;');
      text = text.replace(/´/g, '&acute;');
      text = text.replace(/µ/g, '&micro;');
      text = text.replace(/¶/g, '&para;');
      text = text.replace(/·/g, '&middot;');
      text = text.replace(/¸/g, '&cedil;');
      text = text.replace(/¹/g, '&sup1;');
      text = text.replace(/º/g, '&ordm;');
      text = text.replace(/»/g, '&raquo;');
      text = text.replace(/∼/g, '&sim;');
      text = text.replace(/−/g, '&minus;');
      text = text.replace(/⋅/g, '&sdot;');
      text = text.replace(/á/g, '&aacute;');
      text = text.replace(/é/g, '&eacute;');
      text = text.replace(/í/g, '&iacute;');
      text = text.replace(/ó/g, '&oacute;');
      text = text.replace(/ú/g, '&uacute;');
      text = text.replace(/à/g, '&agrave;');
      text = text.replace(/è/g, '&egrave;');
      text = text.replace(/ì/g, '&igrave;');
      text = text.replace(/ò/g, '&ograve;');
      text = text.replace(/ù/g, '&ugrave;');
    }
    return text;
  }

  //genera un elemento <a> con el atributo download a un documento que se genera de forma dinámica, en función de lo pasado como cabecera y datos
  generarExcel(entidad: string, cabecera: Array<any>, datos: Array<any>, campos: Array<any>): void {
    let texto = '';
    texto = '<!DOCTYPE\thtml><html\tlang="es-ES"\txml:lang="es-ES"\tstyle="font-family: calibri;font-size:14pt;><head><meta\thttp-equiv="content-type"\tcontent="application/vnd.ms-excel;\tcharset=UTF-8">\t<meta\tcharset="utf-8"\t/>\t</head>';
    texto = texto + '<body><table>';
    texto = texto + '<tr">'

    for (let i = 0; i < cabecera.length; i++) {
      if (cabecera[i]) {
        texto = texto + '<th>' + this.specialCharacters(cabecera[i]) + '</th>';
      }
    }
    texto = texto + '</tr>';

    //field

    for (let i = 0; i < datos.length; i++) {
      texto = texto + '<tr>';
      for (let index = 0; index < campos.length; index++) {

        if (entidad === campos[index]['entity']) {
          texto = texto + '<td>' + datos[i][campos[index].field] + '</td>';
        } else {

          if (datos[i][campos[index].entity] !== null) {

            texto = texto + '<td>' + datos[i][campos[index].entity][campos[index].field] + '</td>';
          } else {
            texto = texto + '<td>' + '</td>';
          }

        }

      }
      texto = texto + '</tr>';
    }

    texto = texto + '</body></html>'

    texto = texto.replace(/undefined/g, '');

    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/vnd.ms-excel;charset=utf-8,' + texto);
    element.setAttribute('download', 'listado.xls');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

  }

    // Convierte a minúsculas las claves de un objeto
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
      }, {});
    }


    md5(inputString) {
      var hc="0123456789abcdef";
      function rh(n) {var j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
      function ad(x,y) {var l=(x&0xFFFF)+(y&0xFFFF);var m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
      function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
      function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
      function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
      function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
      function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
      function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
      function sb(x) {
          var i;var nblk=((x.length+8)>>6)+1;var blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
          for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
          blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
      }
      var i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
      for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
          a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
          b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
          c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
          d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
          a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
          b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
          c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
          d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
          a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
          b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
          c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
          d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
          a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
          b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
          c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
          d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
          a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
          b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
          c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
          d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
          a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
          b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
      }
      return rh(a)+rh(b)+rh(c)+rh(d);
  }

  objectDeepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.objectDeepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.objectDeepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}
}
