

export class ProviderFilters {

  public staticFilters;
  public optionalFilters;


  constructor(private entities){

    this.staticFilters = [
      {
        entity: this.entities.company.entity,
        field: {
          name: 'name',
          type: 'text',
          label: 'Nombre',
          width: 2
        }
      },
      {
        entity: this.entities.company.entity,
        field: {
          name: 'cif',
          type: 'text',
          label: 'CIF',
          width: 2
        }
      },
      {
        entity: this.entities.company.entity,
        field: {
          name: 'address',
          type: 'text',
          label: 'Dirección',
          width: 2
        }
      },
      {
        entity: this.entities.company.entity,
        field: {
          name: 'postal_code',
          type: 'text',
          label: 'C.Postal',
          width: 2
        }
      },
      {
        entity: this.entities.company.entity,
        field: {
          name: 'email',
          type: 'text',
          label: 'Email',
          width: 2
        }
      },
    ]
  }
}

