<app-loader *ngIf="loading"></app-loader>

  <div class="divCustomGlobal">
    <div class="divCustomSection">
      <i class='fas fa-book mr-2'></i> Información
    </div>
    <!--PESTAÑA INFORMACION-->
    <form [formGroup]="formNewClaim">
      <div style="margin: 10px;">
        <div class="row mt-2">
          <div class="col-md-2">
            <div class="md-form md-outline">
              <mdb-date-picker name="mydate2" [inline]="true" [options]="datepicker_options" formControlName="order_date" [label]="'Fecha Encargo'"></mdb-date-picker>
            </div>
          </div>
          <div class="col-lg">
            <div class="md-form md-outline">
              <input type="text" name="client_name" formControlName="client_name" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
              <label for="client_name" class="">{{client_entity.labels.form | titlecase}}</label>
              <mdb-error *ngIf="formNewClaim.controls.client_name.invalid && (formNewClaim.controls.client_name.dirty || formNewClaim.controls.client_name.touched)">Campo Obligatorio</mdb-error>
            </div>
          </div>

          <div class="col-md-1">
            <div class="row">
              <div class="md-form md-outline m-1">
                <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [mdbTooltip]="'Seleccionar '+(client_entity.labels.form | titlecase)" placement="top" id="seleccionarCliente" mdbWavesEffect (click)="selectClient()">
                  <mdb-icon fas icon="user-check"></mdb-icon>
                </button>
              </div>
            
              <div class="md-form md-outline m-1">
                <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" mdbWavesEffect [mdbTooltip]="'Crear '+(client_entity.labels.form | titlecase)" placement="top" (click)="newClient()">
                  <mdb-icon fas icon="user-plus"></mdb-icon>
                </button>
              </div>
              
            </div>
          </div>

          <div class="col-lg">
            <div class="md-form md-outline">
              <mdb-select-2 [outline]="true" placeholder="" label="Tipo Proveedor" formControlName="typeprovider">
                <mdb-select-option *ngFor="let type of dropdowns?.typeprovider" [value]="type.id">{{ type.name }}</mdb-select-option>
              </mdb-select-2>
            </div>

          </div>

          <div class="col-lg">
            <ng-container [ngSwitch]="selected_typeprovider">
              <ng-container *ngSwitchCase="-1">
                <div class="col">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                      <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                    </mdb-select-2>
                    <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                      Dirección Obligatoria
                    </mdb-error>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="1">
                <div class="col">
                  <div class="md-form md-outline">

                    <mdb-select-2 [outline]="true" placeholder="Elige una póliza:" label="Póliza" formControlName="policy_id" mdbValidate [validateSuccess]="false">
                      <mdb-select-option *ngFor="let policy of policies" [value]="policy.id">
                        {{ policy.number }} - {{policy.provider.name}}
                      <ng-container *ngIf="policy.subprovider; else policyprovider">
                        ({{policy.subprovider.name}})
                      </ng-container>
                      - <span class="small">{{policy.address_complete}}</span>
                      </mdb-select-option>
                    </mdb-select-2>
                    <mdb-error *ngIf="formNewClaim.controls.policy_id.invalid && (formNewClaim.controls.policy_id.dirty || formNewClaim.controls.policy_id.touched)">
                      Póliza Obligatoria
                    </mdb-error>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="2">
                <div class="row">
                  <div class="col-6">
                    <div class="md-form md-outline">
                      <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                        <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                      </mdb-select-2>
                      <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                        Dirección Obligatoria
                      </mdb-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="md-form md-outline">
                      <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                        <mdb-select-option *ngFor="let repairman of dropdowns?.repairman" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
                      </mdb-select-2>
                      <mdb-error *ngIf="formNewClaim.controls.provider_id.invalid && (formNewClaim.controls.provider_id.dirty || formNewClaim.controls.provider_id.touched)">
                        Reparador Obligatorio
                      </mdb-error>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="3">
                <div class="row">
                  <div class="col-6">
                    <div class="md-form md-outline">
                      <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                        <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                      </mdb-select-2>
                      <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                        Dirección Obligatoria
                      </mdb-error>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="md-form md-outline">
                      <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                        <mdb-select-option *ngFor="let administrator of dropdowns?.administrator" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
                      </mdb-select-2>
                      <mdb-error *ngIf="formNewClaim.controls.provider_id.invalid && (formNewClaim.controls.provider_id.dirty || formNewClaim.controls.provider_id.touched)">
                        Administrador Obligatorio
                      </mdb-error>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

        </div>
        <div class="row mt-3">
          
          <div class="col-md-2 mt-1">
            <mdb-select-2 [outline]="true" [placeholder]="'Elige un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName = "guild_id">
              <mdb-select-option *ngFor="let guild of dropdowns?.guild" [value]="guild.id">{{ guild.name }}</mdb-select-option>
            </mdb-select-2>
          </div>
          <div class="col-md-2">
            <div class="md-form md-outline">
              <input mdbInput
              type="text"
              class="form-control form-control-sm"
              id="number_provider"
              formControlName="number_provider"
              placeholder="">
              <label for="number_provider">Nº Aviso</label>
            </div>
          </div>
          <div class="col-md-2">
            <div class="md-form md-outline">
              <input mdbInput
              type="text"
              class="form-control form-control-sm"
              id="number_assistance"
              formControlName="number_assistance"
              placeholder="">
              <label for="number_assistance">Nº Asistencia</label>
            </div>
          </div>
          
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <div class="md-form md-outline">
              <textarea rows="4" type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
              <label for="description">Descripción</label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  
<!--
<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formNewClaim">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(client_entity.labels.form | titlecase)+', Dirección, Proveedor'">
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col">
                    <span class="character-counter">{{client_entity.labels.form | titlecase}}</span>
                    <div class="md-form md-outline input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn-group" mdbDropdown>
                          <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [mdbTooltip]="'Seleccionar '+(client_entity.labels.form | titlecase)" placement="top"
                          id="seleccionarCliente" mdbWavesEffect (click)="selectClient()">
                          <mdb-icon fas icon="user"></mdb-icon>
                        </button>
                        </div>
                      </div>
                      <input type="text" name="client_name" formControlName="client_name" mdbValidate [validateSuccess]="false"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <mdb-error *ngIf="formNewClaim.controls.client_name.invalid && (formNewClaim.controls.client_name.dirty || formNewClaim.controls.client_name.touched)">
                      Campo Obligatorio
                    </mdb-error>
                    <div class="input-group-append" id="button-addon4">
                      <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" mdbWavesEffect [mdbTooltip]="'Crear '+(client_entity.labels.form | titlecase)" placement="top" (click)="newClient()">
                        <mdb-icon fas icon="user-plus"></mdb-icon>
                      </button>
                    </div>
                    </div>
                  </div>
                </div>

              </div>


              <div class="col-md-3">
                <div class="md-form md-outline">
                  <mdb-select-2 [outline]="true" placeholder="" label="Tipo Proveedor" formControlName="typeprovider">
                    <mdb-select-option *ngFor="let type of dropdowns?.typeprovider" [value]="type.id">{{ type.name }}</mdb-select-option>
                  </mdb-select-2>
                </div>

              </div>


            </div>
            <div class="row mt-3">

              <ng-container [ngSwitch]="selected_typeprovider">
                  <ng-container *ngSwitchCase="-1">
                    <div class="col-md-4">
                      <div class="md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                          <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                        </mdb-select-2>
                        <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                          Dirección Obligatoria
                        </mdb-error>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="1">
                    <div class="col-md-4">
                      <div class="md-form md-outline">

                        <mdb-select-2 [outline]="true" placeholder="Elige una póliza:" label="Póliza" formControlName="policy_id" mdbValidate [validateSuccess]="false">
                          <mdb-select-option *ngFor="let policy of policies" [value]="policy.id">
                            {{ policy.number }} - {{policy.provider.name}}
                          <ng-container *ngIf="policy.subprovider; else policyprovider">
                            ({{policy.subprovider.name}})
                          </ng-container>
                          - <span class="small">{{policy.address_complete}}</span>
                          </mdb-select-option>
                        </mdb-select-2>
                        <mdb-error *ngIf="formNewClaim.controls.policy_id.invalid && (formNewClaim.controls.policy_id.dirty || formNewClaim.controls.policy_id.touched)">
                          Póliza Obligatoria
                        </mdb-error>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="2">
                    <div class="col-md-4">
                      <div class="md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                          <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                        </mdb-select-2>
                        <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                          Dirección Obligatoria
                        </mdb-error>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                          <mdb-select-option *ngFor="let repairman of dropdowns?.repairman" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
                        </mdb-select-2>
                        <mdb-error *ngIf="formNewClaim.controls.provider_id.invalid && (formNewClaim.controls.provider_id.dirty || formNewClaim.controls.provider_id.touched)">
                          Reparador Obligatorio
                        </mdb-error>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="3">
                    <div class="col-md-4">
                      <div class="md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                          <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                        </mdb-select-2>
                        <mdb-error *ngIf="formNewClaim.controls.clientaddress_id.invalid && (formNewClaim.controls.clientaddress_id.dirty || formNewClaim.controls.clientaddress_id.touched)">
                          Dirección Obligatoria
                        </mdb-error>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                          <mdb-select-option *ngFor="let administrator of dropdowns?.administrator" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
                        </mdb-select-2>
                        <mdb-error *ngIf="formNewClaim.controls.provider_id.invalid && (formNewClaim.controls.provider_id.dirty || formNewClaim.controls.provider_id.touched)">
                          Administrador Obligatorio
                        </mdb-error>
                      </div>
                    </div>
                  </ng-container>
              </ng-container>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>

      <div class="col-12 custom-card-padding mt-3">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="Datos Adicionales">
            <div class="row">
              <div class="col-md-2">
                <mdb-date-picker name="mydate2" [inline]="true" [options]="datepicker_options" formControlName="order_date" [label]="'Fecha Encargo'"></mdb-date-picker>

              </div>
              <div class="col-md-2 mt-1">
                <mdb-select-2 [outline]="false" [placeholder]="'Elige un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName = "guild_id">
                  <mdb-select-option *ngFor="let guild of dropdowns?.guild" [value]="guild.id">{{ guild.name }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <div class="col-md-2">
                <div class="md-form">
                  <input mdbInput
                  type="text"
                  class="form-control form-control-sm"
                  id="number_provider"
                  formControlName="number_provider"
                  placeholder="">
                  <label for="number_provider">Nº Aviso</label>
                </div>
              </div>
              <div class="col-md-2">
                <div class="md-form">
                  <input mdbInput
                  type="text"
                  class="form-control form-control-sm"
                  id="number_assistance"
                  formControlName="number_assistance"
                  placeholder="">
                  <label for="number_assistance">Nº Asistencia</label>
                </div>
              </div>
              <div class="col">
                <div class="md-form">
                  <textarea type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
                  <label for="description">Descripción</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </form>
</div>
-->