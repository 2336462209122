<!--<mdb-card class="z-depth-0">
  <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
      <mdb-icon fad
      icon="list"
      size="lg"
      class="mr-1"></mdb-icon>Líneas
  </mdb-card-header>
  <mdb-card-body>-->
    <div class="table-responsive">
      <table mdbTable small="true" mdbTableScroll scrollY="true" [maxHeight]="maxHeight" stickyHeader= "true" class="excel-table">
        <thead>
          <tr>
            <th scope="col" class="buttons-column"></th>
            <ng-container *ngFor="let header of headers">
              <ng-container *ngIf="header.tooltip; else plainheader">
                <th scope="col" mdbTooltip="{{header.tooltip}}" placement="top">{{header.header}}</th>
              </ng-container>
              <ng-template #plainheader>

                <ng-container *ngIf="header.class; else plain2">

                <th scope="col" [class]="header.class">{{header.header}}</th>
                </ng-container>
                <ng-template #plain2>
                  <th scope="col">{{header}}</th></ng-template>
              </ng-template>
             </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let detailForm of detailsForms.controls; let i=index">
            <tr mdbTableCol>
              <td class="buttons-column">
                <ng-container *ngIf="!detailForm.controls.readonly.value; else readonly">
                  <a (click)="deleteDetail(i)">
                    <mdb-icon fad icon="trash" class="red-text ml-1"></mdb-icon>
                  </a>
                </ng-container>
                <ng-template #readonly>

                  <ng-container *ngIf="getDetailById(detailForm.controls.id.value)?.type_export_line_id == 3; else not_exported">
                    <mdb-icon fad icon="share-square" class="grey-text ml-1" mdbTooltip="Exportado" placement="top"></mdb-icon>
                  </ng-container>

                  <ng-template #not_exported>
                    <ng-container *ngIf="master?.type_state_id >= 4">
                      <mdb-icon fad icon="receipt" class="grey-text ml-1" mdbTooltip="Semi/cobrado" placement="top"></mdb-icon>
                    </ng-container>

                  </ng-template>



                </ng-template>
              </td>
              <ng-container class="test" [formGroup]="detailForm">
                <ng-container *ngFor="let field of field_order; let j= index">
                  <td>
                    <ng-container [ngSwitch]="field.type">
                      <ng-container *ngSwitchCase="'checkbox'">
                        <mdb-checkbox [default]="true" id="{{field.control}}{{i}}" formControlName="{{field.control}}" (change)="liquidationStatusChange($event, detailForm)"></mdb-checkbox>
                      </ng-container>
                      <ng-container *ngSwitchCase="'select'">

                        <ng-container *ngIf="field.addKey; else defaultselect">
                          <select class="form-control form-control-sm" id="exampleFormControlSelect1" formControlName="{{field.control}}" (keydown)="addLineaKeyTAB($event, i)"  mdbValidate [validateSuccess]="false" >
                            <option *ngFor="let option of getDropdown(field.dropdown)" class="form-control form-control-sm" [value]="option.id" [disabled]="!option.enabled" [ngClass]="{'mdb-option disabled option-disabled': !option.enabled}">{{ option[field.field_value] }}</option>
                          </select>
                        </ng-container>
                        <ng-template #defaultselect>
                          <select class="form-control form-control-sm" id="exampleFormControlSelect1" formControlName="{{field.control}}"  mdbValidate [validateSuccess]="false" >
                            <option *ngFor="let option of getDropdown(field.dropdown)" class="form-control form-control-sm" [value]="option.id" [disabled]="!option.enabled" [ngClass]="{'mdb-option disabled option-disabled': !option.enabled}">{{ option[field.field_value] }}</option>
                          </select>
                        </ng-template>
                      </ng-container>


                      <ng-container *ngSwitchDefault>
                        <ng-container *ngIf="field.addKey; else defaultinput">
                          <input mdbInput type="{{field.type}}" id="{{field.control}}{{i}}" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" [ngClass]="{'text-right': field.type == 'number'}" formControlName="{{field.control}}" (keydown)="addLineaKeyDOWN($event, i)">
                        </ng-container>
                        <ng-template #defaultinput>
                          <input mdbInput type="{{field.type}}" id="{{field.control}}{{i}}" mdbValidate [validateSuccess]="false"  class="form-control form-control-sm" [ngClass]="{'text-right': field.type == 'number'}" formControlName="{{field.control}}">
                        </ng-template>

                        <mdb-error class="custom-input-error" *ngIf="detailForm.controls[field.control].invalid && (detailForm.controls[field.control].dirty || detailForm.controls[field.control].touched)">
                          <ng-container *ngFor="let error of detailForm.controls[field.control].errors | keyvalue">
                            <ng-container [ngSwitch]="error.key">
                              <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                              <ng-container *ngSwitchCase="'min'">Valor incorrecto</ng-container>
                              <ng-container *ngSwitchDefault>{{field.error_msg}}</ng-container>
                            </ng-container>
                          </ng-container>


                        </mdb-error>

                      </ng-container>
                    </ng-container>
                  </td>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
          <!-- <tr>
             <td [colSpan]="headers.length">
              <ng-container *ngIf="master?.typecondition_id == 1 || master?.type_export_id == 1">
                  <a (click)="addDetail()" *ngIf="showAddDetail()">
                    <mdb-icon fad
                              icon="plus-circle"
                              size="lg"
                              class="blue-text"></mdb-icon>
                  </a>
              </ng-container>
             </td>
          </tr> -->
        </tbody>
      </table>
     <div class="row mt-1 ">
      <div class="col-1">
        <ng-container *ngIf="master?.typecondition_id == 1 || master?.type_export_id == 1">
          <a (click)="addDetail()" *ngIf="showAddDetail()">
            <mdb-icon fad icon="plus-circle" size="lg" class="blue-text"></mdb-icon>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
  <!--</mdb-card-body>
</mdb-card>-->

  <div class="row mt-1">
    <div class="col d-flex justify-content-end" style="margin:0; padding:0; box-sizing:border-box;">
      <mdb-card class="text-center m-1">
        <mdb-card-header class="blue-grey-text white border-bottom border-primary total-header">Total Neto</mdb-card-header>
        <mdb-card-body class="total-card">
          {{total_price | number:'1.2-2':'es'}} €
        </mdb-card-body>
      </mdb-card>
      <mdb-card class="text-center m-1">
        <mdb-card-header class="blue-grey-text white border-bottom border-primary total-header">Total Impuesto</mdb-card-header>
        <mdb-card-body class="total-card">
          <!-- <div class="input-group input-group-sm">
            <input type="text" class="form-control text-right" value="{{total_taxe | number:'1.2-2':'es'}}" readonly>
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div> -->
          {{total_taxe | number:'1.2-2':'es'}} €
        </mdb-card-body>
      </mdb-card>
      <mdb-card class="text-center m-1">
        <mdb-card-header class="blue-grey-text white border-bottom border-primary total-header">Total Importe</mdb-card-header>
        <mdb-card-body class="total-card">
          <!-- <div class="input-group input-group-sm">
            <input type="text" class="form-control text-right" value="{{total_lineas | number:'1.2-2':'es'}}" readonly>
            <div class="input-group-append">
              <span class="input-group-text">€</span>
            </div>
          </div> -->
          {{total_lineas | number:'1.2-2':'es'}} €
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>

<!--
<mdb-card class="z-depth-0">
  <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
      <mdb-icon fad
      icon="list"
      size="lg"
      class="mr-1"></mdb-icon>Líneas
  </mdb-card-header>
  <mdb-card-body>
    <div class="table-responsive">
    <table mdbTable small="true" mdbTableScroll scrollY="true" maxHeight="13rem" stickyHeader= "true" class="excel-table">
      <thead>
        <tr>
          <th scope="col" class="buttons-column"></th>
          <ng-container *ngFor="let header of headers">
            <ng-container *ngIf="header.tooltip; else plainheader">
              <th scope="col" class="text-center" mdbTooltip="{{header.tooltip}}" placement="top">{{header.header}}</th>
            </ng-container>
            <ng-template #plainheader>

              <ng-container *ngIf="header.class; else plain2">

              <th scope="col" [class]="header.class+' text-center'">{{header.header}}</th>
              </ng-container>
              <ng-template #plain2>
                <th scope="col" class="text-center">{{header}}</th></ng-template>
            </ng-template>
           </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let detailForm of detailsForms.controls; let i=index">
          <tr mdbTableCol>
            <td class="buttons-column">
              <ng-container *ngIf="!detailForm.controls.exported.value; else exported">
                <a (click)="deleteDetail(i)">
                  <mdb-icon fad icon="trash" class="red-text ml-1"></mdb-icon>
                </a>
              </ng-container>
              <ng-template #exported>
                <mdb-icon fad icon="share-square" class="grey-text ml-1" mdbTooltip="Exportado" placement="top"></mdb-icon>
              </ng-template>
            </td>
            <ng-container class="test" [formGroup]="detailForm">
              <ng-container *ngFor="let field of field_order; let j= index">
                <td>
                  <ng-container [ngSwitch]="field.type">
                    <ng-container *ngSwitchCase="'checkbox'">
                      <mdb-checkbox [default]="true" id="{{field.control}}{{i}}" formControlName="{{field.control}}" (change)="liquidationStatusChange($event, detailForm)"></mdb-checkbox>
                    </ng-container>
                    <ng-container *ngSwitchCase="'select'">

                      <ng-container *ngIf="field.addKey; else defaultselect">
                        <select class="form-control form-control-sm" id="exampleFormControlSelect1" formControlName="{{field.control}}" (keydown)="addLineaKeyTAB($event, i)"  mdbValidate [validateSuccess]="false" >
                          <option *ngFor="let option of getDropdown(field.dropdown)" class="form-control form-control-sm" [value]="option.id" [disabled]="!option.enabled" [ngClass]="{'mdb-option disabled option-disabled': !option.enabled}">{{ option[field.field_value] }}</option>
                        </select>
                      </ng-container>
                      <ng-template #defaultselect>
                        <select class="form-control form-control-sm" id="exampleFormControlSelect1" formControlName="{{field.control}}"  mdbValidate [validateSuccess]="false" >
                          <option *ngFor="let option of getDropdown(field.dropdown)" class="form-control form-control-sm" [value]="option.id" [disabled]="!option.enabled" [ngClass]="{'mdb-option disabled option-disabled': !option.enabled}">{{ option[field.field_value] }}</option>
                        </select>
                      </ng-template>
                    </ng-container>


                    <ng-container *ngSwitchDefault>
                      <ng-container *ngIf="field.addKey; else defaultinput">
                        <input mdbInput type="{{field.type}}" id="{{field.control}}{{i}}" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" [ngClass]="{'text-right': field.type == 'number'}" formControlName="{{field.control}}" (keydown)="addLineaKeyDOWN($event, i)">
                      </ng-container>
                      <ng-template #defaultinput>
                        <input mdbInput type="{{field.type}}" id="{{field.control}}{{i}}" mdbValidate [validateSuccess]="false"  class="form-control form-control-sm" [ngClass]="{'text-right': field.type == 'number'}" formControlName="{{field.control}}">
                      </ng-template>

                      <mdb-error class="custom-input-error" *ngIf="detailForm.controls[field.control].invalid && (detailForm.controls[field.control].dirty || detailForm.controls[field.control].touched)">
                        <ng-container *ngFor="let error of detailForm.controls[field.control].errors | keyvalue">
                          <ng-container [ngSwitch]="error.key">
                            <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                            <ng-container *ngSwitchCase="'min'">Valor incorrecto</ng-container>
                            <ng-container *ngSwitchDefault>{{field.error_msg}}</ng-container>
                          </ng-container>
                        </ng-container>


                      </mdb-error>

                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
        <!-- <tr>
           <td [colSpan]="headers.length">
            <ng-container *ngIf="master?.typecondition_id == 1 || master?.type_export_id == 1">
                <a (click)="addDetail()" *ngIf="showAddDetail()">
                  <mdb-icon fad
                            icon="plus-circle"
                            size="lg"
                            class="blue-text"></mdb-icon>
                </a>
            </ng-container>
           </td>
        </tr> -->
<!--      </tbody>
   </table>
   <div class="row float-left position-absolute">
    <div class="col-1">
      <ng-container *ngIf="master?.typecondition_id == 1 || master?.type_export_id == 1">
        <a (click)="addDetail()" *ngIf="showAddDetail()">
          <mdb-icon fad
                    icon="plus-circle"
                    size="lg"
                    class="blue-text"></mdb-icon>
        </a>
    </ng-container>
    </div>
   </div>

  </div>
  </mdb-card-body>
</mdb-card>

<div class="row">
  <div class="col-md d-flex justify-content-end">
    <mdb-card class="text-center m-2">
      <mdb-card-header class="blue-grey-text white border-bottom border-primary total-header">Total Neto</mdb-card-header>
      <mdb-card-body class="total-card">
        <!-- <div class="input-group input-group-sm">
          <input type="text" class="form-control text-right" value="{{total_price | number:'1.2-2':'es'}}" readonly>
          <div class="input-group-append">
            <span class="input-group-text">€</span>
          </div>
        </div> -->
 <!--       {{total_price | number:'1.2-2':'es'}} €
      </mdb-card-body>
    </mdb-card>
    <mdb-card class="text-center m-2">
      <mdb-card-header class="blue-grey-text white border-bottom border-primary total-header">Total Impuesto</mdb-card-header>
      <mdb-card-body class="total-card">
        <!-- <div class="input-group input-group-sm">
          <input type="text" class="form-control text-right" value="{{total_taxe | number:'1.2-2':'es'}}" readonly>
          <div class="input-group-append">
            <span class="input-group-text">€</span>
          </div>
        </div> -->
 <!--       {{total_taxe | number:'1.2-2':'es'}} €
      </mdb-card-body>
    </mdb-card>
    <mdb-card class="text-center m-2">
      <mdb-card-header class="blue-grey-text white border-bottom border-primary total-header">Total Importe</mdb-card-header>
      <mdb-card-body class="total-card">
        <!-- <div class="input-group input-group-sm">
          <input type="text" class="form-control text-right" value="{{total_lineas | number:'1.2-2':'es'}}" readonly>
          <div class="input-group-append">
            <span class="input-group-text">€</span>
          </div>
        </div> -->
  <!--      {{total_lineas | number:'1.2-2':'es'}} €
      </mdb-card-body>
    </mdb-card>
  </div>
</div>-->


