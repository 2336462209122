import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { ProviderInterface } from 'src/app/shared/interfaces/data/provider-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-new-scale',
  templateUrl: './new-scale.component.html',
  styleUrls: ['./new-scale.component.scss']
})
export class NewScaleComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  public loading: boolean = false;
  public formScale: FormGroup;

  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;

  filteredCompanyOptions: Observable<any[]>;

  public companies: Array<ProviderInterface>;
  public subcompanies: Array<ProviderInterface>;

  public dropdowndata: DropdownsInterface;


  public guild_entity = this._entity.entities.guild;

  modalRef: MDBModalRef | null = null;
  constructor(private modalService: MDBModalService,private _tabs: TabService, private _dropdowns: DropdownService,private _api: ApirequestService, private _buttons: ActionButtonsService, private _notification: NotificationService, private fb: FormBuilder, private _entity: EntityService) {
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.createForm();
    this.getDropdowns();
  }

  getDropdowns(){
    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          let data = this.utils.objectDeepCopy(response.response.dropdown);
          data.guild = data.guild.filter(guild => guild.enabled == true);
          this.companies =  data.company_with_subcompanies;
          data.taxe = data.taxe.filter(taxe => taxe.enabled == true);

          let default_taxe = data.taxe.find(taxe => taxe.default);
          if(default_taxe) {
            this.formScale.controls.taxe_id.setValue(default_taxe.id)
          } else {
            if(data.taxe.length  > 0) {
              this.formScale.controls.taxe_id.setValue(data.taxe[0].id)
            }
          }


          this.dropdowndata = data;


          this.subscriptions.push(
            this.formScale.valueChanges.subscribe(
              () => {
                this.tab.modifiedData = true;
              }
            )
          );

          this.subscriptions.push(
            this.formScale.controls.company_id.valueChanges.subscribe(
              value => {
                this.formScale.controls.subcompany_id.reset();

                if(this.formScale.controls.company_id.enabled) {
                  this.subcompanies = this.companies.find(c => c.id === value).subcompanies;
                  //this.subcompanies = this.all_subcompanies.filter(sc => sc.provider_id === value);
                }
              }
            )
          );

          /* this.filteredCompanyOptions = this.formScale.controls.company_name.valueChanges.pipe(
            startWith(''),
            map((value: string) => this.filterCompanies(value))
          ); */

          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  createForm(){
    this.formScale = this.fb.group({
      company_id:[null, Validators.required],
      subcompany_id:[null],
      guild_id:[null, Validators.required],
      province_id:[null, Validators.required],
      code:[null, Validators.required],
      internal_code:[null],
      description:[null, Validators.required],
      taxe_id:[null, Validators.required],
      price:[0, [Validators.required, Validators.min(0)]],
      cost:[0, [Validators.required, Validators.min(0)]],

    })

  }

  /* filterCompanies(value: string): any[] {
    this.subcompanies = [];
    if(this.formScale.controls.subcompany_id.dirty) {
      this.formScale.controls.subcompany_id.reset();
    }
    const filterValue = value?.toLowerCase();
    return (value=== '') ? this.dropdowndata.company : this.dropdowndata.company.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  companySelected(company_id:number){
    this.formScale.controls.subcompany_id.reset();
    this.loadSubcompanies(company_id)
  } */


  /* loadSubcompanies(company_id: number) {
    console.log(company_id)
   // this.formScale.controls.company_id.updateValueAndValidity();
    this.subcompanies =  this.dropdowndata.subcompany.filter(sc => sc.provider_id == company_id)
  } */




  onSubmit() {
    this.formScale.markAllAsTouched();
    if(this.formScale.valid) {
      this._buttons.setLoading(true);

      let form_values = {...this.formScale.value};

      let params = {
        scale: {
          provider_id: form_values.company_id,
          subprovider_id: form_values.subcompany_id,
          province_id: form_values.province_id,
          guild_id: form_values.guild_id,
          code: form_values.code,
          internal_code: form_values.internal_code,
          description: form_values.description,
          price: form_values.price,
          taxe_id: form_values.taxe_id,
          cost: form_values.cost,
        }
      };

      console.log(params)

      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {
            let scale = response.response.scale
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
            this.modalRef = this.modalService.show(OpenRegisterComponent, {
                    keyboard: false,
                    ignoreBackdropClick: true,
                    class: 'modal-dialog',
                    data: {
                      heading: 'Registro Creado',
                      text: 'Registro creado correctamente, ¿desea acceder al registro?',
                      register: scale,
                      form_entity: this.data.entity,
                    }
                  })

            this._tabs.removeTabByTabId(this.tab.tabId);
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      );



    }
  }

}
