<div class="modal-header">
  <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button> -->
  <h5 class="modal-title w-100 d-flex justify-content-center" id="myModalLabel">{{heading}}</h5>
</div>
<div class="modal-body">
  {{text}}
</div>
<div class="modal-footer text-center">
  <button mdbBtn type="button" size="sm" color="default" mdbWavesEffect (click)="open(true)">
    Sí
  </button>
  <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="open(false)">
    No
  </button>
</div>
