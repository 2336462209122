

export class FirmFilters {



  public staticFilters;
  public optionalFilters;

  constructor(private entities){

    this.staticFilters = [
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'enabled',
          type: 'select',
          label: 'Estado',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todas'
            },
            {
              value:  0,
              text: 'Inactiva'
            },
            {
              value:  1,
              text: 'Activa'
            }
          ]
        }
      },
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'name',
          type: 'text',
          label: 'Nombre',
          width: 2
        }
      },
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'identification_document',
          type: 'text',
          label: 'CIF',
          width: 2
        }
      },
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'email',
          type: 'text',
          label: 'Email',
          width: 2
        }
      },
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'phone',
          type: 'text',
          label: 'Teléfono',
          width: 2
        }
      },
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'mobile',
          type: 'text',
          label: 'Móvil',
          width: 2
        }
      },
      {
        entity: this.entities.firm.entity,
        field: {
          name: 'url_web',
          type: 'text',
          label: 'Web',
          width: 2
        }
      },
    ]

  }

}

