import { Component, OnInit } from '@angular/core';
import { DynamicListComponent } from '../../components/dynamic-list/dynamic-list.component';

@Component({
  selector: 'app-mail-accounts',
  templateUrl: './mail-accounts.component.html',
  styleUrls: ['./mail-accounts.component.scss']
})
export class MailAccountsComponent extends DynamicListComponent implements OnInit {

  public activeFirm: number;

  public selectedRow = -1;

  public linking = false;

  ngOnInit(): void {
    if(this.data.listParams.filters.dropdowns) {
      this._dropdown.getDropdownsAPI(this.data.listParams.filters.dropdowns);
    }
    this.linking = false;
    this.filters = {[this.data.entity] :{}};

    this.loadData();
  }

  selectMail(item, position) {
    this.selectedRow = position;
    this.register = item;
  }

  onContextMenuUser(event: MouseEvent, registro, pos) {

    event.preventDefault();

    this.selectedRow = pos;

    this.register = registro;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': this.register };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  linkAccount(account) {
    this.linking = true;
    const params = {};
    params[this.data.entity] = {};
    params[this.data.entity]['type'] = account.typemailaccount_id;
    params[this.data.entity]['enabled'] = 1;
    params[this.data.entity]['token'] = null;

    let url = '';
    let model = '';
    switch (account.typemailaccount_id) {
      case 1:
        url = this.data.saveEndpoint+'/gmail/api/url';
        model = 'gmailAPi'
        break;
      case 2:
        url = this.data.saveEndpoint+'/outlook/api/url';
        model = 'outlookAPi'
        break;

    }
    if(url !== '' && model !== '') {
      this._api.get(url).subscribe(
        resp => {
          console.log(resp)
          let url = resp['response'][model]['url'];
          window.location.href = url;

        },
        error => {
          this.linking = false;
        }
      )
    }

}
}
