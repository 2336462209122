import { CommunicationsComponent } from "src/app/main/sections/communications/communications.component"
import { Tab } from "src/app/main/tab-models/tab.model"

export class ComunicationsModel {
  tabData = {
    entity: 'communications'
  }

  getTab(): Tab {
    return new Tab(
      CommunicationsComponent,
      "<i class='fas fa-envelope mr-1'></i>Panel Comunicaciones",
      {
          buttonNew: false,
          buttonDelete: false
      },
        this.tabData
    )
}
}
