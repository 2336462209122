import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbCheckboxChange, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { forkJoin, of, Subscription, throwError } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { matchValueToArray } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { MailAccountInterface } from 'src/app/shared/interfaces/data/mail-account-interface';
import { UserInterface } from 'src/app/shared/interfaces/data/user-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { UnblockModalComponent } from '../../components/dynamic-modals/unblock-modal/unblock-modal.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { FirmsService } from '../../services/firms.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { UserPermissionsService } from '../../services/user-permissions.service';
import { UserService } from '../../services/user.service';
import { Tab } from '../../tab-models/tab.model';
import { catchError } from 'rxjs/operators';
import { DropdownService } from '../../services/dropdown.service';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;
  public formFirmRoles: FormArray = new FormArray([]);
  public formPermissions: FormGroup;
  public user: UserInterface;

  public available_firms: Array<any>;
  public user_firms: Array<any> = [];
  public user_permissions: Array<any> = [];

  public dropdowndata: DropdownsInterface;

  public loading: boolean = false;

  public formUser: FormGroup;

  public id: number;
  private subscriptions: Array<Subscription> = [];
  private firm_form_subscriptions: Array<any> = [];
  private mailaccount_form_subscriptions: Array<any> = [];


  public roles = [
    {value: 2, label: 'Gerente'},
    {value: 3, label: 'Tramitador'}
  ]

  public readonly app_permissions = [
    {id: 1, label: 'Ver Usuarios Web', permission:'list_users'},
    {id: 2, label: 'Crear Usuarios Web', permission:'modify_users'},
    {id: 125, label: 'Ver Empresas', permission:'list_firms'},
    {id: 126, label: 'Modificar Empresas', permission:'modify_firms'},
    {id: 127, label: 'Eliminar Empresas', permission:'delete_firms'},
    {id: 141, label: 'Ver Usuarios de Empresas', permission:'list_firm_users'},
    {id: 142, label: 'Modificar Usuarios de Empresas', permission:'modify_firm_users'},
  ];

  public available_accounts: Array<any> = [];
  public linked_accounts: Array<any> = [];

  public selected_firm: any;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;


  modalRefUnblock: MDBModalRef | null = null;

  public user_role: number;

  constructor(private _api: ApirequestService, private _lock: LockService, private _user: UserService,private _userpermissions: UserPermissionsService, private modalService: MDBModalService, private _firms: FirmsService, private _apifunctions: ApiFunctions, private fb: FormBuilder, private _buttons: ActionButtonsService, private _notification: NotificationService, private _dropdowns: DropdownService) {

    this.user_role = this._user.getUserData().role_id;

    this.formPermissions = this.fb.group({
      list_users:[{value: false, disabled: false }],
      modify_users:[{value: false, disabled: false }],
      list_firms:[{value: false, disabled: false }],
      modify_firms:[{value: false, disabled: false }],
      delete_firms:[{value: false, disabled: false }],
      list_firm_users:[{value: false, disabled: false }],
      modify_firm_users:[{value: false, disabled: false }],
    })

  }


  ngOnDestroy(): void {
    this.unsubscribeAll()
    this._api.unlockExit(this.lockdata)
  }
  unsubscribeAll(){
    this.subscriptions.forEach(e => e.unsubscribe());
    this.firm_form_subscriptions.forEach(f => f.subs.forEach((s: Subscription) => s.unsubscribe()))
    this.mailaccount_form_subscriptions.forEach(f => f.subs.forEach((s: Subscription) => s.unsubscribe()))
  }

  ngOnInit(): void {
    this.id = this.data.id;
    this.formUser = this.formFunctions.getForm(this.data.entity);
    this.getData();
  }

  getData(){
    this.loading = true;

    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          console.log(error)
          this.loading = false;
        }
      )
    );
  }

  private setDropdowns() {

    let dropdowns = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    dropdowns.user = dropdowns.user.filter(u => u.email !== this.user.email);
    this.dropdowndata = dropdowns;


    this.available_firms = [...this.dropdowndata.firm.filter(f => !this.user.roleuserfirm.find(f1 => f.id == f1.firm_id)).map(f => {return {id: f.id, name:f.name}})];

    this.setRoleFirms();
    this.setAccounts()
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)

    this.user = apiresponse.user;


    this.tab.tabData.headerData = this.user.name;

    if (this.user?.surname) {
      this.tab.tabData.headerData += ' ' + this.user.surname;
    }

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formUser = undefined;
          setTimeout(() => {
            this.formUser = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formUser, this.user);
      this.linked_accounts = this.user.mailaccounts.map(acc => {
        acc.default ? acc.default = true : acc.default = false;
        return acc
      });
      this.formUser.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formUser, this.user);
      this.setRoleFirms();
      this.linked_accounts = this.user.mailaccounts.map(acc => {
        acc.default ? acc.default = true : acc.default = false;
        return acc
      });

      if(this.disableChanges()){

        this.formUser.disable()
      }
      this.subscriptions.push(
        this.formUser.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }

  hasAditionalPermissions(firm_id:number): boolean{
    let hasPermissions = false;

    let firm = this.user_permissions.find(p => p.firm_id == firm_id);
    if(firm){
      if(firm.permissions.filter(p=> p.is_enabled).length > 0) {
        hasPermissions = true;
      }
    }

    return hasPermissions;
  }

  savePermissions(){
    Object.keys(this.formPermissions.controls).forEach(
      key => {

        let selected_firm_permissions = this.user_permissions.find(up => up.firm_id == this.selected_firm.firm_id);

        let control = this.formPermissions.controls[key];
        if(control.dirty) {
          if(selected_firm_permissions) {
            //busca si la empresa tenia el permiso
            let permission = selected_firm_permissions.permissions.find(p => p.name == key);
            if(permission) {
              permission.is_enabled = control.value;
            } else {
              selected_firm_permissions.permissions.push({name: key,is_enabled : control.value })
            }
          } else {
            this.user_permissions.push({
              firm_id: this.selected_firm.firm_id,
              permissions: [{
                name: key,
                is_enabled: control.value
              }]
            });
          }
        }
      }
    )

    this.selected_firm = null;
    this.tab.modifiedData = true;

  }

  private setRoleFirms(){
    this.firm_form_subscriptions.forEach(f => f.subs.forEach((s: Subscription) => s.unsubscribe()))
    this.user_permissions=[];
    this.user_firms=[]

    //PERMISOS
    this.user.permissionuserfirm.forEach(
      data => {

        let firm_permission = this.user_permissions.find(up => up.firm_id == data.firm_id);

        //si ya hay entrada de firm, se añade el permiso
        if(firm_permission) {
          firm_permission.permissions.push({
            name: data.permission.name,
            is_enabled: data.is_enabled
          })
        } else {
          //se crea la entrada para el firm_id
          this.user_permissions.push({
            firm_id: data.firm_id,
            permissions: [{
              name: data.permission.name,
              is_enabled: data.is_enabled
            }]
          });
        }
      }
    )

    //ROLES
    this.user.roleuserfirm.forEach(
      data => {

        let control_role = new FormControl({value: data.role_id, disabled: this.disableRoleChanges()});
        let control_default = new FormControl({value: data.default, disabled: this.disableRoleChanges()});

        if(this.user_role == 3 && data.role_id <3) {
          control_role.disable()
        }

        let firm_subscription = {
          firm_id: data.firm_id,
          subs:[]
        }
        firm_subscription.subs.push(
          control_role.valueChanges.subscribe(value => {
            if(value == 2) {
              if(this.selected_firm?.firm_id == data.firm_id) {
                this.selected_firm = null;
                this.formPermissions.reset();
              }
              this.user_permissions = this.user_permissions.filter(up => up.firm_id != data.firm_id)

            }
            this.tab.modifiedData = true
          })
        );
        firm_subscription.subs.push(
          control_default.valueChanges.subscribe(value => {
            if(value){
              let last_default = this.user_firms.find(f => (f.default_form.value && f.firm_id != data.firm_id));
              if(last_default) {
                last_default.default_form.setValue(false);
              }
            }
            this.tab.modifiedData = true;
          })
        );
        this.firm_form_subscriptions.push(firm_subscription)

        this.user_firms.push({
          firm_id: data.firm_id,
          role_form: control_role,
          firm_name: data.firm.name,
          default_form: control_default,
          has_permissions: this.hasAditionalPermissions(data.firm_id)
        });
      }
    )
  }

  addFirm(firm){

    let control_role = new FormControl({value: 3, disabled: this.disableRoleChanges()});
        let control_default = new FormControl({value: false, disabled: this.disableRoleChanges()});

        let firm_subscription = {
          firm_id: firm.id,
          subs:[]
        }

        firm_subscription.subs.push(
          control_role.valueChanges.subscribe(value => {
            if(value == 2) {
              if(this.selected_firm?.firm_id == firm.id) {
                this.selected_firm = null;
                this.formPermissions.reset();
              }
              this.user_permissions = this.user_permissions.filter(up => up.firm_id != firm.id)

            }
            this.tab.modifiedData = true
          })
        );
        firm_subscription.subs.push(
          control_default.valueChanges.subscribe(value => {
            if(value){
              let last_default = this.user_firms.find(f => (f.default_form.value && f.firm_id != firm.id));
              if(last_default) {
                last_default.default_form.setValue(false);
              }
            }
          })
        );
        this.firm_form_subscriptions.push(firm_subscription)

        this.user_firms.push({
          firm_id: firm.id,
          role_form: control_role,
          firm_name: firm.name,
          default_form: control_default
        });

        this.available_firms = this.available_firms.filter(f => f.id != firm.id);
        this.tab.modifiedData = true;
  }

  removeFirm(firm) {

    this.firm_form_subscriptions.find(f => f.firm_id == firm.firm_id).subs.forEach((s: Subscription) => s.unsubscribe())
    this.firm_form_subscriptions = this.firm_form_subscriptions.filter(f => f.firm_id != firm.firm_id);


    this.user_firms = this.user_firms.filter(f => f.firm_id != firm.firm_id);
    this.available_firms.push({id:firm.firm_id, name: firm.firm_name});
    if(firm.default_form.getRawValue() && this.user_firms.length > 0) {
      this.user_firms[0].default_form.setValue(true);
    }

    if(this.selected_firm) {
      this.selected_firm = null;
      this.formPermissions.reset();
    }

    this.user_permissions = this.user_permissions.filter(up => up.firm_id != firm.firm_id);
    this.tab.modifiedData = true

  }

  loadAdditionalPermissions(firm: any) {
    if(!this.disableRoleChanges()) {
      this.formPermissions.reset();

      if(firm.role_form.getRawValue() == 3) {
        if(this.selected_firm?.firm_id != firm.firm_id) {
          this.selected_firm = firm;

          let permission_firm = this.user_permissions.find(up => up.firm_id == firm.firm_id);

          if(permission_firm) {
            permission_firm.permissions.forEach(
              p => {
                console.log(p.name)
                this.formPermissions.controls[p.name].setValue(p.is_enabled)
              }
            )
          }
        } else {
          this.selected_firm = null;
        }

      } else {
        this.selected_firm = null;
      }
    }
  }



  /* showTramitFields() {
    let show = false;
    if (this.formFirmRoles.controls.length > 0) {

      for (let index = 0; index < this.formFirmRoles.controls.length; index++) {
        let id_role = this.formFirmRoles.controls[index]['controls'].role['controls'].id_role.value;
        if (id_role === 2 || id_role === 3) {
          show = true;
          break;
        }

      }
    }

    this.show_tramit_fields = show;
   // return show;
  } */

  disableChanges(): boolean {
    let readonly = false;
    if(!this._userpermissions.checkPermission('modify_users') || !this.lockdata?.unblock) {
      readonly = true;
    } else {

    }

    return readonly;
  }

  disableRoleChanges():boolean {
    let readonly = false;

    if(this.disableChanges() || !this._userpermissions.checkPermission('modify_firm_users')) {
      readonly = true;
    }

    return readonly;
  }

  relockField(field: string) {

    this.formUser.controls[field].clearValidators();

    switch (field) {
      case 'email':
        this.formUser.controls[field].setValue(this.user.email)
        break;
      case 'password':
        this.formUser.controls[field].reset()
        break;
    }
    this.formUser.controls[field].disable();
    this.formUser.controls[field].updateValueAndValidity();
  }

  unblockModal(field: string){
    let label: string
    let validators =[];
    switch (field) {
      case 'email':
        label = '¿Desea desbloquear el campo Email del usuario?';
        validators = [Validators.required, Validators.email,matchValueToArray(this.dropdowndata.user.map(user => {return user.email}))];
        break;
      case 'password':

        label = '¿Desea cambiar la contraseña del usuario?';
        validators = [Validators.required];
        break;
    }
    this.modalRefUnblock = this.modalService.show(UnblockModalComponent, {
      class: 'modal-sm',
      data: {
        text: label
      }
    });

    this.subscriptions.push(
      this.modalRefUnblock.content.unblockConfirm.subscribe(
          confirm => {
              if(confirm === true) {
                this.formUser.controls[field].enable();
                this.formUser.controls[field].addValidators(validators);
              } else {
                this.formUser.controls[field].clearValidators();
                this.formUser.controls[field].disable();
              }
              this.formUser.controls[field].updateValueAndValidity();
          }
        )
   )
  }


  setAccounts(){
    this.available_accounts = this.dropdowndata?.mailaccount.filter(
      account => {
        if(!this.linked_accounts.find(a => a.id == account.id)) {
          return true
        }
      }
    ).map(
      account => {
        account.default = false;
        return account;
      })

      console.log(this.dropdowndata)
      console.log(this.available_accounts)
  }

  addAccount(account: MailAccountInterface) {
    this.linked_accounts.push(account)
    this.setAccounts();
    this.tab.modifiedData = true;
  }
  removeAccount(account: MailAccountInterface){
    this.linked_accounts.splice(this.linked_accounts.findIndex(u=> u.id == account.id), 1);
    this.tab.modifiedData = true;
    this.setAccounts();
  }

  onChangeDefault(event: MdbCheckboxChange, account: MailAccountInterface) {
    this.linked_accounts.forEach(ma => ma.default = false);
    account.default = event.checked;
    this.tab.modifiedData = true;
  }

  onSubmit(){
    this.formUser.markAllAsTouched()

    if(this.formUser.valid) {
      this._buttons.setLoading(true);

      console.log(this.formUser.value)
      const params = {};
      this.formFunctions.fillFormParams(this.formUser,this.data.entity,params)

      let enabledValue;

      if(params[this.data.entity].is_enabled)
      {
        enabledValue = params[this.data.entity].is_enabled;
      }
      else
      {
        ///Si no se ha modificado el valor del check asignamos por defecto el valor que tiene el modelo
        params[this.data.entity].is_enabled = this.user.is_enabled;
        enabledValue = this.user.is_enabled;
      }
      //params[this.data.entity].is_enabled = true;

      ///SI activamos o desactivamos al usuario, usaremos esta variable para actualizar tanto a activado como no en la tabla roleuserfirm de las empresas vinculadas

      params['roleuserfirm']=[];
      params['permissionuserfirm']=[];

      if(!this.disableRoleChanges()) {
        this.user_firms.forEach(
          data => {
            params['roleuserfirm'].push({
              role_id: +data.role_form.getRawValue(),
              firm_id: data.firm_id,
              is_enabled: enabledValue,
              default: data.default_form.getRawValue(),
            })
          }
        );

        if(params['roleuserfirm'].length >0 && !params['roleuserfirm'].find(r => r.default)) {
          params['roleuserfirm'][0].default = true;

        }

        //console.log(this.user_firms);

        this.user_permissions.forEach(
          data => {

            const permission_firm = {
              id_firm: data.firm_id,
              permissions: data.permissions.map(
                p =>{
                  return {
                    id_permission: this.app_permissions.find(ap => ap.permission == p.name).id,
                    is_enabled: p.is_enabled
                  }
                }
              )
            }

            params['permissionuserfirm'].push(permission_firm)

          }
        )
      }



          //cuentas de correo
        params['mail_accounts'] = this.linked_accounts.map(it => {
          return {
            account_id: it.id,
            default: it.default
          }
        });


      console.log(params)
      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
          response => {
            this.tab.modifiedData = false;
            this._buttons.reloadListTab(this.data.saveEndpoint);
            this._buttons.setLoading(false);
            this._notification.notificacionOK(response.response[this.data.entity]);
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      );

    }
  }


}
