<app-loader *ngIf="sending"></app-loader>
<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h6 class="modal-title w-100 d-flex justify-content-start small" id="myModalLabel">
    <mdb-icon fad icon="pencil" class="mr-1"></mdb-icon>
    Escribir: {{header_subject}}
  </h6>
</div>
<div class="modal-body">
  <form [formGroup]="formSendMail">
    <div class="row my-1">
      <div class="col-lg-3 small">
          <div class="row">
            <div class="col">
              <span class="align-middle text-muted">Remitente: </span>
              <select class="browser-default custom-select sender-select" formControlName="from">
                <option *ngFor="let account of accounts" [value]="account.id">{{ account.mail }}</option>
              </select>
            </div>
          </div>
      </div>
      <div class="col-6">
        <a class="border border-light rounded mx-1 p-1 small" (click)="setCC()">
          Cc
        </a>
        <a class="border border-light rounded mx-1 p-1 small" (click)="setCCO()">
          Cco
        </a>
        <div class="file-field custom-file-field">
          <a class="border border-light rounded mx-1 p-1 small">
            <mdb-icon fad icon="paperclip" class="mr-1"></mdb-icon>
            <span>Adjuntar</span>
            <input
              type="file"
              title="Seleccionar"
              #uploader
              mdbFileSelect
              (uploadOutput)="onUploadOutput($event)"
              [uploadInput]="uploadInput"
              multiple="multiple"
            />
          </a>
      </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col small">
          <div class="row">
            <div class="col">
              <span class="text-muted"><mdb-icon fad icon="info-circle" size="lg" mdbTooltip="Puede introducir varias direcciones separadas por ;" placement="top"></mdb-icon> Para: </span>
              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="form1" placeholder="" formControlName="to" class="form-control form-control-sm send-form-control">
              <!-- <mdb-error *ngIf="formSendMail.controls.to.invalid && (formSendMail.controls.to.dirty || formSendMail.controls.to.touched)">
                <ng-container *ngFor="let error of formSendMail.controls.to.errors | keyvalue">
                  <ng-container [ngSwitch]="error.key">
                    <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                    <ng-container *ngSwitchCase="'email_error'">Formato incorrecto</ng-container>
                  </ng-container>
                </ng-container>
              </mdb-error> -->
            </div>
          </div>
      </div>
    </div>
    <div class="row my-1" *ngIf="formSendMail.controls.cc">
      <div class="col small">
          <div class="row">
            <div class="col">
              <span class="text-muted">
                <mdb-icon fad icon="info-circle" size="lg" mdbTooltip="Puede introducir varias direcciones separadas por ;" placement="top"></mdb-icon> CC: </span>
              <input mdbInput mdbValidate [validateSuccess]="false" formControlName="cc" type="text" id="form2" placeholder="" class="form-control form-control-sm send-form-control">
            </div>
          </div>
      </div>
    </div>
    <div class="row my-1"  *ngIf="formSendMail.controls.cco">
      <div class="col small">
          <div class="row">
            <div class="col">
              <span class="text-muted"><mdb-icon fad icon="info-circle" size="lg" mdbTooltip="Puede introducir varias direcciones separadas por ;" placement="top"></mdb-icon> CCo: </span>
              <input mdbInput mdbValidate [validateSuccess]="false" formControlName="cco"  type="text" id="form3" placeholder="" class="form-control form-control-sm send-form-control">
            </div>
          </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col small">
          <div class="row">
            <div class="col">
              <span class="text-muted">Asunto: </span><input mdbInput type="text" id="form4"  formControlName="subject" placeholder="" class="form-control form-control-sm send-form-control">
            </div>
          </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col small">
        <textarea mdbInput type="text" placeholder="Mensaje"  formControlName="body" id="form107" class="md-textarea form-control"
        rows="15"></textarea>
      </div>
    </div>

    <div class="row mt-2 attach-files" *ngIf="files?.length > 0">
      <div class="col-12">
        <div class="row">
          <div class="col-1">
            <a class="small" (click)="test.toggle()" mdbWavesEffect>
              <mdb-icon fad icon="paperclip mr-1"></mdb-icon>
              {{files.length}} Adjunto(s)
            </a>
          </div>
        </div>
      </div>
      <div class="col-12" mdbCollapse #test="bs-collapse"[isCollapsed]="false">
        <div class="row mt-2">
          <ng-container  *ngFor="let file of files; let i = index">
            <div class="col-2">
              <a class="small" (click)="removeAttach(i)">
                <mdb-icon fad icon="file mr-1"></mdb-icon>{{file.name}}
              </a>
            </div>
          </ng-container>
        </div>
      </div>





      <!-- <ng-container *ngFor="let attach of files">
        <div class="col-2">
          <a class="small" (click)="removeAttach(attach)">
            <mdb-icon fad icon="file mr-1"></mdb-icon>{{ attach.name }}
          </a>
        </div>
      </ng-container> -->
    </div>
    </form>
</div>
<div class="modal-footer">
  <button type="submit" mdbBtn color="primary" class="z-depth-1a" [disabled]="formSendMail.invalid" (click)="sendMail()">Enviar</button>
</div>
