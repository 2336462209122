import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { ClaimInterface } from 'src/app/shared/interfaces/data/claim-interface';

@Component({
  selector: 'app-chage-claim-provider',
  templateUrl: './chage-claim-provider.component.html',
  styleUrls: ['./chage-claim-provider.component.scss']
})
export class ChageClaimProviderComponent implements OnInit {
  policies: Array<any> = null;
  repairmen: Array<any> = null;
  administrators: Array<any> = null;
  addresses: Array<any> = null;
  provider_types: Array<any> = [{id: -1, name: 'Particular'}];
  claim_data: ClaimInterface = null;

  formTypeProvider: FormGroup;

  private newProviderData = new Subject<any>()

  constructor(public modalRef: MDBModalRef, private _apifunctions: ApiFunctions, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(){
    this.formTypeProvider = this.fb.group({
      typeprovider:[-1],
      clientaddress_id:[{value: null, disabled: false}, []],
      provider_id:[null, []],
      subprovider_id:[null, []],
      policy_id:[{value: null, disabled: false}],
    });
    console.log(this.claim_data)
    if(this.claim_data.provider) {
      this.formTypeProvider.controls.typeprovider.setValue(this.claim_data.provider.typeprovider_id);

      if(this.claim_data.policy) {
        this.formTypeProvider.controls.policy_id.setValue(this.claim_data.policy.id)
      } else {
        this.formTypeProvider.controls.provider_id.setValue(this.claim_data.provider.id)
        this.formTypeProvider.controls.clientaddress_id.setValue(this.claim_data.clientaddress.id)
      }
    } else {
      this.formTypeProvider.controls.clientaddress_id.setValue(this.claim_data.clientaddress.id)
    }



  }

  get selected_typeprovider(): number {
    return this.formTypeProvider?.controls.typeprovider.value
  }


  confirm(){
    let data:{[key:string]:any};
    const selected_address = this.addresses.find(p => p.id == this.formTypeProvider.controls.clientaddress_id.value)
    const selected_policy = this.policies.find(p => p.id == this.formTypeProvider.controls.policy_id.value)

    switch (this.selected_typeprovider) {
      case -1:
        if(selected_address) {
          data = {
            typeprovider: this.formTypeProvider.controls.typeprovider.value,
            clientaddress: selected_address,
            provider: null,
            policy: null,
          }
        }
        break;
      case 1:
        if(selected_policy){
          data = {
            typeprovider: this.formTypeProvider.controls.typeprovider.value,
            policy: selected_policy,
            clientaddress: null
          }
        }
        break;
      case 2:
        const selected_repairman = this.repairmen.find(r => r.id == this.formTypeProvider.controls.provider_id.value)
        if(selected_address && selected_repairman) {
          data = {
            typeprovider: this.formTypeProvider.controls.typeprovider.value,
            clientaddress: selected_address,
            provider: selected_repairman,
            policy: null
          }
        }

        break;
      case 3:
        const selected_administrator = this.administrators.find(r => r.id == this.formTypeProvider.controls.provider_id.value)
        if(selected_address && selected_administrator) {
          data = {
            typeprovider: this.formTypeProvider.controls.typeprovider.value,
            clientaddress: selected_address,
            provider: selected_administrator,
            policy: null
          }
        }

        break;
    }

    this.newProviderData.next(data);
    this.modalRef.hide();
  }


}
