import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { ApirequestService } from '../../api/request/apirequest.service';
import { SessionModalComponent } from '../components/dynamic-modals/session-modal/session-modal.component';
import { DropdownService } from './dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  modalRef: MDBModalRef;

  private defaultInsertMsg = 'Se ha creado el registro correctamente.';
  private defaultUpdateMsg = 'Se ha actualizado el registro correctamente.';

  constructor(private toast: ToastService, private modalService: MDBModalService, private router: Router , private _api: ApirequestService, private _dropdown: DropdownService) { }

  getInsertMsg(): string {
    return this.defaultInsertMsg;
  }

  getUpdateMsg(): string {
    return this.defaultUpdateMsg;
  }

  notificacionOK(msg: string, title?:string): void {
    let t = '';

    if(title) {
      t= title;
    }

    this.toast.success(msg, title,
    {
      closeButton: true,
      tapToDismiss: false,
      timeOut: 3000,
      toastClass: 'opacidadWarning',
      positionClass: 'md-toast-bottom-left'
    })
  }

  notificacionError(error: HttpErrorResponse): void {
    let msg = 'error';
    if(error.error.response.error.message) {
      msg = error.error.response.error.message
    } else if(error.error.response.error) {
      msg = error.error.response.error;
    }
    this.toast.warning(msg, 'Error',
    {
      /* actionButton: 'Action button',
      actionButtonClass: 'text-red my-5', */
      closeButton: true,
      tapToDismiss: false,
      timeOut: 5000,
      progressBar: true,
      toastClass: 'opacidadWarning',
      positionClass: 'md-toast-bottom-left'
    })
  }

  notificacionErrorManual(msg: string): void {
    this.toast.warning(msg, 'Error',
    {
      /* actionButton: 'Action button',
      actionButtonClass: 'text-red my-5', */
      closeButton: true,
      tapToDismiss: false,
      timeOut: 0,
      progressBar: true,
      toastClass: 'opacidadWarning',
      positionClass: 'md-toast-bottom-left'
    })
  }

  modalDinamico(unauthorized?:boolean){
	  if(!this.modalRef) {

      let modal_data = {
        heading: 'Sesión Caducada',
        content: {
          description: 'La sesión ha caducado, deberá volver a introducir sus datos de acceso',
          button: 'Cerrar'
        }
      }

      if(unauthorized) {
        modal_data = {
          heading: 'Privilegios insuficientes',
          content: {
            description: 'El usuario no tiene permiso para realizar la acción',
            button: 'Cerrar'
          }
        }
      }

		  this.modalRef=this.modalService.show(SessionModalComponent, {
      backdrop: true,
      keyboard: false,
      show: false,
      ignoreBackdropClick: true,
      animated: true,
      data: modal_data
    })
	  }

    this.modalService.close.subscribe(()=> {
      this.modalRef=null;
      if(!unauthorized) {
        this._dropdown.resetDropdowns();
        this._api.logOut();
        //this.router.navigateByUrl('/login')
      }

    });
  }

  clearAlerts(): void{
    this.toast.clear();
  }
}
