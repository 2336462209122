import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { MdbCheckboxChange } from 'ng-uikit-pro-standard';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { EmployeeInterface } from 'src/app/shared/interfaces/data/employee-interface';
import { LinesInterface } from 'src/app/shared/interfaces/data/lines-interface';
import { TaxeInterface } from 'src/app/shared/interfaces/data/taxe-interface';
import { DetailFormInterface } from 'src/app/shared/interfaces/formgroups/detail-form-interface';

@Component({
  selector: 'app-detail-lines',
  templateUrl: './detail-lines.component.html',
  styleUrls: ['./detail-lines.component.scss']
})
export class DetailLinesComponent implements OnInit {
  @Input() master: any
  @Input() taxes: Array<TaxeInterface>;
  @Input() employees: Array<EmployeeInterface>;
  @Input() headers: Array<any>;
  @Input() field_order: Array<any>;
  @Input() details: Array<LinesInterface>;
  @Input() readonly: boolean;
  @Input() maxHeight: any = '15rem';

  @Output() detailsChanged = new EventEmitter<boolean>();

  private changed: boolean = false;

  public total_lineas = 0;
  public total_price = 0;
  public total_taxe = 0;
  public total_cost = 0;



  public detailsForms: FormArray = new FormArray([]);

  //public deletedDetails: Array<FormGroup> = [];
  /**
   * lineas eliminadas
   */
 public delete_lines: Array<any>=[];

  private formFunctions = new FormFunctions;

  constructor(private formbuilder: FormBuilder) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.delete_lines = [];

    //if(!changes.employees?.previousValue){
      if(this.details && this.taxes) {

      this.total_lineas = 0;
      this.total_price = 0;
      this.total_taxe = 0;
      this.total_cost = 0;
      if(this.details.length > 0) {
        this.detailsForms.clear();
      }
      this.processDetails();
    }


  }

  ngOnInit(): void {
  }


  getDropdown(dropdown: string):Array<any> {
    return this[dropdown];
  }

  liquidationStatusChange(event: MdbCheckboxChange, form: FormGroup) {
    form.markAsTouched();
  }

  emitChanges(){
    //if(!this.changed) {
      this.changed = true;
      this.detailsChanged.emit(this.changed);
    //}
  }

  public getDetailById(id: number): LinesInterface {
    return this.details.find(d => d.id == id);
  }

  private fillLineForm(line, readonly: boolean){
    let form = this.formbuilder.group<DetailFormInterface>({
      readonly: readonly,
      id:[{value: line.id, disabled: true}]
    });


    for (let j = 0; j < this.field_order.length; j++) {
      if((this.field_order[j].control === 'typeliquidation_id') || (this.field_order[j].control === 'type_export_line_id')) {
        let liq = true;
        if(line[this.field_order[j].control] === 1) {
          liq = false;
        }
        form.addControl(this.field_order[j].control,new FormControl({value: liq, disabled: readonly}))
      } else {
        let validators = [];
        if(this.field_order[j].validate) {
          validators = this.field_order[j].validators
        }

        form.addControl(this.field_order[j].control,new FormControl({value: line[this.field_order[j].control], disabled: readonly}, validators))

      }
    }


    if(form.controls.taxe_id) {
      form.controls.taxe_id.valueChanges.subscribe(
        val => {
          let has_cost = false;
          if(form.controls.cost){
            has_cost = true;
          }
          this.formFunctions.lineFormChange(form, this.taxes.find(t => t.id == val).value, has_cost);
          this.calculateTotalDetails();
        }
      )
    }

    //en cada cambio de cantidad, precio y coste se recalculan los datos
    form.controls.quantity.valueChanges.subscribe(
      val => {
        if(val>=0) {


          let has_cost = false;
          if(form.controls.cost){
            has_cost = true;
          }
          this.formFunctions.lineFormChange(form, this.taxes.find(t => t.id == form.controls.taxe_id.value).value, has_cost);

          this.calculateTotalDetails();
        }
      }
    )

    form.addControl('total_price',new FormControl({value: line['total_price'], disabled: true}))
    form.addControl('total_taxe',new FormControl({value: line['total_taxe'], disabled: true}))
    form.addControl('total',new FormControl({value: line['total'], disabled: true}))

    form.controls.total_price.disable()
    form.controls.total_taxe.disable()
    form.controls.total.disable()

    if(form.controls.cost) {
      form.addControl('total_cost',new FormControl({value: line['total_cost'], disabled: true}))
      form.controls.cost.valueChanges.subscribe(
        val => {
          if(val>=0) {
            form.controls.total_cost.setValue(((+form.controls.quantity.value)*(+form.controls.cost.value)).toFixed(2))
            this.calculateTotalDetails();
          }
        }
      )
    }

    form.controls.price.valueChanges.subscribe(
      val => {
        if(val>=0) {
          let has_cost = false;
          if(form.controls.cost){
            has_cost = true;
          }
          this.formFunctions.lineFormChange(form, this.taxes.find(t => t.id == form.controls.taxe_id.value).value, has_cost);


          this.calculateTotalDetails();
        }

      }
    )

    form.controls.discount.valueChanges.subscribe(
      val => {
        if(val>=0) {
          let has_cost = false;
          if(form.controls.cost){
            has_cost = true;
          }
          this.formFunctions.lineFormChange(form, this.taxes.find(t => t.id == form.controls.taxe_id.value).value, has_cost);


          this.calculateTotalDetails();
        }

      }
    )

    form.valueChanges.subscribe(
      change => {
        this.emitChanges()
      }
    )

    this.detailsForms.push(form);

  }

  //recorre las lineas del parte para ir creando los formularios de linea
   private processDetails(): void{
    let total = 0;
    let total_price = 0;
    let total_taxe = 0;
    let total_cost = 0;

    for (let index = 0; index < this.details?.length; index++) {
      total += +this.details[index].total;
      total_price += +this.details[index].total_price;
      total_taxe += +this.details[index].total_taxe;

      if(this.details[index].total_cost){
        total_cost += +this.details[index].total_cost;
      }



      let deshabilitado = this.readonly;


      //Deshabilita la linea si se ha exportado
      if(this.details[index].linesexportsorigin) {
        deshabilitado = true;
      }
      if(this.details[index].type_export_line_id == 3) {
        deshabilitado = true;
      }

      //Deshabilita la linea si viene de una exportación
      /* if(this.details[index].linesexportsdestiny) {
        deshabilitado = true;
      } */

      //Deshabilita la linea si se ha liquidado
      if(this.details[index].typeliquidation_id) {
        if(this.details[index].typeliquidation_id == 3) {
          deshabilitado = true;
        }
      }

      if(this.readonly) {
        deshabilitado = true;
      }


      if(!this.taxes.find(t => t.id == this.details[index].taxe_id)){
        this.taxes.unshift(this.details[index].taxe)
      }

      this.fillLineForm(this.details[index], deshabilitado);

    }

    this.total_lineas = +total.toFixed(2);
    this.total_price = +total_price.toFixed(2);
    this.total_taxe = +total_taxe.toFixed(2);
    this.total_cost = +total_cost.toFixed(2);


  }

  private calculateTotalDetails(): void{
    let total = 0;
    let total_price = 0;
    let total_taxe = 0;
    let total_cost = 0;

    for (let j = 0; j < this.detailsForms.controls.length; j++) {
      let form: FormGroup = this.detailsForms.controls[j] as FormGroup;
      total += +form.controls.total.value;
      total_taxe += +form.controls.total_taxe.value;
      total_price += +form.controls.total_price.value;
      if(this.master.total_cost) {
        total_cost += +form.controls.total_cost.value;
      }
    }

    this.total_lineas = +total.toFixed(2);
    this.total_price = +total_price.toFixed(2);
    this.total_taxe = +total_taxe.toFixed(2);
    this.total_cost = +total_cost.toFixed(2);

    this.emitChanges()

  }



  addLineaKeyTAB(ev, pos){
    const key = ev.keyCode || ev.which;

    //última línea
    if(pos === this.detailsForms.controls.length-1) {
      //tecla TAB
      if(key == 9) {
        if(this.showAddDetail()) {
          this.addDetail()
        }
      }
    }
  }

  addLineaKeyDOWN(ev, pos){
    const key = ev.keyCode || ev.which;

    let addline = false;
    //última línea
    if(pos === this.detailsForms.controls.length-1) {
      //tecla ABAJO
      if(key == 40) {
        addline = true;
      }
    }

    if(addline){
      if(this.showAddDetail()) {
        this.addDetail()
      }
    }
  }

  public showAddDetail(): boolean{
    let mostrar = false;
    if(!this.readonly) {
      if(this.master?.typecondition_id == 1 || this.master?.type_export_id == 1) {
        if(this.detailsForms.controls.length == 0) {
          mostrar = true;
        } else {
          if(this.detailsForms.controls[this.detailsForms.controls.length -1]['controls'].id.value) {
            mostrar = true;
          } else {
            //nueva linea
            if(this.detailsForms.controls[this.detailsForms.controls.length -1].dirty) {
              mostrar = true;
            }
          }
        }
      }
    }

    return mostrar;
  }



  //Al añadir una linea nueva se genera un formulario vacio y se suscriben a los cambios
  public addDetail(): void{
    let insertline = {
      typeliquidation_id: 1,
      type_export_line_id: 1,
      taxe_id: this.taxes[0].id,
      code: '',
      description: '',
      quantity: 1,
      price: 0,
      discount: 0,
      total: 0,
      cost: null,
      total_cost: null,
      total_price: 0,
      total_taxe: 0,
      employee_id: null
    }
    if(this.employees) {

      if(this.employees?.length > 0) {
        let manager = this.employees.find(e=> e.manager == true);
        if(manager) {
          insertline.employee_id = manager.id;
        } else {
          insertline.employee_id = this.employees[0].id;
        }
      }
    }


    if(this.field_order.find(field => field.control === 'cost')){
      insertline.cost = 0;
      insertline.total_cost = 0;
    }

    this.fillLineForm(insertline, false)

    this.emitChanges();
  }

  public deleteDetail(index:number): void{

    let line_deleted = {...this.details[index]};

    //eliminación del array de lineas liquidables y del formulario de linea
    this.details.splice(index, 1)
    this.detailsForms.removeAt(index);

    if(line_deleted.id) {
      if(this.delete_lines.findIndex(line => line.id == line_deleted.id) < 0){
        this.delete_lines.push(line_deleted)
      }
    }

    this.calculateTotalDetails();

  }


  /**
   * Rellena el array de lineas del maestro con los formularios de linea y las lineas borradas
   * @param param Array del maestro que se enviará al api
   */
  pushDetailsToMaster(paramlines: Array<any>) {
    //nuevas y modificadas
    for (let index = 0; index < this.detailsForms.controls.length; index++) {
      const form = this.detailsForms.controls[index] as FormGroup;

      //si la linea se ha tocado y cambiado de valor se envia
      if(form.touched && !form.pristine) {
        let linea = {};
        Object.keys(form.controls).forEach(
          datolinea =>{
            if(datolinea !== 'readonly') {
              let datavalue = form.controls[datolinea].value;
              if((datolinea == 'typeliquidation_id') || (datolinea == 'type_export_line_id')){
                if(form.controls[datolinea].value == true) {
                  datavalue = 2
                } else {
                  datavalue = 1;
                }
              } else if(datolinea == 'employee_id' && datavalue === 'null') {
                datavalue = null;
              }
              linea[datolinea] = datavalue;

            }


          });
          paramlines.push(linea)
      }


    }

    //eliminadas
    this.delete_lines.forEach(
      deleted_line => {
        paramlines.push({id: deleted_line.id,  delete: true})
      }
    )


  }
}
