import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PanelOptionsService {


  /**
   * Tema que puede ser cambiado por el usuario durante el uso de la aplicación
   */
   private current_theme = new Subject<string>();

  private savedTheme:string;
  private timeOutDemo: any;

  private defaultTheme = 'PGA-Skin';
  private defaultInitPage = 'inicio';
  private defaultView = 'tramit-view';

  constructor() {

  }

  setDefaultOptions():void {
    this.setTheme(this.defaultTheme)
    //this.setInitPage(this.defaultInitPage)
    this.setView(this.defaultView)
  }

  setOptions(options, role_id?:number): void{
    this.setDefaultOptions();

    if(options.theme_webpanel) {
      this.setTheme(options.theme_webpanel)
    }

    /* if(options.init_tab) {
      this.setInitPage(options.init_tab)
    } */

    if(options.type_view) {
      this.setView(options.type_view)
    } else {
      this.setViewByRole(role_id);
    }
  }


 /**
  * VISTA
  */
 setView(view:string){
  sessionStorage.setItem('type_view',view)
 }
  setViewByRole(role:number): void {

    switch (role) {
      case 1:
        sessionStorage.setItem('type_view',this.defaultView)
        break;
      case 2:
        sessionStorage.setItem('type_view',this.defaultView)
        break;
      case 3:
        sessionStorage.setItem('type_view',this.defaultView)
        break;
      case 4:
        sessionStorage.setItem('type_view','employee-view')
        break;
      case 5:
        sessionStorage.setItem('type_view','client-view')
        break;
      case 5:
        sessionStorage.setItem('type_view','agent_view')
        break;

      default:

        sessionStorage.setItem('type_view',this.defaultView)
        break;
    }
  }


/**
 *
 * PAGINA INICIO
 */
  setInitPage(page: string): void{
    sessionStorage.setItem('init_tab', page);
  }



  /****
   * TEMA
   */

  setTheme(theme: string): void{
    if(theme) {
      sessionStorage.setItem('theme_webpanel', theme)
    } else {
      sessionStorage.setItem('theme_webpanel',this.defaultTheme)
    }
    this.savedTheme=theme;
  }
  /**
   *
   * @param theme : Nombre de la clase del tema "black-skin, white-skin, etc"
   */
   setCurrentTheme(theme: string){
    clearTimeout(this.timeOutDemo)
    this.savedTheme=theme;
    this.current_theme.next(theme);
  }

  /**
   *
   * @param theme : Nombre de la clase del tema "black-skin, white-skin, etc"
   */
   setDemoTheme(theme: string){
    this.current_theme.next(theme);
    clearTimeout(this.timeOutDemo)
    this.timeOutDemo=setTimeout(() => {
      this.declineDemoTheme()
    },15000);

  }

  declineDemoTheme(){
    this.current_theme.next(this.savedTheme);
  }

  /**
   * Devuelve el tema facilitado por el usuario durante el uso de la app
   */
  getCurrentTheme(): Observable<string>{
    return this.current_theme.asObservable();
  }

  getSavedTheme(): string{
    return this.savedTheme;
  }

  getDefaultTheme():string {
    return this.defaultTheme;
  }

  getDefaultInitPage():string{
    return this.defaultInitPage;
  }

  getDefaultView():string{
    return this.defaultView;
  }

}
