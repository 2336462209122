<div class="container-fluid">
  <app-loader *ngIf="loading"></app-loader>
  <mdb-card>
    <mdb-card-body>
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-8 mb-4">
          <!-- Nav Tabs -->
          <mdb-tabset #buyingTabs [buttonClass]="'md-pills pills-primary font-weight-bold'" [justified]="true" [disableWaves]="true">
            <!--Panel 1-->
            <mdb-tab heading="1. Licencia">
                <section class="text-center pb-3 my-2">
                  <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4">
                      <!--Card-->
                      <mdb-card>

                        <!--Content-->
                        <mdb-card-body>
                          <mdb-card-title>
                            <h5 class="mb-4">Usuarios</h5>
                          </mdb-card-title>
                          <div class="card-circle mx-auto d-flex flex-center">
                            <mdb-icon fas icon="user" class="light-blue-text"></mdb-icon>
                          </div>
                          <!--Price-->
                          <h2 class="font-weight-bold my-3">100</h2>
                          <!-- <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa pariatur id
                            nobis
                            accusamus deleniti
                            cumque hic laborum.</p> -->
                          <!-- <a mdbBtn color="light-blue" rounded="true" class="waves-light" mdbWavesEffect>Buy now</a> -->
                        </mdb-card-body>

                      </mdb-card>
                      <!--Card-->
                    </div>
                    <!--Grid column-->
                    <div class="col-lg-4 col-md-12 mb-4">
                      <!--Card-->
                      <mdb-card>

                        <!--Content-->
                        <mdb-card-body>
                          <mdb-card-title>
                            <h5 class="mb-4">Expedientes</h5>
                          </mdb-card-title>
                          <div class="card-circle mx-auto d-flex flex-center">
                            <mdb-icon fas icon="folders" class="light-blue-text"></mdb-icon>
                          </div>
                          <!--Price-->
                          <h2 class="font-weight-bold my-3">{{license_data?.number_claims}}</h2>
                          <p class="grey-text">
                            Precio por Expediente: {{CLAIM_PRICE | number:'1.2-2':'es'}} €
                          </p>
                          <!-- <p class="grey-text">
                            Total: 800 €
                          </p> -->
                          <a mdbBtn color="blue" rounded="true" class="waves-light" mdbWavesEffect *ngIf="!show_claims_add" (click)="show_claims_add = true">Añadir más</a>

                          <ng-container *ngIf="show_claims_add">
                            <div class="row">
                              <div class="col-8 offset-2">
                                <div class="md-form md-outline input-group mb-3">
                                  <input type="number" class="form-control text-right" step="1" min="0" id="numberInputEx" placeholder="" aria-describedby="button-addon2" [(ngModel)]="number_claims" mdbInput>
                                  <div class="input-group-append">
                                    <button mdbBtn color="light-blue" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2"
                                      mdbWavesEffect (click)="addClaims()">
                                      <mdb-icon fas icon="plus"></mdb-icon>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </mdb-card-body>

                      </mdb-card>
                      <!--Card-->
                    </div>
                    <!--Grid column-->
                    <!--Grid column-->
                    <div class="col-lg-4 col-md-12 mb-4">
                      <!--Card-->
                      <mdb-card>

                        <!--Content-->
                        <mdb-card-body>
                          <mdb-card-title>
                            <h5 class="mb-4">Partes</h5>
                          </mdb-card-title>
                          <div class="card-circle mx-auto d-flex flex-center">
                            <mdb-icon fas icon="file-alt" class="light-blue-text"></mdb-icon>
                          </div>
                          <!--Price-->
                          <h2 class="font-weight-bold my-3">{{license_data?.number_workorders}}</h2>
                          <p class="grey-text">
                            Precio por Parte: {{WORKORDER_PRICE | number:'1.2-2':'es'}}€
                          </p>
                          <a mdbBtn color="blue" rounded="true" class="waves-light" mdbWavesEffect *ngIf="!show_workorders_add" (click)="show_workorders_add = true">Añadir más</a>

                          <ng-container *ngIf="show_workorders_add">
                            <div class="row">
                              <div class="col-8 offset-2">
                                <div class="md-form md-outline input-group mb-3">
                                  <input type="number" class="form-control text-right" step="1" min="0" id="numberInputWK" placeholder="" aria-describedby="button-addon3" [(ngModel)]="number_workorders" mdbInput>
                                  <div class="input-group-append">
                                    <button mdbBtn color="light-blue" size="md" class="m-0 px-3 py-2" type="button" id="button-addon3"
                                      mdbWavesEffect (click)="addWorkorders()">
                                      <mdb-icon fas icon="plus"></mdb-icon>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </mdb-card-body>

                      </mdb-card>
                      <!--Card-->
                    </div>
                    <!--Grid column-->
                </div>
              </section>
            </mdb-tab>
            <!--Panel 2-->
            <mdb-tab heading="2. Módulos">
              <section class="text-center pb-3 my-2">
                  <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4">
                      <!--Card-->
                      <mdb-card>

                        <!--Content-->
                        <mdb-card-body>
                          <mdb-card-title>
                            <h5 class="mb-4">Compañías</h5>
                          </mdb-card-title>
                          <div class="card-circle mx-auto d-flex flex-center">
                            <mdb-icon fas icon="building" class="light-blue-text"></mdb-icon>
                          </div>
                          <!--Price-->
                          <h2 class="font-weight-bold my-3" *appModules="['companies']; else companiesprice">
                            En Uso
                          </h2>
                          <ng-template #companiesprice>
                            <h2 class="font-weight-bold my-3">{{modulePrice('companies') | number:'1.2-2':'es'}} €</h2>
                          </ng-template>
                          <ul class="custom-list-price">
                            <li>
                              <p>
                                Gestión de Compañías</p>
                            </li>
                            <li>
                              <p class="mt-1">
                                Protocolos</p>
                            </li>
                            <li>
                              <p>
                                Pólizas</p>
                            </li>
                          </ul>

                          <ng-container *appModules="['companies']; else addcompanies">
                            <a mdbBtn color="light-blue" rounded="true" class="waves-light" mdbWavesEffect *ngIf="showRemoveButton('companies')"
                            (click)="addModule('companies', true)">Quitar</a>
                          </ng-container>

                          <ng-template #addcompanies>
                            <a mdbBtn color="blue" rounded="true" class="waves-light" mdbWavesEffect (click)="addModule('companies')" *ngIf="showAddButton('companies')">Añadir</a>
                          </ng-template>
                        </mdb-card-body>

                      </mdb-card>
                      <!--Card-->
                    </div>
                    <div class="col-lg-4 col-md-12 mb-4">
                      <!--Card-->
                      <mdb-card>

                        <!--Content-->
                        <mdb-card-body>
                          <mdb-card-title>
                            <h5 class="mb-4">Facturación</h5>
                          </mdb-card-title>
                          <div class="card-circle mx-auto d-flex flex-center">
                            <mdb-icon fas icon="file-invoice-dollar" class="light-blue-text"></mdb-icon>
                          </div>
                          <!--Price-->
                          <h2 class="font-weight-bold my-3" *appModules="['billing']; else billingprice">
                            En Uso
                          </h2>
                          <ng-template #billingprice>
                            <h2 class="font-weight-bold my-3">{{modulePrice('billing') | number:'1.2-2':'es'}} €</h2>
                          </ng-template>
                          <ul class="custom-list-price">
                            <li>
                              <p>
                                Facturas, Presupuestos, Albaranes</p>
                            </li>
                            <li>
                              <p class="mt-1">
                                Facturas Proforma, Gastos</p>
                            </li>
                            <li>
                              <p>
                                Liquidaciones a Operarios</p>
                            </li>
                          </ul>

                          <ng-container *appModules="['billing']; else addbilling">
                            <a mdbBtn color="light-blue" rounded="true" class="waves-light" mdbWavesEffect *ngIf="showRemoveButton('billing')"
                            (click)="addModule('billing', true)">Quitar</a>
                          </ng-container>

                          <ng-template #addbilling>
                            <a mdbBtn color="blue" rounded="true" class="waves-light" mdbWavesEffect (click)="addModule('billing')" *ngIf="showAddButton('billing')">Añadir</a>
                          </ng-template>
                        </mdb-card-body>

                      </mdb-card>
                      <!--Card-->
                    </div>
                    <div class="col-lg-4 col-md-12 mb-4">
                      <!--Card-->
                      <mdb-card>

                        <!--Content-->
                        <mdb-card-body>
                          <mdb-card-title>
                            <h5 class="mb-4">Correo electrónico</h5>
                          </mdb-card-title>
                          <div class="card-circle mx-auto d-flex flex-center">
                            <mdb-icon fas icon="at" class="light-blue-text"></mdb-icon>
                          </div>
                          <!--Price-->
                          <h2 class="font-weight-bold my-3" *appModules="['mail']; else mailprice">
                            En Uso
                          </h2>
                          <ng-template #mailprice>
                            <h2 class="font-weight-bold my-3">{{modulePrice('mail') | number:'1.2-2':'es'}} €</h2>
                          </ng-template>
                          <ul class="custom-list-price">
                            <li>
                              <p>
                                Gestión de Correo</p>
                            </li>
                            <li>
                              <p class="mt-1">
                                Integración Gmail y Outlook</p>
                            </li>
                            <li>
                              <p>
                                Procesamiento de Expedientes</p>
                            </li>
                          </ul>

                          <ng-container *appModules="['mail']; else addmail">
                            <a mdbBtn color="light-blue" rounded="true" class="waves-light" mdbWavesEffect *ngIf="showRemoveButton('mail')"
                            (click)="addModule('mail', true)">Quitar</a>
                          </ng-container>

                          <ng-template #addmail>
                            <a mdbBtn color="blue" rounded="true" class="waves-light" mdbWavesEffect (click)="addModule('mail')" *ngIf="showAddButton('mail')">Añadir</a>
                          </ng-template>
                        </mdb-card-body>

                      </mdb-card>
                      <!--Card-->
                    </div>
                  </div>
                </section>
            </mdb-tab>
            <!--Panel 3-->
            <mdb-tab heading="3. Pago">
              <div class="row">
                <div class="col-12">
                  <div class="d-block my-3">
                    <div class="mb-2">
                      <input
                        name="group2"
                        type="radio"
                        class="form-check-input with-gap"
                        id="radioWithGap4"
                        checked
                        required
                      />
                      <label class="form-check-label" for="radioWithGap4">Credit card</label>
                    </div>
                    <div class="mb-2">
                      <input
                        iname="group2"
                        type="radio"
                        class="form-check-input with-gap"
                        id="radioWithGap5"
                        required
                      />
                      <label class="form-check-label" for="radioWithGap5">Debit card</label>
                    </div>
                    <div class="mb-2">
                      <input
                        name="group2"
                        type="radio"
                        class="form-check-input with-gap"
                        id="radioWithGap6"
                        required
                      />
                      <label class="form-check-label" for="radioWithGap6">Paypal</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <div class="md-form mb-0">
                        <label for="cc-name123">Name on card</label>
                        <input
                          mdbInput
                          type="text"
                          class="form-control"
                          id="cc-name123"
                          placeholder=""
                          required
                        />
                      </div>
                      <small class="text-muted">Full name as displayed on card</small>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="md-form">
                        <label for="cc-number123">Credit card number</label>
                        <input
                          mdbInput
                          type="text"
                          class="form-control"
                          id="cc-number123"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 mb-3">
                      <div class="md-form">
                        <label for="cc-expiration123">Expiration</label>
                        <input
                          mdbInput
                          type="text"
                          class="form-control"
                          id="cc-expiration123"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <div class="md-form">
                        <label for="cc-cvv123">CVV</label>
                        <input
                          mdbInput
                          type="text"
                          class="form-control"
                          id="cc-cvv123"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <hr class="mb-4" />
                  <button mdbBtn type="button" color="primary" block="true" mdbWavesEffect>
                    Place Order
                  </button>
                </div>
              </div>
            </mdb-tab>
          </mdb-tabset>
        </div>
        <!--Grid column-->
        <!--Grid column-->
        <div class="col-lg-4 mb-4">

          <!--Card-->
          <mdb-card>
            <!--Card content-->
            <mdb-card-body>
              <h4 class="mb-4 mt-1 h5 text-center font-weight-bold"><mdb-icon fas icon="shopping-cart" class="indigo-text pr-1"></mdb-icon>Resumen</h4>
              <dl class="row">
                <dt class="col-sm-7">
                  Licencia
                </dt>
                <dt class="col-sm-2">
                </dt>
              </dl>
              <ng-container *ngFor="let license of license_cart; let i= index">
                <hr />
                <dl class="row animated fadeInDown">
                  <dd class="col-sm-7">
                    {{license.description}}
                  </dd>
                  <dd class="col-sm-3">
                      {{license.price | number:'1.2-2':'es'}} €
                  </dd>
                  <dd class="col-sm-2">
                    <button type="button" mdbBtn size="sm" class="custom-sm-button" color="primary" (click)="removeLicense(license.name)">X
                    </button>
                  </dd>
                </dl>
              </ng-container>
              <dl class="row">
                <dt class="col-sm-7">
                  Módulos
                </dt>
                <dt class="col-sm-2">
                </dt>
              </dl>
              <ng-container *ngFor="let product of modules_cart; let i= index">
                <hr />
                <dl class="row animated fadeInDown">
                  <dd class="col-sm-7">
                    {{product.description}}
                  </dd>
                  <dd class="col-sm-3">
                    <ng-container *ngIf="!product.delete; else deletemodule">
                      {{product.price | number:'1.2-2':'es'}} €
                    </ng-container>
                    <ng-template #deletemodule>
                      baja
                    </ng-template>
                  </dd>
                  <dd class="col-sm-1">
                    <button type="button" mdbBtn size="sm" class="custom-sm-button" color="primary" (click)="removeModule(product.name)">X
                    </button>
                  </dd>
                </dl>
              </ng-container>
              <hr />
              <dl class="row">
                <dt class="col-sm-7">
                  Total
                </dt>
                <dt class="col-sm-4">
                  {{total_cart | number:'1.2-2':'es'}} €
                </dt>
              </dl>
            </mdb-card-body>
          </mdb-card>
          <mdb-card-footer>
            <button mdbBtn type="button" color="primary" block="true" mdbWavesEffect (click)="onSubmit()" [disabled]="license_cart.length===0 && modules_cart.length === 0">
              Confirmar
            </button>
          </mdb-card-footer>
          <!--/.Card-->
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </mdb-card-body>
  </mdb-card>
</div>
