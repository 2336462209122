<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formScale">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="'<i class=\'fas fa-info-circle\'></i> Datos del nuevo '+data.entity_label+''">
            <div class="row">
              <div class="col-lg-3">
                <div class="md-form">
                  <mdb-select-2 mdbValidate [validateSuccess]="false" placeholder="Selecciona compañía" label="Compañía" formControlName="company_id" [allowClear]="false">
                    <mdb-select-option *ngFor="let company of companies" [value]="company.id">{{ company.name }}
                    </mdb-select-option>
                    <!-- <mdb-select-filter formControlName="company_name"> </mdb-select-filter>
                    <mdb-select-option *ngFor="let company of filteredCompanyOptions | async" [value]="company.id">{{ company.name }}
                    </mdb-select-option> -->
                </mdb-select-2>
                <mdb-error *ngIf="formScale.controls.company_id.invalid && (formScale.controls.company_id.dirty || formScale.controls.company_id.touched)">
                  Debe seleccionar una compañía
                </mdb-error>
                </div>

              </div>
              <div class="col-lg-3">
                <div class="md-form">
                  <mdb-select-2 placeholder="Selecciona subcompañía" label="Subcompañía" formControlName="subcompany_id" [allowClear]="true">
                    <mdb-select-option *ngFor="let subcompany of subcompanies" [value]="subcompany.id">{{ subcompany.name }}
                    </mdb-select-option>
                </mdb-select-2>
                </div>

              </div>
              <div class="col-lg-3">
                <div class="md-form">
                  <mdb-select-2 mdbValidate [validateSuccess]="false" [placeholder]="'Selecciona un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName="guild_id">
                    <mdb-select-option *ngFor="let guild of dropdowndata?.guild" [value]="guild.id">{{ guild.name }}
                    </mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formScale.controls.guild_id.invalid && (formScale.controls.guild_id.dirty || formScale.controls.guild_id.touched)">
                  Debe seleccionar un {{guild_entity.labels.form}}
                </mdb-error>
                </div>

              </div>
              <div class="col-lg-3">
                <div class="md-form">
                  <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Selecciona una provincia" label="Provincia" formControlName="province_id">
                    <mdb-select-option *ngFor="let province of dropdowndata?.province" [value]="province.id">{{ province.name }}
                    </mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formScale.controls.province_id.invalid && (formScale.controls.province_id.dirty || formScale.controls.province_id.touched)">
                  Debe seleccionar una provincia
                </mdb-error>
                </div>

              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-3">
                <div class="md-form">
                  <input mdbInput type="text" id="internal_code" formControlName="internal_code" placeholder=""
                      class="form-control form-control-sm">
                  <label for="internal_code" class="">Código Interno</label>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="code" formControlName="code" placeholder=""
                      class="form-control form-control-sm">
                  <label for="code" class="">Código</label>
                  <mdb-error *ngIf="formScale.controls.code.invalid && (formScale.controls.code.dirty || formScale.controls.code.touched)">
                    Debe introducir un código
                  </mdb-error>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="description" formControlName="description" placeholder=""
                      class="form-control form-control-sm">
                  <label for="description" class="">Descripción</label>
                  <mdb-error *ngIf="formScale.controls.description.invalid && (formScale.controls.description.dirty || formScale.controls.description.touched)">
                    Debe introducir una descripción
                  </mdb-error>
                </div>
              </div>
            </div>
            <div class="row mt-3">

              <div class="col-lg-2">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="number" id="price" formControlName="price" placeholder=""
                    step="1" class="form-control form-control-sm text-right">
                  <label for="price" class="">Precio</label>
                  <mdb-error *ngIf="formScale.controls.price.invalid && (formScale.controls.price.dirty || formScale.controls.price.touched)">
                    <ng-container *ngFor="let error of formScale.controls.price.errors | keyvalue">
                      <ng-container [ngSwitch]="error.key">
                        <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                        <ng-container *ngSwitchCase="'min'">Valor incorrecto</ng-container>
                      </ng-container>
                    </ng-container>
                  </mdb-error>
                </div>
              </div>
              <div class="col-lg-2">
                <div class="md-form">
                  <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Selecciona un impuesto" label="Impuesto" formControlName="taxe_id">
                    <mdb-select-option *ngFor="let taxe of dropdowndata?.taxe" [value]="taxe.id">{{ taxe.value }} %
                    </mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formScale.controls.taxe_id.invalid && (formScale.controls.taxe_id.dirty || formScale.controls.taxe_id.touched)">
                  Debe seleccionar un impuesto
                </mdb-error>
                </div>

              </div>
              <div class="col-lg-2">
                <div class="md-form">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="number" id="cost" formControlName="cost" placeholder=""
                    step="1" class="form-control form-control-sm text-right">
                  <label for="cost" class="">Coste</label>
                  <mdb-error *ngIf="formScale.controls.cost.invalid && (formScale.controls.cost.dirty || formScale.controls.cost.touched)">
                    <ng-container *ngFor="let error of formScale.controls.cost.errors | keyvalue">
                      <ng-container [ngSwitch]="error.key">
                        <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                        <ng-container *ngSwitchCase="'min'">Valor incorrecto</ng-container>
                      </ng-container>
                    </ng-container>
                  </mdb-error>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
  </form>
</div>
