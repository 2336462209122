<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid">

    <app-dynamic-list-filters #dynamicFilters [entityFilters]="data.listParams.filters" (emitFilters)="applyFilters($event)"></app-dynamic-list-filters>

    <!--Table-->
    <div class="table-responsive" style="min-height:500px">
        <table mdbTable mdbTableScroll scrollY="true" maxHeight="100" #tableEl="mdbTable" class="z-depth-1">
            <!--Table head-->
            <thead>
                <tr class="blue darken-1 white-text">
                    <th>Estado</th>
                    <th>Nombre completo</th>
                    <th>Email</th>
                    <th>Empresa:Rol</th>
                </tr>
            </thead>
            <!--Table head-->

            <!--Table body-->
            <tbody>
                <!-- FILAS -->
                <ng-container *ngFor="let usuario of datos; let i=index;">
                    <tr class="filas" (click)="selectUser(usuario, i)" (dblclick)="editRegister(usuario)" (contextmenu)="onContextMenuUser($event,usuario,i)" [class.selected]="i == selectedRow">
                      <td>
                        <ng-container *ngIf="usuario.is_enabled == 1; else notActive">
                          <mdb-icon far icon="check-square"></mdb-icon>
                        </ng-container>
                        <ng-template #notActive>
                          <mdb-icon far icon="square"></mdb-icon>
                        </ng-template>
                      </td>
                      
                      <td>{{usuario.name}} {{usuario.surname}}</td>
                        <td>{{usuario.email}}</td>
                       <!-- <td>
                            <ng-container *ngFor="let rolfirm of usuario.roleuserfirm">
                                <ng-container *ngIf="rolfirm.is_enabled == 1">
                                    {{rolfirm.firm.name | titlecase}} <br>
                                </ng-container>
                            </ng-container>
                        </td>-->
                        <td>
                          <ng-container *ngFor="let rolfirm of usuario.roleuserfirm">
                            <ng-container *ngIf="rolfirm.is_enabled == 1;else notActiveRoleUserFirm">
                              <mdb-icon far icon="check-square"></mdb-icon> {{rolfirm.firm.name | titlecase }}: {{rolfirm.role.name | titlecase}} <br>
                            </ng-container>
                            <ng-template #notActiveRoleUserFirm>
                              <mdb-icon far icon="square"></mdb-icon> {{rolfirm.firm.name | titlecase }}: {{rolfirm.role.name | titlecase}} <br>
                            </ng-template>
                          </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
            <!--Table body-->
        </table>
        <!--Table-->
    </div>

    <tfoot class="grey lighten-5 w-100">
        <tr>
            <td></td>
            <td [colSpan]="6">
                <mat-paginator #paginator style="float: left" [length]="total" [pageIndex]="pag_actual" [pageSize]="[data.listParams.limit]" [pageSizeOptions]="[data.listParams.limit]" [showFirstLastButtons]="true" (page)="changePage($event)">
                </mat-paginator>
            </td>
        </tr>
    </tfoot>
    <!--Menú contextual en las filas de la tabla-->

    <!--Menú contextual en las filas de la tabla-->
    <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="editRegister(item)">
        <mdb-icon fas icon="pencil-alt" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Editar {{data.entity_label}}
      </button>

            <ng-container *ngIf="listparams?.disable_user;">
                <button mat-menu-item>
          <mdb-icon fas icon="user-slash" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Deshabilitar
          {{data.entity_label}}
        </button>
            </ng-container>

        </ng-template>
    </mat-menu>

    <!-- modal confirmación deshabilitar -->
    <!--    <div
   mdbModal
   [config]="{ ignoreBackdropClick: true, keyboard: false }"
   #modalDisable="mdbModal"
   class="modal fade"
   id="modalDisable"
   tabindex="-1"
   role="dialog"
   aria-labelledby="modalDisable"
   aria-hidden="true"
 >
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-header">
         <h4 class="modal-title w-100" id="myModalLabel">
           Deshabilitar Usuario
         </h4>
         <button
           type="button"
           class="close"
           data-dismiss="modal"
           aria-label="Close"
           (click)="modalDisable.hide()"
         >
           <span aria-hidden="true">×</span>
                   </button>
               </div>
               <div class="modal-body" *ngIf="register">
                   <div *ngIf="!borrando">
                       ¿Quiere deshabilitar el usuario: <br> {{ register.name }} {{register.surname}} ({{register.email}})?

                   </div>

                   <span *ngIf="borrando">Deshabilitando Usuario {{ register.id }} -
           {{ register.name }}</span
         >
       </div>
       <div class="modal-footer">
         <ng-container *ngIf="!borrando; else wait">
           <button
             type="button"
             mdbBtn
             color="primary"
             size="sm"
             class="waves-light"
             mdbWavesEffect
             (click)="disableUser()"
           >
             Deshabilitar
           </button>
           <button
             type="button"
             mdbBtn
             color="secondary"
             size="sm"
             class="bwaves-light"
             data-dismiss="modal"
             (click)="cancelDisableUser()"
             mdbWavesEffect
           >
             Cancelar
           </button>
         </ng-container>
         <ng-template #wait>
           <mdb-progress-bar
             class="progress primary-color-dark"
             mode="indeterminate"
           ></mdb-progress-bar>
         </ng-template>
       </div>
     </div>
   </div>
 </div> -->

</div>
