<app-loader *ngIf="loading || sending"></app-loader>


<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--SECCION INFORMACION-->
  <div class="m-3">
    <div class="col-3">
      <div class="md-form">
        <mdb-select [options]="optionsSelect" [outline]="true" [appendToBody]="true" placeholder="Selección de proveedor de correo" label="Proveedor" [(ngModel)]="type" (ngModelChange)="changeType($event)"></mdb-select>
      </div>
    </div>
  </div>
  
  <ng-container *ngIf="type">
    <!--SECCION DATOS DE CONEXION-->
    <div class="divCustomSection">
      <i class='fas fa-cog mr-2'></i> Datos de conexión
    </div>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="3">
        <form [formGroup]="formAccountData">
         <div class="row mt-3 ml-2 mr-2 mb-3">
            <!--cuenta de correo-->
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="mail" formControlName="mail" class="form-control"  placeholder="" aria-label="Cuenta de Correo">
                <label for="mail" class="">Cuenta de Correo</label>
                <mdb-error *ngIf="formAccountData.controls.mail.invalid && (formAccountData.controls.mail.dirty || formAccountData.controls.mail.touched)">
                  <ng-container *ngFor="let error of formAccountData.controls.mail.errors | keyvalue">
                    <ng-container [ngSwitch]="error.key">
                      <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                      <ng-container *ngSwitchCase="'email'">Formato de email Incorrecto</ng-container>
                      <ng-container *ngSwitchCase="'data_exists'">La cuenta introducida ya existe</ng-container>
                      <ng-container *ngSwitchDefault>Valor incorrecto</ng-container>
                    </ng-container>
                  </ng-container>

                </mdb-error>
              </div>
            </div>

            <!--contraseña-->
            <div class="col-lg">
              <div class="row">
                <div class="col-9">
                  <div class="md-form md-outline">
                    <input mdbInput mdbValidate [validateSuccess]="false" [type]="view_pass ? 'text' : 'password'" id="password" class="form-control custom-input-group" formControlName="password" placeholder="" aria-label="Contraseña">
                    <label for="password">Contraseña</label>
                  </div>
                </div>
                <div class="col-3 mt-1">
                  <button mdbBtn color="light" (click)="view_pass = !view_pass" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2">
                    <mdb-icon fad [icon]="view_pass ? 'eye-slash' : 'eye'"></mdb-icon>
                  </button>
                </div>
              </div>
              <mdb-error *ngIf="formAccountData.controls.password.invalid && (formAccountData.controls.password.dirty || formAccountData.controls.password.touched)">Campo Obligatorio</mdb-error>
            </div>

            <!--seguridad-->
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Seguridad" formControlName="method">
                  <mdb-select-option *ngFor="let option of imap_ports" [value]="option.type">{{ option.label }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>
                  
            <!--servidorIMAP-->
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="imap_server" class="form-control form-control-sm" formControlName="imap_server" placeholder="">
                <label for="imap_server">Servidor IMAP</label>
                <mdb-error *ngIf="formAccountData.controls.imap_server.invalid && (formAccountData.controls.imap_server.dirty || formAccountData.controls.imap_server.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>

            <!--puertoIMAP-->
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Puerto Imap" formControlName="imap_port">
                  <mdb-select-option *ngFor="let port of filtered_imap_ports" [value]="port"> {{ port }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>

            <!--servidorSMTP-->
            <div class="col-lg">
              <div class="md-form md-outline">
                <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="smtp_server" class="form-control form-control-sm" formControlName="smtp_server" placeholder="">
                <label for="smtp_server">Servidor SMTP</label>
                <mdb-error *ngIf="formAccountData.controls.smtp_server.invalid && (formAccountData.controls.smtp_server.dirty || formAccountData.controls.smtp_server.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>

            <!--puertoSMTP-->
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Puerto SMTP" formControlName="smtp_port">
                  <mdb-select-option *ngFor="let port of filtered_smtp_ports" [value]="port">{{ port }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>
          </div>
        </form>


        <!--SECCION usuario-->
        <div class="divCustomSection">
          <i class='fas fa-user mr-2'></i> Usuarios
        </div>
        <div class="row mt-2 ml-2 mr-2">
          <div class="col-6">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                  <mdb-icon fad icon="users" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios Vinculados a la cuenta de correo
              </mdb-card-header>
              <mdb-card-body class="scroll-card-body">
                <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">                  
                  <thead>
                    <tr>
                      <th scope="col" class="buttons-column"></th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Email</th>
                      <th scope="col" class="buttons-column">Pred.</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let user of users; let i=index">
                      <tr mdbTableCol>
                        <td class="buttons-column">
                          <a (click)="removeUser(user)">
                            <mdb-icon fad icon="times-circle" size="lg" class="red-text"></mdb-icon>
                          </a>
                        </td>
                        <td>
                          <span>{{user.name}} {{user.surname}}</span>
                        </td>
                        <td>
                          <span>{{user.email}}</span>
                        </td>
                        <td class="buttons-column"> 
                          <mdb-checkbox [default]="true" [checked]="user.default_mailaccount" (change)="onChangeDefault($event, user)"></mdb-checkbox>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </mdb-card-body>
            </mdb-card>
          </div>
          <div class="col-6">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                  <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios Disponibles
              </mdb-card-header>
              <mdb-card-body class="scroll-card-body">
                <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">                  
                  <thead>
                    <tr>
                      <th scope="col" class="buttons-column"></th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let user of available_users; let i=index">
                      <tr mdbTableCol>
                        <td class="buttons-column">
                          <a (click)="addUser(user)">
                            <mdb-icon fad icon="plus-circle"  size="lg" class="blue-text"></mdb-icon>
                          </a>
                        </td>
                        <td>
                          <span>{{user.name}} {{user.surname}}</span>
                        </td>
                        <td>
                          <span>{{user.email}}</span>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </mdb-card-body>
            </mdb-card>
          </div>
        </div>


      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="mt-1 ml-4">
          <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="openMailProvider()">
            <label>Iniciar sesión</label> 
            <mdb-icon fab icon="{{getSelectedType()?.button}}" size="lg" class="ml-2"></mdb-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<!--
<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-6">
          <mdb-card class="z-depth-0">
            <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                <!-- <mdb-icon fad
                icon="mail"
                size="md"
                class="mr-1"></mdb-icon>  Tipo de Cuenta
         </mdb-card-header>
            <mdb-card-body>

                    <div class="row">
                        <div class="col">
                            <div class="md-form">
                                <mdb-select [options]="optionsSelect" placeholder="SELECCIONAR"
                                    label="Selecciona el tipo de cuenta" [(ngModel)]="type"
                                    (ngModelChange)="changeType($event)"></mdb-select>
                            </div>
                        </div>
                    </div>

                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>
<ng-container *ngIf="type">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="3">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5">
            <form [formGroup]="formAccountData">
              <div class="row justify-content-center mt-3">
                <div class="col">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                        <mdb-icon fad
                        icon="at"
                        size="md"
                        class="mr-1"></mdb-icon> Datos Cuenta
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row">
                        <div class="col-md">
                          <div class="md-form">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="mail" formControlName="mail" class="form-control"  placeholder="" aria-label="Cuenta de Correo">
                            <label for="mail" class="">Cuenta de Correo</label>
                            <mdb-error *ngIf="formAccountData.controls.mail.invalid && (formAccountData.controls.mail.dirty || formAccountData.controls.mail.touched)">
                              <ng-container *ngFor="let error of formAccountData.controls.mail.errors | keyvalue">
                                <ng-container [ngSwitch]="error.key">
                                  <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                                  <ng-container *ngSwitchCase="'email'">Formato de email Incorrecto</ng-container>
                                  <ng-container *ngSwitchCase="'data_exists'">La cuenta introducida ya existe</ng-container>
                                  <ng-container *ngSwitchDefault>Valor incorrecto</ng-container>
                                </ng-container>
                              </ng-container>

                            </mdb-error>
                          </div>
                        </div>
                        <div class="col-md">
                          <div class="md-form input-group mb-3">
                            <input mdbInput mdbValidate [validateSuccess]="false" [type]="view_pass ? 'text' : 'password'" id="password" class="form-control custom-input-group" formControlName="password" placeholder="" aria-label="Contraseña">
                            <label for="password">Contraseña</label>
                            <div class="input-group-append">
                              <button mdbBtn color="light" (click)="view_pass = !view_pass" size="md" class="m-0 px-3 py-2" type="button" id="button-addon2">
                                <mdb-icon fad [icon]="view_pass ? 'eye-slash' : 'eye'"></mdb-icon>
                              </button>
                            </div>
                            <mdb-error *ngIf="formAccountData.controls.password.invalid && (formAccountData.controls.password.dirty || formAccountData.controls.password.touched)">
                              Campo Obligatorio
                            </mdb-error>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="md-form">
                            <mdb-select-2 placeholder="Seleccionar" label="Seguridad" formControlName="method">
                              <mdb-select-option *ngFor="let option of imap_ports" [value]="option.type">{{ option.label }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </div>
                      </div>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
              <div class="row justify-content-center mt-3">
                <div class="col-md-6">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                        <mdb-icon fad
                        icon="inbox-in"
                        size="sm"
                        class="mr-1"></mdb-icon>Ajustes de Entrada
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row">
                        <div class="col-md">
                          <div class="md-form">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="imap_server" class="form-control form-control-sm" formControlName="imap_server" placeholder="">
                            <label for="imap_server">Servidor de entrada</label>
                            <mdb-error *ngIf="formAccountData.controls.imap_server.invalid && (formAccountData.controls.imap_server.dirty || formAccountData.controls.imap_server.touched)">
                              Campo Obligatorio
                            </mdb-error>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="md-form">
                            <mdb-select-2 placeholder="Seleccionar" label="Puerto" formControlName="imap_port">
                              <mdb-select-option *ngFor="let port of filtered_imap_ports" [value]="port">{{ port }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </div>
                      </div>
                    </mdb-card-body>
                  </mdb-card>
                </div>
                <div class="col-md-6">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                        <mdb-icon fad
                        icon="inbox-out"
                        size="sm"
                        class="mr-1"></mdb-icon>Ajustes de Salida
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row">
                        <div class="col-md">
                          <div class="md-form">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="smtp_server" class="form-control form-control-sm" formControlName="smtp_server" placeholder="">
                            <label for="smtp_server">Servidor de salida</label>
                            <mdb-error *ngIf="formAccountData.controls.smtp_server.invalid && (formAccountData.controls.smtp_server.dirty || formAccountData.controls.smtp_server.touched)">
                              Campo Obligatorio
                            </mdb-error>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="md-form">
                            <mdb-select-2 placeholder="Seleccionar" label="Puerto" formControlName="smtp_port">
                              <mdb-select-option *ngFor="let port of filtered_smtp_ports" [value]="port">{{ port }}</mdb-select-option>
                            </mdb-select-2>
                          </div>
                        </div>
                      </div>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md">
            <mdb-card class="z-depth-0 mt-3">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                  <mdb-icon fad
                  [icon]="user_entity.icon.list"
                  size="md"
                  class="mr-1"></mdb-icon>  {{user_entity.labels.list | titlecase}}
              </mdb-card-header>
              <mdb-card-body>
                <div class="row">
                  <div class="col-6">
                    <mdb-card class="z-depth-0">
                      <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                          <mdb-icon fad icon="users" size="lg" class="mr-1 text-primary"></mdb-icon>Vinculados
                      </mdb-card-header>
                      <mdb-card-body class="scroll-card-body">
                        <table mdbTable small="true">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Nombre</th>
                              <th scope="col">Email</th>
                              <th scope="col">Predet.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let user of users; let i=index">
                              <tr mdbTableCol>
                                <td>
                                  <a (click)="removeUser(user)">
                                    <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                                  </a>
                                </td>
                                <td>
                                  {{user.name}} {{user.surname}}
                                </td>
                                <td>
                                  {{user.email}}
                                </td>
                                <td>
                                  <mdb-checkbox [default]="true" [checked]="user.default_mailaccount" (change)="onChangeDefault($event, user)"></mdb-checkbox>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                  <div class="col-6">
                    <mdb-card class="z-depth-0">
                      <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                          <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Disponibles
                      </mdb-card-header>
                      <mdb-card-body class="scroll-card-body">
                        <table mdbTable small="true">
                          <thead>
                            <tr>
                              <th scope="col"></th>
                              <th scope="col">Nombre</th>
                              <th scope="col">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let user of available_users; let i=index">
                              <tr mdbTableCol>
                                <td>
                                  <a (click)="addUser(user)">
                                    <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                                  </a>
                                </td>
                                <td>
                                  {{user.name}} {{user.surname}}
                                </td>
                                <td>
                                  {{user.email}}
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </div>
              </mdb-card-body>
              </mdb-card>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="container">
        <div class="row justify-content-center mt-3">
          <div class="col-6">
            <mdb-card class="z-depth-0">
              <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                  <mdb-icon fad
                  icon="at"
                  size="md"
                  class="mr-1"></mdb-icon> Datos Cuenta
              </mdb-card-header>
              <mdb-card-body class="d-flex justify-content-center">

                <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="openMailProvider()">
                  Conectar con {{getSelectedType()?.label}} <mdb-icon fab icon="{{getSelectedType()?.button}}" size="md" class="ml-1"></mdb-icon>
                </button>
                <!-- <div class="row">
                  <div class="col">
                    <div class="md-form">
                        <input mdbInput mdbValidate [validateSuccess]="false" type="text" placeholder="" [(ngModel)]="account"
                            name="mailaccount" #mailaccount="ngModel" id="mailaccount" class="form-control">
                        <label for="mailaccount">Cuenta de Correo</label>
                        <mdb-error *ngIf="mailaccount.control.invalid && (mailaccount.control.dirty || mailaccount.control.touched)">

                          <ng-container *ngFor="let error of mailaccount.control.errors | keyvalue">
                            <ng-container [ngSwitch]="error.key">
                              <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                              <ng-container *ngSwitchCase="'email'">Formato de email Incorrecto</ng-container>
                              <ng-container *ngSwitchCase="'data_exists'">La cuenta introducida ya existe</ng-container>
                              <ng-container *ngSwitchDefault>Valor incorrecto</ng-container>
                            </ng-container>
                          </ng-container>
                        </mdb-error>
                    </div>
                  </div>
                </div> -->
     <!--         </mdb-card-body>
            </mdb-card>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
-->