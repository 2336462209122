import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MdbCheckboxChange, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { matchValueToArray } from 'src/app/shared/form-validators/custom-validators';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { UserInterface } from 'src/app/shared/interfaces/data/user-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { DropdownService } from '../../services/dropdown.service';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';

@Component({
  selector: 'app-new-mail-account',
  templateUrl: './new-mail-account.component.html',
  styleUrls: ['./new-mail-account.component.scss']
})
export class NewMailAccountComponent implements OnInit {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private subscriptions: Array<Subscription> = [];

  public loading: boolean; //spinner

  public optionsSelect = [
    { value: 1, label: 'Gmail' , button:'google'},
    { value: 2, label: 'Outlook', button:'microsoft' },
    { value: 3, label: 'Dedicado', button:'' },
  ];

  public type: number;

  public sending = false;


  public dropdowns: DropdownsInterface;
  private mailaccounts: Array<any>;
  private all_users: Array<UserInterface>;
  public available_users: Array<UserInterface>;
  public users: Array<UserInterface>=[];

  public formAccountData: FormGroup;

  private imap_ports = [
    {
      type: 'ssl',
      label:'SSL/TLS',
      ports: [
        993
      ]
    },
    {
      type: 'starttls',
      label: 'STARTTLS',
      ports: [
        143
      ]
    },
    {
      type: 'plain',
      label: 'Ninguna',
      ports: [
        143
      ]
    },
  ]

  private smtp_ports = [
    {
      type: 'ssl',
      ports: [
        465
      ]
    },
    {
      type: 'starttls',
      ports: [
        587
      ]
    },
    {
      type: 'plain',
      ports: [
        25
      ]
    },
  ]

  public filtered_imap_ports: Array<any>;
  public filtered_smtp_ports: Array<any>;

  public view_pass = false;

  modalRef: MDBModalRef | null = null;

  /**
   * Datos de la entidad usuario
   */
  public user_entity = this._entity.entities.user;

  constructor(private modalService: MDBModalService, private _api: ApirequestService, private _tabs: TabService, private _apifunctions: ApiFunctions, private _notification: NotificationService, private _buttons: ActionButtonsService, private fb: FormBuilder, private _entity: EntityService, private _dropdowns: DropdownService) { }

  ngOnInit(): void {
    if (!this.mailaccounts) {
      this.getDropdowns()
    }
  }


  private setFormAccount(){
    this.formAccountData = this.fb.group({
      mail:['', [Validators.required, Validators.email, matchValueToArray(this.mailaccounts.map(acc => {return acc.mail}))]],
      typemailaccount_id:[{value: 3, disabled: true}],
      imap_server:['',[Validators.required]],
      imap_port:[null,[Validators.required]],
      smtp_server:['',[Validators.required]],
      smtp_port:['',[Validators.required]],
      password:['',[Validators.required]],
      method:['ssl',[Validators.required]],
    })

    this.changePorts(this.formAccountData.controls.method.value);

    this.formAccountData.controls.method.valueChanges.subscribe(
      value => {
        this.changePorts(value);
      }
    )

    this.formAccountData.valueChanges.subscribe(
      value => {
        this.tab.modifiedData = true;
      }
    )

  }

  changePorts(type:string){
    this.filtered_imap_ports = this.imap_ports.find(ports => ports.type === type)?.ports;
    this.filtered_smtp_ports = this.smtp_ports.find(ports => ports.type === type)?.ports;

    this.formAccountData.controls.imap_port.setValue(this.filtered_imap_ports[0]);
    this.formAccountData.controls.smtp_port.setValue(this.filtered_smtp_ports[0]);

  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
  }

  async getDropdowns() {

    this.loading = true;
    this.dropdowns = await this._dropdowns.getDropdownsAPI_ASYNC(this.data.new_register_dropdowns)
    this.loading = false;
    this.setDropdowns();

    /* if(this._dropdowns.checkDropdownsLoaded(this.data.new_register_dropdowns)) {

      this.loading = true;
        this.subscriptions.push(
          this._dropdowns.dropdowns_sub.subscribe(
            response => {

              this.setDropdowns()


              this.loading = false;
            },
            error => {
              this.loading = false;
            }
          )
        );
    } else {
      //ya estan todos en memoria
      this.setDropdowns()
    } */
    /* this.loading = true;
    this.subscriptions.push(
      this._apifunctions.getDropdowns(['mailaccount', 'tramit']).subscribe(
        response => {
          this.mailaccounts = response.response.dropdown.mailaccount;
          this.all_users = response.response.dropdown.tramit;
          this.setUsers();
          this.setFormAccount();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    ); */
  }

  setDropdowns() {
    //this.dropdowns = this._dropdowns.getDropdownsObject(this.data.new_register_dropdowns)

    if(this.dropdowns) {
      this.mailaccounts = this.dropdowns.mailaccount;
      this.all_users = this.dropdowns.tramit;
      this.setUsers();
      this.setFormAccount();
    }

  }

  setUsers(){
    this.available_users = this.all_users.filter(
      user => {
        if(!this.users?.find(mail_user => mail_user.id == user.id)) {
          return true
        }
      }
    ).map(
      user => {
        user.default_mailaccount = false;
        return user;
      })

  }

  addUser(user: UserInterface) {
    this.users.push(user);
    this.setUsers()
    this.tab.modifiedData = true;
  }

  removeUser(user: UserInterface) {
    this.users.splice(this.users.findIndex(u=> u.id == user.id), 1);
    this.setUsers()
    this.tab.modifiedData = true;
  }

  onChangeDefault(event: MdbCheckboxChange, user: UserInterface) {
    user.default_mailaccount = event.checked;
  }

  changeType(ev) {

    this.setFormAccount();
    this.tab.modifiedData = false;

  }

  getSelectedType():any{
    return this.optionsSelect.find(option => option.value === this.type );
  }

  onSubmit() {

    switch (this.type) {
      case 3:
        this.formAccountData.markAllAsTouched()
        if(this.formAccountData.valid){
          this.dedicatedDomain();
        }

        break;
    }

  }

  public openMailProvider() {
    let url = '';
    let model = ''
    switch (this.type) {
      case 1:
        url = 'mailaccounts/gmail/api/url';
        model = 'gmailAPi'
        break;
      case 2:
        url = 'mailaccounts/outlook/api/url';
        model = 'outlookAPi'
        break;
      default:
        throw new Error('Incorrect Type')
        break;

    }

    this._buttons.setLoading(true)
    this.subscriptions.push(
      this._api.get(url).subscribe(
        resp => {
          let url = resp['response'][model]['url'];

          this._tabs.removeTabByTabId(this.tab.tabId);
          sessionStorage.setItem('mail_operation', 'new');
          sessionStorage.setItem('mail_id', '');
          window.location.href = url;

        },
        error => {
          this._buttons.setLoading(false)
        }
      )
    );

  }


  private dedicatedDomain() {
        this._buttons.setLoading(true)
        let data_values = {...this.formAccountData.value};
        const params = {
          mailaccount: {
            mail: data_values.mail,
            password: btoa(data_values.password),
            imap_server: data_values.imap_server,
            imap_port: data_values.imap_port,
            smtp_server: data_values.smtp_server,
            smtp_port: data_values.smtp_port,
            ssl: false,
            starttls: false
          }
        }
        switch (data_values.method) {
          case 'ssl':
            params.mailaccount.ssl = true;
            break;
          case 'starttls':
            params.mailaccount.starttls = true;
            break;
        }

        if(this.users.length > 0) {
          params['mail_account_users'] = this.users.map(it => {
            return {
              user_id: it.id,
              default: it.default_mailaccount
            }
          });
        }

        console.log(params)
        this.subscriptions.push(
          this._api.post('mailaccounts/dedicated/login', JSON.stringify(params)).subscribe(
            response => {
              console.log(response)
              let mailaccount = response.response.mailaccount;
              this._buttons.reloadListTab(this.data.saveEndpoint);
              this._buttons.setLoading(false)
              this._notification.notificacionOK(this._notification.getInsertMsg())

              let text = 'Cuenta creada correctamente, ¿desea acceder a su ficha?';
              if(!mailaccount.state) {
                text = 'Cuenta creada pero se han encontrado errores en los datos de conexión, ¿desea acceder a su ficha?'
              }
              //Abre el modal de apertura de registro
              this.modalRef = this.modalService.show(OpenRegisterComponent, {
                    keyboard: false,
                    ignoreBackdropClick: true,
                    class: 'modal-dialog',
                    data: {
                      heading: 'Cuenta Creada',
                      text: text,
                      register: mailaccount,
                      form_entity: 'mailaccount',
                    }
                  })

              this._tabs.removeTabByTabId(this.tab.tabId);
            },
            error => {
              console.log(error)
              this._buttons.setLoading(false)
            }
          )
        )
  }
}
