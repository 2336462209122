<app-loader *ngIf="loading"></app-loader>


<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
    <!--PESTAÑA INFORMACION-->
    <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
        <ng-container *ngIf="formExpert">
            <form [formGroup]="formExpert">
                <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        <div class="row">
                            <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Perito activo</b></mdb-checkbox>
                        </div>
                    </mdb-card-header>
                </mdb-card>
                <div class="row mt-2">
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                            <label for="identification_document" class="">NIF</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertname" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                            <label for="expertname" class="">Nombre</label>
                            <mdb-error *ngIf="formExpert.controls.name.invalid&& (formExpert.controls.name.dirty || formExpert.controls.name.touched)">
                                Campo obligatorio</mdb-error>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="expertsurname" formControlName="surname" class="form-control form-control-sm" placeholder="">
                            <label for="expertsurname" class="">Apellidos</label>
                            <mdb-error *ngIf="formExpert.controls.surname.invalid&& (formExpert.controls.surname.dirty || formExpert.controls.surname.touched)">
                                Debe introducir el apellido al dar acceso web</mdb-error>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="expertemail" formControlName="email" class="form-control form-control-sm" placeholder="">
                            <label for="expertemail" class="">Email</label>
                            <mdb-error *ngIf="formExpert.controls.email.invalid && (formExpert.controls.email.dirty || formExpert.controls.email.touched)">
                              <ng-container *ngFor="let error of formExpert.controls.email.errors | keyvalue">
                                <ng-container [ngSwitch]="error.key">
                                  <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                                  <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                                  <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>
  
                                </ng-container>
                              </ng-container>
  
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertphone" formControlName="phone" class="form-control form-control-sm" placeholder="">
                            <label for="expertphone" class="">Teléfono</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertmobile" formControlName="mobile" class="form-control form-control-sm" placeholder="">
                            <label for="expertmobile" class="">Móvil</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertaddress" formControlName="address"
                                class="form-control form-control-sm" placeholder="">
                            <label for="expertaddress" class="">Dirección</label>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertpopulation" formControlName="population"
                                class="form-control form-control-sm" placeholder="">
                            <label for="expertpopulation" class="">Población</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertprovince" formControlName="province"
                                class="form-control form-control-sm" placeholder="">
                            <label for="expertprovince" class="">Provincia</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="expertpostalcode" formControlName="postal_code"
                                class="form-control form-control-sm" placeholder="">
                            <label for="expertpostalcode" class="">Código Postal</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="md-form md-outline">
                            <textarea type="text" id="observations" rows="15" formControlName="observations" placeholder="" class="md-textarea form-control" mdbInput></textarea>
                            <label for="observations">Observaciones</label>
                        </div>
                    </div>

                </div>
            </form>
        </ng-container>
    </mdb-tab>
</mdb-tabset>
<!--
<div class="pt-2">
      <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

        <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
          <ng-container *ngIf="formExpert">
          <form [formGroup]="formExpert">
                  <div class="row">
                    <div class="col-lg-2">
                        <mdb-checkbox [default]="true" formControlName="enabled">Activo
                        </mdb-checkbox>

                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                            <label for="identification_document" class="">NIF</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="clientname" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                            <label for="clientname" class="">Nombre</label>
                            <mdb-error *ngIf="formExpert.controls.name.invalid&& (formExpert.controls.name.dirty || formExpert.controls.name.touched)">
                                Campo obligatorio</mdb-error>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="clientsurname" formControlName="surname" class="form-control form-control-sm" placeholder="">
                            <label for="clientsurname" class="">Apellidos</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="clientemail" formControlName="email" class="form-control form-control-sm" placeholder="">
                          <label for="clientemail" class="">Email</label>
                          <mdb-error *ngIf="formExpert.controls.email.invalid && formExpert.controls.email.errors.data_exists">El email introducido ya está en uso</mdb-error>
                          <mdb-error *ngIf="formExpert.controls.email.invalid && (formExpert.controls.email.dirty || formExpert.controls.email.touched)">
                              Introduce un email válido</mdb-error>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="clientphone" formControlName="phone" class="form-control form-control-sm" placeholder="">
                          <label for="clientphone" class="">Teléfono</label>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="clientmobile" formControlName="mobile" class="form-control form-control-sm" placeholder="">
                          <label for="clientmobile" class="">Móvil</label>
                      </div>
                  </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        <mdb-icon fad icon="home-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Dirección
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row">
                        <div class="col-lg">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeeaddress" formControlName="address"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeeaddress" class="">Dirección</label>
                            </div>
                        </div>

                        <div class="col-lg">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeepopulation" formControlName="population"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeepopulation" class="">Población</label>
                            </div>
                        </div>
                        <div class="col-lg">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeeprovince" formControlName="province"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeeprovince" class="">Provincia</label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-1">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeepostalcode" formControlName="postal_code"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeepostalcode" class="">Código Postal</label>
                            </div>
                        </div>
                    </div>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>
          </form>
          </ng-container>
        </mdb-tab>
      </mdb-tabset>

</div>
-->