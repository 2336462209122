import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MdbCheckboxChange, MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { DynamicListSelectionComponent } from '../../components/dynamic-list-selection/dynamic-list-selection.component';
import { ListSelectionComponent } from '../../components/dynamic-modals/list-selection/list-selection.component';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-new-expense',
  templateUrl: './new-expense.component.html',
  styleUrls: ['./new-expense.component.scss']
})
export class NewExpenseComponent implements OnInit {
  @Input() tab: Tab
  @Input() data: any

  @ViewChild('listSelection', { static: true }) listSelection!: DynamicListSelectionComponent;

  private subscriptions: Array<Subscription> = [];

  public loading = false;

  formNewExpense: FormGroup;

  private formFunctions = new FormFunctions;

  public hasClaim: boolean;

  public dropdowns: DropdownsInterface;

  modalRefClaim: MDBModalRef | null = null;
  modalRefOpenRegister: MDBModalRef | null = null;

  public claim_entity = this._entity.entities.claim;
  public user_entity = this._entity.entities.user;
  public client_entity = this._entity.entities.claim;
  public employee_entity = this._entity.entities.client;
  public company_entity = this._entity.entities.company;

  constructor(private modalService: MDBModalService, private _buttons: ActionButtonsService, private _apifunctions: ApiFunctions, private fb: FormBuilder,
     private _api: ApirequestService, private _notification: NotificationService, private _tabs: TabService, private _entity: EntityService, private _titleCasePipe: TitleCasePipe) {

  }

  ngOnInit(): void {
    this.loadDropdowns()

    this.formNewExpense = new FormGroup({
       claim_id: new FormControl(null, []),
       employee_id: new FormControl(null, []),
       claim_number: new FormControl({value:'', disabled: false}, []),
       code: new FormControl('', []),
       concept: new FormControl('', [Validators.required]),
       taxe_id: new FormControl(1, [Validators.required],),
       total_base: new FormControl(0, [Validators.required, Validators.min(0)]),
       total_taxe: new FormControl({value:0, disabled: true}, []),
       total: new FormControl({value:0, disabled: true}, []),
    })

    this.formNewExpense.controls.taxe_id.valueChanges.subscribe(
      val => {
        console.log(val)

        if(this.formNewExpense.controls.total_base.value >=0) {
          let total_iva = (+(this.formNewExpense.controls.total_base.value)*this.dropdowns.taxe.find(t => t.id == val).value)/100;
          this.formNewExpense.controls.total_taxe.setValue(total_iva.toFixed(2));

          let total_linea = (+this.formNewExpense.controls.total_base.value) + (+this.formNewExpense.controls.total_taxe.value);
          this.formNewExpense.controls.total.setValue(total_linea.toFixed(2));
        } else {
          this.formNewExpense.controls.total_taxe.setValue(0);
          this.formNewExpense.controls.total.setValue(0);
        }
    });

    this.formNewExpense.controls.total_base.valueChanges.subscribe(
      val => {
        if(val >= 0) {

          let total_iva = (+(this.formNewExpense.controls.total_base.value)*this.dropdowns.taxe.find(t => t.id == this.formNewExpense.controls.taxe_id.value).value)/100;
          this.formNewExpense.controls.total_taxe.setValue(total_iva.toFixed(2));

          let total_linea = (+this.formNewExpense.controls.total_base.value) + (+this.formNewExpense.controls.total_taxe.value);
          this.formNewExpense.controls.total.setValue(total_linea.toFixed(2));

        } else {
          this.formNewExpense.controls.total_taxe.setValue(0);
          this.formNewExpense.controls.total.setValue(0);
        }

      }
    )


    if(this.data.parentData) {
      this.hasClaim = true;
      this.formNewExpense.controls.claim_id.setValue(this.data.parentData.id);
      this.formNewExpense.controls.claim_id.markAsDirty();
      this.formNewExpense.controls.claim_number.setValue(this.data.parentData.number);
    }


    this.formNewExpense.valueChanges.subscribe(
      change => {
        this.tab.modifiedData = true;
      }
    )


    this.formNewExpense.controls.taxe_id.markAsDirty();
    this.formNewExpense.controls.total_base.markAsDirty();
    this.formNewExpense.controls.total_taxe.markAsDirty();
    this.formNewExpense.controls.total.markAsDirty();
  }

  //carga los datos de los desplegables
  loadDropdowns() {

    this.loading = true;
    this.subscriptions.push(
      this._apifunctions.getDropdowns(['taxe', 'employee']).subscribe(
        response => {
          this.dropdowns = response.response.dropdown;

          this.dropdowns.taxe = this.dropdowns.taxe.filter(e => e.enabled == true);
          this.dropdowns.employee = this.dropdowns.employee.filter(e => e.enabled == true);


          this.loading = false;
        },
        error => {

          this.formNewExpense.disable()
          this.loading = false;
        }
      )
    )
  }

  //check de expediente
  changeClaim(ev: MdbCheckboxChange){
    this.formNewExpense.controls.claim_id.markAsPristine();


    if(!this.hasClaim) {
      //si se desmarca el check de expediente se resetan los campos de expediente
      this.formNewExpense.controls.claim_id.setValue(null);
      this.formNewExpense.controls.claim_number.setValue('');
      this.formNewExpense.controls.claim_number.removeValidators([Validators.required])
    } else {
      //obligatorio seleccionar un expediente
      this.formNewExpense.controls.claim_number.addValidators([Validators.required])
    }


    this.formNewExpense.controls.claim_number.updateValueAndValidity()
  }

  selectClaim(){

    this.modalRefClaim = this.modalService.show(ListSelectionComponent, {
            keyboard: false,
            ignoreBackdropClick: true,
            class: 'modal-dialog modal-fluid',
            data: {
          modal_header: 'Seleccione un '+this.claim_entity.labels.form,
              list_headers: [
                'Nº Aviso',
                'Número',
                'Fecha encargo',
                'Proveedor',
                this._titleCasePipe.transform(this.client_entity.labels.form),
                'Dirección',
                'Tramitador',
              ],
              list_fields: [
                {entity: this.claim_entity.entity, field: 'number'},
                {entity: this.claim_entity.entity, field: 'number_provider'},
                {entity: this.claim_entity.entity, field: 'order_date'},
                {entity:  this.company_entity.entity, field: 'name'},
                {entity: this.client_entity.entity, field: 'name_complete'},
                {entity: 'policy', field: 'address_complete'},
                {entity: this.user_entity.entity, field: 'name'}
              ],
              list_entity: this.claim_entity.entity,
              list_endpoint: this.claim_entity.list_entity,
              list_extra_params: [
                {
                  name: 'clientaddress',
                  value: 'true'
                }
              ]
            }
        })

    //recoge los datos del expediente seleccionado
    this.modalRefClaim.content.selectedRegister.subscribe(register => {

      //datos expediente
      this.formNewExpense.controls.claim_id.setValue(register.id);
      this.formNewExpense.controls.claim_id.markAsDirty();
      this.formNewExpense.controls.claim_number.setValue(register.number);


    })

    //libera memoria del modal de selección de expediente
    this.modalService.closed.subscribe(() => this.modalRefClaim.content.onClosed())

  }

  public onSubmit(): void {

    this.formNewExpense.markAllAsTouched();

    if(this.formNewExpense.valid) {
      this._buttons.setLoading(true);

      let params ={};

      //datos gasto
      this.formFunctions.fillFormParams(this.formNewExpense, this.data.entity, params);

      console.log(params)
      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {

            let expense = response.response.expense;


            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
             this.modalRefOpenRegister = this.modalService.show(OpenRegisterComponent, {
                keyboard: false,
                ignoreBackdropClick: true,
                class: 'modal-dialog',
                data: {
                    heading: 'Registro Creado',
                    text: 'Registro creado correctamente, ¿desea acceder al registro?',
                    register: expense,
                    form_entity: this.data.entity,
                }
              })

              this._tabs.removeTabByTabId(this.tab.tabId)
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      )
    }



  }

  ngOnDestroy(): void {
    this.unsubscribeAll()
  }

  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
  }

}
