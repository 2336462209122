import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.scss']
})
export class TextModalComponent implements OnInit {
  heading: string | null = null;
  text: string = null;

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
  }

}
