import { ManagementComponent } from "src/app/main/sections/management/management.component"
import { Tab } from "src/app/main/tab-models/tab.model"

export class ManagementModel {
  tabData = {
    entity: 'management'
  }

  getTab(): Tab {
    return new Tab(
      ManagementComponent,
      "<i class='fas fa-user-cog mr-1'></i>Panel Gestión",
        {
            buttonNew: false,
            buttonDelete: false
        },
        this.tabData
    )
}
}

