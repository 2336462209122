<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
  <form [formGroup]="formNewInvoice">
    <div style="margin: 10px;">
      <div class="row mt-2">
        <div class="col-lg">
          <div class="row">
            <mdb-checkbox class="mt-2 ml-3" [default]="true" [disabled]="data.parentData" [(ngModel)]="hasClaim" [ngModelOptions]="{standalone: true}" (change)="changeClaim($event)"></mdb-checkbox>
            <div class="md-form md-outline">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [disabled]="!hasClaim || data.parentData" id="seleccionarExpediente" mdbWavesEffect (click)="selectClaim()">
                <mdb-icon fas icon="folder-plus" size="lg"></mdb-icon>
              </button>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <input type="text" name="claim_number" formControlName="claim_number" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                <label for="claim_number" class="">Expediente</label>
                <mdb-error *ngIf="formNewInvoice.controls.claim_number.invalid && (formNewInvoice.controls.claim_number.dirty || formNewInvoice.controls.claim_number.touched)">Seleccionar {{claim_entity.labels.form | titlecase}}</mdb-error>
              </div>
            </div>
            
            
          </div>
        </div>
        
        <div class="col-lg">
          <div class="row">
            <div class="md-form md-outline m-1">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" [disabled]="hasClaim" [mdbTooltip]="'Seleccionar '+(client_entity.labels.form | titlecase)" placement="top" id="seleccionarCliente" mdbWavesEffect (click)="selectClient()">
                <mdb-icon fas icon="user-check"></mdb-icon>
              </button>
            </div>
          
            <div class="md-form md-outline m-1">
              <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" mdbWavesEffect [disabled]="hasClaim" [mdbTooltip]="'Crear '+(client_entity.labels.form | titlecase)" placement="top" (click)="newClient()">
                <mdb-icon fas icon="user-plus"></mdb-icon>
              </button>
            </div>

            <div class="col-lg">

              <div class="md-form md-outline">
                <input type="text" name="client_name" formControlName="client_name" mdbValidate [validateSuccess]="false" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                <label for="client_name" class="">{{client_entity.labels.form | titlecase}}</label>
                <mdb-error *ngIf="formNewInvoice.controls.client_name.invalid && (formNewInvoice.controls.client_name.dirty || formNewInvoice.controls.client_name.touched)">Seleccionar {{client_entity.labels.form | titlecase}}</mdb-error>
              </div>
            </div>
            
          </div>
        </div>

        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" [placeholder]="'Elige un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName = "guild_id">
              <mdb-select-option *ngFor="let guild of dropdowns?.guild" [value]="guild.id">{{ guild.name }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+serie_entity.labels.form+':'" [label]="(serie_entity.labels.form |titlecase)" formControlName = "serie_id">
              <mdb-select-option *ngFor="let serie of dropdowns?.serie" [value]="serie.id">{{ serie.extend_description }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>

        
      </div>

    <!--LINEA 2proveedor-->
    <div class="row mt-3">
      
      <div class="col-lg">
        <div class="md-form md-outline">
          <ng-container *ngIf="hasClaim; else selectaddress">
            <div class="md-form md-outline" *ngIf="selected_claim_id">
              <input type="text" name="claim_address" formControlName="claim_address" class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
              <label for="claim_address">Dirección</label>
            </div>
          </ng-container>
          <ng-template #selectaddress>
            <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
              <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
            </mdb-select-2>
            <mdb-error *ngIf="formNewInvoice.controls.clientaddress_id.invalid && (formNewInvoice.controls.clientaddress_id.dirty || formNewInvoice.controls.clientaddress_id.touched)">Campo Obligatorio</mdb-error>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="hasClaim; else selectprovider">
        <div class="col-lg" *ngIf="selected_claim_id">
          <div class="md-form md-outline">
            <input type="text" name="provider_name" formControlName="provider_name"
            class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
            <label for="provider_name">Proveedor</label>
          </div>
        </div>
      </ng-container>
      <ng-template #selectprovider>
        <div class="col-lg">
          <div class="md-form md-outline">
            <mdb-select-2 [outline]="true" placeholder="" label="Tipo Proveedor" formControlName="typeprovider">
              <mdb-select-option *ngFor="let type of provider_types" [value]="type.id">{{ type.name }}</mdb-select-option>
            </mdb-select-2>
          </div>
        </div>
        <ng-container [ngSwitch]="selected_typeprovider">
          <ng-container *ngSwitchCase="1">
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+company_entity.labels.form+':'" [label]="(company_entity.labels.form | titlecase)" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                  <mdb-select-option *ngFor="let company of companies" [value]="company.value">{{ company.label }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formNewInvoice.controls.provider_id.invalid && (formNewInvoice.controls.provider_id.dirty || formNewInvoice.controls.provider_id.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" [placeholder]="'Elige una sub'+company_entity.labels.form+':'" [label]="'Sub'+company_entity.labels.form" formControlName="subprovider_id">
                  <mdb-select-option *ngFor="let company of subcompanies" [value]="company.value">{{ company.label }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                  <mdb-select-option *ngFor="let repairman of repairmen" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formNewInvoice.controls.provider_id.invalid && (formNewInvoice.controls.provider_id.dirty || formNewInvoice.controls.provider_id.touched)">
                  Campo Obligatorio
                </mdb-error>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <div class="col-lg">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                  <mdb-select-option *ngFor="let administrator of administrators" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
                </mdb-select-2>
                <mdb-error *ngIf="formNewInvoice.controls.provider_id.invalid && (formNewInvoice.controls.provider_id.dirty || formNewInvoice.controls.provider_id.touched)">Campo Obligatorio</mdb-error>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>

      
    </div>
    
    <!--LINEA 3 descripcion y pie-->
    <div class="row mt-3">
      <div class="col-lg">
        <div class="md-form md-outline">
          <textarea rows="3" type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
          <label for="description">Descripción</label>
        </div>
      </div>
      <div class="col-lg">
        <div class="md-form md-outline">
          <textarea rows="3"  type="text" id="footer" class="md-textarea form-control" placeholder="" mdbInput formControlName="footer"></textarea>
          <label for="footer">Pie</label>
        </div>
      </div>
    </div>
  </div>

  <!--LINEAS-->
  <div class="divCustomSection">
    <i class='fas fa-money-bill mr-2'></i> Líneas
  </div>
  <div style="margin: 10px;">
    <div class="row mt-3 ">

      <div class="col">
        <app-detail-lines #detailComponent [master]="master_object" [taxes]="dropdowns?.taxe" [details]="[]" [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="false" (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
      </div>
    </div>
  </div>
  
</form>


<!--<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">
  <form [formGroup]="formNewInvoice">
    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(claim_entity.labels.form | titlecase)+', '+(client_entity.labels.form | titlecase)+', Dirección, Proveedor'">
            <div class="row">
              <div class="col-md-2 mt-2">
                <div class="row">
                  <div class="col-1 custom-cell mt-2">
                    <mdb-checkbox [default]="true" [disabled]="data.parentData" [(ngModel)]="hasClaim" [ngModelOptions]="{standalone: true}" (change)="changeClaim($event)"></mdb-checkbox>
                  </div>
                  <div class="col">
                    <span class="character-counter">{{claim_entity.labels.form | titlecase}}</span>
                    <div class="md-form md-outline input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn-group" mdbDropdown>
                          <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2 z-depth-0" type="button" [disabled]="!hasClaim || data.parentData"
                          id="seleccionarExpediente" mdbWavesEffect (click)="selectClaim()">
                          <mdb-icon fas icon="folder-plus" size="lg"></mdb-icon>
                        </button>
                        </div>
                      </div>
                      <input type="text" name="claim_number" formControlName="claim_number" mdbValidate [validateSuccess]="false"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <mdb-error *ngIf="formNewInvoice.controls.claim_number.invalid && (formNewInvoice.controls.claim_number.dirty || formNewInvoice.controls.claim_number.touched)">
                      Seleccionar {{claim_entity.labels.form | titlecase}}
                    </mdb-error>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md mt-2">

                <div class="row">
                  <div class="col">
                    <span class="character-counter">{{client_entity.labels.form | titlecase}}</span>
                    <div class="md-form md-outline input-group mb-3">
                      <div class="input-group-prepend">
                        <div class="btn-group" mdbDropdown>
                          <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" [disabled]="hasClaim" [mdbTooltip]="'Seleccionar '+(client_entity.labels.form | titlecase)" placement="top"
                          id="seleccionarCliente" mdbWavesEffect (click)="selectClient()">
                          <mdb-icon fas icon="user-check"></mdb-icon>
                        </button>
                        </div>
                      </div>
                      <input type="text" name="client_name" formControlName="client_name" mdbValidate [validateSuccess]="false"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <mdb-error *ngIf="formNewInvoice.controls.client_name.invalid && (formNewInvoice.controls.client_name.dirty || formNewInvoice.controls.client_name.touched)">
                      Seleccionar {{client_entity.labels.form | titlecase}}
                    </mdb-error>
                    <div class="input-group-append" id="button-addon4">
                      <button mdbBtn color="primary" size="md" class="m-0 px-3 py-2" type="button" mdbWavesEffect [disabled]="hasClaim" [mdbTooltip]="'Crear '+(client_entity.labels.form | titlecase)" placement="top" (click)="newClient()">
                        <mdb-icon fas icon="user-plus"></mdb-icon>
                      </button>
                    </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-md mt-2">
                <div class="md-form md-outline">
                  <ng-container *ngIf="hasClaim; else selectaddress">
                    <div class="md-form md-outline" *ngIf="selected_claim_id">
                      <input type="text" name="claim_address" formControlName="claim_address"
                      class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                      <label for="claim_address">Dirección</label>
                    </div>
                  </ng-container>
                  <ng-template #selectaddress>
                    <mdb-select-2 [outline]="true" placeholder="Elige una dirección:" label="Dirección" formControlName="clientaddress_id" mdbValidate [validateSuccess]="false">
                      <mdb-select-option *ngFor="let address of addresses" [value]="address.id">{{ address.address_complete }}</mdb-select-option>
                    </mdb-select-2>
                    <mdb-error *ngIf="formNewInvoice.controls.clientaddress_id.invalid && (formNewInvoice.controls.clientaddress_id.dirty || formNewInvoice.controls.clientaddress_id.touched)">
                      Campo Obligatorio
                    </mdb-error>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <ng-container *ngIf="hasClaim; else selectprovider">
                <div class="col-md-3" *ngIf="selected_claim_id">
                  <div class="md-form md-outline">
                    <input type="text" name="provider_name" formControlName="provider_name"
                    class="form-control form-control-sm" mdbInput placeholder="" readonly="true" />
                    <label for="provider_name">Proveedor</label>
                  </div>
                </div>
              </ng-container>
              <ng-template #selectprovider>
                <div class="col-md-3">
                  <div class="md-form md-outline">
                    <mdb-select-2 [outline]="true" placeholder="" label="Tipo Proveedor" formControlName="typeprovider">
                      <mdb-select-option *ngFor="let type of provider_types" [value]="type.id">{{ type.name }}</mdb-select-option>
                    </mdb-select-2>
                  </div>
                </div>


                  <ng-container [ngSwitch]="selected_typeprovider">
                    <ng-container *ngSwitchCase="1">
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" [placeholder]="'Elige una '+company_entity.labels.form+':'" [label]="(company_entity.labels.form | titlecase)" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                            <mdb-select-option *ngFor="let company of companies" [value]="company.value">{{ company.label }}</mdb-select-option>
                          </mdb-select-2>
                          <mdb-error *ngIf="formNewInvoice.controls.provider_id.invalid && (formNewInvoice.controls.provider_id.dirty || formNewInvoice.controls.provider_id.touched)">
                            Campo Obligatorio
                          </mdb-error>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" [placeholder]="'Elige una sub'+company_entity.labels.form+':'" [label]="'Sub'+company_entity.labels.form" formControlName="subprovider_id">
                            <mdb-select-option *ngFor="let company of subcompanies" [value]="company.value">{{ company.label }}</mdb-select-option>
                          </mdb-select-2>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" placeholder="Elige un Reparador:" label="Reparador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                            <mdb-select-option *ngFor="let repairman of repairmen" [value]="repairman.id">{{ repairman.name }}</mdb-select-option>
                          </mdb-select-2>
                          <mdb-error *ngIf="formNewInvoice.controls.provider_id.invalid && (formNewInvoice.controls.provider_id.dirty || formNewInvoice.controls.provider_id.touched)">
                            Campo Obligatorio
                          </mdb-error>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                      <div class="col-md-4">
                        <div class="md-form md-outline">
                          <mdb-select-2 [outline]="true" placeholder="Elige un Administrador:" label="Administrador" formControlName="provider_id" mdbValidate [validateSuccess]="false">
                            <mdb-select-option *ngFor="let administrator of administrators" [value]="administrator.id">{{ administrator.name }}</mdb-select-option>
                          </mdb-select-2>
                          <mdb-error *ngIf="formNewInvoice.controls.provider_id.invalid && (formNewInvoice.controls.provider_id.dirty || formNewInvoice.controls.provider_id.touched)">
                            Campo Obligatorio
                          </mdb-error>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

              </ng-template>

            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>

      <div class="col-12 custom-card-padding mt-3">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab [heading]="(guild_entity.labels.form |titlecase) +', '+(serie_entity.labels.form |titlecase) +', Descripción, Pie (opcional)'">
            <div class="row">
              <div class="col-md">
                <mdb-select-2 [outline]="false" [placeholder]="'Elige un '+guild_entity.labels.form+':'" [label]="(guild_entity.labels.form |titlecase)" formControlName = "guild_id">
                  <mdb-select-option *ngFor="let guild of dropdowns?.guild" [value]="guild.id">{{ guild.name }}</mdb-select-option>
                </mdb-select-2>
              </div>
              <div class="col-md">
                <div class="md-form">
                  <mdb-select-2 [outline]="false" [placeholder]="'Elige una '+serie_entity.labels.form+':'" [label]="(serie_entity.labels.form |titlecase)" formControlName = "serie_id">
                    <mdb-select-option *ngFor="let serie of dropdowns?.serie" [value]="serie.id">{{ serie.extend_description }}</mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form">
                  <textarea type="text" id="description" class="md-textarea form-control" placeholder="" mdbInput formControlName="description"></textarea>
                  <label for="description">Descripción</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form">
                  <textarea type="text" id="footer" class="md-textarea form-control" placeholder="" mdbInput formControlName="footer"></textarea>
                  <label for="footer">Pie</label>
                </div>
              </div>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
      <div class="col-12">
        <app-detail-lines #detailComponent [master]="master_object" [taxes]="dropdowns?.taxe" [details]="[]"
        [headers]="detail_headers" [field_order]="detail_fields_order" [readonly]="false" (detailsChanged)="tab.modifiedData = true"></app-detail-lines>
      </div>
    </div>
  </form>
</div>
-->