import { Component, OnInit } from '@angular/core';
import { DynamicListComponent } from '../../components/dynamic-list/dynamic-list.component';

@Component({
  selector: 'app-series',
  templateUrl: './series.component.html',
  styleUrls: ['./series.component.scss']
})
export class SeriesComponent extends DynamicListComponent implements OnInit {


  /* ngOnInit(): void {
  } */

  editRegister(register): void{

    if(this.data.modal_form) {

      this.modalRefMaintenance = this.modalService.show(this.data.modal_form, {
              keyboard: false,
              ignoreBackdropClick: true,
              class: this.data.modal_class,
              data: {
                header: this.data.entity_label,
                register: register,
                entity: this.data.entity,
                endpoint: this.data.saveEndpoint
              }
            })

        this.subscriptions.push(
          this.modalService.closed.subscribe(() => this.modalRefMaintenance.content.onClosed())
        );

    } else {
      if(this.data.listParams?.form_entity) {
        this._tabs.addTab(this.data.listParams.form_entity, register);
      } else {
        this._tabs.addTab(this.data.entity, register);
      }
    }


  }

  selectRegister(registro,position) {
      if (this.selectedRow == position) {
        this.selectedRow = -1;
        this.register = null;
        this.tab.deleteButton = false;

      } else {
        this.register = registro;
        this.selectedRow = position;
        if(registro.id == 1) {
          this.tab.deleteButton = false;
        } else {
          this.tab.deleteButton = true;
        }
      }


  }

onContextMenu(event: MouseEvent, registro,pos) {

  event.preventDefault();
    this.selectedRow = pos;
    //this.register = registro;

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': registro };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();

}

}
