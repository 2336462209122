import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subscription, Subject } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ActionButtonsService } from 'src/app/main/services/action-buttons.service';
import { DropdownService } from 'src/app/main/services/dropdown.service';
import { EntityService } from 'src/app/main/services/entity.service';
import { NotificationService } from 'src/app/main/services/notification.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  providers:[DatePipe]
})
export class TaskComponent implements OnInit {
  header: string = 'Datos de ';
  register:any;
  entity: string;
  endpoint: string;
  typecondition: Array<any>;
  tramits: Array<any>;
  claim:any | null;

  private subscriptions: Array<Subscription> = [];
  private utils = new PGAUtils;

  public formTask: FormGroup;

  private id: number|null;

  private dateformats = new DateFormats;
  public datepicker_options = this.dateformats.opcionesMDBDatePicker;

  reload = new Subject<boolean>();

  public loading: boolean;

  public claim_entity = this._entity.entities.claim;
  public task_entity = this._entity.entities.task;

  constructor(public modalRef: MDBModalRef, private _api: ApirequestService,  private _dropdowns: DropdownService, private datePipe: DatePipe,private _notification: NotificationService, private fb: FormBuilder, private _buttons: ActionButtonsService, private _entity: EntityService){
    this.header += this.task_entity.labels.form
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(){

    if(this.typecondition) {
      this.formTask = this.fb.group({
        typecondition_id: [this.typecondition[0].id],
        description:[null, Validators.required],
        user_destiny_id:[null, Validators.required],
        date: [this.dateformats.getCurrentDate()],
        time: [this.dateformats.getCurrentTime()],
      });

      if(this.register) {
        this.id = this.register.id;

        Object.keys(this.formTask.controls).forEach(k => {
          if(this.register[k]){
            this.formTask.controls[k].setValue(this.register[k])
          }
        });

        if(this.register.date_time) {
          this.formTask.controls.date.setValue(this.datePipe.transform(this.register.date_time, 'dd/MM/yyyy'));
          this.formTask.controls.time.setValue(this.datePipe.transform(this.register.date_time, 'HH:mm'));
        }

        console.log(this.formTask)

      }
    } else {
      if(this.register) {
        if(this.register.claim) {
          this.claim = {
            id: this.register.claim.id,
            number: this.register.claim.number,
          }
        }

      }

      this.formTask = this.fb.group({
        typecondition_id: [null, Validators.required],
        description:[null, Validators.required],
        user_destiny_id:[null, Validators.required],
        date: [this.dateformats.getCurrentDate()],
        time: [this.dateformats.getCurrentTime()],
      });
      this.loading = true;

      this.subscriptions.push(
        this._dropdowns.getDropdownsAPI_Observable(this.task_entity.form_tabData.edit_register_dropdowns).subscribe(
          response => {
            this.typecondition = this.utils.objectDeepCopy(response.response.dropdown.typecondition);
            this.formTask.controls.typecondition_id.setValue(this.typecondition[0].id);
            this.tramits = this.utils.objectDeepCopy(response.response.dropdown.tramit);


            if(this.register) {
              this.id = this.register.id;

              Object.keys(this.formTask.controls).forEach(k => {
                if(this.register[k]){
                  this.formTask.controls[k].setValue(this.register[k])
                }
              });

            }

            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        )
      );
    }

  }

  submit(): void{

    this.formTask.markAllAsTouched();

    if(this.formTask.valid) {
      const formValues = {...this.formTask.value};
      const params: {task: any} = {
        task: {
          typecondition_id: formValues.typecondition_id,
          user_destiny_id: formValues.user_destiny_id,
          description: formValues.description,
          date_time: null
        }
      }

      if(formValues.date && formValues.time != '') {
        params.task.date_time = this.dateformats.formatoSave(formValues.date)+' '+formValues.time
      }

      this.loading = true;
      if(this.register) {
        this.updateRegister(params);
      } else {
        if(this.claim) {
          params.task.claim_id = this.claim.id;
        }
        this.newRegister(params);
      }
    }


  }

  newRegister(params){
    this.subscriptions.push(
      this._api.post(this.endpoint, JSON.stringify(params)).subscribe(
        response => {
          this._notification.getInsertMsg();

          if(this.claim) {
            this.reload.next(true);
          } else {
            this._buttons.reloadListTab(this.endpoint);
          }

          this.modalRef.hide();

          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  updateRegister(params){
    this.subscriptions.push(
      this._api.put(this.endpoint+'/'+this.id, JSON.stringify(params)).subscribe(
        response => {
          this._notification.notificacionOK(this._notification.getUpdateMsg());
          this.reload.next(true);
          this.modalRef.hide();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  unsubscribeAll(): void{
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onClosed(){
    this.unsubscribeAll();
  }
}
