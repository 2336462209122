export class EntitiesAlerts {

  private alert_messages = {
    claims_license: 'Ha llegado al nº máximo de Expedientes',
    workorders_license: 'Ha llegado al nº máximo de Partes',

  }

  getAlertMessage(alert:string): string {
    return this.alert_messages[alert];
  }

}
