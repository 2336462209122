import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { OpenRegisterComponent } from '../../components/dynamic-modals/open-register/open-register.component';
import { EmployeeGuildsComponent } from '../../components/employee-guilds/employee-guilds.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { NotificationService } from '../../services/notification.service';
import { TabService } from '../../services/tab.service';
import { Tab } from '../../tab-models/tab.model';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../../services/dropdown.service';

@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.scss']
})
export class NewEmployeeComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  @ViewChild('guildsComponent', {static: true}) guildsComponent: EmployeeGuildsComponent

  public loading: boolean = false;
  public formEmployee: FormGroup;

  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;
  private utils = new PGAUtils;

  public heading = "<i class='fas fa-info-circle'></i> Datos Personales";


  public dropdowndata: DropdownsInterface;

  modalRef: MDBModalRef | null = null;
  constructor(private modalService: MDBModalService, private _dropdowns: DropdownService,private _tabs: TabService,private _api: ApirequestService, private _buttons: ActionButtonsService, private _notification: NotificationService, private fb: FormBuilder) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  ngOnInit(): void {
    this.createForm();
    this.getDropdowns();
  }

  getDropdowns(){
    this.loading = true;
    this.subscriptions.push(
      this._dropdowns.getDropdownsAPI_Observable(this.data.new_register_dropdowns).subscribe(
        response => {
          let data = this.utils.objectDeepCopy(response.response.dropdown);
          data.guild = data.guild.filter(guild => guild.enabled == true);
          this.dropdowndata = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  createForm() {
    this.formEmployee = this.fb.group({
      identification_document: [null],
      name: [null, [Validators.required]],
      surname: [null],
      address: [null],
      email: [null,[Validators.email]],
      phone: [null],
      mobile: [null],
      postal_code: [null],
      province: [null],
      population: [null],
    });

    this.subscriptions.push(
      this.formEmployee.valueChanges.subscribe(
        () => {
          this.tab.modifiedData = true;
        }
      )
    );
  };

  public onSubmit(): void {
    this.formEmployee.markAllAsTouched();

    if (this.formEmployee.valid) {
      this._buttons.setLoading(true);

      const params = {};
      this.formFunctions.fillFormParams(this.formEmployee,this.data.entity, params);

      if(this.guildsComponent.id_default) {
        params[this.data.entity]['guild_default'] = +this.guildsComponent.id_default;
      }
      params[this.data.entity]['guilds'] = this.guildsComponent.employee_guilds.map(guild => {return guild.id});

      this.subscriptions.push(
        this._api.post(this.data.saveEndpoint, JSON.stringify(params)).subscribe(
          response => {
            let employee = response.response.employee
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(this._notification.getInsertMsg())

            //Abre el modal de apertura de registro
              this.modalRef = this.modalService.show(OpenRegisterComponent, {
                keyboard: false,
                ignoreBackdropClick: true,
                class: 'modal-dialog',
                data: {
                  heading: 'Registro Creado',
                  text: 'Registro creado correctamente, ¿desea acceder al registro?',
                  register: employee,
                  form_entity:  this.data.entity,
                }
              })

              this._tabs.removeTabByTabId(this.tab.tabId)

          },
          err => {
            this._buttons.setLoading(false);
          }
        )
      );
    }
  }

}
