import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CollapseComponent } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { AppConfigService } from '../services/app-config.service';
import { NotificationService } from '../services/notification.service';
import { Tab } from '../tab-models/tab.model';
import { TableComunicationsComponent } from './table-comunications/table-comunications.component';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { DropdownService } from '../services/dropdown.service';

@Component({
  selector: 'app-comunications-protocols',
  templateUrl: './comunications-protocols.component.html',
  styleUrls: ['./comunications-protocols.component.scss']
})
export class ComunicationsProtocolsComponent implements OnInit, OnDestroy {
  @Input() tab: Tab
  @Input() data: any


  @ViewChild('t1', { static: false }) t1: TableComunicationsComponent;
  @ViewChild('t2', { static: false }) t2: TableComunicationsComponent;
  @ViewChild('t3', { static: false }) t3: TableComunicationsComponent;


  @ViewChild('received_filters', { static: false }) received_filters: CollapseComponent;
  @ViewChild('sent_filters', { static: false }) sent_filters: CollapseComponent;

  private subscriptions: Array<Subscription> = [];
  private dropdowns_subscription: Subscription;
  private download_subscription: Subscription;

  public loading: boolean = true;
  public downloading_comunications: boolean;
  public comunications: Array<any>;

  public active_tab: number;

  private filters: any;

  public table_params: {
    comunications: Array<any>,
    total: number,
    pag_actual: number,
    limit: number,
    protocol_options: Array <any>
    tab?:number
  }

  public total = 0;
  public pag_actual = 0;

  private dateFunctions = new DateFormats;
  private utils = new PGAUtils;

  public protocols_options: Array<any> = [{id: -1, name:'--TODOS--'}];

  public protocols_enum: Array<any>;

  public access_type_protocol: FormControl;

  public comunications_log:string;

  private default_date_filter = {
    date : {
      from: this.dateFunctions.formatoSave(this.dateFunctions.getLastWeekDate()),
      to: this.dateFunctions.formatoSave(this.dateFunctions.getCurrentDate()),
    }
  }

  constructor(private _api: ApirequestService, private _dropdowns: DropdownService, private _appconfig: AppConfigService, private _notification: NotificationService) { }
  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  unsubscribeAll(): void {
    this.download_subscription?.unsubscribe()
    this.dropdowns_subscription?.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit(): void {
    this.getDropdowns()
    this.setDateFilter();
  }

  getDropdowns(){
    this.protocols_enum = this._appconfig.getProtocolsEnum();
    this.dropdowns_subscription = this._dropdowns.getDropdownsAPI_Observable(['protocol']).subscribe(
        response => {
          let data = this.utils.objectDeepCopy(response.response.dropdown.protocol);
          this.protocols_options = [...[{id: -1, name:'--TODOS--'}],...data];
        }
      )

  }



  setDateFilter() {
    this.filters = {
      [this.data.entity]: {
        date: {
          from: this.dateFunctions.formatoSave(this.dateFunctions.getCurrentDate()),
          to: this.dateFunctions.formatoSave(this.dateFunctions.getCurrentDate()),
        }
      }
    }
  }


  setFilters(ev: any) {
   console.log(ev)
    this.filters[this.data.entity] = ev;
    this.setTabFilters(this.active_tab);

    this.loadComunications()
  }

  openFilters() {
    /* switch (this.active_tab) {
      case 0:
          this.received_filters.toggle();
        break;
      case 1:
         this.sent_filters.toggle();
         break;

      default:
        break;
    }
 */
  }

  setTabFilters(tab: number){
      switch (tab) {
          case 0:
          this.filters[this.data.entity].ignored = 0;
          this.filters[this.data.entity].received = 1;
          this.filters[this.data.entity].sent = 0;
          this.filters[this.data.entity].processed = 0;
          break;
          case 1:
          this.filters[this.data.entity].received = 1;
          this.filters[this.data.entity].sent = 0;
          break;
          case 2:
            this.filters[this.data.entity].received = 0;
            this.filters[this.data.entity].sent = 1;
            break;

          default:
            break;
        }
  }

  onGetActiveTab(e: any){
    if(e) {
      if(this.active_tab != e.activeTabIndex) {
        this.comunications = [];
        this.total = 0;
        this.pag_actual = 0;
        this.setTableParams(true);
        this.setDateFilter()
        this.subscriptions.forEach(s => s.unsubscribe());
       // this.unsubscribeAll();
        switch (e.activeTabIndex) {
          case 0:
          case 1:
          case 2:
            this.table_params.tab = e.activeTabIndex;
            this.setTabFilters(e.activeTabIndex);
            this.loadComunications();
            break;

          case 3:
            this.access_type_protocol = new FormControl('all');
            break;
          default:
            break;
        }
      }


      this.active_tab = e.activeTabIndex;
    }


  }

  setTableParams(init?: boolean){
    if(init) {
      this.table_params = {
        comunications: this.comunications,
        total: this.total,
        pag_actual: this.pag_actual,
        limit: this.data.listParams.limit,
        protocol_options: this.protocols_options
      }
    } else {
      this.table_params.comunications = this.comunications;
      this.table_params.total = this.total;
      this.table_params.pag_actual = this.pag_actual;
      this.table_params.limit = this.data.listParams.limit;
      this.table_params.protocol_options = this.protocols_options
    }
  }


  loadComunications(page?:string){
    this.loading = true;

    let newurl = new URL(this._api.getAPIEndpoint()+this.data.listParams.list_entity)

    //Limit
    //newurl.searchParams.set('limit', this.data.listParams.limit)
    if(page){
      newurl.searchParams.append('page', page)
    }

    //Orden
    /* let order = {
      field: 'id',
      type:'DESC'
    };
    newurl.searchParams.append('order', JSON.stringify(order)) */

    newurl.searchParams.append('filters', JSON.stringify(this.filters));



    console.log(this.filters)

    this.subscriptions.push(
      this._api.get(this.data.listParams.list_entity+'/listwithemails'+newurl.search).subscribe(
        response => {
          this.comunications = response.response[this.data.entity].data;
          this.total = response.response[this.data.entity].total;
          this.pag_actual = response.response[this.data.entity].current_page -1;


          this.setTableParams();
          this.loading = false;
        },
        error => {
          this.comunications = [];
          this.setTableParams();

          this.loading = false;
        }
      )
    );
  }

  changePage(pag){
    console.log(pag)
    let p = pag.pageIndex + 1;
    this.loadComunications(p.toString())
  }

  processProtocol(protocol_name: string) {

    let process_protocol = 'protocols/process/';

    this.loading = true;
    this.subscriptions.push(
      this._api.get(process_protocol+protocol_name).subscribe(
        response => {
          this.t1.clearSelected();
          this.loadComunications();
        },
        error => {
          this.loading = false;
        }
      )
    );
  }

  private getTableComponent(): TableComunicationsComponent {
    let component: TableComunicationsComponent;

    switch (this.active_tab) {
      case 0:
        component = this.t1;
        break;
      case 1:
        component = this.t2;
        break;
      case 2:
        component = this.t3;
        break;
    }

    return component;
  }

  ignoreComunication(comunication, unignore?:boolean) {



    switch (comunication.model_name) {
      case 'email':
        this.loading = true;
        let ignore = 1;
        if(unignore) {
          ignore = 0;
        }
        const params = {
          email: {
            ignored: ignore
          }
        }
        this.subscriptions.push(
          this._api.put('emails/' + comunication.id, JSON.stringify(params)).subscribe(
            response => {
              this.getTableComponent().clearSelected();
              this.loadComunications();
            },
            error => {
              this.loading = false;
            }
          )
        );
        break;

      case 'comunicationprotocol':
        break;
    }
  }

  downloadComunications(){
    if(!this.downloading_comunications) {
      this.comunications_log = '';

      this.downloading_comunications = true;
      this.tab.tabLock = true;
      this.download_subscription =
      this._api.get('protocols/download/'+this.access_type_protocol.value).subscribe(
        response => {
          this.downloading_comunications = false;
          this.tab.tabLock = false;
          this.comunications_log = this.dateFunctions.getCurrentDate() + ' ' + this.dateFunctions.getCurrentTime() + '\n';
          this.comunications_log += response.response.protocol;
          //this._notification.notificacionOK(response.response.protocol, 'Descarga Completada')
        },
        error => {
          this.downloading_comunications = false;
          this.tab.tabLock = false;
        }
      )
    }
  }

}
