import { InvoicingComponent } from "src/app/main/sections/invoicing/invoicing.component"
import { Tab } from "src/app/main/tab-models/tab.model"

export class InvoicingModel {
  tabData = {
    entity: 'invoicing',
    entity_modules: ['billing'],
  }

  getTab(): Tab {
    return new Tab(
      InvoicingComponent,
      "<i class='fas fa-file-invoice-dollar mr-1'></i>Panel Facturación",
      {
          buttonNew: false,
          buttonDelete: false
      },
      this.tabData
    )
}
}
