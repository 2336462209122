import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { TabService } from 'src/app/main/services/tab.service';

@Component({
  selector: 'app-open-register',
  templateUrl: './open-register.component.html',
  styleUrls: ['./open-register.component.scss']
})
export class OpenRegisterComponent implements OnInit {
  heading: string | null = null;
  text: string = null;
  register: any = null;
  form_entity: string = null;
  form_entity_type: number = null;


  constructor(public modalRef: MDBModalRef, private _tabs: TabService) { }

  ngOnInit(): void {
  }

  open(open: boolean){
    if(open) {
      if(this.form_entity_type) {
        this._tabs.addTab(this.form_entity, this.register, this.form_entity_type);
      } else {
        this._tabs.addTab(this.form_entity, this.register);
      }
    }

    this.modalRef.hide();
  }

}
