import { ComponentRef, Type } from '@angular/core';


export class Tab {
/**
 * tabId: identificador único de la pestaña: la posición de la pestaña en el momento de la creación
**/
public tabId: number;

/**
 * tabHeader: cabecera de la pestaña
**/
public tabHeader: string;

/**
 * tabData: parámetros que se le pasan al componente renderizado en la pestaña
 */
public tabData: any;

/**
 * tabActive: indica si la pestaña está activa
 */
public tabActive: boolean;

/**
 * tabLock: indica si la pestaña está bloqueada
 */
public tabLock: boolean;

/**
 * tabComponent: componente que renderizará el cuerpo de la pestaña
 */
public tabComponent: Type<any>;

/**
 * tabComponentInstance: instancia del componente: se usará para acceder a las funciones del componente cada pestaña
 */
public tabComponentRef: ComponentRef<any>;

/**
 *
 */
public searchButton: boolean

public newButton: boolean

/**
 *
 */
public deleteButton: boolean

/**
 * modifiedData: indica que se ma modificado los datos del formulario de la pestaña
 */
public modifiedData: boolean

/**
 *
 * @param component Componente que renderizará la pestaña
 * @param header Cabecera de la pestaña
 * @param actionButtons indica si se muestran los botones de Acción al seleccionar la pestaña
 * @param tabData Datos que se pasan al componente
 */
constructor(component: Type<any>, header: string, actionButtons: actionButtonsInterface, tabData: any) {
    this.tabData = tabData;
    this.tabComponent = component;
    this.tabHeader = header;
    this.newButton = actionButtons.buttonNew;
    this.deleteButton = actionButtons.buttonDelete;
    this.searchButton = actionButtons?.buttonSearch

    this.modifiedData = false;
  }
}

/**
 * buttonNew: muestra el botón de nuevo registro al seleccionar la pestaña
 * buttonDelete: muestra el botón de eliminar registro al seleccionar la pestaña
 */
interface actionButtonsInterface {
  buttonNew: boolean,
  buttonDelete: boolean,
  buttonSearch?:boolean
}
