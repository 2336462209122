import { ExpertFilters } from "../../data-classes/filters/expert-filters";
import { EntityBaseModel } from "./entity-base.model";

export class ExpertModel extends EntityBaseModel {

  protected readonly icon = {
    form: 'user-tie',
    list: 'user-tie'
  }

  setModelData(models:any) {

    models.expert.icon = this.icon;

    models.expert.form_tabData = {
      entity: models.expert.entity,
      saveEndpoint: models.expert.saveEndpoint,
      entity_permissions: models.expert.permissions,
      entity_label: models.expert.labels.form,
      new_register_dropdowns: [],
      edit_register_dropdowns: []
    }

    models.expert.list_tabData = {
      entity: models.expert.entity,
      entity_label: models.expert.labels.form,
      entity_permissions:models.expert.permissions,
      listParams: {
        list_entity: models.expert.list_entity,
        list_label: models.expert.labels.list,
          limit: 15,
          headers: [
            'Activo',
            'Nombre',
            'NIF',
            'Dirección',
            'Teléfono',
            'Móvil',
            'Email',
            'Población',
            'Provincia',
            'Código postal',
          ],
          fields: [
            { entity: models.expert.entity, field: 'enabled', type: 'check'},
            { entity: models.expert.entity, field: 'name_complete' },
            { entity: models.expert.entity, field: 'identification_document' },
            { entity: models.expert.entity, field: 'address_complete' },
            { entity: models.expert.entity, field: 'phone' },
            { entity: models.expert.entity, field: 'mobile' },
            { entity: models.expert.entity, field: 'email' },
            { entity: models.expert.entity, field: 'population' },
            { entity: models.expert.entity, field: 'province' },
            { entity: models.expert.entity, field: 'postal_code' },
          ],
          web_access: true,
          disable_user: false,
          filters: new ExpertFilters(models)
      }
    }

  }
}
