import { ProviderFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";

export class RepairmanModel extends EntityBaseModel {

  protected icon ={
    form: 'tools',
    list: 'tools'
  }

  setModelData(models:any) {
    models.repairman.icon = this.icon;

    models.repairman.form_tabData = {
      entity: models.repairman.entity,
      entity_type: models.repairman.entity_type,
      saveEndpoint: models.repairman.saveEndpoint,
      entity_permissions: models.repairman.permissions,
      entity_modules:models.repairman.modules,
      entity_label: models.repairman.labels.form,
      new_register_dropdowns: [],
      edit_register_dropdowns: []
    }

    models.repairman.list_tabData = {
      entity: models.repairman.entity,
      entity_type: models.repairman.entity_type,
      entity_label: models.repairman.labels.form,
      entity_permissions:models.repairman.permissions,
      entity_modules:models.repairman.modules,
      listParams: {
        list_entity: models.repairman.list_entity,
        list_label: models.repairman.labels.list,
        form_entity: 'repairman',
        limit: 10,
        headers: [
          'Activo',
          'CIF',
          'Nombre',
          'Teléfono',
          'Móvil',
          'Email',
        ],
        fields: [
          { entity: models.repairman.entity, field: 'enabled', type: 'check' },
          { entity: models.repairman.entity, field: 'cif' },
          { entity: models.repairman.entity, field: 'name' },
          { entity: models.repairman.entity, field: 'phone' },
          { entity: models.repairman.entity, field: 'mobile' },
          { entity: models.repairman.entity, field: 'email' },
        ],
        filter_firm: false,
        web_access: false,
        disable_user: false,
        filters: new ProviderFilters(models)
    }


    }
  }

}
