

export class LiquidationFilters {

  public staticFilters;
  public optionalFilters;

  constructor(private entities){


    this.staticFilters = [
      {
        entity: this.entities.liquidation.entity,
        field: {
          name: 'code',
          type: 'text',
          label: 'Código',
          width: 3
        }
      },
      {
        entity: this.entities.employee.entity,
        field: {
          name: 'name',
          type: 'text',
          label: 'Empleado',
          width: 3
        }
      },
      {
        entity: this.entities.liquidation.entity,
        field: {
          name: 'type_state_id',
          type: 'select',
          label: 'Situación',
          width: 2,
          options: [
            {
              value:  -1,
              text: 'Todas'
            },
            {
              value:  1,
              text: 'Pendiente'
            },
            {
              value:  2,
              text: 'Semipagado'
            },
            {
              value:  3,
              text: 'Pagado'
            }
          ]
        }
      },
      {
        entity: this.entities.liquidation.entity,
        field: {
          name: 'created_at',
          type: 'date',
          label: 'Creado',
          width: 2,
          from: true
        }
      },
    ]


  }
}

