<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid">
    <app-dynamic-list-filters #dynamicFilters [entityFilters]="data.listParams.filters" (emitFilters)="applyFilters($event)"></app-dynamic-list-filters>

    <!--Table-->
    <div class="table-responsive" style="min-height:500px">
        <table mdbTable mdbTableScroll scrollY="true" maxHeight="100" #tableEl="mdbTable" class="z-depth-1">
            <!--Table head-->
            <thead>
                <tr class="blue darken-1 white-text">
                    <th class="header2">Estado</th>
                    <th class="header2">Cuenta de correo</th>
                    <th class="header2">Proveedor</th>
                    <th class="header2">Servidor IMAP</th>
                    <th class="header2">Puerto IMAP</th>
                    <th class="header2">Servidor SMTP</th>
                    <th class="header2">Puerto SMTP</th>
                    <th class="header2">Situación</th>
                </tr>
            </thead>
            <!--Table head-->

            <!--Table body-->
            <tbody>
                <app-spinner *ngIf="linking"></app-spinner>
<!--                 <ng-container *ngIf="loading">
                    <tr>
                        <td [colSpan]="4">
                            <div class="text-center">
                                <mdb-spinner spinnerType="big" spinnerColor="blue"></mdb-spinner>
                            </div>
                        </td>
                    </tr>
                </ng-container> -->

                <!-- FILAS -->
                <ng-container *ngFor="let mailaccount of datos; let i=index;">
                    <tr class="filas" (click)="selectMail(mailaccount, i)"
                        (dblclick)="editRegister(mailaccount)"
                        (contextmenu)="onContextMenuUser($event,mailaccount,i)" [class.selected]="i == selectedRow">
                        <td>
                            <ng-container *ngIf="mailaccount.enabled == 1; else notActive">
                              <mdb-icon far icon="check-square"></mdb-icon>
                            </ng-container>
                            <ng-template #notActive>
                              <mdb-icon far icon="square"></mdb-icon>
                            </ng-template>
                          </td>

                        <td>{{mailaccount.mail}}</td>

                        <td>{{mailaccount.typemailaccount.name}}</td>

                        <td>
                            <ng-container *ngIf="mailaccount.imap_server != null; else apiServer">
                                <label>{{mailaccount.imap_server}}</label>
                            </ng-container>
                            <ng-template #apiServer>
                                <label>Automático</label>
                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="mailaccount.imap_port != null; else apiServer">
                                <label>{{mailaccount.imap_port}}</label>
                            </ng-container>
                            <ng-template #apiServer>
                                <label>Automático</label>
                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="mailaccount.smtp_server != null; else apiServer">
                                <label>{{mailaccount.smtp_server}}</label>
                            </ng-container>
                            <ng-template #apiServer>
                                <label>Automático</label>
                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="mailaccount.smtp_port != null; else apiServer">
                                <label>{{mailaccount.smtp_port}}</label>
                            </ng-container>
                            <ng-template #apiServer>
                                <label>Automático</label>
                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="mailaccount.state; else error">
                              <mdb-icon fas icon="check" class="green-text" aria-hidden="true">{{ mailaccount.state_description }}</mdb-icon>
                              <label class="ml-1">{{ mailaccount.state_description }}</label>
                            </ng-container>
                            <ng-template #error>
                                <mdb-icon fas icon="bug" class="red-text" aria-hidden="true" mdbTooltip="{{ mailaccount.state_description }}" placement="left"></mdb-icon>
                                <label class="ml-1">Error en la configuración</label>
                            </ng-template>
                        </td>



                    </tr>
                </ng-container>
            </tbody>
            <!--Table body-->
            <tfoot class="grey lighten-5 w-100">
                <tr>
                    <td></td>
                    <td [colSpan]="6">
                        <mat-paginator #paginator style="float: left" [length]="total" [pageIndex]="pag_actual" [pageSize]="[data.listParams.limit]" [pageSizeOptions]="[data.listParams.limit]" [showFirstLastButtons]="true" (page)="changePage($event)">
                        </mat-paginator>
                    </td>
                </tr>
            </tfoot>
        </table>
        <!--Table-->
    </div>
    <!--Menú contextual en las filas de la tabla-->

    <!--Menú contextual en las filas de la tabla-->
    <div style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="editRegister(item)" *appPermissions="data.entity_permissions?.modify"><mdb-icon fas icon="pencil-alt" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Editar Registro</button>
<!--             <button mat-menu-item (click)="linkAccount(item)" *ngIf="can_modify && item.state !== 'En funcionamiento'"><mdb-icon fas icon="at" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Volver a vincular</button>
 -->        </ng-template>
    </mat-menu>
</div>
