import { Injectable } from '@angular/core';
import { UserDataInterface } from 'src/app/shared/interfaces/data/user-data-interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user_data: UserDataInterface;

  constructor() { }



  setUserData(value: UserDataInterface){
    this.user_data = value;
  }

  getUserData(): UserDataInterface {
    return this.user_data;
  }

  reset(){
    this.user_data = null;
  }
}
