import { Component, OnInit } from '@angular/core';
import { LicenseDataInterface } from 'src/app/shared/interfaces/license/license-data-interface';
import { LicenseService } from '../services/license.service';
import { ModulesService } from '../services/modules.service';
import { TabService } from '../services/tab.service';
import { EntityService } from '../services/entity.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

  //Datos de la licencia
  public license_data: LicenseDataInterface;

  //Datos entidades
  public claim_entity = this._entity.entities.claim;
  public workorder_entity = this._entity.entities.workorder;

  constructor(private _tabs: TabService, private _license: LicenseService, private _modules: ModulesService, private _entity: EntityService) {

    //obtiene la información de la licencia del servicio y queda escuchando cambios
    this.license_data = this._license.getLicenseData();
    this._license.licenseDataSub.subscribe(
      data => {
        this.license_data = data;
      }
    )


  }

  ngOnInit(): void {
  }

  /**
   * Abre la pestaña de tienda
   */
  openBuyingTab(): void{
    this._tabs.addTab('buyingpage');
  }

}
