import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ApirequestService } from "src/app/api/request/apirequest.service";

@Injectable({
  providedIn: 'root'
})
export class ApiFunctions {

  constructor(private _api: ApirequestService){}

  /* getAllDropdowns(): Observable<any>{
    return this._api.get("dropdowns?allData=true");
  } */

  getDropdowns(type:Array<string>): Observable<any>{

    return this._api.get("dropdowns?types="+JSON.stringify(type));
  }


  unsubscribeAll(subscriptions: Array<Subscription>): void{
    for (let i = 0; i < subscriptions.length; i++) {
      subscriptions[i].unsubscribe();
    }
  }
}
