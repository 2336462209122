import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loading-app',
  templateUrl: './loading-app.component.html',
  styleUrls: ['./loading-app.component.scss']
})
export class LoadingAppComponent implements OnInit {
  @Output() loaded = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

}
