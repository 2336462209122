<app-loader *ngIf="loading"></app-loader>

<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
  <!--PESTAÑA INFORMACION-->
  <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
      <ng-container *ngIf="formProvider">
          <form [formGroup]="formProvider">
              <mdb-card class="z-depth-0">
                  <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                      <div class="row">
                          <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Compañía activa</b></mdb-checkbox>
                      </div>
                  </mdb-card-header>
              </mdb-card>
              <div class="row mt-2">
                <div class="col-lg">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="cif" formControlName="cif" placeholder="" class="form-control form-control-sm">
                      <label for="cif" class="">CIF</label>
                    </div>
                </div>
                <div class="col-lg">
                  <div class="md-form md-outline">
                    <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="providername" placeholder="" formControlName="name" class="form-control form-control-sm">
                    <label for="providername" class="">Nombre</label>
                    <mdb-error *ngIf="formProvider.controls.name.invalid && (formProvider.controls.name.dirty || formProvider.controls.name.touched)">Campo obligatorio</mdb-error>
                  </div>
                </div>
                <div class="col-lg">
                  <div class="md-form md-outline">
                    <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="provideremail" placeholder="" formControlName="email" class="form-control form-control-sm">
                    <label for="provideremail" class="">Email</label>
                    <mdb-error *ngIf="formProvider.controls.email.invalid && (formProvider.controls.email.dirty || formProvider.controls.email.touched)">Introduce un email válido</mdb-error>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg">
                  <div class="md-form md-outline">
                    <input mdbInput type="text" id="providerphone" formControlName="phone" placeholder="" class="form-control form-control-sm">
                    <label for="providerphone" class="">Teléfono</label>
                  </div>
                </div>
                <div class="col-lg">
                    <div class="md-form md-outline">
                      <input mdbInput type="text" id="providermobile" formControlName="mobile" placeholder="" class="form-control form-control-sm">
                      <label for="providermobile" class="">Móvil</label>
                    </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                    <div class="md-form md-outline">
                        <textarea type="text" id="observations" rows="15" formControlName="observations" placeholder="" class="md-textarea form-control" mdbInput></textarea>
                        <label for="observations">Observaciones</label>
                    </div>
                </div>
                <div class="col-md-6">
                  <app-gmaps-component #maps></app-gmaps-component>
                </div>
            </div>
          </form>
      </ng-container>
  </mdb-tab>

  <!--PESTAÑA DIRECCIONES-->
  <mdb-tab heading="<i class='fas fa-map-marked-alt mr-2'></i> Direcciones">
    <app-addresses [params]="provider_addresses_params" [readonly]="disableChanges()" (loading)="loading = $event"></app-addresses>
  </mdb-tab>

  <!--COMPAÑIAS PRINCIPALES (ES SUBCOMPAÑIA DE)-->
  <mdb-tab heading="<i class='fas fa-building mr-2'></i> Compañías">
    <div class="row">

      <div class="col-md-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Compañías vinculadas
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Nombre compañía</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let provider of this.provider?.is_subcompanyof; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="removeProvider(provider)" *ngIf="!disableChanges()">
                        <mdb-icon fad icon="times-circle" size="sm" class="red-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{provider.name}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-md-6 scroll-card-body">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Compañías Disponibles
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Nombre compañía</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let provider of available_providers; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="addProvider(provider)" *ngIf="!disableChanges()">
                        <mdb-icon fad icon="plus-circle" size="sm" class="blue-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{provider.name}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </mdb-tab>

  <!--PROTOCOLOS-->
  <mdb-tab heading="<i class='fas fa-sliders-h mr-2'></i> Protocolos">
    
    <form [formGroup]="formProtocol">
      <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
              <div class="row">
                  <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Protocolo activo</b></mdb-checkbox>
              </div>
          </mdb-card-header>
      </mdb-card>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md">
              <div class="md-form md-outline">
                <mdb-select-2 [outline]="true" placeholder="Seleccionar" label="Protocolo" formControlName="typeprotocol_id">
                  <mdb-select-option *ngFor="let option of protocols" [value]="option.id">{{ option.name }}</mdb-select-option>
                </mdb-select-2>
              </div>
            </div>
            <div class="col-md">
              <div class="md-form md-outline">
                  <mdb-select-2 mdbValidate [outline]="true" [validateSuccess]="false"  placeholder="Seleccionar" label="Tipo de Acceso"  formControlName="typeprotocolaccess_id">
                    <mdb-select-option *ngFor="let option of access_type" [value]="option.id">{{ option.name }}</mdb-select-option>
                  </mdb-select-2>
                  <mdb-error *ngIf="formProtocol.controls.typeprotocolaccess_id.invalid && (formProtocol.controls.typeprotocolaccess_id.dirty || formProtocol.controls.typeprotocolaccess_id.touched)">Campo obligatorio</mdb-error>
                </div>
            </div>
            <div class="col-md">
              <div class="md-form md-outline" formGroupName="access_data">
                <input mdbInput type="text" id="code_provider" placeholder="" class="form-control form-control-sm" formControlName="code_provider">
                <label for="code_provider">Código (opcional)</label>
              </div>
            </div>
          </div>

          <div class="row mt-3" formGroupName="access_data">
            <ng-container *ngIf="typeprotocolaccess == 1 || typeprotocolaccess == 3 || typeprotocolaccess == 4">
              <div class="col-md">
                <div class="md-form md-outline">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="user" placeholder="" class="form-control form-control-sm" formControlName="user">
                  <label for="user">Usuario</label>
                </div>
              </div>
              <div class="col-md">
                <div class="md-form md-outline">
                  <input mdbInput mdbValidate [validateSuccess]="false" type="password" id="password" placeholder="" class="form-control form-control-sm" formControlName="password">
                  <label for="password">Password</label>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="typeprotocolaccess == 2 || typeprotocolaccess == 4">
              <div class="col-md">
                <div class="md-form md-outline">
                  <mdb-select-2 mdbValidate [outline]="true" [validateSuccess]="false"  placeholder="Seleccionar" label="Email"  formControlName="mailaccount_id">
                    <mdb-select-option *ngFor="let option of mailaccounts" [value]="option.id">{{ option.mail }}</mdb-select-option>
                  </mdb-select-2>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3" *ngIf="protocol_logo_path">
              <img src="{{protocol_logo_path}}" alt="" class="protocol-provider-logo">
            </div>
            <div class="col-md">
              <div class="alert alert-warning" role="alert" *ngIf="provider?.protocolaccess?.status_error">{{provider.protocolaccess.status_error_message}}</div>
            </div>
          </div>  
        </div>
      </div>
      <div class="row mt-2 ml-2">
        <div class="col-md-6">
          <button type="button" mdbBtn color="primary" mdbWavesEffect size="md" *ngIf="formProtocol.dirty" (click)="saveProtocolData()">
            Guardar Datos Protocolo
          </button>
        </div>
      </div>
    </form>
  </mdb-tab>
</mdb-tabset>

<!--
<div class="pt-2">

  <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

    <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
        <ng-container *ngIf="formProvider">

              <form [formGroup]="formProvider">
                <div class="row">
                    <div class="col-lg-1">
                        <mdb-checkbox formControlName="enabled" [default]="true" >Activo</mdb-checkbox>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="cif" formControlName="cif" placeholder=""
                                class="form-control form-control-sm">
                            <label for="cif" class="">CIF</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="providername" placeholder=""
                                formControlName="name" class="form-control form-control-sm">
                            <label for="providername" class="">Nombre</label>
                            <mdb-error
                                *ngIf="formProvider.controls.name.invalid && (formProvider.controls.name.dirty || formProvider.controls.name.touched)">
                                Campo obligatorio
                            </mdb-error>
                        </div>
                    </div>

                    <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="providerphone" formControlName="phone" placeholder=""
                              class="form-control form-control-sm">
                          <label for="providerphone" class="">Teléfono</label>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="providermobile" formControlName="mobile" placeholder=""
                              class="form-control form-control-sm">
                          <label for="providermobile" class="">Móvil</label>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="provideremail" placeholder=""
                              formControlName="email" class="form-control form-control-sm">
                          <label for="provideremail" class="">Email</label>
                          <mdb-error
                              *ngIf="formProvider.controls.email.invalid && (formProvider.controls.email.dirty || formProvider.controls.email.touched)">
                              Introduce un email válido</mdb-error>
                      </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md">
                    <app-addresses [params]="provider_addresses_params" [readonly]="disableChanges()" (loading)="loading = $event"></app-addresses>
                    </div>

                  <div class="col-md-5">
                    <mdb-card class="z-depth-0">
                      <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                          <mdb-icon fad icon="building" size="lg" class="mr-1 text-primary"></mdb-icon>Sub{{data.entity_label}} de
                      </mdb-card-header>
                      <mdb-card-body>
                        <div class="row">

                          <div class="col-md-6">
                            <table mdbTable small="true">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let provider of this.provider?.is_subcompanyof; let i=index">
                                  <tr mdbTableCol>
                                    <td>
                                      <a (click)="removeProvider(provider)" *ngIf="!disableChanges()">
                                        <mdb-icon fad icon="times-circle" size="sm" class="red-text"></mdb-icon>
                                      </a>
                                    </td>
                                    <td>
                                      {{provider.name}}
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-6 scroll-card-body">
                            <table mdbTable small="true">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">
                                  Disponibles
                                </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let provider of available_providers; let i=index">
                                  <tr mdbTableCol>
                                    <td>
                                      <a (click)="addProvider(provider)" *ngIf="!disableChanges()">
                                        <mdb-icon fad icon="plus-circle" size="sm" class="blue-text"></mdb-icon>
                                      </a>
                                    </td>
                                    <td>
                                      {{provider.name}}
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </mdb-card-body>
                    </mdb-card>
                  </div>
                </div>

              </form>
          </ng-container>
            </mdb-tab>

            <mdb-tab heading="<i class='fas fa-broadcast-tower'></i> Protocolo">
              <form [formGroup]="formProtocol">
                <div class="row mt-2">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2">
                        <mdb-checkbox formControlName="enabled" [default]="true" >Activo</mdb-checkbox>
                      </div>
                      <div class="col-md">
                          <div class="md-form">
                            <mdb-select-2 placeholder="Seleccionar" label="Protocolo" formControlName="typeprotocol_id">
                              <mdb-select-option *ngFor="let option of protocols" [value]="option.id">{{ option.name }}</mdb-select-option>
                            </mdb-select-2>
                        </div>
                      </div>
                      <div class="col-md">
                          <div class="md-form">
                            <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Seleccionar" label="Tipo de Acceso"  formControlName="typeprotocolaccess_id">
                              <mdb-select-option *ngFor="let option of access_type" [value]="option.id">{{ option.name }}</mdb-select-option>
                            </mdb-select-2>
                            <mdb-error
                                  *ngIf="formProtocol.controls.typeprotocolaccess_id.invalid && (formProtocol.controls.typeprotocolaccess_id.dirty || formProtocol.controls.typeprotocolaccess_id.touched)">
                                  Campo obligatorio</mdb-error>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="md-form" formGroupName="access_data">
                          <input mdbInput type="text" id="code_provider" placeholder="" class="form-control form-control-sm" formControlName="code_provider">
                          <label for="code_provider">Código (opcional)</label>
                        </div>
                      </div>
                    </div>

                <div class="row mt-3" formGroupName="access_data">
                  <ng-container *ngIf="typeprotocolaccess == 1 || typeprotocolaccess == 3 || typeprotocolaccess == 4">
                    <div class="col-md">
                      <div class="md-form">
                        <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="user" placeholder="" class="form-control form-control-sm" formControlName="user">
                        <label for="user">Usuario</label>
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="md-form">
                        <input mdbInput mdbValidate [validateSuccess]="false" type="password" id="password" placeholder="" class="form-control form-control-sm" formControlName="password">
                        <label for="password">Password</label>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="typeprotocolaccess == 2 || typeprotocolaccess == 4">
                    <div class="col-md">
                      <div class="md-form">
                        <mdb-select-2 mdbValidate [validateSuccess]="false"  placeholder="Seleccionar" label="Email"  formControlName="mailaccount_id">
                          <mdb-select-option *ngFor="let option of mailaccounts" [value]="option.id">{{ option.mail }}</mdb-select-option>
                        </mdb-select-2>
                      </div>
                    </div>
                  </ng-container>
                </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-3" *ngIf="protocol_logo_path">
                        <img src="{{protocol_logo_path}}" alt="" class="protocol-provider-logo">
                      </div>
                      <div class="col-md">
                        <div class="alert alert-warning" role="alert" *ngIf="provider?.protocolaccess?.status_error">
                          {{provider.protocolaccess.status_error_message}}
                        </div>
                        <!-- <div class="md-form md-outline">
                          <textarea type="text" id="form1" formControlName="status_error_message" class="md-textarea form-control" mdbInput></textarea>
                          <label for="form1">Errores</label>
                        </div> -->
 <!--                   </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-md-6">
                        <button type="button" mdbBtn color="primary" mdbWavesEffect size="sm" *ngIf="formProtocol.dirty" (click)="saveProtocolData()">
                          Guardar Datos Protocolo
                        </button>
                      </div>
                      </div>
                  </div>
              </div>
              </form>
            </mdb-tab>
        </mdb-tabset>
</div>
-->