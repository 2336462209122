import { ProcessingComponent } from "src/app/main/sections/processing/processing.component"
import { Tab } from "src/app/main/tab-models/tab.model"

export class ProcessingModel {
  tabData = {
    entity: 'processing'
  }

  getTab(): Tab {
    return new Tab(
      ProcessingComponent,
      "<i class='fas fa-house-damage fa-lg mr-1'></i>Panel Tramitación",
      {
        buttonNew: false,
        buttonDelete: false
      },
      this.tabData
      )
}
}
