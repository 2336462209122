import { ClientFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";

export class ClientModel extends EntityBaseModel{

  protected readonly icon = {
    form: 'user',
    list: 'user'
  }



  setModelData(models:any) {

    models.client.icon = this.icon;

    models.client.form_tabData = {
      entity: models.client.entity,
      saveEndpoint: models.client.saveEndpoint,
      entity_permissions: models.client.permissions,
      entity_label: models.client.labels.form,
      new_register_dropdowns: ['company_with_subcompanies'],
      edit_register_dropdowns: ['user','mailaccount']
    }

    models.client.list_tabData = {
      entity: models.client.entity,
      entity_label: models.client.labels.form,
      entity_permissions:models.client.permissions,
      listParams: {
        list_entity: models.client.list_entity,
        list_label: models.client.labels.list,
          limit: 15,
          headers: [
              'Activo',
              'Acceso Web',
              'NIF',
              'Nombre completo',
              'Dirección completa',
              'Teléfono',
              'Móvil',
              'Email',
          ],
          fields: [
              { entity: models.client.entity, field: 'enabled', type: 'check' },
              { entity: models.client.entity, field: 'web_access', type: 'check' },
              { entity: models.client.entity, field: 'identification_document' },
              { entity: models.client.entity, field: 'name_complete' },
              { entity: 'defaultaddress', field: 'address_complete' },
              { entity: models.client.entity, field: 'phone' },
              { entity: models.client.entity, field: 'mobile' },
              { entity: models.client.entity, field: 'email' },
          ],
          web_access: true,
          disable_user: false,
          filters: new ClientFilters(models)
      }
    }
  }
}
