<app-loader *ngIf="loading"></app-loader>

<mdb-tabset [buttonClass]="'classic-tabs tabs-grey'" [contentClass]="'card'" class="classic-tabs custom-tabset header">
    <!--PESTAÑA INFORMACION-->
    <mdb-tab heading="<i class='fas fa-book mr-2'></i> Información">
        <ng-container *ngIf="formAgent">
            <form [formGroup]="formAgent">
                <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        <div class="row">
                            <mdb-checkbox [default]="true" formControlName="enabled"><b style="color: gray;">Agente activo</b></mdb-checkbox>
                            <mdb-checkbox class="ml-3" [default]="true" formControlName="web_access" (change)="changeWebAccess($event)"><b style="color: gray;">Acceso web</b></mdb-checkbox>
                        </div>
                    </mdb-card-header>
                </mdb-card>
                <div class="row mt-2">
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                            <label for="identification_document" class="">NIF</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentname" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                            <label for="agentname" class="">Nombre</label>
                            <mdb-error *ngIf="formAgent.controls.name.invalid&& (formAgent.controls.name.dirty || formAgent.controls.name.touched)">
                                Campo obligatorio</mdb-error>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="agentsurname" formControlName="surname" class="form-control form-control-sm" placeholder="">
                            <label for="agentsurname" class="">Apellidos</label>
                            <mdb-error *ngIf="formAgent.controls.surname.invalid&& (formAgent.controls.surname.dirty || formAgent.controls.surname.touched)">
                                Debe introducir el apellido al dar acceso web</mdb-error>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="agentemail" formControlName="email" class="form-control form-control-sm" placeholder="">
                            <label for="agentemail" class="">Email</label>
                            <mdb-error *ngIf="formAgent.controls.email.invalid && (formAgent.controls.email.dirty || formAgent.controls.email.touched)">
                              <ng-container *ngFor="let error of formAgent.controls.email.errors | keyvalue">
                                <ng-container [ngSwitch]="error.key">
                                  <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                                  <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                                  <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>
  
                                </ng-container>
                              </ng-container>
  
                            </mdb-error>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentphone" formControlName="phone" class="form-control form-control-sm" placeholder="">
                            <label for="agentphone" class="">Teléfono</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentmobile" formControlName="mobile" class="form-control form-control-sm" placeholder="">
                            <label for="agentmobile" class="">Móvil</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-4">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentaddress" formControlName="address"
                                class="form-control form-control-sm" placeholder="">
                            <label for="agentaddress" class="">Dirección</label>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentpopulation" formControlName="population"
                                class="form-control form-control-sm" placeholder="">
                            <label for="agentpopulation" class="">Población</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentprovince" formControlName="province"
                                class="form-control form-control-sm" placeholder="">
                            <label for="agentprovince" class="">Provincia</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form md-outline">
                            <input mdbInput type="text" id="agentpostalcode" formControlName="postal_code"
                                class="form-control form-control-sm" placeholder="">
                            <label for="agentpostalcode" class="">Código Postal</label>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="md-form md-outline">
                            <textarea type="text" id="observations" rows="15" formControlName="observations" placeholder="" class="md-textarea form-control" mdbInput></textarea>
                            <label for="observations">Observaciones</label>
                        </div>
                    </div>

                </div>
            </form>
        </ng-container>
    </mdb-tab>
</mdb-tabset>
<!--
<div class="pt-2">

      <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">

        <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos generales">
          <ng-container *ngIf="formAgent">
          <form [formGroup]="formAgent">
                  <div class="row">
                    <div class="col-lg-2">
                        <mdb-checkbox [default]="true" formControlName="enabled" (change)="changeEnabled($event)">Activo
                        </mdb-checkbox>

                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                            <label for="identification_document" class="">NIF</label>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput type="text" id="agentname" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                            <label for="agentname" class="">Nombre</label>
                            <mdb-error *ngIf="formAgent.controls.name.invalid&& (formAgent.controls.name.dirty || formAgent.controls.name.touched)">
                                Campo obligatorio</mdb-error>
                        </div>
                    </div>
                    <div class="col-lg">
                        <div class="md-form">
                            <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="agentsurname" formControlName="surname" class="form-control form-control-sm" placeholder="">
                            <label for="agentsurname" class="">Apellidos</label>
                            <mdb-error *ngIf="formAgent.controls.surname.invalid&& (formAgent.controls.surname.dirty || formAgent.controls.surname.touched)">
                                Debe introducir el apellido al dar acceso web</mdb-error>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                  <div class="col-lg-2">
                      <mdb-checkbox [default]="true" formControlName="web_access" (change)="changeWebAccess($event)">Acceso web
                      </mdb-checkbox>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="agentemail" formControlName="email" class="form-control form-control-sm" placeholder="">
                          <label for="agentemail" class="">Email</label>
                          <mdb-error *ngIf="formAgent.controls.email.invalid && (formAgent.controls.email.dirty || formAgent.controls.email.touched)">
                            <ng-container *ngFor="let error of formAgent.controls.email.errors | keyvalue">
                              <ng-container [ngSwitch]="error.key">
                                <ng-container *ngSwitchCase="'required'">Campo Obligatorio</ng-container>
                                <ng-container *ngSwitchCase="'email'">Introduce un email válido</ng-container>
                                <ng-container *ngSwitchCase="'data_exists'">El email introducido ya está en uso</ng-container>

                              </ng-container>
                            </ng-container>

                          </mdb-error>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="agentphone" formControlName="phone" class="form-control form-control-sm" placeholder="">
                          <label for="agentphone" class="">Teléfono</label>
                      </div>
                  </div>
                  <div class="col-lg">
                      <div class="md-form">
                          <input mdbInput type="text" id="agentmobile" formControlName="mobile" class="form-control form-control-sm" placeholder="">
                          <label for="agentmobile" class="">Móvil</label>
                      </div>
                  </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <mdb-card class="z-depth-0">
                    <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                        <mdb-icon fad icon="home-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Dirección
                    </mdb-card-header>
                    <mdb-card-body>
                      <div class="row">
                        <div class="col-lg">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeeaddress" formControlName="address"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeeaddress" class="">Dirección</label>
                            </div>
                        </div>

                        <div class="col-lg">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeepopulation" formControlName="population"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeepopulation" class="">Población</label>
                            </div>
                        </div>
                        <div class="col-lg">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeeprovince" formControlName="province"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeeprovince" class="">Provincia</label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-1">
                            <div class="md-form">
                                <input mdbInput type="text" id="employeepostalcode" formControlName="postal_code"
                                    class="form-control form-control-sm" placeholder="">
                                <label for="employeepostalcode" class="">Código Postal</label>
                            </div>
                        </div>
                    </div>
                    </mdb-card-body>
                  </mdb-card>
                </div>
              </div>


          </form>
          </ng-container>
          </mdb-tab>
        </mdb-tabset>
</div>
-->