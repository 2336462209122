<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid">

    <ng-container *ngIf="data.listParams.filters">
      <app-dynamic-list-filters #dynamicFilters [entityFilters]="data.listParams.filters" (emitFilters)="applyFilters($event)"></app-dynamic-list-filters>
    </ng-container>

    <!--Table-->
    <div class="table-responsive" style="min-height:400px">
        <table mdbTable mdbTableScroll scrollY="true" maxHeight="100" #tableEl="mdbTable" class="z-depth-1">
            <!--Table head-->
            <thead>
                <tr class="blue darken-1 white-text">
                    <ng-container *ngFor="let header of data.listParams.headers; let i = index">
                        <th>
                            {{ header }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let registro of datos; let i = index">
                    <!-- <tr class="filas" (click)="seleccionRegistro($event, registro, i)" (dblclick)="irARegistro()" (contextmenu)="onContextMenu($event,registro,i)" [class.selected]="i == selectedRow"> -->
                    <tr class="filas" (dblclick)="editRegister(registro)" (click)="selectRegister(registro, i)" (contextmenu)="onContextMenu($event,registro,i)" [class.selected]="i == selectedRow">
                        <ng-container *ngFor="let param of data.listParams.fields; let j = index">



                            <ng-container *ngIf="param.entity == data.entity; else nested">
                                <ng-container *ngIf="param.type == undefined || param.type == null ">
                                    <td>

                                      <ng-container *ngIf="param.field === 'created_at'; else plaindata">
                                        {{ registro[param.field] | date: 'dd/MM/yyyy HH:mm:ss'}}
                                      </ng-container>
                                      <ng-template #plaindata>
                                        {{ registro[param.field] }}
                                      </ng-template>

                                    </td>
                                </ng-container>
                                <ng-container *ngIf="param.type == 'check' ">
                                    <ng-container *ngIf="registro[param.field] == 1 ">
                                        <td>
                                            <mdb-icon far icon="check-square"></mdb-icon>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="registro[param.field] != 1 ">
                                        <td>
                                            <mdb-icon far icon="square"></mdb-icon>
                                        </td>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-template #nested>
                              <!-- CAMPOS que vienen  en la respuesta de entidades relacionadas -->
                                <!-- Se recorre cada campo anidado del registro como clave->valor (clave: entidad anidada, valor: registro de la entidad)-->
                                <ng-container *ngFor="let campo of registro | keyvalue">
                                    <!-- Se compara la clave del parámetro con la del campo anidado -->
                                    <ng-container *ngIf="param.entity == campo.key">
                                        <td *ngIf="campo.value">

                                          <!-- Peronalización para juntar varios campos de relaciones distintas en una celda (ej: para mostrar compañía-subcompañía) -->
                                          <ng-container *ngIf="param.custom; else defaultdata">
                                            {{ campo.value[param.field] }}
                                            <ng-container *ngIf="registro[param.show_with.entity]">
                                              {{registro[param.show_with.entity][param.show_with.field]}}
                                            </ng-container>

                                          </ng-container>

                                          <!-- Muestra el dato simple -->
                                          <ng-template #defaultdata>
                                            {{ campo.value[param.field] }}
                                          </ng-template>
                                        </td>
                                        <td *ngIf="!campo.value"></td>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>

    </div>

    <tfoot class="grey lighten-5 w-100">
        <tr>
            <td></td>
            <td [colSpan]="data.listParams.headers.length">
                <mat-paginator #paginator style="float: left" [length]="total" [pageIndex]="pag_actual" [pageSize]="data.listParams.limit" [showFirstLastButtons]="true" [pageSizeOptions]="[data.listParams.limit]" (page)="changePage($event)">
                </mat-paginator>
            </td>
        </tr>
    </tfoot>

    <!--Menú contextual en las filas de la tabla-->
    <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
    </div>
    <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
            <button mat-menu-item (click)="editRegister(item)"><mdb-icon fas icon="eye" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Visualizar</button>

            <ng-container *appPermissions="data.entity_permissions?.delete">
                <button mat-menu-item><mdb-icon fas icon="trash" class="blue-grey-text pr-1" aria-hidden="true"></mdb-icon> Eliminar</button>
            </ng-container>


        </ng-template>
    </mat-menu>

</div>
