import { TitleCasePipe } from "@angular/common";
import { EntityBaseModel } from "./entity-base.model";
import { MailAccountFilters } from "../../data-classes";
import { Tab } from "src/app/main/tab-models/tab.model";
import { MailAccountsComponent } from "src/app/main/lists/mail-accounts/mail-accounts.component";

const titleCasePipe = new TitleCasePipe();

export class MailAccountModel extends EntityBaseModel {
  protected readonly icon = {
    form: 'envelope',
    list: 'envelope'
  }

  setModelData(models:any) {

    models.mailaccount.icon = this.icon;
    models.mailaccount.modules = ['mail'];

    models.mailaccount.form_tabData = {
      entity: models.mailaccount.entity,
      saveEndpoint: models.mailaccount.saveEndpoint,
      entity_permissions: models.mailaccount.permissions,
      entity_modules: models.mailaccount.modules,
      entity_label: models.mailaccount.labels.form,
      new_register_dropdowns:['mailaccount', 'tramit'],
      edit_register_dropdowns:['tramit'],
    }

    models.mailaccount.list_tabData = {
      entity: models.mailaccount.entity,
      entity_label: models.mailaccount.labels.form,
      entity_permissions:models.mailaccount.permissions,
      entity_modules:models.mailaccount.modules,
      listParams: {
        list_entity: models.mailaccount.list_entity,
        list_label: models.mailaccount.labels.list,
        limit: 10,
        headers: [
          'Cuenta',
          'Activa',
          'Tipo',
          'Estado'
        ],
        fields: [
          {entity: models.mailaccount.entity, field: 'mail'},
          {entity: models.mailaccount.entity, field: 'enabled'},
          {entity: 'typemailaccount', field: 'name'},
          {entity: models.mailaccount.entity, field: 'state'},
        ],
        web_access: false,
        disable_user: false,
        filter_firm: false,
        filters: new MailAccountFilters(models),
      }
    }
  }

  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      MailAccountsComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: true,
        buttonDelete: false,
        buttonSearch: true
      },
      tabData
      )
  }
}
