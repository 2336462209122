import { TitleCasePipe } from "@angular/common"
import { WorkorderFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";
import { ENUM_HISTORY_DATE_NAME } from "../../enumerates/enum-history-date-name";

const titleCasePipe = new TitleCasePipe();

export class WorkorderModel extends EntityBaseModel {

  private readonly license_alert: 'Ha llegado al nº máximo de Partes';

  protected readonly icon = {
    form: 'file-alt',
    list: 'file-alt'
  }


  setModelData(models:any) {
    models.workorder.icon = this.icon

    models.workorder.form_tabData = {
      entity: models.workorder.entity,
      saveEndpoint: models.workorder.saveEndpoint,
      entity_permissions: models.workorder.permissions,
      entity_label: models.workorder.labels.form,
      license_alert: this.license_alert,
      new_register_dropdowns:['taxe', 'guild', 'employee', 'serie', 'typeprovider', 'company_with_subcompanies', 'repairman', 'administrator'],
      edit_register_dropdowns: ['taxe', 'guild', 'employee', 'typecondition', 'user', 'typestatusonmobile', 'typeexport', 'serie']
    }

    models.workorder.list_tabData = {
      entity: models.workorder.entity,
      entity_label: models.workorder.labels.form,
      entity_permissions:models.workorder.permissions,
      license_alert: this.license_alert,
      listParams: {
        list_entity: models.workorder.list_entity,
        list_label: models.workorder.labels.list,
        limit: 10,
        headers: [
          'Número',
          titleCasePipe.transform(models.serie.labels.form),
          titleCasePipe.transform(models.claim.labels.form),
          'Nº Aviso',
          titleCasePipe.transform(models.client.labels.form),
          'Dirección',
          titleCasePipe.transform(models.employee.labels.form),
          titleCasePipe.transform(models.guild.labels.form),
          'Tramitador',
          'Estado',
          'Estado App',
          'Fecha de Cita',
          // 'Finalizada',
        ],
        fields: [
          {entity: models.workorder.entity, field: 'number'},
          {entity: models.serie.entity, field: 'description'},
          {entity: models.claim.entity, field: 'number'},
          {entity: models.claim.entity, field: 'number_provider'},
          {entity: models.client.entity, field: 'name_complete'},
          {entity: 'clientaddress', field: 'address_complete'},
          {entity: models.employee.entity, field: 'name_complete'},
          {entity: models.guild.entity, field: 'name'},
          {entity: models.user.entity, field: 'name'},
          {entity: 'typecondition', field: 'name'},
          {entity: 'typestatusonmobile', field: 'name'},
          {entity: 'current_appointment', field: 'starttime'},
          // {entity: 'appointment', field: 'finished'},
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new WorkorderFilters(models),
        history_date_filter: ENUM_HISTORY_DATE_NAME[models.workorder.entity],
        in_claim: {
          claim_id: null,
          limit: 5,
          headers:[
            {label: 'Número', class: 'code-column'},
            {label: titleCasePipe.transform(models.serie.labels.form), class: 'numbers-column'},
            titleCasePipe.transform(models.client.labels.form),
            'Dirección',
            titleCasePipe.transform(models.employee.labels.form),
            titleCasePipe.transform(models.guild.labels.form),
            'Tramitador',
            {label: 'Estado', class: 'numbers-column'},
            'Estado App',
            'Fecha de Cita',
          ],
          fields:[
            {entity: models.workorder.entity, field: 'number'},
            {entity: models.serie.entity, field: 'description'},
            {entity: models.client.entity, field: 'name_complete'},
            {entity: models.client.entity, field: 'address_complete'},
            {entity: models.employee.entity, field: 'name_complete'},
            {entity: models.guild.entity, field: 'name'},
            {entity: models.user.entity, field: 'name'},
            {entity: 'typecondition', field: 'name'},
            {entity: 'typestatusonmobile', field: 'name'},
            {entity: 'current_appointment', field: 'starttime'},
          ],
          lines_limit: 10,
          lines_headers: [
            {header: titleCasePipe.transform(models.workorder.labels.form), class: 'numbers-column'},
            {header: 'Exp.', class: 'icon-column'},
            { header: 'Liq.', class: 'icon-column'},
            {header: 'Código', class: 'code-column'},
            'Descripción',
            {header:titleCasePipe.transform(models.employee.labels.form), class:'medium-column'},
           { header: 'Cantidad', class: 'numbers-column'},
            {header: 'Coste', class: 'numbers-column'},
            {header: 'Precio', class: 'numbers-column'},
            {header:'%Descuento', class: 'numbers-column'},
            {header:'%Impuesto', class: 'numbers-column'}/* , 'Total Coste', 'Total Precio', 'Total IVA', 'Total' */
          ],
          lines_fields: [
            {
              field: 'workorder_number',
              type: 'none',
            },
            {
              control: 'type_export_line_id',
              type: 'checkbox',
              default:  0,
              disabled: false,
              class: 'icon-column'
            },
            {
              control: 'typeliquidation_id',
              type: 'checkbox',
              default:  1,
              disabled: false,
              class: 'icon-column'
            },
            {
              control: 'code',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'description',
              type: 'text',
              default: '',
              disabled: false
            },
            {
              control: 'employee_id',
              type: 'select',
              dropdown: 'workorder_employees',
              field_value: 'employee_name',
              select_value: 'id',
              select_label: 'name_complete',
              clear: true,
              listen_changes: true,
              disabled: false,
              default: null,
            },
            {
              control: 'quantity',
              type: 'number',
              default: 1,
              disabled: false
            },
            {
              control: 'cost',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'price',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'discount',
              type: 'number',
              disabled: false,
              default: 0,
            },
            {
              control: 'taxe_id',
              type: 'select',
              dropdown: 'taxes',
              field_value: 'taxe_value',
              select_value: 'id',
              select_label: 'value',
              addKey: true,
              clear: false,
              listen_changes: true,
              disabled: false,
              default: 1,
            }

            /* ,
            {
              control: 'total_cost',
              type: 'text',
              disabled: true,
              default: 0,
            },
            {
              control: 'total_price',
              type: 'text',
              disabled: true,
              default: 0,
            },
            {
              control: 'total_taxe',
              type: 'text',
              disabled: true,
              default: 0,
            },
            {
              control: 'total',
              type: 'text',
              disabled: true,
              default: 0,
            } */
          ],
          export_table_data: [
            {
              header: 'Número',
              entity: models.workorder.entity,
              field_name: 'number',
              class: 'code-column'
            },
            {
              header: titleCasePipe.transform(models.client.labels.form),
              entity: models.client.entity,
              field_name: 'name_complete',
            },
            {
              header: titleCasePipe.transform(models.guild.labels.form),
              entity: models.guild.entity,
              field_name: 'name',
              class: 'code-column',
            },
            {
              header: 'Situación',
              entity: 'typeexport',
              field_name: 'name',
              class: 'code-column',
            },
            {
              header: 'Importe',
              entity: models.workorder.entity,
              field_name: 'total',
              class: 'numbers-column',
            },
            {
              header: titleCasePipe.transform(models.employee.labels.form),
              entity: models.employee.entity,
              field_name: 'name_complete',
            },
          ],
          export_line_table_data: [
            {
              header: 'Código',
              field_name: 'code',
              class: 'code-column'
            },
            {
              header: 'Descripción',
              field_name: 'description'
            },
            {
              header: 'Precio',
              field_name: 'price',
              class: 'numbers-column'
            },
            {
              header: 'Cantidad',
              field_name: 'quantity',
              class: 'numbers-column'
            },
            {
              header: '%Desc',
              field_name: 'discount',
              class: 'numbers-column'
            },
            {
              header: '%Impto',
              field_name: 'taxe_value',
              class: 'numbers-column'
            },
            {
              header: 'Base',
              field_name: 'total_price',
              class: 'numbers-column'
            },
          ],
          export_destinations: [
            {
              entity: models.budget.entity,
              label: titleCasePipe.transform(models.budget.labels.form),
              list: models.budget.list_entity
            },
            {
              entity: models.deliverynote.entity,
              label: titleCasePipe.transform(models.deliverynote.labels.form),
              list: models.deliverynote.list_entity
            },
            {
              entity: models.invoice.entity,
              label: titleCasePipe.transform(models.invoice.labels.form),
              list: models.invoice.list_entity
            },
            {
              entity: models.invoiceproforma.entity,
              label: titleCasePipe.transform(models.invoiceproforma.labels.form),
              list: models.invoiceproforma.list_entity
            },
          ]
      }
    }


    }
  }
}
