import { ActionComponent } from "src/app/main/components/dynamic-modals/action/action.component";
import { ActionFilters } from "../../data-classes";
import { EntityBaseModel } from "./entity-base.model";
import { TitleCasePipe } from "@angular/common";
import { DynamicListComponent } from "src/app/main/components/dynamic-list/dynamic-list.component";
import { Tab } from "src/app/main/tab-models/tab.model";
import { ActionsComponent } from "src/app/main/lists/actions/actions.component";

const titleCasePipe = new TitleCasePipe();

export class ActionModel extends EntityBaseModel {

  protected readonly icon = {
    form: 'sticky-note',
    list: 'sticky-note'
  }

  setModelData(models:any) {

    models.action.icon = this.icon;

    models.action.form_tabData = {
      new_register_dropdowns: ['typeaction'],
      edit_register_dropdowns: ['typeaction']
    }

    models.action.list_tabData = {
      entity: models.action.entity,
      entity_label: models.action.labels.form,
      entity_permissions:models.action.permissions,
      saveEndpoint: models.action.saveEndpoint,
      modal_form: ActionComponent,
      modal_class: 'modal-lg',
      listParams: {
        list_entity: models.action.list_entity,
        list_label: models.action.labels.list,
        limit: 10,
        headers: [
          'Creación',
          'Tipo',
          'Usuario',
          'Descripción',
          titleCasePipe.transform(models.claim.labels.form),
          'Aviso',
          'Cerrada'
        ],
        fields: [
          { entity: models.action.entity, field: 'created_at' },
          { entity: 'typeaction', field: 'name' },
          { entity: models.user.entity, field: 'name',  custom: true, show_with: {entity: models.user.entity, field:'surname'}},
          { entity: models.action.entity, field: 'description' },
          { entity: models.claim.entity, field: 'number' },
          { entity: models.claim.entity, field: 'number_provider' },
          { entity: models.action.entity, field: 'close', type: 'check' }
        ],
        web_access: false,
        disable_user: false,
        filter_firm: true,
        filters: new ActionFilters(models),
      }
    }
  }


  getListTab(entity_data): Tab {
    let tabData = {...entity_data.list_tabData};
    return new Tab(
      ActionsComponent,
      "<i class='"+this.fa_conf.list+' fa-'+entity_data.icon.list+" fa-lg mr-1'></i>"+titleCasePipe.transform(entity_data.labels.list),
      {
        buttonNew: false,
        buttonDelete: false,
        buttonSearch: true
      },
      tabData
      )
  }

}
