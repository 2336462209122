import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { ExpertInterface } from 'src/app/shared/interfaces/data/expert-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { TabParamsInterface } from 'src/app/shared/interfaces/tab-params/tab-params-interface';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { Tab } from '../../tab-models/tab.model';

@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit, OnDestroy {

  @Input() tab: Tab;
  @Input() data: TabParamsInterface;

  private formFunctions = new FormFunctions;
  public loading: boolean = false;
  public formExpert: FormGroup;

  public expert: ExpertInterface;

  private id: number;
  private subscriptions: Array<Subscription> = [];

  public heading = "<i class='fas fa-info-circle'></i> Datos generales";

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;

  constructor(private _api: ApirequestService, private _lock: LockService, private _buttons: ActionButtonsService, private _notification: NotificationService) {
    this.formExpert = this.formFunctions.getForm('expert');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());

    this._api.unlockExit(this.lockdata)
  }

  ngOnInit(): void {
    this.id = this.data.id;
    this.getData();
  }

  private getData() {
    this.loading = true;
    this.subscriptions.push(
      this._api.get(this.data.saveEndpoint  + '/' + this.id).subscribe(
        data => {
          this.checkLock(data.response)
          this.loading = false;

        },
        error => {
          this.loading = false;
        })
    );
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)
    this.expert = apiresponse.expert;


    this.tab.tabData.headerData = this.expert.name_complete;


    console.log(this.lockdata)

    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formExpert = undefined;
          setTimeout(() => {
            this.formExpert = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?:boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formExpert, this.expert);
      this.formExpert.disable();
    } else {
      this.formFunctions.rellenarFormulario(this.formExpert, this.expert);


      this.subscriptions.push(
        this.formExpert.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      );
    }

  }

  public onSubmit(): void {
    this.formExpert.markAllAsTouched();

    if (this.formExpert.valid) {

      this._buttons.setLoading(true);
      const params = {};

      this.formFunctions.fillFormParams(this.formExpert, this.data.entity, params)
      params[this.data.entity]['name'] = this.formExpert.controls.name.value;

         this.subscriptions.push(
          this._api.put(this.data.saveEndpoint + '/' + this.data.id, JSON.stringify(params)).subscribe(
            resp => {
              this.tab.modifiedData = false;
              this.formExpert.markAsPristine();
              this._buttons.reloadListTab(this.data.saveEndpoint)
              this._buttons.setLoading(false);
              this._notification.notificacionOK(resp.response[this.data.entity])
            },
            err => {
              this._buttons.setLoading(false);
            }
          )
        );
    }
  }

}
