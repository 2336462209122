import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { UserPermissionsService } from 'src/app/main/services/user-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class MailGuard implements CanActivate {

  private gmailurl = 'mailaccounts/gmail/api/token';
  private outlookurl = 'mailaccounts/outlook/api/token';

  constructor(private router: Router, private activatedroute: ActivatedRoute, private _api: ApirequestService, private _userPermissions: UserPermissionsService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let type = route.paramMap.get('type');
      this._userPermissions.setCheckPermissions(true);
      let mailOperation = sessionStorage.getItem('mail_operation');
      sessionStorage.removeItem('mail_operation');

      let mailId = null;

      if(mailOperation == 'edit')
      {
        mailId = sessionStorage.getItem('mail_id');
        sessionStorage.removeItem('mail_id');
      }

      switch (type) {
        case 'gmail':
          if(route.queryParamMap.get('code')) {
            const parametro = {};
            parametro['code'] = route.queryParamMap.get('code');
            parametro['mailOperation'] = mailOperation;
            parametro['mailId'] = mailId;
            sessionStorage.getItem('mail_operation'); ///getItem('new') removeItem('new')

            return this.setToken(this.gmailurl, parametro)
          }

        case 'outlook':
          if(route.queryParamMap.get('code') && route.queryParamMap.get('state')) {
            const parametro_outlook = {};
            parametro_outlook['code'] = route.queryParamMap.get('code');
            parametro_outlook['state'] = route.queryParamMap.get('state');
            parametro_outlook['mailOperation'] = mailOperation;
            parametro_outlook['mailId'] = mailId;
            return this.setToken(this.outlookurl, parametro_outlook)
          }
      }

      this._userPermissions.setCheckPermissions(false);
      this.router.navigate(['/'])
      return false;
  }

  setToken(url: string, params: any) {
    return this._api.post(url, JSON.stringify(params)).pipe(
      map(
        response => {
          this._userPermissions.setCheckPermissions(false);
          this.router.navigate(['/'])
          return false;
        }
      ),
      catchError(err => {
        this._userPermissions.setCheckPermissions(false);
        this.router.navigate(['/'])
        return throwError( err );
      })
    )
  }

/*
  gmail(code: string): Observable<boolean | UrlTree> {
    const parametro = {};
    parametro['code'] = code;

    return this._api.post(this.gmailurl, JSON.stringify(parametro)).pipe(
      map(
        response => {
          this._userPermissions.setCheckPermissions(false);
          this.router.navigate(['cuentas-de-correo'])
          return false;
        }
      ),
      catchError(err => {
        this._userPermissions.setCheckPermissions(false);
        this.router.navigate(['cuentas-de-correo'])
        return throwError( err );
      })
    )
  }

  outlook(code:string, state:string): Observable<boolean | UrlTree>{
    const parametro = {};
    parametro['code'] = code;
    parametro['state'] = state;

    return this._api.post(this.outlookurl, JSON.stringify(parametro)).pipe(
      map(
        response => {
          this._userPermissions.setCheckPermissions(false);
          this.router.navigate(['cuentas-de-correo'])
          return false;
        }
      ),
      catchError(err => {
        this._userPermissions.setCheckPermissions(false);
        this.router.navigate(['cuentas-de-correo'])
        return throwError( err );
      })
    ) */
  }


