import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { forkJoin, throwError } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { DateFormats } from 'src/app/shared/functions/date-formats';
import { FormFunctions } from 'src/app/shared/functions/form-functions';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';
import { ClaimInterface } from 'src/app/shared/interfaces/data/claim-interface';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';
import { LockdataInterface } from 'src/app/shared/interfaces/data/lockdata-interface';
import { PolicyInterface } from 'src/app/shared/interfaces/data/policy-interface';
import { ChageClaimProviderComponent } from '../../components/dynamic-modals/chage-claim-provider/chage-claim-provider.component';
import { TextModalComponent } from '../../components/dynamic-modals/text-modal/text-modal.component';
import { UnblockModalComponent } from '../../components/dynamic-modals/unblock-modal/unblock-modal.component';
import { WhatsappChatComponent } from '../../components/dynamic-modals/whatsapp-chat/whatsapp-chat.component';
import { ActionButtonsService } from '../../services/action-buttons.service';
import { LockService } from '../../services/lock.service';
import { NotificationService } from '../../services/notification.service';
import { UserPermissionsService } from '../../services/user-permissions.service';

import { Tab } from '../../tab-models/tab.model';
import { EntityService } from '../../services/entity.service';
import { TitleCasePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { TabService } from '../../services/tab.service';
import { DynamicInClaimListComponent } from '../../components/dynamic-in-claim-list/dynamic-in-claim-list.component';
import { DropdownService } from '../../services/dropdown.service';


@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss']
})
export class ClaimComponent implements OnInit, OnDestroy {
  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth
  }

  @Input() tab: Tab
  @Input() data


  @ViewChild('inclaimlist', { static: false }) inclaimlist: DynamicInClaimListComponent;

  public currentWindowWidth: number;

  private id: number;


  private subscriptions: Array<Subscription> = [];

  private formFunctions = new FormFunctions;

  public formClaim: FormGroup;

  public claim: ClaimInterface;
  public original_claim_data: ClaimInterface;
  public dropdowndata: DropdownsInterface;

  public loading: boolean;

  public dateFormatter = new DateFormats;
  private utils = new PGAUtils;

  public policies: Array<PolicyInterface>;
  public client_addresses: Array<any>;
  public policychange = false;
  public addresschange = false;

  public client_entity = this._entity.entities.client;
  public workorder_entity = this._entity.entities.workorder;
  public budget_entity = this._entity.entities.budget;
  public invoice = this._entity.entities.invoice;
  public invoiceproforma = this._entity.entities.invoiceproforma;
  public guild_entity = this._entity.entities.guild;
  public deliverynote_entity = this._entity.entities.deliverynote;
  public expense_entity = this._entity.entities.expense;
  public company_entity = this._entity.entities.company;

  showmore = false;
  modalRef: MDBModalRef | null = null;
  modalRefUnblock: MDBModalRef | null = null;
  modalRefChangeProvider: MDBModalRef | null = null;
  modalRefChat: MDBModalRef | null = null;

  public welcome_template_approved: boolean;

  public claim_entity = this._entity.entities.claim;
  public agent_entity = this._entity.entities.agent;
  public action_entity = this._entity.entities.action;
  public task_entity = this._entity.entities.task;
  public user_entity = this._entity.entities.user;

  public app_entities = this._entity.entities;

  selected_section: {section:string, label:string, icon:string, params?:any} = {
    section: 'info',
    label: this.titleCasePipe.transform(this.claim_entity.labels.form),
    icon:'info-circle'
  }
  activeClaimTab = 0;

  private unlock_subscription: Subscription;
  private lockdata: LockdataInterface;


  constructor(private _api: ApirequestService, private _lock: LockService, private _apifunctions: ApiFunctions, private modalServiceChangeProvider: MDBModalService,
    private modalService: MDBModalService, private _userpermissions: UserPermissionsService, private _buttons: ActionButtonsService, private _notification: NotificationService,
    private _entity: EntityService, private titleCasePipe: TitleCasePipe, private _tabs: TabService, private _dropdowns: DropdownService) {
   }

  ngOnInit(): void {
    this.currentWindowWidth = window.innerWidth;
    this.id = +this.data.id
    this.workorder_entity.list_tabData.listParams.in_claim.claim_id = this.id;
    this.invoice.list_tabData.listParams.in_claim.claim_id = this.id;
    this.invoiceproforma.list_tabData.listParams.in_claim.claim_id = this.id;
    /* this.invoice_params.listParams.in_claim.claim_id = this.id
    this.invoiceproforma_params.listParams.in_claim.claim_id = this.id */
    this.budget_entity.list_tabData.listParams.in_claim.claim_id = this.id
    this.deliverynote_entity.list_tabData.listParams.in_claim.claim_id = this.id

    this.formClaim = this.formFunctions.getForm(this.data.entity);
    this.getData()
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
    this._api.unlockExit(this.lockdata)
  }

  tabFocus(event: MatTabChangeEvent) {
    console.log(this.activeClaimTab)
  }

  private getData() {
    this.loading = true;

    this.subscriptions.push(
      forkJoin([
        this._api.get(this.data.saveEndpoint + '/' + this.id),
        this._dropdowns.getDropdownsAPI_Observable(this.data.edit_register_dropdowns)
      ])
      .pipe(
        catchError(error => {
          this.loading = false;
          return throwError(error)
        })
      )
      .subscribe(
        responses => {
          console.log(responses)
          this.checkLock(responses.find(response => response.response.hasOwnProperty(this.data.entity)).response)
          this.setDropdowns()

          this.loading = false;
        },
        error => {
          if(error.status === 403) {
            this._tabs.removeTabByTabId(this.tab.tabId)
          } else {
            this.formClaim.disable();
          }
          this.loading = false;
        },
      )


    );
  }

  private setDropdowns() {

    let data = this.utils.objectDeepCopy(this._dropdowns.getDropdownsObject(this.data.edit_register_dropdowns));
    data.guild = data.guild.filter(guild => guild.enabled == true);
    data.taxe = data.taxe.filter(t => t.enabled == true);
    this.dropdowndata = data;
  }

  private checkLock(apiresponse: any): void {
    this.lockdata = this._lock.fillLockdata(apiresponse.lock)

    this.claim = {...apiresponse.claim};
    this.original_claim_data = {...apiresponse.claim};

    this.policies = apiresponse.claim.client.policies
    this.client_addresses = apiresponse.claim.client.clientaddress;
    this.tab.tabData.headerData = this.claim.number;



    if(!this.lockdata.unblock) {
      this.tab.tabLock = true;
      if(this.tab.tabActive) {
        this.openLockModal()
      }
    } else {
      this.setFormData()
    }
  }

  public openLockModal(){
    this._lock.showLockModal(this.lockdata);

    this.unlock_subscription = this._lock.confirmUnblock.subscribe(
      lockdata =>{
        if(lockdata) {
          this.lockdata = this._lock.fillLockdata(lockdata)
          this.tab.tabLock = false;
          this.formClaim = undefined;
          setTimeout(() => {
            this.formClaim = this.formFunctions.getForm(this.data.entity);
            this.setFormData();
          },5)
        } else{
          this.setFormData(true)
        }

        this.unlock_subscription.unsubscribe()
      }
    );
  }

  private setFormData(block?: boolean) {
    if(block){
      this.formFunctions.rellenarFormulario(this.formClaim, this.claim);
      this.setClaimProviderDate();
      this.formClaim.disable();
    } else {
      if(this.claim.firm.whatsapptemplates.find(template => template.name === 'welcome_'+this.claim.firm.whatsapp_firm_alias && template.typewhatsapptemplatestatus_id === 2)) {
        this.welcome_template_approved = true;
      }

      this.formFunctions.rellenarFormulario(this.formClaim, this.claim);

      this.setClaimProviderDate();

      this.subscriptions.push(
        this.formClaim.valueChanges.subscribe(
          change => {
            this.tab.modifiedData = true;
          }
        )
      )
    }

  }

  setClaimProviderDate(){

     /*  if(this.claim.provider?.issubcompanyof) {
        this.formClaim.controls.provider['controls'].name.setValue(this.claim.provider.name + ' | ' + this.claim.provider.issubcompanyof.name)
      } */

      if(this.claim.policy) {
       /*  let policydata = this.claim.policy.number +' - '+ this.claim.provider.name;
        if(this.claim.subprovider) {
          policydata += '('+this.claim.subprovider.name+')';
        }
        this.formClaim.addControl('policydata', new FormControl({value: policydata, disabled: true})); */
      }

      //console.log(this.formClaim)

      this.formClaim.controls.order_date.setValue(this.dateFormatter.cambioFormato(this.claim.order_date))


  }

  disableChanges(): boolean {
    let readonly = false;
    if(!this.lockdata?.unblock) {
      readonly = true;
    } else {
    }

    return readonly;
  }

  showFullDescription(){
    this.modalRef = this.modalService.show(TextModalComponent, {
      class: 'modal-lg',
      data: {
        heading: 'Descripción',
        text: this.formClaim.controls.description.value
      }
    })
  }

  changeSelect(tipo: string, valor) :void{
    this.formClaim.controls[tipo]['controls'].id.setValue(valor);
    this.formClaim.controls[tipo]['controls'].id.markAsDirty()
  }

  reloadSection(section_reload: string) {
    this.reloadCounters();
    if(this.selected_section.section === section_reload) {
      if(this.selected_section.params) {
        this.inclaimlist.loadData();
        this.inclaimlist.loadClaimLines()
        this.inclaimlist.setLineForm();
      } else {

        /* switch (this.selected_section.section) {
          case this.task_entity.list_entity:
            break;

          default:
            break;
        } */

      }
    }
  }

  reloadCounters(){

    this.subscriptions.push(
      this._api.get(this.data.saveEndpoint + '/' + this.id+ '/counters').subscribe(
        response => {
          let claim_counters = response.response.claim;

          Object.keys(claim_counters).forEach(
            counter => {
              this.claim[counter] = claim_counters[counter];
            }
          )



        },
        error => {

        }
      )
    )
  }



  selectSection(section: string) {
    this.selected_section.params = null;

    setTimeout(() => {
      this.selected_section.section = section
      switch (section) {
        case 'info':
          this.selected_section.params = null;
          this.selected_section.label = this.claim_entity.labels.form;
          this.selected_section.icon = 'info-circle'
          break;
        case this.workorder_entity.list_entity:
          this.selected_section.params = this.workorder_entity.list_tabData;
          this.selected_section.label = this.titleCasePipe.transform(this.workorder_entity.labels.list);
          this.selected_section.icon = this.workorder_entity.icon.list;
          break;
        case this.budget_entity.list_entity:
          this.selected_section.params = this.budget_entity.list_tabData;
          this.selected_section.label = this.titleCasePipe.transform(this.budget_entity.labels.list);
          this.selected_section.icon = this.budget_entity.icon.list;
          break;
        case this.deliverynote_entity.list_entity:
          this.selected_section.params = this.deliverynote_entity.list_tabData;
          this.selected_section.label = this.titleCasePipe.transform(this.deliverynote_entity.labels.list);
          this.selected_section.icon = this.deliverynote_entity.icon.list;
          break;
        case this.invoice.list_entity:
          this.selected_section.params = this.invoice.list_tabData;
          this.selected_section.label = this.titleCasePipe.transform(this.invoice.labels.list);
          this.selected_section.icon = this.invoice.icon.list;
          break;
        case this.invoiceproforma.list_entity:
          this.selected_section.params = this.invoiceproforma.list_tabData;
          this.selected_section.label = this.titleCasePipe.transform(this.invoiceproforma.labels.list);
          this.selected_section.icon = this.invoiceproforma.icon.list;
          break;
        case this.expense_entity.list_entity:
          this.selected_section.params = this.expense_entity.list_tabData;
          this.selected_section.label = this.titleCasePipe.transform(this.expense_entity.labels.list);
          this.selected_section.icon = this.expense_entity.icon.list;
          break;
        case 'claimprotocol':
          this.selected_section.label = 'Comunicaciones Protocolo';
          this.selected_section.icon = 'broadcast-tower';
          break;
        case 'claimemails':
          this.selected_section.label = 'Emails';
          this.selected_section.icon = 'at';
          break;
        case this.action_entity.list_entity:
          this.selected_section.label = this.titleCasePipe.transform(this.action_entity.labels.list);
          this.selected_section.icon = this.action_entity.icon.list;
          break;
        case this.task_entity.list_entity:
          this.selected_section.label = this.titleCasePipe.transform(this.task_entity.labels.list);
          this.selected_section.icon = this.task_entity.icon.list;
        break;
        case 'thirds':
          this.selected_section.label = 'Terceros';
          this.selected_section.icon = 'user-injured';
          break;
        case 'multimedia':
          this.selected_section.label = 'Multimedia';
          this.selected_section.icon = 'photo-video';
          break;
        case 'documents':
          this.selected_section.label = 'Documentos';
          this.selected_section.icon = 'file-pdf';
          break;

        default:
          break;
      }
    },0);
  }

  unblockModal(providerchange?:boolean){
    if(!this.original_claim_data.typeprotocol_id) {

      let text = '¿Desea cambiar la dirección del '+this.client_entity.labels.form+'?';
      if(providerchange) {
        text = '¿Desea cambiar el proveedor del '+this.claim_entity.labels.form+'?'
      }
      this.modalRefUnblock = this.modalService.show(UnblockModalComponent, {
        class: 'modal-sm',
        data: {
          text: text
        }
      });

      this.subscriptions.push(
        this.modalRefUnblock.content.unblockConfirm.subscribe(
            confirm => {
                if(confirm === true) {
                  if(providerchange) {
                   this.openChangeProvider();
                  } else {
                    this.addresschange = true;
                  }
                }
            }
          )
        )
    }
  }

  openChangeProvider(){
    this.modalRefChangeProvider = this.modalServiceChangeProvider.show(ChageClaimProviderComponent, {
      class: 'modal-lg',
      data: {
        policies: this.policies,
        provider_types: [...[{id: -1, name: 'Particular'}],...this.dropdowndata.typeprovider],
        repairmen: this.dropdowndata.repairman,
        administrators: this.dropdowndata.administrator,
        addresses: this.client_addresses,
        claim_data: this.claim
      }
    }
    )

    this.subscriptions.push(
      this.modalRefChangeProvider.content.newProviderData.subscribe(
        providerdata=>{
          if(providerdata) {
            this.claim.clientaddress = providerdata.clientaddress;
            this.claim.policy = providerdata.policy;

            if(providerdata.policy) {
              this.claim.provider = providerdata.policy.provider;
              this.claim.provider.typeprovider_id = providerdata.typeprovider;

              this.formClaim.controls.clientaddress.reset();
              this.formFunctions.rellenarFormulario(this.formClaim.controls.policy, providerdata.policy)
              this.formFunctions.rellenarFormulario(this.formClaim.controls.provider, providerdata.policy.provider)

              if(providerdata.policy.subprovider) {
                this.claim.subprovider = providerdata.policy.subprovider;
                this.formFunctions.rellenarFormulario(this.formClaim.controls.subprovider, providerdata.policy.subprovider)
              } else {
                this.claim.subprovider = null;
                this.formClaim.controls.subprovider.reset({id:null, name:'--Sin Asignar--'});
              }
            } else {

              this.formClaim.controls.policy.reset();

              if(providerdata.provider) {
                this.claim.provider = providerdata.provider;
                this.claim.provider.typeprovider_id = providerdata.typeprovider;

                this.formFunctions.rellenarFormulario(this.formClaim.controls.provider, providerdata.provider);


              } else {
                this.claim.provider = null;
                this.formClaim.controls.provider.reset({id:null, name:'Particular'});
              }
              this.formFunctions.rellenarFormulario(this.formClaim.controls.clientaddress, providerdata.clientaddress);
              this.claim.subprovider = null;
              this.formClaim.controls.subprovider.reset({id:null, name:'--Sin Asignar--'});


            }


            this.formClaim.controls.provider.markAsDirty();
            this.formClaim.controls.subprovider.markAsDirty();
            this.formClaim.controls.policy.markAsDirty();
            this.formClaim.controls.clientaddress.markAsDirty();
          }


        }
      )
    )
   // this.modalServiceChangeProvider.close.subscribe(() =>  console.log('claim'))
  }

  saveAddress(){
    let clientaddress_formgroup = (this.formClaim.controls.clientaddress as FormGroup);
    let selected_address = this.client_addresses.find(a=> a.id == clientaddress_formgroup.controls.id.value);

    clientaddress_formgroup.controls.address_complete.setValue(selected_address.address_complete);
    this.addresschange = false;
  }

  cancelEditAddress(){
    this.formClaim.controls.clientaddress['controls'].id.setValue(this.claim.clientaddress.id);
    this.formClaim.controls.clientaddress['controls'].address_complete.setValue(this.claim.clientaddress.address_complete);

    this.addresschange = false;
  }

  savePolicy(){
    let policy_formgroup = (this.formClaim.controls.policy as FormGroup);
    let selected_policy = this.policies.find(p=> p.id == policy_formgroup.controls.id.value);

    if(selected_policy){
      policy_formgroup.controls.number.setValue(selected_policy.number);
      policy_formgroup.controls.address_complete.setValue(selected_policy.address_complete);

      this.formClaim.controls.provider['controls'].id.setValue(selected_policy.provider.id);
      this.formClaim.controls.provider['controls'].name.setValue(selected_policy.provider.name);

      this.formClaim.controls.subprovider['controls'].id.setValue(selected_policy.subprovider?.id)
      this.formClaim.controls.subprovider['controls'].name.setValue((selected_policy.subprovider?.name ? selected_policy.subprovider.name : '--Sin Asignar--'))
    }

    this.policychange = false
  }

  cancelEditPolicy(){
    this.formClaim.controls.policy['controls'].id.setValue(this.claim.policy.id);
    this.formClaim.controls.policy['controls'].address_complete.setValue(this.claim.policy.address_complete);

    this.policychange = false;
  }


  openChat() {
    this.modalRefChat = this.modalService.show(WhatsappChatComponent, {
      class: '',
      data: {
        claim: this.claim,
        number: this.formClaim.controls.client['controls'].mobile.value
      }
    })

    this.subscriptions.push(
      this.modalService.closed.subscribe(() => this.modalRefChat.content.onClosed())
    );
  }

  onSubmit(){

    this.formClaim.markAllAsTouched();
    if(this.formClaim.valid) {
      this._buttons.setLoading(true);

      const params = {};
      this.formFunctions.fillFormParams(this.formClaim, this.data.entity, params)



      this.subscriptions.push(
        this._api.put(this.data.saveEndpoint+'/'+this.id, JSON.stringify(params)).subscribe(
          response => {
            this.tab.modifiedData = false;
            this.formClaim.markAsPristine();
            this.unsubscribeAll();
            this._buttons.reloadListTab(this.data.saveEndpoint)
            this._buttons.setLoading(false);
            this._notification.notificacionOK(response.response[this.data.entity])
            this.getData();
          },
          error => {
            this._buttons.setLoading(false);
          }
        )
      )
    }


  }

  openItem(register) {
    if(register.header_field) {
      this._tabs.addTab(register.enumModel, {id: register.id, [register.header_field]: register.header_value});
    } else {

      let section: string
        switch (register.enumModel) {
          case 'email':
            section = 'claimemails'
            break;
          case 'multimedia':
            section = register.enumModel
            break;
          default:
            section = register.enumModel+'s';
            break;
        }
        this.selectSection(section);
    }
  }


  unsubscribeAll(){
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
  }

}
