
<app-loader *ngIf="loading"></app-loader>

<div class="divCustomGlobal">
  <div class="divCustomSection">
    <i class='fas fa-book mr-2'></i> Información
  </div>
  <!--PESTAÑA INFORMACION-->
    <div style="margin: 10px;">
      <form [formGroup]="formFirm">

        <div class="row mt-2">
          <div class="col-lg">
            <div class="md-form md-outline">
                <input mdbInput type="text" id="identification_document" formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                <label for="identification_document" class="">CIF</label>
            </div>
          </div>

          <div class="col-lg">
            <div class="md-form md-outline">
                <input mdbInput type="text" id="firmname" formControlName="name" class="form-control form-control-sm" mdbValidate [validateSuccess]="false" placeholder="">
                <label for="firmname" class="">Nombre</label>
                <mdb-error *ngIf="formFirm.controls.name.invalid&& (formFirm.controls.name.dirty || formFirm.controls.name.touched)">
                    Campo obligatorio</mdb-error>
            </div>
          </div>

          <div class="col-lg">
            <div class="md-form md-outline">
              <input mdbInput type="text" id="firmphone" formControlName="phone" class="form-control form-control-sm"  placeholder="">
              <label for="firmphone" class="">Teléfono</label>
            </div>
          </div>

          <div class="col-lg">
            <div class="md-form md-outline">
              <input mdbInput type="text" id="firmmobile" formControlName="mobile" class="form-control form-control-sm"  placeholder="">
              <label for="firmmobile" class="">Móvil</label>
            </div>
          </div>

          <div class="col-lg">
            <div class="md-form md-outline">
              <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email"
                class="form-control form-control-sm" placeholder="">
              <label for="email" class="">Email</label>
              <mdb-error
                *ngIf="formFirm.controls.email.invalid && (formFirm.controls.email.dirty || formFirm.controls.email.touched)">
                Introduce un email válido</mdb-error>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!--PESTAÑA DIRECCION-->
  <div class="divCustomSection">
    <i class='fas fa-home-alt mr-2'></i> Dirección completa
  </div>
    <div style="margin: 10px;">
      <form [formGroup]="addressForm">
        <div class="row mt-3">
          <div class="col-lg">
            <div class="md-form md-outline">
              <input mdbInput type="text" id="firmddress"formControlName="address" class="form-control form-control-sm" placeholder="">
              <label for="firmddress" class="">Dirección</label>
            </div>
          </div>

          <div class="col-lg" formGroupName="postalcode">
            <div class="row">
              <div class="col">
                <div class="md-form md-outline">
                  <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                    <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                  </a>
                  <input mdbInput type="text" id="firmpostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                  <label for="firmpostal_code" class="">CP</label>
                </div>
              </div>
              <div class="col">
                <div class="md-form  md-outline">
                  <input mdbInput type="text" id="firmpopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                  <label for="firmpopulation" class="">Población</label>
                </div>
              </div>
              <div class="col">
                <div class="md-form  md-outline">
                  <input mdbInput type="text" id="firmprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                  <label for="firmprovince" class="">Provincia</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

  <!--PESTAÑA USUARIO-->
  <div class="divCustomSection">
    <i class='fas fa-user mr-2'></i> Usuarios
  </div>
  <div style="margin: 10px;">
    <div class="row">
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad [icon]="user_entity.icon.list" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios vinculados a la empresa
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Nombre completo</th>
                  <th scope="col">Email</th>
                  <th scope="col" class="medium-column">Rol</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of users; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="removeUser(user.user)">
                        <mdb-icon fad icon="times-circle mr-1" size="lg" class="red-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{user.user.name}} {{user.user.surname}}</span>
                    </td>
                    <td>
                      <span>{{user.user.email}}</span>
                    </td>
                    <td>
                      <!-- <div class="md-form md-outline">
                        <mdb-select-2 [outline]="true" placeholder="" label="">
                          <mdb-select-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mdb-select-option>
                        </mdb-select-2>
                      </div> -->
                      <!--  <mdb-select [options]="options" placeholder="" [outline]="true" [appendToBody]="true"></mdb-select> -->
                      <select class="form-control form-control-sm" [formControl]="user.role">
                        <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
                      </select>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="col-6">
        <mdb-card class="z-depth-0">
          <mdb-card-header class="blue-grey-text white border-bottom border-primary">
            <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Usuarios disponibles
          </mdb-card-header>
          <mdb-card-body class="scroll-card-body">
            <table mdbTable mdbTableScroll scrollY="true" small="true" class="inline-buttons-table">
              <thead>
                <tr>
                  <th scope="col" class="buttons-column"></th>
                  <th scope="col">Nombre completo</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let user of available_users; let i=index">
                  <tr mdbTableCol>
                    <td class="buttons-column">
                      <a (click)="addUser(user)">
                        <mdb-icon fad icon="plus-circle mr-1" size="lg" class="blue-text"></mdb-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{user.name}} {{user.surname}}</span>
                    </td>
                    <td>
                      <span>{{user.email}}</span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </div>
</div>
<!--

<app-loader *ngIf="loading"></app-loader>

<div class="container-fluid pt-2">

    <div class="row">
      <div class="col-12 custom-card-padding">
        <mdb-tabset [buttonClass]="'md-tabs pills-primary white mb-2'" [contentClass]="'card'">
          <mdb-tab heading="<i class='fas fa-info-circle'></i> Datos de la empresa">
            <form [formGroup]="formFirm">
              <div class="row">
                <div class="col-lg-1">
                    <div class="md-form">
                        <input mdbInput type="text" id="identification_document"
                            formControlName="identification_document" class="form-control form-control-sm" placeholder="">
                        <label for="identification_document" class="">CIF</label>
                    </div>
                </div>
                <div class="col-lg">
                    <div class="md-form">
                        <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="firmname"
                            formControlName="name" class="form-control form-control-sm" placeholder="">
                        <label for="firmname" class="">Nombre</label>
                        <mdb-error
                            *ngIf="formFirm.controls.name.invalid && (formFirm.controls.name.dirty || formFirm.controls.name.touched)">
                            Campo obligatorio</mdb-error>
                    </div>
                </div>
                <div class="col-lg">
                  <div class="md-form">
                    <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="email" formControlName="email"
                      class="form-control form-control-sm" placeholder="">
                    <label for="email" class="">Email</label>
                    <mdb-error
                      *ngIf="formFirm.controls.email.invalid && (formFirm.controls.email.dirty || formFirm.controls.email.touched)">
                      Introduce un email válido</mdb-error>
                  </div>
                </div>
                <div class="col-lg">
                    <div class="md-form">
                        <input mdbInput type="text" id="firmphone"
                            formControlName="phone" class="form-control form-control-sm"  placeholder="">
                        <label for="firmphone" class="">Teléfono</label>
                    </div>
                </div>
                <div class="col-lg">
                    <div class="md-form">
                        <input mdbInput type="text" id="firmmobile"
                            formControlName="mobile" class="form-control form-control-sm"  placeholder="">
                        <label for="firmmobile" class="">Móvil</label>
                    </div>
                </div>
            </div>
          </form>
          <div class="row mt-3">
            <div class="col-md-4">
              <mdb-card class="z-depth-0">
                <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                    <mdb-icon fad icon="home-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Dirección
                </mdb-card-header>
                <mdb-card-body>
                  <form [formGroup]="addressForm">
                    <div class="row mt-3">
                      <div class="col-md">
                        <div class="md-form">
                          <input mdbInput type="text" id="firmddress"formControlName="address" class="form-control form-control-sm" placeholder="">
                          <label for="firmddress" class="">Dirección</label>
                        </div>
                      </div>

                    </div>
                    <div class="row mt-3" formGroupName="postalcode">
                      <!-- <div class="col-1">

                      </div> -->
  <!--                    <div class="col-md-3">
                        <div class="md-form">
                          <a class="prefix blue-text" (click)="selectLocation()" mdbTooltip="Seleccionar Población" placement="top">
                            <mdb-icon fas icon="hand-point-up"></mdb-icon>
                          </a>
                          <input mdbInput type="text" id="firmpostal_code" formControlName="description" class="form-control form-control-sm" placeholder="" readonly>
                          <label for="firmpostal_code" class="">CP</label>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="md-form">
                          <input mdbInput type="text" id="firmpopulation"  formControlName="population" class="form-control form-control-sm" placeholder="" readonly>
                          <label for="firmpopulation" class="">Población</label>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="md-form">
                          <input mdbInput type="text" id="firmprovince"  formControlName="province" class="form-control form-control-sm" placeholder="" readonly>
                          <label for="firmprovince" class="">Provincia</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </mdb-card-body>
              </mdb-card>
            </div>
            <div class="col-md">
              <mdb-card class="z-depth-0">
                <mdb-card-header class="blue-grey-text white border-bottom border-primary text-center">
                    <mdb-icon fad
                    [icon]="user_entity.icon.list"
                    size="md"
                    class="mr-1"></mdb-icon> {{user_entity.labels.list | titlecase}}
                </mdb-card-header>
                <mdb-card-body>
                  <div class="row">
                    <div class="col-6">
                      <mdb-card class="z-depth-0">
                        <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                            <mdb-icon fad icon="users" size="lg" class="mr-1 text-primary"></mdb-icon>Vinculados
                        </mdb-card-header>
                        <mdb-card-body class="scroll-card-body">
                          <table mdbTable small="true">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Email</th>
                                <th scope="col">Rol</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let user of users; let i=index">
                                <tr mdbTableCol>
                                  <td>
                                    <a (click)="removeUser(user.user)">
                                      <mdb-icon fad icon="times-circle" class="red-text"></mdb-icon>
                                    </a>
                                  </td>
                                  <td>
                                    {{user.user.name}} {{user.user.surname}}
                                  </td>
                                  <td>
                                    {{user.user.email}}
                                  </td>
                                  <td>
                                    <!-- <div class="md-form md-outline">
                                      <mdb-select-2 [outline]="true" placeholder="" label="">
                                        <mdb-select-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mdb-select-option>
                                      </mdb-select-2>
                                    </div> -->
                                   <!--  <mdb-select [options]="options" placeholder="" [outline]="true" [appendToBody]="true"></mdb-select> -->
  <!--                                 <select class="browser-default custom-select" [formControl]="user.role">
                                    <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
                                  </select>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </mdb-card-body>
                      </mdb-card>
                    </div>
                    <div class="col-6">
                      <mdb-card class="z-depth-0">
                        <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                            <mdb-icon fad icon="users-cog" size="lg" class="mr-1 text-primary"></mdb-icon>Disponibles
                        </mdb-card-header>
                        <mdb-card-body class="scroll-card-body">
                          <table mdbTable small="true">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container *ngFor="let user of available_users; let i=index">
                                <tr mdbTableCol>
                                  <td>
                                    <a (click)="addUser(user)">
                                      <mdb-icon fad icon="plus-circle" class="blue-text"></mdb-icon>
                                    </a>
                                  </td>
                                  <td>
                                    {{user.name}} {{user.surname}}
                                  </td>
                                  <td>
                                    {{user.email}}
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </mdb-card-body>
                      </mdb-card>
                    </div>


                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div>
          <!-- <div class="row mt-3">
            <div class="col">
              <mdb-card class="z-depth-0">
                <mdb-card-header class="blue-grey-text white border-bottom border-primary">
                    <mdb-icon fad icon="home-alt" size="lg" class="mr-1 text-primary"></mdb-icon>Dirección
                </mdb-card-header>
                <mdb-card-body>
                  <div class="row">
                    <div class="col-lg">
                      <div class="md-form">
                        <input mdbInput type="text" id="address" formControlName="address"
                          class="form-control form-control-sm" placeholder="">
                        <label for="address" class="">Dirección</label>
                      </div>
                    </div>

                    <div class="col-lg">
                      <div class="md-form">
                        <input mdbInput type="text" id="population" formControlName="population"
                          class="form-control form-control-sm" placeholder="">
                        <label for="population" class="">Población</label>
                      </div>
                    </div>
                    <div class="col-lg">
                      <div class="md-form">
                        <input mdbInput type="text" id="province" formControlName="province"
                          class="form-control form-control-sm" placeholder="">
                        <label for="province" class="">Provincia</label>
                      </div>
                    </div>
                    <div class="col-lg-2 col-xl-1">
                      <div class="md-form">
                        <input mdbInput type="text" id="postalcode" formControlName="postal_code"
                          class="form-control form-control-sm" placeholder="">
                        <label for="postalcode" class="">Código Postal</label>
                      </div>
                    </div>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </div> -->


          <!-- <div class="row mt-3">
            <div class="col">
              <app-employee-guilds #guildsComponent [all_guilds]="dropdowndata?.guild" (guildsChanged)="tab.modifiedData = true"></app-employee-guilds>
            </div>
          </div> -->
<!--          </mdb-tab>
        </mdb-tabset>
      </div>
    </div>
</div>
-->
