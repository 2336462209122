<div class="modal-header text-center">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{modal_header}}</h4>
        <button type="button" class="modal-filter pull-left" aria-label="Filter" (click)="toggleFilters()" *ngIf="entity_filters">
          <mdb-icon fas icon="search" size="sm"></mdb-icon>
        </button>
</div>
  <div class="modal-body">
    <app-loader *ngIf="loading"></app-loader>
    <ng-container *ngIf="entity_filters">
      <app-dynamic-list-filters #dynamicFilters [entityFilters]="entity_filters" (emitFilters)="applyFilters($event)"></app-dynamic-list-filters>
    </ng-container>
    <table mdbTable mdbTableScroll scrollY="true" maxHeight="100" #tableEl="mdbTable" class="z-depth-1">
      <!--Table head-->
      <thead>
          <tr class="blue darken-1 white-text">
          <ng-container *ngFor="let header of list_headers">
            <th>{{header}}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let registro of data; let i = index">
          <tr class="filas" (click)="selectRegister(registro)">
            <ng-container *ngFor="let field of list_fields; let j = index">

              <ng-container *ngIf="field.entity == list_entity; else nested">
                <ng-container *ngIf="field.type == undefined || field.type == null ">
                    <td>{{ registro[field.field] }}</td>
                </ng-container>
                <ng-container *ngIf="field.type == 'check' ">
                    <ng-container *ngIf="registro[field.field] == 1 ">
                        <td>
                            <mdb-icon far icon="check-square"></mdb-icon>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="registro[field.field] != 1 ">
                        <td>
                            <mdb-icon far icon="square"></mdb-icon>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #nested>

              <!-- Se recorre cada campo anidado del registro como clave->valor (clave: entidad anidada, valor: registro de la entidad)-->
                <ng-container *ngFor="let campo of registro | keyvalue">
                  <!-- Se compara la clave del parámetro con la del campo anidado -->
                  <ng-container *ngIf="field.entity == campo.key">
                      <!-- <td *ngIf="campo.value; else vacio">
                              {{ campo.value[field.field] }}
                      </td>
                      <td *ngIf="!campo.value"></td> -->
                      <td *ngIf="campo.value">

                        <!-- Peronalización para juntar varios campos de relaciones distintas en una celda (ej: para mostrar compañía-subcompañía) -->
                        <ng-container *ngIf="field.custom; else defaultdata">
                          {{ campo.value[field.field] }}
                          <ng-container *ngIf="registro[field.show_with.entity]">
                            ({{registro[field.show_with.entity][field.show_with.field]}})
                          </ng-container>

                        </ng-container>

                        <!-- Muestra el dato simple -->
                        <ng-template #defaultdata>
                          {{ campo.value[field.field] }}
                        </ng-template>
                      </td>
                      <td *ngIf="!campo.value"></td>
                  </ng-container>
              </ng-container>
          </ng-template>


            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <tfoot class="grey lighten-5 w-100">
      <tr>
          <td></td>
          <td [colSpan]="list_headers.length">
              <mat-paginator #paginator style="float: left" [length]="total" [pageIndex]="pag_actual" [pageSize]="limit" [showFirstLastButtons]="true" [pageSizeOptions]="[limit]" (page)="changePage($event)">
              </mat-paginator>
          </td>
      </tr>
  </tfoot>

  </div>
  <div class="modal-footer">
    <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close" mdbWavesEffect (click)="modalRef.hide()">Cerrar</button>
  </div>
