import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subscription, Subject, Observable } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ActionButtonsService } from 'src/app/main/services/action-buttons.service';
import { NotificationService } from 'src/app/main/services/notification.service';

@Component({
  selector: 'app-serie-form',
  templateUrl: './serie-form.component.html',
  styleUrls: ['./serie-form.component.scss']
})
export class SerieFormComponent implements OnInit {
  header: string;
  register:any;
  entity: string
  endpoint: string

  private subscriptions: Array<Subscription> = [];

  public formSerie: FormGroup;

  private id: number|null

  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _notification: NotificationService, private fb: FormBuilder, private _buttons: ActionButtonsService) {

  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(){
    this.formSerie = this.fb.group({
      description:['', Validators.required],
      extend_description:['', Validators.required],
      default:[false],
    })

    if(this.register) {
      this.id = this.register.id;

      Object.keys(this.formSerie.controls).forEach(k => this.formSerie.controls[k].setValue(this.register[k]))

      if(this.register.default == true) {
        this.formSerie.controls.default.disable();
      }

      if(this.register.id == 1) {
        this.formSerie.controls.description.disable();
        this.formSerie.controls.extend_description.disable();
      }

    }

    console.log(this.register)
  }

  submit(){
    if(this.formSerie.dirty) {
      this.formSerie.markAllAsTouched()
      if(this.formSerie.valid) {
        this._buttons.setLoading(true);

        const params = {
          [this.entity]: this.formSerie.value
        }

        let call:Observable<any>;

        if(this.register) {
          if(this.register.id == 1) {
            params[this.entity].firstserie = true;
          }
          call = this._api.put(this.endpoint+'/'+this.register.id, JSON.stringify(params))
        } else {
          call = this._api.post(this.endpoint, JSON.stringify(params))
        }

        this.subscriptions.push(
          call.subscribe(
            response => {
              let msg = '';
              if(this.register) {
                msg = this._notification.getUpdateMsg();
              } else {
                msg = this._notification.getInsertMsg();

              }
              this._notification.notificacionOK(msg);
              this._buttons.reloadListTab(this.endpoint);
              this._buttons.setLoading(false);
              this.modalRef.hide();
            },
            error => {
              this._buttons.setLoading(false);
            }
          )
        )
      }
    } else {
      this.modalRef.hide();
    }
  }

  onClosed() {
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
    this.header = null;

  }


}
