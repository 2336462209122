import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { NotificationService } from 'src/app/main/services/notification.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  header: string;
  text: string = '¿Desea eliminar el registro seleccionado?';
  id: number;
  register_data: Array<any>;
  entity: string;
  delete_endpoint: string
  emit_delete: boolean

  private subscriptions: Array<Subscription> = [];
  confirmDelete = new Subject<any>();

  public deleting: boolean = false;

  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _notification: NotificationService) { }

  ngOnInit(): void {
  }

  onClosed() {
    for(let i=0;i<this.subscriptions.length;i++){
      this.subscriptions[i].unsubscribe();
    }
    this.header = null;
    this.id = null;
    this.register_data = null;
    this.entity = null;
    this.delete_endpoint = null;
    this.deleting = false;
  }

  delete(){
    if(this.emit_delete) {
      this.confirmDelete.next(this.id);
      this.modalRef.hide();
    } else {
      if(!this.deleting) {
        this.deleting = true;
        this.subscriptions.push(
          this._api.delete(this.delete_endpoint+'/'+this.id).subscribe(
            response => {
              this.deleting = false;
              this.confirmDelete.next(true);
              this._notification.notificacionOK(response.response[this.entity])
              this.modalRef.hide();
            },
            error => {
              this.deleting = false;
            }
          )
        )
      }
    }

  }

}
