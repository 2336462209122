import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject, Subscription } from 'rxjs';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { ActionButtonsService } from 'src/app/main/services/action-buttons.service';
import { DropdownService } from 'src/app/main/services/dropdown.service';
import { EntityService } from 'src/app/main/services/entity.service';
import { NotificationService } from 'src/app/main/services/notification.service';
import { ApiFunctions } from 'src/app/shared/functions/api-functions';
import { PGAUtils } from 'src/app/shared/functions/pga-utils';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  header: string = 'Datos de ';
  register:any;
  entity: string;
  endpoint: string;
  typeaction: Array<any>;
  claim:any | null;


  private utils = new PGAUtils;

  private subscriptions: Array<Subscription> = [];

  public formAction: FormGroup;

  private id: number|null;

  reload = new Subject<boolean>();

  public loading: boolean;

  public claim_entity = this._entity.entities.claim;
  public action_entity = this._entity.entities.action;

  constructor(public modalRef: MDBModalRef, private _api: ApirequestService, private _notification: NotificationService, private fb: FormBuilder, private _dropdowns: DropdownService, private _entity: EntityService){
    this.header += this.action_entity.labels.form
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(){
    if(this.typeaction) {
      this.formAction = this.fb.group({
        typeaction_id: [this.typeaction[0].id],
        close:[false],
        description:[null, Validators.required]
      });

      if(this.register) {
        this.id = this.register.id;

        Object.keys(this.formAction.controls).forEach(k => this.formAction.controls[k].setValue(this.register[k]))

      }
    } else {
      this.claim = {
        id: this.register.claim.id,
        number: this.register.claim.number,
      }
      this.formAction = this.fb.group({
        typeaction_id: [null, Validators.required],
        close:[false],
        description:[null, Validators.required]
      });

      this.loading = true;

      this.subscriptions.push(
        this._dropdowns.getDropdownsAPI_Observable(this.action_entity.form_tabData.edit_register_dropdowns).subscribe(
          response => {
            this.typeaction = this.utils.objectDeepCopy(response.response.dropdown.typeaction);
            this.formAction.controls.typeaction_id.setValue(this.typeaction[0].id);


            if(this.register) {
              this.id = this.register.id;

              Object.keys(this.formAction.controls).forEach(k => this.formAction.controls[k].setValue(this.register[k]))

            }

            this.loading = false;
          },
          error =>{
            this.loading = false;
          }
        )
      );
    }


  }

  submit(): void{
    this.formAction.markAllAsTouched();

    if(this.formAction.valid) {
      const formValues = this.formAction.value;
      const params: {action: any} = {
        action: {
          typeaction_id: formValues.typeaction_id,
          close: formValues.close,
          description: formValues.description
        }
      }
      this.loading = true;
      if(this.register) {
        this.updateRegister(params);
      } else {
        params.action.claim_id = this.claim.id;
        this.newRegister(params);
      }
    }
  }

  newRegister(params){
    this.subscriptions.push(
      this._api.post(this.endpoint, JSON.stringify(params)).subscribe(
        response => {
          this._notification.getInsertMsg();
          this.reload.next(true);
          this.modalRef.hide();

          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  updateRegister(params){
    this.subscriptions.push(
      this._api.put(this.endpoint+'/'+this.id, JSON.stringify(params)).subscribe(
        response => {
          this._notification.notificacionOK(this._notification.getUpdateMsg());
          this.reload.next(true);
          this.modalRef.hide();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    )
  }

  unsubscribeAll(): void{
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onClosed(): void {
    this.unsubscribeAll();
  }
}
