<div class="modal-header justify-content-center">
  <h4 class="modal-title" id="myModalLabel">{{header | titlecase}}</h4>
</div>
<app-loader *ngIf="loading"></app-loader>
<div class="modal-body">
  <form [formGroup]="formAction">
    <div class="row">
      <div class="col" *ngIf="claim">
        <div class="md-form md-outline">
          <input mdbInput type="text" id="form1" class="form-control" [value]="claim?.number" disabled>
          <label for="form1">{{claim_entity.labels.form | titlecase}}</label>
        </div>
      </div>
      <div class="col">
        <div class="md-form md-outline">
          <mdb-select-2 [outline]="true" placeholder="" [label]="'Tipo de '+(action_entity.labels.form | titlecase)" formControlName="typeaction_id">
            <mdb-select-option *ngFor="let option of typeaction" [value]="option.id">{{ option.name }}</mdb-select-option>
          </mdb-select-2>
        </div>
      </div>
      <div class="col-3">
        <mdb-checkbox formControlName="close" [default]="true">Cerrada</mdb-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="md-form md-outline">
          <textarea type="text" id="actiondescription" class="md-textarea form-control" placeholder="" formControlName="description" mdbInput mdbValidate [validateSuccess]="false"></textarea>
          <label for="actiondescription">Descripción</label>
          <mdb-error *ngIf="formAction.controls.description.invalid && (formAction.controls.description.touched || formAction.controls.description.dirty)">
            Campo Obligatorio
          </mdb-error>
        </div>
        <!-- <div class="md-form">
          <input mdbInput mdbValidate [validateSuccess]="false" type="text" id="guildname" formControlName="name" placeholder="" class="form-control form-control-sm">
          <label for="guildname">Nombre</label>
          <mdb-error *ngIf="formGuild.controls.name.invalid && (formGuild.controls.name.dirty || formGuild.controls.name.touched)">
            Campo obligatorio
          </mdb-error>
        </div> -->
      </div>
    </div>
  </form>
</div>
<div class="modal-footer text-center">
  <button mdbBtn type="button" size="sm" color="default" mdbWavesEffect (click)="submit()">
    <mdb-icon fas icon="check"></mdb-icon>
  </button>
  <button mdbBtn type="button" size="sm" color="light" mdbWavesEffect (click)="modalRef.hide()">
    <mdb-icon fas icon="undo"></mdb-icon>
  </button>
</div>
