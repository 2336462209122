import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader, ToastModule } from 'ng-uikit-pro-standard';

import { AuthGuard } from './shared';
import { Interceptor } from './api/interceptor';
import { LoadingAppComponent } from './loading-app/loading-app.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { PaginatorLanguage } from './shared/functions/paginator-language';
import { AppNamePipe } from './shared/pipes/app-name.pipe';
registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        LoadingAppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MDBBootstrapModulesPro.forRoot(),
        ToastModule.forRoot(),
        MatPaginatorModule,
        ReactiveFormsModule,
    ],
    providers: [
        MDBSpinningPreloader,
        AuthGuard,
        AppNamePipe,
        { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
        TitleCasePipe,
        {provide: MatPaginatorIntl, useClass: PaginatorLanguage}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
