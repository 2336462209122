import { Component, Input, OnInit } from '@angular/core';
import { DynamicListComponent } from '../../components/dynamic-list/dynamic-list.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends DynamicListComponent implements OnInit {

  public activeFirm: number;

  public selectedRow = -1;

  public borrando: boolean = false;

  ngOnInit(): void {
    if(this.data.listParams.filters.dropdowns) {
      this._dropdown.getDropdownsAPI(this.data.listParams.filters.dropdowns);
    }

    this.filters = {[this.data.entity] :{}};
    /* if (this.data.listparams?.filter_firm) {
      this.addFirmFilter();
    } */
    this.loadData();
  }



  selectUser(item, position) {
    this.selectedRow = position;
    this.register = item;
  }

  editRegister(register): void{
    if(register.email !== this._user.getUserData()?.email) {
      this._tabs.addTab(this.data.entity, register);
    } else {
      this._tabs.addTab('userprofile');
    }


  }

  onContextMenuUser(event: MouseEvent, registro, pos) {

    if (registro.email !== this._user.getUserData()?.email) {
      event.preventDefault();

      this.selectedRow = pos;

      this.register = registro;

      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.menuData = { 'item': this.register };
      this.contextMenu.menu.focusFirstItem('mouse');
      this.contextMenu.openMenu();
    }
  }

  /*   showDisableUser(item): boolean{
      let show = false;

      if(this.can_modify) {
        if(item.roleuserfirm.filter(it=> it.is_enabled==1).length > 0) {
          show = true;
        }
      }

      return show;
    } */

  /*   disableUser(){
      this.borrando = true;
      const params = {};
      params['is_enabled']= false;


      this._api.put('users/'+this.register.id,JSON.stringify(params)).subscribe(
        res=> {
            this.borrando = false;
            this.register = null;
            this._notificacion.notificacionOK(res.response.user)
            this.loadData();

        },
        err =>{
          this.borrando = false;
          console.log(err)
        }
      );
    } */

}
