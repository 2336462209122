import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ApirequestService } from 'src/app/api/request/apirequest.service';
import { DropdownsInterface } from 'src/app/shared/interfaces/data/dropdowns-interface';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  private dropdowns: DropdownsInterface = null;
  public dropdowns_sub = new Subject<DropdownsInterface>();


  private loading_types: Array<string>=[];



  constructor(private _api: ApirequestService) { }


  saveDropdowns(): void {
    if(this.dropdowns) {
      sessionStorage.setItem('dropdowns', btoa(JSON.stringify(this.dropdowns)))
    }
  }

  recoverDropdowns(): void {
    if (sessionStorage.getItem('dropdowns')) {
      let value = JSON.parse(atob(sessionStorage.getItem('dropdowns')));
      this.dropdowns = value;
      this.dropdowns_sub.next(this.dropdowns);
    }

  }

  resetDropdowns(): void {
    this.dropdowns = null;
    this.dropdowns_sub.next(this.dropdowns);
  }

  getDropdowns(): DropdownsInterface {
    return this.dropdowns
  }


  async getDropdownsAPI_ASYNC(dropdown_types:Array<string>): Promise<DropdownsInterface>{

    let unload_types = [];
    dropdown_types.forEach(
      dropdown_type => {


        let type = dropdown_type;
        switch (dropdown_type) {
          case 'company_with_subcompanies':
            type = 'company'
            break;
        }

        //si no está cargado y no está en proceso de carga se añade para cargar
        if(!this.dropdowns?.[type] /* && this.loading_types.indexOf(type) === -1 */) {
          unload_types.push(dropdown_type);
        }
      }
    )


  let promise = await new Promise<DropdownsInterface>((resolve, reject) => {

    if(unload_types.length > 0) {
      //this.loading_types = [...unload_types];
      this._api.get("dropdowns?types="+JSON.stringify(unload_types))
      .toPromise()
      .then(
        response => {
          this.loading_types = [];
          this.dropdowns = {...this.dropdowns, ...response.response.dropdown};

          this.dropdowns_sub.next(this.dropdowns);

          resolve(this.dropdowns);
        },
        error => {
          this.loading_types = [];
          //resuelve a null para que el component que llama siga la ejecución
          resolve(null)
        }
      );
    } else {
      //si ya están todos cargadas se devuelven
      resolve(this.dropdowns);
    }
  });



    return promise;



  }




  getDropdownsAPI_Observable(dropdown_types:Array<string>): Observable<any>{


    let unload_types = [];
    dropdown_types.forEach(
      dropdown_type => {


        let type = dropdown_type;
        /* switch (dropdown_type) {
          case 'company_with_subcompanies':
            type = 'company'
            break;
        } */

        //si no está cargado y no está en proceso de carga se añade para cargar
        if(!this.dropdowns?.[type] && this.loading_types.indexOf(type) === -1) {
          unload_types.push(dropdown_type);
        }
      }
    )


    if(unload_types.length> 0) {

      this.loading_types = [...unload_types];
      return this._api.get("dropdowns?types="+JSON.stringify(unload_types)).pipe(
        map(
          response => {
            this.loading_types = [];
            this.dropdowns = {...this.dropdowns, ...response.response.dropdown};

            this.dropdowns_sub.next(this.dropdowns);
            return {response: {dropdown: this.dropdowns}}
          }
        ),
        finalize(() => this.loading_types = [])
      );

    } else {
      return of({response: {dropdown: this.dropdowns}});
    }
  }


  getDropdownsAPI(dropdown_types:Array<string>): void{


    let unload_types = [];
    dropdown_types.forEach(
      dropdown_type => {


        let type = dropdown_type;
        /* switch (dropdown_type) {
          case 'company_with_subcompanies':
            type = 'company'
            break;
        } */

        //si no está cargado y no está en proceso de carga se añade para cargar
        if(!this.dropdowns?.[type] && this.loading_types.indexOf(type) === -1) {
          unload_types.push(dropdown_type);
        }
      }
    )



    if(unload_types.length> 0) {

      this.loading_types = [...unload_types];
      this._api.get("dropdowns?types="+JSON.stringify(unload_types)).subscribe(
        response => {
          this.loading_types = [];
          this.dropdowns = {...this.dropdowns, ...response.response.dropdown};

          this.dropdowns_sub.next(this.dropdowns);

        },
        error => {
          this.loading_types = [];
        }
      )
    }
  }


  unsubscribeAll(subscriptions: Array<Subscription>): void{
    for (let i = 0; i < subscriptions.length; i++) {
      subscriptions[i].unsubscribe();
    }
  }





  /**
   * Comprueba silos tipos de dropdowns están todos cargados
   * @param dropdown_types Array de strings con los tipos de dropdown a comprobar
   * @returns devuelve true si falta alguno por cargar
   */
  checkDropdownsLoaded(dropdown_types: Array<string>): boolean {
    let get_dropdowns_from_api = false;
    for (let index = 0; index < dropdown_types.length; index++) {

      const element = dropdown_types[index];

      let type = element;
      switch (element) {
        case 'company_with_subcompanies':
          type = 'company'
          break;
      }
      if(!this.dropdowns?.[type]) {
        get_dropdowns_from_api = true;
        break;
      }
    }

    return get_dropdowns_from_api;
  }

  /**
   * Devuelve un objeto con los datos de los tipos de dropdowns pasados por parámetro
   * @param dropdown_types tipos de dropdowns que tendrá el objeto
   * @returns
   */
  getDropdownsObject(dropdown_types: Array<string>): DropdownsInterface {

    let return_object: any={};

    //se va recorriendo los tipos pasados por parámetro y se añaden de los dropdowns cargados en memoria
    dropdown_types.forEach(
      (type: string) => {

        let object_key = type;
        /* switch (type) {
          case 'company_with_subcompanies':
            object_key = 'company'
            break;
        } */

        if(this.dropdowns[object_key]) {
          return_object[object_key] = this.dropdowns[object_key];
        }


      }
    );

    return return_object;


  }




  getDropdownsAPI_Observable2(dropdown_types:Array<string>): Observable<any>{


    let unload_types = [];
    dropdown_types.forEach(
      dropdown_type => {


        let type = dropdown_type;
        switch (dropdown_type) {
          case 'company_with_subcompanies':
            type = 'company'
            break;
        }

        //si no está cargado y no está en proceso de carga se añade para cargar
        if(!this.dropdowns?.[type] && this.loading_types.indexOf(type) === -1) {
          unload_types.push(dropdown_type);
        }
      }
    )

    console.log(this.loading_types)
    console.log(unload_types)

    if(unload_types.length> 0) {

      this.loading_types = [...unload_types];
      return this._api.get("dropdowns?types="+JSON.stringify(unload_types))/* .subscribe(
        response => {
          this.loading_types = [];
          this.dropdowns = {...this.dropdowns, ...response.response.dropdown};

          this.dropdowns_sub.next(this.dropdowns);

        },
        error => {
          this.loading_types = [];
        }
      ) */
    } else {
      return of({response: {dropdown: this.dropdowns}});
    }
  }


  /* getDropdownsAPI(types:Array<string>): Promise<DropdownsInterface> {
    let promise = new Promise<DropdownsInterface>((resolve, reject) => {
      this._api.get("dropdowns?types="+JSON.stringify(types))
        .toPromise()
        .then(
          res => { // Success

    console.log('ccccc')
            resolve(res.response.dropdown);
          },
          error => {
            reject(error)
          }
        );
    });
    return promise;


    /* .pipe(
      map(
        response => {
          return response.response.dropdown
        }
      )
    )
  } */


}
